import {
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  CircularProgress,
  Box,
  CardContent,
  Card,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik } from "formik";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Link ,RouteComponentProps,withRouter} from "react-router-dom";
import * as Yup from "yup";
import OAuth from "../../components/oauth/OAuth";
import MuiTextField from "../../components/textfield/MuiTextField";
import paths from "../../constants/paths";
import "./login.css";
import { login, resendVerification, clearLoading } from "./redux/actions";
import {
  currentOrganization,
  hasValidSessionSelector,
} from "./redux/selectors";

// type defs
import { WithTranslation } from "react-i18next";
import { createStyles, Theme, WithStyles } from "@material-ui/core";
import { Dispatch } from "redux";
import { History, Location } from 'history';
import { StaticContext } from 'react-router';
import ReCAPTCHA from "react-google-recaptcha";
import { VersionRibbon } from "../../helpers/utils.ext";
import LandingScreen from "../../components/companybanner/LandingScreen";
import palette from "../../theme/palette";
import SettingsIcon from '@material-ui/icons/Settings';
import { AuthformHeader } from "../../components/authformheader/AuthformHeader";

/* istanbul ignore next */
const useStyles = (theme: Theme) =>
  createStyles({
    root: {
      // height: "95vh",
      height: 'calc(98vh - 13px)',
      flex: 2
    },
    image: {
      backgroundImage: "url(/images/infographics/login.svg)",
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      //margin: theme.spacing(15, 8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: "15%",
      paddingRight: "15%",
      paddingTop: "5%",
      paddingBottom: "5%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "2.5%",
        paddingRight: "2.5%",
        //margin: theme.spacing(10, 6),
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      // marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1.5, 0, 2),
    },
    textfield: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px', // Adjust the radius value as needed
        // '&:hover fieldset': {
        //   borderColor: 'green', // You can change the hover border color if needed
        // },
        // '&.Mui-focused fieldset': {
        //   borderColor: 'blue', // You can change the focused border color if needed
        // },
      },
    },
  });


  type LocationState = {
    from: Location;
  };

  type Payload = {
    email: string;
    password: string;
    captcha_code: string
  };
  interface Props
    extends PropsFromRedux,
      WithTranslation,
      RouteComponentProps<{}, StaticContext, LocationState>,
      WithStyles<typeof useStyles> {}

export interface State {
  showPassword: boolean;
  email: string;
  password: string;
  rememberme: boolean;
  loginAttempt: number;
  captchaEntry: boolean;
  captcha_code: string
}
export class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPassword: false,
      email:
        localStorage.rememberMe && localStorage.rememberMe !== ""
          ? localStorage.email
          : "",
      password: "",
      rememberme:
        localStorage.rememberMe && localStorage.rememberMe !== ""
          ? true
          : false,
      captcha_code: "",
      loginAttempt: isNaN(localStorage.loginAttempt)
        ? 1
        : localStorage.loginAttempt,
      captchaEntry: false,
    };
  }
  recaptchaRef = React.createRef<ReCAPTCHA>();

  componentDidMount() {
    this.isAlreadyLoggedIn();
    this.props.clearLoading();
    this.handleCaptchaCheck();
  }

  isAlreadyLoggedIn = () => {
    if (this.props.hasValidSession === true) {
      let _path = paths.PROJECTLIST;
      if (
        this.props.currentOrganization &&
        this.props.currentOrganization.id > 0
      ) {
        _path = "/organization/" + this.props.currentOrganization.id;
      } else {
        const redirect = this.props.location.state
          ? this.props.location.state?.from
          : { pathname: paths.PROJECTLIST };

        _path = redirect.pathname;
      }
      this.props.history.push(_path);
    }
  };

  handleCaptchaCheck = () => {
    localStorage.loginAttempt = this.state.loginAttempt;
    if (this.state.loginAttempt > 3) {
      this.setState({
        captchaEntry: true,
      });
    }
  };

  // handleOnChange = (e) => {
  //     this.handleCaptchaCheck();
  //     this.setState({
  //         [ e.target.name ] : e.target.value
  //     })
  // }

  // handleSubmitOnEnter = (e) => {
  //     var _key = e.which || e.keyCode;
  //     if(_key === 13) {
  //         this.handleSubmit();
  //     }
  // }

  handleRememberMe = (
    e: React.ChangeEvent<HTMLInputElement>,
    email: string
  ) => {
    this.setState({
      rememberme: e.target.checked,
    });

    if (e.target.checked && email !== "") {
      localStorage.email = email;
      localStorage.rememberMe = e.target.checked;
    } else {
      localStorage.email = "";
      localStorage.rememberMe = "";
    }
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  resendVerification = () => {
    var payload:Pick<Payload,"email"> = {
      email: this.props.resendVerifyEmail,
    };
    this.props.resendVerification(payload);
  };

  hanldeDocNav=(url: string)=> {
    window.open(url, '_blank')
  }


  onChangeRecaptcha = (token: string | null) => {
    this.setState(
      {
        captcha_code: token?token:"",
      },
      () => {
        this.setState({
          captchaEntry: false,
        });
      }
    );
  };

  render() {
    const { t, classes, isLoginLoading } = this.props;
    return (
      <Grid container component="main" className={`${classes.root}`} >
        {/* <CssBaseline /> */}
        <LandingScreen mode={1}/>
        <Grid item xs={12} sm={12} md={12} lg={6} style={{padding: '16px 24px'}}>
          {/* <Grid container alignItems="center">
            <Grid item xs={6}>
              <img
                id="imgLogo"
                src="/images/logos/logo-blue.svg"
                alt="01Cloud"
                className="authlogo"
                height={40}
                width={40}
              />
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Button onClick={() => this.hanldeDocNav(window?.config?.REACT_APP_01CLOUD_DOCS)} disableElevation style={{display: 'flex', alignItems: 'center', gap: 8}}>
                <SettingsIcon style={{color: palette.text.secondary}}/>
                <Typography variant="subtitle2">Need help?</Typography>
              </Button>
            </Grid>
          </Grid> */}
          <AuthformHeader />
          {/* <VersionRibbon /> */}
          <Grid container justifyContent="center" alignContent="center" style={{height: "100%"}}>
            <Grid item xs={12} md={9}>
              {/* <Card elevation={10} style={{borderRadius: "30px"}}>
                <CardContent> */}
                <div className={classes.paper}>
                  {/* <img
                    id="imgLogo"
                    src="/images/logos/logo-blue.svg"
                    alt="01Cloud"
                    className="authlogo"
                  /> */}
                  <Typography variant="h5" align="left" id="loginTopMsg" style={{width: '100%'}}>{t("LoginWelcomeBack")}</Typography>
                  <Formik
                    initialValues={{
                      email: this.state.email,
                      password: this.state.password,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      this.setState((prevState) => ({
                        loginAttempt: prevState.loginAttempt + 1,
                      }));

                      setSubmitting(true);
                      const payload: Payload = {
                        email: values.email,
                        password: values.password,
                        captcha_code: this.state.captcha_code,
                      };
                      this.handleCaptchaCheck();
                      const location = this.props.location?.state?.from?.pathname + this.props.location?.state?.from?.hash;
                      this.props.loginAction(payload, this.props.history, location);

                      this.recaptchaRef.current?.reset();

                      this.setState({
                        captcha_code: "",
                      });
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .min(2, t("Account.PasswordTab.tooShort"))
                        .email(t("LoginModule.LoginPage.notValidEmail"))
                        .required(t("LoginModule.LoginPage.enterEmail")),
                      password: Yup.string().required(
                        t("LoginModule.LoginPage.enterPassword")
                      ),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        dirty,
                        // isSubmitting,
                        handleChange,
                        handleSubmit,
                        // handleReset,
                        isValid,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit} className={classes.form}>
                          <MuiTextField
                            id="email"
                            error={errors.email && touched.email ? true : false}
                            label={t("LoginModule.LoginPage.email")}
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            // onBlur={handleBlur}
                            helperText={
                              errors.email && touched.email ? errors.email : undefined
                            }
                            margin="normal"
                            customClassName={classes.textfield}
                          />

                          <MuiTextField
                            id="password"
                            margin="normal"
                            error={errors.password && touched.password ? true : false}
                            label={t("LoginModule.LoginPage.password")}
                            type={this.state.showPassword ? "text" : "password"}
                            value={values.password}
                            name="password"
                            onChange={handleChange}
                            customClassName={classes.textfield}
                            // onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  data-test="passwordVisibility"
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                    edge="end"
                                    data-test="password-icon"
                                  >
                                    {this.state.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            // labelWidth={70}
                            data-test="password"
                            helperText={
                              errors.password && touched.password
                                ? errors.password
                                : ""
                            }
                          />

                          <Grid container data-test="rememberContainer">
                            <Grid
                              item
                              md={6}
                              sm={12}
                              xs={12}
                              className="rememberContainer"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value="remember"
                                    color="primary"
                                    checked={this.state.rememberme}
                                    onChange={(e) => {
                                      this.handleRememberMe(e, values.email);
                                    }}
                                    data-test="rememberMe"
                                  />
                                }
                                label={t("RememberMe")}
                                data-test="remember-label"
                              />
                            </Grid>
                            <Grid
                              item
                              // align="right"
                              // style={{ display: "flex", justifyContent: "flex-end" }}
                              md={6}
                              sm={12}
                              xs={12}
                              className="forgotPasswordContainer"
                            >
                              <Link
                                to="/forgot"
                                // variant="body2"
                                className="textDecorationNone"
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                <Typography variant="body2">
                                  {t("LoginModule.LoginPage.forgotPassword")}
                                </Typography>
                              </Link>
                            </Grid>
                          </Grid>
                          {this.state.loginAttempt > 3 ? (
                            <Box
                              marginTop={2}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              data-test="loginAttemp"
                            >
                              <ReCAPTCHA
                                ref={this.recaptchaRef}
                                sitekey={window?.config?.REACT_APP_RECAPTCHA_SITEKEY}
                                onChange={this.onChangeRecaptcha}
                                data-test="hcaptcha"
                            />
                            </Box>
                          ) : (
                            ""
                          )}
                          
                          <Grid container spacing={2}>
                            <Grid item md={12} sm={12} xs={12}>
                              <div className="signInButtonDiv">
                                <Button
                                  id="loginbtn"
                                  variant="contained"
                                  type="submit"
                                  size="large"
                                  className="w-100 r-69"
                                  color="primary"
                                  // align="center"
                                  disabled={
                                    !(isValid && dirty) ||
                                    isLoginLoading || 
                                    this.state.captchaEntry
                                  }
                                  endIcon={
                                    isLoginLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                        thickness={4.5}
                                      />
                                    ) : null
                                  }
                                >
                                  {t("SIGN IN")}
                                </Button>
                              </div>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                              <Box 
                                display="flex" 
                                alignItems="center" 
                                justifyContent="center"
                              >
                                <Box 
                                  flex="1" 
                                  height="1px" 
                                  borderBottom={`1px dashed ${palette.background.neutral}`}
                                  bgcolor="transparent"
                                  mr={2}
                                />
                                <Typography
                                  variant="overline"
                                  style={{color: palette.text.disabled}}
                                  align="center"
                                >
                                  OR
                                </Typography>
                                <Box 
                                  flex="1" 
                                  height="1px" 
                                  borderBottom={`1px dashed ${palette.background.neutral}`}
                                  bgcolor="transparent"
                                  ml={2}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                              <OAuth />
                            </Grid>
                          </Grid>

                          {this.props.isResendVerifyEmail && (
                            <div className="signInButtonDiv">
                              <Button
                                color="primary"
                                onClick={this.resendVerification}
                                data-test="resend"
                              >
                                {t("LoginModule.LoginPage.verifcationEmail")}
                              </Button>
                            </div>
                          )}
                        </form>
                      );
                    }}
                  </Formik>
                  <div className="signInDiv">
                    
                    <Link
                      to="/requestdemo"
                      // variant="body2"
                      className="textDecorationNone"
                    >
                      <Typography variant="body1" className="signInLink">
                        {t("LoginModule.LoginPage.account")}
                        <span className="signup">
                          {" "}
                          {t("LoginModule.LoginPage.demo")}{" "}
                        </span>
                      </Typography>
                    </Link>
                  </div>
                </div>
                {/* </CardContent>
              </Card> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  hasValidSession: hasValidSessionSelector(state),
  isResendVerifyEmail: state.RegisterReducer.isResendVerifyEmail,
  resendVerifyEmail: state.RegisterReducer.resendVerifyEmail,
  currentOrganization: currentOrganization(state),
  isLoginLoading: state.AuthReducer.isLoginLoading,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    loginAction: (payload:Payload, history:History, location:string) => dispatch(login(payload, history, location)),
    resendVerification: (payload:Pick<Payload,"email">) => dispatch(resendVerification(payload)),
    clearLoading: () => dispatch(clearLoading()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(
  withRouter(withStyles(useStyles)(withTranslation()(Login)))
);
