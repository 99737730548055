import { Button,Divider, Grid, Menu, MenuItem, Paper,Tooltip, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React, { Component } from 'react';
import { withTranslation,WithTranslation } from "react-i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import Skeleton from '@material-ui/lab/Skeleton';

import { connect,ConnectedProps } from 'react-redux';
import ConfirmDeletePopup from '../../../components/confirmdeletepopup/ConfirmDeletePopup';
import EnvironmentCard from "../../../components/environmentcard/EnvironmentCard";
import BackdropLoader from '../../../components/loader/BackdropLoader';
// import { CreateApp_TemplateTypes } from '../../../constants/enums';
import { isAuthorized } from '../../../helpers/utils';
import { setCurrentProject, updateBreadcrumb } from '../../project/redux/actions';
import { clearAppdetail, deleteApp, fetchAppAvailableResource, fetchAppDetails, fetchAppRole, fetchEnvironmentsByAppId, renameApp } from '../redux/actions';
import { switchOrg } from "../../organization/redux/actions";
import './appinfo.css';
import RefreshIcon from '@material-ui/icons/Refresh';
import { DateHandler } from "../../../components/dateHandler/DateHandler"
import { BreadcrumbModel } from '../../../models/Common.model';
import {RepoName} from "../../../components/reponame/RepoName"
import NoContentImage from '../../../components/nocontentimagecontainer/NoContentImage';
import paths from '../../../constants/paths';
import { LabelHandler } from '../../../components/labelHandler/LabelHandler';
import { createStyles,Theme,WithStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { EnvironmentModel } from '../../../models/Environment.model';
import { StaticContext } from 'react-router';
import { Dispatch } from "redux";
import AppRenameDialog from '../../../components/appEnvRename/AppRenameDialog';
import { TitleHandler } from '../../../components/titleHandler/TitleHandler';
import { CustomColors } from '../../../constants/enums';

/* istanbul ignore next */
const useStyles = (theme:Theme) =>createStyles ({
  env: {
      display: "flex",
      marginTop:20,
      [theme.breakpoints.down(350)]: {
        display: "grid"
      }
  },
  globalVariables :{
    marginTop : 80
  },
  createEnv: {
    marginLeft : "auto"
  },
  topmargin: {
    marginTop: 15
  },
  delete: {
    marginTop: 30,
    textTransform: 'none'
  }
});

interface State {
  isConfirmPopupOpen: boolean;
  isHelmChart: boolean;
  anchorEl: Element | ((element: Element) => Element) | null;
  open: boolean
}

type Props = RouteComponentProps<{appId:string}, StaticContext> & PropsFromRedux & WithStyles<typeof useStyles> & WithTranslation ;
export class AppInfo extends Component<Props,State> {
    constructor(props:Props){
        super(props);
        this.state = {
          isConfirmPopupOpen: false,
          isHelmChart: false,
          anchorEl:null,
          open: false
        }
    }
  
  handleMenuClick = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      this.setState({ anchorEl : event.currentTarget });
  };
  
  handleMenuClose = () => {
      this.setState({ anchorEl : null });
  };
 
  componentDidMount() {
      const intAppId = parseInt(this.props.match.params.appId);
      this.props.fetchAppDetails(intAppId)
      // this.props.fetchEnvironmentsByAppId(this.props.match.params.appId)
      this.props.fetchAppRole(intAppId)
      this.props.fetchAppAvailableResource(intAppId)
    }

    handleDeleteApp = () =>{
      this.handleMenuClose()
      this.setState({
        isConfirmPopupOpen : true
      })
    }

    onRefresh = () => {
      if(this.props?.match?.params?.appId){
        this.props.fetchEnvironmentsByAppId(parseInt(this.props.match.params.appId), this.state.isHelmChart)
      }     
    }
    // handleEnvClick = (id) => {
    //   this.props.history.push('/environment/' + id);
    // }

    navigateToCreateEnv = () => {
      if(this.state.isHelmChart){
        this.props.history.push({
            pathname : paths.CREATEHELMENVIRONMENT.replace(":appId", this.props.match.params.appId)
        });
      }else if(this.props.appDetails?.operator_package_name){
        this.props.history.push({
          pathname: paths.CREATEOPERATORENVIRONMENT.replace(":appId", this.props.match.params.appId)
      });
      }
      else{
        this.props.history.push({
            pathname : paths.CREATEENVIRONMENT.replace(":appId", this.props.match.params.appId)
        });
      }
    }
  
    handleDisAgreeHandler = () => {
      this.setState({
        isConfirmPopupOpen : false
      })
    }

    handleAgreeHandler = () => { 
      this.setState({
        isConfirmPopupOpen : false
      })
      this.props.deleteApp(parseInt(this.props.match.params.appId), this.props.appDetails ? this.props.appDetails.project_id : 0, this.props.history)
    }

    switchOrgCallback = () => {
      this.props.fetchAppRole(parseInt(this.props.match.params.appId))
    }
  
  breadcrumbUpdate = (newProps: Props) => {
    const { appDetails } = newProps;
    const breadcrumbData = [
      { name: appDetails.project.name, path: '/project/' + appDetails.project_id },
      { name: appDetails.name, path: '/app/' + appDetails.id }
    ]
    this.props.updateBreadcrumb(breadcrumbData);
  }

    UNSAFE_componentWillReceiveProps = (newProps:Props) => {
      if(this.props.match.params.appId !== newProps.match.params.appId) 
      {
        const intAppId = parseInt(newProps.match.params.appId);
        this.props.fetchAppDetails(intAppId)
          //this.props.fetchEnvironmentsByAppId(newProps.match.params.appId)
        this.props.fetchAppRole(intAppId)
        this.props.fetchAppAvailableResource(intAppId)
      }

      if(newProps.appDetails.id > 0)
      {
          if(!this.props.appDetails.id || this.props.appDetails.id !== newProps.appDetails.id)
          {
            const { appDetails }= newProps;
            this.breadcrumbUpdate(newProps);
            this.props.setCurrentProject(appDetails.project_id as number);

            const org_id = appDetails?.project?.organization_id;
            if (org_id) {
              if (!this.props.currentOrganization || this.props.currentOrganization.id.toString() !== org_id) {
                this.props.switchOrg(org_id as number, null, null, 0, this.switchOrgCallback);
              }
            }
            else if (this.props.currentOrganization?.id) {
              this.props.switchOrg(0, null, null, 0, this.switchOrgCallback);
            }

            const _isHelmChart = appDetails?.chart_id ? true : false
            this.props.fetchEnvironmentsByAppId(appDetails.id as number, _isHelmChart)
            this.setState({
              isHelmChart: _isHelmChart
            }) 
          }
          if(this.props.appDetails.name !== newProps.appDetails.name){
            this.breadcrumbUpdate(newProps);
          }
        
      }
      if (!this.props.isRenameSuccess && newProps.isRenameSuccess) {
        this.setState({ open: false })
      }
    }

    componentWillUnmount(){
      this.props.clearAppdetail()
    }

    isEnoughResource = () => {
      const required_resource = this.props.appDetails?.plugin
      const available_resource = this.props.appAvailableResource
      if(required_resource && available_resource){
        return Boolean(required_resource.min_memory <= available_resource.memory && required_resource.min_cpu <= available_resource.cpu)
      }
      return true
    }

    getImage = () => {
      const { appDetails } = this.props;
      let _image = "";
      if (appDetails) {
        if (this.state.isHelmChart && appDetails.chart && appDetails.chart.Icon) {
          _image = appDetails.chart.Icon
        }
        else {
          _image = appDetails.plugin?.image;
        }
      }
      return _image;
    }

    handleRenameApp = () => {
      this.setState({
        open: true,
        anchorEl: null
      })
    }

    handleCloseDialog = () => {
      this.setState({ open: false })
    }

  handleNameSubmit = (appName: string) => {
    const intAppId = parseInt(this.props.match.params.appId);
    const name = { name: appName }
    if(appName !== this.props.appDetails.name){
      this.props.renameApp(intAppId, name)
    }
  }
  
    render () {
      const { classes, appDetails, environmentList, appRole, t ,isRenaming,isRenameSuccess} = this.props;
      console.log(appDetails);
      
        return (
            <div data-test="main-container">
                {isRenaming && <BackdropLoader message="Renaming your app please wait..."/>}
                { this.props.isDataNotFound && 
                    <Grid data-test="app-not-found" container justify="center" alignItems="center" className="notFoundGrid">
                        <NoContentImage 
                          message={t('App.AppInfo.applicationNotFound')}
                          alt="No Apps"
                          type="404"
                        />
                    </Grid>  
                }
                { !this.props.isDataNotFound && 
                <div>
                    <TitleHandler 
                    image={ appDetails && appDetails.plugin && appDetails.plugin.image  ? 
                            (<img src={ this.getImage() } alt='Plugin' height={25} className="appImg" data-test="app-image"/>) :
                            (<Skeleton variant='circle' height={25}width={25}/>)
                      }
                      title={!isRenaming && appDetails && appDetails.name ? appDetails.name : (<Skeleton width={200} />) }
                      actionItem={
                        isAuthorized('delete', appRole.name) && appDetails?.name && !this.props.envListLoading && (
                        <>
                            <Button variant="contained" color="primary" endIcon={<ExpandMoreIcon/>} disableElevation disableFocusRipple disableTouchRipple
                              aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleMenuClick(e)} data-test="action-button"
                            >
                                {t('App.AppInfo.actions')}
                            </Button>
                            <Menu
                              id="simple-menu"
                              anchorEl={ this.state.anchorEl }
                              open={ Boolean(this.state.anchorEl) }
                              onClose={ () => this.handleMenuClose() }
                              disableScrollLock={true}
                              getContentAnchorEl={ null }
                              anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
                              transformOrigin={ { vertical: 'top', horizontal: 'center' } }
                              data-test="action-menu"
                            >
                                <MenuItem onClick={() => this.handleRenameApp()}>Rename</MenuItem>
                                <MenuItem onClick={() => this.handleDeleteApp()} data-test="action-delete">{t('App.AppInfo.delete')}</MenuItem>
                      
                            </Menu>
                        </>
                        )
                        }
                    />
                    <Grid container className="appIconsTop" spacing={3}>
                        <Grid item className="topgridalign">
                            <LabelHandler 
                              icon={<LanguageOutlinedIcon style={{color: CustomColors.other.icon}}/>}
                              iconTooltip={t('App.AppInfo.region')}
                              label={appDetails?.cluster?.name}
                              labelTooltip={appDetails?.cluster?.zone}
                              labelType='Grey'
                            />
                        </Grid>
                
                        <Grid item className="topgridalign">
                          <LabelHandler 
                            icon={<PersonOutlineOutlinedIcon style={{color: CustomColors.other.icon}}/>}
                            iconTooltip={t('App.AppInfo.createdBy')}
                            label={
                              appDetails?.owner?.first_name 
                                ? `${appDetails?.owner?.first_name } ${appDetails?.owner?.last_name}`
                                : ""
                            }
                            labelType='Info'
                          />
                        </Grid>
                        {(appDetails?.git_repository_info?.name || appDetails?.image_url) &&
                        (
                            <Grid item className="topgridalign">
                              <RepoName source={1} appDetails={appDetails} />
                            </Grid>
                        )}
                        <Grid item className="topgridalign">
                            <DateHandler 
                            date={appDetails.createdat}  
                            icon={<ScheduleIcon className="svgicon" style={{color: CustomColors.other.icon}}/>}
                            labelType='Secondary'
                            />
                        </Grid>

                    </Grid>
                    <Divider/>
                    <div className={classes.env}>
                        <Typography variant="h4" >{t('App.AppInfo.environments')}</Typography>
                        <div className={classes.createEnv}>
                          <span data-test="refreshIcon" >
                            <Tooltip title="Refresh Environments list">
                              <IconButton onClick={() => {this.onRefresh()}} data-test="refresh-icon">
                                <RefreshIcon color="primary"/>
                              </IconButton>
                            </Tooltip>
                          </span>
                        { isAuthorized('create', appRole.name) && appDetails?.cluster?.active && (
                          <Button data-test="create-env-btn" disabled={!this.isEnoughResource()} variant="contained" color="primary" onClick={this.navigateToCreateEnv}>Create Environment</Button>
                        )}
                        </div>
                    </div>
                  
                    <Grid container spacing={3} className={classes.topmargin}>      
                        {
                      this.props.envListLoading && [0,1,2,3,4,5].map(val => {
                        return (
                            <Grid item xs={4} key={ val }>
                                <Paper variant='outlined' style={{padding : '1rem'}}>
                                    <SkeletonTheme height={250}>
                                        <div className='displayFlexjustifySpacebetween'>
                                            <div>
                                                <Skeleton variant="circle" height={50} width={50} />
                                            </div>
                                            <div  className='nameSkeleton'>
                                                <Skeleton  width={180}  />
                                            </div>
                                        </div>
                                    </SkeletonTheme>
                                </Paper>
                            </Grid>
                          )
                        })
                      }
                        {
                    !this.props.envListLoading && 
                    <>
                        {environmentList && environmentList.length > 0 && environmentList.map((item:EnvironmentModel, ind:number) => (
                          <EnvironmentCard data-test="env-card" details={item} key={ind} isHelmChart={this.state.isHelmChart}/>
                      ))}
                        {
                          environmentList && environmentList.length === 0 && 
                          <NoContentImage 
                            message={t('App.AppInfo.noEnvError')}
                            alt="No Environemnt"
                            type="env"
                            data-test="env-not-found"
                          />                                                                   
                      }
                    </>
                  }
                    </Grid>
                    <ConfirmDeletePopup open={this.state.isConfirmPopupOpen} handleAgree={this.handleAgreeHandler} handleDisAgree={this.handleDisAgreeHandler}   toMatchName={appDetails.name} toDeleteModule="app" loading={this.props.deletingApp} data-test="delete-popup"/> 
                    { this.props.deletingApp && <BackdropLoader message={t('App.AppInfo.deleteMessage')} data-test="delete-app"/>}
                    { this.props.isLoading && <BackdropLoader message={"Fetching environments List"} data-test="loading" />}
                </div>
                }
                
                { this.state.open && 
                <AppRenameDialog
                open = {this.state.open }
                handleCloseDialog = {this.handleCloseDialog}
                appName = {this.props.appDetails.name}
                handleNameSubmit = {this.handleNameSubmit}
                />
                }
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  appDetails: state.AppsReducer.appDetails,
  isRenaming:state.AppsReducer.isRenaming,
  isRenameSuccess:state.AppsReducer.isRenameSuccess,
  environmentList: state.AppsReducer.environmentList,
  isLoading: state.AppsReducer.isLoading,
  isDataNotFound: state.AppsReducer.isDataNotFound,
  appRole: state.AppsReducer.appRole,
  envListLoading: state.AppsReducer.envListLoading,
  deletingApp: state.AppsReducer.deletingApp,
  appAvailableResource: state.AppsReducer.appAvailableResource,
  currentOrganization: state.AuthReducer.currentOrganization
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    fetchAppDetails : (payload:number) => dispatch(fetchAppDetails(payload)),
    deleteApp: (id:number, project_id:number, history:RouteComponentProps["history"]) => dispatch(deleteApp(id, project_id, history)),
    fetchEnvironmentsByAppId: (id:number, isHelm:boolean) => dispatch(fetchEnvironmentsByAppId(id, isHelm)),
    updateBreadcrumb: (breadcrumbData: BreadcrumbModel[]) => dispatch(updateBreadcrumb(breadcrumbData)),
    clearAppdetail: () => dispatch(clearAppdetail()),
    setCurrentProject: (id:number) => dispatch(setCurrentProject(id)), 
    fetchAppRole: (id:number) => dispatch(fetchAppRole(id)),
    fetchAppAvailableResource : (id:number) => dispatch(fetchAppAvailableResource(id)),
    switchOrg: (id:number, history:RouteComponentProps["history"]|null, path:string|null, source:number, dependencyCallback:() => void) => dispatch(switchOrg(id, history, path, source, dependencyCallback)),
    renameApp: (id:number, name:any) => dispatch(renameApp(id, name)),
  }
}
const connector=connect(
  mapStateToProps,
  mapDispatchtoProps
)

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withStyles(useStyles)(withTranslation()(AppInfo)))
