import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import CPUChartCard from "../chartcard/CPUChartCard";
import RAMChartCard from "../chartcard/RAMChartCard";
import DataUsageChartCard from "../chartcard/DataUsageChartCard";
import BackdropLoader from "../loader/BackdropLoader";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useTranslation } from "react-i18next";
import {
  fetchPodInsights,
  fetchContainerInsights,
  fetchInsightGraphData,
  clearInsightGraphData,
} from "../../pages/pods/redux/actions";
import {
  clearEnvInsights,
} from "../../pages/environment/redux/actions";
import { getDurationInSeconds } from "../../helpers/utils";
import { connect, ConnectedProps } from "react-redux";

import { Dispatch } from "redux";

const useStyles = makeStyles(() => ({
  durationDropdown: {
    minWidth: 120,
  },
  podDropdown: {
    minWidth: 180,
  },
}));

interface Props extends PropsFromRedux {
  environmentId: number;
  type: string;
  podName: string;
  containerName?: string;
}

export const ContainerMonitoring = (props: Props) => {
  const [selectedDuration, setSelectedDuration] = useState("Hour");

  const classes = useStyles();

  const [t] = useTranslation();

  const getUsageData = ({
    id,
    duration = "Hour",
  }: {
    id?: number;
    duration: string;
  }) => {
    if (!id) {
      id = props.environmentId;
    }
    const nowInS = Math.round(new Date().getTime() / 1000);
    let payload = {
      start_time: nowInS - getDurationInSeconds(duration),
      end_time: nowInS,
    };

    props.fetchInsightGraphData(id, payload);
  };

  const handleDurationChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setSelectedDuration(e.target.value as string);
    getUsageData({ duration: e.target.value as string });
  };

  const getInsightsProps = () => {
    if (props.type === "pod") {
      return {
        selectedPod: props.podName,
        environmentInsights: props.podInsightGraphData,
      };
    }

    if (props.type === "container") {
      return {
        selectedPod: props.containerName,
        environmentInsights: props.containerInsightGraphData,
      };
    }
  };

  const handleRefresh = () => {
    getUsageData({
      id: props.environmentId,
      duration: selectedDuration,
    });
  };

  useEffect(() => {
    getUsageData({
      id: props.environmentId,
      duration: selectedDuration,
    });

    return () => {
      props.clearInsightGraphData();
    };
  }, []);

  return (
    <>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        spacing={1}
        data-test="monitoring-container"
      >
        <Grid item>
          <IconButton
            title="Refresh Insights"
            color="primary"
            aria-label="Refresh"
            // className={classes.refresh}
            onClick={handleRefresh}
            data-test="refresh-button"
          >
            <RefreshIcon fontSize="medium" />
          </IconButton>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" className={classes.durationDropdown}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedDuration}
              onChange={handleDurationChange}
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                transformOrigin: { vertical: "top", horizontal: "left" },
                getContentAnchorEl: null,
              }}
              data-test="duration-select"
            >
              <MenuItem value={"Hour"}>
                {t("Environment.OverviewTab.hour")}
              </MenuItem>
              <MenuItem value={"Day"}>
                {t("Environment.OverviewTab.day")}
              </MenuItem>
              <MenuItem value={"Week"}>
                {t("Environment.OverviewTab.week")}
              </MenuItem>
              <MenuItem value={"Month"}>
                {t("Environment.OverviewTab.month")}
              </MenuItem>
              {/* <MenuItem value={'Year'}>1 year</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="m-t-20" data-test="chartContainer">
        <Grid container spacing={3}>
          <Grid item sm={12} xs={12} md={12} lg={6}>
            <CPUChartCard {...getInsightsProps()} />
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={6}>
            <RAMChartCard {...getInsightsProps()} />
          </Grid>
          {props.type === "pod" && (
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <DataUsageChartCard {...getInsightsProps()} />
            </Grid>
          )}
        </Grid>
      </div>

      {props.fetchingInsightGraphData && (
        <BackdropLoader message={t("Pods.fetchingInsight")} />
      )}
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
  return {
    podInsights: state.PodsReducer.podInsights,
    fetchingPodInsights: state.PodsReducer.fetchingPodInsights,
    containerInsights: state.PodsReducer.containerInsights,
    fetchContainerInsights: state.PodsReducer.fetchContainerInsights,
    environmentInsights: state.EnvironmentReducer.environmentInsights,
    fetchingEnvInsight: state.EnvironmentReducer.fetchingEnvInsight,
    podInsightGraphData: state.PodsReducer.podInsightGraphData,
    containerInsightGraphData: state.PodsReducer.containerInsightGraphData,
    fetchingInsightGraphData: state.PodsReducer.fetchingInsightGraphData,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchPodInsights: () => dispatch(fetchPodInsights()),
    fetchContainerInsights: (envId: number, podName: string) =>
      dispatch(fetchContainerInsights(envId, podName)),
    // fetchEnvironmentInsights: (id, payload) =>
    //   dispatch(fetchEnvironmentInsights(id, payload)),
    clearEnvInsights: () => dispatch(clearEnvInsights()),
    fetchInsightGraphData: (
      envId: number,
      jsonBody: {
        start_time: number;
        end_time: number;
      }
    ) => dispatch(fetchInsightGraphData(envId, jsonBody)),
    clearInsightGraphData: () => dispatch(clearInsightGraphData()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ContainerMonitoring);
