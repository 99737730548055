export default {
    root:{
        display: 'flex', 
        alignItems:'center', 
        gap: 2,
        marginTop: 8
    },
    contained:{
        marginLeft: '0px !importtant'
    }
}