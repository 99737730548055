import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { fetchBackupRestoreList, clearBackupRestoreDetail } from '../redux/actions'
import { getDateInStandardFormat } from '../../../helpers/utils';
import { BackupStatusIndicator } from '../../../components/statusindicator/statusindicator';
import { connect,ConnectedProps } from "react-redux";
import { useTranslation } from 'react-i18next';
import BackdropLoader from "../../../components/loader/BackdropLoader";
import { Dispatch } from "redux";
import { BackUpRestoreType } from "../../../models/Environment.model";
import { StyledTableCell } from "../../../components/styledtabelcell/StyledTabelCell";
/* istanbul ignore next */
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
interface Props extends PropsFromRedux {
  isHelmEnv: boolean;
}

export function RestoresTab(props:Props) {
    // const envRole = props.isHelmEnv ? {name: "Admin"} :  props.envRole
    const environmentDetails = props.isHelmEnv ? props.helmEnvironmentDetails :  props.environmentDetails
    const classes = useStyles();
    const [ t ] = useTranslation();

    useEffect(() => {
        if(environmentDetails && environmentDetails.id > 0)
        {
            props.fetchBackupRestoreList(environmentDetails.id, props.isHelmEnv);
        }
        return(() => {
            props.clearBackupRestoreDetail()
        })
    }, [environmentDetails]);

    // useEffect(() => {
    //     let data = props.backupRestoreList?.find((backup) => backup.status != 'Completed')
    //     if(data){
    //         props.checkBackupRestoreComplete()
    //     }else{
    //         props.checkBackupRestoreCompleteFalse()
    //     }
    // });
    return (
        <div data-test="main-container">
            <div className="listContainer">
                <Typography color="textPrimary" variant="h5">
                    {t('Backup.RestoresTab.restores')}
                </Typography>
                {/* { 
                    isAuthorized("create", envRole.name) &&
                    <CustomButton
                        data-test="create-btn"
                        label="create snapshot"
                        onClick={() => handleCreateSnapshot() }
                    />
                } */}
            </div>
            <TableContainer component={Paper} data-test="snapshots-table">
                <Table className={classes.table} aria-label="simple table">
                <TableHead data-test="table-head">
                    <TableRow>
                        <StyledTableCell>{t('Backup.SnapshotsTab.snapshot')}</StyledTableCell>
                        <StyledTableCell align="left">{t('Backup.SnapshotsTab.created')}</StyledTableCell>
                        {/* <TableCell align="left">Duration</TableCell> */}
                        {/* <TableCell align="left">Time</TableCell> */}
                        {/* <TableCell align="left">Tag</TableCell> */}
                        <StyledTableCell align="left">{t('Backup.RestoresTab.initiatedBy')}</StyledTableCell>
                        <StyledTableCell align="left">{t('Backup.SnapshotsTab.status')}</StyledTableCell>
                        {/* {
                            isAuthorized("update", envRole.name) &&
                            <TableCell align="right">Actions</TableCell>
                        } */}
                    </TableRow>
                </TableHead>
                <TableBody>
                        {props.backupRestoreList && props.backupRestoreList.length > 0 && props.backupRestoreList.map((backup:BackUpRestoreType, ind:number) => {
                            //const backup = JSON.parse(item.status);
                        return (
                            <TableRow key={ind} data-test="snapshot-row">
                                <TableCell component="th" scope="row" data-test="backup-name"> { backup.name} </TableCell>
                                <TableCell data-test="backup-created" align="left">{ getDateInStandardFormat(backup.created * 1000) }</TableCell>
                                {/* <TableCell align="left">{ backup.duration }</TableCell> */}
                                {/* <TableCell align="left">{ backup.snapshot }</TableCell> */}
                                {/* <TableCell align="left">{ backup.preserved && <Chip label="preserved"  color="primary"/> }</TableCell> */}
                                {/* <TableCell align="left"><span className="activestatus"></span>{ backup.status }</TableCell> */}
                                <TableCell data-test="backup-created-by" align="left">{ backup.triggered_by_name }</TableCell>
                                <TableCell align="left">{ <BackupStatusIndicator data-test="backup-status" status={backup.status} /> }</TableCell>
                                {/* {
                                    isAuthorized("update", envRole.name) &&
                                    <TableCell align="right" data-test="backup-menu">
                                        <BackupMenu backup={ backup }
                                            handlePreserveSnapshot={ handlePreserveSnapshot }
                                            handleRestoreSnapshot={ handleRestoreSnapshot }
                                            handleDeleteSnapshot={ handleDeleteSnapshot }
                                        />
                                    </TableCell>
                                } */}
                            </TableRow>
                        )
                    })}
                </TableBody>
                </Table>
                {(!props?.backupRestoreList || props?.backupRestoreList?.length < 1) ? (
                    <div className="alignCenter" data-test="no-restores">
                      <p> {t('Backup.RestoresTab.noRestores')} </p>
                    </div>
                ) : ''}
            </TableContainer>
            { props.fetchingBackupRestoreList && <BackdropLoader message={t('Backup.RestoresTab.fetchingRestores')} />}  
        </div>
    );
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    helmEnvironmentDetails: state.HelmEnvironmentReducer.helmEnvironmentDetails,
    backupRestoreList: state.BackupReducer.backupRestoreList,
    fetchingBackupRestoreList: state.BackupReducer.fetchingBackupRestoreList
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => ({
    fetchBackupRestoreList : (eId:number, isHelm:boolean) => dispatch(fetchBackupRestoreList(eId, isHelm)),
    clearBackupRestoreDetail : () => dispatch(clearBackupRestoreDetail()),
    // checkBackupRestoreComplete : () => dispatch(checkBackupRestoreComplete()),
    // checkBackupRestoreCompleteFalse : () => dispatch(checkBackupRestoreCompleteFalse()),
});

const connector=connect(mapStateToProps, mapDispatchToProps);
export default connector(RestoresTab);
type PropsFromRedux = ConnectedProps<typeof connector>;