import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

type Props = {
  label: string,
  customClass?: string,
  type?: string,
  onClick?: any,
  disabled?:boolean,
}

const useStyles = makeStyles(theme => ({
  primaryButton: {
    background:'#0057fa',
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 38,
    minWidth: '180px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '110px',
    },
    maxWidth: '100%',
    '&:hover': {
      background: '#0057fa',
      opacity:0.9
    }
  },
  // dangerButton: {
  //   background:'#ff6565',
  //   border: 0,
  //   minWidth : '180px',
  //   maxWidth: '100%',
  //   borderRadius: 3,
  //   color: 'white',
  //   height: 38,
  //   width : '6rem',
  //   '&:hover': {
  //     background: "#ff6565",
  //     opacity:0.9
  //   }
  // },
  disabledButton: {
    background:'rgba(0, 0, 0, 0.12)',
    border: 0,
    minWidth : '180px',
    maxWidth: '100%',
    borderRadius: 3,
    color: 'white',
    height: 38,
    width : '6rem',
    '&:hover': {
      background:'rgba(0, 0, 0, 0.12)', 
      // opacity:0.9
    }
  },
}));

export default function CustomButton ({label, type='primary', customClass="" , ...rest}: Props) {
  const classes = useStyles();
  // return <Button className={ `${ customClass ? customClass : "" } ${type === 'danger' ? classes.dangerButton :rest.disabled?classes.disabledButton: classes.primaryButton}`} {...rest} data-test="main-button">{label}</Button>;
  return <Button {...rest} data-test="main-button" variant='contained' color="primary" disabled={rest.disabled}>{label}</Button>;
}