import React, { Component } from 'react'
import { withTranslation ,WithTranslation} from "react-i18next";
import { connect,ConnectedProps } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    FormControl,
    Select,
    InputAdornment,
    MenuItem,
    // OutlinedInput,
    Button,
    FormControlLabel,
    IconButton,
    Typography,
    Collapse,
    Switch,
    CardActions, createStyles, Theme, WithStyles,
    Divider
} from "@material-ui/core";
import MuiTextField from "../../components/textfield/MuiTextField";
import {
    Input as AntdInput,
    // Divider
} from 'antd';
import Cron, { CronError} from 'react-js-cron';
import "./cronjob.css";
import BackdropLoader from '../../components/loader/BackdropLoader';
import { createCronJob, getCronImages, clearCreateCronJob, fetchJobDetails, clearCronJobDetails, updateCronJob } from './redux/actions'
import toast from '../../components/toast/Toast';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router-dom';
import { Dispatch } from "redux";
import { CronImage } from '../../models/cron.model';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

/* istanbul ignore next */

const useStyles = (theme:Theme) => createStyles({
    actions: {
        display: "flex",
    },
    save: {
        marginLeft: "auto",
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
});
// import "antd/dist/antd.css";

type JsonBody={
    image: string;
    command: string | null;
    schedule: string;
    suspend: boolean;
    concurrent_policy: string;
    restart_policy: string;
    starting_deadline_seconds: number;
    name?:string|null;
}
interface Props extends PropsFromRedux,WithStyles<typeof useStyles>,WithTranslation, RouteComponentProps<{}, StaticContext>{
jobId:number|null;
createSuccessHandler:()=>void;
handleBlockingStatus:(status:boolean)=>void
}
export interface State {
    openAdvanceSettings:       boolean;
    cronExpression:            string;
    concurrent_policy:         string;
    restart_policy:            string;
    starting_deadline_seconds: string;
    image:                     string;
    command:                  string| null;
    active:                    boolean;
    source:                    number;
    isJonNameFieldError:       boolean;
    JonNameFieldErrorMessage:  string;
    isJonNameButtonDisabled:   boolean;
    changed:                   boolean;
    cronError:                 boolean;
    showSettings:              boolean;
    cronErrorMessage:          string;
    job_name:                    string|null;
    // cronExpressionRef:          React.RefObject<HTMLInputElement>;
}

export class CronJobCreate extends Component<Props,State> {
    constructor(props:Props) {
        super(props);
        this.state = {
            openAdvanceSettings: false,
            cronExpression: '',
            concurrent_policy: 'Forbid',
            restart_policy:'Never',
            starting_deadline_seconds: '',
            image: 'MyImage',
            command: null,
            active: true,
            source: 1, //Create: 1,Edit: 2
            isJonNameFieldError: false,
            JonNameFieldErrorMessage: '',
            isJonNameButtonDisabled: true,
            changed: false,
            // cronExpressionRef:  React.createRef<HTMLInputElement>()
            cronError: false,
            showSettings: false,
            cronErrorMessage: "",
            job_name:null
        }
    }

    componentDidMount() {
        this.props.getCronImages();
        if(this.props.jobId && this.props.jobId > 0)
        {
            this.props.fetchJobDetails(this.props.environmentDetails.id, this.props.jobId);
            this.setState({
                source: 2
            }, () => {
                this.setState({
                    isJonNameButtonDisabled : false
                })
            })
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps:Props) => {
        if(this.props.cronjob_createdId === -1 && newProps.cronjob_createdId !== -1)
        {
            this.props.createSuccessHandler();
        }
        if(newProps.cronjobDetails && newProps.cronjobDetails.id > 0 && !this.props.cronjobDetails)
        {
            this.setState({
                job_name: newProps.cronjobDetails.name,
                cronExpression: newProps.cronjobDetails.schedule,
                concurrent_policy: newProps.cronjobDetails.concurrent_policy, //? newProps.cronjobDetails.concurrent_policy : "Forbid",
                restart_policy: newProps.cronjobDetails.restart_policy, //? newProps.cronjobDetails.restart_policy : "Never",
                starting_deadline_seconds: newProps.cronjobDetails.starting_deadline_seconds,
                image: newProps.cronjobDetails.image ? newProps.cronjobDetails.image : "MyImage",
                command: newProps.cronjobDetails.command,
                active: !newProps.cronjobDetails.suspend,
            })
        }
    }

    componentWillUnmount() {
        this.props.clearCreateCronJob();
        this.props.clearCronJobDetails();
    }

    updateCronExpression = (value:string) => {
        this.setState({
            cronExpression: value,
            // changed: true
        })
        // if(this.state?.cronExpressionRef?.current){
        //     this.state.cronExpressionRef.current.setValue(value)
        // }

        if(this.state.source === 2 ){
            if(this.props?.cronjobDetails?.schedule !== value){
                this.setState({
                    changed: true,
                }, () => {
                    this.props.handleBlockingStatus(true)
                })
    
            } else {
                this.setState({
                    changed: false,
                }, () => {
                    this.props.handleBlockingStatus(true)
                })
            }
        }
    }
    // toggleAdvance = () => {
    //     this.setState(
    //         {
    //             openAdvanceSettings: !this.state.openAdvanceSettings,
    //             changed: true,
    //         }, () => {
    //             this.props.handleBlockingStatus(true)
    //         }
    //     );
    // };

    handleOnChangeEditor = (newValue:string) => {
        this.setState({
            command: newValue,
        }, () => {
            this.props.handleBlockingStatus(true)
        });
        // let e = {
        //     target:{
        //         name: "command",
        //         value:newValue
        //     }
        // }

        this.setState({
            command:newValue,
            changed: true,

        }as Pick<State, keyof State>, () => {
            this.props.handleBlockingStatus(true) 
            if(this.state.command === "" || this.state.image === "Select" || this.state.command === null || this.state.isJonNameFieldError){
                this.setState({
                    isJonNameButtonDisabled: true
                })
            } else{           
                this.setState({
                    isJonNameButtonDisabled: false
                })
            }
           
        })
    }

    handleOnChange = (e:React.ChangeEvent<{  name?: string | undefined; value: unknown }>) => {
      
        this.setState({
            [ e.target.name as any] : e.target.value as string,
            changed: true,
        }as Pick<State, keyof State>, () => {
            this.props.handleBlockingStatus(true) 
            if(this.state.command === "" || this.state.image === "Select" || this.state.command === null || this.state.isJonNameFieldError){
                this.setState({
                    isJonNameButtonDisabled: true
                })
            } else{           
                this.setState({
                    isJonNameButtonDisabled: false
                })
            }
           
        })
    }

    handleOnCheckedChange = (e: React.ChangeEvent<HTMLInputElement>,checked:boolean) => {
        this.setState({
            [ e.target.name as any] : e.target.checked as boolean,
            changed: true,
        } as Pick<State, keyof State>, () => {
            this.props.handleBlockingStatus(true)
        })
    }

    handleJobNameChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if(e.target.value.length > 0) {
            if(e.target.value.length > 0 && e.target.value.length < 3) {
                this.setState({
                    isJonNameButtonDisabled : true,
                    isJonNameFieldError :true,
                    JonNameFieldErrorMessage: this.props.t('Projects.ProjectsTab.tooShortError'), 
                })
            }
            else if(e.target.value.length > 100) {
                this.setState({
                    isJonNameButtonDisabled : true,
                    isJonNameFieldError :true,
                    JonNameFieldErrorMessage: this.props.t('Projects.ProjectsTab.tooLongError'), 
                })
            }
            else if(!/^[A-Za-z0-9]*$/.test(e.target.value) ){
                this.setState({
                    isJonNameButtonDisabled : true,
                    isJonNameFieldError :true,
                    JonNameFieldErrorMessage: this.props.t('CronJob.CronJobCreate.alphanumericOnly'), 
                })
            }
            else {
                this.setState({
                    isJonNameButtonDisabled: false,
                    isJonNameFieldError :false,
                    JonNameFieldErrorMessage: '', 
                })
            }
        }
        else {
            this.setState({
                isJonNameButtonDisabled : true,
                isJonNameFieldError :true,
                JonNameFieldErrorMessage: this.props.t('CronJob.CronJobCreate.emptyJobs')
            })
        }

        
        this.setState({
            [ e.target.name as any ] : e.target.value,
        }  as Pick<State, keyof State>, () => {
            this.props.handleBlockingStatus(true)
        })
        this.handleOnChange(e)
    }
    
    handleSave = () => {
        this.props.handleBlockingStatus(false)
        if(this.state.image === "Select"){
            toast.error(this.props.t('CronJob.CronJobCreate.selectValidImage'))
            return
        }
        let jsonBody:JsonBody = {
            image : this.state.image !== "Select" && this.state.image !== "MyImage" ? this.state.image : "",
            command : this.state.command,
            schedule : this.state.cronExpression,
            suspend : !this.state.active,
            concurrent_policy : this.state.concurrent_policy, // != "Select" ? this.state.concurrent_policy : "Forbid",
            restart_policy : this.state.restart_policy, // != "Select" ? this.state.restart_policy : "Never",
            starting_deadline_seconds: parseInt(this.state.starting_deadline_seconds),
           

        }

        if(this.state.source === 2 && this.props.jobId)
        {
            this.props.updateCronJob(this.props.environmentDetails.id, this.props.jobId, jsonBody, this.updateCallBack);
        }
        else
        {
            jsonBody["name" ] = this.state.job_name;
            this.props.createCronJob(this.props.environmentDetails.id, jsonBody);
        }
    }

    /* istanbul ignore next */
    updateCallBack = () => {
        this.props.createSuccessHandler();
    }

    cronError =(e:CronError) => {
        if(e?.type === "invalid_cron"){
            this.setState({
                cronError: true,
                cronErrorMessage: e?.description
            })
        } else {
            this.setState({
                cronError: false,
                cronErrorMessage: "",

            })
        }
    }
    handleShowSettings = () => {
        this.setState({
            showSettings: !this.state.showSettings
          })
        
      };

    render() {
        const { classes, t } = this.props;
        return (
            <div data-test="main-container">
                <Card className="m-b-20">
                    <CardHeader title={<Typography variant='h6'>{t('CronJob.CronJobCreate.createCronJob')}</Typography>}/>
                    <Divider />
                    <CardContent>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Card elevation={1}>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item md={4} xs={12}>
                                                    <label>{t('CronJob.CronJobCreate.jobName')} *</label>
                                                    <MuiTextField
                                                        data-test="job-name-input"
                                                        id="job_name"
                                                        name="job_name"
                                                        customClassName="oneRemMarginBottomSeperator"
                                                        type="text"
                                                        onChange={ (e) => this.handleJobNameChange(e) }
                                                        value={this.state.job_name}
                                                        error={this.state.isJonNameFieldError}
                                                        helperText={this.state.JonNameFieldErrorMessage}
                                                        disabled= {this.state.source === 2 ? true : false }
                                                        //size="small"
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <label>{t('CronJob.CronJobCreate.image')} *</label>
                                                    <FormControl variant="outlined" className="w-100">
                                                        <Select
                                                            data-test="job-image-select"
                                                            labelId=""
                                                            id=""
                                                            name='image'
                                                            onChange={ e => this.handleOnChange(e) }
                                                            MenuProps={{ disableScrollLock: true }}
                                                            value={this.state.image}
                                                        >
                                                            <MenuItem value="MyImage">{ this.props.environmentDetails 
                                                            && this.props.environmentDetails.application 
                                                            && this.props.environmentDetails.application.name}{" "}{this.props.environmentDetails 
                                                                && this.props.environmentDetails.name}{" "}image
                                                            </MenuItem>
                                                            {
                                                                this.props.cronImages && this.props.cronImages.length > 0 && this.props.cronImages.map((cimg:CronImage, ind:number) => <MenuItem value={cimg.image_name} key={ ind }>{cimg.name}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <label>{t('CronJob.CronJobCreate.expression')} * </label> 
                                                    <AntdInput
                                                        data-test="cron-input"
                                                    //   ref={this.state.cronExpressionRef}
                                                        value={this.state.cronExpression}
                                                        onBlur={(event) => {
                                                            this.updateCronExpression(event.target.value)
                                                        }}
                                                        onChange={(event) => {
                                                            this.updateCronExpression(event.target.value)
                                                        }}
                                                        allowClear={true}
                                                    />
                                                    <Typography variant="body2" color="secondary"> {this.state.cronErrorMessage} </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Cron data-test="cron-component" value={this.state.cronExpression} setValue={this.updateCronExpression}
                                                    onError={this.cronError}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card elevation={1}>
                                        <CardHeader
                                            title={<Typography variant='h6'>{t('CronJob.CronJobCreate.enterCode')} *</Typography>}
                                            action={
                                                <FormControlLabel
                                                    data-test="active-switch"
                                                    control={
                                                        <Switch
                                                            name="active"
                                                            color="primary"
                                                            checked={this.state.active}
                                                            onChange={this.handleOnCheckedChange}
                                                        />
                                                    }
                                                    label={t('CronJob.CronJobCreate.active')}
                                                />
                                            }
                                        />
                                        <Divider />
                                        <CardContent>
                                            <AceEditor
                                                data-test="editor"
                                                placeholder={t('CronJob.CronJobCreate.enterCodeHere')} 
                                                mode="javascript"
                                                theme="monokai"
                                                fontSize={14}
                                                showPrintMargin={false}
                                                width="100%"
                                                height="300px" 
                                                name="command"
                                                onChange={ (val) => this.handleOnChangeEditor(val) }
                                                value={ this.state.command ? this.state.command : "" }
                                                setOptions={{
                                                    showLineNumbers: true,
                                                    tabSize: 4
                                                }}
                                                style={{borderRadius: 16}}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card elevation={1}>
                                        <CardHeader 
                                            title={<Typography variant="h6" color="textPrimary">{t('CronJob.CronJobCreate.advanceSettings')}</Typography>}
                                            action={
                                                <IconButton
                                                    onClick={() => this.handleShowSettings()}
                                                    aria-label="Show/Hide Settings"
                                                    aria-expanded={this.state.showSettings}
                                                    className={clsx(classes.expand, {
                                                            [classes.expandOpen]: this.state.showSettings,
                                                        })}
                                                        data-test="settings-toggle"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            }
                                        />
                                        <Collapse in={this.state.showSettings} timeout={600} unmountOnExit>
                                            <Divider />
                                            <CardContent>
                                                <Grid container spacing={3}>
                                                    <Grid item md={4} xs={12}>
                                                        <label>{t('CronJob.CronJobCreate.concurrencyPolicy')}</label>
                                                        <FormControl variant="outlined" className="w-100">
                                                            <Select
                                                            labelId=""
                                                            id=""
                                                            name="concurrent_policy"
                                                            onChange={ this.handleOnChange }
                                                            MenuProps={{ disableScrollLock: true }}
                                                            value={this.state.concurrent_policy}
                                                            >
                                                                <MenuItem value="Allow">{t('CronJob.CronJobCreate.allow')}</MenuItem>
                                                                <MenuItem value="Forbid">{t('CronJob.CronJobCreate.forbid')}</MenuItem>
                                                                <MenuItem value="Replace">{t('CronJob.CronJobCreate.replace')}</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <label>{t('CronJob.CronJobCreate.restartPolicy')}</label>
                                                        <FormControl variant="outlined" className="w-100">
                                                            <Select
                                                            labelId=""
                                                            id=""
                                                            name="restart_policy"
                                                            onChange={ this.handleOnChange }
                                                            MenuProps={{ disableScrollLock: true }}
                                                            value={this.state.restart_policy}
                                                            >
                                                                {/* <MenuItem value="Select">Select</MenuItem> */}
                                                                <MenuItem value="Always">{t('CronJob.CronJobCreate.always')}</MenuItem>
                                                                <MenuItem value="OnFailure">{t('CronJob.CronJobCreate.onFaliure')}</MenuItem>
                                                                <MenuItem value="Never">{t('CronJob.CronJobCreate.never')}</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <label>{t('CronJob.CronJobCreate.deadline')}</label>
                                                        <MuiTextField
                                                            id="starting_deadline_seconds"
                                                            name="starting_deadline_seconds"
                                                            onChange={ this.handleOnChange }
                                                            value={this.state.starting_deadline_seconds}
                                                            type="number"
                                                            inputProps={{min: "0"}}
                                                            className="w-100"
                                                            InputProps={{
                                                                endAdornment:(
                                                                    <InputAdornment position="end">Seconds</InputAdornment>
                                                                )
                                                            }}
                                                        />

                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                        {/* <Grid>

                            
                            
                            <Grid container spacing={3}>
                                
                                <Grid item xs={12}>
                                <div style={{marginBottom: '1rem'}}>
                                    <label>{t('CronJob.CronJobCreate.enterCode')} *</label>
                                </div>
                                <AceEditor
                                    data-test="editor"
                                    placeholder={t('CronJob.CronJobCreate.enterCodeHere')} 
                                    mode="javascript"
                                    theme="monokai"
                                    fontSize={14}
                                    showPrintMargin={false}
                                    width="100%"
                                    height="300px" 
                                    name="command"
                                    onChange={ (val) => this.handleOnChangeEditor(val) }
                                    value={ this.state.command ? this.state.command : "" }
                                    setOptions={{
                                        showLineNumbers: true,
                                        tabSize: 4
                                    }}
                                />
                                </Grid>

                                <Grid item md={12} sm={6} xs={12} >
                                    <FormControlLabel
                                      data-test="active-switch"
                                      control={
                                          <Switch
                                            name="active"
                                            color="primary"
                                            checked={this.state.active}
                                            onChange={this.handleOnCheckedChange}
                                          />
                                    }
                                      label={t('CronJob.CronJobCreate.active')}
                                    />
                                </Grid>

                            </Grid>
        
                            <Grid className="advancediv">
                                <Link onClick={this.toggleAdvance} href="#" underline="none"> Advanced Settings </Link>

                            </Grid>

                            {this.state.openAdvanceSettings && (
                                <Grid container spacing={3}>
                                    <Grid item md={4} xs={12}>
                                        <label>Concurrency Policy</label>
                                        <FormControl variant="outlined" className="w-100">
                                            <Select
                                                labelId=""
                                                id=""
                                                name="concurrent_policy"
                                                onChange={ this.handleOnChange }
                                                value={this.state.concurrent_policy}
                                            >
                                                <MenuItem value="Select">Select</MenuItem>
                                                <MenuItem value="Allow">Allow</MenuItem>
                                                <MenuItem value="Forbid">Forbid</MenuItem>
                                                <MenuItem value="Replace">Replace</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <label>Restart Policy</label>
                                        <FormControl variant="outlined" className="w-100">
                                            <Select
                                                labelId=""
                                                id=""
                                                name="restart_policy"
                                                onChange={ this.handleOnChange }
                                                value={this.state.restart_policy}
                                            >
                                                <MenuItem value="Select">Select</MenuItem>
                                                <MenuItem value="Always">Always</MenuItem>
                                                <MenuItem value="OnFailure">OnFailure</MenuItem>
                                                <MenuItem value="Never">Never</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <label>Starting deadline seconds</label>

                                        <OutlinedInput

                                            id="starting_deadline_seconds"
                                            name="starting_deadline_seconds"
                                            onChange={ this.handleOnChange }
                                            value={this.state.starting_deadline_seconds}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{min: "0"}}
                                            className="w-100"
                                            endAdornment={<InputAdornment position="end">Seconds</InputAdornment>}
                                        />

                                    </Grid>
                                </Grid>
                            )}
                        </Grid> */}
                    </CardContent>
                    {/* <CardActions>
                        <Typography variant="h6" color="primary">{t('CronJob.CronJobCreate.advanceSettings')}</Typography>
                        <IconButton
                          onClick={() => this.handleShowSettings()}
                          aria-label="Show/Hide Settings"
                          aria-expanded={this.state.showSettings}
                          className={clsx(classes.expand, {
                                [classes.expandOpen]: this.state.showSettings,
                            })}
                            data-test="settings-toggle"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={this.state.showSettings} timeout={600} unmountOnExit>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={4} xs={12}>
                                    <label>{t('CronJob.CronJobCreate.concurrencyPolicy')}</label>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                          labelId=""
                                          id=""
                                          name="concurrent_policy"
                                          onChange={ this.handleOnChange }
                                          MenuProps={{ disableScrollLock: true }}
                                          value={this.state.concurrent_policy}
                                        >
                                            <MenuItem value="Select">Select</MenuItem>
                                            <MenuItem value="Allow">{t('CronJob.CronJobCreate.allow')}</MenuItem>
                                            <MenuItem value="Forbid">{t('CronJob.CronJobCreate.forbid')}</MenuItem>
                                            <MenuItem value="Replace">{t('CronJob.CronJobCreate.replace')}</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <label>{t('CronJob.CronJobCreate.restartPolicy')}</label>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                          labelId=""
                                          id=""
                                          name="restart_policy"
                                          onChange={ this.handleOnChange }
                                          MenuProps={{ disableScrollLock: true }}
                                          value={this.state.restart_policy}
                                        >
                                            <MenuItem value="Select">Select</MenuItem>
                                            <MenuItem value="Always">{t('CronJob.CronJobCreate.always')}</MenuItem>
                                            <MenuItem value="OnFailure">{t('CronJob.CronJobCreate.onFaliure')}</MenuItem>
                                            <MenuItem value="Never">{t('CronJob.CronJobCreate.never')}</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <label>{t('CronJob.CronJobCreate.deadline')}</label>
                                    
                                    <OutlinedInput

                                      id="starting_deadline_seconds"
                                      name="starting_deadline_seconds"
                                      onChange={ this.handleOnChange }
                                      value={this.state.starting_deadline_seconds}
                                      variant="outlined"
                                      type="number"
                                      inputProps={{min: "0"}}
                                      className="w-100"
                                      endAdornment={<InputAdornment position="end">Seconds</InputAdornment>}
                                    />
                                    <label>{t('CronJob.CronJobCreate.deadline')}</label>
                                    <MuiTextField
                                    id="starting_deadline_seconds"
                                    name="starting_deadline_seconds"
                                    onChange={ this.handleOnChange }
                                    value={this.state.starting_deadline_seconds}
                                    type="number"
                                    inputProps={{min: "0"}}
                                    className="w-100"
                                    InputProps={{
                                        endAdornment:(
                                            <InputAdornment position="end">Seconds</InputAdornment>
                                        )
                                    }}
                                    />

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Collapse> */}

                </Card>
                {
                    //isAuthorized("update", role.name) && 
                    <div className={classes.actions}>
                        <Button
                          data-test="create-btn"
                          variant="contained"
                          color="primary"
                          className={classes.save}
                          onClick={() => this.handleSave()}
                          disabled={ this.state.isJonNameButtonDisabled || this.state.cronExpression === "" || (!this.state.changed && this.state.source === 2) || (this.state.cronError) } 
                        >
                            { this.state.source === 2 ? t('CronJob.CronJobCreate.save') : t('CronJob.CronJobCreate.create') }
                        </Button>
                    </div>
                }
                { this.props.isSavingCronJob && <BackdropLoader message={ this.state.source === 2 ? t('CronJob.CronJobCreate.updating') :  t('CronJob.CronJobCreate.creating') } data-test="saving-loader" />}
                { this.state.source === 2 && !this.props.cronjobDetails && <BackdropLoader message={t('CroJob.CronJobCreate.jobDetails')} data-test="fetching-loader" />}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    cronImages: state.CronJobReducer.cronImages,
    isSavingCronJob: state.CronJobReducer.isSavingCronJob,
    cronjob_createdId: state.CronJobReducer.cronjob_createdId,
    cronjobDetails: state.CronJobReducer.cronjobDetails
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        getCronImages: () => dispatch(getCronImages()),
        createCronJob: (id:number, jsonBody:JsonBody) => dispatch(createCronJob(id, jsonBody)),
        clearCreateCronJob: () => dispatch(clearCreateCronJob()),
        clearCronJobDetails: () => dispatch(clearCronJobDetails()),
        fetchJobDetails: (eId:number, cId:number) => dispatch(fetchJobDetails(eId, cId)),
        updateCronJob:  (eId:number, cId:number, jsonBody:JsonBody, callback:()=>void) => dispatch(updateCronJob(eId, cId, jsonBody, callback))
    };
};
const connector=connect(
    mapStateToProps,
    mapDispatchtoProps
)
export default connector(withRouter(withStyles(useStyles)(withTranslation()(CronJobCreate))));
type PropsFromRedux = ConnectedProps<typeof connector>;