import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Divider, Grid } from "@material-ui/core";
import "./addonspopup.css";
import { useTranslation } from "react-i18next";
// type defn
import { createStyles, Theme, WithStyles } from "@material-ui/core";
import AddonInstallFormNew from "../../pages/environment/environmentinfo/addonstab/addonintallform/AddonInstallForm_New";
import palette from "../../theme/palette";
import { StyledDivider } from "../dashedDivider/DashedDivider";

/* istanbul ignore next */
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    imageContainer: {
      height: 64,
      width: 64,
      margin: "0 auto",
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: "5px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      minWidth: 280,
    },
    addonsCardDivider:{
      marginTop: 20, 
    }
  });

  interface Props extends WithStyles<typeof styles> {
    children: JSX.Element;
    onClose: () => void;
    id: string;
  }
  // interface AddonProps {
  //   createdat: string;
  //   UpdatedAt?: string;
  //   DeletedAt?: null;
  //   name: string;
  //   description?: string;
  //   source_url?: string;
  //   image?: string;
  //   active: boolean;
  //   support_ci?: boolean;
  //   min_cpu?: number;
  //   min_memory?: number;
  //   is_add_on?: boolean;
  //   service_detail?: { [key: string]: string };
  //   AddOns?: any;
  //   attributes?: string;
  //   Categories?: any[]; // possibly {id:number}
  // }
  interface AddonsPopUpProps extends WithStyles<typeof styles> {
    addon?: any; 
    // Addon Props
    source: number;
    open: boolean;
    setOpen: (openStatus: boolean) => void;
    action: (payload?: any) => void;
    installed?: boolean; 
    envAddon?: any; // fix me later , 
    // fetchPluginVersions?: () => void;  unused prop
 
  }
/* istanbul ignore next */
export const DialogTitle = withStyles(styles)((props: Props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

/* istanbul ignore next */
export const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

/* istanbul ignore next */
export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const AddonsPopUp = (props: AddonsPopUpProps) => {
  const { classes } = props;
  // const [open, setOpen] = React.useState(false);
  const [payload, setPayload] = useState<any>(null);
  const [error, setError] = useState(true);
  const { addon, source, open } = props;
  const [t] = useTranslation();
  // const handleClickOpen = () => {
  //   if (props.fetchPluginVersions && props.addon.id)
  //     props.fetchPluginVersions(props?.addon.id);
  //   props.setOpen(true);
  // };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleContinue = (_payload: any, _error: boolean) => {
    // fix me later any type
    setError(_error);
    setPayload(_payload);
  };

  // useEffect(() => {
  //   if(props.addon && props.fetchPluginVersions){
  //     props.fetchPluginVersions(props.addon?.id)
  //   }
  // }, [props.addon])

  useEffect(() => {
    if (props.source === 2) {
      props.action();
    }
  }, [props.source]);

  const addonAction = () => {
    if (payload) {
      props.action(payload);
    } else {
      props.action();
    }

    props.setOpen(false);
  };

 

  return (
    <div>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        disabled={
          ((addon.status && addon.status !== "Running") ||
            (addon.installed && !addon.status)) &&
          !(addon.installed && addon.error)
        }
      >
        {source === 1 && "Install"}
        {source === 2 && "View Details"}
      </Button> */}
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="sm"
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ marginTop: 50 }}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <div className="imageContainer">
                <img
                  src={addon && addon.image}
                  alt={addon && addon.name}
                  className="addonimage"
                  data-test="title-image"
                />
              </div>
            </Grid>
            <Grid item>
              <Typography variant="h6" data-test="title-text">
                {addon && addon.name}
              </Typography>
              {/* <Typography display="inline" variant="body2">
                Updated 2hr ago
              </Typography> */}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div>
            {source === 1 && (
              <>
                {/* <Typography gutterBottom variant="body1">
                  {" "}
                  {addon && addon.description}{" "}
                </Typography> */}

                {/* <Typography gutterBottom variant="body2">
              <Link href={addon?.source_url} target="_blank" underline="always">
                Read More...{" "}
              </Link>
              </Typography> */}
                <div>
                  {!props?.installed && (
                    <AddonInstallFormNew
                      addon={addon}
                      addonId={addon.id}
                      handleContinue={!props.installed ? handleContinue : null}
                    />
                  )}
                </div>
              </>
            )}
            {source === 2 && (
              <Typography data-test="uninstall-text">
                `Are You sure that you want to uninstall the addon, $
                {addon && addon.name}?`
              </Typography>
            )}
            {source === 3 && (
              <AddonInstallFormNew
                addon={addon}
                addonId={addon.id}
                handleContinue={handleContinue}
                envAddon={props.envAddon}
              />
            )}
          </div>
          {/* <Divider className={classes.addonsCardDivider}/> */}
          <StyledDivider className={classes.addonsCardDivider}/>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={addonAction}
            color="primary"
            variant="contained"
            disabled={error}
            data-test="action-button"
          >
            {source === 1 && t("Addons.install")}
            {source === 2 && t("Addons.details")}
            {source === 3 && t("Addons.modify")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(AddonsPopUp);
