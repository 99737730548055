import React, { Component } from 'react'
import './environmentinfo.css';
import { Tabs, Grid ,Button, Menu, MenuItem , Divider } from '@material-ui/core';
//import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Tab from '@material-ui/core/Tab';
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
//import Box from '@material-ui/core/Box';
// import LogsTab from './logstab/LogsTab';
import OverviewTab from './overviewtab/OverviewTab';
// import InsightTab from './insighttab/InsightTab';
import CicdTab from './cicdtab/CicdTab';
import SettingsTab from './settingstab/SettingsTab';
import AddonsTab from './addonstab/AddonsTab';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { fetchEnvDetails, fetchEnvRole, updateEnvName } from '../redux/actions'
import { fetchRoles } from '../../../components/adduserpopup/redux/actions'
import { coreConversion, spaceCoversion, getErrorInfo } from '../../../helpers/utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deleteEnvironment, clearEnvironmentdetails, fetchEnvironmentStateInitiate, fetchEnvironmentState, changeEnvTabValue, reCreateEnvironment, getAddonStatus, setCurrentEnvironment, clearCurrentEnvironment, startEnvironment, stopEnvironment, fetchBuildImages, rollbackEnv, initiateFetchPackageStatus } from '../redux/actions'
import { fetchPluginVersions, clearPlugInVersions } from '../../app/createapp/redux/actions'
import {  fetchPluginDetailsById, clearPluginDetails } from '../../app/redux/actions'
import { switchOrg } from "../../organization/redux/actions";
import ConfirmActionPopup from '../../../components/confirmactionpopup/ConfirmActionPopup'
import ConfirmDeletePopup from '../../../components/confirmdeletepopup/ConfirmDeletePopup'
import { updateBreadcrumb } from '../../project/redux/actions'
import { setCurrentProject } from '../../project/redux/actions'
import {isAuthorized} from '../../../helpers/utils'
//import { w3cwebsocket as W3CWebSocket } from "websocket";
import { sessionTokenSelector } from '../../login/redux/selectors';
import Skeleton from 'react-loading-skeleton'
import BackdropLoader from '../../../components/loader/BackdropLoader';
import TabsSkeleton from '../../../components/skeletons/TabsSkeleton';
import CronJobList from '../../cronjob/CronJobList'
import RecreatePopup from './RecreatePopup'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MemoryOutlinedIcon from '@material-ui/icons/MemoryOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import StraightenOutlinedIcon from '@material-ui/icons/StraightenOutlined';
import WebsocketConnection from '../../../containers/WebsocketConnection'
import RollbackPopup from './RollbackPopup';
import BackupTab from '../../backup/BackupTab';
import { EnvSettingTabs } from "../../../constants/environmentTabs";
import { CreateApp_TemplateTypes, CustomColors } from '../../../constants/enums';
import { DateHandler } from '../../../components/dateHandler/DateHandler';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { AppConstants } from '../../../constants/appconstants';
import NoContentImage from '../../../components/nocontentimagecontainer/NoContentImage';
import paths from '../../../constants/paths';
import { LabelHandler } from '../../../components/labelHandler/LabelHandler';
import {TabPanel} from "../../../components/tabpanel/TabPanel"
import PodsOverview from "../../pods/PodsOverview"
import CloneEnvironment from './CloneEnvironment';
// import { getProgressIcon } from '../../../helpers/utils.ext';
import EnvRenameDialog from '../../../components/appEnvRename/EnvRenameDialog';
import AlertManager from './alertmanager/AlertManager';
import Security from './security/Security';
// import { grey } from '@material-ui/core/colors';
import palette from '../../../theme/palette';
import { TitleHandler } from '../../../components/titleHandler/TitleHandler';
//import moment from 'moment';

/* istanbul ignore next */
const useStyles = (theme) => ({
  header: {
    margin:"0px 50px 0px 0px"
  },
  tab:{
    minWidth:'fit-content'
  },
  envTitle: {
    padding: "5px 20px 5px 0px",
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxWidth: 150,
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
  },
  progress:{
    display:'flex',
    alignItems:'center'
  },
  menuItem: {
    margin: '4px',
    '&:hover': { 
      backgroundColor: theme.palette.action.hover, 
  },
},
});

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

// function handleClick(event) {
//   event.preventDefault();

// }

export class EnvironmentInfo extends Component {
  //dummyData = []

  constructor(props){
        super(props);
        this.state = {
          isConfirmPopupOpen: false,
          isRecreateConfirmPopupOpen: false,
          value : this.props.envTabValue,
          // ws: null,
          currentVersions: null,
          environmentId: 0,
          stopEnvPopupOpen: false,
          startEnvPopupOpen: false,
          rollbackPopup: false,
          innerTab: 0,
          isTabIndexUpdated: false,
          isCloneEnv: false,
          ErrorInfo:null,
          openRenameDialog: false,
          isAlertOpen: true,
        }
  }
  
  updateInnerTab = (value) => {
    this.setState({ innerTab: value })
  }

  handleMenuClick = (event) => {
      this.setState({ anchorEl : event.currentTarget });
  };

  handleMenuClose = () => {
      this.setState({ anchorEl : null });
  };

  handleDeleteEnv = () => {
    this.handleMenuClose();
      this.setState({
          isConfirmPopupOpen : true
      })
  }

  handleDisAgreeHandler = () => {
      this.setState({
          isConfirmPopupOpen : false
      })
  }

  handleAgreeHandler = () => {
    this.props.deleteEnvironment(this.props.environmentDetails.id, this.props.environmentDetails ? this.props.environmentDetails.application_id : 0, this.props.history)
    this.setState({
        isConfirmPopupOpen : false
    })
  }

  handleRecreateEnv = () => {
    this.handleMenuClose();
    this.props.fetchPluginVersions(this.props.environmentDetails.application.plugin_id)
    this.props.fetchPluginDetailsById(this.props.environmentDetails.application.plugin_id);

    this.setState({
      isRecreateConfirmPopupOpen : true
    })
  }

  redeployEnv = () =>{
    this.props.history.push({
      pathname: paths.EDITOPERATORENVIRONMENT.replace(":eId", this.props.environmentDetails.id) 
    }
    )
  }

  handleRecreateDisAgreeHandler = () => {
      this.setState({
        isRecreateConfirmPopupOpen : false
      })
  }

  handleRecreateAgreeHandler = (data) => {
      const {environmentDetails} = this.props
      if(environmentDetails?.version){
        // const initialVersion = environmentDetails.version.name
        // if(initialVersion !== data?.version?.name){
          const payload = {
            ...data
          }
        this.props.reCreateEnvironment(this.props.environmentDetails.id, payload,  environmentDetails.git_url === "" ? null : this.changeHash)
        // }
      }

      this.setState({
        isRecreateConfirmPopupOpen : false
      })
  }

  handleEnvRollback = () => {
    this.handleMenuClose();
    this.setState({
      rollbackPopup: true
    }) 
  }

  handleRollbackAgree = (payload) => {
    this.setState({
      rollbackPopup: false
    })
    this.props.rollbackEnv(this.props.environmentDetails.id, payload, this.changeHash)
  }

  handleRollbackDisagree = () => {
    this.setState({
      rollbackPopup: false
    })
  }

    componentDidMount(){
      this.props.fetchRolesAction();
      //this.getTabIndexbyName(this.props.location.hash)
      this.initialize(this.props.match.params.eId.split('#')[0]);
      if(this.props.environmentDetails && this.props.environmentDetails.application){
        // this.props.fetchPluginVersions(this.props.environmentDetails?.application?.plugin_id)
        // this.props.fetchPluginDetailsById(this.props.environmentDetails.application.plugin_id)
      }
      if(this.props.environmentDetails?.version && this.props.environmentDetails?.other_version){
        this.manageVersions(this.props.environmentDetails.version, this.props.environmentDetails.other_version)       
      }
      if(this.props.environmentDetails?.application?.project?.organization_id > 0 &&  this.props.environmentDetails?.service_type >= 0){
        this.props.initiateFetchPackageStatus(this.props.environmentDetails?.id)
      }
  }

    getTabIndexbyName = () => {
      let _hash = "";
      if(this.props.location.hash)
      {
        _hash = this.props.location.hash;
      }
      else
      {
        const hash = this.props.location.pathname && this.props.location.pathname.trim().split("#");
        if(hash && hash.length === 2){
          _hash = "#" + hash[1];
        }
      }
      if (_hash) {
        const splitHash = _hash.split("_");
        if (splitHash.length > 1) {
          const subIndex = EnvSettingTabs(splitHash[0], this.props.environmentDetails).indexOf(splitHash[1]);
          this.updateInnerTab(subIndex > -1 ? subIndex : 0);
        }
      
        const index = this.getTabPanelIndex(splitHash[0]);
        if(index > -1)
          this.handleChange(null, parseInt(index));
      }
    }

    getTabPanelIndex = (name) => {
      const tabPanel = document.getElementsByName(name);
      if(tabPanel && tabPanel.length > 0)
      {
        const id = tabPanel[0].id;
        return id.split('-').pop();
      }
    }

    ///envId parameter to be passed only if environment need to be changed. 
    ///envId is passed from cloning, as we navigate to new environmen
    changeHash = (hash, val, envId,delay) => {
      const index = parseInt(this.getTabPanelIndex(`#${hash}`))
      this.setState({
        value: index,
      }, () => {
        this.props.changeEnvTabValue(index)
        if(delay){
         setTimeout(()=>{
          this.props.history.push({
            pathname: paths.ENVIRONMENTINFO.replace(":eId", envId ? envId : this.props.environmentDetails?.id) + "#" + hash,
            state: { value: val ? val : 0 }
          }
          );
         },200)
        }else{
          this.props.history.push({
            pathname: paths.ENVIRONMENTINFO.replace(":eId", envId ? envId : this.props.environmentDetails?.id) + "#" + hash,
            state: { value: val ? val : 0 }
          }
          );
        }
      })
    }

    navigatescheduleEnv = () =>{
      const subIndex = EnvSettingTabs("#settings", this.props.environmentDetails).indexOf(AppConstants.EnvSettingsTabs.scheduler);
      this.changeHash("settings", subIndex)
      this.handleMenuClose();
    }

    handleChange = (event, newValue) => {
      this.setState({
          value : newValue
      })
      this.props.changeEnvTabValue(newValue)
      if(event)
      {
        const tabPanelObj = document.getElementById('simple-tabpanel-' + newValue);
        if(tabPanelObj)
        {
          const name = tabPanelObj.getAttribute("name");
          if(name)
          {
            this.props.history.push({
              pathname : paths.ENVIRONMENTINFO.replace(":eId", this.props.environmentDetails?.id) + name
            });
          }
        }
      }
    };

    initialize = (id) => {
      this.props.clearEnvironmentdetails()
      this.props.fetchEnvDetails(id)
      //this.props.fetchUserListInEnvironmentAction(id)
      //this.props.fetchEnvLogs(id, "")
      this.props.fetchEnvironmentStateInitiate(id, this.props?.history?.location?.state?.initial);
      this.props.setCurrentEnvironment(id);
      // this.props.getAddonStatus(id);
      this.props.fetchEnvRole(id)
      // this.props.fetchBuildImages(id)
      // this.props.changeEnvTabValue(0);
      //this.socketConnection(id)
      this.setState({
        environmentId: id
      })
    }

    switchOrgCallback = () => {
      this.props.fetchEnvRole(this.state.environmentId)
    }
  
  breadcrumbUpdate = (newProps) => {
    const { environmentDetails } = newProps;
    const breadcrumbData = [
      { name: environmentDetails.application.project.name, path: '/project/' + environmentDetails.application.project_id },
      { name: environmentDetails.application.name, path: '/app/' + environmentDetails.application.id },
      { name: environmentDetails.name, path: '/environment/' + environmentDetails.id }
    ]
    this.props.updateBreadcrumb(breadcrumbData);
  }

    UNSAFE_componentWillReceiveProps = (newProps) => {
      if(this.props.match.params.eId.split('#')[0] !== newProps.match.params.eId.split('#')[0])
      {
        this.initialize(newProps.match.params.eId.split('#')[0]);
      }
      
      const prevHash = this.props.match.params.eId.split('#');
      const newHash = newProps.match.params.eId.split('#');
      if(newHash.length > 1)
      {
        if(prevHash.length <= 1 || prevHash[1] !== newHash[1])
        {
          const index = this.getTabPanelIndex("#" + newHash[1]);
          if(index > -1)
            this.handleChange(null, parseInt(index));
        }

      }
      // if(this.state.value !== newProps.envTabValue){
      //   this.handleChange(null, newProps.envTabValue)
      // }
      // if( newProps?.environmentDetails?.application?.plugin_id > 0 && newProps?.versionsList?.plugin_id !== newProps.environmentDetails?.application?.plugin_id){
        
        // this.props.fetchPluginVersions(newProps.environmentDetails.application.plugin_id)
        // this.props.fetchPluginDetailsById(newProps.environmentDetails.application.plugin_id);
      // }

      if(newProps.environmentDetails?.version && newProps.environmentDetails?.other_version){
        this.manageVersions(newProps.environmentDetails.version, newProps.environmentDetails.other_version)
      }

      if(newProps.environmentDetails.id > 0)
      {
        if(!this.props.environmentDetails.id || this.props.environmentDetails.id !== newProps.environmentDetails.id)
        {
          const { environmentDetails } = newProps;
          this.breadcrumbUpdate(newProps);
          this.props.setCurrentProject(environmentDetails.application.project_id);
          this.props.setCurrentEnvironment(environmentDetails.id);
          if(environmentDetails?.application?.project?.organization_id > 0 && environmentDetails?.service_type >= 0){
            this.props.initiateFetchPackageStatus(environmentDetails?.id)
          }

          const org_id = environmentDetails?.application?.project?.organization_id;
          if (org_id) {
            if (!this.props.currentOrganization || this.props.currentOrganization.id.toString() !== org_id) {
              this.props.switchOrg(org_id, null, null, 0, this.switchOrgCallback);
            }
          }
          else if (this.props.currentOrganization?.id) {
            this.props.switchOrg(0, null, null, 0, this.switchOrgCallback);
          }
          setTimeout(this.getTabIndexbyName, 200);
            // if(this.props.match.params.isCreate === 't')
            //   this.handleChange(null, newProps.environmentDetails.git_url != "" ? 3 : 2)
        }
        if (newProps.environmentDetails && newProps.environmentDetails.error_message && newProps.environmentDetails.error_message.code) {
         
          if(!this.hasEnvErrored()){
            this.setState({ErrorInfo:getErrorInfo(newProps.environmentDetails.error_message)})
          }else {
           if( newProps.environmentDetails.error_message.code!==this.props.environmentDetails.error_message.code){
            this.setState({ErrorInfo:getErrorInfo(newProps.environmentDetails.error_message)})
           }
          }
        }
        if(this.props.environmentDetails.name !== newProps.environmentDetails.name){
          this.breadcrumbUpdate(newProps);
        }
      }
      if(!this.props.reCreationTriggered && newProps.reCreationTriggered)
      {
        this.props.history.push({
          pathname : paths.ENVIRONMENTINFO.replace(":eId", this.props.environmentDetails?.id) + "#overview"
        });
        const index = parseInt(this.getTabPanelIndex("#overview"));
        this.setState({
          value : index
        })
        this.props.changeEnvTabValue(index);

        // setTimeout(() => {
        //   this.props.fetchEnvironmentState(this.props.environmentDetails.id);
        // }, 5000);
      }
    }

    componentWillUnmount(){
      this.props.clearEnvironmentdetails()
      this.props.clearPlugInVersions();
      this.props.clearPluginDetails();
      // if(this.state.ws !== null){
      //   this.state.ws.close();
      // }
    }

    // socketConnection = (id) => {
    //   if(this.state.ws != null) return;
    //     const sessionToken = this.props.validSessionId;
    //     var ws = new W3CWebSocket(window?.config?.REACT_APP_SOCKET_IO_ENDPOINT + "?token="+ sessionToken + "&room=env-" + id);
    //     this.setState({
    //       ws
    //     })
    //   ws.onopen = () => {
    //       console.log('WebSocket Client Connected');
    //       // let timer = setInterval( () => this.updatedummystate(), 500);
    //       this.setState({ ws: ws });
    //   };

    //   ws.onclose = e => {
    //       console.log('WebSocket closed');
    //       this.setState({ ws: null });
    //   };

    //   ws.onerror = err => {
    //       console.log('WebSocket error');
    //       ws.close();
    //       this.setState({ ws: null })
    //   };

    // }

    manageVersions = (main, other) => {    
      const mainVersion =  typeof main !== "object" ? JSON.parse(main) : main
      const otherVersion =  typeof other !== "object" ? JSON.parse(other) : other
      let currentVersions = {}
      currentVersions.main = mainVersion.name
      otherVersion.forEach(_v => {
        currentVersions[_v.deployment_name] = _v.name
      })
      this.setState({
        currentVersions
      })

    }

    startEnvCall = () => {
      this.handleMenuClose();
      this.setState({
        startEnvPopupOpen: true
      })
    }

    startEnvDisagreeHandler = () => {
      this.setState({
        startEnvPopupOpen: false
      })
    }

    startEnvAgreeHandler = () => {
      this.props.startEnvironment(this.props.environmentDetails.id)
      this.setState({
        startEnvPopupOpen: false
      })
    }

    stopEnvCall = () => {
      this.handleMenuClose();
      this.setState({
        stopEnvPopupOpen: true
      })
    }

    stopEnvDisagreeHandler = () => {
      this.setState({
        stopEnvPopupOpen: false
      })
    }

    stopEnvAgreeHandler = () => {
      this.props.stopEnvironment(this.props.environmentDetails.id, this.changeHash  ,this.props.history)
      this.setState({
        stopEnvPopupOpen: false
      })
    }
  
  getTabIndexes = () => {
    // refractored ternary operators to if blocks
    let tabIndexes={}
  if (this.props.environmentDetails) {
    const { service_type } = this.props.environmentDetails;

    if (service_type <= 0 || service_type === CreateApp_TemplateTypes.manageDB) {
      tabIndexes= {
        Overview: 0,
        Insight: 1,
        // Logs: 2,
        Addons: 2,
        Backup: 3,
        Alert: 4,
        Security: 5,
        Settings:6,
      };
    }else if (service_type === 4) {
      tabIndexes= {
        Overview: 0,
        // Logs: 1,
        Addons: 1,
        Backup: 2,
        Security: 3,
        Settings:4,
      };
    } else{
      tabIndexes= {
        Overview: 0,
        Insight: 1,
        Cicd: 2,
        Addons: 3,
        Jobs: 4,
        Backup: 5,
        Alert: 6,
        Security: 7,
        Settings: 8,
      };
    }
  
  }
  return tabIndexes

};

  cloneEnvironment = () => {
    this.setState({
      isCloneEnv: true
    })
    this.handleMenuClose();
  }

  cancelCloneEnv = () => {
    this.setState({
      isCloneEnv: false
    })
  }

  hasEnvErrored = () => {
    let hasEnvErrored = false;
    const { environmentDetails } = this.props
    if (environmentDetails && environmentDetails.error_message && environmentDetails.error_message.code) {
      hasEnvErrored = environmentDetails.id === environmentDetails.error_message.environment_id
    }
    return hasEnvErrored
  }

  getSubIndex=(errorInfo)=>{
    return EnvSettingTabs(errorInfo.tabName, this.props.environmentDetails).indexOf(errorInfo.subTab);
  }
  navigateToErrorTab = (errorInfo) => () => {
    if (!errorInfo || !errorInfo.isNavigatable || !errorInfo.tabName) {
      return
    }
    const subIndex = this.getSubIndex(errorInfo)
    if (subIndex<1) return
    this.changeHash(errorInfo.tabName.substring(1),subIndex,null,true)
  }

  showNavigation=(errorInfo)=>{
    if (!errorInfo || this.getSubIndex(errorInfo)<1) return false
   const currentHash= new URL(document.URL).hash
   const navHash=errorInfo.subTab? `${errorInfo.tabName}_${errorInfo.subTab}` :errorInfo.tabName
   const hashMatch=currentHash===navHash

   return !hashMatch && errorInfo.isNavigatable
  }

  handleEnvRename = () => {
    this.setState({ 
      anchorEl : null,
      openRenameDialog: true,
    });
  }

  handleCloseDialog = () => {
    this.setState({ openRenameDialog: false })
  }

  handleNameSubmit = (envName) => {
    const id = this.props.match.params.eId.split('#')[0];
    const name = { name: envName }
    if(envName !== this.props.environmentDetails.name){
      this.props.updateEnvName(id, name);
    }
    this.setState({ openRenameDialog: false })
  }

shouldRenderTab=(tabIndexes,tabIndexesProp)=>{

return !!tabIndexes[tabIndexesProp] && tabIndexes[tabIndexesProp] > -1
}

  hideAlert = () => {
    this.setState({
      isAlertOpen: false,
    });
  };

    render () {
      const { environmentDetails, environmentState, classes, envRole, t } = this.props
      const  {ErrorInfo,isAlertOpen}=this.state
      //const cname = environmentState && environmentState.State == "Running" && environmentState.CName != "" && "https://" + environmentState.CName;
      const _versionObj = environmentDetails.version ?? "";
      // let statusColor = getStatusColor(environmentState.state);

      // let _backup = false
      // let _cicd = false

      // if(this.props.packageStatus && environmentDetails?.application?.project?.organization_id > 0){
        // if(this.props.packageStatus[AppConstants.packages.VELERO] === AppConstants.PackageStatus.Installed){
    
        //   _backup = true
        // }
        // if(this.props.packageStatus[AppConstants.packages.TEKTON] === AppConstants.PackageStatus.Installed  ){
        //   _cicd = true
        // }
        // if (!this.state.isTabIndexUpdated
        //   && this.props.packageStatus[AppConstants.packages.VELERO]
        //   && this.props.packageStatus[AppConstants.packages.TEKTON]
        //   //&& (_backup || _cicd)
        // ) {
        //   this.setState({ isTabIndexUpdated: true })
        //   setTimeout(this.getTabIndexbyName, 200);
        // }
      // }

      // else if (environmentDetails?.application?.project?.organization_id === 0)
      // {
      //   _backup = true;
      //   _cicd = true;
      // }
      // if(this.props.environmentDetails?.service_type <= 0){
      //   _backup = false;
      // }
    
      let tabIndexes = this.getTabIndexes();

        return (
            <div data-test="environmentInfoContainer">
                <WebsocketConnection envId={ this.state.environmentId} />
                <div style={{ display: this.state.isCloneEnv ? "none": ""}}>
                { this.props.isDataNotFound &&
                    <Grid container justify="center" alignItems="center" className="notFoundGrid" data-test="noDataFound">
                        <NoContentImage
                          type="404"
                          message={t('Environment.EnvironmentInfo.notFoundEnv')}
                          alt="No environment details"
                        />
                    </Grid>
                }
                { !this.props.isDataNotFound &&
                <div data-test="dataFoundContainer">
                    <TitleHandler 
                      title={environmentDetails && environmentDetails.name ? environmentDetails.name :(<Skeleton width={150} />)} 
                      image={ environmentDetails && environmentDetails.application && environmentDetails.application.plugin ?
                                <img src={environmentDetails.application.plugin.image} alt='Plugin' height={25} className="pluginImg" data-test="environmentPluginImage" />
                              : (<Skeleton circle={false} height={25} width={25} />) 
                              }
                      status={environmentState.state  ? environmentState.state : <Skeleton circle={true} height={10} width={10}  /> }  
                      actionItem={
                                    <>
                                        { 
                                          (isAuthorized("update", envRole.name) || isAuthorized("delete", envRole.name)) &&
                                          <Button variant="contained" color="primary" endIcon={<ExpandMoreIcon/>} disableElevation disableFocusRipple disableTouchRipple
                                          aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleMenuClick(e)}
                                          data-test="environmentActionsButton" disabled={!environmentState.state}
                                          >
                                          {t("Projects.ProjectInfo.actionsLabel")}
                                          </Button>
                                        }
                                          <Menu
                                            id="simple-menu"
                                            disableScrollLock={true}
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={() => this.handleMenuClose()}
                                            //className={classes.profileMenu}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            elevation={0}
                                          >
                                            <MenuItem onClick={() => this.handleEnvRename()}>Rename</MenuItem>
                                            {isAuthorized("update", envRole.name) && environmentState.state !== AppConstants.EnvironmentStatus.Stopped && environmentDetails.service_type !== CreateApp_TemplateTypes.cr &&  environmentDetails.service_type !== CreateApp_TemplateTypes.op &&  environmentDetails.service_type !== CreateApp_TemplateTypes.manageDB &&(
                                                  <MenuItem onClick={() => this.handleRecreateEnv()} data-test="recreateOption">{t('Environment.EnvironmentInfo.recreate')}</MenuItem>
                                            )}
                                            {isAuthorized("update", envRole.name) && environmentState.state === AppConstants.EnvironmentStatus.Running &&
                                                  <MenuItem onClick={() => this.stopEnvCall()} data-test="stopOption">{t('Environment.EnvironmentInfo.stop')}</MenuItem>
                                            }

                                            {environmentState.state !== AppConstants.EnvironmentStatus.Stopped &&   environmentDetails.service_type === CreateApp_TemplateTypes.op &&
                                                  <MenuItem onClick={() => this.redeployEnv()} data-test="redeployOption">{t("Operators.OperatorCatalog.redeploy")}</MenuItem>
                                            }
                                            
                                            {isAuthorized("update", envRole.name) && environmentState.state === AppConstants.EnvironmentStatus.Stopped && 
                                                  <MenuItem onClick={() => this.startEnvCall()} data-test="startOption">{t('Environment.EnvironmentInfo.start')}</MenuItem>
                                            }
                                            {isAuthorized("update", envRole.name) && environmentState.state !== AppConstants.EnvironmentStatus.Stopped && environmentDetails.service_type === CreateApp_TemplateTypes.git 
                                            &&
                                            <MenuItem onClick={() => this.handleEnvRollback()} data-test="rollbackOption">{t('Environment.EnvironmentInfo.rollBack')}</MenuItem>
                                            }

                                            {(environmentState.state !== AppConstants.EnvironmentStatus.Stopped) && environmentDetails.service_type !== CreateApp_TemplateTypes.op && environmentDetails.service_type !== CreateApp_TemplateTypes.manageDB &&
                                              <MenuItem onClick={() => this.navigatescheduleEnv()} data-test="schedulerOption">Scheduler</MenuItem>
                                            }
                                      
                                      { isAuthorized("update", envRole.name) &&
                                        environmentState.state !== AppConstants.EnvironmentStatus.Stopped &&
                                        environmentDetails.service_type !== CreateApp_TemplateTypes.helm &&
                                        environmentDetails.service_type !== CreateApp_TemplateTypes.op &&
                                        <MenuItem onClick={() => this.cloneEnvironment()} data-test="cloneOption">{t('Environment.Clone.title')}</MenuItem>
                                            }

                                      {isAuthorized("delete", envRole.name) && (
                                        <MenuItem onClick={() => this.handleDeleteEnv()} data-test="deleteOption">{t("Environment.EnvironmentInfo.deleteLabel")}</MenuItem>
                                      )}
                                          </Menu>
                                    </>
                                  } 
                    />
                    <Grid container variant="outlined" className={classes.header} data-test="environmentDetailsContainer">

                        {/* <Grid item md={12} xs={12}>
                            <Grid container direction="row" spacing={2}  alignItems="center">
                                <Grid item>

                                    {/* <DashboardIcon color='primary' className='projectIcon' /> */}
                                    {/* { environmentDetails && environmentDetails.application && environmentDetails.application.plugin ?
                                        <img src={environmentDetails.application.plugin.image} alt='Plugin' height={25} className="pluginImg" data-test="environmentPluginImage" />
                             : (<Skeleton circle={false} height={25} width={25} />) }

                                </Grid> */}
                                {/* <Grid item xs>
                                    <Grid container direction="column">
                                        <Grid container direction="row" alignItems="center">
                                          <Tooltip title={environmentDetails && environmentDetails.name ? environmentDetails.name : ""} arrow>
                                            <Typography variant="h4" className={classes.envTitle} data-test="environmentName">  {environmentDetails && environmentDetails.name ? environmentDetails.name : (<Skeleton width={150} />)}
                                            </Typography>
                                          </Tooltip>
                                            <Typography variant="h4" >
                                                <span className={classes.progress}>
                                                    <Tooltip title={t('Environment.EnvironmentInfo.state')}>
                                                        <>
                                                        {
                                        environmentState.state ? 
                                        getProgressIcon(environmentState.state,statusColor) :
                                              <Skeleton circle={true} height={10} width={10}  /> 
                                        }
                                                        </>
                                                    </Tooltip>
                                                <span style={{ color: statusColor }} className="statusTxt"> {environmentState.state ? environmentState.state : <Skeleton width={80} /> }</span>
                                                </span>
                                            </Typography>

                                            {/* <span className='envSubDetails'>
                                  <span>
                                    <Tooltip title="Uptime">
                                      { environmentDetails && environmentDetails.createdat ?
                                        (<ScheduleIcon className='topIcon'/>) :
                                        (<Skeleton circle={true} height={10} width={10} /> )
                                      }
                                    </Tooltip>
                                    <Typography color="textPrimary" variant="body2">{environmentDetails.createdat ? getDiffDays(environmentDetails.createdat) : (<Skeleton width={80} />)}</Typography>
                                  </span>
                                  <span>
                                    <Tooltip title="Version">
                                      {
                                      ( _versionObj && _versionObj.name )|| (environmentDetails && environmentDetails.plugin_version) ?
                                        <SettingsIcon className='topIcon'/> :
                                        (<Skeleton circle={true} height={10} width={10} />)
                                      }
                                    </Tooltip>
                                    <Typography color="textPrimary" variant="body2">{ _versionObj && _versionObj.name ? _versionObj.name : (environmentDetails && environmentDetails.plugin_version && environmentDetails.plugin_version.version ? environmentDetails.plugin_version.version : (<Skeleton width={80} />)) }

                                    </Typography>
                                  </span>

                              </span> */}

                                            {/* <span className='envSubDetails'>
                                <span>
                                  <Typography color="primary">
                                    <Link href={ cname } target="_blank" rel="noreferrer" underline='always' color="primary">
                                      { environmentState && environmentState.CName }
                                    </Link>
                                  </Typography>
                                </span>
                              </span> */}
                                        {/* </Grid> */}
                                        {/* <Grid item>
                                <Typography variant="caption">{environmentDetails ? environmentDetails.createdat : ""}</Typography>
                              </Grid> */}

                                        {/* <Grid item>
                              <Typography variant="caption">{ environmentDetails && environmentDetails.application && environmentDetails.application.project ? environmentDetails.application.project.name : ""} </Typography>
                            </Grid> */}
                                    {/* </Grid>
                                </Grid>
                        <Grid item>
                              { 
                                 (isAuthorized("update", envRole.name) || isAuthorized("delete", envRole.name)) &&
                                <Button variant="contained" color="primary" endIcon={<ExpandMoreIcon/>} disableElevation disableFocusRipple disableTouchRipple
                                aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleMenuClick(e)}
                                data-test="environmentActionsButton" disabled={!environmentState.state}
                                >
                                {t("Projects.ProjectInfo.actionsLabel")}
                                </Button>
                              }
                                <Menu
                                  id="simple-menu"
                                  disableScrollLock={true}
                                  anchorEl={this.state.anchorEl}
                                  open={Boolean(this.state.anchorEl)}
                                  onClose={() => this.handleMenuClose()}
                                  //className={classes.profileMenu}
                                      getContentAnchorEl={null}
                                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                  <MenuItem onClick={() => this.handleEnvRename()}>Rename</MenuItem>
                                  {isAuthorized("update", envRole.name) && environmentState.state !== AppConstants.EnvironmentStatus.Stopped && environmentDetails.service_type !== CreateApp_TemplateTypes.cr &&  environmentDetails.service_type !== CreateApp_TemplateTypes.op &&  environmentDetails.service_type !== CreateApp_TemplateTypes.manageDB &&(
                                        <MenuItem onClick={() => this.handleRecreateEnv()} data-test="recreateOption">{t('Environment.EnvironmentInfo.recreate')}</MenuItem>
                                  )}
                                  {isAuthorized("update", envRole.name) && environmentState.state === AppConstants.EnvironmentStatus.Running &&
                                        <MenuItem onClick={() => this.stopEnvCall()} data-test="stopOption">{t('Environment.EnvironmentInfo.stop')}</MenuItem>
                                  }

                                  {environmentState.state !== AppConstants.EnvironmentStatus.Stopped &&   environmentDetails.service_type === CreateApp_TemplateTypes.op &&
                                        <MenuItem onClick={() => this.redeployEnv()} data-test="redeployOption">{t("Operators.OperatorCatalog.redeploy")}</MenuItem>
                                  }
                                  
                                  {isAuthorized("update", envRole.name) && environmentState.state === AppConstants.EnvironmentStatus.Stopped && 
                                        <MenuItem onClick={() => this.startEnvCall()} data-test="startOption">{t('Environment.EnvironmentInfo.start')}</MenuItem>
                                  }
                                  {isAuthorized("update", envRole.name) && environmentState.state !== AppConstants.EnvironmentStatus.Stopped && environmentDetails.service_type === CreateApp_TemplateTypes.git 
                                  // &&  this.props.buildImages?.data?.length > 0
                                  &&
                                  <MenuItem onClick={() => this.handleEnvRollback()} data-test="rollbackOption">{t('Environment.EnvironmentInfo.rollBack')}</MenuItem>
                                  }

                                  {(environmentState.state !== AppConstants.EnvironmentStatus.Stopped) && environmentDetails.service_type !== CreateApp_TemplateTypes.op && environmentDetails.service_type !== CreateApp_TemplateTypes.manageDB &&
                                    <MenuItem onClick={() => this.navigatescheduleEnv()} data-test="schedulerOption">Scheduler</MenuItem>
                                  }
                            
                            { isAuthorized("update", envRole.name) &&
                              environmentState.state !== AppConstants.EnvironmentStatus.Stopped &&
                              environmentDetails.service_type !== CreateApp_TemplateTypes.helm &&
                              environmentDetails.service_type !== CreateApp_TemplateTypes.op &&
                              <MenuItem onClick={() => this.cloneEnvironment()} data-test="cloneOption">{t('Environment.Clone.title')}</MenuItem>
                                  }

                            {isAuthorized("delete", envRole.name) && (
                              <MenuItem onClick={() => this.handleDeleteEnv()} data-test="deleteOption">{t("Environment.EnvironmentInfo.deleteLabel")}</MenuItem>
                            )}
                                </Menu>
                        </Grid>
                            </Grid>

                        </Grid>  */}

                        <Grid container spacing={3} className="p-tb-20">
                            {this.props.environmentDetails?.service_type !== undefined && this.props.environmentDetails?.service_type !== CreateApp_TemplateTypes.op &&
                            <Grid item className="topgridalign">
                              <LabelHandler 
                                icon={<SettingsOutlinedIcon style={{color: palette.text.primary, opacity: '32%'}} />}
                                iconTooltip={t('Environment.EnvironmentInfo.version')}
                                label={_versionObj?.name ?? environmentDetails?.plugin_version?.version}
                                labelType='Grey'
                              />
                            </Grid>
                            }

                            <Grid item  className="topgridalign">
                              <LabelHandler 
                                icon={<StraightenOutlinedIcon style={{color: CustomColors.Info.Dark}} />}
                                iconTooltip={t('Environment.EnvironmentInfo.ram')}
                                label={environmentDetails?.resource && spaceCoversion(environmentDetails.resource.memory)}
                                data-test="ram-resource-label"
                                labelType='Info'
                              />
                            </Grid>
                            <Grid item  className="topgridalign">
                              <LabelHandler 
                                icon={<MemoryOutlinedIcon style={{color: CustomColors.Success.Dark}} />}
                                iconTooltip={t('Resource.ResourceForm.coresLabel')}
                                label={environmentDetails?.resource && coreConversion(environmentDetails.resource.cores)}
                                data-test="core-resource-label"
                                labelType='Success'
                              />
                            </Grid>
                            <Grid item className="topgridalign">
                              <LabelHandler 
                                icon={<LanguageOutlinedIcon style={{color: CustomColors.Warning.Dark}} />}
                                iconTooltip={t('Projects.LoadbalancerInfo.regionLabel')}
                                label={environmentDetails?.application?.cluster?.name}
                                labelTooltip={environmentDetails?.application?.cluster?.zone}
                                labelType='Warning'
                              />
                            </Grid>
                            <Grid item className="topgridalign">
                              <DateHandler date={environmentDetails.createdat}  icon={<ScheduleIcon className="labelsvgicon" style={{color: CustomColors.Secondary.Dark}}/>} labelType='Secondary'/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider/>

                  {tabIndexes && ErrorInfo && isAlertOpen &&
                    <Alert severity="error" color='error'  onClose={this.hideAlert}>
                    <Typography variant='subtitle1'>
                    { ErrorInfo.msg}
                    </Typography>
                    {this.showNavigation(ErrorInfo) &&
                      <span onClick={this.navigateToErrorTab(ErrorInfo)}>
                        <Typography style={{ cursor: 'pointer', color: "blue", textDecoration: 'underline' }} variant='subtitle1'>
                        Go to {ErrorInfo.settingsName}
                        </Typography>
                      </span>
                    } 
                    </Alert>
                  }

                    <div className='tabsContainer' data-test="tabsContainer">
                        {
                    environmentDetails && environmentDetails.name ? (
                      (<Tabs value={this.state.value} onChange={this.handleChange}  aria-label="simple tabs example" indicatorColor="primary" variant="scrollable" data-test="environment-details-tabs">
                          <Tab label={t('Environment.EnvironmentInfo.overview')} data-test="overview" {...a11yProps(0)} className={classes.tab} />
                      {this.shouldRenderTab(tabIndexes,"Insight") &&
                            <Tab label={t('Environment.EnvironmentInfo.insight')} data-test="insight" {...a11yProps(1)} className={classes.tab} disabled={environmentState.state === AppConstants.EnvironmentStatus.Stopped} />
                      }
                      {this.shouldRenderTab(tabIndexes,"Cicd")&&
                            <Tab label={t('Environment.EnvironmentInfo.ci/cd')} data-test="ci/cd" {...a11yProps(2)} className={classes.tab} disabled={environmentState.state === AppConstants.EnvironmentStatus.Stopped} />
                        }
                          {/* <Tab label="Activity" {...a11yProps(2)} /> */}
                          {/* <Tab label={t('Environment.EnvironmentInfo.logs')} data-test="logs" {...a11yProps(3)} className={classes.tab} disabled={environmentState.State === AppConstants.EnvironmentStatus.Stopped} /> */}
                          <Tab label={t('Environment.EnvironmentInfo.addons')} data-test="addons" {...a11yProps(4)} className={classes.tab} disabled={environmentState.state === AppConstants.EnvironmentStatus.Stopped} />
                          { this.shouldRenderTab(tabIndexes,"Jobs") &&
                            <Tab label={t('Environment.EnvironmentInfo.jobs')} data-test="jobs" {...a11yProps(5)} className={classes.tab} disabled={environmentState.state === AppConstants.EnvironmentStatus.Stopped} />
                        }
                      {this.shouldRenderTab(tabIndexes,"Backup") && (
                            <Tab label={t('Environment.EnvironmentInfo.backup')} data-test="backup" {...a11yProps(6)} className={classes.tab} disabled={environmentState.state === AppConstants.EnvironmentStatus.Stopped}/>
                        )}

                      <Tab label="Alert" {...a11yProps(7)} className={classes.tab} disabled={environmentState.state === AppConstants.EnvironmentStatus.Stopped} />

                      <Tab label="Security" {...a11yProps(8)} className={classes.tab}  disabled={environmentState.state === AppConstants.EnvironmentStatus.Stopped} />

                      {this.shouldRenderTab(tabIndexes,"Settings") &&
                            <Tab label={t('Environment.EnvironmentInfo.settings')} data-test="settings" {...a11yProps(9)} className={classes.tab} disabled={environmentState.state === AppConstants.EnvironmentStatus.Stopped} />
                      }

                       </Tabs>)

                    ) : (
                        <TabsSkeleton />
                    )
                  }
                        <div className='horizontalLine m-b-20' />
                        <TabPanel value={this.state.value} index={ tabIndexes.Overview } name="#overview" tabPanel="simple-tabpanel"  data-test="overview-tabpanel">
                            <OverviewTab changeHash={this.changeHash} startEnv={this.startEnvCall} />
                        </TabPanel>
                        <TabPanel className='removePadding' value={this.state.value} index={ tabIndexes.Insight } name="#insight" tabPanel="simple-tabpanel">
                            {/* <InsightTab /> */}
                            <PodsOverview environmentId={environmentDetails.id} />
                        </TabPanel>
                        {/* {
                         environmentDetails && environmentDetails.service_type > 0 && */}
                      <TabPanel value={this.state.value} index={ tabIndexes.Cicd } name="#cicd" tabPanel="simple-tabpanel">
                    <CicdTab history={{...this.props.history}} innerTab={this.state.innerTab} updateInnerTab={this.updateInnerTab} changeHash={this.changeHash} />
                      </TabPanel>
                    {/* } */}
                        {/* <TabPanel value={this.state.value} index={ tabIndexes.Activity } name="#activity">
                        <ActivityTab ws={this.state.ws} data={this.dummyData} />
                    </TabPanel> */}
                    {/* <TabPanel value={this.state.value} index={ tabIndexes.Logs } name="#logs" tabPanel="simple-tabpanel">
                        <LogsTab />
                    </TabPanel> */}
                    <TabPanel value={this.state.value} index={ tabIndexes.Addons } name="#addons" tabPanel="simple-tabpanel">
                    <AddonsTab history={this.props.history} changeHash={this.changeHash} />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={ tabIndexes.Jobs } name="#jobs" tabPanel="simple-tabpanel">
                      <CronJobList history={ this.props.history }/>
                    </TabPanel>
                    
                    <TabPanel value={this.state.value} index={tabIndexes.Backup  } name="#backup" tabPanel="simple-tabpanel">
                    <BackupTab history={this.props.history} innerTab={this.state.innerTab} updateInnerTab={this.updateInnerTab} />
                    </TabPanel>

                    <TabPanel value={this.state.value} index={tabIndexes.Alert  } name="#alert" tabPanel="simple-tabpanel">
                    <AlertManager history={this.props.history} innerTab={this.state.innerTab} updateInnerTab={this.updateInnerTab} />
                    </TabPanel>

                    <TabPanel value={this.state.value} index={ tabIndexes.Security } name="#security" tabPanel="simple-tabpanel">
                      <Security/>
                    </TabPanel>

                    <TabPanel value={this.state.value} index={tabIndexes.Settings} name="#settings" tabPanel="simple-tabpanel" className={environmentState.state === AppConstants.EnvironmentStatus.Stopped ? "avoid-clicks" : "" }>
                    <SettingsTab history={{...this.props.history}} changeHash={this.changeHash} innerTab={this.state.innerTab} updateInnerTab={this.updateInnerTab} />
                    </TabPanel>

                    </div>
                {/* <Grid item xs>
                    <div>
                        <Button color="secondary" size="large" startIcon={<HighlightOffRoundedIcon />} onClick={() => this.handleDeleteEnv()}>
                            <Typography >Delete this Environment</Typography>
                        </Button>
                    </div>
                </Grid> */}

                    {this.state.startEnvPopupOpen && (
                    <ConfirmActionPopup open={ this.state.startEnvPopupOpen } handleAgree={ this.startEnvAgreeHandler } handleDisAgree={ this.startEnvDisagreeHandler } message={t('Environment.EnvironmentInfo.startEnvMessage')} yesText={t('Projects.VariablesTab.yesText')} noText={t('Projects.VariablesTab.yesText')} data-test="start-env-confirm" />
            )}
                    {this.state.isConfirmPopupOpen && (
                    <ConfirmDeletePopup open={this.state.isConfirmPopupOpen} handleAgree={this.handleAgreeHandler} handleDisAgree={this.handleDisAgreeHandler}  yesText={t('Projects.VariablesTab.yesText')} noText={t('Projects.VariablesTab.yesText')} toMatchName={environmentDetails.name} toDeleteModule="environment" loading={this.props.deletingEnv} data-test="delete-env-confirm" divider={true}/>
            )}
                    {this.state.stopEnvPopupOpen && (
                    <ConfirmDeletePopup open={this.state.stopEnvPopupOpen} handleAgree={this.stopEnvAgreeHandler} handleDisAgree={this.stopEnvDisagreeHandler}  yesText={t('Projects.VariablesTab.yesText')} noText={t('Projects.VariablesTab.yesText')} toMatchName={environmentDetails.name} toDeleteModule="environment" loading={this.props.stoppingEnv} message={t('Environment.EnvironmentInfo.stopEnvMessage')} action="stop" data-test="stop-env-confirm" /> 
            )}
                    {this.state.isRecreateConfirmPopupOpen && (
                    <RecreatePopup open={this.state.isRecreateConfirmPopupOpen} handleAgree={this.handleRecreateAgreeHandler} handleDisAgree={this.handleRecreateDisAgreeHandler} message={t('Environment.EnvironmentInfo.recreateEnvMessage')} yesText={t('Environment.EnvironmentInfo.recreate')} noText={t('Projects.ProjectsTab.cancelLabel')} version={environmentDetails && environmentDetails.version && environmentDetails.version.name } currentVersions={this.state.currentVersions} data-test="recreate-env-confirm" />
            )}
                    {this.state.rollbackPopup && (
                    <RollbackPopup open={this.state.rollbackPopup} handleAgree={this.handleRollbackAgree} handleDisAgree={this.handleRollbackDisagree} message={t('Environment.EnvironmentInfo.rollbackEnvMessage')} yesText={t('Environment.EnvironmentInfo.rollback')} noText={t('Projects.ProjectsTab.cancelLabel')} envId={this.state.environmentId} repository_image={this.props.environmentDetails?.repository_image} data-test="rollback-env-confirm" />
            )}
                    { this.props.deletingEnv && <BackdropLoader message={t('Environment.EnvironmentInfo.deletingMessage')} data-test="deleting-loader" />}
                    { this.props.reCreationTriggered && <BackdropLoader message={t('Environment.EnvironmentInfo.recreatingMessage')} data-test="recreating-loader" />}
                    { this.props.stoppingEnv && <BackdropLoader message={t('Environment.EnvironmentInfo.stoppingMessage')} data-test="stopping-loader" />}
                    { this.props.startingEnv && <BackdropLoader message={t('Environment.EnvironmentInfo.startingMessage')} data-test="starting-loader" />}
                    { this.props.rollingBack && <BackdropLoader message={t('Environment.EnvironmentInfo.initiatingMessage')} data-test="initiating-loader" />}
                    {this.props.isExternalUrlLoading && <BackdropLoader message={t('Environment.EnvironmentInfo.updateExternalurl')} data-test="initiating-loader" />}
                </div>
              }
                </div>
            {
              this.state.isCloneEnv &&
              <CloneEnvironment cancelCloning={this.cancelCloneEnv} history={this.props.history} changeHash={this.changeHash} />
            }

            { this.state.openRenameDialog &&
            <EnvRenameDialog
            openRenameDialog={this.state.openRenameDialog}
            handleCloseDialog = {this.handleCloseDialog}
            envName = {this.props.environmentDetails.name}
            handleNameSubmit = {this.handleNameSubmit}
            />
            }            
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  environmentMetadata: state.EnvironmentReducer.environmentMetadata,
  environmentState: state.EnvironmentReducer.environmentState,
  isDataNotFound: state.EnvironmentReducer.isDataNotFound,
  envTabValue: state.EnvironmentReducer.envTabValue,
  envRole: state.EnvironmentReducer.envRole,
  validSessionId: sessionTokenSelector(state),
  reCreationTriggered: state.EnvironmentReducer.reCreationTriggered,
  deletingEnv: state.EnvironmentReducer.deletingEnv,
  versionsList: state.CreateAppReducer.versionsList,
  startingEnv: state.EnvironmentReducer.startingEnv,
  stoppingEnv: state.EnvironmentReducer.stoppingEnv,
  rollingBack: state.EnvironmentReducer.rollingBack,
  buildImages: state.EnvironmentReducer.buildImages,
  packageStatus: state.EnvironmentReducer.packageStatus,
  currentOrganization: state.AuthReducer.currentOrganization,
  isExternalUrlLoading: state.EnvironmentReducer.isExternalUrlLoading
})

/* istanbul ignore next */
const mapDispatchtoProps = dispatch => {
  return {
    fetchEnvDetails : (payload) => dispatch(fetchEnvDetails(payload)),
    // fetchUserListInEnvironmentAction : (evironmentId) => dispatch(fetchUserListInEnvironment(evironmentId)),
    fetchRolesAction : () => dispatch(fetchRoles()),
    //fetchEnvLogs : (id, duration) => dispatch(fetchEnvLogs(id, duration)),
    //startStopEnvironment : (id, isActive) => dispatch(startStopEnvironment(id, isActive)),
    deleteEnvironment: (id, app_id, history) => dispatch(deleteEnvironment(id, app_id, history)),
    updateBreadcrumb : (breadcrumbData) => dispatch(updateBreadcrumb(breadcrumbData)),
    clearEnvironmentdetails : () => dispatch(clearEnvironmentdetails()),
    fetchEnvironmentStateInitiate: (id, initial) => dispatch(fetchEnvironmentStateInitiate(id, initial)),
    fetchEnvironmentState: (id) => dispatch(fetchEnvironmentState(id)),
    setCurrentProject: (id) => dispatch(setCurrentProject(id)),
    changeEnvTabValue : (id) => dispatch(changeEnvTabValue(id)),
    fetchEnvRole: (id) => dispatch(fetchEnvRole(id)),
    reCreateEnvironment: (id, payload, changeHash) => dispatch(reCreateEnvironment(id, payload, changeHash)),
    fetchPluginVersions : (id) => dispatch(fetchPluginVersions(id)),
    fetchPluginDetailsById : (id) => dispatch(fetchPluginDetailsById(id)),
    getAddonStatus : (id) => dispatch(getAddonStatus(id)),
    setCurrentEnvironment : (id) => dispatch(setCurrentEnvironment(id)),
    clearCurrentEnvironment : () => dispatch(clearCurrentEnvironment()),
    startEnvironment : (id) => dispatch(startEnvironment(id)),
    stopEnvironment : (id, changeHash, history) => dispatch(stopEnvironment(id, changeHash, history)),
    fetchBuildImages : (id) => dispatch(fetchBuildImages(id)),
    rollbackEnv : (id, payload, changeHash) => dispatch(rollbackEnv(id, payload, changeHash)),
    clearPlugInVersions: () => dispatch(clearPlugInVersions()),
    clearPluginDetails: () => dispatch(clearPluginDetails()),
    initiateFetchPackageStatus: (id) => dispatch(initiateFetchPackageStatus(id)),
    switchOrg: (id, history, path, source, dependencyCallback) => dispatch(switchOrg(id, history, path, source, dependencyCallback)),
    //fetchEnvironmentState: (id) => dispatch(fetchEnvironmentState(id)),
    updateEnvName: (id, name) => dispatch(updateEnvName(id, name))
  }
}

export default connect(
mapStateToProps,
mapDispatchtoProps
)(withStyles(useStyles)(withTranslation()(EnvironmentInfo)))
