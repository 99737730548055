import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core"
import React from "react"
import { getStatusColor } from "../../helpers/utils"
import { getProgressIcon } from "../../helpers/utils.ext"
import Skeleton from 'react-loading-skeleton'

type Props = {
    title: string 
    status?: string
    domainName?: string
    image?: JSX.Element
    actionItem?: JSX.Element | any
}

const useStyles = makeStyles((theme) => ({
	envTitle: {
        padding: "5px 20px 5px 0px",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
          maxWidth: 150,
          overflow: "hidden",
          textOverflow: "ellipsis"
        },
    },
}));

export const TitleHandler = (props: Props) => {
    const classes = useStyles();
    let statusColor = getStatusColor(props.status);
    return(
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <Grid container direction="row" spacing={2}  alignItems="center">
                    <Grid item>
                        {props.image}
                    </Grid>
                    <Grid item xs>
                        <Grid container direction="column">
                            {!props.domainName && 
                                <Grid container direction="row" alignItems="center">
                                    <Tooltip title={props.title} arrow>
                                        <Typography variant="h3" className={classes.envTitle}>{props.title ? props.title : (<Skeleton width={150} />)}</Typography>
                                    </Tooltip>
                                    {props.status &&
                                        <Typography variant="h4">
                                            <span>
                                                <Tooltip title={props.status || ''}>
                                                    <>
                                                    {props.status ? 
                                                        getProgressIcon(props.status,statusColor) :
                                                        <Skeleton circle={true} height={10} width={10}  /> }
                                                    </>
                                                </Tooltip>
                                            </span>
                                            <span style={{ color: statusColor }} className="statusTxt">{props.status ? props.status : <Skeleton width={80} />}</span>
                                        </Typography>
                                    }
                                </Grid>
                            }
                            {props.domainName && 
                                <>
                                    <Grid item>
                                        <Typography
                                            variant="h3"
                                            className="projectTitle"
                                        >
                                            {props.title ? props.title : <Skeleton width={100} data-test="noname_skeleton" />}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            color="textPrimary"
                                            variant="body2"
                                        >
                                        {props.domainName ? props.domainName : <Skeleton width={100} />}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        {props.actionItem}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}