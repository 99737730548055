import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Collapse,
  Divider,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  editIcon: {
    border: "3px dashed",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme?.transitions?.create("transform", {
      duration: theme?.transitions?.duration?.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeader: {
    padding: "0 !important",
  },
  cardHeaderOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  skeletonContainer: {
    padding: "10px",
  },
}));

type Props = {
  defaultExpand: boolean,
  title: string,
  children: JSX.Element
}

export const ContainerCollapse = (props: Props) => {
  const [expanded, setExpanded] = useState(props.defaultExpand ?? false);

  const classes = useStyles();

  return (
    <Card className="m-t-20" data-test="collapse-card-container">
      <CardHeader
        className={classes.cardHeader}
        title={
          <div className={classes.cardHeaderOptions}>
            <Typography variant="h6" display="inline">
              <strong>{props.title}</strong>
            </Typography>
          </div>
        }
        avatar={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={() => {
              setExpanded(!expanded);
            }}
            data-test="expand-button"
          >
            <ExpandMoreIcon color="primary"/>
          </IconButton>
        }
      />
      <Collapse in={expanded}>
        <Divider />
        <CardContent>
          {React.cloneElement(props.children, { expanded })}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ContainerCollapse;
