import palette from "../palette";

export default {
  root: {
    borderRadius: '8px !important',
    boxSizing: 'border-box', 
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${palette.background.neutral} !important`,
      borderWidth: '1px',
      fontSize: '12px',
      lineHeight: '12px'
    },
    '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      borderColor: `${palette.background.neutral} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${palette.background.primary} !important`,
      borderWidth: '1px',
      fontSize: '12px',
    },
    '&.MuiFilled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${palette.background.neutral} !important`,
    },
  },
};
