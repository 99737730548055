import React, { Component } from 'react'
// import { withTranslation } from 'react-i18next';
import {
         withStyles,
         Grid,
         Paper,
         Typography,
         Link,
        //  ButtonBase,
         IconButton,
         Button,
       } from '@material-ui/core';
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect ,ConnectedProps} from 'react-redux';
import { fetchPluginsList, clearPlugInListData } from './redux/actions';
//import { pluginSelection } from '../redux/actions'
import { compose } from 'redux';
import paths from "../../../constants/paths"
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Skeleton } from "@material-ui/lab";
import { CreateApp_TemplateTypes } from '../../../constants/enums'
import './createapp.css';
import { withTranslation,WithTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component';
import BackdropLoader from '../../../components/loader/BackdropLoader';
import SearchField from '../../../components/searchfield/SearchField';
import { currentOrgRole } from "../../login/redux/selectors";
import { isAuthorizedOrg } from '../../../helpers/utils';
import BackButton from '../../../components/button/BackButton';
import { createStyles, WithStyles } from "@material-ui/core";
 import { StaticContext } from 'react-router';
 import { RouteComponentProps } from 'react-router';
 import { Dispatch } from "redux";
import { PluginModel } from '../../../models/Environment.model';
/* istanbul ignore next */
const useStyles = () =>createStyles ({
    root:{
        rowGap: 40,
    },
    container:{
        marginTop: 20,
        minHeight : '20rem',
    },
    paperContainer: {
        height: 344,
        width: '100%',
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems  : 'center',
    },
    icon:{
        height: 100,
        width: 100
    },

    // iconContainer: {
    //  //flex : 1,
    //     marginTop:'10px',
    //  alignItems: 'center',
    //  display: 'flex',
    //  //flexDirection: 'column',
    //  //justifyContent: 'flex-end',
    //     height: '80px',
    //     width: '80px'
    // },
    iconContainer: {
        width: '100%',
        height: 344,
        '&:hover': {  
            backgroundColor: 'transparent', 
        },
        '&:focus': {
            backgroundColor: 'transparent',  
        },
    },
    buttonsContainer : {
        display: 'flex', 
        flexDirection: 'column',
        flex : 1,
        justifyContent : 'space-between',
        padding : '1rem',
    },
    selectTemplateTypography: {
        marginTop : '1rem',
        textTransform: 'none'
    },
});

interface Props extends PropsFromRedux,  WithStyles<typeof useStyles>,RouteComponentProps<{tType:string,pId:string,source:string}, StaticContext>,WithTranslation {

}
interface State{
    page:number,size:number,search:string,
}

export class PluginSelection extends Component<Props,State> {
    constructor(props:Props){
        super(props);
        this.state = {
            page: 1,
            size: 20,
            search: "",
        }
    }

    componentDidMount(){
        this.fetchPluginsList()
        //this.props.fetchPluginsListAction( this.props.match.params.tType !== CreateApp_TemplateTypes.default.toString() ? true : false);
    }

    fetchMorePlugins = () => {
        this.setState({
            page: this.state.page + 1
        }, () => this.fetchPluginsList())
    }

    fetchPluginsList(){
        if(this.props.match.params.tType === CreateApp_TemplateTypes.default.toString()){
            this.props.fetchPluginsListAction( 
                false,
                this.state.page, 
                this.state.size,
                this.state.search,
                false
            )
        }
        else if(this.props.match.params.tType === CreateApp_TemplateTypes.manageDB.toString()){
            this.props.fetchPluginsListAction( 
                false,
                this.state.page, 
                this.state.size,
                this.state.search,
                true
            )
        }
        else{
            this.props.fetchPluginsListAction( 
                true,
                this.state.page, 
                this.state.size,
                this.state.search,
                false
            )
        }
    }

    handleSearchChange = (st:string) => {
        this.setState({
            search: st
        })
    }

    handleSearch = () => {
        this.fetchPluginsList()
    }

    handlePluginClick = (id:number )=> {
        //this.props.pluginSelection(id);
        this.props.history.push({
            pathname: paths.CREATEAPP_SELECTREGION.replace(':pId', this.props.match.params.pId).replace(':tType', this.props.match.params.tType).replace(':source', this.props.match.params.source).replace(':plugId', id.toString()),
          });
    }

    isResetValid = () => {
        if(this.state.search === ""
        ) return false;
        return true;
    } 

    resetFilters = () => {
        this.setState({
            page: 1,
            search: "",
        }, () => this.fetchPluginsList());
    }

    componentWillUnmount(){
        this.props.clearPlugInListData();
    }
    
    render () {
        const { classes,pluginsList,t, currentOrganization } = this.props;
        
        return (
            <div data-test="main-container">
            <BackButton
                clickHandler={() => this.props.history.goBack()}
                data-test='backButton'
            />

            <Typography 
                gutterBottom 
                variant="h5" color="textPrimary"
                data-test="select-label"
                style={{marginLeft: 10}}
            >
                {t('App.PluginSelection.selectPlugin')}
            </Typography>

                {/* search */}
                <div 
                style={{marginTop: -55}}
                >
                <Grid
                    container
                    spacing={2}
                    justify="flex-end"
                >

                    {this.isResetValid() && (
                        <Grid item xs={12} sm={2} md={1} data-test="grid">
                            <Grid container justify="flex-end">
                                <IconButton onClick={() => this.resetFilters()} data-test="reset">
                                <Typography variant="h5">
                                {t('Support.SupportPage.reset')}{" "}
                                </Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={6} sm={4} md={3}>
                        <SearchField
                            label={t('App.PluginSelection.searchPlugin')}
                            handleSearch = {this.handleSearch}
                            handleSearchChange= {this.handleSearchChange}
                            search = {this.state.search}
                            data-test="search-box"
                        />
                        {/* <MuiTextField
                        className="w-100"
                        id="searchText"
                        label={t('App.PluginSelection.searchPlugin')}
                        name="searchText"
                        style={{ width: "100%", marginTop: '0px' }}
                        color="primary"
                        onChange={(e) => this.handleSearch(e)}
                        value={this.state.search}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" edge="end">
                                <SearchIcon size="small" />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        data-test="search-box"
                        /> */}
                    </Grid> 
                </Grid>
                </div>
           
                {/* searchEnd */}
                    {
                        pluginsList && pluginsList.length === 0 &&
                        <Grid container spacing={2} component='div'  className={classes.container}>
                        <Grid item xs={ 12 } data-test="no-plugin-grid">
                            <img
                              src="/images/infographics/no_proj.svg"
                              alt="No plugins"
                              className="defaultImg"
                            />
                            <p>{t('App.PluginSelection.noPlugins')} </p>
                            {(currentOrganization.id > 0 && isAuthorizedOrg("", this.props.currentOrgRole)) && (
                                <Link
                                    href={paths.ORGANIZATIONINFO.replace(":id", currentOrganization.id)+ `#admin_plugin`}
                                    underline="always"
                                >
                                    {t('App.PluginSelection.addPlugin')}
                                </Link>
                            )}
                        </Grid>
                        </Grid>
                    }
                    {
                        !pluginsList && 
                        <Grid container spacing={6} component='div'  className={`${classes.container} ${classes.root}`}>
                           {
                                !pluginsList && [0, 1, 2, 3, 4, 5, 6, 7].map((idx) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={idx} data-test="loading-skeleton">
                                            <Paper className={classes.paperContainer} >
                                                <Grid container direction="column" alignItems="center" justifyContent="center" spacing={4}>
                                                    <Grid item>
                                                        <Skeleton variant="rect" width={80} height={80} />
                                                    </Grid>
                                                    <Grid item style={{ width: '100%', display:'contents'}}>
                                                        <Skeleton animation="wave" height={20} width="30%" />
                                                    </Grid>
                                                    <Grid item style={{ width: '100%', display: 'contents' }}>
                                                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} width="80%" />
                                                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} width="80%" />
                                                        <Skeleton animation="wave" height={10} width="60%" />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    }
                    {
                        pluginsList &&
                        <InfiniteScroll
                            dataLength={pluginsList.length ?? 0}
                            next={this.fetchMorePlugins}
                            hasMore={pluginsList.length === this.state.page * this.state.size}
                            scrollThreshold={0.95}
                            style={{ overflow: "hidden", padding: '10px' }}
                            // scrollableTarget="scrollableDiv"
                            scrollableTarget="scrollable-content"
                            data-test="more-plugins"
                            loader=''
                        >
                        <Grid container spacing={6} className={`${classes.root}` } style={{marginTop: 40, padding: 40}}>
                        {
                            pluginsList && pluginsList.length > 0 && pluginsList.map((plugin: PluginModel,idx:number) => {
                                return (
                                    // <Grid item xs={12} md={4} key={idx}  data-test="plugin-grid">
                                    //     <Paper className={`${classes.paperContainer} circle-background`} elevation={15}>
                                    //         <Link href="#" underline='none' onClick={() => this.handlePluginClick(plugin.id)} data-test="plugin-click">
                                    //             <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
                                    //                 <Grid item>
                                    //                     <div className={classes.iconContainer} >
                                    //                         <img src={plugin.image} className='pluginimg' alt='' data-test="plugin-img" />
                                    //                     </div>    
                                    //                 </Grid>
                                    //                 <Grid item>
                                    //                     <Typography align="center" variant="h6" color="textPrimary" data-test="plugin-name">{plugin.name}</Typography>
                                    //                 </Grid>
                                    //                 <Grid item xs={11}>
                                    //                     <Typography align="center" variant="caption" color="textSecondary" className='plugindis' data-test="plugin-desc">
                                    //                         {plugin.description}
                                    //                     </Typography>
                                    //                 </Grid>
                                    //             </Grid>
                                    //         </Link>
                                    //     </Paper>
                                    // </Grid>
                                <Grid item xs={12} md={4} key={idx}  data-test="plugin-grid">
                                    <Button onClick={() => this.handlePluginClick(plugin.id)} className={classes.iconContainer} data-test="plugin-click">
                                        <Paper className={`${classes.paperContainer} circle-background`} elevation={15}>
                                            <img src={plugin.image}  alt='' data-test="plugin-img" className={classes.icon} />  
                                            <Typography align="center" variant="h6" color="textPrimary" className={classes.selectTemplateTypography} data-test="plugin-name">{plugin.name}</Typography>
                                            <Typography align="center" variant="caption" color="textSecondary" className={classes.selectTemplateTypography} style={{maxWidth: '80%'}} data-test="plugin-desc">
                                                {plugin.description}
                                            </Typography>
                                        </Paper>
                                    </Button>
                                </Grid>
                                )
                            })
                            
                        }
                        </Grid>
                        </InfiniteScroll>
                    }
                {this.props.fetchpluginsCallStarted && <BackdropLoader message={t('App.PluginSelection.fetchingPlugin')} />}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        fetchPluginsListAction : (support_ci:boolean, page:number, size:number, search:string, is_managed_service: boolean) => dispatch(fetchPluginsList(support_ci, page, size, search, is_managed_service)),
        clearPlugInListData: () => dispatch(clearPlugInListData())
        //pluginSelection: (id) => dispatch(pluginSelection(id))
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => {
    return {
        pluginsList: state.CreateAppReducer.pluginsList,
        fetchpluginsCallStarted: state.CreateAppReducer.fetchpluginsCallStarted,
        currentOrganization: state.AuthReducer.currentOrganization,
        currentOrgRole: currentOrgRole(state),
        //selectedTemplate_Create: state.AppsReducer.selectedTemplate_Create
    }
}
const connector= connect(
    mapStateToProps,
    mapDispatchtoProps
)
export default connector(
    compose(
        withStyles,
  )(useStyles)(withTranslation()(PluginSelection))
)
type PropsFromRedux = ConnectedProps<typeof connector>;