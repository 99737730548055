import {
  Divider,
  Grid,
  Input,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchSearchResult } from "../../pages/header/redux/actions";
import { getSubscriptionListCall } from "../../pages/project/createproject/redux/actions";
import data from "../../theme/styleConstants";
import NoContentImage from "../nocontentimagecontainer/NoContentImage";

import { Dispatch } from "redux";
import { ConnectedProps } from "react-redux";
import { History } from "history";
import { createStyles, Theme, WithStyles } from "@material-ui/core";
import { WithTranslation } from "react-i18next";
import { EnvironmentModel } from "../../models/Environment.model";
import { ProjectModel } from "../../models/Project.model";
import { AppDetailsModel } from "../../models/Application.model";
import palette from "../../theme/palette";
import { CustomColors } from "../../constants/enums";
import { getDateBilling } from "../../helpers/utils";
import { StyledDivider } from "../dashedDivider/DashedDivider";
/* istanbul ignore next */
const useStyles = (theme: Theme) =>
  createStyles({
    icon: {
      marginRight: 5,
      marginLeft: 4,
      fontSize: "1.1rem",
      verticalAlign: "middle",
    },
    popoverPaperMax: {
      top: "60px !important",
      // left: data.drawerWidth + 24 + "px !important",
      // [theme.breakpoints.down("sm")]: {
      //   left: data.drawerWidth + -9 + "px !important",
      // },
    },

    popoverPaperMin: {
      top: "80px !important",
      // left: data.drawerMiniWidth + 24 + "px !important",
      // [theme.breakpoints.down("sm")]: {
      //   left: data.drawerMiniWidth + -9 + "px !important",
      // },
    },

    input: {
      flexGrow: 1,
      lineHeight: "16px",
      letterSpacing: "-0.05px",
      // minWidth: 300,
      // minHeight: 40,
    },
    popover: {
      padding: theme.spacing(1),
      background: "rgba(128,128,128,0.5)",
    },
    searchImg: {
      width: 100,
      objectFit: "cover",
      padding: 20,
    },
    resultList: {
      minWidth: 300,
      marginTop: 10,
      [theme.breakpoints.up("sm")]: {
        maxHeight: 370,
        minWidth: 500,
      },
      overflowY: "auto",
    },
    searchBox: {
      display: "inline-flex",
      alignItems: "center",
      
      height: 45,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "30%",
        background:'#fff',
      },
      [theme.breakpoints.down("sm")]: {
      
        background:'inherit',
        boxShadow:'none'
      },
     
    },
    projectCount:{
      marginLeft: 8, 
      fontSize: '12px', 
      fontWeight: 700, 
      lineHeight: '20px',
      padding: '2px 6px',
      borderRadius: 6,
    },
    searchComponentbuttonContainer: {
      backgroundColor: palette.background.neutral, 
      padding: 8, 
      display: 'flex', 
      justifyContent: 'space-between'
    },
    buttonActive: {
      backgroundColor: palette. white, 
      padding: '6px 24px', 
      borderRadius: 8, 
      cursor: 'pointer'
    },
    buttonInactive: {
      backgroundColor: 'transparent', 
      padding: '6px 24px', 
      borderRadius: 8, 
      cursor: 'pointer'
    }
  });

/* istanbul ignore next */
const useStyles2 = makeStyles(() => ({
  link: {
    textDecoration: "none",
  },
  paperRoot: {
    width: "100%",
  },
  rowContainer: {
    display: "flex",
    padding: "0.5rem",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },
  title: {
    // color: "#0057fa",
    // marginLeft: "1rem",
    // fontWeight: 500,
  },
  subTitle: {
    color: palette.text.disabled,
    // marginLeft: "1rem",
    // fontWeight: 200,
    // fontSize: 12,
    marginTop: 4
  },
  smallTitle: {
    color: "#0057fa",
    fontSize: "1rem",
  },
  titleShorter: {
    width: 30,
    height: 30,
    background: "white",
    border: "1px solid #0057fa",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
}));

interface SearchRowProps {
  to: string;
  id: number;
  type: number;
  image: string;
  name: string;
  description: string;
  handleResultClick: (id: number, type: number) => void;
}
export function SearchRow(props: SearchRowProps) {
  const classes = useStyles2();
 
  return (
    // <Paper elevation={1} className={classes.paperRoot}>
      <Link
        to={props.to}
        className={classes.link}
        onClick={() => props.handleResultClick(props.id, props.type)}
        data-test="link-to"
      >
        <div className={classes.rowContainer}>
          {props.image && (
            <img src={props.image} alt="" height={40} width={40} />
          )}
          {!props.image && (
            <div className={classes.titleShorter}>
              <p className={classes.smallTitle}>
                {props.name !== "" && props.name.charAt(0).toUpperCase()}
              </p>
            </div>
          )}
          <div className="projectTitlesContainer">
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.title} variant="body2">{props.name}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.subTitle} variant="caption">{props.description}</Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Link>
    // </Paper>
  );
}

interface Props
  extends PropsFromRedux,
    WithTranslation,
    WithStyles<typeof useStyles> {
  updateHeaderOnSearch: (state: boolean) => void;
  isSideBarOpen?: boolean;
  endormentElement?:JSX.Element,
  cb?:()=>void
}

interface State {
  anchorEl: Element | ((element: Element) => Element) | null;
  value: string;
  tempAnchorEl: Element | ((element: Element) => Element) | null;
  mode: number
}
export class Search extends Component<Props, State> {
  timer: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      value: "",
      anchorEl: null,
      tempAnchorEl: null,
      mode: 0
    };
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  // handleKeyUp= (e) => {
  //   console.log("handleKeyUp : " + e.target.value);
  //   clearTimeout(this.timer);
  //   if(e.target.value.length > 2)
  //   {
  //     this.timer = setTimeout(this.handleSearch(e), 3000);
  //   }
  //   else if(e.target.value.length === 0 || e.target.value.trim() === "")
  //   {
  //     this.clearResult();
  //   }
  // };

  // searchClick = (e) => {
  //   this.handleSearch(e)
  // }

  handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      value: e.target.value,
      tempAnchorEl: e.currentTarget,
    });

    clearTimeout(this.timer);
    if (e.target.value.length > 0) {
      this.timer = setTimeout(this.handleSearch, 1000);
    } else if (e.target.value.length === 0 || e.target.value.trim() === "") {
      this.clearResult();
    }
    this.props.updateHeaderOnSearch(e.target.value.trim().length > 0);
  };

  handleSearch = () => {
    const { tempAnchorEl } = this.state;
    this.setState({
      anchorEl: tempAnchorEl,
    });
    this.props.fetchSearchResult(this.state.value);
  };

  // handleSearchFocus = (e) => {
  // }

  getSubscriptionName = (subId: number) => {
    const { subscriptionList } = this.props;
    if (subscriptionList) {
      for (let i = 0; i < subscriptionList.length; i++) {
        const subscription = subscriptionList[i];
        if (subscription.id === subId) {
          return subscription.name;
        }
      }
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleResultClick = () => {
    this.clearResult();
    this.props.cb && this.props.cb()
  };

  clearResult = () => {
    this.setState({
      anchorEl: null,
      value: "",
    });
    this.props.updateHeaderOnSearch(false);
  };

  
  
  render() {
    
    const { classes, t, isSideBarOpen } = this.props;

    const isAllEmpty = this.props.searchResult.projects.length === 0 && this.props.searchResult.applications.length === 0 && this.props.searchResult.environments.length === 0;

    return (
      <>
        <Paper className={classes.searchBox}>
          <SearchIcon className={classes.icon} />
          <Input
            className={classes.input}
            disableUnderline
            onChange={this.handleOnChange}
            //onKeyDown = { this.handleKeyDown }
            //onKeyPress = {this.handleKeyPress }
            //onKeyUp = {this.handleKeyUp }
            endAdornment={this.props.endormentElement||null}
            value={this.state.value}
            placeholder={t("SearchProjectsApps")}
            //onFocus={ this.handleSearchFocus}
            aria-describedby="simple-popper"
            data-test="search-box"
          />
        </Paper>
        {/* <Popper 
            id='simple-popper' 
            open={Boolean(this.state.anchorEl)} 
            anchorEl={this.state.anchorEl} 
            className={classes.paper} 
            placement='bottom-start'
            > */}

        {/* <Backdrop className={classes.backdrop} open={Boolean(this.state.anchorEl)}> */}
        <Popover
          id="simple-popper"
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          className={classes.popover}
          classes={{
            paper: isSideBarOpen
              ? classes.popoverPaperMax
              : classes.popoverPaperMin,
          }}
          onClose={this.handleClose}
          //BackdropComponent = {Backdrop}
          disableAutoFocus
          disableEnforceFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          data-test="simple-popover"
          PaperProps={{
            style: { zIndex: 1300 }, 
          }}
        >
          {isAllEmpty ? 
              (<NoContentImage
                type="search"
                message={"No search result with given keyword"}
                alt={"No result"}
                className={classes.searchImg}
                data-test="no-result-grid"
              /> ): 
            (<div className={classes.searchComponentbuttonContainer}>
              <div className={this.state.mode === 0 ? classes.buttonActive : classes.buttonInactive} onClick={() => this.setState({mode : 0})}>
                <Typography color={this.state.mode === 0 ? 'textPrimary' : "textSecondary"}>
                    Projects
                    <span className={classes.projectCount} style={{backgroundColor: palette.background.primary, color: palette.white}}>{this.props.searchResult.projects.length}</span>
                </Typography>
              </div>
              <div className={this.state.mode === 1 ? classes.buttonActive : classes.buttonInactive} onClick={() => this.setState({mode : 1})}>
                <Typography color={this.state.mode === 1 ? 'textPrimary' : "textSecondary"}>
                    Apps
                    <span className={classes.projectCount} style={{backgroundColor: CustomColors.Info.Lighter, color: CustomColors.Info.Dark}}>{this.props.searchResult.applications.length > 0 ? this.props.searchResult.applications.length : 0}</span>
                </Typography>
              </div>
              <div className={this.state.mode === 2 ? classes.buttonActive : classes.buttonInactive} onClick={() => this.setState({mode : 2})}>
                <Typography color={this.state.mode === 2 ? 'textPrimary' : "textSecondary"}>
                    Environment
                    <span className={classes.projectCount} style={{backgroundColor: CustomColors.Success.Lighter, color: CustomColors.Success.Dark}}>{this.props.searchResult.environments.length > 0 ? this.props.searchResult.environments.length : 0}</span>
                </Typography>
              </div>
            </div>)
          }
          <MenuList className={classes.resultList}>
            {/* {this.props.searchResult &&
              this.props.searchResult.projects &&
              this.props.searchResult.projects.length > 0 && (
                <MenuItem data-test="pro-header">Projects</MenuItem>
            )} */}
            {this.state.mode === 0 &&
            this.props.searchResult &&
              this.props.searchResult.projects &&
              this.props.searchResult.projects.length > 0 ?
              this.props.searchResult.projects.map(
                (item: ProjectModel, ind: number) => {
                  return (
                    <>
                      <MenuItem data-test="pro-menu-item" key={ind}>
                        <SearchRow
                          to={`/project/${item.id}`}
                          id={item.id}
                          name={item.name}
                          // description={
                          //   item &&
                          //   item.subscription_id &&
                          //   this.getSubscriptionName(item.subscription_id)
                          // }
                          description={getDateBilling(item.createdat)}
                          type={1}
                          handleResultClick={this.handleResultClick}
                          image={item.image}
                          data-test="search-row"
                        />
                      </MenuItem>
                      {ind !== this.props.searchResult.projects.length -1 && <StyledDivider/>}
                    </>
                  );
                }
              )
              :
              this.state.mode === 0 &&  !isAllEmpty && (
                <NoContentImage
                  type="search"
                  message={"No projects with given keyword"}
                  alt={"No result"}
                  className={classes.searchImg}
                  data-test="no-result-grid"
                />
              )
            }
            {/* {this.props.searchResult &&
              this.props.searchResult.applications &&
              this.props.searchResult.applications.length > 0 && (
                <MenuItem data-test="app-header">Applications</MenuItem>
              )} */}
            {this.state.mode === 1 &&
            this.props.searchResult &&
              this.props.searchResult.applications &&
              this.props.searchResult.applications.length > 0 ?
              this.props.searchResult.applications.map(
                (
                  item: AppDetailsModel,
                  ind: number
                ) => {
                  return (
                    <>
                      <MenuItem data-test="app-menu-item" key={ind}>
                        <SearchRow
                          to={`/app/${item.id}`}
                          id={item.id}
                          name={item.name}
                          description={item.project && item.project.name}
                          type={2}
                          handleResultClick={this.handleResultClick}
                          image={
                            item.plugin && item.plugin.image
                              ? item.plugin.image
                              : ""
                          }
                        />
                      </MenuItem>
                      {ind !== this.props.searchResult.applications.length -1 && <StyledDivider />}
                    </>
                  );
                }
              )
              :
              this.state.mode === 1 && !isAllEmpty && (
                <NoContentImage
                  type="search"
                  message={"No applications with given keyword"}
                  alt={"No result"}
                  className={classes.searchImg}
                  data-test="no-result-grid"
                />
              )
            }
            {/* {this.props.searchResult &&
              this.props.searchResult.environments &&
              this.props.searchResult.environments.length > 0 && (
                <MenuItem data-test="env-header">Environments</MenuItem>
              )} */}
            {
            this.state.mode === 2 &&
              this.props.searchResult &&
              this.props.searchResult.environments &&
              this.props.searchResult.environments.length > 0 ?
              this.props.searchResult.environments.map(
                (item: EnvironmentModel, ind: number) => {
                  const desctiption = item.application
                    ? (item.application.project &&
                        item.application.project.name) +
                      " - " +
                      item.application.name
                    : "";
                  return (
                    <>
                      <MenuItem data-test="env-menu-item" key={ind}>
                        <SearchRow
                          to={`/environment/${item.id}`}
                          id={item.id}
                          name={item.name}
                          description={desctiption}
                          type={3}
                          handleResultClick={this.handleResultClick}
                          image={
                            item.application &&
                            item.application.plugin &&
                            item.application.plugin.image
                              ? item.application.plugin.image
                              : ""
                          }
                        />
                      </MenuItem>
                      {ind !== this.props.searchResult.environments.length -1 && <StyledDivider />}
                    </>
                  );
                }
              ) : 
              this.state.mode === 2 && !isAllEmpty && (
                <NoContentImage
                  type="search"
                  message={"No environments with given keyword"}
                  alt={"No result"}
                  className={classes.searchImg}
                  data-test="no-result-grid"
                />
              )
              }
          </MenuList>
          {/* </Popper> */}
        </Popover>
        {/* </Backdrop> */}
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  searchResult: state.HeaderReducer.searchResult,
  subscriptionList: state.CreateProjectReducer.subscriptionList,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchSearchResult: (keyword: string) =>
      dispatch(fetchSearchResult(keyword)),
    getSubscriptionListCall: (history: History) =>
      dispatch(getSubscriptionListCall(history)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(Search)));
