import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, makeStyles, Grid, CardHeader } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import GenerateToken from "./GenerateToken";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  deleteAccessToken,
  getAccessTokenList,
  clearAccessToken,
  deleteAllTokens,
} from "../profiletab/redux/actions";
import { LabelHandler } from "../../../components/labelHandler/LabelHandler";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import WarningIcon from "@material-ui/icons/Warning";
import { CopyIcon } from "../../../components/uservariablestable/UserVariablesTable";
import Popup from "../../../components/confirmactionpopup/ConfirmActionPopup";
import { DateHandler } from "../../../components/dateHandler/DateHandler";
import { getDate } from "../../../helpers/utils";
import Alert from "@material-ui/lab/Alert";
import NoContentImage from "../../../components/nocontentimagecontainer/NoContentImage";
import { connect } from "react-redux";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import { useTranslation } from 'react-i18next';
import { ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { AccessTokenModel } from '../../../models/Account.model';

const useStyles = makeStyles((theme) => ({
  AccessToken: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
    flexDirection: "row",
    [theme.breakpoints.down(350)]: {
      display: "grid",
    },
  },
  btn_group: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btn__padding: {
    paddingRight: "30px",
  },
  tokenTitleContainer:{
    display: 'flex', 
    alignItems: 'center', 
    gap: 24
  },
  tokenDescription:{
    display: 'flex', 
    alignItems: 'center', 
    gap: 10
  }
}));


interface Props extends PropsFromRedux{
}

export function AccessTokens(props:Props) {
  const {getAccessTokenList,clearAccessToken,deleteAccessToken,deleteAllTokens,ProfileTabReducer} = props;
  const [t] = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [removeAll, setRemoveAll] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  useEffect(() => {
    getAccessTokenList()
    return () => {
      clearAccessToken();
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTokenDelete = (id:number) => {
    setDeleteId(id);
    setOpenPopup(true);
  };

  const handleConfirmPopupAgree = () => {
    openPopup
      ? deleteAccessToken(deleteId)
      : deleteAllTokens();
    openPopup ? setOpenPopup(false) : setRemoveAll(false);
  };

  const handleConfirmPopupDisAgree = () => {
    openPopup ? setOpenPopup(false) : setRemoveAll(false);
    setDeleteId(0);
  };

  return (
    <>
      {/* <div className='alertwhitecontainer' data-test="main">
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h3">{t('AccessTokens.accessToken')}</Typography>
          <div style={{display: "flex", alignItems: 'center'}}>
            <div className={classes.btn__padding}>
              <Button
                color="primary"
                // size="small"
                variant="contained"
                type="reset"
                onClick={handleClickOpen}
                disableElevation
                data-test="newToken"
              >
                {t('AccessTokens.AccessTokenList.newToken')}
              </Button>
            </div>
            {ProfileTabReducer.tokenList?.length > 0 && (
              <div>
                <Button
                  color="secondary"
                  // size="small"
                  variant="contained"
                  type="reset"
                  onClick={() => setRemoveAll(true)}
                  disableElevation
                  data-test = "revoke-token"
                >
                  {t('AccessTokens.AccessTokenList.revokeAll')}
                </Button>
              </div>
            )}
          </div>
        </div>
        {ProfileTabReducer.newToken && ProfileTabReducer.tokenList?.length > 0 && (
          <Alert severity="info" data-test="alert">
            {t('AccessTokens.AccessTokenList.alert')}
          </Alert>
        )}
        
      </div> */}

      <Card className="ciSettings">
        {/* <CardHeader
          title={<Typography variant="h6">{t('AccessTokens.accessToken')}</Typography>}
          action={
            <div style={{ display: "flex", flexDirection: "column", alignItems: 'flex-start' }}>
              <Alert severity="info" style={{ marginBottom: '10px' }}>
                {t('AccessTokens.AccessTokenList.alert')}
              </Alert>

              <div style={{ display: "flex", alignItems: 'center' }}>
                <div className={classes.btn__padding}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="reset"
                    onClick={handleClickOpen}
                    disableElevation
                    data-test="newToken"
                  >
                    {t('AccessTokens.AccessTokenList.newToken')}
                  </Button>
                </div>
                {ProfileTabReducer.tokenList?.length > 0 && (
                  <div>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="reset"
                      onClick={() => setRemoveAll(true)}
                      disableElevation
                      data-test="revoke-token"
                    >
                      {t('AccessTokens.AccessTokenList.revokeAll')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          }
        /> */}

        <div className='alertwhitecontainer' data-test="main">
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant="h6">{t('AccessTokens.accessToken')}</Typography>
            {ProfileTabReducer.newToken && ProfileTabReducer.tokenList?.length > 0 && (
              <Alert severity="info" data-test="alert">
                {t('AccessTokens.AccessTokenList.alert')}
              </Alert>
            )}
            <div style={{display: "flex", alignItems: 'center'}}>
              <div className={classes.btn__padding}>
                <Button
                  color="primary"
                  // size="small"
                  variant="contained"
                  type="reset"
                  onClick={handleClickOpen}
                  disableElevation
                  data-test="newToken"
                >
                  {t('AccessTokens.AccessTokenList.newToken')}
                </Button>
              </div>
              {ProfileTabReducer.tokenList?.length > 0 && (
                <div>
                  <Button
                    color="secondary"
                    // size="small"
                    variant="contained"
                    type="reset"
                    onClick={() => setRemoveAll(true)}
                    disableElevation
                    data-test = "revoke-token"
                  >
                    {t('AccessTokens.AccessTokenList.revokeAll')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <CardContent>
          {ProfileTabReducer.tokenList?.length > 0 ? (
            ProfileTabReducer.tokenList.map(({ name, createdat, expiry_date, id }:AccessTokenModel) => {
              return (
                <Card key={id} data-test="token-item" elevation={6}>
                  <CardContent>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={8}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item className={classes.tokenTitleContainer}>
                          <Typography variant="body2" color="textPrimary">
                            {name}
                          </Typography>
                          {ProfileTabReducer.newToken?.id === id && (
                            <div className={classes.tokenDescription}>
                              <Typography variant="body2">{ProfileTabReducer.newToken.token}</Typography>
                              <CopyIcon copyText={ProfileTabReducer.newToken.token} />
                            </div>
                          )}
                        </Grid>
                        <Grid item>
                          <DateHandler
                            date={createdat}
                            icon={<CalendarTodayOutlinedIcon />}
                            labelType="Grey"
                            className="env-label-handler-container"
                            labelClassName="accessTokenCaption"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={3} justifyContent="flex-end">
                      <LabelHandler
                        icon={<WarningIcon style={{ color: "orange" }} />}
                        iconTooltip={`${t("Common.expiryDate")}`}
                        label={expiry_date
                          ? `${t('AccessTokens.AccessTokenList.expiresOn')} ${getDate(expiry_date)}`
                          : t('AccessTokens.AccessTokenList.noExpiration')}
                        labelType="Warning"
                        className="env-label-handler-container"
                      />
                    </Grid>
                    <Grid item container xs={1} justifyContent="flex-end">
                      <Button onClick={() => handleTokenDelete(id)} data-test="delete-token" disableElevation style={{textDecoration: 'underline', textUnderlineOffset: 4, fontSize: 14, fontWeight: 400}}>
                        Revoke
                      </Button>
                    </Grid>
                  </Grid>
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <NoContentImage message="No Tokens available" data-test="noContent-img"/>
          )}
        </CardContent>
      </Card>

      {open && <GenerateToken open={open} setOpen={setOpen} data-test="generate-token"/>}


      {/* {(openPopup || removeAll) && ( */}
        <Popup
          open={removeAll || openPopup}
          // setOpen={setOpenPopup}
          handleAgree={handleConfirmPopupAgree}
          handleDisAgree={handleConfirmPopupDisAgree}
          yesText="Confirm"
          noText="Cancel"
          data-test="popup"
          message={
            openPopup
              ? t('AccessTokens.AccessTokenList.deleteToken')
              : t('AccessTokens.AccessTokenList.deleteAllToken')
          }
        />
      {/* )} */}
      {ProfileTabReducer.loadingTokenList  && <BackdropLoader message = {t('AccessTokens.AccessTokenList.fetch')} data-test="fetch-loader"/> }
      {(ProfileTabReducer.deletingTokenList || ProfileTabReducer.deletingAllToken) 
      && <BackdropLoader message = {t('AccessTokens.AccessTokenList.deleting')} data-test="delete-loader"/> }
      {ProfileTabReducer.creatingToken && <BackdropLoader message = {t('AccessTokens.AccessTokenList.creating')} data-test="create-loader"/>}
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  ProfileTabReducer: state.ProfileTabReducer,
  });

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    getAccessTokenList: () => dispatch(getAccessTokenList()),
    clearAccessToken: () => dispatch(clearAccessToken()),
    deleteAccessToken: (deleteId:number) => dispatch(deleteAccessToken(deleteId)),
    deleteAllTokens: () => dispatch(deleteAllTokens()),
  }
}

const connector=connect(mapStateToProps, mapDispatchtoProps)

export default connector(AccessTokens);
type PropsFromRedux = ConnectedProps<typeof connector>;