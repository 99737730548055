import {
  Button,
  CardActions,
  CardHeader, Collapse, Divider, FormControl, Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem, Select,
  Typography
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import { makeStyles } from "@material-ui/core/styles";
// import "ace-builds/src-noconflict/mode-javascript";
// import "ace-builds/src-noconflict/theme-monokai";
import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import MuiTextField from "../../components/textfield/MuiTextField";
import { useTranslation } from 'react-i18next';
import { CronImage } from "../../models/cron.model";
import { EnvironmentModel, InitContainerType } from "../../models/Environment.model";
import { UnsavedInitContainer } from "./InitContainer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

// const useStyles = makeStyles((theme) => ({
//   expand: {
//     transform: "rotate(0deg)",
//     marginLeft: "auto",
//     transition: theme.transitions.create("transform", {
//       duration: theme.transitions.duration.shortest,
//     }),
//   },
//   expandOpen: {
//     transform: "rotate(180deg)",
//   },
//   svgicon: {
//     fontSize: 14,
//     color: "#357dfd",
//     marginRight: 5,
//   },
// }));

const useStyles = makeStyles(() => ({
  expand: {
    transform: "rotate(0deg)",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));


interface Props{
  source:number;
  initImages:CronImage[];
  onSave:(currentImageContainer:UnsavedInitContainer|InitContainerType)=>void;
  environmentDetails:EnvironmentModel;

  onCancel: () => void;
  create:boolean;
  initContainer:InitContainerType|null
}

export default function RecipeReviewCard(props:Props) {
  //const [expanded, setExpanded] = useState(false);
  const [currentInitContainer, setCurrentInitContainer] = useState<UnsavedInitContainer|InitContainerType|null>({
    name: "",
    command:"",
    image: props.source === 2 ? "MyImage" : props.initImages[0]?.image_name,
  });
  const [error, setError] = useState(false);
  const [changed, setChanged] = useState(false);
  const [edit, setEdit] = useState(false);
  const [expandStartupJsob,setExpandStartupJsob] = useState(false);
  const [t] = useTranslation()
  const classes = useStyles();

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const onSave = () => {
    if(!currentInitContainer) return;
    props.onSave(currentInitContainer);
    setCurrentInitContainer(null);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>|React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if(!currentInitContainer) return
    setCurrentInitContainer({
      ...currentInitContainer,
      [e.target?.name as keyof typeof currentInitContainer]: e.target?.value as string,
    });
    setChanged(true);
  };

  const handleExpandStartUpJob = () => {
    setExpandStartupJsob(prevState => !prevState)
  }

  useEffect(() => {
    if(!currentInitContainer) return
    const e =
      !currentInitContainer.image ||
      currentInitContainer?.image === "Select";
    setError(e);
  }, []);

  useEffect(() => {
    if(!currentInitContainer) return
    if (
      currentInitContainer.image === "Select" ||
      !currentInitContainer.name ||!currentInitContainer.command
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [currentInitContainer]);

  const handleOnChangeEditor = (newValue:string) => {
    if(!currentInitContainer) return
    setCurrentInitContainer({
      ...currentInitContainer,
      command: newValue,
    });
    setChanged(true);
  };

  useEffect(() => {
    if (props.initContainer) {
      setCurrentInitContainer(props.initContainer);
      setEdit(true);
    } else {
      setEdit(false);
    }
    return () => {
      setCurrentInitContainer(null);
    };
  }, [props.initContainer]);


  return (
      <div data-test="main-container">
          <Card className="startupContainer">
              <CardHeader 
              title={<Typography variant="h6">{t('InitContainer.InitContainerCreate.startUpJob')}</Typography>} 
              avatar={
                props.source !== 2 && (
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expandStartupJsob,
                    })}
                    onClick={handleExpandStartUpJob}
                  >
                    <ExpandMoreIcon color="primary"/>
                  </IconButton> 
                )}
              />
              <Collapse in={expandStartupJsob || props.source === 2}>
                  <Divider />
                  <CardContent>
                      <Grid>
                          <Grid container spacing={3}>
                              <Grid item md={4} xs={12}>
                                <FormControl fullWidth>
                                  {/* <label>{t('InitContainer.InitContainerCreate.jobName')}</label> */}
                                  <MuiTextField
                                    id="name"
                                    name="name"
                                    customClassName="oneRemMarginBottomSeperator"
                                    type="text"
                                    value={currentInitContainer?.name ?? ""}
                                    onChange={onChange}
                                    placeholder="Name"
                                    disabled={props.source === 2 && edit}
                                    data-test="name-input"
                                    label={t('InitContainer.InitContainerCreate.jobName')}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                  <FormControl variant="outlined" className="w-100">
                                      {/* <label>{t('InitContainer.InitContainerCreate.image')}</label> */}
                                      <InputLabel>{t('InitContainer.InitContainerCreate.image')}</InputLabel>
                                      <Select
                                        labelId=""
                                        id=""
                                        name="image"
                                        value={currentInitContainer?.image ?? "Select"}
                                        onChange={onChange}
                                        data-test="image-select"
                                      >
                                          {props.source === 2 && props.environmentDetails && (
                                          <MenuItem value="MyImage" data-test="app-image">
                                            {props.environmentDetails?.application?.name}{" "}
                                            {props.environmentDetails?.name} image
                                          </MenuItem>
                        )}
                                          {props.initImages?.map((i:CronImage, _ind:number) => (
                                              <MenuItem value={i.image_name} key={_ind} data-test="init-image">{i.name}</MenuItem>
                        ))}
                                      </Select>
                                  </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                  {/* <TextField
                      id="filled-multiline-flexible"
                      className="addcodeDiv"
                      multiline
                      rows={4}
                      placeholder="Enter your code here"
                      value={currentInitContainer?.command ?? ""}
                      name="command"
                      variant="outlined"
                      onChange={onChange}
                    /> */}
                                  <AceEditor
                                    placeholder="Enter your code here"
                                    mode="javascript"
                                    theme="monokai"
                                    fontSize={14}
                                    showPrintMargin={false}
                                    width="100%"
                                    height="300px"
                                    name="command"
                                    onChange={handleOnChangeEditor}
                                    value={currentInitContainer?.command ?? ""}
                                    setOptions={{
                                      showLineNumbers: true,
                                      tabSize: 4,
                                    }}
                                    style={{borderRadius: 16}}
                                    data-test="editor"
                                  />
                              </Grid>
                          </Grid>
                      </Grid>
                  </CardContent>
                  <CardActions className="startupActionContainer">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSave}
                        disabled={error || !changed}
                        data-test="save-btn"
                      >
                          {edit ? t('Projects.ActivityTab.updateLabel') : t('Backup.SettingsTab.save')}
                      </Button>
                      <Button variant="contained" color="primary" onClick={props.onCancel} data-test="cancel-btn">
                      {t('CronJob.CronJobList.noText')}
                      </Button>
                  </CardActions>
              </Collapse>
              
          </Card>
      </div>
  );
}
