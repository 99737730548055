import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { connect, ConnectedProps } from "react-redux";
import toast from "../toast/Toast";
import styled from "styled-components";
import { uploadMultipleFiles } from "../../pages/common/redux/actions";
import UploadIcon from "../../upload.svg";
import ImagePopupModal from "../imagepopupmodal/ImagePopupModal";
import BackdropLoader from "../loader/BackdropLoader";
import { useTranslation } from 'react-i18next';
import { Dispatch } from "redux";
import { UserFileUploadIcon } from "../../helpers/customIcon";
import { CustomColors } from "../../constants/enums";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme?.palette?.background?.paper,
  },
  spanStyle: {
    color: "#0057fa",
  },
  labelStyle: {
    color: "black",
    fontWeight: "bolder",
    textAlign: "start",
    marginBottom: theme.spacing(1),
  },
  styleGrid: {
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  previewImage: {
    height: "auto",
    width: "auto",
    maxHeight: 150,
    maxWidth: 150,
  },
}));

/* istanbul ignore next */
const getColor = (props:any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

/* istanbul ignore next */
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  color: black;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

// Set Files with the array of objects with each object of the format
// {
//   id: int,
//   file : Object,
//   url: string,
// }

const fileLimit = 10

type Image = {
  url: string,
  fileValue: string
}

interface Props extends PropsFromRedux{
  setFile:(files: any)=>void,
  reset: boolean
  uploadingFile: boolean
}

export function MultiFileUpload(props: Props) {
  const classes = useStyles();
  const [uploaded, setUploaded] = useState<any[]>([]);
  const [t] = useTranslation()

  /* istanbul ignore next */
  const uploadCallback = (data: any, _file: any) => {
    if (data && _file) {
      const fileData = {
        id: uploaded.length,
        file: _file,
        url: data.url,
      };
      setUploaded((prevUploads:any) => [...prevUploads, fileData]);
    }
  };
  /* istanbul ignore next */
  const onDrop =
    //  useCallback(
    (acceptedFiles:any) => {
      if (acceptedFiles.length + uploaded.length > fileLimit) {
        toast.error(`Cannot attach more than ${fileLimit} files. Try attaching fewer`);
      } else {
        const toUploadFiles = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
          const f = acceptedFiles[i];
          const attachment = new FormData();
          attachment.append("file_name", f.name);
          attachment.append("file_type", f.type);
          attachment.append("file", f);
          toUploadFiles.push(attachment);
        }
        props.uploadMultipleFiles(toUploadFiles, uploadCallback);
      }
    };
  // , []);

  useEffect(() => {
    props.setFile(uploaded);
  }, [uploaded]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    // acceptedFiles,
  } = useDropzone({
    onDrop,
    // maxFiles: fileLimit,
    // maxSize: 1024,
    accept: "image/*",
  });

  // useEffect(() => {
  //   setFile(
  //     acceptedFiles.map((file) => (
  //       <li key={file.path}>
  //         {file.path} - {file.size} bytes
  //       </li>
  //     ))
  //   );
  // }, [acceptedFiles]);

  // useEffect(() => {
  //   setFile(
  //     uploaded.map((upload) => (
  //       <li key={upload?.file?.path}>
  //         {upload?.file?.path} - {upload?.file?.size} bytes
  //       </li>
  //     ))
  //   );
  // }, [uploaded]);

  useEffect(() => {
    if (props.reset) {
      setUploaded([]);
    }
  }, [props.reset]);

  const handleRemove = (item: Image) => {
    const newUploads:any[] = [];
    uploaded.forEach((u: any) => {
      if (u.url !== item.url) {
        newUploads.push(u);
      }
    });
    setUploaded([...newUploads]);
  };

  return (
    <div data-test="main-container">
      <Grid container spacing={1} className={classes.styleGrid}>
        <Grid item md={12} sm={12} xs={12}>
          <Card elevation={0}>
            {/* <CardHeader title="Attachments" /> */}
            <CardContent style={{padding: uploaded?.length <= 0 ? "0px" : ""}}>
              <ImagePopupModal images={uploaded} remove={handleRemove} data-test="image-popup" />
              <Container
                {...getRootProps({
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                })}
                style={{border:'none', borderRadius: 16}}
                className="custom-file-upload"
              >
                <input {...getInputProps()} data-test="file-input" />
                {uploaded?.length <= 0 ? (
                  <Box component="div" style={{minWidth:560,minHeight:288,display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap:8, }}>
                    <UserFileUploadIcon style={{height: 150, width: 200}} viewBox="0 0 200 150"/>
                    <Typography variant="h6">Drop or select file</Typography>
                    <Typography variant="body2" color='textSecondary'>{t('FileLimit')} {fileLimit} {t('FileSupport')}{" "}</Typography>
                  </Box>
                ) : (
                  <Box component="div" style={{minWidth:560,minHeight:288,display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap:8, }}>
                    <UserFileUploadIcon style={{height: 150, width: 200}} viewBox="0 0 200 150"/>
                    <Typography variant="h6">{t('AddMore')}</Typography>
                    <Typography variant="body2" color='textSecondary'>{t('FileLimit')} {fileLimit} {t('FileSupport')}{" "}</Typography>
                  </Box>
                )}
              </Container>
            </CardContent>
          </Card>
          {/* <Grid container direction={"row"} spacing={3}>
              {uploaded.map((upload, ind) => (
                <Grid key={upload.url} item sm={12} xs={12} md={4}>
                  <img
                    src={upload.url}
                    onClick={() => openImageViewer(ind)}
                    width="100"
                    height="100"
                    style={{ margin: "2px" }}
                    alt={upload.file?.name}
                    className={classes.previewImage}
                  />
                </Grid>
              ))}
            </Grid> */}
        </Grid>
      </Grid>
      {props.uploadingFile && <BackdropLoader message={t('Uploading')} data-test="uploading-loader" />}
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  uploadingFile: state.CommonReducer.uploadingFile,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    uploadMultipleFiles: (files:any[], callBack:(data: any, _file: any)=>void) =>
      dispatch(uploadMultipleFiles(files, callBack)),
  };
};

// export default connect(mapStateToProps, mapDispatchtoProps)(MultiFileUpload);

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MultiFileUpload);