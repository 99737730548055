import { TableCell, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import palette from "../../theme/palette";

// import { StyledTableCell, StyledTableRow } from "../../components/styledtabelcell/StyledTabelCell";
/* istanbul ignore next */
export const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: palette.background.primary,
      color: palette.white,
      fontWeight: 600,
      fontSize: 14
    },
    body: {
      color: palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
      borderBottom: `1px dashed ${palette.background.neutral}`,
    },
  }))(TableCell);

/* istanbul ignore next */
export const StyledTableRow = withStyles(() => ({
    root: {},
  }))(TableRow);

/* istanbul ignore next */
export const TableCellStyled = withStyles(() => ({
    head: {
      // backgroundColor: theme.palette.background.default,
      // color: 'grey',
      backgroundColor: palette.background.primary,
      color: palette.white,
      fontWeight: 600,
      fontSize: 14
    },
    body: {
      //   color : '#555',
      // fontSize: 14,
      color: palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
      borderBottom: `1px dashed ${palette.background.neutral}`,
    },
  }))(TableCell);

/* istanbul ignore next */
export const GeneralStyledTableCell = withStyles(() => ({
    head: {
    
    },
  }))(TableCell);