import palette from "../palette";

export default {
  contained: {
    backgroundColor: `${palette.background.primary} !important`, 
    padding: '8px 20px !important', 
    boxShadow: 'none !important', 
    transition: 'none',
    borderRadius: '8px !important',
    '&.Mui-disabled': {
      backgroundColor: `${palette.background.disabled} !important`, 
      color: 'rgba(28, 37, 46, 0.32) !important',
    },
  },
  containedSecondary: {
    backgroundColor: `${palette.text.disabled} !important`, 
    color: `${palette.white} !important`, 
    padding: '8px 20px !important', 
    boxShadow: 'none !important', 
    transition: 'none',
    borderRadius: '8px !important',
    '&.Mui-disabled': {
      backgroundColor: `${palette.background.disabled} !important`, 
      color: 'rgba(28, 37, 46, 0.32) !important',
    },
  }
};
