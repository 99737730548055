import React from 'react';
import { Card, CardContent, CardHeader , InputLabel, Typography } from '@material-ui/core';
//import { makeStyles } from '@material-ui/styles';
import LineChart from '../linechart/LineChart'

// const useStyles = makeStyles(() => ({
    
//   }));

export const ChartCard = ( props ) => {
    //const classes = useStyles();
    
    return (
        <Card data-test="main-container" elevation={1}>
            <CardHeader 
                title={
                    <Typography variant='subtitle2' style={{display:'flex', alignItems:'center', gap: 24}}>
                        {props.title}
                        <div style={{display:'flex', alignItems:'center', gap: 24}}>
                            <div style={{height: 12, width: 12, borderRadius: '50%', backgroundColor: props.lineColor}}></div>
                            {props.lineTitle1 && (<Typography variant='caption'>{props.lineTitle1}</Typography>)}
                        </div>
                        {props.secondlinecolor && (
                            <div style={{display:'flex', alignItems:'center', gap: 24}}>
                                <div style={{height: 12, width: 12, borderRadius: '50%', backgroundColor: props.secondlinecolor}}></div>
                                {props.lineTitle2 && (<Typography variant='caption'>{props.lineTitle2}</Typography>)}
                            </div>
                        )}
                    </Typography>
                } 
            />
            {/* <Divider /> */}
            <CardContent>
                {props.data?.data ?  <LineChart data={ props.data } maxY={ props.maxY } legend={ props.legend } lineColor={props.lineColor } secondlinecolor={props.secondlinecolor} xtitle= { props.xtitle } ytitle={ props.ytitle } source={ props.source } suffix={ props.suffix } data-test="line-chart"/> : <InputLabel data-test="no-data-label">No data available</InputLabel> }   
            </CardContent>
        </Card>  
    )
};

export default ChartCard;
