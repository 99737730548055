import { Button, Card, CardContent,OutlinedInput, CardHeader, Divider, FormControl, FormControlLabel, Grid, MenuItem, Select, Switch, Typography, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
// import Slide from '@material-ui/core/Slide';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import MultiValueChipInput from '../../../../../components/emailinput/MultiValueChipInput';
import ImageCropper from '../../../../../components/imagecropper/ImageCropper';
import BackdropLoader from '../../../../../components/loader/BackdropLoader';
import MuiTextField from '../../../../../components/textfield/MuiTextField';
import { Cluster_Scope, CustomColors, Get_Enum_Key } from '../../../../../constants/enums';
import { isAuthorized, validateInputField } from '../../../../../helpers/utils';
import { fetchRegionsList } from '../../../../app/createapp/redux/actions';
import { updateProjectIcon } from '../../../createproject/redux/actions';
import { deleteProjectApiCall, editProjectApiCall } from '../../../redux/actions';
import './ProjectsTab.css';
import { withTranslation, WithTranslation } from "react-i18next";
import KeyValueRow from '../../../../../components/keyvaluerow/KeyValueRow';
import TooltipChip from '../../../../../components/tooltipchip/TooltipChip';
import { History } from 'history';
import { Dispatch } from "redux";
import { ProjectModel } from '../../../../../models/Project.model';
import { withRouter } from 'react-router-dom';
import palette from '../../../../../theme/palette';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

// function AlertDialogSlide() {
//     const [open, setOpen] = React.useState(false);

//     const handleClickOpen = () => {
//       setOpen(true);
//     };

//     const handleClose = () => {
//       setOpen(false);
//     };
// } 

type AdvanceSettings = Pick<ProjectModel,"project_code"|"base_domain"|"description"|"cluster_scope"|"region"|
"logging"|"monitoring"|"optimize_cost"|"dedicated_lb"|"tags">

type Payload = Pick<ProjectModel,"name"|"id"|"subscription_id"|"description"|"project_code"|"base_domain"|
"cluster_scope"|"region"|"logging"|"monitoring"|"optimize_cost"|"dedicated_lb"|"tags">

interface MatchParams {
    id: string;
}

interface Props extends WithTranslation, PropsFromRedux, RouteComponentProps<MatchParams> {
    history: History
}

type State = {
    projectName: string,
    isPopupOpened: boolean,
    isSubmitButtonDisabled: boolean,
    isProjectFeildError: boolean,
    projectFeildErrorMessage: string,
    selectedFile: any,
    isShowPreview: boolean,
    editModeEnabled: boolean,
    imgUploadeditModeEnabled: boolean,
    advanceSettings: AdvanceSettings
}

export class ProjectsTab extends Component<Props, State, MatchParams> {
    constructor(props: Props) {
        super(props);
        this.state = {
            projectName: this.props.projectDetails ? this.props.projectDetails.name : '',
            //projectImg:this.props.projectDetails ? this.props.projectDetails.image : '',
            isPopupOpened: false,
            isSubmitButtonDisabled: false,
            isProjectFeildError: false,
            projectFeildErrorMessage: '',
            selectedFile: null,
            isShowPreview: false,
            editModeEnabled: false,
            imgUploadeditModeEnabled: false,
            advanceSettings: {
                project_code: "",
                base_domain: "",
                description: "",
                cluster_scope: 0,
                region: " ",
                logging: "",
                monitoring: "",
                optimize_cost: false,
                dedicated_lb: false,
                tags: ""
            }
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps: Props) => {
        //console.log(newProps ,'newprops')
        if (newProps.projectDetails) {
            if (this.state.projectName !== newProps.projectDetails.name) {
                this.setState({
                    projectName: newProps.projectDetails.name ? newProps.projectDetails.name : ""
                })
            }
            // if (this.state.projectImg !== newProps.projectDetails.image) {
            //     this.setState({
            //         projectImg: newProps.projectDetails.image ? newProps.projectDetails.image : ""
            //     })
            // }
            if ((this.props.projectDetails.id !== newProps.projectDetails.id) || (this.props.projectDetails.image !== newProps.projectDetails.image)) {
                this.setState({
                    selectedFile: null,
                    isShowPreview: false
                })
            }
        }
    }
    handleImgUpload = () => {
        this.setState({
            imgUploadeditModeEnabled: true,

        })
    }

    handleImgUploadCancel = () => {
        this.setState({
            imgUploadeditModeEnabled: false
        })
    }

    handleEditClick() {
        if (!this.props.regionsList || this.props.regionsList.length === 0) {
            this.props.fetchRegionsList();
        }
        this.setState({
            editModeEnabled: true,
            projectName: this.props.projectDetails ? this.props.projectDetails.name : '',
            advanceSettings: {
                project_code: this.props.projectDetails.project_code,
                base_domain: this.props.projectDetails.base_domain,
                description: this.props.projectDetails.description,
                cluster_scope: this.props.projectDetails.cluster_scope,
                region: this.props.projectDetails.region ? this.props.projectDetails.region : " ",
                logging: this.props.projectDetails.logging,
                monitoring: this.props.projectDetails.monitoring,
                optimize_cost: this.props.projectDetails.optimize_cost,
                dedicated_lb: this.props.projectDetails.dedicated_lb,
                tags: this.props.projectDetails.tags
            }
        })
    }

    handleCancelEditProject = () => {
        this.setState({
            editModeEnabled: false
        })
    }

    /*deleteProjectAxiosCall = () => {
        const config = {
            headers : {
              'Content-Type': 'application/json',
              'Authorization': 'basic eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJleHAiOjE1ODYzNzA2MDUsInVzZXJfaWQiOjE4fQ.7stGIKeTg_4q2QcqWYYtpdEw_7VgyYtkCEeTRi6enhM'
            }
          }
        axios.delete(endpoints.DELETE_PROJECT.replace(':projectId' , this.props.match.params.id) , config).then(res => {
            this.setState({
                projectName : '',
            })
            Toast.success('Project deleted successfully');
        }).catch(err => {
            Toast.error('Something went wrong while deleting project')
        })
    }*/

    /*editProjectDetailsAxiosCall = () => {
        const { projectName } = this.state;
        const payload = {
            name : projectName
        }
        const config = {
            headers : {
             'Content-Type': 'application/json',
             'Authorization' : 'basic eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJleHAiOjE1ODYzNzA2MDUsInVzZXJfaWQiOjE4fQ.7stGIKeTg_4q2QcqWYYtpdEw_7VgyYtkCEeTRi6enhM'
            }
          }
        axios.put(endpoints.UPDATE_PROJECT_DETAILS.replace(':projectId' , this.props.match.params.id) ,payload, config).then(res => {
            Toast.success('Project Details Updated successfully');
        }).catch(err => {
            Toast.error('Error while Updating project');
        })
    }*/

    editProjectDetailsHandler = () => {
        const { projectName } = this.state;
        const payload = {
            name: projectName,
            id: parseInt(this.props.match.params.id),
            subscription_id: this.props.projectDetails.subscription_id,
            description: this.state.advanceSettings.description,
            project_code: this.state.advanceSettings.project_code,
            base_domain: this.state.advanceSettings.base_domain,
            cluster_scope: this.state.advanceSettings.cluster_scope,
            region: this.state.advanceSettings.region.trim(),
            logging: this.state.advanceSettings.logging,
            monitoring: this.state.advanceSettings.monitoring,
            optimize_cost: this.state.advanceSettings.optimize_cost,
            dedicated_lb: this.state.advanceSettings.dedicated_lb,
            tags: this.state.advanceSettings.tags
        }
        this.props.editProjectApiCallAction(payload, parseInt(this.props.match.params.id));
        this.setState({
            editModeEnabled: false
        })
        // this.editProjectDetailsAxiosCall()
    }
    /* istanbul ignore next */
    deleteProjectDetailsHandler = () => {
        this.setState({
            isPopupOpened: true,
        })
    }

    /* istanbul ignore next */
    handleDisAgreeHandler = () => {
        this.setState({
            isPopupOpened: false
        })
    }

    /* istanbul ignore next */
    handleAgreeHandler = () => {
        this.props.deleteProjectApiCallAction(parseInt(this.props.match.params.id), this.props.history);
        this.setState({
            isPopupOpened: false
        })
    }

    handleProjectNameOnChange = (e: React.ChangeEvent<{ value: string }>) => {
        let validation = validateInputField(e.target.value)
        this.setState({
            projectName: e.target.value,
            isSubmitButtonDisabled: validation.error,
            isProjectFeildError: validation.error,
            projectFeildErrorMessage: validation.error ? this.props.t(validation.message) : "",
        })
    }

    handleProjectNameOnBlur = () => {
        const { projectName } = this.state;

        if (projectName.length === 0) {
            this.setState({
                isProjectFeildError: true,
                projectFeildErrorMessage: this.props.t('Projects.ProjectsTab.projetEmptyError'),
            })
        }
    }

    handleIconSelection = (data: string) => {
        this.setState({
            selectedFile: data,
            isShowPreview: true
        }, () => this.handleUploadClick())
        
    }

    handleUploadClick = () => {
        if (this.state.selectedFile) {
            let iconPayload = new FormData();
            iconPayload.append('file_name', this.state.selectedFile.name);
            iconPayload.append('file_type', this.state.selectedFile.type);
            iconPayload.append('file', this.state.selectedFile);
            this.props.updateProjectIcon(parseInt(this.props.match.params.id), iconPayload)
        }
        this.setState({
            imgUploadeditModeEnabled: false,
        })
    }

    handleAdvanceSettings = (e: React.ChangeEvent<{ value: unknown, name?: string|boolean, checked?:boolean }>) => {
        const { advanceSettings } = this.state;
        const {name, value, checked} = e.target;
        if(name === "optimize_cost" || name === "dedicated_lb") 
        {
            advanceSettings[name] = checked as boolean;
        }
        else if (name === "base_domain" || name === "logging" || name === "region"
         || name === "monitoring" || name === "description" || name === "project_code") {
            advanceSettings[name] = value as string;
        }
        else if (name === "cluster_scope"){
            advanceSettings[name] = value as number;
        }

        this.setState({
            advanceSettings
        })
    }

    handleTags = (data: string[]) => {
        const { advanceSettings } = this.state;
        advanceSettings.tags = data.join(",");
        this.setState({
            advanceSettings
        })
    }

    getProjectTags = () => {
        let tagsComponent
        if(this.props.projectDetails?.tags?.length > 0){
            tagsComponent = (
                <Grid container spacing={1}>
                    {this.props.projectDetails?.tags?.split(",").map((_tag: string, ind: number) => (
                        <TooltipChip title={_tag} key={ind} />
                    ))}
                </Grid>
            )
        }else{
            tagsComponent =  <Typography color="textSecondary"> N/A</Typography>
        }
        return tagsComponent
    }

    render = () => {
        const { projectName, isProjectFeildError, projectFeildErrorMessage, isSubmitButtonDisabled } = this.state;
        const { projectRole, regionsList, t } = this.props
        
        return (
            <div data-test="main-container" className={!this.state.editModeEnabled ? "whitecontainer" : ""}>
                {/* <TextFeild 
                        id='ProjectName' 
                        customClassName='oneRemMarginBottomSeperator' 
                        label='Project Name *' 
                        handleOnChange={ this.handleProjectNameOnChange }
                        value={ projectName }
                /> */}
                {!this.state.editModeEnabled && <Typography variant='subtitle1'>General</Typography>}
                <Grid className='mt-6'>
                    <Grid container spacing={2} alignItems="stretch" direction="row">
                        <Grid item xs={12} md={9}>
                            <Card style={{border: `1px solid ${palette.background.neutral}`, padding: 14}}>
                                <CardHeader title={<Typography variant='h6'>{t('Projects.ProjectsTab.details')}</Typography>} 
                                    action={
                                        isAuthorized("update", projectRole.name) && !this.state.editModeEnabled &&
                                        <IconButton aria-label="settings">
                                            <EditIcon onClick={() => this.handleEditClick()} fontSize="small" className="" data-test="edit-icon"/>
                                        </IconButton>
                                        }
                                    data-test="card-header"
                                >
                                </CardHeader>
                                <Divider />
                                {
                                    !this.state.editModeEnabled &&
                                    // <CardContent data-test="project-details" style={{padding: 24}}>
                                    //     {/* <Grid container spacing={1}> */}

                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.projectNameLabel')} rowValue={this.props.projectDetails?.name} data-test="project-name"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.projectCodeLabel')} rowValue={this.props.projectDetails?.project_code ? this.props.projectDetails.project_code : <Typography color="textSecondary" component="em" > N/A</Typography>} data-test="project-code"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.baseDomainLabel')} rowValue={this.props.projectDetails?.base_domain  ? this.props.projectDetails.base_domain : <Typography color="textSecondary" component="em" > N/A</Typography>} data-test="base-domain"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.descriptionLabel')} rowValue={this.props.projectDetails?.description  ? this.props.projectDetails.description : <Typography color="textSecondary" component="em" > N/A</Typography>} data-test="project-desc"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.clusterScopeLabel')} rowValue={Get_Enum_Key(Cluster_Scope, this.props.projectDetails && this.props.projectDetails.cluster_scope)} data-test="cluster-scope"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.defaultRegionLabel')} rowValue={this.props.projectDetails?.region  ? this.props.projectDetails.region : "All regions"} data-test="default-region"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.loggingLabel')} rowValue={this.props.projectDetails?.logging ?? <Typography color="textSecondary" component="em" > N/A</Typography>} data-test="logging"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.monitoringLabel')} rowValue={this.props.projectDetails?.monitoring ?? <Typography color="textSecondary" component="em" > N/A</Typography>} data-test="monitoring"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.tagsLabel')} rowValue={this.getProjectTags()} data-test="project-tags"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.dedicatedLoadBalancerLabel')} rowValue={this.props.projectDetails && this.props.projectDetails.dedicated_lb ? t('Projects.ProjectInfo.yesLabel') : t('Projects.ProjectInfo.noLabel')} data-test="dedicated-lb"/>
                                    //         <KeyValueRow 
                                    //         // rowXs={6} rowMd={4} 
                                    //         rowKey={t('Projects.ProjectsTab.optimizeCostLabel')} rowValue={this.props.projectDetails && this.props.projectDetails.optimize_cost ? t('Projects.ProjectInfo.yesLabel') : t('Projects.ProjectInfo.noLabel')} data-test="optimized-cost"/>
                                    //     {/* </Grid> */}
                                    // </CardContent>
                                    <CardContent data-test="project-details">
                                        <Grid container spacing={2} style={{display: 'flex', flexDirection: 'column', gap: 24}}> 
                                            {/* <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.projectNameLabel')} 
                                                    rowValue={this.props.projectDetails?.name} 
                                                    data-test="project-name"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.projectCodeLabel')} 
                                                    rowValue={this.props.projectDetails?.project_code ? this.props.projectDetails.project_code : <Typography color="textSecondary" component="em"> N/A</Typography>} 
                                                    data-test="project-code"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.baseDomainLabel')} 
                                                    rowValue={this.props.projectDetails?.base_domain ? this.props.projectDetails.base_domain : <Typography color="textSecondary" component="em"> N/A</Typography>} 
                                                    data-test="base-domain"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.descriptionLabel')} 
                                                    rowValue={this.props.projectDetails?.description ? this.props.projectDetails.description : <Typography color="textSecondary" component="em"> N/A</Typography>} 
                                                    data-test="project-desc"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.clusterScopeLabel')} 
                                                    rowValue={Get_Enum_Key(Cluster_Scope, this.props.projectDetails && this.props.projectDetails.cluster_scope)} 
                                                    data-test="cluster-scope"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.defaultRegionLabel')} 
                                                    rowValue={this.props.projectDetails?.region ? this.props.projectDetails.region : "All regions"} 
                                                    data-test="default-region"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.loggingLabel')} 
                                                    rowValue={this.props.projectDetails?.logging ?? <Typography color="textSecondary" component="em"> N/A</Typography>} 
                                                    data-test="logging"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.monitoringLabel')} 
                                                    rowValue={this.props.projectDetails?.monitoring ?? <Typography color="textSecondary" component="em"> N/A</Typography>} 
                                                    data-test="monitoring"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.tagsLabel')} 
                                                    rowValue={this.getProjectTags()} 
                                                    data-test="project-tags"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.dedicatedLoadBalancerLabel')} 
                                                    rowValue={this.props.projectDetails && this.props.projectDetails.dedicated_lb ? t('Projects.ProjectInfo.yesLabel') : t('Projects.ProjectInfo.noLabel')} 
                                                    data-test="dedicated-lb"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyValueRow 
                                                    rowKey={t('Projects.ProjectsTab.optimizeCostLabel')} 
                                                    rowValue={this.props.projectDetails && this.props.projectDetails.optimize_cost ? t('Projects.ProjectInfo.yesLabel') : t('Projects.ProjectInfo.noLabel')} 
                                                    data-test="optimized-cost"
                                                />
                                            </Grid> */}
                                            <Grid container spacing={2} direction='row' style={{marginTop: 24}}>
                                                <Grid item xs={12} sm={4} >
                                                    <Typography variant='h6'>{this.props.projectDetails?.name}</Typography>
                                                    <Typography variant='body1' color="textSecondary">{t('Projects.ProjectsTab.projectNameLabel')}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={4} >
                                                    <Typography variant='h6'>{this.props.projectDetails?.project_code ? this.props.projectDetails.project_code : <Typography color="textSecondary"> N/A</Typography>}</Typography>
                                                    <Typography variant='body1' color="textSecondary">{t('Projects.ProjectsTab.projectCodeLabel')}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={4} >
                                                    <Typography variant='h6'>{this.props.projectDetails?.base_domain ? this.props.projectDetails.base_domain : <Typography color="textSecondary"> N/A</Typography>}</Typography>
                                                    <Typography variant='body1' color="textSecondary">{t('Projects.ProjectsTab.baseDomainLabel')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{display:'flex',gap: 24}}>
                                                <Typography variant='subtitle2'>{t('Projects.ProjectsTab.tagsLabel')}</Typography>
                                                {this.getProjectTags()}
                                            </Grid>
                                            <Grid container spacing={2} direction='row'>
                                                <Grid item xs={12} sm={6} >
                                                    <Typography variant='h6'>{t('Projects.ProjectsTab.clusterScopeLabel')}</Typography>
                                                    <Typography variant='body1' color="textSecondary">{Get_Enum_Key(Cluster_Scope, this.props.projectDetails && this.props.projectDetails.cluster_scope)}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} >
                                                    <Typography variant='h6'>{t('Projects.ProjectsTab.defaultRegionLabel')}</Typography>
                                                    <Typography variant='body1' color="textSecondary">{this.props.projectDetails?.region ? this.props.projectDetails.region : "All regions"}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} direction='row'>
                                                <Grid item xs={12} sm={6} >
                                                    <Typography variant='h6'>{t('Projects.ProjectsTab.dedicatedLoadBalancerLabel')}</Typography>
                                                    <Typography variant='body1' color="textSecondary">{this.props.projectDetails && this.props.projectDetails.dedicated_lb ? t('Projects.ProjectInfo.yesLabel') : t('Projects.ProjectInfo.noLabel')}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} >
                                                    <Typography variant='h6'>{t('Projects.ProjectsTab.optimizeCostLabel')}</Typography>
                                                    <Typography variant='body1' color="textSecondary">{this.props.projectDetails && this.props.projectDetails.optimize_cost ? t('Projects.ProjectInfo.yesLabel') : t('Projects.ProjectInfo.noLabel')}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                }
                                {
                                    this.state.editModeEnabled &&
                                    <CardContent data-test="project-edit">
                                        <Grid container className="" direction="column" spacing={2}>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.projectNameLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <MuiTextField
                                                          data-test="name-input"
                                                          value={projectName}
                                                          error={isProjectFeildError}
                                                          helperText={projectFeildErrorMessage}
                                                          required
                                                        //   autoFocus
                                                            //variant='outlined'
                                                          onBlur={this.handleProjectNameOnBlur}
                                                          onChange={(e: React.ChangeEvent<{ value: string }>) => this.handleProjectNameOnChange(e)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.projectCodeLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <MuiTextField
                                                          data-test="code-input"
                                                          name='project_code'
                                                          label={t('Projects.ProjectsTab.projectCode')}
                                                          value={this.state.advanceSettings.project_code}
                                                          onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.handleAdvanceSettings(e)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.baseDomainLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <MuiTextField
                                                          data-test="base-domain-input"
                                                          name='base_domain'
                                                          label={t('Projects.ProjectsTab.baseDomain')}
                                                          value={this.state.advanceSettings.base_domain}
                                                          onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.handleAdvanceSettings(e)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.descriptionLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <MuiTextField
                                                          data-test="description-input"
                                                          name="description"
                                                       
                                                          value={this.state.advanceSettings.description}
                                                            //style={{ margin: 8 }}
                                                            //fullWidth
                                                            //margin="normal"
                                                          InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                          multiline
                                                          rows={3}
                                                            //variant="outlined"
                                                          onChange={this.handleAdvanceSettings}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.clusterScopeLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <FormControl variant="outlined" className="w-100">
                                                            <Select
                                                              data-test="cluster-scope-select"
                                                              name="cluster_scope"
                                                              value={this.state.advanceSettings.cluster_scope}
                                                                //variant='outlined' 
                                                              onChange={(e) => this.handleAdvanceSettings(e)}
                                                            >
                                                                <MenuItem value={0}>Shared Clustser</MenuItem>
                                                                <MenuItem value={1}>Orgnization Cluster</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.defaultRegionLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <FormControl variant="outlined" className="w-100">
                                                            <Select
                                                              data-test="default-region-select"
                                                              name="region"
                                                              value={this.state.advanceSettings.region}
                                                                //variant='outlined' 
                                                              onChange={(e:React.ChangeEvent<{ value: unknown, name?: string }>) => this.handleAdvanceSettings(e)}
                                                            >
                                                                <MenuItem value=' '>All region</MenuItem>
                                                                {
                                                                    regionsList && regionsList.length > 0 && regionsList.map((region:string) => <MenuItem key={region} value={region}>{region}</MenuItem>)
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.loggingLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <FormControl variant="outlined" className="w-100">
                                                            {/* <InputLabel id="demo-simple-select-outlined-label">Logging</InputLabel> */}
                                                            <Select
                                                              data-test="logging-select"
                                                              input={<OutlinedInput />}
                                                              labelId="demo-simple-select-outlined-label"
                                                              name="logging"
                                                              value={this.state.advanceSettings.logging}
                                                              onChange={(e) => this.handleAdvanceSettings(e)}
                                                              MenuProps={{
                                                                    getContentAnchorEl: null,
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "left"
                                                                    }
                                                                }}
                                                            >
                                                                {/* <MenuItem value="Select">Select</MenuItem> */}
                                                                <MenuItem value="01Logs">01Logs</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.monitoringLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <FormControl variant="outlined" className="w-100">
                                                            {/* <InputLabel id="demo-simple-select-outlined-label">Monitoring</InputLabel> */}
                                                            <Select
                                                              data-test="monitoring-select"
                                                              input={<OutlinedInput />}
                                                              labelId="demo-simple-select-outlined-label"
                                                              name="monitoring"
                                                              value={this.state.advanceSettings.monitoring}
                                                                //variant='outlined' 
                                                              label={t('Projects.ProjectsTab.monitoring')}
                                                              onChange={(e) => this.handleAdvanceSettings(e)}
                                                              MenuProps={{
                                                                    getContentAnchorEl: null,
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "left"
                                                                    }
                                                                }}
                                                            >
                                                                {/* <MenuItem value="Select">Select</MenuItem> */}
                                                                <MenuItem value="Prometheus">Prometheus</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.tagsLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        {/* <MuiTextField
                                                            name='tags'
                                                            label='Tags'
                                                            //variant='outlined'
                                                            value={this.state.advanceSettings.tags}
                                                            onChange={this.handleAdvanceSettings}
                                                        /> */}
                                                        <MultiValueChipInput data-test="tags-input" values={ _.without(this.state.advanceSettings.tags && this.state.advanceSettings.tags.split(','), '')  } handleValues={this.handleTags}/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.dedicatedLoadBalancerLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <FormControlLabel
                                                          data-test="dedicated-lb-select"
                                                          control={
                                                              <Switch
                                                                name="dedicated_lb"
                                                                color="primary"
                                                                checked={this.state.advanceSettings.dedicated_lb}
                                                                onChange={(e) => this.handleAdvanceSettings(e)}
                                                              />
                                                            }
                                                          label=""
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Grid container >
                                                    <Grid item md={4} xs={12}> <Typography variant="subtitle1" color='textSecondary'>{t('Projects.ProjectsTab.optimizeCostLabel')}</Typography></Grid>
                                                    <Grid item md={8} xs={12}>
                                                        <FormControlLabel
                                                          data-test="optimize-cost-select"
                                                          control={
                                                              <Switch
                                                                name="optimize_cost"
                                                                color="primary"
                                                                checked={this.state.advanceSettings.optimize_cost}
                                                                onChange={(e) => this.handleAdvanceSettings(e)}
                                                              />
                                                            }
                                                          label=""
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center" alignItems="center">
                                            <Grid item >
                                                <Button data-test="cancel-btn" variant='contained' size='small' color='primary' className='editProjectButton' onClick={this.handleCancelEditProject}>{t('Projects.ProjectsTab.cancelLabel')}</Button>
                                                <Button data-test="submit-btn" variant='contained' size='small' color='primary' disabled={isSubmitButtonDisabled} className='editProjectButton' onClick={this.editProjectDetailsHandler}>{t('Projects.ProjectsTab.saveLabel')}</Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                }
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Card className="projectIconCard" style={{border: `1px solid ${palette.background.neutral}`}}>
                                <CardHeader 
                                    title={<Typography variant='h6'>{t('Projects.ProjectsTab.projectIconLabel')}</Typography>} 
                                    action={
                                        isAuthorized("update", projectRole.name) &&
                                        <IconButton aria-label="settings">
                                            <EditIcon onClick={() => this.handleImgUpload()} fontSize="small" className="" data-test="edit-icon"/>
                                        </IconButton>
                                    }
                                />
                                <Divider />
                                <CardContent >
                                    <Grid container alignItems="center" justify="center" style={{minHeight: '240px'}}>
                                        <Grid item>
                                            <div className="projectimgContainer">
                                                <div className={this.props.projectDetails && this.props.projectDetails.image ? "projectimgWrapperUploaded" : "projectimgWrapper"} style={{ backgroundImage: `url(${this.props.projectDetails && this.props.projectDetails.image ? this.props.projectDetails.image : '/images/infographics/no_img.jpg'})` }} data-test="project-image" >
                                                </div>
                                                {/* {isAuthorized("update", projectRole.name) && (
                                                    <span className="projectcirclebtn">
                                                        <EditIcon onClick={() => this.handleImgUpload()} fontSize="small" className="upload-icon-align" data-test="image-edit-btn"/>
                                                    </span>
                                                )} */}
                                                <Typography variant='body1' align='center'>{this.props.projectDetails && this.props.projectDetails.image ? "" : 'No image'}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Grid>

                {/* <ConfirmActionPopup open={ isPopupOpened } handleAgree={ this.handleAgreeHandler } handleDisAgree={ this.handleDisAgreeHandler } message='Are you sure you want to remove this project?' yesText='Yes' noText='No'/> */}

                {/* <Button variant="outlined" color="primary" onClick={this.handleEditClick}>
                    Rename Project
                </Button> */}
                {/* <Dialog
                    open={this.state.editModeEnabled}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCancelEditProject}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                    <Typography className='dialogtitle'>{"Rename project"}</Typography>
                    <IconButton aria-label="close" size="small" className='right' onClick={this.handleCancelEditProject}>
                        <CloseIcon />
                    </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent maxWidth="lg" dividers>
                        <DialogContentText id="alert-dialog-slide-description">

                            <MuiTextField
                                id='ProjectName'
                                value={projectName}
                                customClassName='oneRemMarginBottomSeperator'
                                label='Project Name'
                                error={isProjectFeildError}
                                helperText={projectFeildErrorMessage}
                                required
                                autoFocus
                                //variant='outlined'
                                onBlur={this.handleProjectNameOnBlur}
                                onChange={this.handleProjectNameOnChange}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' size='small' color='primary' disabled={isSubmitButtonDisabled} className='editProjectButton' onClick={this.editProjectDetailsHandler}>Save</Button>
                    </DialogActions>
                </Dialog> */}
                
                {this.props.editingProject && <BackdropLoader message={t('Projects.ProjectsTab.updatingProjectInfoLabel')} />}

                {
                    this.state.imgUploadeditModeEnabled && 
                    <ImageCropper 
                      data-test="image-cropper"
                      title = {t('Projects.ProjectsTab.changeProjectIconLabel')}
                      dontUpload = {true}
                      isOpen={this.state.imgUploadeditModeEnabled}
                      onClose={this.handleImgUploadCancel}
                      onSuccess={ this.handleIconSelection}
                    />
                }
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        projectDetails: state.ProjectReducer.projectDetails,
        projectRole: state.ProjectReducer.projectRole,
        editingProject: state.ProjectReducer.editingProject,
        regionsList: state.CreateAppReducer.regionsList
    }
}

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        editProjectApiCallAction: (payload: Payload, id: number) => dispatch(editProjectApiCall(payload, id)),
        deleteProjectApiCallAction: (id: number, history: History) => dispatch(deleteProjectApiCall(id, history)),
        updateProjectIcon: (id: number, payload: any) => dispatch(updateProjectIcon(id, payload)),
        fetchRegionsList: () => dispatch(fetchRegionsList()),
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(withTranslation()(ProjectsTab)));