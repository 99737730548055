import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import ConfirmActionPopup from "../../../components/confirmactionpopup/ConfirmActionPopup";
import ConfirmDeletePopup from "../../../components/confirmdeletepopup/ConfirmDeletePopup";
import BackdropLoader from "../../../components/loader/BackdropLoader";
// import TabsSkeleton from "../../../components/skeletons/TabsSkeleton";
import { isAuthorizedOrg } from "../../../helpers/utils";
import {
  currentOrgRole,
  sessionTokenSelector,
} from "../../login/redux/selectors";
import ProjectList from "../../project/projectlist/ProjectList";
import {
  clearOrgInfo,
  deleteMember,
  deleteOrg,
  getOrgInfo,
  switchOrg,
} from "../redux/actions";
import AdminTab from "./admintab/AdminTab";
import GroupsTab from "./groupstab/GroupsTab";
// import { sessionTokenSelector, currentOrgRole } from "../../login/redux/selectors";
import MembersTab from "./memberstab/MembersTab";
import "./OrganizationInfo.css";
import { OrgSettingTabs } from "../../../constants/environmentTabs";
import paths from "../../../constants/paths";
import { TabPanel } from "../../../components/tabpanel/TabPanel";
import OrganizationActivityTab from "./activitytab/OrganizationActivityTab";
import { Dispatch } from "redux";
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import Clustertab from "./Clustertab/Clustertab";

/* istanbul ignore next */
const useStyles = makeStyles(() => ({
  tabRoot: {
    minWidth: 100,
  },
}));
const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface MatchParams {
  id: string;
}

type Location = {
  state:{
    path:string
  },
  hash: string;
  pathname: string;
  search: string;
}

type Payload = {
  email: string
}

interface Props extends PropsFromRedux, RouteComponentProps<MatchParams>{
  location: Location
}

export const OrganizationInfo = (props: Props) => {
  const [organizationId, setOrganizationId] = useState(
    props?.match?.params?.id
  );
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isPopupOpened, setPopupOpened] = useState(false);
  const [leaveOrganizationPopupOpen, setLeaveOrganizationPopupOpen] =
    useState(false);
  const [innerTab, setInnerTab] = useState(0);
  const classes = useStyles();
  const [t] = useTranslation();

  const updateInnerTab = (_value: number) => {
    setInnerTab(_value);
  };

  useEffect(() => {
    if (props?.location?.state?.path) {
      props.history.push(props.location.state.path);
    }
  }, [props?.location?.state]);

  const handleChange = (event: React.ChangeEvent<{}>|null, newValue: number) => {
    setValue(newValue);
    if (event) {
      const tabPanelObj = document.getElementById(
        "simple-tabpanel-" + newValue
      );
      if (tabPanelObj) {
        const name = tabPanelObj.getAttribute("name");
        if (name) {
          props.history.push({
            pathname:
              paths.ORGANIZATIONINFO.replace(":id", organizationId) + name,
          });
        }
      }
    }
  };

  const getTabIndexbyName = () => {
    if (props.location.hash) {
      const splitHash = props.location.hash.split("_");
      if (splitHash.length > 1) {
        const subIndex = OrgSettingTabs(splitHash[0]).indexOf(splitHash[1]);
        updateInnerTab(subIndex > -1 ? subIndex : 0);
      }
      const tabPanel = document.getElementsByName(splitHash[0]);
      //const tabPanel = document.getElementsByName(props.location.hash);
      if (tabPanel && tabPanel.length > 0) {
        const id = tabPanel[0].id;
        const index = id.split("-").pop();
        if (index && parseInt(index) > -1) {
          handleChange(null, parseInt(index));
        }
      }
    }
  };

  const initialize = () => {
    const org_id = props.match.params.id?.split("#")[0];
    if (org_id) {
      if (
        !props.currentOrganization || props.currentOrganization.id.toString() !== org_id
      ) {
        props.switchOrg(org_id, null, null, 1);
      } else {
        if (
          !props.organizationInfo ||
          !props.organizationInfo.id ||
          props.organizationInfo.id.toString() !== org_id
        ) {
          props.getOrgInfo();
        }
      }
    }
    getTabIndexbyName();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    //initialize();
    return () => {
      props.clearOrgInfo();
    };
  }, []);

  useEffect(() => {
    initialize();
    return () => {};
  }, [props.match.params.id]);

  // useEffect(() => {
  //   getTabIndexbyName();
  //   return () => {};
  // }, [value]);

  const deleteOrganizationHandler = () => {
    handleMenuClose();
    setPopupOpened(true);
  };

  const handleDisAgreeHandler = () => {
    setPopupOpened(false);
  };

  const handleAgreeHandler = () => {
    setPopupOpened(false);
    props.deleteOrg(props.history);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (props.organizationInfo && props.organizationInfo.id) {
      setOrganizationId(props.organizationInfo.id);
      getTabIndexbyName();
    }
    // return () => {};
  }, [props.organizationInfo]);

  // useEffect(() => {
  //   setOrganizationId(props.match.params.id);
  //   // return () => {};
  // }, [props.match.params.id]);

  const handleLeaveOrganization = () => {
    setLeaveOrganizationPopupOpen(true);
  };

  const leaveOrganizationAgreeHandler = () => {
    const payload = { email: props.userDetails?.email };
    setLeaveOrganizationPopupOpen(false);
    props.deleteMember(payload, true, props.history);
  };

  const leaveOrganizationDisagreeHandler = () => {
    setLeaveOrganizationPopupOpen(false);
  };

  const { organizationInfo } = props;

  return (
    <div data-test="organizationInfoContainer">
      <div data-test="infoContainer">
        <Grid container>
          <Grid item md={12} xs={12}>
            <Grid container direction="row" spacing={2} alignItems="center">
              <Grid item data-test="imageContainer">
                {organizationInfo?.name ? (
                  organizationInfo?.image ? (
                    <div>
                      <img
                        src={organizationInfo.image}
                        alt="Organization icon"
                        height={40}
                        width={40}
                        data-test="orgImage"
                        style={{borderRadius: '50%'}}
                      />
                    </div>
                  ) : (
                    //   <img
                    //     src={/images/default}
                    //     alt="Default Project Icon"
                    //     height={60}
                    //     width={60}
                    //     className="projecticonImg"
                    // />
                    <DashboardIcon color="primary" height={50} />
                  )
                ) : (
                  <Skeleton height={25} width={25} data-test="noimage_skeleton"/>
                )}
              </Grid>
              <Grid item xs>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                      className="projectTitle"
                      data-test="nameContainer"
                    >
                      {organizationInfo?.name ?? <Skeleton width={100} data-test="noname_skeleton" />}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      variant="body2"
                      data-test="domainContainer"
                    >
                      {organizationInfo?.domain ?? <Skeleton width={100} />}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <React.Fragment>
                  <span className="marginBtn">
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<ExpandMoreIcon />}
                      disableElevation
                      disableFocusRipple
                      disableTouchRipple
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e)}
                      data-test="actionButton"
                    >
                      {t("Organization.OrganizationInfo.actions")}
                    </Button>
                  </span>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => handleMenuClose()}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    data-test="organizationMenu"
                  >
                    {isAuthorizedOrg("delete", props.currentOrgRole) && (
                      <MenuItem
                        onClick={deleteOrganizationHandler}
                        data-test="delete"
                      >
                        {t("Organization.OrganizationInfo.delete")}
                      </MenuItem>
                    )}
                    {props.userDetails &&
                      props.userDetails.id !== organizationInfo.user_id && (
                        <MenuItem
                          onClick={handleLeaveOrganization}
                          data-test="leave"
                        >
                          {t("Organization.OrganizationInfo.leave")}
                        </MenuItem>
                      )}
                  </Menu>
                  <ConfirmDeletePopup
                    open={isPopupOpened}
                    handleAgree={handleAgreeHandler}
                    handleDisAgree={handleDisAgreeHandler}
                    message={`This action will permanently delete all the data. Please type "${organizationInfo?.name}" to terminate the Organization : ${organizationInfo?.name}`}
                    toMatchName={organizationInfo?.name}
                    toDeleteModule="organization"
                    loading={props.deletingOrganization}
                  />
                  {props.deletingOrganization && (
                    <BackdropLoader
                      message={t("Organization.OrganizationInfo.deleteOrg")}
                      data-test="deleting-organization-loader"
                    />
                  )}
                  {!organizationInfo && (
                    <BackdropLoader data-test="organizationInfo-loader" />
                  )}
                </React.Fragment>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {organizationInfo && organizationInfo.id > 0 && (
        <div className="tabsContainer" data-test="tabsContainer">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            variant="scrollable"
            data-test="tabs-list"
          >
            <Tab
              classes={{ root: classes.tabRoot }}
              label={t("Organization.OrganizationInfo.projects")}
              data-test="projects"
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={t("Organization.OrganizationInfo.members")}
              data-test="members"
              {...a11yProps(1)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={t("Organization.OrganizationInfo.groups")}
              data-test="groups"
              {...a11yProps(2)}
            />
            {isAuthorizedOrg("settings", props.currentOrgRole) && (
              <Tab
                classes={{ root: classes.tabRoot }}
                label={t("Projects.ProjectInfo.activityLabel")}
                data-test="audit-logs"
                {...a11yProps(3)}
              />
            )}
            {/* <Tab
            classes={{ root: classes.tabRoot }}
            label="Registry"
            {...a11yProps(3)}
          /> */}
              {isAuthorizedOrg("settings", props.currentOrgRole) && (
                <Tab
                  classes={{ root: classes.tabRoot }}
                  label={t("ClusterLabel")}
                  data-test="cluster"
                  {...a11yProps(4)}
                />
              )}
            {isAuthorizedOrg("settings", props.currentOrgRole) && (
              <Tab
                classes={{ root: classes.tabRoot }}
                label={t("Organization.OrganizationInfo.admin")}
                data-test="admin"
                {...a11yProps(5)}
              />
            )}
          </Tabs>
          {/* ) : (
            <TabsSkeleton />
        )} */}
          <div className="horizontalLine m-b-20" />
          {/* <TabPanel
          className="removePadding"
          value={value}
          index={0}
          name="#overview"
        >
          <OverviewTab />
        </TabPanel> */}
          <TabPanel
            value={value}
            index={0}
            name="#projects"
            tabPanel="simple-tabpanel"
          >
            <ProjectList history={props.history} />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            name="#members"
            tabPanel="simple-tabpanel"
          >
            <MembersTab history={props.history} />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            name="#groups"
            tabPanel="simple-tabpanel"
          >
            <GroupsTab />
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            name="#audit-log"
            tabPanel="simple-tabpanel"
          >
            <OrganizationActivityTab
              orgId={organizationInfo.id}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={3} name="#registry">
          <RegistryTab history={props.history} />
        </TabPanel> */}
          <TabPanel
            value={value}
            index={4}
            name="#cluster"
            tabPanel="simple-tabpanel"
          >
            <Clustertab 
              history={props.history}
              innerTab={innerTab}
              updateInnerTab={updateInnerTab}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={5}
            name="#admin"
            tabPanel="simple-tabpanel"
          >
            <AdminTab 
            history={props.history}
            innerTab={innerTab}
            updateInnerTab={updateInnerTab}/>
          </TabPanel>
        </div>
      )}
      <ConfirmActionPopup
        open={leaveOrganizationPopupOpen}
        message={t("Organization.OrganizationInfo.sureLeave")}
        handleAgree={leaveOrganizationAgreeHandler}
        handleDisAgree={leaveOrganizationDisagreeHandler}
        loading={props.deletingMember}
        yesText={t("Organization.OrganizationInfo.leave")}
      />

      {/* <ConfirmDeletePopup
        open={leaveOrganizationPopupOpen}
        handleAgree={leaveOrganizationAgreeHandler}
        handleDisAgree={leaveOrganizationDisagreeHandler}
        message={`This action will remove your access to all data. Please type "${organizationInfo.name}" to leave the organization : ${organizationInfo.name}`}
        yesText="Leave"
        noText="No"
        toMatchName={organizationInfo.name}
        toDeleteModule="organization"
        loading={props.deletingMember}
      /> */}
      {/* {props.deletingMember && ( <BackdropLoader message="Leaving Organization" /> )} */}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  organizationInfo: state.OrganizationReducer.organizationInfo,
  deletingOrganization: state.OrganizationReducer.deletingOrganization,
  fetchingOrgInfo: state.OrganizationReducer.fetchingOrgInfo,
  validSessionId: sessionTokenSelector(state),
  currentOrgRole: currentOrgRole(state),
  userDetails: state.AuthReducer.user,
  deletingMember: state.OrganizationReducer.deletingMember,
  currentOrganization: state.AuthReducer.currentOrganization,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getOrgInfo: () => dispatch(getOrgInfo()),
    clearOrgInfo: () => dispatch(clearOrgInfo()),
    deleteOrg: (history: History) => dispatch(deleteOrg(history)),
    deleteMember: (data: Payload, isLeave: boolean, history: History) =>
      dispatch(deleteMember(data, isLeave, history)),
    switchOrg: (id: number|string, history: History|null, path: string|null, source: number) =>
      dispatch(switchOrg(id, history, path, source)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(OrganizationInfo);