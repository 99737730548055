import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AceEditor from "react-ace";
import * as yaml from "js-yaml";
import { Skeleton } from "@material-ui/lab";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import KeyValueRow from "../../components/keyvaluerow/KeyValueRow";
import { getDiffDays, gitDiffBtnScanDate } from "../../helpers/utils";
import VulnerableDetail from "../environment/environmentinfo/security/VulnerableDetail";
import BackdropLoader from "../../components/loader/BackdropLoader";
import { clearSpecificReport, fetchSpecificClusterReport } from "./redux/actions";
import { useTranslation } from "react-i18next";
import { CustomColors } from "../../constants/enums";
import palette from "../../theme/palette";

const useStyles = makeStyles({
  backBtn: {
    color: CustomColors.Success.Main,
    marginBottom: "20px",
  },
  cardHeader: {
    padding: "0 !important",
  },
  cardHeaderAction: {
    marginTop: 8,
    marginRight: 20,
  },
  downloadIcon: {
    cursor: "pointer",
  },
  statusBackground:{ 
    width: 'fit-content', 
    borderRadius:8, 
    padding: 1,
    color: palette.white
  },
  statusIcons:{
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  }
});

interface Props extends PropsFromRedux {
  handleBack: () => void;
  listID: number;
  clusterId:  number;
}

function ClusterScanReport(props: Props) {
  const classes = useStyles();
  const [t] = useTranslation();
  const [filterSeverity, setFilterSeverity] = useState("ALL");
  const [filteredData, setFilteredData] = useState<any>(null);

  useEffect(() => {
    props.fetchSpecificClusterReport(props.clusterId, props.listID);

    return () => {
      props.clearSpecificReport();
    };
  }, []);

  useEffect(() => {
    setFilteredData(props.specificReport?.vulnerability);
  }, [props.specificReport]);

  const handleSeverityFilter = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selected = e.target.value as string;
    setFilterSeverity(selected);
    if (selected === "ALL") {
      setFilteredData(props.specificReport?.vulnerability);
    } else {
      const vulnerability: any = props.specificReport?.vulnerability.filter(
        (el: any) => el.properties.severity === selected
      );
      setFilteredData(vulnerability);
    }
  };

  const keyValueInfo = (key: string, value: string) => {
    return <KeyValueRow rowKey={key} rowValue={value} />;
  };

  const keyValueSkeleton = () => {
    return (
      <>
        <Grid item xs={4}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rect" />
        </Grid>
      </>
    );
  };

  const severityCount = (severity: string) => {
    let count = 0;
    if (props.specificReport?.vulnerability !== null) {
      props.specificReport?.vulnerability.forEach((el: any) => {
        if (el.properties.severity === severity) {
          count++;
        }
      });
    }
    return count;
  };

  const customKeyValue = (key: string, value: string, color: string) => {
    return (
      <Grid data-test="mainContainer" container>
        <Grid item xs={4}>
          <Tooltip title={key} arrow>
            <Typography
              style={{ color: color }}
              variant="subtitle2"
              className="oneLine"
              data-test="rowKey"
            >
              {key}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleDownload = (data: any, type: string) => {
    let jsonString;
    if (type === "output") {
      const parsedData = JSON.parse(data);
      jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(parsedData)
      )}`;
    } else {
      jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(data)
      )}`;
    }

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";

    link.click();
  };
  
  const getStatusColor = (status:string) => {
    switch (status) {
      case 'completed':
        return CustomColors.Success.Main;
      case 'error':
        return CustomColors.Error.Main;
      default:
        return CustomColors.Warning.Main;
    }
  };

  return (
    <div data-test="main-container">
      <ButtonBase
        onClick={() => {
          props.handleBack();
        }}
        data-test="backButton"
        className={classes.backBtn}
      >
        <ArrowBackIcon fontSize="small" />{" "}
        <span className="jobNav">{t("Cluster.Security.back")}</span>
      </ButtonBase>

      {/* <Grid container spacing={2} data-test="status-icon">
        <Grid item>
          {props.specificReport?.status === "completed" ? (
            <Tooltip title="status completed" arrow>
              <CheckOutlinedIcon
                fontSize="medium"
                style={{ color: "#4CAF50" }}
              />
            </Tooltip>
          ) : props.specificReport?.status === "error" ? (
            <Tooltip title="status error" arrow>
              <CloseOutlinedIcon
                fontSize="medium"
                style={{ color: "#FF5252" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="status pending" arrow>
              <CircularProgress size={25} style={{ color: "#FF9800" }} />
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          {" "}
          <Typography variant="h3">
            {props.specificReport?.name} {t("Cluster.Security.report")}
          </Typography>
        </Grid>
      </Grid> */}
      <Grid container spacing={1} data-test="status-icon" className={classes.statusBackground} style={{backgroundColor: getStatusColor(props.specificReport?.status)}}>
        <Grid item>
          {props.specificReport?.status === "completed" ? (
            <Tooltip title="status completed" arrow>
              <div className={classes.statusIcons}>
                <CheckOutlinedIcon
                  fontSize="small"
                />
              </div>
            </Tooltip>
          ) : props.specificReport?.status === "error" ? (
            <Tooltip title="status error" arrow>
               <div className={classes.statusIcons}>
                <CloseOutlinedIcon
                  fontSize="small"
                />
               </div>
            </Tooltip>
          ) : (
            <Tooltip title="status pending" arrow>
              <div className={classes.statusIcons}>
                <CircularProgress size={18} style={{color: palette.white}}/>
              </div>
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          {" "}
          <Typography variant="body2" style={{color: palette.white}}>
            {props.specificReport?.name} {t("Cluster.Security.report")}
          </Typography>
        </Grid>
      </Grid>
      <br />

      <div>
        <Grid container spacing={2} data-test="report-left-section">
          <Grid item md={6} xs={12}>
            <Card>
              <CardContent>
                {props.specificReport ? (
                  <Grid container direction="column" justify="center">
                    {keyValueInfo(
                      t("Cluster.Security.name"), 
                      props.specificReport?.name
                      )}
                    {keyValueInfo(
                      t("Cluster.Security.createdat"),
                      getDiffDays(props.specificReport?.CreatedAt, true)
                    )}
                    {keyValueInfo(
                      t("Cluster.Security.pluginname"),
                      props.specificReport?.plugin_name
                    )}
                    {keyValueInfo(
                      t("Cluster.Security.status"), 
                      props.specificReport?.status)}
                    {keyValueInfo(
                      t("Cluster.Security.timetaken"),
                      gitDiffBtnScanDate(props.specificReport.time_taken)
                    )}
                  </Grid>
                ) : (
                  <Grid container justify="center" spacing={2}>
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={6} xs={12}>
            <Card>
              <CardContent data-test="card-content">
                {props.specificReport ? (
                  <Grid container direction="column" justify="center">
                    {customKeyValue(
                      t("Cluster.Security.low"),
                      severityCount(t("Cluster.Security.Low")).toString(),
                      CustomColors.Info.Main
                    )}
                    {customKeyValue(
                      t("Cluster.Security.high"),
                      severityCount(t("Cluster.Security.High")).toString(),
                      CustomColors.Error.Main
                    )}
                    {customKeyValue(
                       t("Cluster.Security.medium"),
                      severityCount(t("Cluster.Security.Medium")).toString(),
                      CustomColors.Warning.Main
                    )}
                    {customKeyValue(
                       t("Cluster.Security.critical"),
                      severityCount(t("Cluster.Security.Critical")).toString(),
                      CustomColors.Error.Dark
                    )}
                    {customKeyValue(
                      t("Cluster.Security.totalvulnerabilities"),
                      props.specificReport.vulnerability !== null ||
                        props.specificReport.vulnerability?.length > 0
                        ? props.specificReport.vulnerability?.length
                        : 0,
                      CustomColors.Success.Dark
                    )}
                  </Grid>
                ) : (
                  <Grid container justify="center" spacing={2}>
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {props.specificReport?.vulnerability !== null &&
          props.specificReport?.vulnerability.length > 0 && (
            <div>
              <Card className="m-t-20">
                <CardHeader
                  title={
                    <Typography variant="subtitle1" display="inline">
                      {t("Cluster.Security.vulnerability")}
                    </Typography>
                  }
                  action={
                    <Grid container spacing={4} alignItems="center">
                      <Grid item>
                        <FormControl
                          className="w-100"
                          variant="outlined"
                          margin="normal"
                        >
                          <Select
                            name="provider"
                            value={filterSeverity}
                            color="primary"
                            onChange={(
                              e: React.ChangeEvent<{ value: unknown }>
                            ) => handleSeverityFilter(e)}
                            data-test="provider-select"
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                            }}
                          >
                            <MenuItem value="ALL">{t("Cluster.Security.All")}</MenuItem>
                            <MenuItem value="LOW">{t("Cluster.Security.Low")}</MenuItem>
                            <MenuItem value="HIGH">{t("Cluster.Security.High")}</MenuItem>
                            <MenuItem value="MEDIUM">{t("Cluster.Security.Medium")}</MenuItem>
                            <MenuItem value="CRITICAL">{t("Cluster.Security.Critical")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Tooltip title={t("Cluster.Security.download")}>
                          <GetAppIcon
                            onClick={() =>
                              handleDownload(
                                props.specificReport?.vulnerability,
                                "vulnerability"
                              )
                            }
                            className={classes.downloadIcon}
                            color="primary"
                            fontSize="large"
                          >
                            {t("Cluster.Security.download")}
                          </GetAppIcon>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  }
                />
                <Divider />
                <CardContent>
                  {!(filteredData === undefined || filteredData === null) &&
                    filteredData.length > 0 &&
                    filteredData.map((el: any, ind: number) => (
                      <>
                        <VulnerableDetail detail={el} serialNum={ind + 1} />
                      </>
                    ))}
                </CardContent>
              </Card>
            </div>
          )}

        {(props.specificReport?.vulnerability === null ||
          props.specificReport?.vulnerability.length === 0) &&
          props.specificReport?.status === "completed" && (
            <div>
              <Card className="m-t-20">
                <CardHeader
                  title="Output"
                  action={
                    <Tooltip title={t("Cluster.Security.download")}>
                      <GetAppIcon
                        onClick={() =>
                          handleDownload(
                            props.specificReport?.source_data,
                            "output"
                          )
                        }
                        className={classes.downloadIcon}
                        color="primary"
                        fontSize="large"
                      >
                        {t("Cluster.Security.download")}
                      </GetAppIcon>
                    </Tooltip>
                  }
                />
                <CardContent>
                  <AceEditor
                    data-test="editor-container"
                    mode="yaml"
                    theme={"xcode"}
                    width="100%"
                    readOnly={true}
                    setOptions={{ showPrintMargin: false }}
                    editorProps={{ $blockScrolling: Infinity }}
                    value={yaml.dump(props.specificReport?.source_data)}
                    className="editor"
                    fontSize="15px"
                  />
                </CardContent>
              </Card>
            </div>
          )}
      </div>

      {props.fetchingSpecificReport && (
        <BackdropLoader message={t("Cluster.Security.fetchsecurityreport")} />
      )}
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  specificReport: state.ClusterReducer.specificReport,
  fetchingSpecificReport: state.ClusterReducer.fetchingSpecificReport,
});
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchSpecificClusterReport: (clusterId: number, listID: number) =>
      dispatch(fetchSpecificClusterReport(clusterId, listID)),
    clearSpecificReport: () => dispatch(clearSpecificReport()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(ClusterScanReport);
type PropsFromRedux = ConnectedProps<typeof connector>;
