import React from 'react';
import { Grid, Snackbar, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
//import { css } from 'glamor';

const useStyle = makeStyles(() => ({
  snackbarContainer: {
    width: '100%',
    height: 'auto',
    position: 'fixed',
    top: '18px',
    right: '20px',
    zIndex: 9999,
  },
  alertContainer: {
    width: '75%',
    height: 'auto',
    alignItems: 'center',
    transition: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    boxShadow: 'none',
    padding: '0 20px',
  }
}))

type MessageProps={msg:string,icon?:JSX.Element,severityType?:string|any}
const Message = ({ msg, icon,severityType}:MessageProps) => {

  const classes = useStyle();

  return(
    <Snackbar 
    open={true} 
    autoHideDuration={4000} 
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    className={classes.snackbarContainer}
    >
      <Alert 
      severity={severityType} 
      className={classes.alertContainer}
      >
        {/* <Grid container direction="row">
            <Grid item>{icon}</Grid>
            &nbsp;&nbsp;&nbsp;
            <Grid item>
                <Typography variant="subtitle1">{msg}</Typography>
            </Grid>
        </Grid> */}
        {msg}
      </Alert>
    </Snackbar>
  )
}


export const clearNotifications = () => {
  toast.clearWaitingQueue();
  toast.dismiss();
}

const getToastOptions = (time = 4000):ToastOptions => ({
  position: toast.POSITION.TOP_RIGHT,
  autoClose: time,
  className: 'rotateY animated',
  onOpen:()=>{
    toast.clearWaitingQueue()
  },
  closeButton:false,
  bodyStyle:{backgroundColor: 'transparent', boxShadow: 'none !important', transition: 'none !important', transform: 'none'}
})


const ToastConfig = {
  default(msg:string) { 
    return toast(<Message msg={ msg } />)
  },
  success(msg:string, time:number) {
    return toast(<Message msg={ msg } icon={ <CheckIcon /> } severityType="success" />, getToastOptions(time));
  },
  info(msg:string) {
    return toast(<Message msg={ msg } icon={ <InfoIcon /> } severityType="info" />, getToastOptions());
  },
  warn(msg:string) {
    return toast(<Message msg={ msg } icon={ <HelpIcon /> } severityType="warning" />, getToastOptions());
  },
  error(msg:string) {
    return toast(<Message msg={ msg } icon={ <WarningIcon /> } severityType="error"/>, getToastOptions());
  }
};

export default ToastConfig;
