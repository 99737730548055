import { Box, Card, CardContent, CardHeader, Grid, IconButton, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AddUserPopup from '../../../../components/adduserpopup/AddUserPopup';
import { clearUserListInProject, fetchRoles, fetchUserListInProject } from '../../../../components/adduserpopup/redux/actions';
import CustomButton from '../../../../components/custombutton/CustomButton';
import DeleteUserPopup from '../../../../components/deleteUserPopup/deleteUserPopup';
import BackdropLoader from '../../../../components/loader/BackdropLoader';
import { isAuthorized } from '../../../../helpers/utils';
import { getOrgGroups } from '../../../organization/redux/actions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import palette from '../../../../theme/palette';

// const StyledTableCell = withStyles(() => ({
//     head: {
//       // backgroundColor: theme.palette.background.default,
//       // color: 'grey',
//       fontWeight : 500,
//     },
//     body: {
//         color : '#555',
//       fontSize: 14,
//     },
//   }))(TableCell);
  
//   const StyledTableRow = withStyles(() => ({
//     root: {
//     },
//   }))(TableRow);

const styles = () => ({
  emailText: {
    color: `${palette.text.disabled} !important` 
  }
});

export class UserPermissionTab extends Component {
    constructor(props){
        super(props);
        this.state = {
          isDeleteUserPopupOpened : 0,
          userIdToBeDeleted : 0,
          isAddUserPopupOpened : 0,
          deleteGroup: false,
          userToBeEdited : null,
          isEditPopUpOpen : 0,
          editGroup: false,
          edit: false,
          leavePopup: 0,
          isLeave: false
        }
    }

    componentDidMount(){
      this.props.fetchUserListInProjectAction(this.props.projectId);
      this.props.fetchRolesAction();
    }

    componentWillUnmount(){
      this.props.clearUserListInProject();
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
      if(this.props.projectId !== newProps.projectId) 
      {
          this.props.fetchUserListInProjectAction(newProps.projectId)
      }
    }
    
    setEditFalse = () =>{
      this.setState({
        edit: false,
        userToBeEdited: null,
        isAddUserPopupOpened: Math.random(),
        leavePopup: Math.random(),
      })
    }

    handleDeleteUser = (userId, isGroup) => {
      this.setState({
        userIdToBeDeleted : userId,
        isDeleteUserPopupOpened : Math.random(),
        deleteGroup: isGroup,
        edit: false
      })
    }

    handleLeaveProject = (userId, isGroup) => {
      this.setState({
        userIdToBeDeleted : userId,
        isDeleteUserPopupOpened : Math.random(),
        deleteGroup: isGroup,
        edit: false,
        isLeave: true,
      })
    }

    handleEdit = (user) => {
      this.setState({
        userToBeEdited : user,
        isAddUserPopupOpened : Math.random(),
        edit: true,
      })
    }

    handleAddUser = () => {
      this.setState({
        isAddUserPopupOpened : Math.random(),
        edit: false
      })
    }

    handleClose = () => {
      this.setState({
        isLeave: false,
      })
    }

    goToGroups = (id) => {
      this.props.history.push(`/organization/${id}#groups`)
    }

    render () {
      const { usersListInProject, projectDetails, projectRole, t , classes} = this.props;
      const { userIdToBeDeleted } = this.state;
      const userData = usersListInProject.length > 0 ? usersListInProject : [];
        return (
            <div data-test="main-container" className="org-activities-container p-b-10">
                {/* <Box component="div" style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px 16px', marginBottom: 24}}>
                  <Typography variant='subtitle1'>Users</Typography>
                  { isAuthorized('addUser', projectRole.name) && (
                  <div >
                      <CustomButton label={t('Projects.UserTab.addUserLabel')} onClick={e => this.handleAddUser(e)} data-test="add-user-btn" />
                  </div>
                  )}
                </Box> */}
                {/* <TableContainer component={ Paper } elevation={1}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell colSpan={3}>{t('Projects.UserTab.nameLabel')}</StyledTableCell>
                                <StyledTableCell>{t('Projects.UserTab.emailLabel')}</StyledTableCell>
                                <StyledTableCell colSpan={1}>{t('Projects.UserTab.roleLabel')}</StyledTableCell>
                                { isAuthorized('delete', projectRole.name) && (
                                <StyledTableCell align="center" data-test="action-label" colSpan={1}>{t('Projects.UserTab.actionLabel')}</StyledTableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody> 
                            {
                            projectDetails && projectDetails.user &&
                            <StyledTableRow key={ projectDetails.user.id } data-test="owner-row">
                                <StyledTableCell> <Avatar src={ projectDetails.user.image ? projectDetails.user.image : "./Images/profile_image.png" } /></StyledTableCell>
                                <StyledTableCell data-test="owner-name">{ projectDetails.user.first_name + " " + projectDetails.user.last_name }
                                    <Chip className='userRole' label="owner" />
                                </StyledTableCell>
                                <StyledTableCell data-test="owner-email">{ projectDetails.user.email }</StyledTableCell>
                                <StyledTableCell colSpan={3}>
                                  <Box display="flex" style={{gap: 16}}>
                                    <Avatar src={projectDetails.user.image ? projectDetails.user.image : "./Images/profile_image.png"} />
                                    <Box>
                                      <Typography variant="body2" data-test="owner-name">
                                        {projectDetails.user.first_name + " " + projectDetails.user.last_name}
                                      </Typography>
                                      <Typography variant="body2" className={classes.emailText} data-test="owner-email">
                                        {projectDetails.user.email}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </StyledTableCell>
                                <StyledTableCell data-test="owner-role">
                                  <Typography variant='body2'>{t('Projects.UserTab.owner')}</Typography>
                                </StyledTableCell>
                                { isAuthorized('delete', projectRole.name) && (
                                <StyledTableCell>
                                    <IconButton onClick={ e => this.handleDeleteUser(user.id) }>
                                          <DeleteIcon />
                                      </IconButton>
                                </StyledTableCell>
                                )}
                            </StyledTableRow>
                          }
                            {userData.length > 0 && userData.map((user) => (
                                <StyledTableRow key={ user.id } data-test="user-row">
                                    <StyledTableCell> {!user.group_id ?  <Avatar src={ user.user && user.user.image ? user.user.image : "./Images/profile_image.png" } /> : <Avatar> <GroupIcon /> </Avatar> } </StyledTableCell>
                                    <StyledTableCell data-test="user-name">{user.user? user?.user?.first_name + ' ' + user?.user?.last_name : (
                                      <Typography
                                        color="primary" 
                                        onClick={() => this.goToGroups(user?.group?.organization_id)}
                                        style={{cursor: 'pointer'}}
                                      >
                                          {user.group.Name} 
                                      </Typography>)}
                                        <Chip className='userRole' label="owner" />
                                    </StyledTableCell>
                                    <StyledTableCell data-test="user-email">{user.user ? user?.email : 'N/A'}</StyledTableCell>
                                    <StyledTableCell colSpan={3}>
                                      <Box display="flex" style={{gap: 16}}>
                                        <>
                                          {!user.group_id ?  <Avatar src={ user.user && user.user.image ? user.user.image : "./Images/profile_image.png" } /> : <Avatar> <GroupIcon /> </Avatar> }
                                        </>
                                        <Box>
                                          <Typography variant="body2" data-test="user-name">
                                            {user.user? user?.user?.first_name + ' ' + user?.user?.last_name : (
                                              <Typography
                                                color="primary" 
                                                onClick={() => this.goToGroups(user?.group?.organization_id)}
                                                style={{cursor: 'pointer'}}
                                              >
                                                  {user.group.Name} 
                                              </Typography>)
                                            }
                                          </Typography>
                                          <Typography variant="body2" className={classes.emailText} data-test="user-email">
                                            {user.user ? user?.email : 'N/A'}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </StyledTableCell>
                                    <StyledTableCell data-test="user-role">
                                      <Typography variant='body2'>{ user.user_role ? user.user_role.name : "" }</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    {this.props.currentUser?.id === user?.user_id ? (
                                      <Tooltip title={t('Projects.UserTab.leaveProject')} arrow>
                                        <IconButton aria-label="edit" onClick={() => this.handleLeaveProject(user.group ? user?.id : user.id, user.group ? true : false)} data-test="leave-icon">
                                          <ExitToAppIcon fontSize="inherit" />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <>
                                        {isAuthorized('editUser', projectRole.name) && (
                                          <Tooltip arrow title={t('Projects.UserTab.editPermission')}>
                                            <IconButton aria-label="edit" onClick={() => this.handleEdit(user,user.group ? true : false)} data-test="edit-icon">
                                                <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                          )}
                                          {isAuthorized('delete', projectRole.name) && (
                                          <Tooltip arrow title={t('Projects.UserTab.removeUser')}>
                                            <IconButton onClick={ () => this.handleDeleteUser(user.group ? user?.id : user.id, user.group ? true : false) } data-test="remove-icon">
                                                <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                          )}
                                      </>
                                    ) }                                       
                                    </StyledTableCell>
                                </StyledTableRow>
                      ))}
                        </TableBody>
                    </Table>
                    {userData.length === 0 && <div className='alignCenter'><p>{t('Projects.UserTab.noSharedUser')}</p></div>}
                </TableContainer> */}
                <div>
                  <Card elevation={1} className='scripts'>
                    <CardHeader 
                      title={
                        <Typography variant='subtitle1'>Users</Typography>
                      }
                      action={ 
                        isAuthorized('addUser', projectRole.name) && (
                        <div >
                            <CustomButton label={t('Projects.UserTab.addUserLabel')} onClick={e => this.handleAddUser(e)} data-test="add-user-btn" />
                        </div>
                      )}
                      style={{marginTop: 5}}
                      data-test="card-header"
                    />
                      <CardContent className="titleHeader">
                        <Grid container spacing={2}>
                              <Grid item xs={12} sm={8}>
                                    <span className='activityHeader' style={{display: 'flex', gap:8, alignItems: 'center'}}>
                                        {t('Projects.UserTab.nameLabel')}
                                        {/* <ArrowDownwardIcon fontSize="small"/> */}
                                    </span>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                  <span className='activityHeader'>{t('Projects.UserTab.roleLabel')}</span>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                              { isAuthorized('delete', projectRole.name) && (<span className='activityHeader'>{t('Projects.UserTab.actionLabel')}</span>)}
                              </Grid>
                        </Grid>
                      </CardContent>
                  </Card>
                  <Card elevation={10}>
                    <CardContent>
                      <Grid container  spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <Box component='div' display="flex" style={{gap: 16}}>
                              <Avatar src={projectDetails?.user?.image ? projectDetails?.user?.image : "./Images/profile_image.png"} />
                              <Box>
                                <Typography variant="body2" data-test="owner-name">
                                  {projectDetails?.user?.first_name + " " + projectDetails?.user?.last_name}
                                </Typography>
                                <Typography variant="body2" className={classes.emailText} data-test="owner-email">
                                  {projectDetails?.user?.email}
                                </Typography>
                              </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2} data-test="owner-role" style={{display: 'flex', alignItems:'center'}}>
                          <Typography variant='body2'>{t('Projects.UserTab.owner')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>

                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  {userData.length > 0 && userData.map((user) => (
                  <Card elevation={10} key={ user.id } >
                    <CardContent data-test="user-row">
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={8}>
                            <Box display="flex" style={{gap: 16}}>
                              <>
                                {!user.group_id ?  <Avatar src={ user.user && user.user.image ? user.user.image : "./Images/profile_image.png" } /> : <Avatar> <GroupIcon /> </Avatar> }
                              </>
                              <Box>
                                <Typography variant="body2" data-test="user-name">
                                  {user.user? user?.user?.first_name + ' ' + user?.user?.last_name : (
                                    <Typography
                                      color="primary" 
                                      onClick={() => this.goToGroups(user?.group?.organization_id)}
                                      style={{cursor: 'pointer'}}
                                    >
                                        {user.group.Name} 
                                    </Typography>)
                                  }
                                </Typography>
                                <Typography variant="body2" className={classes.emailText} data-test="user-email">
                                  {user.user ? user?.email : 'N/A'}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={2} style={{display: 'flex', alignItems:'center'}}>
                            <Typography variant='body2'>{ user.user_role ? user.user_role.name : "" }</Typography>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                          {this.props.currentUser?.id === user?.user_id ? (
                            <Tooltip title={t('Projects.UserTab.leaveProject')} arrow>
                              <IconButton aria-label="edit" onClick={() => this.handleLeaveProject(user.group ? user?.id : user.id, user.group ? true : false)} data-test="leave-icon">
                                <ExitToAppIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <>
                              {isAuthorized('editUser', projectRole.name) && (
                                <Tooltip arrow title={t('Projects.UserTab.editPermission')}>
                                  <IconButton aria-label="edit" onClick={() => this.handleEdit(user,user.group ? true : false)} data-test="edit-icon">
                                      <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                )}
                                {isAuthorized('delete', projectRole.name) && (
                                <Tooltip arrow title={t('Projects.UserTab.removeUser')}>
                                  <IconButton onClick={ () => this.handleDeleteUser(user.group ? user?.id : user.id, user.group ? true : false) } data-test="remove-icon">
                                      <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                                )}
                            </>
                          ) }                                       
                          </Grid>
                        </Grid>
                    </CardContent>
                  </Card>
                      ))}
                </div>
                  <AddUserPopup open={ this.state?.isAddUserPopupOpened } instance={this.state.edit ? this.state.userToBeEdited: null} edit={this.state.edit} getOrgGroups={this.props.getOrgGroups} data-test="add-user-popup"/>
                  <DeleteUserPopup open={ this.state?.isDeleteUserPopupOpened } userId={ userIdToBeDeleted } deleteGroup={this.state.deleteGroup} setEditFalse={this.setEditFalse} isLeave={this.state.isLeave} history={this.props.history} handleClose={this.handleClose} data-test="delete-user-popup"/>
                { this.props.addingUser && <BackdropLoader message={t('Projects.UserTab.addingUser')} />}
                { this.props.editingUser && <BackdropLoader message={t('Projects.UserTab.editingUser')} />}
                { this.props.deletingUser && <BackdropLoader message={t('Projects.UserTab.deletingUser')} />}
                { this.props.fetchingUserList && <BackdropLoader message={t('Projects.UserTab.fetchingUser')} />}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  projectDetails: state.ProjectReducer.projectDetails,
  usersListInProject: state.AddUserPopupReducer.usersListInProject,
  projectRole: state.ProjectReducer.projectRole,
  addingUser: state.AddUserPopupReducer.addingUser,
  editingUser: state.AddUserPopupReducer.editingUser,
  deletingUser: state.AddUserPopupReducer.deletingUser,
  fetchingUserList: state.AddUserPopupReducer.fetchingUserList,
  currentUser: state.AuthReducer.user,
})

/* istanbul ignore next */
const mapDispatchtoProps = dispatch => {
  return {
    fetchUserListInProjectAction : payload => dispatch(fetchUserListInProject(payload)),
    fetchRolesAction : (payload) => dispatch(fetchRoles(payload)),
    clearUserListInProject: () => dispatch(clearUserListInProject()),
    getOrgGroups: () => dispatch(getOrgGroups()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withTranslation()(withStyles(styles)(UserPermissionTab)));
