import React from 'react'
import { connect,ConnectedProps } from 'react-redux'
import {
     Typography, Table,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
    Tooltip,
    Link,
    Button,
} from '@material-ui/core';
import CustomButton from "../../../../components/custombutton/CustomButton";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import ConfirmDeletePopup from '../../../../components/confirmdeletepopup/ConfirmDeletePopup';
import { useTranslation } from 'react-i18next';
import { getChartRepos, deleteChartRepo, clearReposInfo, addChartRepo, editChartRepo, syncRepo } from '../../redux/actions'
import ChartRepositoryModal from './ChartRepositoryModal';
import BackdropLoader from '../../../../components/loader/BackdropLoader';
import { StyledTableCell, StyledTableRow } from '../../../../components/styledtabelcell/StyledTabelCell';
import { Dispatch } from "redux";
import { ChartRepoModel } from '../../../../models/HelmCharts.model';
import palette from '../../../../theme/palette';
/* istanbul ignore next */
type Payload = Omit<ChartRepoModel, "id" | "organization_id" | "Filter">;

interface Props extends PropsFromRedux{
}

export const ChartRepoTab = (props:Props) => {
    const [openAddPopup, setOpenAddPopup] = React.useState(false)
    const [deleteObject, setDeleteObject] = React.useState<null|ChartRepoModel>(null)
    const [editObject, setEditObject] = React.useState<null|ChartRepoModel>(null)
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [t] = useTranslation()

    React.useEffect(() => {
        props.getChartRepos()
        return (() => {
            props.clearReposInfo()
        })
    }, [])

    const handleAddRepo = () => {
        setOpenAddPopup(true)
    }

    const handleEdit = (repo:ChartRepoModel) => {
        setEditObject(repo)
        setOpenAddPopup(true)
    }

    const handleDeleteRepo = (repo:ChartRepoModel) => {
        setDeleteObject(repo)
        setDeletePopup(true)
    }

    const deleteAgreeHandler = () => {
        setDeletePopup(false)
        if (deleteObject) {
            props.deleteChartRepo(deleteObject.id)
        }
        setDeleteObject(null)
    }

    const deleteDisagreeHandler = () => {
        setDeleteObject(null)
        setDeletePopup(false)
    }

    const handleCancelPopUp = () => {
        setOpenAddPopup(false)
        setEditObject(null)
    }

    const addRepo = (payload:Payload) => {
        props.addChartRepo(payload)
        handleCancelPopUp()
    }

    const editRepo = (payload: Payload, id: ChartRepoModel["id"]) => {
        // if (editObject) {
        props.editChartRepo(id, payload)
        handleCancelPopUp()
        // }
    }

    const handleSyncRepo = (repo:ChartRepoModel) => {
        // if (editObject) {
        props.syncRepo(repo.id)
        handleCancelPopUp()
        // }
    }

    // const updateRepo = (id, payload) => {
    //     props.editChartRepo(id, payload)
    // }
    return (
        <div data-test="main-container" style={{backgroundColor: palette.white, padding: 16, borderRadius: 16}}>
            <div className="listContainer">
                <Typography color="textPrimary" variant="subtitle1">
                    {t('Organization.ChartRepoTab.repos')}
                </Typography>
                {/* <CustomButton
                    label={t('Organization.ChartRepoTab.addRepository')}
                    onClick={() => handleAddRepo()}
                    data-test="add-btn"
                /> */}
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleAddRepo()}
                    data-test="add-btn"
                >
                    {t('Organization.ChartRepoTab.addRepository')}
                </Button>
            </div>
            <TableContainer component={Paper} data-test="table-container">
                <Table aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>{t('Organization.ChartRepoTab.name')}</StyledTableCell>
                            {/* <StyledTableCell align="left">Service Url</StyledTableCell>
                                    <StyledTableCell align="left">Project name</StyledTableCell> */}
                            <StyledTableCell align="left">{t('Organization.ChartRepoTab.url')}</StyledTableCell>
                            <StyledTableCell align="left">{t('Organization.ChartRepoTab.repo_type')}</StyledTableCell>
                            <StyledTableCell align="left">{t('Organization.ChartRepoTab.auth_type')}</StyledTableCell>
                            {/* <StyledTableCell align="left">User name</StyledTableCell> */}
                            {/* <StyledTableCell align="left">{t('Organization.ChartRepoTab.accessLevel')}</StyledTableCell> */}
                            <StyledTableCell align="center">{t('Organization.ChartRepoTab.actions')}</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {props.chartRepos?.length > 0 && props.chartRepos.map((repo:ChartRepoModel, ind:number) => {
                            //const backup = JSON.parse(item.status);
                            return (
                                <StyledTableRow key={ind} data-test="repo-row">
                                    <StyledTableCell component="th" scope="row" data-test="repo-name"> {repo.name} </StyledTableCell>
                                    {/* <StyledTableCell data-test="backup-created" align="left">{repo.service}</StyledTableCell>
                                            <StyledTableCell align="left">{repo.project_name}</StyledTableCell> */}
                                    <StyledTableCell align="left" data-test="repo-url">
                                        <Link
                                            href={repo.url}
                                            target="_blank"
                                        >
                                            {repo.url}
                                        </Link>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" data-test="repo-type">{repo.repo_type}</StyledTableCell>
                                    <StyledTableCell align="left" data-test="repo-type">{repo.authorization?.type}</StyledTableCell>
                                    {/* <StyledTableCell align="left">{repo.user_name}</StyledTableCell> */}
                                    {/* <StyledTableCell data-test="repo-accessLevel" align="left">{repo.accessLevel}</StyledTableCell> */}
                                    <StyledTableCell align="center">
                                        <Tooltip title={t('Organization.ChartRepoTab.sync')} >
                                            <IconButton>
                                                <SyncOutlinedIcon onClick={() => handleSyncRepo(repo)} data-test="repo-sync" />
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton aria-label="edit">
                                            <EditIcon onClick={() => handleEdit(repo)} data-test="repo-edit" />
                                        </IconButton>
                                        <Tooltip title={t('Organization.ChartRepoTab.delete')} >
                                            <IconButton>
                                                <DeleteIcon onClick={() => handleDeleteRepo(repo)}  data-test="repo-delete"/>
                                            </IconButton>
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                {(!props.chartRepos || props.chartRepos?.length === 0) && (
                    <div className="alignCenter" data-test="no-repo-message">
                        <p>
                            <em> {t('Organization.ChartRepoTab.norepo')} </em>
                        </p>
                    </div>
                )}
            </TableContainer>
            {
                openAddPopup &&
                <ChartRepositoryModal
                    open={openAddPopup}
                    handleCancelPopUp={handleCancelPopUp}
                    editObject={editObject ? editObject : null}
                    action={editObject ? editRepo : addRepo}
                />
            }
            {
                deletePopup && deleteObject &&
                <ConfirmDeletePopup
                    open={deletePopup}
                    handleAgree={deleteAgreeHandler}
                    handleDisAgree={deleteDisagreeHandler}
                    message={t("Organization.ChartRepoTab.confirmDelete")}
                    // yesText={t('Projects.VariablesTab.yesText')}
                    // noText={t('Projects.VariablesTab.noText')}
                    action="delete"
                    toMatchName={deleteObject.name}
                    toDeleteModule="chart repository"
                    loading={props.deletingChartRepo}
                />
            }
            {props.fetchingChartRepos && <BackdropLoader message={t('Organization.ChartRepoTab.fetching')} data-test="fetching-loader" />}
            {props.deletingChartRepo && <BackdropLoader message={t('Organization.ChartRepoTab.deleting')} data-test="deleting-loader" />}
            {props.addingChartRepo && <BackdropLoader message={t('Organization.ChartRepoTab.adding')} data-test="adding-loader" />}
            {props.updatingChartRepo && <BackdropLoader message={t('Organization.ChartRepoTab.updating')} data-test="updating-loader" />}
            {props.syncingChartRepos && <BackdropLoader message={t('Organization.ChartRepoTab.syncing')} data-test="syncing-loader" />}
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    chartRepos: state.OrganizationReducer.chartRepos,
    fetchingChartRepos: state.OrganizationReducer.fetchingChartRepos,
    addingChartRepo: state.OrganizationReducer.addingChartRepo,
    updatingChartRepo: state.OrganizationReducer.updatingChartRepo,
    deletingChartRepo: state.OrganizationReducer.deletingChartRepo,
    syncingChartRepos: state.OrganizationReducer.syncingChartRepos,
})

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getChartRepos: () => dispatch(getChartRepos()),
  deleteChartRepo: (id:ChartRepoModel['id']) => dispatch(deleteChartRepo(id)),
  clearReposInfo: () => dispatch(clearReposInfo()),
  addChartRepo: (payload:Payload) => dispatch(addChartRepo(payload)),
  editChartRepo: (id:ChartRepoModel['id'], payload:Payload) => dispatch(editChartRepo(id, payload)),
  syncRepo: (id:ChartRepoModel['id']) => dispatch(syncRepo(id)),
});

const connector=connect(mapStateToProps, mapDispatchToProps);
export default connector(ChartRepoTab)
type PropsFromRedux = ConnectedProps<typeof connector>;
