import React, { useEffect } from 'react'
import { Tabs,Tab,Typography, Theme, createStyles} from '@material-ui/core';
import ProfileTab from './profiletab/ProfileTab';
import PasswordTab from './passwordtab/PasswordTab';
import BillingTab from "./billingtab/BillingTab"
import { updateBreadcrumb } from '../project/redux/actions'
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import paths from '../../constants/paths';
import {TabPanel} from "../../components/tabpanel/TabPanel"
import AccessTokens from './accessToken/AccessTokens';
import { Dispatch } from "redux";
import { History, Location } from 'history';
import { BreadcrumbModel } from '../../models/Common.model';
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShareIcon from '@material-ui/icons/Share';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { makeStyles } from '@material-ui/styles';
import LinkedAccounts from './profiletab/LinkedAccounts';
import { BillingTabIcon, GeneralTabIcon, LinkAccountIcon } from '../../helpers/customIcon';
import { StyledTab, StyledTabs } from '../../components/StyledTab/StyledTab';

const useStyles = makeStyles((theme:Theme) =>createStyles ({
  tabIcons: {
    display: 'flex',
    justifyContent: 'center'
  },
}));

  function a11yProps(index: number, name: string) {
    return {
      id: `simple-tab-${ index }`,
      'aria-controls': `simple-tabpanel-${ index }`,
      name
    };
  }

  interface Props extends PropsFromRedux {
    history: History,
    location: Location
  }
  
  export function VerticalTabs(props: Props) {
    const classes = useStyles();
    const [ value, setValue ] = React.useState(0);
    const { t } = useTranslation();
    
    const  handleChange = (event: any, newValue: number) => {
      setValue(newValue)
      if(event)
      {
        const tabPanelObj = document.getElementById('simple-tab-' + newValue);
        if(tabPanelObj)
        {
          const name = tabPanelObj.getAttribute("name");
          if(name)
          {
            props.history.push(paths.ACCOUNTDETAILS +  name);
          }
        }
      }
    };

    const getTabIndexbyName = () => {
      const newTotalHash = props.location.hash ?? "#profile"
      if (newTotalHash)
      {
        const tabPanel = document.getElementsByName(newTotalHash);
        if(tabPanel && tabPanel.length > 0)
        {
          const id = tabPanel[0].id;
          const index = id.split('-').pop();
          if(index && parseInt(index) > -1)
            handleChange(null, parseInt(index));
        }
      }
    }
    
    useEffect(() => {
      const breadcrumbData = [
        {
          name: "Account",
          path: paths.ACCOUNTDETAILS,
        },
      ];
      props.updateBreadcrumb(breadcrumbData);
    }, []);
    
    useEffect(() => {
      if(props.location.hash){
        getTabIndexbyName()
      }
    }, [props.location?.hash]);
  
    return (
        <div data-test="main-container">
            <div data-test="header-container">
                <Typography 
                  gutterBottom 
                  variant="h4"
                >
                    {t('Account.AccountInfo.account')}
                </Typography>
            </div>
            <div 
            // className={ classes.root }
            >
                {/* <Tabs
          orientation="vertical"
          value={ value }
          onChange={ handleChange }
          aria-label="Vertical tabs example"
          className={ classes.tabs }
        >
                    <Tab label="profile" { ...a11yProps(0) } />
                </Tabs> */}

                <StyledTabs value={ value }  onChange={ handleChange }  aria-label="simple tabs example" indicatorColor="primary" data-test="tab-change">
                    <StyledTab
                        label={t('Account.AccountInfo.profile')} 
                        icon={<GeneralTabIcon />}
                        data-test="profile-tab" 
                        {...a11yProps(0, "#profile")} 
                    />
                    <StyledTab 
                        label={'Billing'} 
                        icon={<BillingTabIcon />}
                        data-test="billing-tab" 
                        {...a11yProps(1, "#billing")} 
                    />
                    {/* <Tab label={t('Account.AccountInfo.password')} data-test="password-tab" { ...a11yProps(1,"#password") } /> */}
                    <StyledTab
                        label={'Linked Accounts'} 
                        icon={<LinkAccountIcon />}
                        data-test="profile-tab" 
                        {...a11yProps(2, "#linkedaccounts")} 
                    />
                    <StyledTab
                        label={t('AccessTokens.accessToken')} 
                        icon={<VpnKeyIcon />}
                        data-test="profile-tab" 
                        {...a11yProps(3, "#security")} 
                    />
                </StyledTabs>
                <div className='horizontalLine m-b-20' />

                <TabPanel value={ value } index={ 0 } 
                    // className={ classes.tabPanel  } 
                    padding={1} 
                    tabPanel="accountTab"
                    >
                    <ProfileTab />
                </TabPanel>
                <TabPanel value={ value } index={ 1 } 
                    // className={ classes.tabPanel } 
                    padding={1} 
                    tabPanel="accountTab"
                    >
                    <BillingTab />
                </TabPanel>
                <TabPanel value={ value } index={ 2 } 
                    // className={ classes.tabPanel } 
                    padding={1} 
                    tabPanel="accountTab"
                    >
                    <LinkedAccounts />
                </TabPanel>
                <TabPanel value={ value } index={ 3 } 
                    // className={ classes.tabPanel } 
                    padding={1} 
                    tabPanel="accountTab"
                    >
                    <AccessTokens />
                </TabPanel>
            </div>
        </div>

    );
  }

  /*istanbul ignore next */
  const mapStateToProps = () => ({
    
  })
  
  /*istanbul ignore next */
  const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
      updateBreadcrumb: (payload: BreadcrumbModel[]) => dispatch(updateBreadcrumb(payload)),
    }
  }

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VerticalTabs);
