import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EmailInput from "../../../../../components/emailinput/EmailInput";
import InfoPopupContainer from "../../../../../components/infopopup/InfoPopupContainer";
import MuiTextField from "../../../../../components/textfield/MuiTextField";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { getNotifiAlertLists, updateNotifiDetails } from "../redux/action";
import ConfirmActionPopup from "../../../../../components/confirmactionpopup/ConfirmActionPopup";
import { Alert } from "@material-ui/lab";
interface Props extends PropsFromRedux{
}

type NotiDetailModel = {
  email?:string,
    group_interval: string,
    group_wait: string,
    repeat_interval: string,
    resolve_timeout: string,
    slack: string,
    webhook: string,
}

function AlertNotif(props:Props) {
  const [emailNoti, setEmailNoti] = useState(false);
  const [slackNoti, setSlackNoti] = useState(false);
  const [webhookNoti, setWebhookNoti] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleIsEmail = () => {
    setEmailNoti(!emailNoti);
  };

  const handleIsSlack = () => {
    setSlackNoti(!slackNoti);
  };

  const handleIsWebhook = () => {
    setWebhookNoti(!webhookNoti);
  };

    const [notiDetails, setNotiDetails] = useState<NotiDetailModel>({
    email: "",
    group_interval: "600s",
    group_wait: "600s",
    repeat_interval: "600s",
    resolve_timeout: "600s",
    slack: "",
    webhook: "",
  });

  // const [unit, setUnit] = useState({
  //   group_interval_unit: "m",
  //   group_wait_unit: "m",
  //   repeat_interval_unit: "m",
  //   resolve_timeout_unit: "m",
  // });

  const [error, setError] = useState({
    email: false,
  });
  const [helpertext, setHelpertext] = useState({
    email: "",
  });
  const [userEmail, setUserEmail] = useState<any>([]);

  useEffect(()=>{
    props.getNotifiAlertLists(props.environmentDetails?.id);
  },[])

  useEffect(()=>{
    if(props.notifiDetails){
      const details = props.notifiDetails;
      setUserEmail(details.email?.split(","))
      details.email?.length>0 && setEmailNoti(true);
      setSlackNoti(details.slack?true:false)
      setWebhookNoti(details.webhook?true:false)
      setNotiDetails({
        group_interval: details.group_interval,
        group_wait: details.group_wait,
        repeat_interval: details.repeat_interval,
        resolve_timeout: details.resolve_timeout,
        slack: details.slack,
        webhook: details.webhook,
      })
    }
  },[props.notifiDetails])

  const handleEmails = (emails: string[]) => {
    setUserEmail(emails);
  };

  // const emailValidation = () => {
  //   if (userEmail?.length <= 0) {
  //     setError(() => {
  //       return { ...error, email: true };
  //     });
  //     setHelpertext(() => {
  //       return { ...helpertext, email: "There should be at least 1 email" };
  //     });
  //   } else {
  //     setError(() => {
  //       return { ...error, email: false };
  //     });
  //     setHelpertext(() => {
  //       return { ...helpertext, email: "" };
  //     });
  //   }
  // };

  const handleChange = (value: any, key: string) => {
      setNotiDetails(() => {
        return { ...notiDetails, [key]: value };
      });
  };

  // const handleChangeUnit = (
  //   e: React.ChangeEvent<{ value: unknown }>,
  //   toUpdate: string
  // ) => {
  //   setUnit(() => {
  //     return { ...unit, [toUpdate]: e.target.value };
  //   });
  // };

  const timeInterval = (
    name: string,
    key: string,
    values: string,
    // unitState: string,
    // unitStateName: string
  ) => {
    return (
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Typography variant="subtitle2">{name}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="row">
            <Grid item>
              <MuiTextField
                name="webhook_token"
                value={values}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  handleChange(e.target.value, key)
                }
                // error = {this.state.errors.webhook_token}
                // helperText = {this.state.helperText.webhook_token}
              />
            </Grid>
            {/* <Grid item>
              <FormControl className="w-100">
                <Select
                  variant="outlined"
                  color="primary"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={unitState}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    handleChangeUnit(e, unitStateName)
                  }
                >
                  <MenuItem value="m">Minute</MenuItem>
                  <MenuItem value="s">Second</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleSaveNoti = () => {
    setOpenConfirm(true);
  };

  const handleConfirmPopupAgree = () => {
    setOpenConfirm(false);
    if(notiDetails){
      const jsonBody:NotiDetailModel = {
        email:userEmail ? userEmail.join(",") : "",
        group_interval: notiDetails.group_interval,
        group_wait: notiDetails.group_wait,
        repeat_interval: notiDetails.repeat_interval,
        resolve_timeout: notiDetails.resolve_timeout,
        slack: notiDetails.slack,
        webhook: notiDetails.webhook,
      };
      props.updateNotifiDetails(props.environmentDetails?.id, jsonBody);
    }
  }

  const handleConfirmPopupDisagree = () => {
    setOpenConfirm(false);
  }

  return (
    <>
      <Card className="alertNotification">
        <CardHeader
          title={
            <Typography variant="h6" display="inline">
              {/* <strong>Notifications</strong> */}
              Notifications
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Alert
                severity="info"
                // className="w-100"
                data-test="replica-limit"
              >
                The input value should be similar as of `1s` or `1m`
              </Alert>
            </Grid>
          </Grid>
          <Typography variant="subtitle2" style={{paddingTop: 30, paddingBottom: 8}}>Includes</Typography>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <FormControlLabel
                    data-test="email-checkbox"
                    control={
                      <Checkbox
                        color="primary"
                        checked={emailNoti}
                        onChange={() => handleIsEmail()}
                      />
                    }
                    label={<Typography variant="body2">Email</Typography>}
                  />
                </Grid>
                {emailNoti && (
                  <Grid
                    item
                    xs={12}
                    className="oneRemLeftMarginSeperator"
                    data-test="email-input-grid"
                  >
                    <EmailInput
                      error={Boolean(error.email) ?? false}
                      helperText={helpertext.email ?? ""}
                      emails={userEmail ?? []}
                      handleEmails={handleEmails}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <FormControlLabel
                    data-test="slack-checkbox"
                    control={
                      <Checkbox
                        color="primary"
                        checked={slackNoti}
                        onChange={() => handleIsSlack()}
                      />
                    }
                    label={<Typography variant="body2" style={{display:'flex', alignItems:'center'}}>
                        Slack  
                        {slackNoti && <InfoPopupContainer
                          label=""
                          message="SlackView"
                          url={
                            "https://api.slack.com/messaging/webhooks#create_a_webhook"
                          }
                          urlText="Slack's documentation"
                        />}
                    </Typography>}
                  />
                </Grid>
                {slackNoti && (
                  <Grid
                    item
                    xs={12}
                    className="oneRemLeftMarginSeperator"
                    data-test="slack-webhook-grid"
                  >
                    <Grid container direction="column" spacing={1}>
                      {/* <InfoPopupContainer
                        label="Slack"
                        message="SlackView"
                        url={
                          "https://api.slack.com/messaging/webhooks#create_a_webhook"
                        }
                        urlText="Slack's documentation"
                      /> */}
                      <Grid item>
                        <MuiTextField
                          name="slack_webhook_url"
                          value={notiDetails?.slack}
                          onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                            handleChange(e.target.value, "slack")
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <FormControlLabel
                    data-test="webhook-checkbox"
                    control={
                      <Checkbox
                        color="primary"
                        checked={webhookNoti}
                        onChange={() => handleIsWebhook()}
                      />
                    }
                    label={
                    <Typography variant="body2" style={{display:'flex', alignItems:'center'}}>
                      Webhook
                      {webhookNoti && 
                        <InfoPopupContainer
                          label=""
                          message="WebHookView'"
                          url={"#"}
                          urlText="Read more in the documentation"
                        />
                      }
                    </Typography>}
                  />
                </Grid>
                {webhookNoti && (
                  <Grid
                    item
                    xs={12}
                    className="oneRemLeftMarginSeperator"
                    data-test="webhook-input-grid"
                  >
                    <Grid container spacing={3}>
                      <Grid item sm={6} xs={12}>
                        <Grid container direction="column" spacing={1}>
                          {/* <Grid item>
                            <InfoPopupContainer
                              label="WebHook"
                              message="WebHookView'"
                              url={"#"}
                              urlText="Read more in the documentation"
                            />
                          </Grid> */}
                          <Grid item>
                            <MuiTextField
                              name="webhook_url"
                              value={notiDetails?.webhook}
                              onChange={(
                                e: React.ChangeEvent<{ value: unknown }>
                              ) => handleChange(e.target.value, "webhook")}
                              // error = {this.state.errors.webhook_url}
                              // helperText = {this.state.helperText.webhook_url}
                              data-test="webhook-url"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        {/* <Divider /> */}
        <CardContent>
        {/* <Grid container spacing={2}>
              <Grid item md={5} xs={12}>
                <Alert
                  severity="info"
                  className="w-100"
                  data-test="replica-limit"
                >
                  The input value should be similar as of `1s` or `1m`
                </Alert>
              </Grid>
            </Grid> */}
          <Grid container spacing={2} >
            <Grid item md={3} xs={12}>
              {timeInterval(
                "Group Interval",
                "group_interval",
                notiDetails?notiDetails.group_interval:"",
                // unit.group_interval_unit,
                // "group_interval_unit"
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {timeInterval(
                "Group Wait",
                "group_wait",
                notiDetails?notiDetails.group_wait:"",
                // unit.group_wait_unit,
                // "group_wait_unit"
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {timeInterval(
                "Repeat Interval",
                "repeat_interval",
                notiDetails?notiDetails.repeat_interval:"",
                // unit.repeat_interval_unit,
                // "repeat_interval_unit"
              )}
            </Grid>
            <Grid item  md={3} xs={12}>
              {timeInterval(
                "Resolve Timeout",
                "resolve_timeout",
                notiDetails?notiDetails.resolve_timeout:"",
                // unit.resolve_timeout_unit,
                // "resolve_timeout_unit"
              )}
            </Grid>
          </Grid>
        </CardContent>
        {/* <Divider /> */}
        <CardActions>
          <Button
            onClick={() => handleSaveNoti()}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </CardActions>
      </Card>

      {openConfirm && (
        <ConfirmActionPopup
          open={openConfirm}
          handleAgree={handleConfirmPopupAgree}
          handleDisAgree={handleConfirmPopupDisagree}
          yesText="yes"
          noText="No"
          message="Are you sure you want to update notification?"
        />
      )}

    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  alertLists: state.AlertReducer.templateLists,
  notifiDetails: state.AlertReducer.notifiDetails,
});
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getNotifiAlertLists: (id: number) => dispatch(getNotifiAlertLists(id)),
    updateNotifiDetails: (id: number, jsonBody: any) => dispatch(updateNotifiDetails(id, jsonBody)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(AlertNotif);
type PropsFromRedux = ConnectedProps<typeof connector>;

