import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Transition } from "../../helpers/utils.ext";
import MuiTextField from "../textfield/MuiTextField";
import _ from "lodash";
import { Pod } from "../../models/Environment.model";
import MultiValueChipInput from "../emailinput/MultiValueChipInput";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { addRule } from "../../pages/environment/environmentinfo/alertmanager/redux/action";
import { CustomColors } from "../../constants/enums";

const useStyles = makeStyles(() => ({
  content: {
    padding: "5px",
  },
  silenceText: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: '24px'
  },
  dialogContent: {
    minWidth: 150
  },
  silenceContainerWrapper:{
    border: `1px solid ${CustomColors.other.border}`, 
    padding: '6px 12px', 
    borderRadius: 8, 
    width: 'fit-content'
  },
  saveContainer:{
    display: "flex", 
    justifyContent: "flex-end"
  }
}));

interface Props extends PropsFromRedux {
  openDialog: boolean;
  handleClose: () => void;
  rules: any;
  template: string;
}

interface IlabelState extends Pick<Pod, "labels" | "annotations"> {
  match_labels: Pod["labels"];
}

const renderContainerLabels = (
  labels: any,
  labelType?: "labels" | "annotations",
  updateLabelState?: (labelStateData: IlabelState) => void,
  labelState?: any
) => {
  let labelsStr;
  let labelsArr = [];

  const isTagDisabled = (tag: string): boolean => {
    if (!labelState) return false;

    const [tagKey] = tag.split(":");
    const match_lablelKeys = labelState;
    if (!match_lablelKeys) return false;

    return !!match_lablelKeys[tagKey.trim() as keyof typeof labelType];
  };

  const handleValues = (values: string[]) => {
    if (!labelType || !updateLabelState || !labelType || !labelState) return;

    let payloadObj: Pod["labels"] | Pod["annotations"] = {};
    // prepare the data for call back fxn
    values.forEach((label: string) => {
      let [tagKey, tagValue] = label.split(":");
      if (!tagKey || !tagValue) return;
      tagKey = tagKey.trim();
      tagValue = tagValue.trim();
      // keep below condition check until validation is written
      payloadObj[tagKey] = tagValue;
    });
    let labelStatekey = labelState[labelType as keyof typeof labelState];
    if (!labelStatekey) return;

    labelState[labelType as keyof typeof labelState] = payloadObj;

    updateLabelState(labelState);
  };

  if (Array.isArray(labels)) {
    labels.map((labelObj) => {
      labelsArr.push(`${labelObj.name}: ${labelObj.containerPort}`);
    });
  } else {
    for (const key in labels) {
      const value: string = labels[key as keyof typeof labels];
      labelsArr.push(`${key}: ${value}`);
    }
  }

  labelsStr = labelsArr.join(",");

  let tagsComponent;

  // if (labelsStr?.length > 0) {
  //   tagsComponent = (
  //     <Grid container spacing={1}>
  //       {labelsStr?.split(",").map((_tag, ind) => (
  //         <Grid
  //           item
  //           key={ind}
  //           style={{ maxWidth: "100%" }}
  //           data-test="tags-item"
  //         >
  //           <Tooltip title={_tag}>
  //             <Chip
  //               color={"primary"}
  //               variant="outlined"
  //               label={_tag}
  //               size="small"
  //               style={{ maxWidth: "100%" }}
  //             />
  //           </Tooltip>
  //         </Grid>
  //       ))}
  //     </Grid>
  //   );
  // }
  // else
  if (labelsStr.length) {
    tagsComponent = (
      <MultiValueChipInput
        data-test="tags-input"
        values={labelsStr.split(",")}
        handleValues={handleValues}
        disableAction={isTagDisabled}
      />
    );
  } else {
    tagsComponent = (
      <MultiValueChipInput
        data-test="tags-input"
        values={[]}
        handleValues={handleValues}
        disableAction={isTagDisabled}
      />
    );
  }
  return tagsComponent;
};

function EditDialog(props: Props) {
  const classes = useStyles();

  const [ruleDetails, setRuleDetails] = useState({
    alert: props.rules.alert ? props.rules.alert : "",
    expr: props.rules.expr ? props.rules.expr : "",
    for: props.rules.for ? props.rules.for : "",
    annotations: {
      summary: props.rules.annotations ? props.rules.annotations.summary : "",
      description: props.rules.annotations
        ? props.rules.annotations.description
        : "",
    },
    labels: props.rules.labels ? props.rules.labels : null,
    disabled: props.rules?props.rules.disabled:false
  });

  const handleChange = (value: any, toUpdate: string) => {
    if (toUpdate === "summary" || toUpdate === "description") {
      setRuleDetails((state) => {
        return {
          ...ruleDetails,
          annotations: { ...state.annotations, [toUpdate]: value },
        };
      });
    } else {
      setRuleDetails(() => {
        return { ...ruleDetails, [toUpdate]: value };
      });
    }
  };

  const textField = (
    name: string,
    multiLine: boolean,
    rowNo: number,
    desc: string,
    values: string,
    state: string
  ) => {
    return (
      <Grid container direction="column" spacing={0}>
        {/* <Grid item>
          <Typography variant="h5">{name}</Typography>
        </Grid> */}
        <Grid item>
          <MuiTextField
            value={values}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              handleChange(e.target.value, state)
            }
            margin="normal"
            multiline={multiLine}
            rows={rowNo}
            placeholder={desc}
            disabled={(state === "alert" || state === "expr")?true:false}
            label={name}
          />
        </Grid>
      </Grid>
    );
  };

  const updateLabelState = (labelStateData: any) => {
    setRuleDetails({ ...labelStateData });
  };

  const addRule = () => {
    props.addRule(props.environmentDetails?.id, props.template, ruleDetails);
    props.handleClose();
  };

  const handleDisabled = (e: React.ChangeEvent<HTMLInputElement>) =>{
    setRuleDetails(() => {
      return { ...ruleDetails, disabled: !ruleDetails.disabled };
    });
  }

  return (
    <>
      <Dialog
        open={props.openDialog}
        TransitionComponent={Transition}
        disableEscapeKeyDown={true}
        onClose={() => props.handleClose()}
        keepMounted
        data-test="main-container"
      >
        <DialogTitle>
          <Typography variant="h6" className="dialogtitle">Edit Role</Typography>
          <IconButton
            aria-label="close"
            size="small"
            className="right"
            onClick={() => props.handleClose()}
            data-test="close-popup"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {textField("Rule Name", false, 1, "", ruleDetails.alert, "alert")}
            </Grid>
            <Grid item xs={12}>
              {textField("Expression", false, 1, "", ruleDetails.expr, "expr")}
            </Grid>
            <Grid item md={6} xs={12}>
              {textField("Duration", false, 1, "", ruleDetails.for, "for")}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {/* <Typography variant="h4">Annotations</Typography> */}
              <Typography variant="body1">Annotations</Typography>
            </Grid>
            <Grid item xs={12}>
              {textField(
                "Summary",
                true,
                4,
                "",
                ruleDetails.annotations.summary,
                "summary"
              )}
            </Grid>
            <Grid item xs={12}>
              {textField(
                "Description",
                true,
                4,
                "",
                ruleDetails.annotations.description,
                "description"
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {/* <Typography variant="h5">Labels</Typography> */}
              <Typography variant="body1">Labels</Typography>
            </Grid>
            <Grid item xs={12}>
              {renderContainerLabels(
                ruleDetails?.labels,
                "labels",
                updateLabelState,
                ruleDetails
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.content}>
            <Grid xs={6} className="sliceContianer">
                <FormControlLabel
                  control={
                    // <Switch
                    //   name="bulk_edit"
                    //   color="primary"
                    //   checked={ruleDetails.disabled}
                    //   onChange={(e) => handleDisabled(e)}
                    //   // disabled={!this.hasValidUserVars()}
                    //   size="medium"
                    // />
                    <Checkbox
                        color="primary"
                        name="bulk_edit"
                        checked={ruleDetails.disabled}
                        onChange={(e) => handleDisabled(e)}
                      />
                  }
                  label={<Typography className={classes.silenceText}>Silence</Typography>}
                  labelPlacement="end"
                  className={classes.silenceContainerWrapper}
                />
            </Grid>
            <Grid
              xs={6}
              className={classes.saveContainer}
            >
              <Button
                onClick={() => addRule()}
                color="primary"
                variant="contained"
                // disabled={this.disableShare()}
                data-test="add-btn"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    addRule: (id: number, template: string, jsonBody: any) =>
      dispatch(addRule(id, template, jsonBody)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EditDialog);
