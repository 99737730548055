import React, { useState } from "react";
import { Grid, Card, CardContent, Tooltip, Button, Dialog, DialogTitle, DialogContent, Select, FormControl, MenuItem, DialogActions, Typography } from "@material-ui/core";
import { LabelHandler } from "../labelHandler/LabelHandler";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";
import { getDiffDays, getPodStatusColor } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import { Pod } from "../../models/Environment.model";
import { AppConstants } from "../../constants/appconstants";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CustomColors } from "../../constants/enums";

type Props = {
  podDetail: Pod
  handleClick: (pod: Pod)=>void
  restartCount: number
  handleRestartPod: (envId: number, json: any) => void
  envId: number
}

const PodsOverviewCard = ({ podDetail, handleClick, restartCount, handleRestartPod, envId }: Props) => {
  const [t] = useTranslation();

  const [openPopup, setOpenPopup] = useState(false);
  const [restartType, setRestartType] = useState("normal");

  const checkContainerReady = podDetail.status?.containerStatuses ? podDetail.status.containerStatuses.every((el) => el.ready === true) : false;

  const handleRestartPopUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenPopup(true);
  }

  const handleChangeType = (e: React.ChangeEvent<{ value: unknown }>) => {
    setRestartType(e.target.value as string);
  };

  const handleClosePopup = () => {
    setOpenPopup(false)
  }

  const handleRestart = () => {
    const jsonBody = {
      type:restartType,
      name:podDetail.name
    }
    handleRestartPod(envId, jsonBody)
    setOpenPopup(false)
  }

  return (
    <>
    <Card
      className="m-b-20"
      data-test="pods-overview-container"
      onClick={() => handleClick(podDetail)}
      style={{ cursor: "pointer"}}
    >
      <CardContent>
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={10}>
              {/* <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                  <Tooltip title={checkContainerReady?podDetail.status.phase:AppConstants.PodStatus.Pending} data-test="pod-status-tooltip">
                    <div
                      style={{
                        width: 15,
                        height: 15,
                        backgroundColor: getPodStatusColor(checkContainerReady?podDetail.status.phase:AppConstants.PodStatus.Pending),
                        borderRadius: "50%",
                      }}
                      data-test="pod-status-color"
                    >
                    </div>
                  </Tooltip>
                  <p
                    className="commitmsg oneLine"
                    title={podDetail.name}
                    data-test="pod-name"
                  >
                    {podDetail.name}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={3} sm={4} xs={6} className="header-details-grid">
                    <LabelHandler
                      icon={<SettingsOutlinedIcon />}
                      iconTooltip={t("Pods.podName")}
                      label={podDetail.namespace}
                      labelTooltip={podDetail.namespace}
                      data-test="pod-namespace"
                    />
                  </Grid>
                  <Grid item md={3} sm={4} xs={6} className="header-details-grid">
                    <LabelHandler
                      icon={<DateRangeOutlinedIcon />}
                      iconTooltip={t("Pods.podAge")}
                      label={getDiffDays(podDetail.created_at, true)}
                      labelTooltip={getDiffDays(podDetail.created_at, true)}
                      data-test="pod-age"
                    />
                  </Grid>
                  <Grid item md={2} sm={4} xs={6} className="header-details-grid">
                    <LabelHandler
                      icon={<RefreshOutlinedIcon />}
                      iconTooltip={t("Pods.numberOfRestarts")}
                      label={String(restartCount ?? 0)}
                      labelTooltip={restartCount.toString()}
                      data-test="pod-restarts"
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid component="div" style={{display: 'flex', gap: 16}}>
                <div>
                  <Tooltip title={checkContainerReady?podDetail.status.phase:AppConstants.PodStatus.Pending} data-test="pod-status-tooltip">
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: getPodStatusColor(checkContainerReady?podDetail.status.phase:AppConstants.PodStatus.Pending),
                        borderRadius: "50%",
                        marginTop: 12
                      }}
                      data-test="pod-status-color"
                    >
                    </div>
                  </Tooltip>
                </div>
                <div >
                  <Typography title={podDetail.name} data-test="pod-name" variant="h5">{podDetail.name}</Typography>
                  <div style={{display: 'flex', gap: 24, marginTop: 8}}>
                    <div>
                      <LabelHandler
                        icon={<SettingsOutlinedIcon style={{color: CustomColors.Grey.border}}/>}
                        iconTooltip={t("Pods.podName")}
                        label={podDetail.namespace}
                        labelTooltip={podDetail.namespace}
                        data-test="pod-namespace"
                        labelType="Grey"
                      />
                    </div>
                    <div>
                      <LabelHandler
                        icon={<DateRangeOutlinedIcon style={{color: CustomColors.Info.Dark}}/>}
                        iconTooltip={t("Pods.podAge")}
                        label={getDiffDays(podDetail.created_at, true)}
                        labelTooltip={getDiffDays(podDetail.created_at, true)}
                        data-test="pod-age"
                        labelType="Info"
                      />
                    </div>
                    <div>
                      <LabelHandler
                        icon={<RefreshOutlinedIcon style={{color: CustomColors.Success.Dark}}/>}
                        iconTooltip={t("Pods.numberOfRestarts")}
                        label={String(restartCount ?? 0)}
                        labelTooltip={restartCount.toString()}
                        data-test="pod-restarts"
                        labelType="Success"
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={2} justifyContent="flex-end">
              <Button onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleRestartPopUp(e)} 
              color="primary"
              variant="contained"
              size = "small"
              >Restart</Button>
            </Grid>
        </Grid>
      </CardContent>
    </Card>

      <Dialog
        disableEscapeKeyDown={true}
        open={openPopup}
        onClose={() => handleClosePopup()}
        keepMounted
      >
        <DialogTitle data-test="title-container">
          <Typography className="dialogtitle" data-test="title-text">
            Restart
          </Typography>

          <IconButton
            aria-label="close"
            size="small"
            className="right"
            onClick={() => handleClosePopup()}
            data-test="close-button"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers data-test="content-container">
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <Typography variant="h5">
                  Type
                </Typography>
                <FormControl
                  className="w-100"
                  variant="outlined"
                  margin="normal"
                >
                  <Select
                    name="provider"
                    value={restartType}
                    color="primary"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleChangeType(e)}
                    data-test="provider-select"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="force">Force</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>          
        </DialogContent>
        <DialogActions>
          <Grid container justify={"flex-end"}>
            <Grid item>
            <Button
                onClick={() => handleClosePopup()}
                size="small"
                color="secondary"
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                className="m-l-10"
                onClick={() => handleRestart()}
                color="primary"
                variant="contained"
                size="small"
              >
                Restart
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PodsOverviewCard;
