import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    Card,
    ButtonBase,
    InputLabel,
} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import { connect, ConnectedProps } from "react-redux";
import BackdropLoader from "../../components/loader/BackdropLoader";
import MarkdownField from "../../components/markdownfield/MarkdownField";
import MultiFileUpload from "../../components/multifileupload";
import MuiTextField from "../../components/textfield/MuiTextField";
import { fetchEnvironmentsByAppId } from "../app/redux/actions";
import { getProjectListApiCall } from "../project/projectlist/redux/actions";
import {
    fetchAppListByPID,
    getSidebarProjectDetails,
} from "../project/redux/actions";
import { createUserTickets, getSupportTypes } from "./redux/actions";
import { updateBreadcrumb } from "../project/redux/actions";
import paths from "../../constants/paths";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from 'react-i18next';
import { Dispatch } from "redux";
import { History } from 'history';
import { FileData } from "../../models/Support.model";

interface FileDetails {
  path: string; 
}

interface IdValue {
  id: number | string
}

/* istanbul-ignore-next */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  styleGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  labelStyle: {
    color: "black",
    fontWeight: "bolder",
    textAlign: "start",
    marginBottom: theme.spacing(1),
  },
  customButtonStyle: {
    height: 40,
    width: "6rem",
    minWidth: "200px",
    maxWidth: "100%",
  },
  styleParagraph: {
    // textAlign: 'center',
    // fontSize: "16px",
    // color: "black",
    // fontWeight: "bolder",
    marginBottom: 16
  },
  styleGridText: {
    color: "#0057fa",
    fontWeight: "bolder",
  },
  spanStyle: {
    color: "#0057fa",
  },
  styleGridValue: {
    color: "gray",
    fontWeight: "bolder",
  },
  headerText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  fileStyle: {
    padding: "2%",
  },
}));

interface Props extends PropsFromRedux{
    history: History
}

interface Payload {
  reply: string;
  ticketId: number | string;
  userId: number | string;
  file?: { fileName: string; fileType: string; fileValue: string; }[];
}


export function CreateTicketForm (props: Props) {
    const [ t ] = useTranslation();
    const [titleValue, setTitleValue] = useState<string>("");
    const [supportTypeValue, setSupportTypeValue] = useState<string | number>("1");
    const [priority, setPriorityValue] = useState<string>("Medium");
    const [issueType, setIssueType] = useState<number>(0);
    const [projectValue, setProjectValue] = useState<number>(0);
    const [appValue, setAppValue] = useState<number>(0);
    const [envValue, setEnvValue] = useState<number>(0);
    const [value, setValue] = React.useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(true);
    const [file, setFile] = useState<File[]>([]);
    const [errors, setErrors] = useState<any>({});
    const [helperText, setHelperText] = useState<any>({});
    const [projects, setProjects] = useState<any>([]);
    const [apps, setApps] = useState<any>([]);
    const [environments, setEnvironments] = useState<any>([]);
    const [issueTypes, setIssueTypes] = useState<any>([]);
    const firstRender = useRef<boolean>(true);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const classes = useStyles();

    useEffect(() => {
        const breadcrumbData = [
          {
            name: "Tickets",
            path: paths.SUPPORT,
          },
          {
            name: "CreateTicket",
            path: paths.TICKET_CREATE,
          },
        ];
        props.updateBreadcrumb(breadcrumbData);
        props.getSidebarProjectDetails();
        props.getSupportTypes();
    }, []);

    useEffect(() => {
        setProjects(props.sidebarProjectDetails);
    }, [props.sidebarProjectDetails]);
    
    const setFiles = (files: File[]) => {
        setFile(files);
    };

    useEffect(() => {
        setEnvValue(0);
        setAppValue(0);
        setProjectValue(0);
    }, [projects]);

    useEffect(() => {
        setEnvValue(0);
        setAppValue(0);
    }, [apps]);
    
    const navToTicketList = () => {
        props.history.push({
            pathname: paths.SUPPORT,
        });
    };

    const formValidation = () => {
        if (titleValue === "") {
            return true;
        } else {
            setButtonDisabled(false);
            return false;
        }
    };

    useEffect(() => {
        if (firstRender.current) {
          firstRender.current = false;
          setDisabled(false);
        } else {
          setDisabled(formValidation());
          if (titleValue.trim().length <= 0) {
            setErrors({
              ...errors,
              titleValue: true,
            });
            setHelperText({
              ...helperText,
              titleValue: "Title cannot be empty.",
            });
          } else {
            setErrors({
              ...errors,
              titleValue: false,
            });
            setHelperText({
              ...helperText,
              titleValue: "",
            });
          }
        }
    }, [titleValue]);

    useEffect(() => {
        if (appValue > 0) {
          const app = apps?.find((a: IdValue) => {
            return a.id === appValue;
          });
          setEnvironments(app?.environments);
        } else {
          setEnvironments([]);
        }
        setEnvValue(0);
    }, [appValue]);

    useEffect(() => {
        if (projectValue > 0) {
            const proj = props.sidebarProjectDetails?.find((p: IdValue) => {
            return p.id === projectValue;
            });
            setApps(proj?.applications);
            setEnvironments([]);
        } else {
            setAppValue(0);
            setApps([]);
        }
    }, [projectValue]);
    
    useEffect(() => {
        if (props.supportTypes?.length > 0) {
            setSupportTypeValue(props.supportTypes[0].id);
        }
    }, [props.supportTypes]);
    
    useEffect(() => {
        if (props.supportTypes?.length > 0) {
            const support = props.supportTypes?.find((_support: IdValue) => {
            return _support.id === supportTypeValue;
            });
            if (support?.issues) {
            setIssueTypes(support.issues);
            if (support.issues.length > 0) {
                setIssueType(support.issues[0].id);
            }
            } else {
            setIssueTypes([]);
            }
        }
    }, [supportTypeValue]);

    const titleValueChangeHandler = (event: ChangeEvent<{value: string}>) => {
        setTitleValue(event.target.value);
    };

    const supportTypeValueChangeHandler = (supportId: string) => {
      console.log(supportId); 
      setSupportTypeValue(supportId); 
    };

    const priorityChangeHandler = (priorityLevel: string) => {
      console.log(priorityLevel);
      
        setPriorityValue(priorityLevel);
    };

    const issueTypeChangeHandler = (event: any) => {
      console.log(event.target.value);
        setIssueType(parseInt(event.target.value));
    };

    const projectValueChangeHandler = (event: any) => {
      console.log(event.target.value);
        setProjectValue(event.target.value);
    };

    const appValueChangeHandler = (event: any) => {
      console.log(event.target.value);
        setAppValue(event.target.value);
    };

    const envValueChangeHandler = (event: any) => {
      console.log(event.target.value);
        setEnvValue(event.target.value);
    };

    const createSuccess = (ticket: any) => {
      console.log(ticket);
      
        props.history.push({
          pathname: paths.TICKET_DETAIL.replace(":id", ticket.id),
          state: {
            id: ticket.id,
            ticket: ticket,
          },
        });
    };
    
    const createHandler = () => {
        let app = null;
        let env = null;
        let pro = null;
        if (supportTypeValue === 1) {
            pro = projectValue === 0 ? null : projectValue;
            app = appValue === 0 ? null : appValue;
            env = envValue === 0 ? null : envValue;
        }
        const ticket = {
            application: app,
            description: value,
            environment: env,
            issueType: issueType,
            priority: priority,
            project: pro,
            supportType: supportTypeValue,
            title: titleValue.trim(),
            userId: props.currentUser.id,
        };
        let payload = {
            ticket,
        };
        if (file?.length > 0) {
            const uploadFiles: Payload['file'] = [];
            file.forEach((f: any) => {
              const _f = {
                fileName: f.file?.name ?? '',  
                fileType: f.file?.type ?? '',  
                fileValue: f.url,
              };
                uploadFiles.push(_f);
            });
            (payload as any).files = uploadFiles;
        }
        props.createUserTickets(payload, createSuccess);
    };
    
    const isValid = () => {
        let valid = true;
        if (value?.trim()?.length <= 0) {
            valid = false;
        }
        return valid;
    };

    return(
      <div className={classes.root} data-test="main-container">
      <div>
        <ButtonBase
          onClick={() => navToTicketList()}
          style={{ color: "#357dfd" }}
          data-test="back-button"
        >
          <ArrowBackIcon fontSize="small" />{" "}
          <span className="jobNav">{t('Support.CreateTicketForm.ticketList')}</span>
        </ButtonBase>
      </div>
      <div className={classes.headerText} data-test="header-component">
        <Typography color="textPrimary" variant="h6">
          {t('Support.CreateTicketForm.createTicket')}
        </Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Card className={classes.paper}>
            <Grid container spacing={1} className={classes.styleGrid}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl variant="outlined" className="w-100">
                  <MuiTextField
                    data-test="title-field"
                    id="Title"
                    value={titleValue}
                    placeholder="Title for your ticket"
                    required
                    error={errors.titleValue}
                    helperText={helperText.titleValue}
                    variant="outlined"
                    onChange={(e) => titleValueChangeHandler(e)}
                    label={t('Support.CreateTicketForm.title')}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.styleGrid}>
              <Grid item xs={12}>
                {props.supportTypes?.length > 0 && (
                  <>
                    <Typography variant="body2">{t('Support.CreateTicketForm.supportType')}{" "}</Typography>
                    <div style={{display: 'flex', alignItems: 'center', gap:20, marginTop: 20}}>
                      {props.supportTypes?.map((support: any) => (
                        <Button 
                          variant="contained"
                          key={support.id} 
                          value={support.id}
                          onClick={() => supportTypeValueChangeHandler(support.id)}
                          color={support.id !== supportTypeValue ? "secondary" : "primary"}
                        >
                          {support.title}{" "}
                        </Button>
                      ))}
                    </div>
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <>
                  <Typography variant="body2">{t('Support.CreateTicketForm.priority')}{" "}</Typography>
                  <div style={{display: 'flex', alignItems: 'center', gap: 20, marginTop: 20}}>
                    {['High', 'Medium', 'Low'].map((priorityLevel: string) => (
                      <Button 
                        variant="contained"
                        key={priorityLevel} 
                        value={priorityLevel}
                        onClick={() => priorityChangeHandler(priorityLevel)} 
                        color={priorityLevel !== priority ? "secondary" : "primary"}
                      >
                        {t(`Support.CreateTicketForm.${priorityLevel.toLowerCase()}`)}
                      </Button>
                    ))}
                  </div>
                </>
              </Grid>
            </Grid>

            {issueTypes?.length > 0 && (
              <Grid container spacing={1} className={classes.styleGrid} style={{marginTop: 20}}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" className="w-100">
                    <InputLabel>{t('Support.CreateTicketForm.issueType')}</InputLabel>
                    <Select
                      data-test="issueTypes-field"
                      labelId="demo-simple-select-outlined-label"
                      name="issueType"
                      value={issueType}
                      input={<OutlinedInput />}
                      
                      onChange={issueTypeChangeHandler}
                    >
                      {issueTypes.map((issue: any) => (
                        <MenuItem key={issue.id} value={issue.id}>
                          {issue.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {supportTypeValue === 1 && (
              <div data-test="supportTypeValue-component">
                <Grid container spacing={2} className={classes.styleGrid}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" className="w-100">
                      <InputLabel>{t('Support.CreateTicketForm.project')}</InputLabel>
                      {projects?.length > 0 ? (
                        <Select
                          data-test="select-project"
                          input={<OutlinedInput />}
                          labelId="demo-simple-select-outlined-label"
                          name="project"
                          value={projectValue}
                          label={t('Support.CreateTicketForm.project')}
                          onChange={projectValueChangeHandler}
                        >
                          <MenuItem value={0}> {t('Support.CreateTicketForm.selectProject')} </MenuItem>
                          {projects?.map((proj: any, ind: number) => (
                            <MenuItem value={proj.id} key={ind}>
                              {" "}
                              {proj.name}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography variant="h5" className="m-t-5">
                        {t('Support.CreateTicketForm.noProjects')}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="outlined" className="w-100">
                      {apps?.length > 0 && <InputLabel>{t('Support.CreateTicketForm.app')}</InputLabel>}
                      {apps?.length > 0 ? (
                        <Select
                          data-test="select-app"
                          input={<OutlinedInput />}
                          labelId="demo-simple-select-outlined-label"
                          name="app"
                          value={appValue}
                          //variant='outlined'
                          label={t('Support.CreateTicketForm.app')}
                          onChange={appValueChangeHandler}
                        >
                          <MenuItem value={0}> {t('Support.CreateTicketForm.selectApp')} </MenuItem>
                          {apps?.map((app: any, ind: number) => (
                            <MenuItem value={app.id} key={ind}>
                              {" "}
                              {app.name}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography variant="body2" className="m-t-5 m-l-5">
                        {t('Support.CreateTicketForm.noApp')}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" className="w-100">
                      {/* <FormLabel className={classes.labelStyle}>
                        {" "}
                        {t('Support.CreateTicketForm.env')}{" "}
                      </FormLabel> */}
                      {environments?.length > 0 && <InputLabel>{t('Support.CreateTicketForm.env')}</InputLabel>}
                      {environments?.length > 0 ? (
                        <Select
                          data-test="select-environment"
                          input={<OutlinedInput />}
                          name="env"
                          value={envValue}
                          //variant='outlined'
                          label={t('Support.CreateTicketForm.env')}
                          onChange={envValueChangeHandler}
                        >
                          <MenuItem value={0}>
                            {" "}
                            {t('Support.CreateTicketForm.selectEnv')}{" "}
                          </MenuItem>

                          {/* <MenuItem value={0}> Select Environment  </MenuItem> */}
                          {environments?.map((env : any, ind: number) => (
                            <MenuItem value={env.id} key={ind}>
                              {" "}
                              {env.name}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography variant="body2" color="primary" className="m-t-5 m-l-5">{t('Support.CreateTicketForm.noEnv')}</Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            <div>
              <MarkdownField value={value} onChange={(newValue?: string) => setValue(newValue || "")} readOnly={false} data-test="markdown-field"/>

              <MultiFileUpload reset={false} setFile={setFiles} data-test="multifile-upload"/>
            </div>
            <br />
            <Grid container justify="flex-end">
              <Button
                data-test="create-button"
                variant="contained"
                color="primary"
                className={classes.customButtonStyle}
                onClick={createHandler}
                disabled={
                  disabled || buttonDisabled || !isValid() ? true : false
                }
                size="small"
              >
                {" "}
                {t('Support.CreateTicketForm.create')}
              </Button>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card className={classes.paper}>
            <Grid spacing={1} container>
              <Grid
                className={classes.styleParagraph}
                item
                xs={12}
                data-test="responseTime-component"
              >
                <Typography variant="h6"> {t('Support.CreateTicketForm.responseTime')} </Typography>
              </Grid>
            </Grid>

            <Grid spacing={1} container>
              <Grid
                className={classes.styleGridText}
                item
                xs={12}
                sm={5}
                md={5}
                data-test="days-component"
              >
                <Typography variant="subtitle2">{t('Support.CreateTicketForm.days')}</Typography>
              </Grid>
              <Grid
                className={classes.styleGridValue}
                item
                xs={12}
                sm={7}
                md={7}
                data-test="time-component"
              >
                <Typography variant="body2" color="textSecondary">{t('Support.CreateTicketForm.time')}</Typography>
              </Grid>
            </Grid>

            <Grid spacing={1} container>
              <Grid
                className={classes.styleGridText}
                item
                xs={12}
                sm={5}
                md={5}
                data-test="generalIssue-component"
              >
                <Typography variant="subtitle2">{t('Support.CreateTicketForm.generalIssue')}</Typography>
              </Grid>
              <Grid
                className={classes.styleGridValue}
                item
                xs={12}
                sm={7}
                md={7}
                data-test="generalIssueTime-component"
              >
                <Typography variant="body2" color="textSecondary">4 Hour</Typography>
              </Grid>
            </Grid>

            <Grid spacing={1} container>
              <Grid
                className={classes.styleGridText}
                item
                xs={12}
                sm={5}
                md={5}
                data-test="businessCritical-component"
              >
                <Typography variant="subtitle2">{t('Support.CreateTicketForm.critical')}</Typography>
              </Grid>
              <Grid
                className={classes.styleGridValue}
                item
                xs={12}
                sm={7}
                md={7}
                data-test="businessCriticalHour-component"
              >
                <Typography variant="body2" color="textSecondary">{t('Support.CreateTicketForm.hour')}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {props.isLoading && <BackdropLoader message={t('Support.CreateTicketForm.data')} data-test="data-loading" />}

      {props.envListLoading && (
        <BackdropLoader message={t('Support.CreateTicketForm.environment')} data-test="env-loading" />
      )}

      {props.fetchingTypes && <BackdropLoader message={t('Support.CreateTicketForm.fetching')} data-test="fetching-types" />}
      {props.creatingTicket && <BackdropLoader message={t('Support.CreateTicketForm.creating')} data-test="creating-ticket" />}
    </div>
    )
}

/* istanbul-ignore-next */
const mapStateToProps = (state: any) => ({
    projectList: state.ProjectListReducer.projectList,
    appList: state.ProjectReducer.appList,
    environmentList: state.AppsReducer.environmentList,
    envListLoading: state.AppsReducer.envListLoading,
    isLoading: state.ProjectListReducer.isLoading,
    currentUser: state.AuthReducer.user,
    creatingTicket: state.SupportReducer.creatingTicket,
    fetchingTypes: state.SupportReducer.fetchingTypes,
    supportTypes: state.SupportReducer.supportTypes,
    issueTypes: state.SupportReducer.issueTypes,
    sidebarProjectDetails: state.ProjectReducer.sidebarProjectDetails,
  });
  
/* istanbul-ignore-next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        getProjectListApiCall: (history: any) =>
        dispatch(getProjectListApiCall(history)),
        fetchAppListByPID: (payload: any) => dispatch(fetchAppListByPID(payload)),
        fetchEnvironmentsByAppId: (payload: any) =>
        dispatch(fetchEnvironmentsByAppId(payload)),
        createUserTickets: (payload: any, callback: any) =>
        dispatch(createUserTickets(payload, callback)),
        getSupportTypes: () => dispatch(getSupportTypes()),
        getSidebarProjectDetails: () => dispatch(getSidebarProjectDetails()),
        updateBreadcrumb: (payload: any) => dispatch(updateBreadcrumb(payload)),
    };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(CreateTicketForm);

type PropsFromRedux = ConnectedProps<typeof connector>;