import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Typography,
  makeStyles,
  Button,
  Box,
} from "@material-ui/core";
import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import PluginConfigForm from "../../../../../components/pluginconfigform/PluginConfigForm";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { updateEnvironment } from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: "0 !important",
  },
  expand: {
    transform: "rotate(0deg)",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  table: {
    minWidth: 650,
  },
  cardHeaderAction: {
    marginTop: 8,
    marginRight: 20
  }
}));

interface Props extends PropsFromRedux {}

function SystemVariable(props: Props) {
  const classes = useStyles();

  const [expandSysVar, setExpandSysVar] = useState(true);
  const [settingVariable, setSettingVariable] = useState({
    executionTime: "",
    uploadSizeMb: ""
  });
  const [isSystemVariablesError, setIsSystemVariablesError] = useState([]);
  const [valueChange, setValueChange] = useState(false);

  const handleSettingVariablesChange = (variables: any, errorsList: any) => {
    setSettingVariable(variables);
    setIsSystemVariablesError(errorsList);
    setValueChange(true);
  };

  const handleSave = () => {
    const setting = {
      setting: {...settingVariable},
    };
    props.updateEnvironment(props.environmentDetails.id, setting, 0, null);
  };
  
  return (
    <>
      <div>
        <Card className="m-t-20">
          <CardHeader
            // className={classes.cardHeader}
            classes={{ action: classes.cardHeaderAction }}
            title={
              <Typography variant="h6" display="inline">
                System Variables
              </Typography>
            }
            avatar={
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expandSysVar,
                })}
                onClick={() => setExpandSysVar(!expandSysVar)}
              >
                <ExpandMoreIcon />
              </IconButton>
            }
            action={ 
              expandSysVar && props.settingConfig?.setting && Object.keys(props.settingConfig.setting?.properties).length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSave()}
                  disabled={!valueChange || settingVariable.executionTime === "" || settingVariable.uploadSizeMb === ""}
                  size="small"
                >
                  Save
                </Button>
              )
            }
          />
          <Collapse in={expandSysVar} data-test="card-content">
            <Divider />
            <br />
            <CardContent data-test="not-applied-card-content">
              <div style={{ padding: "0px" }}>
                <PluginConfigForm
                  pluginVersionConfig={props.settingConfig?.setting ?? {}}
                  handleValueChange={handleSettingVariablesChange}
                  initialValues={
                    props.environmentDetails?.setting ?? {}
                  }
                  isErrors={isSystemVariablesError}
                  gridItemOccupency={4}
                  ignoreAlternateColour={true}
                />
              </div>
              {
                (!props.settingConfig?.setting?.properties || Object.keys(props.settingConfig.setting?.properties).length === 0) && 
                <Typography variant="body1" align="center">No system variables available</Typography>
              }
            </CardContent>
          </Collapse>
        </Card>
      </div>
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  settingConfig: state.EnvironmentReducer.settingConfig,
  environmentDetails: state.EnvironmentReducer.environmentDetails,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    updateEnvironment: (id: number, jsonBody: any, source: number, changeHash: null) =>
      dispatch(updateEnvironment(id, jsonBody, source, changeHash)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SystemVariable);
