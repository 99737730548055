import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress,
  Box,
} from "@material-ui/core";
import InsertChartIcon from "@material-ui/icons/InsertChartOutlined";
import { useTranslation } from 'react-i18next';

type Props = {
  title: string,
  used: number,
  total: number,
  message: string,
  CardIcon: JSX.Element
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  progress: {
    marginTop: theme.spacing(0.5),
  },
}));

export const TasksProgress = (props: Props) => {
  const { ...rest } = props;

  const classes = useStyles();

  const [t] = useTranslation();

  return (
    <Card {...rest} className={clsx(classes.root)} data-test="main-container" elevation={1}>
      <CardContent>
        {/* <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
              data-test="title-container"
            >
              {props.title ?? t('MessageHere')}
            </Typography>
            <Typography data-test="progressCardValue" variant="h3">
              {props.used}/{props.total}
            </Typography>
            <Typography variant="body2" data-test="message-container"> {props.message} </Typography>
          </Grid>
          <Grid item>
            <Avatar
              className={classes.avatar}
              // colorDefault={props.color ?? "primary"}
              data-test="avatar-container"
            >
              {props.CardIcon ?? <InsertChartIcon className={classes.icon} />}
            </Avatar>
          </Grid>
        </Grid> */}
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
              {props.CardIcon ?? <InsertChartIcon className={classes.icon} />}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              data-test="title-container"
            >
              {props.title ?? t('MessageHere')}
            </Typography>
            <LinearProgress
              className={classes.progress}
              value={(props.used / props.total) * 100}
              variant="determinate"
            />
            <Box component="div" style={{marginTop: 4, display: 'flex', justifyContent: 'end'}}>
              <Typography data-test="progressCardValue" variant="subtitle2">
                <Typography variant="subtitle2" color="textSecondary" component="span">{props.used}</Typography> /{props.total} {props.message} 
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string,
};

export default TasksProgress;
