import React from "react";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  Divider,
  IconButton,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  // FormControlLabel,
  // Checkbox,
  InputAdornment,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
import MuiTextField from "../../../../components/textfield/MuiTextField";
import InfoPopupContainer from "../../../../components/infopopup/InfoPopupContainer";
import { AppConstants } from "../../../../constants/appconstants";
import { useFormik } from "formik";
import { validateInputField } from "../../../../helpers/utils";
import { ChartRepoModel,AuthData } from "../../../../models/HelmCharts.model";


type Values = {
  name: string;
  url: string;
  repoType: string;
  repoAuth: string;
  authHeader: string;
  username: string;
  password: string;
  token: string;
  filters: string;
};

type Payload = Omit<ChartRepoModel, "id" | "organization_id" | "Filter">;

interface Props {
  action(payload: Payload, id?: string): void;
  editObject: ChartRepoModel | null;
  handleCancelPopUp: () => void;
  open: boolean;
}

export const ChartRepositoryModal = (props:Props) => {
  const [t] = useTranslation();
  const [isShowPassword, setIsShowPassword] = React.useState(false);
  // const [packageCheck, setPackageCheck] = React.useState(false)
  // const [regexCheck, setRegexCheck] = React.useState(false)
  const toggleEyeIcon = () => {
    setIsShowPassword(!isShowPassword);
  };

  const submitAction = (values:Values) => {
    let payload:Payload = {
      name: values.name,
      url: values.url,
      filter: values.filters,
      repo_type: values.repoType,
    };

    let authData:AuthData = {};
    if (values.repoAuth === AppConstants.repositoryAuthTypes.BASIC) {
      authData["username"] = values.username;
      authData["password"] = values.password;
    } else if (values.repoAuth === AppConstants.repositoryAuthTypes.CUSTOM) {
      authData["header"] = values.authHeader;
    } else if (values.repoAuth === AppConstants.repositoryAuthTypes.BEARER) {
      authData["token"] = values.token;
    }
    let authorization = {
      type: values.repoAuth,
      data: { ...authData },
    };
    payload.authorization = { ...authorization };
    if (props.editObject ) {
      props.action(payload, props.editObject.id);
    } else {
      props.action(payload);
    }
  };

  const validate = (values:Values) => {
    let errors: {
      name?: string;
      url?: string;
      username?: string;
      password?: string;
      token?: string;
      authHeader?: string;
    } = {};
    if (!values.name) {
      errors.name = t("Organization.ChartRepoTab.nameError");
    } else {
      let nameValid = validateInputField(values.name);
      if (nameValid.message) {
        errors.name = nameValid.message;
      }
    }

    if (!values.url) {
      errors.url = t("Organization.ChartRepoTab.urlError");
    } else {
      let urlValid = validateInputField(values.url, "url");
      if (urlValid.message) {
        errors.url = t(urlValid.message);
      }
    }

    if (values.repoAuth === AppConstants.repositoryAuthTypes.BASIC) {
      if (!values.username) {
        errors.username = t("Organization.ChartRepoTab.userNameError");
      }
      if (!values.password) {
        errors.password = t("Organization.ChartRepoTab.passwordError");
      }
    } else if (values.repoAuth === AppConstants.repositoryAuthTypes.BEARER) {
      if (!values.token) {
        errors.token = t("Organization.ChartRepoTab.tokenError");
      }
    } else if (values.repoAuth === AppConstants.repositoryAuthTypes.CUSTOM) {
      if (!values.authHeader) {
        errors.authHeader = t("Organization.ChartRepoTab.headerError");
      }
    }

    return errors;
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      name: props.editObject?.name ?? "",
      url: props.editObject?.url ?? "",
      repoType: props.editObject?.repo_type ?? "helm",
      repoAuth:
        props.editObject?.authorization?.type ??
        AppConstants.repositoryAuthTypes.PUBLIC,
      authHeader: props.editObject?.authorization?.data?.header ?? "",
      username: props.editObject?.authorization?.data?.username ?? "",
      password: props.editObject?.authorization?.data?.password ?? "",
      token: props.editObject?.authorization?.data?.token ?? "",
      filters: props.editObject?.filter ?? props.editObject?.Filter ?? "",
    },
    enableReinitialize: true,
    onSubmit: (_values) => submitAction(_values),
    validate: validate,
  });


  const handleClick =()=>{
    handleSubmit();
  }

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleCancelPopUp}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      disableBackdropClick={true}
      data-test="main-container"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Typography className="dialogtitle" variant="h6">
          {props.editObject
            ? t("Organization.ChartRepoTab.editRepository")
            : t("Organization.ChartRepoTab.addRepository")}
        </Typography>
        <IconButton
          aria-label="close"
          size="small"
          className="right"
          onClick={props.handleCancelPopUp}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <Typography variant='body1' >Repository Name*</Typography> */}
              <MuiTextField
                name="name"
                value={values.name}
                onChange={handleChange}
                type="text"
                label={t("Organization.ChartRepoTab.nameField")}
                margin="normal"
                data-test="name-field"
                error={!!touched.name && !!errors.name}
                onBlur={handleBlur}
                helperText={touched.name && errors.name ? errors.name:undefined}
                placeholder={t("Organization.ChartRepoTab.repoName")}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <Typography variant='body1' >Repository Name*</Typography> */}
              <MuiTextField
                name="url"
                value={values.url}
                onChange={handleChange}
                type="text"
                label={t("Organization.ChartRepoTab.urlField")}
                margin="normal"
                data-test="url-field"
                onBlur={handleBlur}
                error={!!touched.url && !!errors.url}
                helperText={touched.url && errors.url ? errors.url : ""}
                placeholder={t("Organization.ChartRepoTab.repoURL")}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {/* <Typography variant='body1' >Repository Type *</Typography> */}
                  <InfoPopupContainer
                    label={t("Organization.ChartRepoTab.repoTypeField")}
                    message="Repository Type"
                  />
                  <FormControl
                    className="w-100"
                    // error={""}
                    variant="outlined"
                    margin="normal"
                  >
                    <Select
                      name="repoType"
                      value={values.repoType}
                      color="primary"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-test="repoType-field"
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="helm">Helm Repository</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <InfoPopupContainer
                    label={t("Organization.ChartRepoTab.repoAuthField")}
                    message="Repository Authorization Method"
                  />

                  <FormControl
                    className="w-100"
                    // error={""}
                    variant="outlined"
                    margin="normal"
                  >
                    <Select
                      name="repoAuth"
                      value={values.repoAuth}
                      color="primary"
                      onChange={handleChange}
                      data-test="repoAuth-field"
                      onBlur={handleBlur}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      {Object.keys(AppConstants.repositoryAuthTypes).map(
                        (repo, ind) => (
                          <MenuItem
                            key={ind}
                            value={AppConstants.repositoryAuthTypes[repo as keyof typeof AppConstants.repositoryAuthTypes]}
                          >
                            {AppConstants.repositoryAuthTypes[repo as keyof typeof AppConstants.repositoryAuthTypes]}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {values.repoAuth === AppConstants.repositoryAuthTypes.CUSTOM && (
              <Grid item xs={12}>
                <MuiTextField
                  name="authHeader"
                  value={values.authHeader}
                  onChange={handleChange}
                  type="text"
                  label={t("Organization.ChartRepoTab.authHeader")}
                  margin="normal"
                  data-test="authHeader-field"
                  onBlur={handleBlur}
                  error={!!touched.authHeader && !!errors.authHeader}
                  helperText={touched.authHeader && errors.authHeader? errors.authHeader:""}
                  placeholder={t("Organization.ChartRepoTab.authHeader")}
                />
              </Grid>
            )}

            {values.repoAuth === AppConstants.repositoryAuthTypes.BASIC && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MuiTextField
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    type="text"
                    label={t("Organization.ChartRepoTab.username")}
                    margin="normal"
                    data-test="username-field"
                    onBlur={handleBlur}
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username?errors.username:""}
                    placeholder={t("Organization.ChartRepoTab.username")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiTextField
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    label={t("Organization.ChartRepoTab.password")}

                    margin="normal"
                    data-test="password-field"
                    onBlur={handleBlur}
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password? errors.password:''}
                    type={isShowPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => toggleEyeIcon()}
                            edge="end"
                          >
                            {isShowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("Organization.ChartRepoTab.password")}
                  />
                </Grid>
              </Grid>
            )}
            {values.repoAuth === AppConstants.repositoryAuthTypes.BEARER && (
              <Grid item xs={12}>
                <MuiTextField
                  name="token"
                  value={values.token}
                  onChange={handleChange}
                  type="text"
                  label={t("Organization.ChartRepoTab.token")}
                  margin="normal"
                  onBlur={handleBlur}
                  data-test="token-field"
                  error={!!touched.token && !!errors.token}
                  helperText={touched.token && errors.token?errors.token:undefined}
                  placeholder={t("Organization.ChartRepoTab.tokenBearer")}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <MuiTextField
                name="filters"
                value={values.filters}
                onChange={handleChange}
                multiline
                type="text"
                label={t("Organization.ChartRepoTab.filters")}
                margin="normal"
                onBlur={handleBlur}
                data-test="filters-field"
                error={!!touched.filters && !!errors.filters}
                helperText={touched.filters && errors.filters?errors.filters:undefined}
                rows={3}
                placeholder={t("Organization.ChartRepoTab.appfilters")}
              />
              <FormHelperText>
                {t("Organization.ChartRepoTab.helperText")}
              </FormHelperText>
            </Grid>
            {/* <Grid item xs={12} >
                            <FormControlLabel
                                control={<Checkbox value="packageCheck" color="primary" checked={packageCheck} onChange={() => { setPackageCheck(!packageCheck) }} />}
                                label={t('Organization.ChartRepoTab.ExcludePackage')}
                            />
                            <FormControlLabel
                                control={<Checkbox value="regexCheck" color="primary" checked={regexCheck} onChange={() => { setRegexCheck(!regexCheck) }} />}
                                label={t('Organization.ChartRepoTab.RegexCheck')}
                            />
                        </Grid> */}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={!(dirty && isValid)}
          data-test="submit-button"
          onClick={handleClick}
        >
          {props.editObject
            ? t("Organization.ChartRepoTab.update")
            : t("Organization.ChartRepoTab.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, null)(ChartRepositoryModal);
