import { Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  tabContainer: {
    display: 'flex', 
    gap: 16
  }
}))

export const StyledTabs = withStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    flexContainer: {
      gap: '0 24px'
    }
}))(Tabs);

export const StyledTab = withStyles((theme) => ({
  root: {
    display: 'flex',
    gap: 16, 
    padding: 0, 
    alignItems: 'center',
    justifyContent: 'center'
  },
}))(function CustomTab(props: any) {
  const { icon, label, ...restProps } = props;
  const classes = useStyles();
  return (
    <Tab
      {...restProps } 
      label={
        <Box className={classes.tabContainer}>
          {icon} 
          {label} 
        </Box>
      }
    />
  );
});