import { Button, Grid, Typography, ButtonBase, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-mde/lib/styles/css/react-mde-all.css";
import { connect, ConnectedProps } from "react-redux";
import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import ImagePopupModal from "../../components/imagepopupmodal/ImagePopupModal";
import BackdropLoader from "../../components/loader/BackdropLoader";
import MarkdownField from "../../components/markdownfield/MarkdownField";
import MultiFileUpload from "../../components/multifileupload";
import { getDateInStandardFormat } from "../../helpers/utils";
import { getTicketDetail, replyIssue, resetTicketDetail, updateTicket } from "./redux/actions";
import { navigationCheck } from "../notification/redux/actions";
import paths from "../../constants/paths";
import Toast from "../../components/toast/Toast";
import { updateBreadcrumb } from "../project/redux/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from 'react-i18next';
import { Dispatch } from "redux";
import { ConvoModel, FileData, HistoryState, Params, ReplyData, TicketModel } from "../../models/Support.model";
import { History } from 'history';
import { match } from "react-router-dom";
import Reply from "./Reply";

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    // color: theme.palette?.text?.secondary,
    marginTop: theme.spacing(2),
  },
  styleGrid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  labelStyle: {
    color: "black",
    fontWeight: "bolder",
    textAlign: "start",
    marginBottom: theme.spacing(2),
  },
  customButtonStyle: {
    marginTop: theme.spacing(2),
    height: 40,
    width: "6rem",
    minWidth: "200px",
    maxWidth: "100%",
  },
  styleParagraph: {
    // textAlign: 'center',
    fontSize: "16px",
    color: "black",
    fontWeight: "bolder",
  },
  styleGridText: {
    color: "#0057fa",
    fontWeight: "bolder",
    fontSize: "14px",
  },
  styleGridValue: {
    color: "gray",
    fontWeight: "bolder",
    fontSize: "14px",
  },
  headerText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divStyling: {
    // padding: "2%",
  },
  styledGrid: {
    fontSize: "16px",
    color: "black",
    fontWeight: "bolder",
    backgroundColor: "gray",
    margin: "0",
    padding: "0",
  },
  paperUser: {
    color: theme.palette?.text?.secondary,
    marginTop: theme.spacing(2),
  },
  style1: {
    fontSize: "16px",
    color: "black",
    fontWeight: "bolder",
    backgroundColor: theme.palette?.grey[500],
    padding: "2%",
  },
  agentGrid: {
    padding: "2%",
  },
  fileStyle: {
    padding: "2%",
  },
  spanStyle: {
    color: "#0057fa",
  },
  listContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  fixedPosition: {
    marginTop: theme.spacing(3),
  },
  fixedPositionRight: {
    // marginTop: theme.spacing(2),
    // position: "sticky",
    // zIndex: theme.zIndex.appBar,
    // top: "70px",
    // background: "#f6f8fa",
    // marginBottom: theme.spacing(2),
    // marginLeft: theme.spacing(2),
    marginTop: 40,
  },
  fixedHeight: {
    // paddingRight: theme.spacing(1) + "px !important",
    marginTop: 40,
  },
  rightGrid: {
    // zIndex: -1,
  },
}));

interface Props extends PropsFromRedux{
    history: History<HistoryState>;
    match: match<Params>;
    ticket: TicketModel
}
 
interface Payload {
    reply: string;
    ticketId: number | string;
    userId: number | string;
    file?: { fileName: string; fileType: string; fileValue: string; }[];
}

export const TicketDetail = (props: Props) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const [reply, setReply] = useState<string>(" ");
    const [currentImage, setCurrentImage] = useState<any>(null);
    const [openAttachment, setOpenAttachment] = useState<boolean>(false);
    const [updateTicketDetails, setUpdateTicketDetails] = useState<boolean>(false);
    const [reset, setReset] = useState<boolean>(false);
    const [file, setFile] = useState<File[]>([]);

    useEffect(() => {
        let ticket_id;
        if (props?.match?.params?.id) {
          ticket_id = props.match.params.id;
        } else {
          ticket_id = props.history?.location?.state?.id;
        }
        const breadcrumbData = [
          {
            name: "Tickets",
            path: paths.SUPPORT,
          },
          {
            name: "Ticket#" + ticket_id,
            path: paths.TICKET_DETAIL.replace(":id", ticket_id),
          },
        ];
        props.updateBreadcrumb(breadcrumbData);
        props.getTicketDetail(ticket_id);
        return () => {
          props.resetTicketDetail();
        };
    }, []);

    const setFiles = (files: File[]) => {
        setFile(files);
        if (files.length > 0) {
          setReset(false);
        }
    };

    const handleCloseUpdate = () => {
        setUpdateTicketDetails(false);
    };

    const closeCallback = () => {
        const payload = {
          status: "Closed",
        };
        props.updateTicket(
          props.ticketDetail?.ticketDetails?.id ?? props.ticketDetail?.id,
          payload
        );
    };

    const replyHandler = (callback?: any) => {
        const payload: Payload = {
          reply: reply,
          ticketId: props.ticketDetail?.ticketDetails?.id ?? props.ticketDetail?.id,
          userId: props.currentUser?.id,
        };
        if (file?.length > 0) {
          const uploadFiles: Payload['file'] = [];
          file.forEach((f: any) => {
            const _f = {
              fileName: f.file?.name ?? '',  
              fileType: f.file?.type ?? '',  
              fileValue: f.url,
            };
            uploadFiles.push(_f);
          });
          payload.file = uploadFiles;
        }
        clearDatas();
        setReset(true);
        props.replyIssue(payload, callback);
    };

    const closeTicketHandler = () => {
        if (props.ticketDetail) {
          if (reply.trim()?.length) {
            replyHandler(closeCallback);
          } else if (file?.length) {
            Toast.error("To attach files, you must provide some description");
            handleCloseUpdate();
            return;
          } else {
            closeCallback();
          }
        } else {
          Toast.error("Cannot update ticket");
        }
        handleCloseUpdate();
    };

    const reOpenCallback = () => {
        const payload = {
          status: "Open",
        };
        props.updateTicket(
          props.ticketDetail?.ticketDetails?.id ?? props.ticketDetail?.id,
          payload
        );
    };

    const reopenTicketHandler = () => {
        if (props.ticketDetail) {
          if (reply.trim()?.length) {
            replyHandler(reOpenCallback);
          } else if (file?.length) {
            Toast.error("To attach files, you must provide some description");
            handleCloseUpdate();
            return;
          } else {
            reOpenCallback();
          }
        } else {
          Toast.error("Cannot update ticket");
        }
        handleCloseUpdate();
    };
    

    const clearDatas = () => {
        setFile([]);
        setReply("");
    };

    const navToTicketList = () => {
        props.history.push({
          pathname: paths.SUPPORT,
        });
    };

    const handleImageOpen = (image: any) => {
        setOpenAttachment(!openAttachment);
        setCurrentImage(image);
    };

    const cancelReply = () => {
        setReset(true);
        setReply("");
    };

    const isReplyValid = () => {
        let val = false;
        if (props.ticketDetail?.status !== "Closed" && reply?.trim()?.length > 0) {
          val = true;
        }
        return val;
    };

    const handleUpdateTicket = () => {
        setUpdateTicketDetails(true);
    };

    const existanceCallBack = (data: any, module: string, id: number ) => {
      if (data) {
          let path = "";
          switch (module) {
            case "project":
              path = paths.PROJECTINFO.replace(":id", id.toString());
              break;
            case "application":
              path = paths.APPDETAILS.replace(":appId", id.toString());
              break;
            case "environment":
              path = paths.ENVIRONMENTINFO.replace(":eId", id.toString());
              break;
            default:
              path = "";
          }
          if (props.history && path) {
            props.history.push(path);
          }
      } else {
        Toast.error(
          "The " + module + " you are looking for, doesn't exist anymore"
        );
      }
    };

    const navigation = (module: string, id: number, data: any) => {
        props.navigationCheck(module, id, existanceCallBack);
    };

    const getStatus = (status: string) => {
        switch (status) {
          case "Open":
            return "activestatus";
          case "Closed":
            return "inactivestatus";
          default:
            return "";
        }
    };

    return(
        <div className={classes.root} data-test="ticket-container">
            <div data-test="back-button">
                <ButtonBase onClick={() => navToTicketList()} style={{ color: "#357dfd" }} data-test="backButton">
                    <ArrowBackIcon fontSize="small" />{" "}
                    <span className="jobNav">{t('Support.CreateTicketForm.ticketList')}</span>
                </ButtonBase>
            </div>
            <Grid
                className={classes.fixedPosition}
                data-test="ticket-title"
            >
                <Typography color="textPrimary" variant="h6">
                    {" "}
                    {props.ticketDetail?.ticketDetails?.title ??
                    props.ticketDetail?.title ??
                    props.ticket?.title ?? <Skeleton width={250} height={25} />}
                {" "}
                #{props.ticketDetail?.ticketDetails?.id ?? props.ticketDetail?.id}{" "}
                </Typography>
            </Grid>

            <Grid container spacing={3}>
                <Grid
                  className={classes.fixedHeight}
                  item
                  xs={12}
                  sm={7}
                  data-test="left-detail-container"
                >
                  <Card>
                    <CardContent>
                        {(props.ticketDetail?.ticketDetails?.description ||
                          props.ticketDetail?.files?.length > 0) && (
                          <Card elevation={3} className={classes.paper}>
                            {props.ticketDetail?.ticketDetails?.description && (
                              <Grid container spacing={1} className={classes.styleGrid}>
                                <div data-test="ticket-description" style={{ width: "100%" }}>
                                  <Typography variant="subtitle2">{t('Support.TicketDetail.description')}</Typography>
                                  {/* <hr /> */}
                                  <MarkdownField
                                    value={props.ticketDetail?.ticketDetails?.description}
                                    readOnly={true}
                                  />
                                </div>
                              </Grid>
                            )}
                            {props.ticketDetail?.files?.length > 0 && (
                              <div data-test="files-container">
                                <Grid container spacing={3} className={classes.divStyling}>
                                  <Grid className={classes.styleParagraph} item md={12}>
                                    <Typography color="textPrimary" variant="subtitle2">
                                    {t('Support.TicketDetail.attachments')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <ImagePopupModal
                                  images={props.ticketDetail?.files}
                                  readOnly={true}
                                />
                              </div>
                            )}
                          </Card>
                        )}
                        {props.ticketDetail?.conversation?.length > 0 && (
                          <div data-test="conversation-container">
                            {props.ticketDetail?.conversation?.map((convo: ConvoModel, ind: number) => (
                              <Reply
                                convo={convo}
                                key={ind}
                                handleImageOpen={() => handleImageOpen}
                              />
                            ))}
                          </div>
                        )}
                        <div data-test="reply-container">
                          <Typography
                            style={{ paddingBottom: 10, marginTop: 20}}
                            variant="h6"
                            color="textSecondary"
                          >
                            {t('Support.TicketDetail.reply')}  (*)
                          </Typography>
                          <MarkdownField
                            value={reply}
                            onChange={(newValue?:string) => setReply(newValue || "")}
                            title={t('Support.TicketDetail.reply')}
                            data-test="reply-field"
                            readOnly={false}
                          />

                          <MultiFileUpload setFile={setFiles} reset={reset} data-test="files-field" />

                          <Grid container spacing={1} justify="flex-end">
                            {!(reply.trim()?.length === 0 && file?.length === 0) && (
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  className={classes.customButtonStyle}
                                  onClick={() => cancelReply()}
                                  disabled={Boolean(
                                    reply.trim()?.length === 0 && file?.length === 0
                                  )}
                                  data-test="cancel-button"
                                >
                                  {" "}
                                  {t('Support.TicketDetail.cancel')}{" "}
                                </Button>
                              </Grid>
                            )}
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.customButtonStyle}
                                onClick={() => handleUpdateTicket()}
                                size="small"
                                data-test="update-button"
                              >
                                {props.ticketDetail?.ticketDetails?.status === "Closed"
                                  ? "Re-Open Ticket"
                                  : "Close Ticket"}
                              </Button>
                            </Grid>
                            {props.ticketDetail?.ticketDetails?.status === "Open" && (
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  className={classes.customButtonStyle}
                                  onClick={() => replyHandler()}
                                  disabled={!isReplyValid()}
                                  data-test="reply-button"
                                >
                                  {" "}
                                  {t('Support.TicketDetail.replyLabel')}{" "}
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  data-test="right-detail-container"
                  className={classes.rightGrid}
                >
                  <div className={classes.fixedPositionRight}>
                    <Card className={classes.paper}>
                      <Grid spacing={2} container>
                        <Grid
                          className={classes.styleParagraph}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          style={{marginBottom: 20}}
                        >
                          <Typography variant="h6"> {t('Support.TicketDetail.ticketDetails')} </Typography>
                        </Grid>
                      </Grid>
                      {props.ticketDetail?.ticketDetails?.date && (
                        <Grid spacing={1} container>
                          <Grid
                            className={classes.styleGridText}
                            item
                            xs={12}
                            sm={5}
                            md={5}
                          >
                            <Typography variant="subtitle2">{t('Support.TicketDetail.issueDate')}</Typography>
                          </Grid>
                          <Grid
                            className={classes.styleGridValue}
                            item
                            xs={12}
                            sm={7}
                            md={7}
                          >
                            <Typography variant="body2" color="textSecondary">{getDateInStandardFormat(props.ticketDetail?.ticketDetails?.date * 1000,true)}</Typography>
                          </Grid>
                        </Grid>
                      )}

                      <Grid spacing={1} container>
                        <Grid
                          className={classes.styleGridText}
                          item
                          xs={12}
                          sm={5}
                          md={5}
                        >
                          <Typography variant="subtitle2">{t('Support.TicketDetail.lastResponse')}</Typography>
                        </Grid>
                        <Grid
                          className={classes.styleGridValue}
                          item
                          xs={12}
                          sm={7}
                          md={7}
                        >
                          <Typography variant="body2" color="textSecondary" data-test="lastResponse-container">
                            {props.ticketDetail?.conversation?.length > 0
                              ? getDateInStandardFormat(
                                  props.ticketDetail?.conversation[
                                    props.ticketDetail?.conversation?.length - 1
                                  ]?.date * 1000,
                                  true
                                )
                              : "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid spacing={1} container>
                        <Grid
                          className={classes.styleGridText}
                          item
                          xs={12}
                          sm={5}
                          md={5}
                        >
                          <Typography variant="subtitle2">{t('Support.CreateTicketForm.priority')}</Typography>
                        </Grid>
                        <Grid
                          className={classes.styleGridValue}
                          item
                          xs={12}
                          sm={7}
                          md={7}
                        >
                          <Typography variant="body2" color="textSecondary">{props.ticketDetail?.ticketDetails?.priority ??props.ticketDetail?.priority}</Typography>
                        </Grid>
                      </Grid>

                      <Grid spacing={1} container>
                        <Grid
                          className={classes.styleGridText}
                          item
                          xs={12}
                          sm={5}
                          md={5}
                        >
                          <Typography variant="subtitle2">{t('Support.SupportPage.category')}</Typography>
                        </Grid>
                        <Grid
                          className={classes.styleGridValue}
                          item
                          xs={12}
                          sm={7}
                          md={7}
                        >
                          <Typography variant="body2" color="textSecondary">{props.ticketDetail?.ticketDetails?.category ?? props.ticketDetail?.category}</Typography>
                        </Grid>
                      </Grid>

                      <Grid spacing={1} container>
                        <Grid
                          className={classes.styleGridText}
                          item
                          xs={12}
                          sm={5}
                          md={5}
                        >
                          <Typography variant="subtitle2">{t('Support.SupportPage.status')}</Typography>
                        </Grid>
                        <Grid
                          className={classes.styleGridValue}
                          item
                          xs={12}
                          sm={7}
                          md={7}
                        >
                          <Typography variant="body2" color="textSecondary">
                            <span
                              className={getStatus(props.ticketDetail?.ticketDetails?.status)}
                            >
                            </span>
                            {props.ticketDetail?.ticketDetails?.status ??
                              props.ticketDetail?.status}
                          </Typography>
                        </Grid>
                      </Grid>

                      {props.ticketDetail?.ticketDetails?.project && (
                        <Grid spacing={2} container data-test="project-container">
                          <Grid
                            className={classes.styleGridText}
                            item
                            xs={12}
                            sm={5}
                            md={5}
                          >
                            <Typography variant="subtitle2">{t('Support.TicketDetail.project')}</Typography>
                          </Grid>
                          <Grid
                            className={classes.styleGridValue}
                            item
                            xs={12}
                            sm={7}
                            md={7}
                          >
                            <ButtonBase
                              onClick={() =>
                                navigation(
                                  "project",
                                  props.ticketDetail?.ticketDetails?.project?.id,
                                  " "
                                )
                              }
                              data-test="project-navigation-button"
                            >
                              <Typography color="primary" variant="body2">
                                {props.ticketDetail?.ticketDetails?.project?.name}
                              </Typography>
                            </ButtonBase>
                          </Grid>
                        </Grid>
                      )}

                      {props.ticketDetail?.ticketDetails?.application && (
                        <Grid spacing={1} container data-test="application-container">
                          <Grid
                            className={classes.styleGridText}
                            item
                            xs={12}
                            sm={5}
                            md={5}
                          >
                            <Typography variant="subtitle2">{t('Support.TicketDetail.application')}</Typography>
                          </Grid>
                          <Grid
                            className={classes.styleGridValue}
                            item
                            xs={12}
                            sm={7}
                            md={7}
                          >
                            <ButtonBase
                              onClick={() =>
                                navigation(
                                  "application",
                                  props.ticketDetail?.ticketDetails?.application?.id,
                                  " "
                                )
                              }
                              data-test="application-navigation-button"
                            >
                              <Typography color="primary" variant="body2">
                                {props.ticketDetail?.ticketDetails?.application?.name}
                              </Typography>
                            </ButtonBase>
                          </Grid>
                        </Grid>
                      )}

                      {props.ticketDetail?.ticketDetails?.environment && (
                        <Grid spacing={1} container data-test="environment-container">
                          <Grid
                            className={classes.styleGridText}
                            item
                            xs={12}
                            sm={5}
                            md={5}
                          >
                            <Typography variant="subtitle2">{t('Support.TicketDetail.environment')}</Typography>
                          </Grid>
                          <Grid
                            className={classes.styleGridValue}
                            item
                            xs={12}
                            sm={7}
                            md={7}
                          >
                            <ButtonBase
                              onClick={() =>
                                navigation(
                                  "environment",
                                  props.ticketDetail?.ticketDetails?.environment?.id,
                                  " "
                                )
                              }
                              data-test="environment-navigation-button"
                            >
                              <Typography color="primary" variant="body2">
                                {props.ticketDetail?.ticketDetails?.environment?.name}
                              </Typography>
                            </ButtonBase>
                          </Grid>
                        </Grid>
                      )}
                    </Card>
                  </div>
                </Grid>
            </Grid>

            {props.fetchingTicketDetail && (
                <BackdropLoader message={t('Support.TicketDetail.fetchingDetail')} data-test="fetchingTicketDetail-loader" />
            )}
            {props.replyingIssue && <BackdropLoader message={t('Support.TicketDetail.replyingIssue')} data-test="replyingIssue-loader" />}
            {props.updatingTicket && <BackdropLoader message={t('Support.TicketDetail.updatingIssue')}  data-test="updatingTicket-loader" />}

            {currentImage && openAttachment && (
                <ImagePopupModal
                images={currentImage?.fileValue}
                remove={handleImageOpen}
                data-test="reply-image-popup"
                />
            )}

            {updateTicketDetails && (
                <ConfirmActionPopup
                open={updateTicketDetails}
                message={`Are you sure you want to ${
                    props.ticketDetail?.ticketDetails?.status === "Closed"
                    ? "Re-Open"
                    : "Close"
                } the ticket?`}
                handleAgree={
                    props.ticketDetail?.ticketDetails?.status === "Closed"
                    ? reopenTicketHandler
                    : closeTicketHandler
                }
                handleDisAgree={handleCloseUpdate}
                yesText={t('Projects.VariablesTab.yesText')}
                />
            )}
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    currentUser: state.AuthReducer.user,
    fetchingTicketDetail: state.SupportReducer.fetchingTicketDetail,
    ticketDetail: state.SupportReducer.ticketDetail,
    replyingIssue: state.SupportReducer.replyingIssue,
    updatingTicket: state.SupportReducer.updatingTicket,
});
  
/* istanbul ignore next */
const mapDispatchtoProps = (dispatch : Dispatch) => {
    return {
        getTicketDetail: (id: string) => dispatch(getTicketDetail(id)),
        replyIssue: (payload: any, callback:any) => dispatch(replyIssue(payload, callback)),
        updateTicket: (id: number, payload: any) => dispatch(updateTicket(id, payload)),
        updateBreadcrumb: (payload:any) => dispatch(updateBreadcrumb(payload)),
        navigationCheck: (module: any, id: number, callBack: any) =>
        dispatch(navigationCheck(module, id, callBack)),
        resetTicketDetail: () => dispatch(resetTicketDetail()),
    };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(TicketDetail);

type PropsFromRedux = ConnectedProps<typeof connector>;