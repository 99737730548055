import React from 'react';
import { Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import './SubscriptionCard.css';
import { useTranslation } from 'react-i18next';
import { SubscriptionCardModel } from '../../models/Project.model';
import { CashIcon, CashMoreIcon } from '../../helpers/customIcon';
import palette from '../../theme/palette';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

const useStyles = makeStyles({
    cardInnerWrapper: {
        padding: 40, cursor: 'pointer'
    },
    amountSection:{
        display: 'flex'
    },
    cashicon :{
        height: 48, 
        width: 48
    },
    priceDetails:{
        display: 'flex', 
        alignItems:'center', 
        gap: 8
    },
    monthDetails:{
        color: palette.text.disabled
    },
    subscriptionCardDivider:{
        borderBottom: `1px dashed ${palette.background.neutral}`, 
        backgroundColor: palette.white, 
        marginTop: 40, 
        marginBottom: 40
    },
    featureTag:{
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems:'center'
    },
    textUnderline:{
        textDecoration: 'underline'
    },
    marginTop:{
        marginTop: 16
    },
    cardFeatureWrapper:{
        display:'flex', 
        flexDirection: 'column', 
        gap: 16
    },
    cardFeatureDetails:{
        display: 'flex', 
        alignItems:'center',
        gap: 8
    }
})

//const SubscriptionCard = ({disabled = false ,subscriptionName , subscriptionPrice , subscriptionCore , subscriptionApps ,subsciptionSpace , subscriptionDataTransfer , subscriptionRam}) => {
export const OrgSubscriptionCard = (props: SubscriptionCardModel) => {	
 const { details } = props; 
 const classes  = useStyles();
 const disabled = props.selectedSubscriptionId !== details.id ; 
 const cardDisabled = props.cardDisabled;
 const [t] = useTranslation()

 const handleClick = ()=> {
     if(!cardDisabled){
        props.handleSubscriptionClick(details)
     }
 }
 
  return (
      <>
          {/* <Paper elevation={3} className={`SubscriptionCard-container ${cardDisabled ? "disabled-card" : ""}`} data-test="main-container">
              <div 
            
              onClick={cardDisabled ? ()=> {} : handleClick} data-test="card-link">
                  <div className={ disabled === true ? 'SubscriptionCard-header-disabled' : 'SubscriptionCard-header' }>{details.name}</div>	
                  <div className={`SubscriptionCard-header ${cardDisabled ? "" : "" }`} data-test="details-name">{details.name}</div>
                  <div className={ `${ disabled === true ? 'SubscriptionCard-pricing-disabled' : 'SubscriptionCard-pricing'}  ${cardDisabled ? "disabled-card" : "" }` }>
                      <div>
                          <span className={ disabled === true ? 'price-disabled' : 'price' } data-test="details-price">${details.price}</span><span className={ disabled === true ? 'perMonth-disabled' : 'perMonth' }> / {t('PerMonth')} </span>
                      </div>
                  </div>
                  <div className={ `${disabled === true ? 'SubscriptionCard-content-disabled' : 'SubscriptionCard-content'}  ${cardDisabled ? "disabled-card" : "" }` } data-test="details-card">
                      <div>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="user-details">{details.no_of_user > 1 ? details.no_of_user + ' Users': details.no_of_user + ' User'}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="cluster-details">{ details.cluster } {t('ClusterLabel')}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="memory-details">{spaceCoversion(details.memory)} {t('RAM')}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-coreText-disabled' : 'sc-coreText' } data-test="core-details">{coreConversion(details.cores)}</p>
                          <p align='center' className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' }>{spaceCoversion(details.data_transfer)} Data Transfer</p>
                      </div>
                  </div>
              </div>
          </Paper> */}
          <Paper elevation={disabled ? 1 : 11} data-test="main-container">
              <div onClick={cardDisabled ? ()=> {} : handleClick} data-test="card-link" className={classes.cardInnerWrapper}>
                <div>
                    {details.price === 0 ?
                    (<CashIcon className={classes.cashicon} viewBox='0 0 48 48'/>)
                    :
                    (<CashMoreIcon  className={classes.cashicon} viewBox='0 0 48 48'/>) }
                </div>
                <div data-test="details-name" className='mt-40'>
                    <Typography variant='h4'>{details.name}</Typography>
                </div>   
                <div className='mt-40' data-test="details-price">
                    {!props.currentOrganization?.id ? 
                    <div className={classes.amountSection}>
                        <Typography variant='h4'>$</Typography>
                        <div className={classes.priceDetails}>
                            <Typography variant='h2'>
                                {details.price} 
                            </Typography>
                            <Typography variant='body2' className={classes.monthDetails}>/ {t('PerMonth')}</Typography>
                        </div>
                    </div> :
                    <div>
                        <Typography variant='h2'>
                            Free
                        </Typography>
                    </div>}
                </div> 
                <Divider className={classes.subscriptionCardDivider}/>    
                <div>
                    <div className={classes.cardFeatureWrapper}>
                        <Typography variant="body2" data-test="user-details" className={classes.cardFeatureDetails}>
                            <CheckOutlinedIcon fontSize='small'/>
                            {details.no_of_user > 1 ? details.no_of_user + ' Users': details.no_of_user + ' User'}
                        </Typography>
                        <Typography variant="body2" data-test="cluster-details" className={classes.cardFeatureDetails}>
                            <CheckOutlinedIcon fontSize='small'/>
                            { details.cluster } {t('ClusterLabel')}
                        </Typography>
                    </div>
                </div>          
              </div>
          </Paper>
      </>
    )
};

export default OrgSubscriptionCard;
