import { CustomColors } from "../../constants/enums";
import palette from "../palette";

export default {
    root: {
        width: '33px !important',
        height: '20px !important',
        padding: 0,
        marginRight: '12px !important',
    },
    switchBase: {
        padding: 3,
        '&$checked': {
            transform: 'translateX(12px)',
            color: `${palette.white} !important`,
            '& + $track': {
            backgroundColor: `${CustomColors.Success.Main} !important`,
            opacity: 1,
            border: 'none',
            },
        },
    },
    thumb: {
        width: 14,
        height: 14,
    },
    track: {
        borderRadius: '500px',
        backgroundColor: `${[palette.background.switchDisabled]} !important`,
        opacity: 1,
    },
    checked: {
       
    },
    focusVisible: {},
}