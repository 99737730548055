import React from 'react';
import { Button, Chip, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import './SubscriptionCard.css';
import { spaceCoversion , coreConversion } from '../../helpers/utils';
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next';
import { SubscriptionCardModel } from '../../models/Project.model';
import { CashIcon, CashMoreIcon } from '../../helpers/customIcon';
import palette from '../../theme/palette';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { StyledDivider } from '../dashedDivider/DashedDivider';

const useStyles = makeStyles({
    cardInnerWrapper: {
        padding: 20, cursor: 'pointer'
    },
    amountSection:{
        display: 'flex'
    },
    cashicon :{
        height: 48, 
        width: 48
    },
    priceDetails:{
        display: 'flex', 
        alignItems:'center', 
        gap: 4
    },
    monthDetails:{
        color: palette.text.disabled
    },
    subscriptionCardDivider:{
        marginTop: 5, 
        marginBottom: 5
    },
    featureTag:{
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems:'center'
    },
    textUnderline:{
        textDecoration: 'underline'
    },
    marginTop:{
        marginTop: 16
    },
    cardFeatureWrapper:{
        display:'flex', 
        flexDirection: 'column', 
        gap: 16
    },
    cardFeatureDetails:{
        display: 'flex', 
        alignItems:'center',
        gap: 8
    }
})

//const SubscriptionCard = ({disabled = false ,subscriptionName , subscriptionPrice , subscriptionCore , subscriptionApps ,subsciptionSpace , subscriptionDataTransfer , subscriptionRam}) => {
export const SubscriptionCard = (props: SubscriptionCardModel) => {
 const 	classes = useStyles();
 const { details } = props; 
 const disabled = props.selectedSubscriptionId !== details.id; 
 const cardDisabled = props.cardDisabled;
 const [t] = useTranslation()

 const handleClick = ()=> {
     if(!cardDisabled){
         props.handleSubscriptionClick(details)
     }
 }
 
  return (
      <>
          {/* <Paper elevation={3} className={ `SubscriptionCard-container ${cardDisabled ? "disabled-card" : ""}` } data-test="main-container">
              <div onClick={cardDisabled ? ()=> {} : handleClick} data-test="card-link" style={{ cursor: 'pointer' }}>
                  <div className={ disabled === true ? 'SubscriptionCard-header-disabled' : 'SubscriptionCard-header' }>{details.name}</div>	
                  <div className={`SubscriptionCard-header ${cardDisabled ? "" : "" }`} data-test="details-name">{details.name}</div>
                  {!props.currentOrganization?.id && (
                    <div className={ `${ disabled === true ? 'SubscriptionCard-pricing-disabled' : 'SubscriptionCard-pricing'}  ${cardDisabled ? "disabled-card" : "" }` }>
                        <div>
                            <span className={ disabled === true ? 'price-disabled' : 'price' } data-test="details-price">${details.price}</span><span className={ disabled === true ? 'perMonth-disabled' : 'perMonth' }> / {t('PerMonth')}</span>
                        </div>
                    </div>
                  )}
                  <div className={ `${disabled === true ? 'SubscriptionCard-content-disabled' : 'SubscriptionCard-content'}  ${cardDisabled ? "disabled-card" : "" }` }>
                      <div>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="app-details">{details.apps > 1 ? details.apps + ' Apps': details.apps + ' App'}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="memory-details">{spaceCoversion(details.memory)} {t('RAM')}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-coreText-disabled' : 'sc-coreText' } data-test="core-details">{coreConversion(details.cores)}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="disk-details">{spaceCoversion(details.disk_space)} {t('Storage')}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="data-details">{spaceCoversion(details.data_transfer)} {t('Bandwidth')}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="backups-details">{details.backups} {t('Backups')} </p>
                      </div>
                  </div>
              </div>
          </Paper> */}
          <Paper elevation={disabled ? 1 : 11} data-test="main-container">
              <div onClick={cardDisabled ? ()=> {} : handleClick} data-test="card-link" className={classes.cardInnerWrapper}>
                  {/* <div className={ disabled === true ? 'SubscriptionCard-header-disabled' : 'SubscriptionCard-header' }>{details.name}</div>	 */}
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {details.price === 0 || details.price === 1 ?
                        (
                            <CashIcon className={classes.cashicon} viewBox='0 0 48 48'/>
                        )
                        :
                        (
                            <CashMoreIcon  className={classes.cashicon} viewBox='0 0 48 48'/>
                        )
                    }
                    {!disabled && (<Chip label="Selected" size='small' variant='default' style={{backgroundColor: palette.background.primary, color: palette.white, borderRadius: 8}}/>)}
                </div>
                <div data-test="details-name" className='m-t-10'>
                    <Typography variant='h4'>{details.name}</Typography>
                    {/* <Typography variant='subtitle2' style={{marginTop: 8}}>Forever</Typography> */}
                </div>
                <div className='m-t-10'>
                    {!props.currentOrganization?.id ? 
                    <div className={classes.amountSection}>
                        <Typography variant='h3'>$</Typography>
                        <div className={classes.priceDetails}>
                            <Typography variant='h3'>
                                {details.price} 
                            </Typography>
                            <Typography variant='body2' className={classes.monthDetails}>/ {t('PerMonth')}</Typography>
                        </div>
                    </div> :
                    <div>
                        <Typography variant='h2'>
                            Free
                        </Typography>
                    </div>}
                </div>
                <StyledDivider className={classes.subscriptionCardDivider}/>
                <div className={classes.featureTag}>
                    <Typography variant='overline'>Features</Typography>
                    {/* <Typography variant='body2' className={classes.textUnderline}>All</Typography> */}
                </div>
                <div className={classes.marginTop}>
                    <div className={classes.cardFeatureWrapper}>
                        <Typography variant="body2" data-test="app-details" className={classes.cardFeatureDetails}>
                            <CheckOutlinedIcon fontSize='small'/>
                            {details.apps > 1 ? details.apps + ' Apps': details.apps + ' App'}
                        </Typography>
                        <Typography variant="body2" data-test="memory-details" className={classes.cardFeatureDetails}>
                            <CheckOutlinedIcon fontSize='small'/>
                            {spaceCoversion(details.memory)} {t('RAM')}
                        </Typography>
                        <Typography variant="body2" data-test="core-details" className={classes.cardFeatureDetails}>
                            <CheckOutlinedIcon fontSize='small'/>
                            {coreConversion(details.cores)}
                        </Typography>
                        <Typography variant="body2" data-test="disk-details" className={classes.cardFeatureDetails}>
                            <CheckOutlinedIcon fontSize='small'/>
                            {spaceCoversion(details.disk_space)} {t('Storage')}
                        </Typography>
                        <Typography variant="body2" data-test="data-details" className={classes.cardFeatureDetails}>
                            <CheckOutlinedIcon fontSize='small'/>
                            {spaceCoversion(details.data_transfer)} {t('Bandwidth')}
                        </Typography>
                        <Typography variant="body2" data-test="backups-details" className={classes.cardFeatureDetails}>
                            <CheckOutlinedIcon fontSize='small'/>
                            {details.backups} {t('Backups')} 
                        </Typography>
                    </div>
                </div>
                {/* <Button
                variant='contained'
                fullWidth
                color='primary'
                className='mt-40'
                disabled={disabled}
                >
                    Select
                </Button> */}
              </div>
          </Paper>
      </>
    )
};

const mapStateToProps = (state: any) => ({
    currentOrganization: state.AuthReducer.currentOrganization,
  });
  
  export default connect(
    mapStateToProps,
    null
  )(SubscriptionCard);
