import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Button,
  // Popover,
  // Link,
  RadioGroup,
  Switch,
  Typography,
  Grid, 
  Radio,
  Tooltip,
  CardHeader,
} from '@material-ui/core';
import { withStyles,WithStyles } from '@material-ui/core/styles';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
// import InfoIcon from '@material-ui/icons/Info';
import React, { Component } from 'react';
import { withTranslation,WithTranslation } from "react-i18next";
import { connect,ConnectedProps } from 'react-redux';
import ConfirmActionPopup from '../../../../../components/confirmactionpopup/ConfirmActionPopup';
import EmailInput from "../../../../../components/emailinput/EmailInput";
import InfoPopupContainer from '../../../../../components/infopopup/InfoPopupContainer';
import BackdropLoader from '../../../../../components/loader/BackdropLoader';
import MuiTextField from '../../../../../components/textfield/MuiTextField';
import { isAuthorized } from '../../../../../helpers/utils';
import { clearCICDSettings, fetchCICDSettings, testEmail, testSlack, testWebhook, updateCICDSettings } from '../../../cicdredux/actions';
import { CreateApp_TemplateTypes } from "../../../../../constants/enums";

import './cisettingstab.css';
import { Dispatch } from 'redux';
import CDChangePopup from '../../../../../components/cdchangepopup/CDChangePopup';
import palette from '../../../../../theme/palette';
/* istanbul ignore next */
const useStyles = () => ({
  branchInfo: {
      display: 'flex',
      alignItems: 'center'
  },
  paper: {
      maxWidth: 400,
      padding: 10,
      borderRadius: 9
  }
});

//pranab
// const [anchorEl, setAnchorEl] = React.useState(null);

// const handleClick = (event) => {
// setAnchorEl(event.currentTarget);
// };

// const handleClose = () => {
// setAnchorEl(null);
// };

// const open = Boolean(anchorEl);
// const id = open ? 'simple-popover' : undefined;
interface Iprops extends PropsFromRedux,WithTranslation,WithStyles{
    handleCDChange: () => void,
    handleAgree : any
}
interface ItextFieldError {
    slack_webhook_url: boolean;
    webhook_url: boolean;
    emails: boolean;
    webhook_token: boolean;
}
interface IhelperText {
    slack_webhook_url: string;
    webhook_url: string;
    emails: string;
    webhook_token: string;
}
type Tdata={
    isPush: boolean;
    isTag: boolean;
    event_type: string;
    email_notification: boolean;
    emails: string[];
    slack_notification: boolean;
    slack_webhook_url:string;
    webhook_notification: boolean;
    webhook_url: string;
    webhook_token: string;
}
type TjsonBody= {
    events: string;
    event_type: string;
    email_notification: boolean;
    emails: string;
    slack_notification: boolean;
    slack_webhook_url: string;
    webhook_notification: boolean;
    webhook_url: string;
    webhook_token: string;
}
interface Istate {
    isPush: boolean;
    isTag: boolean;
    event_type: string;
    email_notification: boolean;
    emails: string[];
    slack_notification: boolean;
    slack_webhook_url: string;
    webhook_notification: boolean;
    webhook_url: string;
    webhook_token: string;
    isConfirmSettingsSaveOpen: boolean;
    //anchorEl:                  null;
    popOverType: number;
    errors: Partial<ItextFieldError>;
    helperText: Partial<IhelperText>;
    oldData: Partial<Tdata>;
}
export class CISettingsTab extends Component<Iprops,Istate> {
  constructor(props:Iprops) {
      super(props);
      this.state = {
          isPush: false,
          isTag: false,
          event_type: "all",
          email_notification: false,
          emails: [],
          slack_notification: false,
          slack_webhook_url: '',
          webhook_notification: false,
          webhook_url: '',
          webhook_token: '',
          isConfirmSettingsSaveOpen: false,
          //anchorEl: null,
          popOverType: 0,
          errors: {},
          helperText: {},
          oldData: {},
      }
  }

  componentDidMount() {
      this.props.fetchCICDSettings(this.props.environmentDetails.id);
      // const $this = this;
      // window.addEventListener('message', function(e){
      //     $this.props.fetchCICDSettings($this.props.environmentDetails.id);
      // }, false)

      const eventsArray = this.props.cicdSettings.events ? this.props.cicdSettings.events.split(',') : [];

      const oldData = {
          isPush: eventsArray.includes("push") ,
          isTag: eventsArray.includes("release"),
          event_type: this.props.cicdSettings.event_type ? this.props.cicdSettings.event_type : "all",
          email_notification: this.props.cicdSettings.email_notification ?? false,
          emails: this.props.cicdSettings.emails ? this.props.cicdSettings.emails.split(',') : [],
          slack_notification: this.props.cicdSettings.slack_notification ?? false,
          slack_webhook_url: this.props.cicdSettings.slack_webhook_url ?? "",
          webhook_notification: this.props.cicdSettings.webhook_notification ?? false,
          webhook_url: this.props.cicdSettings.webhook_url ?? "",
          webhook_token: this.props.cicdSettings.webhook_token ?? "",    
      }
      this.setState({
          oldData
      })
  }

  componentWillUnmount(){
      this.props.clearCICDSettings();
  }

  UNSAFE_componentWillReceiveProps = (newProps:Iprops) => {
      if(newProps.cicdSettings){
          if(JSON.stringify(this.props.cicdSettings) !== JSON.stringify(newProps.cicdSettings))
          {
              const eventsArray = newProps.cicdSettings.events ? newProps.cicdSettings.events.split(',') : [];
              const oldData = {
                  isPush: eventsArray.includes("push"),
                  isTag: eventsArray.includes("release"),
                  event_type: newProps.cicdSettings.event_type ? newProps.cicdSettings.event_type : "all",
                  email_notification: newProps.cicdSettings.email_notification,
                  emails: newProps.cicdSettings.emails ? newProps.cicdSettings.emails.split(',') : [],
                  slack_notification: newProps.cicdSettings.slack_notification,
                  slack_webhook_url: newProps.cicdSettings.slack_webhook_url,
                  webhook_notification: newProps.cicdSettings.webhook_notification,
                  webhook_url: newProps.cicdSettings.webhook_url,
                  webhook_token: newProps.cicdSettings.webhook_token,    
              }
              this.setState({
                  isPush: eventsArray.includes("push"),
                  isTag: eventsArray.includes("release"),
                  event_type: newProps.cicdSettings.event_type ? newProps.cicdSettings.event_type : "all",
                  email_notification: newProps.cicdSettings.email_notification,
                  emails: newProps.cicdSettings.emails ? newProps.cicdSettings.emails.split(',') : [],
                  slack_notification: newProps.cicdSettings.slack_notification,
                  slack_webhook_url: newProps.cicdSettings.slack_webhook_url,
                  webhook_notification: newProps.cicdSettings.webhook_notification,
                  webhook_url: newProps.cicdSettings.webhook_url,
                  webhook_token: newProps.cicdSettings.webhook_token,
                  oldData,
              })
          }
      }
  }

  handlePushTrigger = (e:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
          isPush: e.target.checked
      })
  }

  handleTagTrigger = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
          isTag: e.target.checked
      })
  }

  handleIsEmail = (e:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
          email_notification: e.target.checked
      }, () => {
          // this.emailValidation()
      })
  }

  handleEmails = (emails:string[]) => {
      this.setState({
          emails : emails
      }, () => {
          this.emailValidation()
      })
  }

  handleIsSlack = (e:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
          slack_notification: e.target.checked
      }, () => {
          // this.slackValidation()
      })
  }

  slackValidation = () => {
      if(this.isEmpty(this.state.slack_webhook_url)){
          this.setState({
              errors: {
                  ...this.state.errors,
                  slack_webhook_url: true,
              },
              helperText: {
                  ...this.state.helperText,
                  slack_webhook_url: this.props.t('Environment.SettingsTab.slackError')
              }
          })
      } else{
          this.setState({
              errors: {
                  ...this.state.errors,
                  slack_webhook_url: false,
              },
              helperText: {
                  ...this.state.helperText,
                  slack_webhook_url: ""
              }
          })
      }      
  }

  emailValidation = () => {
      if(this.state.emails?.length <= 0){
          this.setState({
              errors: {
                  ...this.state.errors,
                  emails: true,
              },
              helperText: {
                  ...this.state.helperText,
                  emails: this.props.t('Environment.SettingsTab.emailError')
              }
          })
      } else{
          this.setState({
              errors: {
                  ...this.state.errors,
                  emails: false,
              },
              helperText: {
                  ...this.state.helperText,
                  emails: ""
              }
          })
      }      
  }

  webhookTokenValidation = () => {
      if(this.isEmpty(this.state.webhook_token)){
          this.setState({
              errors: {
                  ...this.state.errors,
                  webhook_token: true,
              },
              helperText: {
                  ...this.state.helperText,
                  webhook_token: this.props.t('Environment.SettingsTab.webHookError')
              }
          })
      } else{
          this.setState({
              errors: {
                  ...this.state.errors,
                  webhook_token: false,
              },
              helperText: {
                  ...this.state.helperText,
                  webhook_token: ""
              }
          })
      }  
  }

  webhookValidation = () => {
      if(this.isEmpty(this.state.webhook_url)){
          this.setState({
              errors: {
                  ...this.state.errors,
                  webhook_url: true,
              },
              helperText: {
                  ...this.state.helperText,
                  webhook_url: this.props.t('Environment.SettingsTab.webHookUrlError')
              }
          }, () => {
              this.webhookTokenValidation()
          })
      } else{
          this.setState({
              errors: {
                  ...this.state.errors,
                  webhook_url: false,
              },
              helperText: {
                  ...this.state.helperText,
                  webhook_url: ""
              }
          }, () => {
              this.webhookTokenValidation()
          })
      }
 
  }

  isEmpty = (text:string) => {
      if(text.trim().length <= 0) {
          return true
      }
      return false
  }

  handleSlackUrl = (e:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        ...this.state,
          [e.target.name]: e.target.value
      }, () => {
          this.slackValidation()
      })
  }

  handleWebhookUrl = (e:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        ...this.state,
          [e.target.name]: e.target.value
      }, () => {
          this.webhookValidation()
      })
  }

  handleIsWebhook = (e:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
          webhook_notification: e.target.checked
      })
  }

  handleSave = () => {
      this.setState({
          isConfirmSettingsSaveOpen: true
      })
  }

  disagreeSettingsSaveHandler = () => {
      this.setState({
          isConfirmSettingsSaveOpen: false
      })
  }

  agreeSettingsSaveHandler = () => {
      let _events = this.state.isPush ? "push" : '';
      if (this.state.isTag)
          _events = (_events ? _events + "," : '') + "release";
     
      const jsonBody:TjsonBody = {
          events: _events,
          event_type: this.state.event_type,
          email_notification: this.state.email_notification,
          emails: this.state.email_notification ? this.state.emails.join(",") : "",
          slack_notification: this.state.slack_notification,
          slack_webhook_url: this.state.slack_notification ?this.state.slack_webhook_url : "",
          webhook_notification: this.state.webhook_notification,
          webhook_url: this.state.webhook_notification ? this.state.webhook_url : "",
          webhook_token: this.state.webhook_token
      }
      this.props.updateCICDSettings(this.props.environmentDetails.id, jsonBody);
      this.setState({
          isConfirmSettingsSaveOpen: false
      })
  }

  // handleMouseOver = (e, popType) => {
  //     this.setState({
  //         anchorEl : e.currentTarget,
  //         popOverType : popType
  //       });
  // }

  // handleMouseOut = (e) => {
  //     this.setState({
  //         anchorEl : null
  //       });
  // }

//   handlePopoverClose = () => {
//       this.setState({ anchorEl : null });
//   };

  testEmail = () =>{
      this.props.testEmail(this.props.environmentDetails?.id)
  }

  testSlack = () =>{
      // if(this.state.emails?.trim()?.length > 0){
          this.props.testSlack(this.props.environmentDetails?.id)
      // }
  }

  testWebhook = () =>{
      // if(this.state.emails?.trim()?.length > 0){
          this.props.testWebhook(this.props.environmentDetails?.id)
      // }
  }

  handleEventType = (event:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ event_type: event.target.value })
  }
  //isFormValid

  isError = () => {
      let error = false
      if(this.state.slack_notification){
          error = error || this.state.errors.slack_webhook_url || this.state.slack_webhook_url?.trim()?.length <= 0
      }
      if(this.state.webhook_notification){
          error = error || this.state.errors.webhook_url || this.state.errors.webhook_token || this.state.webhook_url?.trim()?.length <= 0 || this.state.webhook_token?.trim()?.length <= 0
      }
      if(this.state.email_notification){
          error = error || this.state.errors.emails || this.state.emails?.length <= 0
      }
      return error
  }

  hasChanged = () => {
      if(Object.keys(this.state.oldData).length > 0){
          const newData = {
              isPush: this.state.isPush,
              isTag: this.state.isTag,
              event_type: this.state.event_type,
              email_notification: this.state.email_notification,
              emails: this.state.emails,
              slack_notification: this.state.slack_notification,
              slack_webhook_url: this.state.slack_webhook_url,
              webhook_notification: this.state.webhook_notification,
              webhook_url: this.state.webhook_url,
              webhook_token: this.state.webhook_token,
          }
          let changed = false
          Object.keys(this.state.oldData).forEach((k:string) => {
              if(JSON.stringify(newData[k as keyof typeof newData]) !== JSON.stringify(this.state.oldData[k as keyof Tdata])){
                  changed = true
                  return
              }
          })
          return changed
      }
  }

  render() {
      const { classes, environmentDetails, envRole,t } = this.props
      return (
        <Card elevation={1} className='ciSettings'>
            <CardHeader
              title={<Typography variant="h6">{t("Environment.SettingsTab.ciSettings")}</Typography>}
              subheader={<Typography variant="body2" color="textSecondary">{t("Environment.SettingsTab.manageCI")}</Typography>}
              action={
                this.props.cicdSettingsError !== 401 &&
                <>
                    {
                        isAuthorized("update", envRole.name) && (
                            <Button color="primary" disabled={this.isError() || !this.hasChanged()} variant="contained" onClick={() => this.handleSave()} data-test="save-button">
                                {t('Environment.SettingsTab.save')}
                            </Button>
                        )
                    }
                </>
              }
            />
            <Divider/>
            <CardContent>
                <div data-test="main-container">
                    <form>
                        <>
                            {
                                this.props.cicdSettingsError !== 401 &&
                                <Grid container spacing={2}>
                                    {/* <Grid item md={12} sm={6} xs={12} className='m-t-20'>
                                        <Typography color="primary" variant="h6" >Git Branch</Typography>
                                    </Grid> */}
                                    <Grid item md={12}>
                                        {/* <Tooltip title="git branch" arrow><AccountTreeOutlinedIcon color="primary" /></Tooltip>
                                        <Typography variant="h5" className='oneRemLeftMarginSeperator' data-test="env-branch">{environmentDetails && environmentDetails.git_branch}</Typography> */}
                                        {/* {this.props.environmentDetails?.service_type ===
                                        CreateApp_TemplateTypes.git &&
                                        isAuthorized("update", envRole.name) && (
                                            <Button
                                            color="primary"
                                            variant="contained"
                                            className="oneRemLeftMarginSeperator"
                                            onClick={this.props.handleCDChange}
                                            >
                                            {t("Environment.SettingsTab.change")}
                                            </Button>
                                        )} */}
                                        {this.props.environmentDetails?.service_type === CreateApp_TemplateTypes.git && isAuthorized("update", envRole.name) && ( <CDChangePopup handleAgree={this.props.handleAgree} />) }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider style={{backgroundColor: palette.white, borderBottom: `1px dashed ${palette.background.neutral}`}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography color="textPrimary" variant="subtitle2" >{t('Environment.SettingsTab.triggers')}</Typography>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={12} sm={4} lg={2}>
                                                <Typography color="textSecondary" variant="body2" >Select</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4} lg={1} className='cisettingswitch'>
                                                <FormControlLabel
                                                data-test="push-switch"
                                                control={
                                                    <Switch
                                                        name="push"
                                                        color="primary"
                                                        checked={this.state.isPush}
                                                        onChange={e => this.handlePushTrigger(e)}
                                                    />
                                                    }
                                                label={t('Environment.SettingsTab.push')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} lg={1}>
                                                <FormControlLabel
                                                data-test="tag-switch"
                                                control={
                                                    <Switch
                                                        name="tag"
                                                        color="primary"
                                                        checked={this.state.isTag}
                                                        onChange={e => this.handleTagTrigger(e)}
                                                    />
                                                    }
                                                label={t('Environment.SettingsTab.tag')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Typography color="textPrimary" variant="subtitle2" >{t('Environment.SettingsTab.notifications')}</Typography>
                                        <Grid container direction="row" justify="flex-start">
                                            <Grid item lg={2} sm={4} xs={12} className="centertxt" >
                                                <Typography  color="textSecondary" variant="body2">{t('Environment.SettingsTab.event')}</Typography>
                                            </Grid>
                                            <Grid item lg={10} sm={8} xs={12}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="" name="eventType" value={ this.state.event_type } onChange={e => this.handleEventType(e)} data-test="event-radio">
                                                        <Grid container spacing={1}>
                                                            <Grid item md={4} sm={4} xs={12}>
                                                                <FormControlLabel value="normal" control={<Radio color="primary" />} label={t('Environment.SettingsTab.normal')} />
                                                            </Grid>
                                                            <Grid item md={4} sm={4} xs={12}>
                                                                <FormControlLabel value="error" control={<Radio color="primary" />} label={t('Environment.SettingsTab.error')} />
                                                            </Grid>
                                                            <Grid item md={4} sm={4} xs={12}>
                                                                <FormControlLabel value="all" control={<Radio color="primary" />} label={t('Environment.SettingsTab.both')} />
                                                            </Grid>
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item md={1} sm={6} xs={12} className="centertxt" >
                                        <Typography  variant="body1">Event Types</Typography>
                                    </Grid>
                                    <Grid item md={2} sm={6} xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                
                                                />
                                            }
                                            label="Normal"
                                        />
                                    </Grid>
                                    <Grid item md={2} sm={6} xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    
                                                />
                                            }
                                            label="Error"
                                        />
                                    </Grid>
                                    <Grid item md={2} sm={6} xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                
                                                />
                                            }
                                            label="Both"
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} className='ciSettingsemail'>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={6} md={4} lg={3}>
                                                <Typography variant='body2'>{t('Environment.SettingsTab.sendEmail')}</Typography>
                                            </Grid>
                                            <Grid xs={6} md={8} lg={9}>
                                                <FormControlLabel
                                                data-test="email-checkbox"
                                                control={
                                                    <Switch 
                                                        checked={this.state.email_notification}
                                                        onChange={(e) => this.handleIsEmail(e)}
                                                    />
                                                    }
                                                label={null}
                                                labelPlacement='start'
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        this.state.email_notification &&
                                        <Grid item sm={6} xs={12} data-test="email-input-grid">
                                            {/* <MuiTextField
                                                name='emails'
                                                value={this.state.emails}
                                                onChange={(e) => this.handleEmails(e)}
                                                margin="normal"
                                            /> */}
                                            <EmailInput error={this.state.errors.emails ?? false} helperText={this.state.helperText.emails ?? ""} emails={ this.state.emails } handleEmails={this.handleEmails} data-test="email-input"/>
                                            {/* <Button className="m-t-10" onClick={this.testEmail} color="primary" variant="contained"  disabled={!this.props.cicdSettings?.emails || (this.state.emails.join(",") !== this.props.cicdSettings?.emails)} data-test="test-email">{t('Environment.SettingsTab.test')}</Button> */}
                                        </Grid>
                                    }
                                    <Grid item xs={12} className='ciSettingsemail'>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={6} md={4} lg={3}>
                                                <Typography variant='body2'>{t('Environment.SettingsTab.slack')}</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={8} lg={9}>
                                                <FormControlLabel
                                                    data-test="slack-checkbox"
                                                    control={
                                                        <Switch 
                                                            checked={this.state.slack_notification}
                                                            onChange={(e) => this.handleIsSlack(e)}
                                                        />
                                                        }
                                                    label={null}
                                                    labelPlacement='start'
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        this.state.slack_notification &&
                                        <Grid item xs={12} data-test="slack-webhook-grid">
                                            <InfoPopupContainer label={t('Environment.SettingsTab.webHook')} message={t('Environment.SettingsTab.webHookView')} url={"https://api.slack.com/messaging/webhooks#create_a_webhook"} urlText="Slack's documentation"  />
                                            <Grid container spacing={1} alignItems='center' style={{marginTop: 20}}>
                                                {/* <Grid item className={classes.branchInfo}>
                                                    <Typography variant='h5'>{t('Environment.SettingsTab.webHook')} </Typography>

                                                </Grid> */}
                                                <Grid item xs={12} md={8}>
                                                    <MuiTextField
                                                        name='slack_webhook_url'
                                                        value={this.state.slack_webhook_url}
                                                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.handleSlackUrl(e)}
                                                        error = {this.state.errors.slack_webhook_url}
                                                        helperText = {this.state.helperText.slack_webhook_url}
                                                        data-test="slack-url"
                                                        label={t('Environment.SettingsTab.webHook')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}> 
                                                    <Button onClick={this.testSlack} color="primary" variant="contained"  disabled={ !this.props.cicdSettings?.slack_webhook_url || (this.state.slack_webhook_url !== this.props.cicdSettings?.slack_webhook_url)} data-test="test-slack">{t('Environment.SettingsTab.test')}</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item xs={12} className='ciSettingsemail'>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={6} md={4} lg={3}>
                                                <Typography variant='body2'>{t('Environment.SettingsTab.enableWebHook')}</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={8} lg={9}>
                                                <FormControlLabel
                                                    data-test="webhook-checkbox"
                                                    control={
                                                        <Switch 
                                                            checked={this.state.webhook_notification}
                                                            onChange={(e) => this.handleIsWebhook(e)}
                                                        />
                                                        }
                                                    label={null}
                                                    labelPlacement='start'
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        this.state.webhook_notification &&
                                        <Grid item xs={12} data-test="webhook-input-grid">
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <InfoPopupContainer label={t('Environment.SettingsTab.webHook')} message={t('Environment.SettingsTab.hpac')} url={"#"} urlText = "Read more in the documentation" />
                                                    <Grid container spacing={1} style={{marginTop: 20}} alignItems='center'>
                                                        <Grid item xs={12} md={6} lg={5}>
                                                            <MuiTextField
                                                                name='webhook_url'
                                                                value={this.state.webhook_url}
                                                                onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.handleWebhookUrl(e)}
                                                                error = {this.state.errors.webhook_url}
                                                                helperText = {this.state.helperText.webhook_url}
                                                                data-test="webhook-url"
                                                                label={t('Environment.SettingsTab.webHook')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={5}>
                                                            <MuiTextField
                                                                name='webhook_token'
                                                                value={this.state.webhook_token}
                                                                onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.handleWebhookUrl(e)}
                                                                error = {this.state.errors.webhook_token}
                                                                helperText = {this.state.helperText.webhook_token}
                                                                label={"Token"}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={2}> 
                                                            <Button onClick={this.testWebhook} color="primary" variant="contained"  disabled={!this.props.cicdSettings?.webhook_url || (this.state.webhook_url !== this.props.cicdSettings?.webhook_url)} data-test="test-webhook">{t('Environment.SettingsTab.test')}</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* <Grid item sm={6} xs={12}>
                                                    <Grid container direction="column" spacing={1}>
                                                        <Grid item className={classes.branchInfo}>
                                                            <Typography variant='h5' >{t('Environment.SettingsTab.token')} </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <MuiTextField
                                                                name='webhook_token'
                                                                value={this.state.webhook_token}
                                                                onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.handleWebhookUrl(e)}
                                                                error = {this.state.errors.webhook_token}
                                                                helperText = {this.state.helperText.webhook_token}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                    }
                                
                                </Grid>
                            
                            }
                            {
                                this.props.cicdSettingsError === 401 &&
                                <Typography variant='h5'>{t('Environment.SettingsTab.permission')}</Typography>
                            }
                        </>
                        {/* {
                            this.props.cicdSettingsError !== 401 &&
                            <>
                                <Divider />
                                {
                                    isAuthorized("update", envRole.name) && (
                                            <Button color="primary" disabled={this.isError() || !this.hasChanged()} variant="contained" onClick={() => this.handleSave()} data-test="save-button">
                                            {t('Environment.SettingsTab.save')}
                                            </Button>
                                    )
                                }
                            </>
                        } */}
                    </form>
                    {/* <Popover
                        id='webhook_pop'
                        open={Boolean(this.state.anchorEl)}
                        //className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        anchorEl={this.state.anchorEl}
                        onClose={(e) => this.handlePopoverClose(e)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {
                            this.state.popOverType == 1 &&
                            <Typography variant='h5'>
                            {t('Environment.SettingsTab.webHookView')} <Link href='https://api.slack.com/messaging/webhooks#create_a_webhook' target="_blank" rel="noreferrer" underline='hover'>{"Slack's documentation"}</Link>
                            </Typography>
                        }
                        {
                            this.state.popOverType == 2 &&
                            <Typography variant='h5'>
                            {t('Environment.SettingsTab.hpac')} <Link href='#' target="_blank" rel="noreferrer" underline='hover'>Read more in the documentation</Link>
                            </Typography>
                        }
                    </Popover> */}
                    <ConfirmActionPopup open={this.state.isConfirmSettingsSaveOpen} handleAgree={this.agreeSettingsSaveHandler} handleDisAgree={this.disagreeSettingsSaveHandler} message={t('Environment.SettingsTab.updateSettings')} yesText={t('Projects.VariablesTab.yesText')} noText={t('Projects.VariablesTab.noText')}  data-test="confirm-popup"/>
                    {this.props.updatingCiSettings && <BackdropLoader message={t('Environment.SettingsTab.updatingCI')} data-test="update-CiSettings"/>}
                    {this.props.fetchingCiSettings && <BackdropLoader message={t('Environment.SettingsTab.fetchingCI')} data-test="fetch-CiSettings"/>}
                    {this.props.testing && <BackdropLoader message={t('Environment.SettingsTab.initiateTest')} data-test="testing"/>}

                </div>
            </CardContent>
        </Card>
      )
  }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  cicdSettings: state.CICDReducer.cicdSettings,
  cicdSettingsError: state.CICDReducer.cicdSettingsError,
  envRole: state.EnvironmentReducer.envRole,
  updatingCiSettings: state.CICDReducer.updatingCiSettings,
  fetchingCiSettings: state.CICDReducer.fetchingCiSettings,
  testing: state.CICDReducer.testing,

})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
      updateCICDSettings: (id:number, jsonBody:TjsonBody) => dispatch(updateCICDSettings(id, jsonBody)),
      fetchCICDSettings: (id:number) => dispatch(fetchCICDSettings(id)),
      clearCICDSettings: () => dispatch(clearCICDSettings()),
      testEmail: (eId:number) => dispatch(testEmail(eId)),
      testSlack: (eId:number) => dispatch(testSlack(eId)),
      testWebhook: (eId:number) => dispatch(testWebhook(eId)),
  }
}

const connector=connect(
    mapStateToProps,
    mapDispatchtoProps
  )

export default connector(withStyles(useStyles)(withTranslation()(CISettingsTab)))
type PropsFromRedux= ConnectedProps<typeof connector>