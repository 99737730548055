import React, { useState, useEffect } from 'react';
import Graph from './Graph';
import "@carbon/charts/styles.css";

export default function CITasks({
  data,
  defaultDirection = 'RIGHT'
}) {
  const [nodes, setNodes] = useState(null);
  const [edges, setEdges] = useState(null);
  const [workflow, setWorkflow] = useState(null);
  
  //need to move below declarations to appconstants
  const height = 50;
  const width = 250;

  const getNodeFormat = (task, workflowName) => {
    return {
      id: task.name,
      status: task.status,
      title: task.name,
      creation_time: task.creation_time,
      completion_time: task.completion_time,
      steps: task.steps,
      height: task.steps && task.steps.length > 0 ? height * (task.steps.length + 1) : height,
      width,
      workflowName: workflowName
    }
  }

  const getEdgeData = function () {
    if (workflow && workflow.pipeline) {
      const tasks = workflow.pipeline.tasks;
      const workflowName = workflow.pipeline.runner; //workflow.workflow.object_meta.name;
      let _edges = [];
      let _nodes = [];
      let counter = 0;
      tasks.forEach((task) => {
        _nodes.push(
          getNodeFormat(task, workflowName)
        )
        if (task.run_after && task.run_after.length > 0) {
          task.run_after.forEach((item) => {
            counter++
            _edges.push({ id: counter, source: item, target: task.name });
          });
        }
      });

      setEdges(_edges);
      setNodes(_nodes);
    }
  }

  useEffect(() => {
    if (data) {
      if (!workflow && data.pipeline) {
        setWorkflow(data);
      }
      else if (workflow && workflow.pipeline.runner !== data.pipeline.runner)
      {
        setEdges(null);
        setNodes(null);
        setWorkflow(null);
      }  
    }
    
  }, [data])

  useEffect(() => {
    if (workflow) {
      getEdgeData();
    }
  }, [workflow])

  return (
    <div>
      {
        edges && nodes &&
        <Graph
          direction={defaultDirection}
          id={"cards-1"}
          edges={edges}
          nodes={ nodes}
        />
      }
    </div>
  );
}