import { createStyles, makeStyles, Tab, Tabs,} from "@material-ui/core";
import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { redirectToClusterChanger } from "../../../cluster/redux/actions";
import { connect, ConnectedProps } from "react-redux";
import withWidth, { isWidthDown, WithWidth } from '@material-ui/core/withWidth';
import { useTranslation } from "react-i18next";
import paths from "../../../../constants/paths";
import { History } from "history";
import { TabPanel } from "../../../../components/tabpanel/TabPanel";
import RegistryTab from "../registrytab/RegistryTab";
import DnsTab from "../dnstab/DnsTab";
import ClusterList from "../../../cluster/ClusterList";
import SecurityTab from "../../../cluster/SecurityTab";
import { StyledTab, StyledTabs } from "../../../../components/StyledTab/StyledTab";
import palette from "../../../../theme/palette";
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import { ClusterIcon, DNSIcon } from "../../../../helpers/customIcon";
import SecurityIcon from '@material-ui/icons/Security';


function a11yProps(index: number){
    return{
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        backgroundColor: "inherit",
        display: "flex",
        padding: "0",
        margin: "0",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      },
      tabs: {
        // borderRight: `1px solid ${theme.palette.divider}`,
        // [theme.breakpoints.down("md")]: {
        //   backgroundColor: "white",
        // },
      },
      tabPanel: {
        padding: "0",
        margin: "0",
        width: "100%",
        minWidth: 250,
      },
      labelContainer : {
        backgroundColor: palette.background.disabled,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '24px',
        justifyContent: 'center',
        padding: '6px',
        borderRadius: '8px'
      },
      sideNavBar:{
        display:"flex", 
        flexDirection: 'column'
      }
    })
  );

  interface Props extends PropsFromRedux, WithWidth {
    history: History;
    innerTab: number;
    updateInnerTab: (_value: number) => void;
  }
  

export function ClusterTab(props: Props) {

    const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [t] = useTranslation();

  const getNamebyTabIndex = (newValue: number) => {
    const tabPanelObj = document.getElementById("clusterTab-" + newValue);
    if (tabPanelObj) {
      const name = tabPanelObj.getAttribute("name");
      if (name) {
        props.history.push({
          pathname:
            paths.ORGANIZATIONINFO.replace(":id", props.organizationInfo?.id) +
            "#cluster_" +
            name,
        });
      }
    }
  };

  const handleChange = (_: any, newValue: number) => {
    getNamebyTabIndex(newValue);
    props.updateInnerTab(newValue);
  };

    useEffect(() => {
    setValue(props.innerTab);
    }, [props.innerTab]);

    useEffect(() => {
    if (props.newClusterData && props.newClusterData.id > 0) {
        props.updateInnerTab(4);
    }
    return () => {
        props.updateInnerTab(0);
    };
    }, []);

    useEffect(() => {
    if (props.redirectToCluster) {
        props.updateInnerTab(4);
        props.redirectToClusterChanger();
    }
    }, [props?.redirectToCluster]);

    return(
      <div className={classes.root} data-test="main-container">
        <div className={classes.sideNavBar}>
          <p style={{display: isWidthDown("md", props.width) ? 'none' : 'flex'}} className={classes.labelContainer}>
            Nodes
          </p>
          <StyledTabs
            orientation={isWidthDown("md", props.width) ? undefined : "vertical"}
            value={value}
            variant="scrollable"
            onChange={handleChange}
            className={classes.tabs}
            indicatorColor="primary"
          >
            <StyledTab
                label={t("Cluster.Security.clusters")}
                icon = {<ClusterIcon viewBox="0 0 16 16" style={{width: 20, height: 20}}/>}
                {...a11yProps(1)}
                data-test="cluster-tab"
            />
            <StyledTab
              label={t("Cluster.Security.registry")}
              icon={<WidgetsOutlinedIcon fontSize="medium"/>}
              {...a11yProps(2)}
              data-test="registry-tab"
            />
            <StyledTab
              label={t("Cluster.Security.dns")}
              icon={<DNSIcon fontSize="medium"/>}
              {...a11yProps(3)}
              data-test="dns-tab"
            />
            <StyledTab
              label={t("Cluster.Security.security")}
              icon={<SecurityIcon fontSize="medium"/>}
              {...a11yProps(4)}
              data-test="security-tab"
            />
          </StyledTabs>
        </div>
        <TabPanel
          value={value}
          index={0}
          className={classes.tabPanel}
          name="cluster"
          padding={2}
          tabPanel="clusterTab"
        >
          <ClusterList history={props.history} />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          className={classes.tabPanel}
          name="registry"
          padding={2}
          tabPanel="clusterTab"
        >
          <RegistryTab history={props.history} />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          className={classes.tabPanel}
          name="dns"
          padding={2}
          tabPanel="clusterTab"
        >
          <DnsTab />
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          className={classes.tabPanel}
          name="security"
          padding={2}
          tabPanel="clusterTab"
        >
          <SecurityTab />
        </TabPanel>
    </div>
    )
    }

const mapStateToProps = (state: any) => ({
    newClusterData: state.ClusterReducer.newClusterData,
    redirectToCluster: state.ClusterReducer.redirectToCluster,
    organizationInfo: state.OrganizationReducer.organizationInfo,
  });

  const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
      redirectToClusterChanger: () => dispatch(redirectToClusterChanger()),
    };
  };
  const connector = connect(mapStateToProps, mapDispatchtoProps);
  export default connector(withWidth()(ClusterTab));

  type PropsFromRedux = ConnectedProps<typeof connector>;