import React from "react";
import AlertExpandableCard from "../../../../../components/alertmanager/AlertExpandableCard";
import { connect, ConnectedProps } from "react-redux";
import { Box, Button, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { Dispatch } from "redux";
import NoContentImage from "../../../../../components/nocontentimagecontainer/NoContentImage";
import paths from "../../../../../constants/paths";
import { History } from "history";
import { getAlertLists } from "../redux/action";
import { EnvSettingTabs } from "../../../../../constants/environmentTabs";
import { AppConstants } from "../../../../../constants/appconstants";
import { UserFileUploadIcon } from "../../../../../helpers/customIcon";

const useStyles = makeStyles(() => ({
  noContentContainer:{
    minWidth: 566
  },
  noContentInnerWrapper:{
    padding: '42px 48px'
  },
  noContentImageWrapper:{
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    marginBottom: 16
  },
  noContentImage:{
    height: 150, 
    width: 200
  }
}))

interface Props extends PropsFromRedux {
  alertLists: any;
  history: History;
  // changeHash: (
  //   hash: string,
  //   value: number,
  //   envId?: number,
  //   delay?: number
  // ) => void;
  changeInnerTab: (event: any, value: number) => void;
}

function Rules(props: Props) {
  const classes = useStyles();

  const handleTempPath = () => {
    const subIndex = EnvSettingTabs("#alert", props.environmentDetails).indexOf(AppConstants.EnvSettingsTabs.alerttemplates);
    //props.changeHash("alert", subIndex);
    props.changeInnerTab({}, subIndex);
  };

  const handleRefresh = () => {
    props.getAlertLists(props.environmentDetails?.id);
  }

  return (
    <>
      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          <Button
            onClick={() => handleRefresh()}
            color="primary"
            variant="contained"
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
      {props.alertLists && props.alertLists.length > 0 ? (
        <div className="m-t-20">
          {props.alertLists.map((item: any, ind: number) => (
              <AlertExpandableCard type="Rules" alertDetails={item} />
          ))}
        </div>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          data-test="noDataFound"
          direction="column"
        >
            <Grid item className={`${classes.noContentContainer} alertwhitecontainer`}>
              <Grid container direction="column" justify="center" alignItems="center" className={classes.noContentInnerWrapper}>
                <Grid item>
                  {/* <NoContentImage
                    type="env"
                    message="No alert template installed, Please install template to receive alerts"
                    alt="No Rules details"
                  /> */}
                    <Box component="div" className={classes.noContentImageWrapper}>
                        <UserFileUploadIcon className={classes.noContentImage} viewBox="0 0 200 150"/>
                        <Typography variant="body1">No alert template installed, Please install template to receive alerts</Typography>
                    </Box>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => handleTempPath()}
                    color="primary"
                    variant="contained"
                  >
                    Go To Template
                  </Button>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
      )}
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getAlertLists: (id: number) => dispatch(getAlertLists(id)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Rules);
