import React from 'react'
import { Grid, Chip, Tooltip } from "@material-ui/core"
import { CustomColors } from '../../constants/enums'
export default function TooltipChip({ title }: { title: string}) {
    return (
        <Grid item data-test="main-container">
            <Tooltip title={title} >
                {/* <Chip style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" }}
                    color={"primary"} variant="outlined" label={title} size="small"
                /> */}
                <Chip style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", backgroundColor: CustomColors.Success.Lighter, color: CustomColors.Success.Dark}}
                    label={title} size="small"
                />
            </Tooltip>
        </Grid>
    )
}
