import React from 'react';
import { Card, CardContent, Grid, Tooltip, Divider, Typography, Avatar, ButtonBase, makeStyles } from '@material-ui/core';
import './ProjectCardDetails.css';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
//import GitHubIcon from '@material-ui/icons/GitHub';
import { getStatusColor, is_hex_light, isAuthorized, getAppNameChar } from '../../helpers/utils'
import { Link } from 'react-router-dom';
import { DateHandler } from '../dateHandler/DateHandler';
// import { CreateApp_TemplateTypes } from '../../constants/enums';
import { RepoName } from "../../components/reponame/RepoName"
import { useTranslation } from 'react-i18next';
import paths from '../../constants/paths';
import { LabelHandler } from '../labelHandler/LabelHandler';
import { AppDetailsModel } from '../../models/Application.model';
import { UserRole } from '../../models/Environment.model';
import { History } from 'history';
import { CustomColors } from '../../constants/enums';
import palette from '../../theme/palette';
import { getAppStatusColor } from '../../helpers/utils.ext';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(() => ({
    labelContainer: {
        display: 'flex', 
        alignItems: 'center', 
        gap: 8, 
        marginTop: 8, 
        marginBottom: 8
    },
    avatarText: {
        fontSize: 12, 
        fontWeight: 500, 
        lineHeight: '18px',
    },
    avatarContainer: {
        borderRadius: '50%',
        height: 24,
        width: 24
    }
}))

type Props = {
    appData: AppDetailsModel,
    history: History,
    projectRole: UserRole
}


export const ProjectCardDetails = ({ appData, history, projectRole }: Props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const isHelmChart = appData?.chart_id ? true : false;
    const navigateToCreateEnv = (appId: number) => {
        history.push({
            pathname: isHelmChart ? paths.CREATEHELMENVIRONMENT.replace(":appId", appId.toString()) : paths.CREATEENVIRONMENT.replace(":appId", appId.toString())
        });
    }
    
    const navigateToEnv = (eId: number) => {
        history.push({
            pathname: isHelmChart ? paths.HELMENVIRONMENTINFO.replace(":eId", eId.toString()) : paths.ENVIRONMENTINFO.replace(":eId", eId.toString())
        });
    }

    const navigateToOperatorEnv = (appId: number) =>{
        history.push({
            pathname: paths.CREATEOPERATORENVIRONMENT.replace(":appId", appId.toString())
        });
    }
    
    const getImage = () => {
        let _image = "";
        if (appData) {
            if (isHelmChart && appData.chart && appData.chart.Icon) {
                _image = appData.chart.Icon
            }
            else {
                _image = appData.plugin?.image;
            }
        }
        return _image;
    }

    const getClusterFontColor = (bgColor: string) => {
      if (!bgColor) {
        return "#43425D";
      }
  
      if (is_hex_light(bgColor)) {
        return "#000000";
      }
  
      return "#ffffff";
    };
    
    // const getRepoName = (value) => {
    //     let repoName = '';
    //     if(value){
    //         // /^(https|git)(:\/\/|@)([^\/:]+)[\/:]([^\/:]+)\/(.+).git$/
    //         const res = /^(https|git)(:\/\/|@)([^/:]+)[/:]([^/:]+)\/(.+).git$/.exec(value);
    //         if(res)
    //             repoName = res[res.length -1];
    //     }
    //     return repoName;
    // }

    return (
        <>
            {/* <Paper elevation={ 1 } >
          <Link href={ `/app/${ appData.id }` } underline='none'>
          <Link to={ {
                                      pathname: '/app/' +  appData.id,
                                      state: { appData }
                                    } } style={{textDecoration:'none'}}>
              <div className='projectListsContainer'>
                  <div className='projectTitleShorter'>

                      <p className='projectSmallTitle'>{ appData.name != '' && appData.name.charAt(0).toUpperCase() }</p>
                  </div>

                  <img src={appData.plugin && appData.plugin.image} className="projectImg"/>

                  <div className='projectTitlesContainer'>
                      <span className='projectTitle'>{ appData.name }</span>
                      <span className='apps'>{appData.cluster && appData.cluster.zone}</span>
                  </div>
                 
              </div>
          </Link>
      </Paper> */}

            <Card elevation={1} className='appcardcontent' data-test="appCardContainer">
                <CardContent>
                    <Link data-test="appdetails-link" to={ { pathname: paths.APPDETAILS.replace(":appId", (appData.id).toString()), state: { appData } } } style={ { textDecoration: 'none'} }>
                        <Grid container spacing={ 2 } >
                            <Grid item xs={12}>
                                <img src={getImage()} className="projectImg" data-test="appPluginImg" alt=''/>
                            </Grid>
                            <Grid item xs={12}>
                                <div className='projectTitlesContainer'>
                                    <Typography variant='subtitle1' data-test="appName">{appData.name}</Typography>
                                </div>
                            </Grid>
                        </Grid>


                        <div className={classes.labelContainer}>
                            <div className="topgridalign">
                                <DateHandler 
                                    date={appData.createdat} 
                                    data-test="createdat-date"  
                                    icon={<ScheduleIcon style={{color: CustomColors.Grey[700]}}/>} 
                                    labelType='Grey'
                                    className='env-label-handler-container'
                                    variant='caption'
                                />
                            </div>
                            <div >
                                <LabelHandler
                                    icon={<LanguageOutlinedIcon style={{color: CustomColors.Success.Dark}}/>}
                                    iconTooltip={t("ClusterLabel")}
                                    label={appData?.cluster?.name}
                                    labelTooltip={appData?.cluster?.zone}
                                    data-test="clustername-label"
                                    labelType='Success'
                                    className='env-label-handler-container'
                                    variant='caption'
                                />
                            </div>
                            <div>
                                <RepoName source={2} appDetails={appData}  labelType='Secondary'/>
                            </div>
                        </div>
                    </Link>
                    <Grid container spacing={2} data-test="appEnvContainer">
                        <Grid item xs={12}>
                            <Divider  />
                            <Typography  variant="caption" style={{color: palette.text.disabled}} className="m-b-10" data-test="appEnvHeader">{t('Environments')}</Typography>
                            <div className="envavatardiv">
                                <div>
                                    {
                                        appData.env && appData.env.length > 0 && appData.env.length <= 3 &&
                                        appData.env.map((envItem, ind) =>
                                            <Tooltip title={ envItem.name + " (" + envItem.status +")" } arrow key={ ind } data-test="envs-card">
                                                <ButtonBase 
                                                    onClick={ () => navigateToEnv(envItem.id) }
                                                    data-test="env-navigate"
                                                >
                                                    <Avatar 
                                                    style={{ 
                                                    // boxShadow: "inset 0 0 0px 2px white, 0 0 0px 1px "+ getStatusColor(envItem.status), 
                                                    backgroundColor:getAppStatusColor(envItem.status).backgroundColor,
                                                    }}
                                                    variant="rounded" 
                                                    className={`${classes.avatarContainer} envavatar`}
                                                    > 
                                                    <Typography variant='subtitle2' color='textSecondary'>{getAppNameChar(envItem?.name)}</Typography>
                                                    </Avatar>
                                                </ButtonBase>
                                            </Tooltip>
                                        )
                                    }
                                    {
                                        appData.env.length > 3 &&
                                        <>
                                        {
                                        appData.env.slice(0,3).map((envItem, ind) =>
                                            <Tooltip title={ envItem.name + " (" + envItem.status +")" } arrow key={ ind } data-test="envs-card">
                                                <ButtonBase 
                                                    onClick={ () => navigateToEnv(envItem.id) }
                                                    data-test="env-navigate"
                                                >
                                                    <Avatar 
                                                    style={{ 
                                                    // boxShadow: "inset 0 0 0px 2px white, 0 0 0px 1px "+ getStatusColor(envItem.status), 
                                                    backgroundColor:getAppStatusColor(envItem.status).backgroundColor,
                                                    }}
                                                    variant="rounded" 
                                                    className={`${classes.avatarContainer} envavatar`}
                                                    > 
                                                    <Typography variant='subtitle2' color='textSecondary'>{getAppNameChar(envItem?.name)}</Typography>
                                                    </Avatar>
                                                </ButtonBase>
                                            </Tooltip>
                                        )}
                                        <ButtonBase 
                                            data-test="env-navigate"
                                        >
                                            <Avatar 
                                            style={{ 
                                            // boxShadow: "inset 0 0 0px 2px white, 0 0 0px 1px "+ CustomColors.Success.Lighter, 
                                            backgroundColor:CustomColors.Success.Lighter,
                                            }}
                                            variant="rounded" 
                                            className={`${classes.avatarContainer} envavatar`}
                                            > 
                                            <Typography className={classes.avatarText} style={{color: CustomColors.Success.Dark}}>{"+" + appData.env.slice(3).length}</Typography>
                                            </Avatar>
                                        </ButtonBase>
                                        </>
                                    }
                                </div>
                                <div>
                                    {appData?.cluster?.active && isAuthorized('create', projectRole?.name) && 
                                        <Tooltip title={t('AddEnvironment')} arrow>
                                            <ButtonBase 
                                            onClick={ () => appData?.operator_package_name? navigateToOperatorEnv(appData.id): navigateToCreateEnv(appData.id) } 
                                            data-test="addenv-button" 
                                            >
                                                <Avatar 
                                                variant="rounded" 
                                                className={`${classes.avatarContainer} envavatar`} 
                                                style={{ 
                                                    backgroundColor: CustomColors.Grey.Disabledbg
                                                }}
                                                >
                                                   <AddIcon color='primary'/>
                                                </Avatar>
                                            </ButtonBase>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        {/* {
                            !appData?.cluster?.active &&
                                <Typography color="error" className={classes.envAddInfo} variant="caption">Associated cluster is <b><em>inactive</em></b>. Cluster must be active in order to add environment(s).</Typography>
                        } */}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default ProjectCardDetails;