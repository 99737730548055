import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import NoContentImage from '../components/nocontentimagecontainer/NoContentImage';
import { hasValidSessionSelector } from '../pages/login/redux/selectors';
import { useHistory } from 'react-router-dom';

/* istanbul ignore next */
const useStyles = () => ({
 
});

export function NoMatchPage() {
    const history = useHistory();

    const goToHome = () => {
        history.push('/'); 
    };
        return (
            <div data-test="main-container">
                <Grid container justify="center" alignItems="center" className='notFoundGrid' direction='column'>
                    <NoContentImage 
                        message={"Resource not found"} 
                        type={"404"} 
                        alt={"No apps"}
                        data-test="noContentImage"
                    />
                    <Grid item>
                        <Button variant='contained' color='primary' style={{marginTop: 80}} onClick={goToHome}>Go to home</Button>
                        {/* Below line for test condition whether session exists or not */}
                        {/* {this.props.hasValidSession && <div></div>}  */}
                    </Grid>
                </Grid>
            </div>
        )
    
}

/* istanbul ignore next */
const mapStateToProps = state => ({
    hasValidSession: hasValidSessionSelector(state)
})

/* istanbul ignore next */
const mapDispatchtoProps = () => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withStyles(useStyles)(withTranslation()(NoMatchPage))) 