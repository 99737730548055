import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Typography,
  IconButton,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch } from "react-redux";
import { createAccessToken } from "../profiletab/redux/actions";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { validateInputField } from "../../../helpers/utils";
import MuiTextField from "../../../components/textfield/MuiTextField";
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

function GenerateToken({ open, setOpen }) {
  const [t] = useTranslation();
  let dispatch = useDispatch();

  const [note, setNote] = useState("");
  const [expiration, setExpiration] = useState(30);
  const [selectDate, setSelectDate] = useState("");
  const [disable, setDisable] = useState(false);
  const [date, setDate] = useState("");
  const [noteErr, setNoteErr] = useState("");
  const [noteErrMsg, setNoteErrMsg] = useState("");

  const formatDate =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);

  const expireDate = () => {
    const myDate = new Date();
    myDate.setDate(myDate.getDate() + expiration);
    const date =
      myDate.getFullYear() +
      "-" +
      ("0" + (myDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + myDate.getDate()).slice(-2);
    return date;
  };

  useEffect(() => {
    if (expiration > 0) {
      const formatDate = expireDate();
      setDate(formatDate);
    } else if (expiration === 0) setDate(selectDate);
    else setDate("");
  }, [selectDate, expiration]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNote = (e) => {
    
    let validation = validateInputField(e.target.value);
    setNoteErr(validation.error);
    setNoteErrMsg(validation.error ? validation.message: "")
    setNote(e.target.value);
    setDisable(false);
  };

  const handleExpiration = (e) => {
    setExpiration(e.target.value);
  };

  const handleGenerate = () => {
    const tokenValue = {
      expiry_date: date,
      name: note,
    };
    dispatch(createAccessToken(tokenValue));
    setOpen(false);
    setNote("");
    setSelectDate("");
    setDisable(true);
  };

  return (
    <div data-test='main'>
      {open && (
        <Dialog open={open} onClose={handleClose} data-test="dialog">
          <DialogTitle>
            <Typography className="dialogtitle" variant="h6">
            {t('AccessTokens.GenerateToken.generateToken')}
            </Typography>

            <IconButton
              aria-label="close"
              size="small"
              className="right"
              onClick={handleClose}
              data-test="close-popup"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent style={{ minWidth: 360 }} dividers>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {/* <Typography variant="h5">{t('AccessTokens.GenerateToken.notes')}</Typography> */}
                  <MuiTextField
                    id="outlined-basic" 
                    type="text"
                    value={note}
                    onChange={(e) => handleNote(e)}
                    error={noteErr}
                    helperText={t(noteErrMsg)}
                    autoFocus
                    data-test = "note"
                    label={t('AccessTokens.GenerateToken.notes')}
                  />
              </Grid>

              <Grid item xs={12}>
                {/* <Typography variant="h5">{t('AccessTokens.GenerateToken.expiration')}</Typography> */}
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth >
                      <InputLabel id="demo-simple-select">{t('AccessTokens.GenerateToken.expiration')}</InputLabel>
                      <Select
                        variant="outlined"
                        color="primary"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue="30"
                        value={expiration}
                        onChange={(e) => handleExpiration(e)}
                        data-test="select"
                      >
                        <MenuItem value={7}>7 {t('AccessTokens.GenerateToken.days')}</MenuItem>
                        <MenuItem value={30}>30 {t('AccessTokens.GenerateToken.days')}</MenuItem>
                        <MenuItem value={60}>60 {t('AccessTokens.GenerateToken.days')}</MenuItem>
                        <MenuItem value={90}>90 {t('AccessTokens.GenerateToken.days')}</MenuItem>
                        <MenuItem value={0}>{t('AccessTokens.GenerateToken.custom')}</MenuItem>
                        <MenuItem value={-1}>{t('AccessTokens.GenerateToken.notExpire')}</MenuItem>
                      </Select>
                    <FormHelperText>
                        {expiration !== 0 &&
                          <>
                            <InfoOutlinedIcon style={{width: 16, height: 16}}/>
                            <Typography variant="caption">{expiration === -1 ?  t('AccessTokens.GenerateToken.noExpiration') :`${t('AccessTokens.GenerateToken.expiresOn')} ${date}`}</Typography>
                          </>
                        }
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    {expiration === 0 && (
                      <div>
                        <TextField
                          fullWidth
                          type="date"
                          format="yyyy-mm-dd"
                          value={selectDate}
                          onChange={(e) => setSelectDate(e.target.value)}
                          variant="outlined"
                          data-test="calender"
                          // InputProps={{
                          //   endAdornment: (
                          //     <InputAdornment position="end">
                          //       <CalendarTodayOutlinedIcon fontSize="small" />
                          //     </InputAdornment>
                          //   ),
                          // }}
                          inputProps={{ min: formatDate }}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              size="small"
              variant="contained"
              type="reset"
              data-test="generate-btn"
              disabled={
                !note || disable || (expiration === 0 && selectDate === "") || noteErr
              }
              onClick={handleGenerate}
            >
              {t('AccessTokens.GenerateToken.generate')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default GenerateToken;
