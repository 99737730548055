import React, { useEffect, useState } from "react";
import { connect,ConnectedProps } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getInvoice, getLatestPaymentHistory, getPaymentHistory } from "./redux/actions";
import PaymentPopup from "../../../components/billing/PaymentPopup";
import { Dispatch } from "redux";
import { BillingHistory } from "../../../models/Billing.model";
/*istanbul ignore next */
const useStyles = makeStyles(() => ({
  actionButton: {
    borderRadius: 2,
    minWidth: 150,
  },
  cardAction: {
    marginTop: 0,
    marginRight: 0,
  },
  cardContent: {
    padding: "5px 16px",
    marginTop: '28px'
  },
  costText: {
    fontWeight: 400,
  },
  subHeader: {
    color: "#919EAB",
  },
  loader: {
    animationDuration: "550ms",
  },
  card: {
    height: "100%",
  },
}));


export const PaymentDue = (props:PropsFromRedux) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const [history, setHistory] = useState<BillingHistory[]>([]);

  const handlePayNowClick = () => {
    setOpenPaymentPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPaymentPopup(false);
  };

  const paymentSuccessCallback = () => {
    handleClosePopup();
    props.getInvoice();
    props.getLatestPaymentHistory();
    props.getPaymentHistory(10, 1);
  };

  useEffect(() => {
    setHistory(props.latestPaymentHistory ?? []);
  }, [props.latestPaymentHistory]);
  return (
    <Grid item xs={12} md={6} data-test="main-container">
      <Card className={classes.card}>
        <CardHeader
          classes={{ action: classes.cardAction }}
          title={
            <Typography variant="h6">
              {history.length > 0 && history[0].balance < 0
                ? t("Billing.PaymentDue.title")
                : t("Billing.AvailableBalance.title")}
            </Typography>
          }
          subheader={
            <Typography variant="caption" className={classes.subHeader}>
              {t("Billing.PaymentDue.subText")}
            </Typography>
          }
        />
        <CardContent classes={{ root: classes.cardContent }}>
          <Grid
            container
            alignItems="flex-start"
            justify="space-between"
            spacing={2}
          >
            <Grid item>
              <Typography
                variant="h4"
                className={classes.costText}
                data-test="total-due"
              >
                {/* {props.userInvoice ? `$${props.userInvoice.total_cost}` : "N/A"} */}
                {history.length > 0
                  ? `$${Math.abs(parseFloat(history[0].balance.toFixed(2)))}`
                  : `$0`}
              </Typography>
            </Grid>
            <Grid item>
              
            <Button
                  className={classes.actionButton}
                  variant="contained"
                  color="primary"
                  onClick={() => handlePayNowClick()}
                  data-test="payNow-button"
                  disableElevation
                >
                  {t("Account.Billing.payNow")}
                </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {openPaymentPopup && (
        <PaymentPopup
          openPopup={openPaymentPopup}
          handleClosePopup={handleClosePopup}
          invoiceId={(history.length > 0 && history[0].invoice_id)?history[0].invoice_id:0}
          amount={(history.length > 0 && history[0].balance )?parseFloat(history[0].balance.toFixed(2)):0}
          callback={paymentSuccessCallback}
          data-test="payment-popup"
        />
      )}
    </Grid>
  );
};

/*istanbul ignore next */
const mapStateToProps = (state:any) => ({
  // userInvoice: state.PaymentReducer.userInvoice,
  latestPaymentHistory: state.PaymentReducer.latestPaymentHistory,
});

/*istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    getInvoice: () => dispatch(getInvoice()),
    getLatestPaymentHistory: () => dispatch(getLatestPaymentHistory()),
    getPaymentHistory: (size:number, page:number) => dispatch(getPaymentHistory(size, page)),
  };
};
const connector=connect(mapStateToProps, mapDispatchtoProps)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PaymentDue);
