import { Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import palette from "../../theme/palette";

export const StyledDivider = withStyles(() => ({
    root:{
        backgroundColor: palette.white, 
        borderBottom: `1px dashed ${palette.background.neutral}`
    }
}))(Divider)