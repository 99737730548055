import { Grid, Link, Paper, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
// import Skeleton from "react-loading-skeleton";
import { Skeleton } from "@material-ui/lab";
import { connect, ConnectedProps } from "react-redux";
import CustomButton from "../../../components/custombutton/CustomButton";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import NoContentImage from "../../../components/nocontentimagecontainer/NoContentImage";
import paths from "../../../constants/paths";
import { getAppNameChar, isSharedProject } from "../../../helpers/utils";
import { currentOrgRole } from "../../login/redux/selectors";
import { getSubscriptionListCall } from "../../project/createproject/redux/actions";
import { getProjectListApiCall } from '../projectlist/redux/actions';
import { setCurrentProject, updateBreadcrumb } from "../redux/actions";
import "./projectlist.css";
import { History } from 'history';
import { ProjectModel } from "../../../models/Project.model";
import { Dispatch } from "redux";
import { BreadcrumbModel } from "../../../models/Common.model";

type Location = {
  state:{
    path:string
  }
}

interface Props extends WithTranslation, PropsFromRedux {
  history: History,
  location?: Location
}

type State = {
  ownProjects: ProjectModel[],
  sharedProjects: ProjectModel[]
}

type ProjectCardModel = {
  project: ProjectModel,
  getSubscriptionName:(subId: number) => void,
  handleProjectClick: (id: number) => void,
  isOwnedProject: boolean
}

export const ProjectCard = ({ project, getSubscriptionName, handleProjectClick,isOwnedProject }: ProjectCardModel) => {

  const getUserName=()=>{

    return !isOwnedProject && project &&  project.user &&
            project.user.first_name &&
            project.user.last_name  && 
            `( ${project.user.first_name} ${project.user.last_name} )`
  }

  return (
    <Grid
      key={project?.id}
      item
      sm={12}
      xs={12}
      md={4}
      data-test="projectCardContainer"
    >
      <Paper elevation={1}  style={{ position: 'relative', overflow: 'hidden' }}>
        <Link
          href="#"
          underline="none"
          onClick={() => handleProjectClick(project?.id)}
          data-test="projectClick"
        >
          <div className="projectListsContainer">
            <div className={`projectTitlesContainer hideTrailingChar ${project.active?'':'projectImageDisabled'}`}>
              <Typography variant="h4"  data-test="projectTitle">{project?.name}</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {project &&
                  project?.subscription_id &&
                  getSubscriptionName(project?.subscription_id)}{" "}
                {
                  getUserName()||""
                }
              </Typography>

            </div>
            {project?.image && (
              <img
                src={project?.image}
                className={project.active?"":"projectImageDisabled"}
                alt=""
                height={36}
                width={36}
                data-test="projectImage"
                style={{borderRadius: '50%'}}
              />
            )}
            {!project.image && (
              <div className={`projectTitleShorter ${project.active?'':"projectImageDisabled"}`}>
                <Typography variant="subtitle1" color="primary" data-test="projectSmallTitle">
                  {getAppNameChar(project?.name)}
                </Typography>
              </div>
            )}
            {/* <MoreHorizIcon /> */}
          </div>
        </Link>
        <img
          src="/images/infographics/shape.svg" 
          alt="Shape"
          className="cornerImage"
          style={{
            position: 'absolute',
            top: 0,
            right:0,
            width: 140, 
            height: 140,
          }}
        />
      </Paper>
    </Grid>
  );
};
export class ProjectList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ownProjects: [],
      sharedProjects: [],
    };
  }

  handleCreateProject = () => {
    this.props.history.push({
      pathname: paths.CREATEPROJECT,
    });
  };

  handleProjectClick = (id: number) => {
    id && this.props.history.push({
      pathname: paths.PROJECTINFO.replace(":id", id.toString()),
    });
  };

  componentDidMount() {
    this.props.getProjectListApiCall(this.props.history);
    this.props.getSubscriptionListCall(this.props.history);
    this.props.updateBreadcrumb([]);
    this.props.setCurrentProject(0);
    if (this.props.projectList) {
      let shared: ProjectModel[] = [];
      let own: ProjectModel[] = [];
      this.props.projectList.forEach((proj: ProjectModel) => {
        if (isSharedProject(proj, this.props.currentUser)) {
          shared.push(proj);
        } else {
          own.push(proj);
        }
      });
      this.setState({
        ownProjects: own,
        sharedProjects: shared,
      });
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
    if (
      nextProps.projectList ||
      nextProps.projectList !== this.props.projectList
    ) {
      let shared: ProjectModel[] = [];
      let own: ProjectModel[] = [];
      nextProps.projectList.forEach((proj: ProjectModel) => {
        if (isSharedProject(proj, this.props.currentUser)) {
          shared.push(proj);
        } else {
          own.push(proj);
        }
      });
      this.setState({
        ownProjects: own,
        sharedProjects: shared,
      });
    }
    // if((nextProps.organizationInfo.id && this.props.organizationInfo.id) && nextProps.organizationInfo.id !== this.props.organizationInfo.id) {
    //   this.props.getProjectListApiCall(this.props.history);
    // }
    if (nextProps.currentOrganization && this.props.currentOrganization && nextProps.currentOrganization.id !== this.props.currentOrganization.id) { 
      this.props.getProjectListApiCall(this.props.history);
    }

    if(this.props?.location?.state?.path){
      this.props.history.push(this.props.location.state.path)
    }

  };

  getSubscriptionName = (subId: number) => {
    const { subscriptionList } = this.props;
    if(subscriptionList) {
      for (let i = 0; i < subscriptionList.length; i++) {
        const subscription = subscriptionList[i];
        if (subscription.id === subId) {
          return subscription.name;
        }
      }
    }
    else
      return "";
  };

  canCreateProject=()=>{
    // check if default organization 
    const isOrg=this.props.currentOrganization && (this.props.currentOrganization.ID>0 || this.props.currentOrganization.id>0);
    const isInDefOrg=  !isOrg 

    // is inside org and is admin/owner of org
    const isOrgAdminOrOwner= isOrg && [0,1].includes(this.props.currentOrgRole) 
    return isInDefOrg || isOrgAdminOrOwner
}

  isCreateDisabled=()=>{
    const isOrg=this.props.currentOrganization && (this.props.currentOrganization.ID>0 || this.props.currentOrganization.id>0);
    const isOrgAdminOrOwner= isOrg && [0,1].includes(this.props.currentOrgRole) 
    const {currentUser}=this.props;
      if(!currentUser){
        return true
      }
      if( isOrgAdminOrOwner){
        return false
      }
      
     return this.state.ownProjects.length>= currentUser.quotas.user_project;
  }
  render() {
    const { t } = this.props;
    return (
        <div data-test="projectListContainer">   
            <div className="projectListContainer">
                <Typography color="textPrimary" variant="h5">Hi, Welcome back 👋</Typography>
                {this.canCreateProject()  ?
                   <CustomButton
                   label={t("CreateProject")}
                   onClick={this.handleCreateProject}
                   data-test="createButton"
                   disabled={this.isCreateDisabled()}
                   />:"" 
              }
            </div>
            <div className="allProjectListsContainer">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="h5">{!this.props.isLoading && this.state.ownProjects?.length !== 0 && t("MyProjects")}</Typography>
                  </Grid>
                    {this.props.isLoading &&
                      [0, 1, 2, 3].map((val) => {
                        return (
                            <Grid item sm={12} xs={12} md={4} key={val} data-test="projectListSkeleton">
                                <Paper variant="outlined" style={{ padding: "1rem", minHeight: 100, borderRadius: 16}}>
                                    {/* <SkeletonTheme height={250}> */}
                                        <Grid container className="displayFlexjustifySpacebetween">
                                            <Grid item xs={8} className="nameSkeleton">
                                                <Skeleton width="100%" />
                                            </Grid>
                                            <Grid item container justifyContent="flex-end" xs={4}>
                                                <Skeleton variant="circle" height={50} width={50} />
                                            </Grid>
                                        </Grid>
                                    {/* </SkeletonTheme> */}
                                </Paper>
                            </Grid>
                        );
                      })}
                    {!this.props.isLoading &&
              this.props?.projectList?.length === 0 && (
                <NoContentImage
                  message={t('NoProjects')}
                  alt="No Projects"
                  type="project"
                  data-test="projectListNoImage"
                />
              )}
                    {!this.props.isLoading &&
              this.state.ownProjects.map((project, ind) => {
                return (      
                    <ProjectCard
                      key={ind}
                      project={project}
                      getSubscriptionName={this.getSubscriptionName}
                      handleProjectClick={this.handleProjectClick}
                      data-test="ownProjectList"
                      isOwnedProject={true}
                    />
                )
              })}

                    {!this.props.isLoading && this.state.sharedProjects?.length > 0 && (
                    <>
                        <Grid item md={12}>
                            <Typography color="textPrimary" variant="h5">
                                {this.props.organizationInfo?.id ? t("OtherProjects") : t("SharedProjects")}
                            </Typography>
                        </Grid>
                        {this.state.sharedProjects.map((project, ind) => (
                            <ProjectCard
                              key={ind}
                              project={project}
                              getSubscriptionName={this.getSubscriptionName}
                              handleProjectClick={this.handleProjectClick}
                              data-test="sharedProjectList"
                              isOwnedProject={false}
                            />
                ))}
                    </>
            )}
                    {this.props.isLoading && <BackdropLoader />}
                </Grid>
            </div>
        </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  projectList: state.ProjectListReducer.projectList,
  isLoading: state.ProjectListReducer.isLoading,
  subscriptionList: state.CreateProjectReducer.subscriptionList,
  currentUser: state.AuthReducer.user,
  organizationInfo: state.OrganizationReducer.organizationInfo,
  currentOrgRole: currentOrgRole(state),
  currentOrganization: state.AuthReducer.currentOrganization,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getProjectListApiCall: (history: History) => dispatch(getProjectListApiCall(history)),
    getSubscriptionListCall: (history: History) =>
      dispatch(getSubscriptionListCall(history)),

    updateBreadcrumb: (breadcrumbData: BreadcrumbModel[]) =>
      dispatch(updateBreadcrumb(breadcrumbData)),
    setCurrentProject: (id: number) => dispatch(setCurrentProject(id)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(ProjectList));
