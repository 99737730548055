import React, { useEffect }  from 'react';
//import PropTypes from 'prop-types';
import { makeStyles,Tabs,Tab, Backdrop, Grid } from '@material-ui/core';
import SettingsTab from './settingstab/SettingsTab';
import SnapshotsTab from './snapshotstab/SnapshotsTab';
import RestoresTab from './restorestab/RestoresTab';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { WithWidth } from '@material-ui/core/withWidth';
import {connect,ConnectedProps} from 'react-redux';
import { withTranslation,WithTranslation } from 'react-i18next';
import paths from '../../constants/paths';
import {TabPanel} from "../../components/tabpanel/TabPanel";
import { createStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { AppConstants } from '../../constants/appconstants';
import Loader from '../../components/loader/Loader';
import NoContentImage from '../../components/nocontentimagecontainer/NoContentImage';
import palette from '../../theme/palette';
import { StyledTab, StyledTabs } from '../../components/StyledTab/StyledTab';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

function a11yProps(index:number) {
  return {
    id: `vertical-tab-${ index }`,
    'aria-controls': `vertical-tabpanel-${ index }`,
  };
}

/* istanbul ignore next */
const useStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: 'inherit',
    display: 'flex',
    padding : '0',
    margin : '0',
    [ theme.breakpoints.down('md') ]: {
      flexDirection : "column",
    }
  },
  tabs: {
    // borderRight: `1px solid ${ theme.palette.divider }`,
    // [ theme.breakpoints.down('md') ]: {
    //   backgroundColor:'white'
    // }
  },
  tabPanel :  {
    padding : '0',
    margin : '0',
    width : '100%',
    minWidth:250,
  },
  backdrop:{
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: "inherit",
    width:"100%",
    height:"70vh"
  },
  labelContainer : {
    backgroundColor: palette.background.disabled,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    justifyContent: 'center',
    padding: '6px',
    borderRadius: '8px'
  },
  sideNavBar:{
    display:"flex", 
    flexDirection: 'column'
  }
}));


type LocationState = {
  value: number;
};

interface Props extends WithWidth,PropsFromRedux,RouteComponentProps<{}, StaticContext, LocationState>,WithTranslation{
isHelmEnv:boolean;
value?:number;
innerTab:number;
updateInnerTab: (_value: number) => void

}

 export function BackupTab(props:Props) {
  const classes = useStyles();
  const environmentDetails = props.isHelmEnv ? props.helmEnvironmentDetails :  props.environmentDetails
  const [ value, setValue ] = React.useState(props.value ?
     props.value :
     ( props.history &&
       props.history.location &&
        props.history.location.state &&
         props.history.location.state.value) ?
          props.history.location.state.value : 0);

   /* istanbul ignore next */
   const getNamebyTabIndex = (newValue:number) => {
     const tabPanelObj = document.getElementById('settingsTab-' + newValue);
     if (tabPanelObj) {
       const name = tabPanelObj.getAttribute("name");
       if (name) {
         if(props.isHelmEnv){
           props.history.push({
             pathname: paths.HELMENVIRONMENTINFO.replace(":eId", environmentDetails?.id) + "#backup_" + name
           });
         }else{
           props.history.push({
             pathname: paths.ENVIRONMENTINFO.replace(":eId", environmentDetails?.id) + "#backup_" + name
           });
         }
       }
     }
   }

   const handleChange = (event:React.ChangeEvent<{}>, newValue:number) => {
    //setValue(newValue);
    getNamebyTabIndex(newValue);
    props.updateInnerTab(newValue);
   };

   /* istanbul ignore next */
   const changeToRestore = () => { 
    getNamebyTabIndex(1);
    props.updateInnerTab(1);
   }

   useEffect(() => {
     setValue(props.innerTab);
   }, [props.innerTab]);

   useEffect(() => {
     return (() => {
       props.updateInnerTab(0);
     })
   }, []);

  return (
      <div className={ classes.root } data-test="backupContainer">
        {
          props.environmentDetails?.application?.project?.organization_id === 0 || 
          (props.environmentDetails?.application?.project?.organization_id>0 && 
            props.packageStatus && props.packageStatus[AppConstants.packages.VELERO] === AppConstants.PackageStatus.Installed
          ) ? 
          <>
            <div className={classes.sideNavBar}>
              <p style={{display: isWidthDown("md", props.width) ? 'none' : 'flex'}} className={classes.labelContainer}>
                Backup
              </p>
              <StyledTabs
                orientation= { isWidthDown('md', props.width) ? undefined : "vertical" }
                value={ value }
                variant="scrollable"
                onChange={ handleChange }
                className={ classes.tabs }
                indicatorColor="primary"
                data-test="tabsContainer"
              >
                <StyledTab label={props.t('Backup.BackupTab.snapshots')} icon={<BackupOutlinedIcon fontSize='medium'/>} { ...a11yProps(0) } data-test="snapshotsLabel"/> 
                <StyledTab label={props.t('Backup.BackupTab.restores')} icon={<SettingsBackupRestoreOutlinedIcon fontSize='medium'/>} { ...a11yProps(1) } data-test="restoresLabel"/>
                <StyledTab label={props.t('Backup.BackupTab.settings')} icon={<SettingsOutlinedIcon fontSize='medium'/>} { ...a11yProps(2) } data-test="settingsLabel"/>
              </StyledTabs>
            </div>
      
            <TabPanel value={value} index={0} className={classes.tabPanel} name="snapshot" padding={2} tabPanel= "settingsTab">
                  <SnapshotsTab changeTab={ changeToRestore } isHelmEnv={props.isHelmEnv}/>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel} name="restore" padding={2} tabPanel= "settingsTab">
                  <RestoresTab isHelmEnv={props.isHelmEnv}/>
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabPanel} name="settings" padding={2} tabPanel= "settingsTab">
                  <SettingsTab isHelmEnv={props.isHelmEnv}/>
            </TabPanel>
          </> :
            props.packageStatus && props.packageStatus[AppConstants.packages.VELERO] === AppConstants.PackageStatus.NotInstalled ?
            <Grid container justify="center" alignItems="center" className="notFoundGrid" data-test="noDataFound">
              <NoContentImage
                type="env"
                message="Package is not installed. Please Contact support team."
                alt="No environment details"
              />
            </Grid>:
            <Backdrop className={classes.backdrop} open={true}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item><Loader /></Grid>
                <Grid item>Checking Packages status</Grid>
              </Grid>
            </Backdrop>
        }
      </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  helmEnvironmentDetails: state.HelmEnvironmentReducer.helmEnvironmentDetails,
  packageStatus: state.EnvironmentReducer.packageStatus,
});
const connector=connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withWidth()(withTranslation()(BackupTab)));