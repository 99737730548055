import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  FormControl,
  withStyles,
  Select,
  MenuItem,
  Grid,
  DialogTitle,
  createStyles,
  WithStyles
  //FormHelperText
} from "@material-ui/core";
import { connect,ConnectedProps } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { compose } from "redux";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import ConfirmActionPopup from "../../../components/confirmactionpopup/ConfirmActionPopup";
import { getDateInStandardFormat } from "../../../helpers/utils";
 import { Transition } from "../../../helpers/utils.ext";
import { fetchBuildImages, clearRollbackPopupInfo } from "../redux/actions";
import { withTranslation,WithTranslation } from 'react-i18next';
import { Dispatch } from "redux";
import { IrepositoryImage,Idata } from "../../../models/Repo.model";

// const transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

/* istanbul ignore next */
const useStyles = () => createStyles({
  formControl: {
    minWidth: "100%",
  },
  discp: {
    marginTop: "20px",
  },
});

type Tpayload={
  name: string;
  tag: string;
}
export interface Istate {
  isPopupOpened:          boolean;
  selectedImage:          string;
  isImageFieldError:      boolean;
  imageFieldErrorMessage: string;
  confirmPopup:           boolean;
  id:boolean
}

interface Iprops extends PropsFromRedux,WithTranslation,WithStyles<typeof useStyles> {
envId:number;
open:boolean;
message:string;
yesText:string;
noText:string;
repository_image:IrepositoryImage;
handleAgree:(payload:Tpayload)=>void;
handleDisAgree:()=>void
}
export class RollbackPopup extends Component<Iprops,Istate> {
  constructor(props:Iprops) {
    super(props);
    this.state = {
      isPopupOpened: false,
      selectedImage: "Select Image",
      isImageFieldError: false,
      imageFieldErrorMessage: "",
      confirmPopup: false,
      id:false
    
    };
  }

  componentDidMount = () => {
    if (this.props.envId) {
      this.props.fetchBuildImages(this.props.envId);
    }
  };

  UNSAFE_componentWillReceiveProps = (newProps:Iprops) => {
    if (this.state.id !== newProps.open) {
      this.setState({
        isPopupOpened: true,
        id: newProps.open,
      });
    }
    if (!this.props.envId && newProps.envId) {
      this.props.fetchBuildImages(newProps.envId);
    }
  };
  
  componentWillUnmount() {
    this.props.clearRollbackPopupInfo()
  }

  handleImageChange = (e:React.ChangeEvent<{ value: unknown }>) => {
    if (e.target.value === "Select Image") {
      this.setState({
        selectedImage: e.target.value as string,
        isImageFieldError: true,
        imageFieldErrorMessage: this.props.t('Environment.RollbackPopup.validImage'),
      });
    } else {
      this.setState({
        selectedImage: e.target.value as string,
        isImageFieldError: false,
        imageFieldErrorMessage: "",
      });
    }
  };

  handleRollback = () => {
    this.setState({
      confirmPopup: false,
    });
    const img = this.props.buildImages?.data[this.state.selectedImage];
    // const img = this.props.buildImages?.data?.find((i) => {
    //   return i.repository_tag === this.state.selectedImage;
    // });
    if (img) {
      const payload:Tpayload = {
        name: img.repository_name,
        tag: img.repository_tag,
      };

      this.props.handleAgree(payload);
    }
  };

  initiateRollback = () => {
    this.setState({
      confirmPopup: true,
    });
  };

  handleDisagree = () => {
    this.setState({
      confirmPopup: false,
    });
  };

  closePopup = () => {
    this.props.handleDisAgree();
  };

  isCurrentTag = (image:Idata, index:number) => {
    if (!this.props.repository_image && index === 0) {
      return true;
    }
    if (this.props.repository_image?.Tag === image.repository_tag) {
      return true;
    }
    return false;
  };

  getDropdownText = (build:Idata) => {
    return (
        <>
            {build.repository_tag.substring(0, 7)} by{" "}
            <span className="rolloverenvinfo"> {build.author}</span>{" "}
            <span className="rollenvtext">on</span>{" "}
            <span className="rolloverenvinfo">
                {" "}
                {getDateInStandardFormat(build.created_at)}
            </span>
        </>
    );
  };

  render() {
    // const { open } = this.state;
    const { open, classes, t } = this.props;
    console.log("props from rollback popup",this.props)
    return (
        <div data-test="rollbackPopupContainer">
            <Dialog
              open={open}
              TransitionComponent={Transition}
              disableEscapeKeyDown={ true }
              onClose={(_, reason) => {
              if (reason !== 'backdropClick') {
                this.closePopup()
              }}
              }
              //onClose={this.closePopup}
              keepMounted
              className="rollbackpopup"
              // data-test = "rollBack-dialog"
            >
                <DialogTitle
                data-test = "dialog-title"
                >
                    <Typography variant="h6" className="dialogtitle">
                        {t('Environment.RollbackPopup.rollback')}{" "}
                    </Typography>

                    <IconButton
                      aria-label="close"
                      size="small"
                      className="right"
                      onClick={this.closePopup}
                      data-test = "icon-button"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent style={{ minWidth: 360 }} dividers>
                    <Grid>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <FormControl
                                  test-data="rollbackFormcontrol"
                                  className={classes.formControl}
                                >
                                    <Select
                                      variant="outlined"
                                      color="primary"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={this.state.selectedImage}
                                      onChange={this.handleImageChange}
                                      error={this.state.isImageFieldError}
                      //helperText={this.state.versionFieldErrorMessage}
                                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                                      inputProps={{
                        "data-test": "rollbackSelect",
                      }}
                      data-test = "demo-simple-select"
                                    >
                                        <MenuItem value="Select Image">{t('Environment.RollbackPopup.images')}</MenuItem>
                                        {this.props.buildImages &&
                          // versionsList.versions[0].versions &&
                          this.props.buildImages?.data?.map((image:Idata, index:number) => (
                              <MenuItem
                                key={index}
                                value={index}
                                className="rollbacklist"
                                disabled={this.isCurrentTag(image, index)}
                                data-test = "menuitemOption"
                            
                              >
                                  {/* {image.repository_tag} */}
                                  {this.getDropdownText(image)}
                                  {this.isCurrentTag(image, index) && "(Current)"}
                              </MenuItem>
                          ))}
                                    </Select>

                                    {/* <FormHelperText error={this.state.isRoleFieldError}>{ this.state.RoleFieldErrorMessage }</FormHelperText> */}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.discp}>
                            <Typography variant="body2" color="textSecondary">
                            {t('Environment.RollbackPopup.envDown')}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                      onClick={this.initiateRollback}
                      color="primary"
                      variant="contained"
                      disabled={this.state.selectedImage === "Select Image"}
                      data-test="rollbackButton"
                    >
                          {t('Environment.EnvironmentInfo.rollBack')}
                    </Button>
                </DialogActions>
            </Dialog>
            {this.props.fetchingBuildImages && (
            <BackdropLoader message={t('Environment.RollbackPopup.buildImages')} />
        )}
            {this.state.confirmPopup && (
            <ConfirmActionPopup
              open={this.state.confirmPopup}
              message={t('Environment.RollbackPopup.sureRollBack')}
              handleAgree={this.handleRollback}
              handleDisAgree={this.handleDisagree}
              yesText={t('Projects.VariablesTab.yesText')}
              data-test = "confirmAction-popup"
            />
        )}
        </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  buildImages: state.EnvironmentReducer.buildImages,
  fetchingBuildImages: state.EnvironmentReducer.fetchingBuildImages,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    fetchBuildImages: (id:number) => dispatch(fetchBuildImages(id)),
    clearRollbackPopupInfo: () => dispatch(clearRollbackPopupInfo()),
  };
};

const connector=connect(
  mapStateToProps,
  mapDispatchtoProps
)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(compose(withStyles)(useStyles)(withTranslation()(RollbackPopup)));
