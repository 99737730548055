import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  FormControl,
  withStyles,
  Select,
  MenuItem,
  Grid,
  DialogTitle,
  createStyles,WithStyles
  //FormHelperText
} from "@material-ui/core";
import { connect,ConnectedProps } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { compose } from "redux";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import { withTranslation,WithTranslation } from "react-i18next";
import {Transition} from '../../../helpers/utils.ext'
import { OtherVersions, Version, PluginVersionModel, IOtherVersion, Versions } from "../../../models/Environment.model";
import palette from "../../../theme/palette";
// const transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

/* istanbul ignore next */
const useStyles = () => createStyles({
  formControl: {
    minWidth: "100%",
  },
  discp: {
    marginTop: "24px",
  },
  templateVersionContainer: {
    marginTop: "24px",
  },
  helperText:{
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '12px',
    color: palette.text.secondary
  }
});


type TcurrentVersions= {[key:string]:string}
 interface Istate {
  isPopupOpened:            boolean;
  selectedVersion:          string;
  isVersionFieldError:      boolean;
  versionFieldErrorMessage: string;
  otherVersions:            OtherVersions[];
  currentVersions:         TcurrentVersions|null;
  changed:                  boolean;
  id:boolean;
  voleFieldErrorMessage:string|undefined;
}
interface Iprops extends PropsFromRedux,WithTranslation,WithStyles<typeof useStyles> {
  version:string;
  currentVersions:{[key:string]:string};
  handleAgree:(data:{version:Version,other_version:IOtherVersion[]})=>void;
  handleDisAgree:()=>void;
  message:string;
  open:boolean;
  yesText:string;
  noText:string;
  versionList: PluginVersionModel
}
export class RecreatePopup extends Component<Iprops,Istate> {
  constructor(props:Iprops) {
    super(props);
    this.state = {
      isPopupOpened: false,
      selectedVersion: props.version ?? "Select version",
      isVersionFieldError: false,
      versionFieldErrorMessage: "",
      otherVersions: [],
      currentVersions: null,
      changed: false,
      id:false,
      voleFieldErrorMessage:undefined
    };
  }

  setOtherVersions = (versionsList: PluginVersionModel, currentVersions:TcurrentVersions) => {
    let all_versions = versionsList?.versions ?? [];
    let other_versions = all_versions.slice(1, all_versions.length);

    let modified_versions:OtherVersions[] = [];
    other_versions.forEach((ver) => {
      const _ver = {
        ...ver,
        selectedVersion: currentVersions
          ? currentVersions[ver.name]
          : (ver.versions?.length > 0 && ver.versions[0].name) || "",
      };
      modified_versions.push(_ver);
    });
    this.setState({
      otherVersions: modified_versions,
    });
  }

  componentDidMount = () => {
    if(this.props.versionsList){
      this.setOtherVersions(this.props.versionsList, this.props.currentVersions)
    }
  };

  UNSAFE_componentWillReceiveProps = (newProps:Iprops) => {
    if (this.state.id !== newProps.open) {
      this.setState({
        isPopupOpened: true,
        id: newProps.open,
      });
    }
    if (!this.props.currentVersions && newProps.currentVersions) {
      this.setState({
        selectedVersion: newProps.currentVersions.main,
      });
    }
    this.setOtherVersions(newProps.versionsList, newProps.currentVersions)
  };

  handleVersionChange = (e:React.ChangeEvent<{ value: unknown }>) => {
    if (e.target.value  === "Select version") {
      this.setState(
        {
          selectedVersion:e.target.value  as string,
          isVersionFieldError: true,
          versionFieldErrorMessage:  this.props.t("Environment.RecreatePopup.validationError"),
          
        },
        () => {
          this.versionChanged();
        }
      );
    } else {
      this.setState(
        {
          selectedVersion: e.target.value  as string,
          isVersionFieldError: false,
          versionFieldErrorMessage: "",
        },
        () => {
          this.versionChanged();
        }
      );
    }
  };

  handleRecreate = () => {
    if (
      this.state.selectedVersion &&
      this.state.selectedVersion !== "Select version"
    ) {
      const { versionsList } = this.props;
      const { selectedVersion, otherVersions } = this.state;
      const _version =
        versionsList?.versions?.length > 0 &&
        versionsList?.versions[0]?.versions &&
        versionsList.versions[0].versions.find(
          (versionDetails:Version) => versionDetails.name === selectedVersion
        );

         
      if (_version) {
        let data:{version:Version,other_version:IOtherVersion[]}
        // data = {
        //   // templateName : pluginDetails.name,
        //   // selectedVersion,
        //   // pluginId : pluginDetails.id,
        //   // versionId : versionsList.id,
        //   version: _version,
        // };

        let other_version:IOtherVersion[]=[] ;
         otherVersions.forEach((ver) => {
            const otherVersion=ver.versions.find((_ver) => _ver.name === ver.selectedVersion)
            if(!otherVersion) return;
          const other_version_data = {
            deployment_name: ver.name,
            ...otherVersion,
          };
          other_version.push(other_version_data)
        });

        data={version:_version,other_version:other_version}
        // data.other_version = other_version.filter((ver)=>!!ver);
        this.setState({
          changed: false,
        });
    

          this.props.handleAgree(data);
        
      }
    } else {
      this.setState({
        isVersionFieldError: true,
        voleFieldErrorMessage: this.props.t("Environment.RecreatePopup.validationError"),
      });
    }
  };

  closePopup = () => {
    this.props.handleDisAgree();
  };

  otherVersionChangeHandler = (e:React.ChangeEvent<{ value: unknown }>, ver:OtherVersions) => {
    const newOtherVersions = this.state.otherVersions.map((ov) => {
      if (ov.name === ver.name) {
        ov.selectedVersion = e.target.value  as string;
      }
      return ov;
    });
    this.setState(
      {
        otherVersions: newOtherVersions,
      },
      () => {
        this.versionChanged();
      }
    );
  };

  versionChanged = () => {
    if (this.props.currentVersions) {
      const cv = this.props.currentVersions;
      let changed = false;
      if (this.state.selectedVersion !== cv.main) {
        this.setState({
          changed: true,
        });
        return true;
      }
      this.state.otherVersions.forEach((v) => {
        if (v.selectedVersion !== cv[v.name]) {
          changed = true;
          this.setState({
            changed: true,
          });
          return true;
        }
      });
      if (!changed) {
        this.setState({
          changed: false,
        });
      }
    } else {
      this.setState({
        changed: true,
      });
    }
  };

  render() {
    // const { open } = this.state;
    const { versionsList, open, classes, t } = this.props;
    return (
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          disableEscapeKeyDown={ true }
          onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            this.closePopup()
          }}
				}
          keepMounted
        >
          <DialogTitle>
            <Typography className="dialogtitle" variant="h6" data-test="dialog-title">
              {t("Environment.RecreatePopup.recreate")}
            </Typography>

            <IconButton
              aria-label="close"
              size="small"
              className="right"
              onClick={this.closePopup}
              data-test="close-icon"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent style={{ minWidth: 360 }}>
            <Grid>
              <Grid container spacing={3}>
                <Grid item md={12} data-test="versionList">
                  <Typography variant="body2">
                    {versionsList?.plugin && versionsList?.plugin?.name}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <FormControl className={classes.formControl}>
                    <Select
                      variant="outlined"
                      color="primary"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.selectedVersion}
                      onChange={(e) => this.handleVersionChange(e)}
                      error={this.state.isVersionFieldError}
                      data-test="main-version-select"
                      //helperText={this.state.versionFieldErrorMessage}
                      MenuProps={{
                        disableScrollLock: true,
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="Select version">
                        {t("Environment.RecreatePopup.versions")}
                      </MenuItem>
                      {versionsList?.versions &&
                        versionsList.versions[0] &&
                        // versionsList.versions[0].versions &&
                        versionsList.versions[0].versions?.map(
                          (versionDetails:Versions, index:number) => (
                            <MenuItem key={index} value={versionDetails.name}>
                              {versionDetails.name}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    {/* <FormHelperText error={this.state.isRoleFieldError}>{ this.state.RoleFieldErrorMessage }</FormHelperText> */}
                  </FormControl>
                </Grid>
              </Grid>
              {this.state.otherVersions?.length > 0 &&
                this.state.otherVersions.map((ver, ind) => (
                  <Grid
                    container
                    spacing={3}
                    key={ind}
                    data-test="other-version"
                    className={classes.templateVersionContainer}
                  >
                    <Grid item md={12}>
                      <Typography variant="body2">{ver.name}</Typography>
                    </Grid>
                    <Grid item md={12}>
                      <FormControl className={classes.formControl}>
                        <Select
                          variant="outlined"
                          color="primary"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ver.selectedVersion}
                          data-test = "demo-sample-otherVersion"
                          onChange={(e) => {
                            this.otherVersionChangeHandler(e, ver);
                          }}
                          // error={this.state.isVersionFieldError}
                          // helperText={this.state.versionFieldErrorMessage}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}                        
                        >
                          <MenuItem value="Select version">
                            {t("Environment.RecreatePopup.versions")}
                          </MenuItem>
                          {ver.versions?.map((versionDetails, _ind) => (
                            <MenuItem value={versionDetails.name} key={_ind}>
                              {versionDetails.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText error={this.state.isRoleFieldError}>{ this.state.RoleFieldErrorMessage }</FormHelperText> */}
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
              <Grid
                container
                className={classes.discp}
                data-test="info-message"
              >
                <Typography variant="caption" className={classes.helperText}>
                  {t("Environment.RecreatePopup.bringDown")}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleRecreate}
              color="primary"
              variant="contained"
              disabled={
                !(this.state.selectedVersion !== "Select version") ||
                !this.state.changed
              }
              data-test="action-button"
            >
              {t("Environment.EnvironmentInfo.recreate")}
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.fetchingVersionsList && (
          <BackdropLoader
            message={t("Environment.RecreatePopup.fetchingVersions")}
          />
        )}
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  versionsList: state.CreateAppReducer.versionsList,
  fetchingVersionsList: state.CreateAppReducer.fetchingVersionsList,
  pluginDetails: state.AppsReducer.pluginDetails,
});

/* istanbul ignore next */
const mapDispatchtoProps = () => {
  return {
    // fetchUserListInProjectAction: (projectId) => dispatch(fetchUserListInProject(projectId))
  };
};

const connector=connect(
  mapStateToProps,
  mapDispatchtoProps
)
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(compose(withStyles)(useStyles)(withTranslation()(RecreatePopup)));
