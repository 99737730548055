import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ButtonBase,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Skeleton } from "@material-ui/lab";
import BackdropLoader from "../../components/loader/BackdropLoader";
import { scanCluster } from "./redux/actions";
import toast from '../../components/toast/Toast';
import { useTranslation } from "react-i18next";
import { CustomColors } from "../../constants/enums";
import palette from "../../theme/palette";


const useStyles = makeStyles({
  root: {
    marginTop: 0,
  },
  paperContainer: {
    height: 200,
    width: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
  },
  iconContainer: {
    border: `1px solid ${CustomColors.Grey.border}`,
    borderRadius: 16,
    width: 200,
    height: 200,
    padding: 0,
  },
  selectTemplateTypography: {
    marginTop: "1rem",
    textTransform: "none",
  },
  backBtn: {
    color: CustomColors.Success.Main,
    marginBottom: "20px",
    display:'flex',
    alignItems:'center'
  },
  typo:{
    color: palette.text.secondary
  },
  dropdown: {
    minWidth: 200,
    height: 50,
  },
  pluginsection :{
    marginTop: 24
  },
  pluginCard:{
    marginTop: 24,
    justifyContent: 'center'
  }
});


interface Props extends PropsFromRedux {
  handleBack: () => void,
  clusterId: number
}

function NewScan(props: Props) {
  const classes = useStyles();
  const [t] = useTranslation();
  const [isClusterList,setIsClusterList] = useState([]);
  const [provider, setProvider] = useState<number>(props.clusterId);
  const [confirmScan, setConfirmScan] = useState(false);
  const [scanPlugin, setScanPlugin] = useState("")
  const [scanLoader, setScanLoader] = useState(false);
  const [ispluginDetails,setIsPluginDetails] = useState([]);

  useEffect(() => {
    if(props.clusterList){
        setIsClusterList(props.clusterList);
    }
  },[props.clusterList])

  useEffect(() => {
    if(props.clusterScanPluginDetails){
      setIsPluginDetails(props.clusterScanPluginDetails.cluster)
    }
  },[props.clusterScanPluginDetails])

  const handleProviderSelection = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProvider(event.target.value as number);
  };

  const handleStartScan = (plugin:string) => {
    setScanPlugin(plugin)
    setConfirmScan(true);
  }

  const runScanLoader = () =>{
    setScanLoader(false)
    props.handleBack();
  }

  const handleConfirmPopupAgree = () => {
    if(provider !== 0){
      props.scanCluster(provider,scanPlugin);
      runScanLoader()
      setConfirmScan(false);
      setScanLoader(true)
    }
    else{
      toast.error(t("Cluster.Security.errortoast"));
      setConfirmScan(false);
    }
  }

  const handleConfirmPopupDisagree = () => {
    setConfirmScan(false);
  }

  const imgArray: any = {
    image: "/images/icons/containerregistry.svg",
    k8s:"/images/provider/gke.svg",
    repo:"/images/icons/git.svg"
  }
  

  return (
    <div data-test="main-container" className="newrun-scan">
      <div style={{display: 'flex', alignContent: 'center', justifyContent: 'space-between'}}>
        <ButtonBase
          onClick={()=>{ props.handleBack() }}
          className={classes.backBtn}
          data-test="backButton"
        >
          <ArrowBackIcon fontSize="small" />{" "}
          <span className="jobNav">{t("Cluster.Security.back")}</span>
        </ButtonBase>

        {Array.isArray(isClusterList) && isClusterList.length > 0 && 
              <FormControl variant="outlined" className={classes.dropdown}>
              <InputLabel>{t("Cluster.Security.selectcluster")}</InputLabel>
                <Select
                  value={provider || ''}
                  onChange={handleProviderSelection}
                  > 
                  {isClusterList
                    .map((item: any, index:number) => (
                      <MenuItem key={item.cluster_id} value={item.cluster_id}>
                        {item.cluster_name}
                      </MenuItem>))}
                </Select>
              </FormControl>
          }
      </div>
      <div>
        {" "}
        {/* <Typography variant="h3">{t("Cluster.Security.runnewscan")}</Typography>{" "} */}
        <div>
          <div data-test="select-cluster">
            {/* <Typography
              variant="h5"
              className={classes.typo}
            >
              {t("Cluster.Security.selectcluster")}
            </Typography> */}

          {props.scanningCluster &&
            <Grid container spacing={2}>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
            </Grid>
          }

            {/* <Grid container spacing={2} className={classes.root}>
              {isClusterList && 
                  <Grid item xs={12} md={2}>
                   <FormControl variant="outlined" className={classes.dropdown}>
                    <InputLabel>{t("Cluster.Security.selectcluster")}</InputLabel>
                      <Select
                       value={provider || ''}
                        onChange={handleProviderSelection}
                        > 
                        {isClusterList
                          .map((item: any, index:number) => (
                            <MenuItem key={item.cluster_id} value={item.cluster_id}>
                              {item.cluster_name}
                            </MenuItem>))}
                      </Select>
                   </FormControl>
                  </Grid>
                }
            </Grid> */}
          </div>
        
          <div data-test="select-cluster-plugin" className={classes.pluginsection}>
            <Typography
              variant="subtitle1"
              className={classes.typo}
            >
              {t("Cluster.Security.selectplugin")}
            </Typography>

            {props.scanningCluster &&
            <Grid container spacing={2}>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
            </Grid>
            }

            <Grid container spacing={2} className={classes.pluginCard}>
              {Array.isArray(ispluginDetails) && ispluginDetails.length > 0 && ispluginDetails.map((item: any) => (
                  <Grid item xs={12} md={3}>
                    <Button
                      className={`${classes.iconContainer} circle-background`}
                      onClick={() => handleStartScan(item.name)}
                    >
                      <Paper className={`${classes.paperContainer}`}>
                        <div>
                          <img
                            src={ item.logo }
                            width="80px"
                            height="80px"
                            alt=""
                            data-test="provider-img"
                          />
                        </div>
                        <Typography
                          className={classes.selectTemplateTypography}
                          variant="h3"
                          color="textPrimary"
                          data-test="provider-name"
                        >
                          {item.provider}
                        </Typography>
                      </Paper>
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </div>

      {confirmScan && (
      <ConfirmActionPopup
        open={confirmScan}
        handleAgree={handleConfirmPopupAgree}
        handleDisAgree={handleConfirmPopupDisagree}
        yesText={t("Cluster.Security.yes")}
        noText={t("Cluster.Security.no")}
        message={<p>{t("Cluster.Security.confirmmsg")} {scanPlugin}</p>}
      />
    )}

    {scanLoader && (<BackdropLoader message={`Scanning using ${scanPlugin} ... `} />)}

    </div>
  );
}

const mapStateToProps = (state: any) => ({
    clusterScanPluginDetails: state.ClusterReducer.clusterScanPluginDetails,
    scanningCluster: state.ClusterReducer.scanningCluster,
    scanedClusterDeatils: state.ClusterReducer.scanedClusterDeatils,
    clusterList: state.ClusterReducer.clusterList,
    
});
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    scanCluster: (clusterId: number, clusterName: string) => dispatch(scanCluster(clusterId, clusterName))
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(NewScan);
type PropsFromRedux = ConnectedProps<typeof connector>;
