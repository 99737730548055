import React, { Component } from 'react'
import { Paper,Card, Grid,Button, ButtonBase,FormControl,TextField,  CircularProgress, Switch, FormControlLabel, Box, CardHeader, Divider, CardContent, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '../../components/textfield/MuiTextField';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { coreConversion, spaceCoversion, isAuthorized, validateInputField } from '../../helpers/utils'
import Alert from '@material-ui/lab/Alert';
import './resourcepaper.css';
import HPA from '../hpa/HPA';
import Loadbalancer from '../loadbalancer/Loadbalancer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CreateApp_TemplateTypes, CustomColors } from '../../constants/enums';
import Scheduling from '../hpa/Scheduling';
import VPA from '../vpa/VPA';
import MemoryIcon from '@material-ui/icons/Memory';
import { RocketLaunchIcon } from '../../helpers/customIcon';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import palette from '../../theme/palette';

/* istanbul ignore next */
const useStyles = () => ({
    containerPaper: {
        margin: 20,
        padding: 24,
        marginTop: 40,
    },
    coreGrid: {
        marginTop: 0
    },
    corePaper: {
        backgroundColor: palette.background.disabled,
        padding : 20,
        width:'100%',
        borderRadius:'16px',
        color: `${palette.text.secondary} !important`,
        border: '0px !important'
    },
    envHeader: {
        textAlign:'center'
    },
    formControl : {
        minWidth : '100%',
    },actions: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 48,
        marginBottom: 20
    },
    header: {
        display: "flex", 
        justifyContent: "flex-end"
    },
    fullBtn:{
        width:'100%'
    },
    resourceInactive: {
        opacity: 0.5,
        backgroundColor: palette.white,
        pointerEvents: 'none',
        width:'100%'
    },
    infoPaper: {
        margin: "20px 20px",
    },
    skeletonItem: {
        textAlign: "center",
    },
    textColor: {
        color: palette.text.disabled
    },
    infoBackground:{
        borderRadius: '8px', 
        backgroundColor: CustomColors.Info.Lighter
    },
    infoText:{
        color: CustomColors.Info.Darker
    }
});

export class ResourcePaper extends Component {
    constructor(props){
        super(props);
        this.state = {
            branchError: false,
            branchErrorText: null,
            isEnvironmentFieldError : false,
            isSubmitButtonDisabled:false,   
            EnvironmentFieldErrorMessage : '',
            //environmentName:'master',
            availableRam : 10,
            availableCore : 8,
            selectedResource : {
                cores : 1,
                memory : 1
            },
            max_replica_limit: 1,
            systemDetail : {
                //'id' : 0,
                //availableOptions : this.props.resources,
  
            },
            storageSizeList:[],
            storageTypeList:[],
            error: false,
            lbError: false,
            scheduling: false,
        }
    }

    componentDidMount(){
        const statePayload={              enable_loadbalancer: this.props.load_balancer_id ? true: false,
            load_balancer_id : this.props.load_balancer_id ? this.props.load_balancer_id : null,
            replicas : this.props.defaultReplicas ? this.props.defaultReplicas : 1,
            environment : this.props.defaultEnvName ? this.props.defaultEnvName : 'development',
            gitBranch: (this.props.defaultBranch && this.props.defaultBranch !== "Select Branch") ? this.props.defaultBranch : (this.props.gitBranches && this.props.gitBranches.length > 0) ? this.props.gitBranches[0].name : 'Select Branch',
            resource: this.props.defaultResource ? this.props.defaultResource : this.getDefaultResource(),
            support_pv: this.props.pvcDetails ? this.props.pvcDetails.enabled : false,
            storage: {
                // enabled: this.props.pvc ? this.props.pvc.enabled : false,
                path: this.props.pvcDetails ? this.props.pvcDetails.path : "/data",
                storageClass: this.props.pvcDetails ? this.props.pvcDetails.storageClass : "default",
                size: this.props.pvcDetails ? this.props.pvcDetails.size : 1,
            },
            auto_scaler:{
                enabled: this.props.auto_scaler ? this.props.auto_scaler?.enabled : false,
                horizontal_pod_autoscaler: this.props.auto_scaler?.horizontal_pod_autoscaler ? this.props.auto_scaler?.horizontal_pod_autoscaler :  {
                    max_replicas: 0,
                    metrics: null,
                    min_replicas: 0,
                    scale_down_rule: null,
                    scale_up_rule: null
                },
    
                vertical_pod_autoscaler: this.props.auto_scaler?.vertical_pod_autoscaler ? this.props.auto_scaler?.vertical_pod_autoscaler :{
                    controlled_resources: null,
                    controlled_values: "RequestsAndLimits",
                    max_allowed_cpu: "1000",
                    max_allowed_memory: "500",
                    min_allowed_cpu: "100",
                    min_allowed_memory: "50",
                    mode: "",
                    update_mode: "Auto"
                },
                //branch : 'master'
                rwxEnable: this.props.rwxEnable,
                advanced_scheduling: this.props.auto_scaler?.advanced_scheduling ? this.props.auto_scaler?.advanced_scheduling:{
                    label_selector: {
                        key: "app.kubernetes.io/provider",
                        value: "zerone"
                    },
                    match_label_keys: null,
                    max_skew: 1,
                    min_domains: 0,
                    node_affinity_policy: "Honor",
                    node_taint_policy: "Honor",
                    topology_key: "kubernetes.io/hostname",
                    when_unsatisfiable: "DoNotSchedule"
                }
            }
        }

            this.setState((prevState)=>{
                return {...prevState,systemDetail:statePayload, scheduling: this.props.auto_scaler?.advanced_scheduling?.max_skew }
            },()=>{
                this.props.handleResourceUpdate(this.state.systemDetail, true)
                if(this.props.availableResources ){
                    this.setMaxReplicaLimit(this.props.availableResources)
                }
            })
     
    }
      
    UNSAFE_componentWillReceiveProps(newProps){
        if(newProps.pvcDetails){
            const _storage = {
                path: newProps.pvcDetails.path,
                size: newProps.pvcDetails.size,
                storageClass: newProps.pvcDetails.storageClass,
            }
            this.setState({
                pv_support: newProps.pvcDetails.enabled,
                systemDetail: {
                    ...this.state.systemDetail,
                    storage: _storage
                }
            })
        }
        if(!Object.keys(this.props.availableResources).length && newProps.availableResources){
            this.setMaxReplicaLimit(newProps.availableResources)
        }
    }

    updatedAdvancedScheduling = (updatedState) => {
        this.setState({
            systemDetail:{
                ...this.state.systemDetail,
                auto_scaler:{
                    ...this.state.systemDetail.auto_scaler,
                    advanced_scheduling: updatedState && Object.keys(updatedState).length !== 0? {...updatedState} : {
                        label_selector: {
                            key: "app.kubernetes.io/provider",
                            value: "zerone"
                        },
                        match_label_keys: null,
                        max_skew: 1,
                        min_domains: 0,
                        node_affinity_policy: "",
                        node_taint_policy: "",
                        topology_key: "kubernetes.io/hostname",
                        when_unsatisfiable: "DoNotSchedule"
                    }
                }
            }
        }, () => {
            this.props.handleResourceUpdate(this.state.systemDetail)
        })
    }

    handleEnvironmentNameOnBlur = () => {
        const { systemDetail } = this.state;

        if(systemDetail.environment.length === 0){
            this.setState({
                isEnvironmentFieldError :true,
                EnvironmentFieldErrorMessage: this.props.t('ResourcePaper.environmentEmptyError'),
            })
        }
    }   

    toggleCardSelection = (item) => {
        const { systemDetail } = this.state;
    
        if (systemDetail.resource.id !== item.id) {
            const updatedSystemDetail = {
                ...systemDetail,
                resource: item,
            };
    
            this.setState({ systemDetail: updatedSystemDetail }, () => {
                this.setMaxReplicaLimit(this.props.availableResources);
                this.props.handleResourceUpdate(updatedSystemDetail);
            });
        }
    };
    
    handleSubmit = () => { 
        this.props.handleButton()
    }

    /* istanbul ignore next */
 /* istanbul ignore next */
handleDecreaseReplica = () => {
    const { systemDetail } = this.state;
    if (systemDetail.replicas > 1) {
        const updatedSystemDetail = {
            ...systemDetail,
            replicas: systemDetail.replicas - 1,
        };

        this.setState({ systemDetail: updatedSystemDetail }, () => {
            this.props.handleResourceUpdate(updatedSystemDetail);
        });
    }
};

    /* istanbul ignore next */
    handleIncreaseReplica = () => {
        if (this.checkIfRamAndCoreAvailable(1)) {
            this.setState((prevState) => {
                const updatedSystemDetail = {
                    ...prevState.systemDetail,
                    replicas: prevState.systemDetail.replicas + 1,
                };
                return { systemDetail: updatedSystemDetail };
            }, () => {
                this.props.handleResourceUpdate(this.state.systemDetail);
            });
        }
    };
    
    handleEnvironmentChange = (e) => {
        const newEnvironmentValue = e.target.value;
        const validation = validateInputField(newEnvironmentValue);
        const { systemDetail } = this.state;
    
        this.setState({
            isSubmitButtonDisabled: validation.error,
            isEnvironmentFieldError: validation.error,
            EnvironmentFieldErrorMessage: validation.error ? this.props.t(validation.message) : "",
            systemDetail: {
                ...systemDetail,
                environment: newEnvironmentValue,
            },
        }, () => {
            this.props.handleResourceUpdate(this.state.systemDetail);
        });
    };

    /* istanbul ignore next */
    handleBranchChange = (e) => {
        const newGitBranchValue = e.target.value;
        const { systemDetail } = this.state;
    
        this.setState({
            systemDetail: {
                ...systemDetail,
                gitBranch: newGitBranchValue,
            },
        }, () => {
            this.props.handleResourceUpdate(this.state.systemDetail);
        });
    };
    
    handleBranchSelect = (e, newvalue) => {
        const validBranch = this.props.gitBranches?.find((branch) => {
            return branch.name === newvalue;
        });
    
        const { systemDetail } = this.state;
        const updatedSystemDetail = { ...systemDetail };
    
        if (validBranch) {
            updatedSystemDetail.gitBranch = validBranch.value;
            this.setState(
                {
                    systemDetail: updatedSystemDetail,
                    branchError: false,
                    branchErrorText: null,
                },
                () => {
                    this.props.handleResourceUpdate(updatedSystemDetail);
                }
            );
        } else {
            const clonedSystemDetail = { ...updatedSystemDetail };
            clonedSystemDetail.gitBranch = "";
            this.setState(
                {
                    systemDetail: clonedSystemDetail,
                    isSubmitButtonDisabled: true,
                    branchError: true,
                    branchErrorText: this.props.t("ResourcePaper.branchEmptyError"),
                },
                () => {
                    this.props.handleResourceUpdate(clonedSystemDetail);
                }
            );
        }
    };

    checkIfRamAndCoreAvailable = (type, currentResource, my_replica) => {
        const { systemDetail } = this.state;
        const { availableResources } = this.props;
        let _availableResources = { cpu: 0, memory: 0 };

        if(this.props.loadSource === 1) { //creation
            _availableResources.cpu = availableResources.cpu;
            _availableResources.memory = availableResources.memory ;
        }
        else { //edit
            if(this.props.defaultReplicas && this.props.defaultResource) {
                _availableResources.cpu = availableResources.cpu + ( this.props.defaultReplicas * this.props.defaultResource.cores);
                _availableResources.memory = availableResources.memory + ( this.props.defaultReplicas * this.props.defaultResource.memory);
            }
            else
            {
                _availableResources.cpu = availableResources.cpu;
                _availableResources.memory = availableResources.memory;
            }
        }
        //console.log( "cpu - "+ _availableResources.cpu + "; memory - " + _availableResources.memory);
        const _replica = my_replica ? my_replica : systemDetail.replicas 
        if(type === 1) //1 : Increasing replica
        {
            const newRelpicaCount = parseInt(_replica);
            return systemDetail.resource.cores * newRelpicaCount <= _availableResources.cpu && systemDetail.resource.memory * newRelpicaCount <= _availableResources.memory
        }
        else if(type === 2) //2 : From list render
        {
            return currentResource.cores * _replica <= _availableResources.cpu && currentResource.memory *_replica<= _availableResources.memory
        }
    }
    
    isResourceEnough = (res) => {
        const {appDetails}  =this.props
        if(appDetails && res){
            const min_cpu = appDetails.plugin?.min_cpu
            const min_memory = appDetails.plugin?.min_memory
            return res.memory >= min_memory && res.cores >= min_cpu
        }
        return true
    }

    getDefaultResource = () => {
        if(this.props.resources && this.props.resources.length > 0 ){
            const min_cpu = this.props.appDetails?.plugin?.min_cpu
            const min_memory = this.props.appDetails?.plugin?.min_memory
            
            const defaultRes = this.props.resources.find(res => { 
                return res.memory >= min_memory && res.cores >= min_cpu
            })
            if(defaultRes)
                return defaultRes
            else
                return { id : 0, cores: 0, memory: 0 }
        }
        else
            return { id : 0, cores: 0, memory: 0 }
    }

    setMaxReplicaLimit = (availableResources) => {
        //const { systemDetail } = this.state;
        // const { availableResources } = this.props;
        const currentResource = this.state.systemDetail?.resource;
        //let _availableResources = {...availableResources}
        if(this.props.edit){
            // _availableResources.memory
        }
        const _max_replica =  parseInt(Math.min(availableResources?.cpu / currentResource?.cores, availableResources?.memory / currentResource?.memory  ))
        this.setState({
            max_replica_limit: _max_replica
        })
    }
    /* istanbul ignore next */
    handlePVSupport = (e) => {
        this.setState({
            systemDetail: {
                ...this.state.systemDetail,
                support_pv: e.target.checked
            }
        }, ()=>{
            this.props.handleResourceUpdate(this.state.systemDetail)
        })
    }

    handleLBSupport = (e) => {
        if(!e.target.checked){
            this.setState({
                lbError: false,
                systemDetail: {
                    ...this.state.systemDetail,
                    load_balancer_id: null,
                    enable_loadbalancer: false
                }
            })
        }else{
            if(!this.state.systemDetail.load_balancer_id || this.state.systemDetail.load_balancer_id === "Select Loadbalancer"){
                this.setState({
                    lbError: true
                })
            }
        }
        this.setState({
            systemDetail: {
                ...this.state.systemDetail,
                enable_loadbalancer: e.target.checked
            }
        }, ()=>{
            this.props.handleResourceUpdate(this.state.systemDetail)
        })
    }

    /* istanbul ignore next */
    handleHpaSupport = (e) => {
        
        const autoScalerEnabled = e.target.checked;
        let updatedSystemDetail = {
            ...this.state.systemDetail,
            auto_scaler:{
                enabled: autoScalerEnabled,
                horizontal_pod_autoscaler: autoScalerEnabled ? { ...this.state.systemDetail.auto_scaler.horizontal_pod_autoscaler } : {
                    max_replicas: 0,
                    metrics: null,
                    min_replicas: 0,
                    scale_down_rule: null,
                    scale_up_rule: null
                },
                vertical_pod_autoscaler: autoScalerEnabled ? { ...this.state.systemDetail.auto_scaler.vertical_pod_autoscaler } : {
                    controlled_resources: null,
                    controlled_values: "RequestsAndLimits",
                    max_allowed_cpu: "1000",
                    max_allowed_memory: "500",
                    min_allowed_cpu: "100",
                    min_allowed_memory: "50",
                    mode: "",
                    update_mode: "Auto"
                },
            }
        };
    
        this.setState({
            systemDetail: updatedSystemDetail,
            scheduling: autoScalerEnabled ? this.state.scheduling : false,
        }, () => {
            this.setMaxReplicaLimit(this.props.availableResources);
            this.props.handleResourceUpdate(updatedSystemDetail);
    
            // if (!autoScalerEnabled) {
            //     this.updatedAdvancedScheduling({
            //         label_selector: {
            //             key: "app.kubernetes.io/provider",
            //             value: "zerone"
            //         },
            //         match_label_keys: null,
            //         max_skew: 1,
            //         min_domains: 0,
            //         node_affinity_policy: "",
            //         node_taint_policy: "",
            //         topology_key: "kubernetes.io/hostname",
            //         when_unsatisfiable: "DoNotSchedule"
            //     });
            // }
        });
    };
    
    setStorage = (storage)=> {
        this.setState({
            systemDetail:{
                ...this.state.systemDetail,
                storage
            }
        }, ()=>{
            this.props.handleResourceUpdate(this.state.systemDetail)
        })
    }

    setHPA = (horizontal_pod_autoscaler, error) => {
        this.setState({
            systemDetail: {
                ...this.state.systemDetail,
                auto_scaler:{
                    ...this.state.systemDetail.auto_scaler,
                    horizontal_pod_autoscaler:{
                        // enabled: this.state.systemDetail.enabled,
                        ...horizontal_pod_autoscaler,      
                    }
                }
            }, 
            error
        }, () => {
            this.props.handleResourceUpdate(this.state.systemDetail)
        })
    }

    setLoadbalancer = (load_balancer_id, error) => {
        this.setState({
            systemDetail: {
                ...this.state.systemDetail,
                load_balancer_id,
            },
            lbError : error
        }, () => {
            // if(this.state.systemDetail.enable_loadbalancer){
                this.props.handleResourceUpdate(this.state.systemDetail)
            // }
        })
    }

    handleScheduling = (e) => {
        this.setState({
            scheduling:e.target.checked
        })
        if(!e.target.checked){
            this.updatedAdvancedScheduling({
                label_selector: {
                    key: "app.kubernetes.io/provider",
                    value: "zerone"
                },
                match_label_keys: null,
                max_skew: 1,
                min_domains: 0,
                node_affinity_policy: "",
                node_taint_policy: "",
                topology_key: "kubernetes.io/hostname",
                when_unsatisfiable: "DoNotSchedule"
            })
        }else{
            this.updatedAdvancedScheduling(this.props.auto_scaler?.advanced_scheduling )
        }
        if(e.target.checked === true){
            this.props.handleResourceUpdate(this.state.systemDetail)
        }
    }

    // handleUpdateVpa = (updatedVpa) => {
    //     this.setState({vertical_pod_autoscaler:{...updatedVpa}})
    // }

    handleUpdateVpa = (updateVpa) => {
        this.setState({
            systemDetail:{
                ...this.state.systemDetail,
                auto_scaler:{
                    ...this.state.systemDetail.auto_scaler,
                    vertical_pod_autoscaler:{
                        ...updateVpa
                    }
                }
            }
        }, () => {
            this.props.handleResourceUpdate(this.state.systemDetail)
        })
    }

    isBtnDisabled = () => {
        if (this.props.updated === undefined) return false
        if (!this.props.actionType) return false
        return !(this.props.updated && this.props.actionType === "updateResources"
        )
    }

    isVpaValuesValid = () => {
        const { systemDetail } = this.state
        if (systemDetail?.auto_scaler?.vertical_pod_autoscaler) {
            const { min_allowed_cpu, min_allowed_memory, max_allowed_cpu, max_allowed_memory } = systemDetail.auto_scaler.vertical_pod_autoscaler
          
            if (parseInt(min_allowed_cpu) !== 0 && parseInt(min_allowed_memory) !== 0 &&
                parseInt(min_allowed_cpu) <= parseInt(max_allowed_cpu) && parseInt(min_allowed_memory) <= parseInt(max_allowed_memory)) {
                return true
            }
            return false
        }
        return false
    }
    render () {
        const { classes, t } = this.props;
        const { systemDetail } = this.state;

        if(JSON.stringify(systemDetail)===JSON.stringify({})){
            return <>loading</>
        }
        return (
            <div data-test="main-container">
                <Paper className= { classes.infoPaper} elevation={7}>
                    <Alert severity="info" data-test="alert-container" className={classes.infoBackground}>
                        <Typography variant='body2' className={classes.infoText}>{t('ResourcePaper.minimumResourceRequiredCPU')} {coreConversion(this.props.appDetails?.plugin?.min_cpu)} {t('ResourcePaper.minimumResourceRequiredMemory')} {spaceCoversion(this.props.appDetails?.plugin?.min_memory)}</Typography>
                    </Alert>
                </Paper>
                
                <Paper elevation={ 1 } className={ classes.containerPaper }>
                    <Grid container
                      direction="row"
                      className={ classes.envHeader }
                      spacing={1}
                    >
                        <Grid item md={ 4 } xs={ 12 }> 
                            { this.props.loadSource === 1 && 
                            <MuiTextField 
                              variant="outlined"
                              color='primary'
                              error={this.state.isEnvironmentFieldError}
                              helperText={this.state.EnvironmentFieldErrorMessage}
                              required
                              autoFocus
                              label={t('ResourcePaper.environmentName')}                
                              onBlur={this.handleEnvironmentNameOnBlur}
                              onChange={ (e) => this.handleEnvironmentChange(e) } 
                              value={ systemDetail.environment }
                              data-test="environment-field"
                            /> 
                            }
                        </Grid>
     
                        <Grid item md={ 4 } xs={ 12 }>
                            {
                                this.props.loadSource === 1 && (this.props.service_type > 0 && this.props.service_type !== CreateApp_TemplateTypes.manageDB) && 
                                <FormControl className={ classes.formControl } variant="outlined">
                                    
                                    <Autocomplete
                                        value={this.state.systemDetail.gitBranch} 
                                        options={this.props.gitBranches.map(branch => branch.name)}
                                        getOptionLabel={(option) => option}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        // onChange={(e) => this.handleRepoSelect(e)}
                                        onChange={(e, newValue) => this.handleBranchSelect(e, newValue)}
                                        data-test="branch-field"
                                        renderInput={(params) =>
                                            <TextField
                                                label={this.props.appDetails && this.props.appDetails.service_type === CreateApp_TemplateTypes.git ? "Select Branch" : "Select Tag"}
                                                error={this.state.branchError}
                                                helperText={this.state.branchErrorText}
                                                {...params}
                                                variant="outlined"
                                                color="primary"
                                            />
                                        }
                                    />
                                    {/* <FormHelperText error={this.state.isDropDownFeildError}>{ this.state.dropDownErrorMessage }</FormHelperText> */}
                                </FormControl>
                            }
                            {/* <div>Available cores - {availableResources && availableResources.cpu}</div>
                            <div>Available memory - {availableResources && availableResources.memory}</div>
                            <div>New Available cores - {this.state.availableCore}</div>
                            <div>New Available memory - {this.state.availableRam}</div> */}
                        </Grid>
                        {/* <Grid item md={ 4 } xs={ 12 }>
                            <IconButton onClick={ e => this.handleDecreaseReplica(e , systemDetail.id) }>
                                <RemoveCircleIcon color="primary"/>
                            </IconButton>
                            {systemDetail.replicas} {systemDetail.replicas > 1 ? 'Replicas' : 'Replica' }
                            <IconButton onClick={ e => this.handleIncreaseReplica(e , systemDetail.id) }>
                                <AddCircleIcon color="primary"/>
                            </IconButton>
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={ 2 } className={ classes.coreGrid }>
                        {   
                            this.props.resources === null && [0,1,2].map(value => {
                                return (
                                    <Grid data-test="resource-skeleton" item md={ 2 } sm= { 3 } xs={ 6 } key={ value }>
                                        <Card variant='outlined' style={{padding : '1rem'}}>
                                            <SkeletonTheme height={250}>
                                                {/* <Skeleton count={5} /> */}
                                                <div className={classes.skeletonItem}>
                                                    <Skeleton width={40} height={40}/>
                                                    <Skeleton width={60} height={10}/>
                                                    <Skeleton width={30} height={20}/>
                                                    <Skeleton width={60} height={10}/>
                                                </div>
                                            </SkeletonTheme>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                        {   
                            this.props.resources && this.props.resources.length === 0 && 
                            <Grid item data-test="no-resource">
                                <Card variant='outlined' style={{padding : '1rem'}}>
                                {t('ResourcePaper.noResource')}
                                </Card>
                            </Grid>
                        }
                        {
                            this.props.resources && this.props.resources.length > 0 && this.props.resources.map((item) => {
                                const isActive = this.checkIfRamAndCoreAvailable(2, item);
                                const isEnough = this.isResourceEnough(item)
                                return ( 
                                    <Grid item md={ 2 } sm= { 3 } xs={ 12 } key={item.id} data-test="resource-cards">
                                        <ButtonBase
                                          data-test= "resource-button" 
                                          onClick={ () => this.toggleCardSelection(item) } 
                                          className={`${classes.fullBtn} ${ (!isActive || !isEnough) ? classes.resourceInactive : "" }`} 
                                          disabled = { !isActive || !isEnough }
                                        >
                                            <Paper elevation={ 7 } className={`${systemDetail.resource && systemDetail.resource.id === item.id ? 'card-Selected' : classes.corePaper} newClass` }>
                                                <Grid container direction="column" spacing={ 1 } alignItems="center" className="resourcesdiv">
                                                    <Grid item>
                                                        {/* <img src={ systemDetail.resource && systemDetail.resource.id === item.id ? "/images/icons/cpu-white.svg" : "/images/icons/cpu.svg" } alt="Core"/> */}
                                                        {/* <span className="oneLine" title={coreConversion(item.cores)}>{coreConversion(item.cores)}</span>  */}
                                                        <MemoryIcon />
                                                        <Typography className={`oneLine ${classes.textColor}`} variant='body2' title={coreConversion(item.cores)}>{coreConversion(item.cores)}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <img src={ systemDetail.resource && systemDetail.resource.id === item.id ? "/images/icons/ram-white.svg" : "/images/icons/ram.svg" } alt="RAM" />
                                                        {/* <span className="oneLine" title={spaceCoversion(item.memory)} >{spaceCoversion(item.memory)}</span> */}
                                                        {/* <HarddriveIcon /> */} {/* need to change */}
                                                        <Typography className={`oneLine ${classes.textColor}`} variant='body2' title={spaceCoversion(item.memory)}>{spaceCoversion(item.memory)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </ButtonBase>
                                    </Grid>
                                )}
                            )
                        }
                    </Grid>
                    {/* <Grid className={ `storageswitch ${ this.props.loadSource === 2 && !isAuthorized("update", this.props.envRole.name, "environment", this.props.environmentState?.State) ? "noCursor" : ""}` }>
                        <FormControlLabel
                          control={
                              <Switch
                                checked={this.state.systemDetail && this.state.systemDetail.support_pv}
                                onChange={this.handlePVSupport}
                                name="support_pv"
                                color="primary"
                              />
                            }
                          label={"Storage"}
                          className={ this.props.loadSource === 2 && !isAuthorized("update", this.props.envRole.name) ? "avoid-clicks" : "" }
                        />
                    </Grid> */}
                    {  
                        this.props.service_type !== CreateApp_TemplateTypes.manageDB &&
                        <Grid className={ `storageswitch ${ this.props.loadSource === 2 && !isAuthorized("update", this.props.envRole.name) ? "noCursor" : ""}` } data-test="storage-switch">
                            <FormControlLabel
                              control={
                                <Switch
                                    checked={this.state.systemDetail && this.state.systemDetail.auto_scaler?.enabled}
                                    onChange={(e) => this.handleHpaSupport(e)}
                                    name="enabled"
                                    color="primary"
                                />
                                }
                              data-test="storage-switch-button"
                              label={t('ResourcePaper.autoScaling')}
                              className={ this.props.loadSource === 2 && !isAuthorized("update", this.props.envRole.name) ? "avoid-clicks" : "" }
                            />
                        </Grid>
                    }   
                  
                    {this.props.appDetails?.project?.dedicated_lb && (this.props.loadSource === 1 || (this.props.loadSource === 2 && this.state.systemDetail.enable_loadbalancer)) &&
                        <Grid className={ `storageswitch ${ this.props.loadSource === 2 && !isAuthorized("update", this.props.envRole.name) ? "noCursor" : ""}` } data-test="lb-switch">
                            <FormControlLabel
                              control={
                                  <Switch
                                    checked={this.state.systemDetail.enable_loadbalancer}
                                    onChange={(e) => this.handleLBSupport(e)}
                                    name="enable_loadbalancer"
                                    color="primary"
                                    disabled={this.props.loadSource === 2 }
                                  />
                                }
                                data-test="lb-switch-button"
                              label={t('ResourcePaper.loadbalancer')}
                              className={ this.props.loadSource === 2 && !isAuthorized("update", this.props.envRole.name) ? "avoid-clicks" : "" }
                            />
                        </Grid>
                    }   
                  
                </Paper>
                { this.state.systemDetail.auto_scaler?.enabled && 
                    <>
                    {
                    (this.props.service_type === CreateApp_TemplateTypes.default && this.props.rwxEnable === true) || 
                    this.props.service_type > 0 || (this.props.service_type === CreateApp_TemplateTypes.default && this.props.hasReponseRwx)
                    ? 
                    <>
                    <HPA 
                    //gitRepository={this.props.gitRepository ? this.props.gitRepository : null}
                    horizontal_pod_autoscaler={this.props.auto_scaler?.horizontal_pod_autoscaler  ? this.props.auto_scaler?.horizontal_pod_autoscaler: null} 
                    setHPA={this.setHPA} 
                    loadSource={this.props.loadSource}
                    isAuthorized={this.props.loadSource === 2 ? isAuthorized("update", this.props.envRole.name, "environment", this.props.environmentState?.state) : true }
                    checkIfRamAndCoreAvailable = {this.checkIfRamAndCoreAvailable}
                    max_replica_limit = {this.state.max_replica_limit}
                    />
                    <div className="m-20">
                    <Card className="mt-55" data-test="system-var-card">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" m={1}>
                      <Box>
                        <CardHeader title={<Typography variant='h6'>Advanced Scheduling</Typography>} />
                      </Box>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch name="scheduling" color="primary"
                                checked={this.state.scheduling}
                                onChange={(e) => this.handleScheduling(e)}
                              />
                            }
                          />
                        </Box>
                    </Box>
                    {
                        this.state.scheduling && 
                        <>
                        <Divider />
                        <CardContent>
                          <Scheduling
                            updatedAdvancedScheduling={this.updatedAdvancedScheduling}
                            schedulingData={this.state.systemDetail.auto_scaler?.advanced_scheduling} 
                          />
                        </CardContent>
                        </>
                    }
                    </Card>
                    </div>
                    </>
                    :
                    <div className="m-20" data-test="main-container">
                        <VPA 
                        vertical_pod_autoscaler={this.props.auto_scaler?.vertical_pod_autoscaler ? this.props.auto_scaler?.vertical_pod_autoscaler : null}
                        handleUpdateVpa={this.handleUpdateVpa}
                        />
                    </div>
                    }
                    </>
                }
                { this.state.systemDetail.enable_loadbalancer && this.props.appDetails?.project?.dedicated_lb && (
                    <Loadbalancer
                      setLoadbalancer={this.setLoadbalancer}
                      isAuthorized={this.props.loadSource === 2 ? isAuthorized("update", this.props.envRole.name, "environment", this.props.environmentState?.state) : true }
                      projectId={this.props.appDetails?.project_id}
                      load_balancer_id = {this.props.load_balancer_id}
                      loadSource={this.props.loadSource}
                    />
                   
                )
                    
                }

                { (this.props.loadSource === 1 || (this.props.loadSource === 2 && isAuthorized("update", this.props.envRole.name))) && (
                    <div className={classes.actions}>
                        { this.props.updatingEnv ? <CircularProgress /> : 
                            (
                                <Grid container spacing={2}  direction="row"
                                    justify="center"
                                    alignItems="center"
                                >
                                    { this.props.loadSource === 1 && 
                                    <Grid item>
                                        <Button data-test="back-button" variant="contained" color='primary' onClick={() => this.props.goBack(this.props.activeStep - 1)}>
                                            <ArrowBackIosIcon fontSize="small"/>
                                            Back
                                        </Button>
                                    </Grid>
                                    }

                                    <Grid item>
                                        <Button
                                            data-test="submit-button"
                                            variant="contained"
                                            color='primary'
                                            onClick={() => this.handleSubmit()}
                                            disabled={!this.isVpaValuesValid() || this.isBtnDisabled() || this.state.error || this.state.lbError || this.state.systemDetail.resource?.id === 0 || !(!this.state.isEnvironmentFieldError && ((this.props.service_type > 0 && this.props.service_type !== CreateApp_TemplateTypes.manageDB) && this.props.service_type !== CreateApp_TemplateTypes.op ? (systemDetail.gitBranch !== 'Select Branch' && systemDetail.gitBranch !== "") : true))}
                                        >
                                            {this.props.buttonName ? this.props.buttonName : t('ResourcePaper.continue')} 
                                            <RocketLaunchIcon style={{marginLeft:'8px'}}/>
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                                )
                        }
                    </div>
                )} 
            </div>
        )
    }
}

export default withStyles(useStyles)(withTranslation()(ResourcePaper))