import { Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, TextField, Typography, withStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { withTranslation,WithTranslation } from 'react-i18next';
import { connect,ConnectedProps } from 'react-redux';
import * as Yup from 'yup';
import MuiTextField from '../../components/textfield/MuiTextField';
import { resetPassword } from '../registration/redux/actions';
import './resetpassword.css';
 import { Theme ,createStyles,WithStyles} from "@material-ui/core";
 import { Link, RouteComponentProps } from 'react-router-dom';
 import { StaticContext } from 'react-router';
  import { Dispatch } from "redux";
import LandingScreen from '../../components/companybanner/LandingScreen';
import palette from '../../theme/palette';
import SettingsIcon from '@material-ui/icons/Settings';
import { ResetPasswordIcon } from '../../helpers/customIcon';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { AuthformHeader } from '../../components/authformheader/AuthformHeader';
/* istanbul ignore next */
const useStyles =(theme:Theme) => createStyles({
  root: {
    height: '100vh',
    // backgroundColor: theme.palette.background.default
  },
  image: {
    backgroundImage: 'url(/images/infographics/login.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.background.default : theme.palette.grey[ 900 ],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    // margin: theme.spacing(10, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft : '15%',
    paddingRight : '15%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'theme.palette.secondary.main',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2 , 0, 3),
    alignSelf : 'center',
    width:'100%'
  }
});

type PayloadType = {
  token: string;
  new_password: string;
  retype_password: string;
};
type HandleSubmitArgs = {
  newPass: string;
  confirmPass: string;
  setSubmitting: (value: boolean) => void;
  resetForm: () => void;
};
interface State {
  showPassword: boolean;
}

interface Props
  extends PropsFromRedux,
    WithTranslation,
    RouteComponentProps<{ token: string }, StaticContext>,
    WithStyles<typeof useStyles> {}

export class ResetPassword extends Component<Props,State> {
  constructor(props:Props){
    super(props);
    this.state = {
      showPassword:false,
    }
  }
  handleSubmit = ({ newPass, confirmPass, setSubmitting, resetForm }:HandleSubmitArgs) => {
    const payload:PayloadType= {
      token : this.props.match.params.token,
      new_password : newPass,
      retype_password : confirmPass
    }
    this.props.resetPassword(payload, this.props.history);
    setTimeout(async () => {
      setSubmitting(false)
      resetForm()
    }, 1000)
  }

  /* istanbul ignore next */
  handleClickShowPassword = () => {
    this.setState({
      showPassword:!this.state.showPassword
    })
  };
  
  // handleKeydown = (e) => {
  //   var _key = e.which || e.keyCode;
  //   if(_key === 13) {
  //       this.handleSubmit();
  //   }
  // }

  render () {
    const { t, classes } = this.props;
    return (
        <Grid container component="main" className={ classes.root } data-test="main-container">
            {/* <CssBaseline />
            <CompanyBanner /> */}
            <LandingScreen mode={2}/>
            <Grid item xs={ 12 } sm={ 12 } md={12} lg={6} style={{padding: '16px 24px'}}>
                {/* <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <img
                      id="imgLogo"
                      src="/images/logos/logo-blue.svg"
                      alt="01Cloud"
                      className="authlogo"
                      height={40}
                      width={40}
                    />
                  </Grid>
                  <Grid item container xs={6} justifyContent="flex-end">
                    <Link to='/' className="textDecorationNone" style={{display: 'flex', alignItems: 'center', gap: 8}}>
                      <SettingsIcon style={{color: palette.text.secondary}}/>
                      <Typography variant="subtitle2">Need help?</Typography>
                    </Link>
                  </Grid>
                </Grid> */}
                <AuthformHeader />
                <Grid container justifyContent="center" alignContent="center" style={{height: "100%"}}>
                  <Grid item xs={12} md={9}>
                    <div className={ classes.paper }>
                        {/* <img src="/images/logos/logo-blue.svg" alt="01Cloud" className='authlogo' data-test="image-container"/> */}
                        <div style={{marginBottom: 24}}>
                          <ResetPasswordIcon viewBox='0 0 96 96' style={{width: 96, height: 96}}/>
                        </div>
                        <Typography  align='center' variant="h5" data-test="typography">Reset Password</Typography>
                        <Typography align='center' variant="body2" color='textSecondary' style={{marginTop: 8, marginBottom: 40}}>Please reset your password</Typography>
                        <div className={ classes.form }>
                            <Formik
                              data-test="formik-component"
                              initialValues={ {
                                  newPass: '',
                                  confirmPass: '',
                                } }
                              validationSchema={ 
                                  Yup.object().shape({
                                    newPass: Yup
                                              .string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong'))
                                              .required(t('Registration.InviteLogin.enterPassword'))
                                              .matches(
                                                ///^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                //'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
                                                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                                                t('Account.PasswordTab.minCharsError')
                                              ),
                                    confirmPass: Yup.string()
                                                .oneOf([ Yup.ref('newPass') ], t('Account.PasswordTab.passwordNotMatching'))
                                                .required(t('Registration.InviteLogin.confirmPassword'))
                                }) }
                              onSubmit={ (
                                  { newPass, confirmPass },
                                  { setSubmitting, resetForm }
                                ) =>
                                  this.handleSubmit({
                                    newPass,
                                    confirmPass,
                                    setSubmitting,
                                    resetForm,
                                  })
                                }
                              render={ props => {
                                  const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    //isValid,
                                    //isSubmitting,
                                  } = props
                      //console.log(isValid , isSubmitting);
                      return  (
                          <form onSubmit={ handleSubmit } className={ classes.form }>
                              {/* <FormControl fullWidth variant="outlined" margin="normal" >
                                  <InputLabel htmlFor="password-new" error={ Boolean(touched.newPass && errors.newPass) }>{t('Registration.ResetPassword.newPassword')}</InputLabel>
                                  <OutlinedInput
                                    id="password-new"
                                    data-test="newpassword-input"
                                    error={ Boolean(touched.newPass && errors.newPass) }
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={values.newPass}
                                    color='primary'
                                    name="newPass" 
                                    onChange={ handleChange } 
                                    onBlur={ handleBlur }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                              data-test="password-toggle"
                                              aria-label="toggle password visibility"
                                              onClick={this.handleClickShowPassword}
                                              edge="end"
                                            >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                  }
                                    labelWidth={100}
                                  /> */}
                                  {/* <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id="password-new"
                                    error={ Boolean(touched.currentPass && errors.currentPass) }
                                    name="newPass"
                                    type="password"
                                    value={ values.newPass }
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                    error={ Boolean(touched.newPass && errors.newPass) }
                                    required
                                    fullWidth
                                    color='primary'
                                    label='New Password'
                                  /> */}
                                  {/* <FormHelperText
                                    error={ Boolean(touched.newPass && errors.newPass) }
                                  >
                                      {touched.newPass && errors.newPass ? errors.newPass : ''}
                                  </FormHelperText>
                              </FormControl> */}
                              
                              <MuiTextField
                                  margin="normal"
                                  label={t('Registration.ResetPassword.newPassword')}
                                  id="password-new"
                                  data-test="newpassword-input"
                                  error={ Boolean(touched.newPass && errors.newPass) }
                                  type={this.state.showPassword ? 'text' : 'password'}
                                  value={values.newPass}
                                  name="newPass" 
                                  onChange={ handleChange } 
                                  onBlur={ handleBlur }
                                  labelWidth={100}
                                  InputProps={{
                                    endAdornment:(
                                      <InputAdornment position="end">
                                          <IconButton
                                            data-test="password-toggle"
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            edge="end"
                                          >
                                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                  helperText={touched.newPass && errors.newPass ? errors.newPass : ''}
                              />

                              <FormControl
                                fullWidth
                                error={ Boolean(touched.confirmPass && errors.confirmPass) }
                              >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    color='primary'
                                    id="password-confirm"
                                    data-test="passwordconfirm-input"
                                    label={t('Registration.ResetPassword.confirmPassword')}
                                    name="confirmPass"
                                    type="password"
                                    value={ values.confirmPass }
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                    error={ Boolean(touched.confirmPass && errors.confirmPass) }
                                  />             
                                  <FormHelperText
                                    error={ Boolean(touched.confirmPass && errors.confirmPass) }
                                  >
                                      {touched.confirmPass && errors.confirmPass
                                      ? errors.confirmPass
                                      : ''}
                                  </FormHelperText>
                              </FormControl>
                              <div className='resetButtonDiv'>
                                  <Button
                                    variant="contained"
                                    data-test="reset-button"
                                    type='submit'
                                    color='primary'
                                    // align='center'
                                    className={ classes.button }
                                  >
                                      {t('Reset Password')}
                                  </Button>
                              </div>
                          </form>
                      )
                    } }
                            />
                            {/* <Typography className='links'>
                                <div className='containerDiv'>
                                    <Link href="#" className='TermsLink'>
                                        {t('TermsOfUse')}
                                    </Link>
                                    <Link href="#" className='PolicyLink'>
                                        {t('Policy')}
                                    </Link>
                                </div>
                            </Typography> */}
                        </div>
                        <Link to='/' className="textDecorationNone">
                          <Typography variant='subtitle2' style={{display: 'flex', alignItems:'center', marginTop: 24}}>
                            <ArrowBackIosIcon fontSize='small' style={{width: 16, height: 16}}/>
                            Return to sign in
                          </Typography>
                        </Link>
                    </div>
                  </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
  }
}

/* istanbul ignore next */


/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    resetPassword : (payload:PayloadType, history:RouteComponentProps["history"]) => dispatch(resetPassword(payload, history)),
  }
}

const connector= connect(
  null,
  mapDispatchtoProps
)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withStyles(useStyles)(withTranslation()(ResetPassword))) 