import {
    ButtonBase,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Typography,
    AccordionSummary,
    AccordionDetails,
    Button,
    Badge,
    Theme,
    Accordion,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React, { ChangeEvent, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect, ConnectedProps } from "react-redux";
import CustomButton from "../../components/custombutton/CustomButton";
import BackdropLoader from "../../components/loader/BackdropLoader";
import paths from "../../constants/paths";
import { getDateInStandardFormat, getDiffDays } from "../../helpers/utils";
import { updateBreadcrumb } from "../project/redux/actions";
import { deleteTicket, getSupportTypes, getUserTickets } from "./redux/actions";
import { StyledTableCell, StyledTableRow } from "../../components/styledtabelcell/StyledTabelCell";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import { useTranslation } from 'react-i18next';
import NoContentImage from "../../components/nocontentimagecontainer/NoContentImage";
import SearchField from "../../components/searchfield/SearchField";
import { Dispatch } from "redux";
import { History } from 'history';
import { TicketDetailsModel } from "../../models/Support.model";

interface Support {
  id: number;
  title: string
}

/* istanbul ignore next */
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  dropdown: {
    minWidth: 80,
    height: 50,
  },
  accordionSummaryRoot: {
    maxWidth: "5%",
    padding: 0,
    height: "40px",
    marginLeft: "auto",
    "&.Mui-expanded": {
      minHeight: "40px",
    },
  },
  accordionSummaryContent: {
    margin: 0,
    padding: 0,
    position: "absolute",
    right: "0",
  },
  accordionRoot: {
    background: "transparent",
    boxShadow: "none",
  },
  accordionRootDisabled: {
    backgroundColor: "transparent !important",
  },
  accordionDetailsRoot: {
    padding: 0,
  },
  filterGrid: {
    marginTop: "5px",
  },
});

const getPriority = (p : string) => {
    let arrow;
    switch (p) {
      case "High":
        arrow = <ArrowUpwardIcon style={{ color: "red" }} />;
        break;
      case "Medium":
        arrow = <ArrowUpwardIcon color="primary" />;
        break;
      case "Low":
        arrow = <ArrowDownwardIcon style={{ color: "green" }} />;
        break;
      default:
        arrow = <ArrowUpwardIcon color="primary" />;
        break;
    }
    return (
      <>
        {" "}
        <span style={{ marginTop: 5 }}>{arrow} </span> {p}
      </>
    );
};



interface Props extends PropsFromRedux {
    history: History,
}

export const Support = (props : Props) => {
  const [t] = useTranslation()
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>("");
  const [currentStatus, setCurrentStatus] = useState<string>("all");
  const [currentPriority, setCurrentPriority] = useState<string>("all");
  const [currentSupportType, setCurrentSupportType] = useState<string | number>("all");
  const [reset, setReset] = useState<boolean>(false);
  const [clearSearch, setClearSearch] = useState<boolean>(false);

  const matches = useMediaQuery((_theme: Theme) => _theme?.breakpoints?.down("xs") ?? "600");

  const limit = 20;

  const fetchSupportTickets = (pageNumber: number) => {
    props.getUserTickets(
      pageNumber ?? 1,
      limit,
      currentStatus === "all" ? "" : currentStatus,
      searchText,
      currentPriority === "all" ? "" : currentPriority,
      currentSupportType === "all" ? "" : String(currentSupportType)
    );
  };

  useEffect(() => {
    const breadcrumbData = [
      {
        name: "Tickets",
        path: paths.SUPPORT,
      },
    ];
    props.updateBreadcrumb(breadcrumbData);
    props.getSupportTypes();
  }, []);

  useEffect(() => {
    fetchSupportTickets(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if(reset){
      setReset(false)
    }
  }, [props.userTickets]);

  const isResetValid = () => {
    if (
      currentStatus === "all" &&
      currentPriority === "all" &&
      currentSupportType === "all" &&
      searchText.trim().length === 0
    ) {
      return false;
    }
    return true;
  };

  const getStatus = (status : string) => {
    switch (status) {
      case "Open":
        return "activestatus";
      case "Closed":
        return "inactivestatus";
      default:
        return "";
    }
  };

  const loadMore = () => {
    let newPageNo = currentPage + 1;
    setCurrentPage(newPageNo);
  };

  const handleSearch = (st: string) => {

    console.log(st);
    
    props.getUserTickets(
    1,
    limit,
    currentStatus === "all" ? "" : currentStatus,
    st,
    currentPriority === "all" ? "" : currentPriority,
    currentSupportType === "all" ? "" : String(currentSupportType)
  );
  }

  const handleSearchChange = (st: string) => {
  setSearchText(st);
  if(st.trim()?.length === 0){
    setClearSearch(true)
  }else{
    setClearSearch(false)
  }
  };

  const createTicket = () => {
    props.history.push({
      pathname: paths.TICKET_CREATE,
    });
  };

  const handleTicketClick = (ticket: TicketDetailsModel) => {
    props.history.push({
      pathname: paths.TICKET_DETAIL.replace(":id", ticket.id.toString()),
      state: { id: ticket.id, ticket: ticket },
    });
  };

  const handleStatusChange = (e: ChangeEvent<{value: unknown}>) => {
    const value = e.target.value as string;
    setCurrentStatus(value);
    props.getUserTickets(
      1,
      limit,
      value === "all" ? "" : value,
      searchText,
      currentPriority === "all" ? "" : currentPriority,
      currentSupportType === "all" ? "" : String(currentSupportType)
    );
  };

  const handlePriorityChange = (e: ChangeEvent<{value : unknown}>) => {
    const value = e.target.value as string;
    setCurrentPriority(value);
    props.getUserTickets(
      1,
      limit,
      currentStatus === "all" ? "" : currentStatus,
      searchText,
      value === "all" ? "" : value,
      currentSupportType === "all" ? "" : String(currentSupportType)
    );
  };

  useEffect(() => {
    if(reset){
      setSearchText("")
    }
  }, [reset])

  const handleSupportTypeChange = (e: ChangeEvent<{value: unknown}>) => {
    const value = e.target.value as string;
    setCurrentSupportType(value);
    props.getUserTickets(
      1,
      limit,
      currentStatus === "all" ? "" : currentStatus,
      searchText,
      currentPriority === "all" ? "" : currentPriority,
      value === "all" ? "0" : value
    );
  };

  const resetFilters = () => {
    setCurrentSupportType("all");
    setCurrentPriority("all");
    setCurrentStatus("all");
    setCurrentPage(1);
    setReset(true)
    props.getUserTickets(1, limit, "", "", "", ""); 
  };

  const filters = (
    <Grid
      container
      spacing={3}
      // justify="flex-end"
      className={classes.filterGrid}
    >
      <Grid item xs={12} sm={4} md={isResetValid() ? 2 : 3}>
        <SearchField
          label={t('Support.SupportPage.searchTicket')}
          search={searchText}
          handleSearch = {handleSearch}
          handleSearchChange = {handleSearchChange}
          data-test="search-box"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <FormControl
          variant="outlined"
          className={`w-100`}
        >
          <InputLabel>{t('Support.SupportPage.status')}</InputLabel>

          <Select
            value={currentStatus}
            onChange={(e) => handleStatusChange(e)}
            label={t('Support.SupportPage.status')}
            className={classes.dropdown}
            data-test="status-field"
          >
            <MenuItem value="all">
              <em>{t('Support.SupportPage.all')}</em>
            </MenuItem>
            <MenuItem value={"Open"}>{t('Support.SupportPage.open')}</MenuItem>
            <MenuItem value={"Closed"}>{t('Support.SupportPage.closed')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <FormControl
          variant="outlined"
          className={`w-100`}
        >
          <InputLabel>{t('Support.SupportPage.priority')}</InputLabel>

          <Select
            value={currentPriority}
            onChange={(e) => handlePriorityChange(e)}
            label={t('Support.SupportPage.priority')}
            className={classes.dropdown}
            data-test="priority-field"
          >
            <MenuItem value="all">
              <em>{t('Support.SupportPage.all')}</em>
            </MenuItem>
            <MenuItem value={"Low"}>{t('Support.SupportPage.low')}</MenuItem>
            <MenuItem value={"Medium"}>{t('Support.SupportPage.medium')}</MenuItem>
            <MenuItem value={"High"}>{t('Support.SupportPage.high')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <FormControl
          variant="outlined"
          className={`w-100`}
        >
          <InputLabel>{t('Support.SupportPage.category')}</InputLabel>

          <Select
            value={currentSupportType}
            onChange={(e) => handleSupportTypeChange(e)}
            className={classes.dropdown}
            label={t('Support.SupportPage.category')}
            data-test="supportType-field"
          >
            <MenuItem value="all">
              <em>{t('Support.SupportPage.all')}</em>
            </MenuItem>
            {props.supportTypes?.reverse()?.map((support: Support) => (
              <MenuItem key={support.id} value={support.id}>
                {support.title}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {isResetValid() && (
        <Grid item xs={12} sm={2} md={1}>
          <Grid container justify="flex-end">
            <IconButton onClick={() => resetFilters()} data-test="reset-button">
              <Typography variant="h5">
              {t('Support.SupportPage.reset')}{" "}
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
    
return (
  <div data-test="tickets-container">
      <div data-test="ticket-list-container">
        <div className="listContainer">
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography color="textPrimary" variant="h4">{t('Support.SupportPage.tickets')}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <CustomButton
                onClick={() => createTicket()}
                label={t('Support.SupportPage.createTicket')}
                data-test="create-ticket-button"
              />
            </Grid>
          </Grid>
        </div>
        <Card elevation={1}>
          <CardHeader
            title={
              !(props.userTickets?.length === 0 && !isResetValid()) &&
              (matches ? (
                <Accordion
                  classes={{
                    root: classes.accordionRoot,
                    disabled: classes.accordionRootDisabled,
                  }}
                  // disabled={}
                >
                  <AccordionSummary
                    classes={{
                      root: classes.accordionSummaryRoot,
                      content: classes.accordionSummaryContent,
                    }}
                  >
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={!isResetValid()}
                    >
                      <Button
                        size="large"
                        variant="outlined"
                        startIcon={<FilterListRoundedIcon />}
                      >
                        {t('Support.SupportPage.filter')}
                      </Button>
                    </Badge>
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{ root: classes.accordionDetailsRoot }}
                  >
                    {filters}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <>{filters}</>
              ))
            }
            data-test="support-card-header"
          />
          <CardContent style={{padding: 0}}>
            {props.userTickets?.length === 0 && !isResetValid() && !reset && !clearSearch ? (
              <NoContentImage 
                message={t('Support.SupportPage.createOne')} 
                type="support" 
                alt="No support tickets"
                data-test="no-support-tickets" 
              />
            ) : (
              <InfiniteScroll
                dataLength={props.userTickets?.length ?? 0}
                next={loadMore}
                hasMore={props.userTickets?.length === currentPage * limit}
                scrollableTarget="scrollable-content"
                scrollThreshold={0.95}
                loader=""
                // scrollThreshold={1}
              >
                <Card elevation={12}>
                  <CardContent className="titleHeader">
                    <Grid container  spacing={2}>
                      <Grid item xs={2}>
                        <span className='activityHeader' style={{display: 'flex', gap:8, alignItems: 'center'}}>
                            {t('Support.SupportPage.id')}
                            <ArrowDownwardIcon fontSize="small"/>
                        </span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className='activityHeader'>{t('Support.SupportPage.title')}</span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className='activityHeader'>{t('Support.SupportPage.created')}</span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className='activityHeader'>{t('Support.SupportPage.category')}</span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className='activityHeader'>{t('Support.SupportPage.priority')}</span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className='activityHeader'>{t('Support.SupportPage.status')}</span>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {props.userTickets?.length > 0 && 
                props.userTickets?.map((ticket : TicketDetailsModel, ind: number) => (
                <Card elevation={ind === props.userTickets?.length - 1 ? 0 : 10} key={ind}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          title={ticket.title}
                          style={{ maxWidth: 100, cursor: 'pointer'}}
                          onClick={() => handleTicketClick(ticket)}
                          data-test="ticket-item-id"
                        >
                          #{ticket.id}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          title={ticket.title}
                          className="oneLine"
                          style={{ maxWidth: 100, cursor: 'pointer' }}
                          onClick={() => handleTicketClick(ticket)}
                          data-test="ticket-item-title"
                          variant="body2"
                        >
                          {ticket.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">{getDateInStandardFormat(ticket.date * 1000)}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">{ticket.category}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">{getPriority(ticket.priority)}</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="body2">
                          <span className={getStatus(ticket.status)}></span>{" "}
                          {ticket.status}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>))}
                {/* {!props.userTickets ||
                    (props.userTickets?.length === 0 && isResetValid() && (
                      <div className="alignCenter" data-test="no-tickets">
                        <p>{t('Support.SupportPage.noTickets')}</p>
                      </div>
                    ))} */}
              </InfiniteScroll>
            )}
          </CardContent>
        </Card>
      </div>
      {props.fetchingTickets && <BackdropLoader message={t('Support.SupportPage.fetchingTickets')} data-test="fetching-tickets-loader" />}
      {props.deletingTicket && <BackdropLoader message={t('Support.SupportPage.deletingTickets')} data-test="deleting-ticket-loader" />}
    </div>
);
   
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    userTickets: state.SupportReducer.userTickets,
    fetchingTickets: state.SupportReducer.fetchingTickets,
    deletingTicket: state.SupportReducer.deletingTicket,
    supportTypes: state.SupportReducer.supportTypes,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUserTickets: (pageNumber: number, limit: number, status: string, search: string, priority: string, supportType: string) =>
        dispatch(getUserTickets(pageNumber, limit, status, search, priority, supportType)),
    deleteTicket: (id: number, callback: () => void) => dispatch(deleteTicket(id, callback)),
    updateBreadcrumb: (payload: any) => dispatch(updateBreadcrumb(payload)),
    getSupportTypes: () => dispatch(getSupportTypes()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Support);

type PropsFromRedux = ConnectedProps<typeof connector>;