import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { AlertInfoIcon, AlertSuccessIcon, AlertWarningIcon } from "../../helpers/customIcon";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeader: {
    padding: "0 !important",
  },
  cardHeaderAction: {
    marginTop: 8,
    marginRight: 20,
  },
  rulePrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  ruleSecondary: {
    color: theme.palette.secondary.light,
  },
  ruleSuccess: {
    color: theme.palette.success.light,
  },
  ruleWarning: {
    color: theme.palette.warning.light,
  },
  textCol: {
    color: "#fff",
  },
}));

interface Props {
  statusDetail: any;
  eventType: string;
}

function AlertCardStatus(props: Props) {
  const classes = useStyles();

  const [expandAlerts, setExpandAlerts] = useState(true);

  const handleExpand = () => {
    setExpandAlerts(!expandAlerts);
  };

  return (
    <>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          classes={{ action: classes.cardHeaderAction }}
          title={
            <Typography variant="h6" display="inline">
              {/* <strong>{props.statusDetail.template}</strong> */}
              {props.statusDetail.template}
            </Typography>
          }
          avatar={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandAlerts,
              })}
              onClick={() => handleExpand()}
            >
              <ExpandMoreIcon color="primary"/>
            </IconButton>
          }
        />
        <Collapse in={expandAlerts}>
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              {props.statusDetail &&
                props.statusDetail.rule_names.map((el: any, ind: number) => (
                  <Grid item xs={12}>
                    <Alert
                      severity={
                        props.eventType === "firing"
                          ? "error"
                          : props.eventType === "pending"
                          ? "warning"
                          : props.eventType === "inactive"
                          ? "success"
                          : "info"
                      }
                      icon={props.eventType === "firing" ? <AlertSuccessIcon /> : props.eventType === "pending" ? <AlertWarningIcon /> : props.eventType === "inactive" ? <AlertInfoIcon /> : ""}
                    >
                      {el}
                    </Alert>
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}

export default AlertCardStatus;
