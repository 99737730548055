import React, { useState, useEffect }  from 'react';
//import PropTypes from 'prop-types';
import { makeStyles,Tabs,Tab, Backdrop, Grid} from '@material-ui/core';
import CiTab from './citab/CiTab';
//import CdTab from './cdtab/CdTab';
import SettingsTab from './settingstab/SettingsTab';
import OverviewCI from './overviewci/OverviewCI'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { connect, ConnectedProps } from "react-redux";
import { CreateApp_TemplateTypes } from '../../../../constants/enums';
import CdTab from './cdtab/CdTab';
import { useTranslation } from 'react-i18next';
import paths from '../../../../constants/paths';
import {TabPanel} from "../../../../components/tabpanel/TabPanel"
import ScriptsTab from '../settingstab/scriptstab/ScriptsTab';
import { AppConstants } from '../../../../constants/appconstants';
import Loader from '../../../../components/loader/Loader';
import NoContentImage from '../../../../components/nocontentimagecontainer/NoContentImage';
import  VariablesAdapter  from './variables/variablesAdapter';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import palette from '../../../../theme/palette';
import { StyledTab, StyledTabs } from '../../../../components/StyledTab/StyledTab';
import AllInclusiveOutlinedIcon from '@material-ui/icons/AllInclusiveOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { ScriptIcon } from '../../../../helpers/customIcon';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';

function a11yProps(index:number) {
  return {
    id: `vertical-tab-${ index }`,
    'aria-controls': `vertical-tabpanel-${ index }`,
  };
}

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'inherit',
    display: 'flex',
    padding : '0',
    margin : '0',
    [ theme.breakpoints.down('md') ]: {
      flexDirection : "column",
    }
  },
  tabs: {
    // borderRight: `1px solid ${ theme.palette.divider }`,
    // [ theme.breakpoints.down('md') ]: {
    //   backgroundColor:'white'
    // }
  },
  tabPanel :  {
    padding : '0',
    margin : '0',
    width : '100%',
    minWidth:250,
  },
  backdrop:{
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: "inherit",
    width:"100%",
    height:"70vh"
  },
  labelContainer : {
    backgroundColor: palette.background.disabled,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    justifyContent: 'center',
    padding: '6px',
    borderRadius: '8px'
  },
  sideNavBar:{
    display:"flex", 
    flexDirection: 'column'
  }
}));

interface TabIndexes {
  CI: number;
  Stats: number;
  CD: number;
  Settings: number;
  Docker: number;
  variables: number;
}

type LocationState = {
  value: number;
};

interface Props extends PropsFromRedux {
  history: RouteComponentProps<{}, StaticContext, LocationState>["history"];
  width: Breakpoint,
  updateInnerTab:(value:number)=>void,
  innerTab: number,
  changeHash:(hash:string,value:number,envId?:number,delay?:number)=>void;
}

export function VerticalTabs(props:Props) {
  const classes = useStyles();
  const [ value, setValue ] = useState(0);
  const [t] = useTranslation();

  const [tabIndexes, setTabIndexes] = useState<Partial<TabIndexes>>({});

  /* istanbul ignore next */
  const getNamebyTabIndex = (newValue:number) => {
     const tabPanelObj = document.getElementById('settingsTab-' + newValue);
     if (tabPanelObj) {
       const name = tabPanelObj.getAttribute("name");
       if (name) {
         props.history.push({
           pathname: paths.ENVIRONMENTINFO.replace(":eId", props.environmentDetails?.id) + "#cicd_" + name
         });
       }
     }
   }

  const handleChange = (event:any, newValue:number) => {
    //setValue(newValue);
    getNamebyTabIndex(newValue);
    props.updateInnerTab(newValue);
   };
   
   useEffect(() => {
     setValue(props.innerTab);
   }, [props.innerTab]);

   useEffect(() => {
     return (() => {
       props.updateInnerTab(0);
     })
   }, []);

   useEffect(() => {
    if (props.environmentDetails) {
      const gitIndices =
        props.environmentDetails?.service_type === CreateApp_TemplateTypes.git
          ? {
              CI: 0,
              Stats: 1,
              CD: 2,
              Settings: 3,
              Docker: 4,
              variables:5
            }
          : {
              CD: 0,
              Settings: 1,
            };

      setTabIndexes(gitIndices);
    }
  }, [props.environmentDetails]);

  useEffect(() => {
    if (Number.isInteger(props.history?.location?.state?.value)) {
      handleChange({}, props.history.location.state.value);
    }
  }, [tabIndexes, props.history]);
  
  return (
      <div className={ classes.root } data-test="main-container">
        {
          props.environmentDetails?.application?.project?.organization_id === 0 || 
          (props.environmentDetails?.application?.project?.organization_id>0 && 
            props.packageStatus && props.packageStatus[AppConstants.packages.TEKTON] === AppConstants.PackageStatus.Installed
          ) ? 
          <>
          <div className={classes.sideNavBar}>
            <p style={{display: isWidthDown("md", props.width) ? 'none' : 'flex'}} className={classes.labelContainer}>
              CI/CD
            </p>
            <StyledTabs
              orientation= { isWidthDown('md', props.width) ? undefined : "vertical" }
              value={ value }
              variant="scrollable"
              onChange={ handleChange }
              className={ classes.tabs }
              indicatorColor="primary"
            >
              {props.environmentDetails?.service_type === CreateApp_TemplateTypes.git && <StyledTab label={t('Environment.CICDTab.ci')} icon={<AllInclusiveOutlinedIcon fontSize='medium'/>} {...a11yProps(1)} data-test="ci-tab" />}
              {props.environmentDetails?.service_type === CreateApp_TemplateTypes.git && <StyledTab label={t('Environment.CICDTab.stats')} icon={<AssessmentOutlinedIcon fontSize='medium'/>} {...a11yProps(2)} data-test="stats-tab" />}
              {(props.environmentDetails?.service_type === CreateApp_TemplateTypes.git || props.environmentDetails?.service_type === CreateApp_TemplateTypes.cr) && <StyledTab label="CD" icon={<SwapHorizontalCircleOutlinedIcon fontSize='medium'/>} {...a11yProps(3)} /> }
              {(props.environmentDetails?.service_type === CreateApp_TemplateTypes.git || props.environmentDetails?.service_type === CreateApp_TemplateTypes.cr) && <StyledTab label={t('Environment.CICDTab.settings')} icon={<SettingsOutlinedIcon fontSize='medium'/>} {...a11yProps(4)} data-test="settings-tab" />}
              {/* {(props.environmentDetails?.service_type === CreateApp_TemplateTypes.cr || props.environmentDetails?.service_type === CreateApp_TemplateTypes.git) && <Tab label=" CD Settings" {...a11yProps(2)} /> } */}
              {props.environmentDetails?.service_type === CreateApp_TemplateTypes.git && <StyledTab label="Scripts" icon={<ScriptIcon fontSize='medium'/>} {...a11yProps(5)} />}
              {props.environmentDetails?.service_type === CreateApp_TemplateTypes.git && <StyledTab label="variables" icon={<SettingsEthernetIcon fontSize='medium'/>} {...a11yProps(6)} />}
            </StyledTabs>
          </div>
      
          <TabPanel value={ value } index={tabIndexes.CI} className={ classes.tabPanel } name="ci" padding={2} tabPanel= "settingsTab">
                <CiTab />
          </TabPanel>
          <TabPanel value={value} index={tabIndexes.Stats} className={classes.tabPanel} name="cistats" padding={2} tabPanel= "settingsTab">
                <OverviewCI handleChange={ handleChange }/>
          </TabPanel>
          <TabPanel value={value} index={tabIndexes.CD} className={ classes.tabPanel } name="cd" padding={2} tabPanel= "settingsTab">
                <CdTab environmentDetails = {props.environmentDetails }/>
          </TabPanel> 
          <TabPanel value={value} index={tabIndexes.Settings} className={classes.tabPanel} name="settings" padding={2} tabPanel= "settingsTab">
                <SettingsTab serviceType={props.environmentDetails?.service_type} changeHash={props.changeHash} />
          </TabPanel>
          <TabPanel value={value} index={tabIndexes.Docker} className={classes.tabPanel} name="scripts" padding={2} tabPanel= "settingsTab">
                <ScriptsTab changeHash={props.changeHash}  />
          </TabPanel>
          <TabPanel value={value} index={tabIndexes.variables} className={classes.tabPanel} name="ciVars" padding={2} tabPanel= "settingsTab">
                <VariablesAdapter  environmentDetails={props.environmentDetails} changeHash={props.changeHash} actionType='update' />
          </TabPanel>
           {/* <TabPanel value={value} index={tabIndexes.CDSettings} className={classes.tabPanel} name="cdsettings">
            <CDSettingsTab />
          </TabPanel> */}
          </> 
          :
          props.packageStatus && props.packageStatus[AppConstants.packages.TEKTON] === AppConstants.PackageStatus.NotInstalled ?
          <Grid container justify="center" alignItems="center" className="notFoundGrid" data-test="noDataFound">
            <NoContentImage
              type="env"
              message="Package is not installed. Please Contact support team."
              alt="No environment details"
            />
          </Grid>:
          <Backdrop className={classes.backdrop} open={true}>
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid item><Loader /></Grid>
              <Grid item>Checking Packages status</Grid>
            </Grid>
          </Backdrop>
        }
      </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  packageStatus: state.EnvironmentReducer.packageStatus,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withWidth()(VerticalTabs));
