import React, { Component } from "react";
import {
  Grid,
  FormControlLabel,
  Card,
  CardHeader,
  Divider,
  Typography,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  Link,
  InputAdornment,
  Checkbox,
  Paper,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiTextField from "../textfield/MuiTextField";
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import "./hpa.css";
import MuiNumberCounter from "../textfield/MuiNumberCounter";
import Alert from "@material-ui/lab/Alert";
import { withTranslation, WithTranslation } from "react-i18next";
import { WithStyles, createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core";
const useStyles = (theme: Theme) =>
  createStyles({
    infoPaper: {
      margin: "0px 20px",
    },
    spaceGrid: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  });

export interface Metric {
  name: string;
  average_utilization: number;
}

export interface ScaleRule {
  type: string;
  value: number;
  time_interval: number;
  average_traffic: number;
}
export interface HPA {
  enabled: boolean;
  metrics: Metric[];
  max_replicas: number;
  min_replicas: number;
  scale_up_rule: ScaleRule;
  scale_down_rule: ScaleRule;
}

interface Props extends WithTranslation, WithStyles<typeof useStyles> {
  horizontal_pod_autoscaler: HPA;
  setHPA: (horizontal_pod_autoscaler: any, error: any) => void;
  loadSource: number;
  isAuthorized: boolean;
  checkIfRamAndCoreAvailable: (
    type: any,
    currentResource: any,
    my_replica: any
  ) => boolean | undefined;
  max_replica_limit: number;
}

interface State {
  min_replicas: number;
  max_replicas: number;
  memory_average_utilization: number;
  cpu_average_utilization: number;
  up_average_traffic: number;
  up_type: string;
  up_value: number;
  up_time_interval: number;
  down_average_traffic: number;
  down_type: string;
  down_value: number;
  down_time_interval: number;
  openAdvanceSettings: boolean;
  cpu_usage: boolean;
  memory_usage: boolean;
  error: any;
  helperText: any;
  replicas: {
    value: number;
    label: string;
  }[];
  max_replica_limit: number;
  [key: string]: any;
}
export class HPA extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      min_replicas: this.props.horizontal_pod_autoscaler?.min_replicas ?? 1,
      max_replicas: this.props.horizontal_pod_autoscaler?.max_replicas > 0 ? this.props.horizontal_pod_autoscaler?.max_replicas : 1,
      memory_average_utilization: 50,
      cpu_average_utilization: 50,
      up_average_traffic:
        this.props.horizontal_pod_autoscaler && this.props.horizontal_pod_autoscaler.scale_up_rule
          ? this.props.horizontal_pod_autoscaler.scale_up_rule.average_traffic
          : 50,
      up_type:
        this.props.horizontal_pod_autoscaler && this.props.horizontal_pod_autoscaler.scale_up_rule
          ? this.props.horizontal_pod_autoscaler.scale_up_rule.type
          : "Pods",
      up_value:
        this.props.horizontal_pod_autoscaler && this.props.horizontal_pod_autoscaler.scale_up_rule
          ? this.props.horizontal_pod_autoscaler.scale_up_rule.value
          : 1,
      up_time_interval:
        this.props.horizontal_pod_autoscaler && this.props.horizontal_pod_autoscaler.scale_up_rule
          ? this.props.horizontal_pod_autoscaler.scale_up_rule.time_interval
          : 1,
      down_average_traffic:
        this.props.horizontal_pod_autoscaler && this.props.horizontal_pod_autoscaler.scale_down_rule
          ? this.props.horizontal_pod_autoscaler.scale_down_rule.average_traffic
          : 50,
      down_type:
        this.props.horizontal_pod_autoscaler && this.props.horizontal_pod_autoscaler.scale_down_rule
          ? this.props.horizontal_pod_autoscaler.scale_down_rule.type
          : "Pods",
      down_value:
        this.props.horizontal_pod_autoscaler && this.props.horizontal_pod_autoscaler.scale_down_rule
          ? this.props.horizontal_pod_autoscaler.scale_down_rule.value
          : 1,
      down_time_interval:
        this.props.horizontal_pod_autoscaler && this.props.horizontal_pod_autoscaler.scale_down_rule
          ? this.props.horizontal_pod_autoscaler.scale_down_rule.time_interval
          : 1,
      openAdvanceSettings:
        this.props.horizontal_pod_autoscaler &&
        (this.props.horizontal_pod_autoscaler.scale_up_rule ||
          this.props.horizontal_pod_autoscaler.scale_down_rule)
          ? true
          : false,
      cpu_usage: true,
      memory_usage: true,
      error: {},
      helperText: {},
      replicas: [
        {
          value: 1,
          label: "1",
        },
      ],
      max_replica_limit: this.props.max_replica_limit
        ? this.props.max_replica_limit
        : 1,
    };
  }

  componentDidMount() {
    if (this.props.horizontal_pod_autoscaler && this.props.horizontal_pod_autoscaler.metrics) {
      let _cpu_usage = false;
      let _memory_usage = false;
      this.props.horizontal_pod_autoscaler.metrics.map((m: Metric) => {
        if (m.name === "cpu") {
          _cpu_usage = true;
          this.setState({
            cpu_average_utilization: m.average_utilization,
          });
        }
        if (m.name === "memory") {
          _memory_usage = true;
          this.setState({
            memory_average_utilization: m.average_utilization,
          });
        }
      });
      this.setState({
        memory_usage: _memory_usage,
        cpu_usage: _cpu_usage,
      });
    }
    if (this.props.max_replica_limit) {
      this.setState({
        max_replica_limit: this.props.max_replica_limit,
      });
      let i = 1;
      let _replicas = [];
      while (i <= this.props.max_replica_limit) {
        _replicas.push({
          value: i,
          label: i + "",
        });
        i++;
      }
      this.setState({
        replicas: _replicas,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps &&
      nextProps.max_replica_limit &&
      nextProps.max_replica_limit !== this.state.max_replica_limit
    ) {
      this.setState({
        max_replica_limit: nextProps.max_replica_limit,
      });
      // if(this.state.max_replicas > nextProps.max_replica_limit){
      // }
      this.maxReplicaChange(this.state.max_replicas);
      // this.setState({
      //   max_replicas: nextProps.max_replica_limit,
      // }, () => {
      // });
      if (this.state.min_replicas > nextProps.max_replica_limit) {
        this.setState(
          {
            min_replicas: nextProps.max_replica_limit,
          },
          () => {
            this.minReplicaChange(this.state.min_replicas);
          }
        );
      }

      let i = 1;
      let _replicas = [];
      while (i <= nextProps.max_replica_limit) {
        _replicas.push({
          value: i,
          label: i + "",
        });
        i++;
      }
      this.setState({
        replicas: _replicas,
      });
    }
    if (nextProps.max_replica_limit) {
      let i = 1;
      let _replicas = [];
      while (i <= nextProps.max_replica_limit) {
        _replicas.push({
          value: i,
          label: i + "",
        });
        i++;
      }
      this.setState({
        replicas: _replicas,
      });
    }
  }

  sliderText = (val: number): string => {
    const _val = Math.round(val);
    if (
      this.state.max_replicas === this.state.min_replicas &&
      this.state.max_replicas === val
    ) {
      return `< ${_val} >`;
    } else if (val === this.state.max_replicas) {
      return `< ${_val}`;
    } else if (val === this.state.min_replicas) {
      return `${_val} >`;
    }
    return `${_val}`;
  };

  validPercent = (val: string) => {
    if (parseInt(val) <= -1 || parseInt(val) === 0 || parseInt(val) > 100) {
      return false;
    }
    return true;
  };

  isPositive = (val: string) => {
    if (parseInt(val) <= -1 || parseInt(val) === 0) {
      return false;
    }
    return true;
  };

  validValue = (type: string, val: string, src: string) => {
    if (type === "Percent") {
      return this.validPercent(val);
    }
    if (type === "Pods") {
      if (src === "up") {
        return Boolean(
          parseInt(val) <= this.state.max_replicas &&
            parseInt(val) >= this.state.min_replicas &&
            this.isPositive(val)
        );
      } else if (src === "down") {
        return Boolean(
          parseInt(val) <= this.state.max_replicas &&
            parseInt(val) >= this.state.min_replicas &&
            this.isPositive(val)
        );

        // return Boolean(val >= this.state.min_replicas && val <= this.state.max_replicas && this.isPositive(val));
      }
    }
  };

  maxReplicaChange = (val: number) => {
    let e = {
      target: {
        value: val,
      },
    };
    this.setState(
      {
        max_replicas: e.target.value,
      },
      () => {
        if (isNaN(this.state.max_replicas)) {
          this.setState(
            {
              helperText: {
                ...this.state.helperText,
                max_replicas: this.props.t("HPA.maxReplica"),
              },
              error: {
                ...this.state.error,
                max_replicas: true,
              },
            },
            () => {
              this.sendHpaData();
            }
          );
        } else if (this.state.max_replicas < 1) {
          this.setState(
            {
              helperText: {
                ...this.state.helperText,
                max_replicas: this.props.t("HPA.maxReplicaLimitError"),
              },
              error: {
                ...this.state.error,
                max_replicas: true,
              },
            },
            () => {
              this.sendHpaData();
            }
          );
        } else if (
          this.state.max_replicas > this.props.max_replica_limit ||
          !this.props.checkIfRamAndCoreAvailable(
            1,
            null,
            this.state.max_replicas
          )
        ) {
          // console.log(this.state.max_replicas)
          // console.log(this.state.max_replica_limit)
          this.setState(
            {
              helperText: {
                ...this.state.helperText,
                max_replicas: this.props.t("HPA.limitExceededError"),
              },
              error: {
                ...this.state.error,
                max_replicas: true,
              },
            },
            () => {
              this.sendHpaData();
            }
          );
        } else {
          this.setState(
            {
              helperText: {
                ...this.state.helperText,
                max_replicas: "",
              },
              error: {
                ...this.state.error,
                max_replicas: false,
              },
            },
            () => {
              this.sendHpaData();
            }
          );
        }
        // this.minReplicaChange({ target: { value: this.state.min_replicas } )
        this.minReplicaChange(this.state.min_replicas);
        // this.sendHpaData();
      }
    );
  };

  minReplicaChange = (val: number) => {
    let e = {
      target: {
        value: val,
      },
    };
    this.setState(
      {
        min_replicas: e.target.value,
      },
      () => {
        if (isNaN(this.state.min_replicas)) {
          this.setState(
            {
              helperText: {
                ...this.state.helperText,
                min_replicas: this.props.t("HPA.minReplicaError"),
              },
              error: {
                ...this.state.error,
                min_replicas: true,
              },
            },
            () => {
              this.sendHpaData();
            }
          );
        } else if (this.state.min_replicas < 1) {
          this.setState(
            {
              helperText: {
                ...this.state.helperText,
                min_replicas: this.props.t("HPA.minReplicaLimitError"),
              },
              error: {
                ...this.state.error,
                min_replicas: true,
              },
            },
            () => {
              this.sendHpaData();
            }
          );
        } else if (this.state.min_replicas > this.state.max_replicas) {
          // console.log(this.state.max_replicas)
          // console.log(this.state.max_replica_limit)
          this.setState(
            {
              helperText: {
                ...this.state.helperText,
                min_replicas: this.props.t("HPA.minMaxReplicaError"),
              },
              error: {
                ...this.state.error,
                min_replicas: true,
              },
            },
            () => {
              this.sendHpaData();
            }
          );
        } else {
          this.setState(
            {
              helperText: {
                ...this.state.helperText,
                min_replicas: "",
              },
              error: {
                ...this.state.error,
                min_replicas: false,
              },
            },
            () => {
              this.sendHpaData();
            }
          );
        }
        // this.maxReplicaChange({target:  {value : this.state.max_replicas}})
      }
    );
  };

  handleHPAChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sName = e.target.name;
    if (
      (sName === "up_time_interval" ||
        sName === "down_time_interval" ||
        sName === "down_average_traffic" ||
        sName === "up_average_traffic") &&
      !this.isPositive(e.target.value)
    ) {
      this.setState({
        error: {
          ...this.state.error,
          [e.target.name]: true,
        },
        helperText: {
          ...this.state.helperText,
          [e.target.name]: this.props.t("HPA.positiveError"),
        },
      });
      return;
    } else {
      this.setState({
        error: {
          ...this.state.error,
          [e.target.name]: false,
        },
        helperText: {
          ...this.state.helperText,
          [e.target.name]: "",
        },
      });
    }
    if (
      sName === "down_value" &&
      !this.validValue(this.state.down_type, e.target.value, "down")
    ) {
      this.setState({
        error: {
          ...this.state.error,
          [e.target.name]: true,
        },
        helperText: {
          ...this.state.helperText,
          [e.target.name]: this.props.t("HPA.percentageError"),
        },
      });
      return;
    } else {
      this.setState({
        error: {
          ...this.state.error,
          [e.target.name]: false,
        },
        helperText: {
          ...this.state.helperText,
          [e.target.name]: "",
        },
      });
    }
    if (
      sName === "up_value" &&
      !this.validValue(this.state.up_type, e.target.value, "up")
    ) {
      this.setState({
        error: {
          ...this.state.error,
          [e.target.name]: true,
        },
        helperText: {
          ...this.state.helperText,
          [e.target.name]: this.props.t("HPA.percentageError"),
        },
      });
      return;
    } else {
      this.setState({
        error: {
          ...this.state.error,
          [e.target.name]: false,
        },
        helperText: {
          ...this.state.helperText,
          [e.target.name]: "",
        },
      });
    }
    if (
      (sName === "memory_average_utilization" ||
        sName === "cpu_average_utilization") &&
      !this.validPercent(e.target.value)
    ) {
      this.setState({
        error: {
          ...this.state.error,
          [e.target.name]: true,
        },
        helperText: {
          ...this.state.helperText,
          [e.target.name]: this.props.t("HPA.rangeError"),
        },
      });
      return;
    } else {
      this.setState({
        error: {
          ...this.state.error,
          [e.target.name]: false,
        },
        helperText: {
          ...this.state.helperText,
          [e.target.name]: "",
        },
      });
    }

    if (sName === "up_type" && e.target.value === "Pods") {
      if (this.state.up_value > this.state.max_replicas) {
        this.setState({
          up_value: this.state.max_replicas,
        });
      }
    }
    if (sName === "down_type" && e.target.value === "Pods") {
      if (this.state.down_value > this.state.max_replicas) {
        this.setState({
          down_value: this.state.max_replicas,
        });
      }
    }
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.sendHpaData();
      }
    );
  };

  sendHpaData = () => {
    let storagePayload: {
      enabled?: boolean;
      metrics?: Metric[];
      max_replicas?: number | null;
      min_replicas?: number | null;
      scale_up_rule?: ScaleRule | null;
      scale_down_rule?: ScaleRule | null;
    } = {
      min_replicas: this.state.min_replicas,
      max_replicas: this.state.max_replicas,
    };

    let metrics = [];
    if (this.state.memory_usage) {
      metrics.push({
        name: "memory",
        average_utilization: this.state.memory_average_utilization,
      });
    }
    if (this.state.cpu_usage) {
      metrics.push({
        name: "cpu",
        average_utilization: this.state.cpu_average_utilization,
      });
    }
    storagePayload.metrics = metrics;
    if (this.state.openAdvanceSettings) {
      storagePayload.scale_up_rule = {
        average_traffic: this.state.up_average_traffic,
        type: this.state.up_type,
        value: this.state.up_value,
        time_interval: this.state.up_time_interval,
      };
      storagePayload.scale_down_rule = {
        average_traffic: this.state.down_average_traffic,
        type: this.state.down_type,
        value: this.state.down_value,
        time_interval: this.state.down_time_interval,
      };
    } else {
      storagePayload.scale_down_rule = null;
      storagePayload.scale_up_rule = null;
    }
    const error =
      this.state.error?.max_replicas || this.state.error?.min_replicas;
    // size: this.state.storageSize,

    this.props.setHPA(storagePayload, error);
  };

  toggleAdvance = () => {
    this.setState(
      {
        openAdvanceSettings: !this.state.openAdvanceSettings,
      },
      () => {
        this.sendHpaData();
      }
    );
  };

  handleIncreaseMaxReplica = () => {
    const rep = this.state.max_replicas;
    const min = this.state.min_replicas;
    let e: any = {};
    e.target = {};
    e.target.value = this.state.max_replicas + 1;

    if (
      rep >= min &&
      this.props.checkIfRamAndCoreAvailable(
        1,
        null,
        // val
        this.state.max_replicas
      )
    ) {
      this.maxReplicaChange(this.state.max_replicas + 1);
      // this.setState(
      //   {
      //     max_replicas: rep + 1,
      //   },
      //   () => {
      //     this.sendHpaData();
      //   }
      // );
    }
  };

  handleDecreaseMaxReplica = () => {
    const rep = this.state.max_replicas;
    const min = this.state.min_replicas;
    let e: any = {};
    e.target = {};
    e.target.value = this.state.max_replicas - 1;

    if (rep > 1 && min < rep) {
      this.maxReplicaChange(this.state.max_replicas - 1);
      // this.setState(
      //   {
      //     max_replicas: rep - 1,
      //   },
      //   () => {
      //     this.sendHpaData();
      //   }
      // );
    }
  };

  handleIncreaseMinReplica = () => {
    const rep = this.state.min_replicas;
    const max = this.state.max_replicas;
    let e: any = {};
    e.target = {};
    e.target.value = this.state.min_replicas + 1;
    if (rep < max) {
      this.minReplicaChange(this.state.min_replicas + 1);
    }
  };

  handleDecreaseMinReplica = () => {
    const rep = this.state.min_replicas;
    const max = this.state.max_replicas;
    let e: any = {};
    e.target = {};
    e.target.value = this.state.min_replicas + 1;
    if (rep > 1 && rep <= max) {
      this.minReplicaChange(this.state.min_replicas + 1);
    }
  };

  cpu_usage_control = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked || this.state.memory_usage) {
      this.setState(
        {
          cpu_usage: e.target.checked,
        },
        () => {
          this.sendHpaData();
        }
      );
    }
  };

  memory_usage_control = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked || this.state.cpu_usage) {
      this.setState(
        {
          memory_usage: e.target.checked,
        },
        () => {
          this.sendHpaData();
        }
      );
    }
  };

  // sliderChange = (e, sliderValue) => {
  //   // console.log(e)
  //   // console.log("sliderValue")
  //   // console.log(sliderValue)
  //   sliderValue[0] = Math.round(sliderValue[0]);
  //   sliderValue[1] = Math.round(sliderValue[1]);
  //   this.setState({
  //     min_replicas: Math.round(sliderValue[0]),
  //     max_replicas: Math.round(sliderValue[1]),
  //   });
  // };

  render() {
    const { classes, t } = this.props;
    return (
      <div className="m-20 mt-55" data-test="main-container">
        <Card>
          <CardHeader title={<Typography variant="h6">{`${t("HPA.options")} - Horizontal`}</Typography>} data-test="options-header" />
          <Divider />
          <CardContent>
            {/* <Grid container spacing={3}>
              <Grid item md={2} xs={3}>
                <label>Replicas </label>
              </Grid>
              <Grid item md={5} xs={9}>
                <Grid container spacing={3}>
                  <Grid item md={3} sm={3} xs={3}>
                    <IconButton
                      onClick={(e) => this.handleDecreaseMaxReplica(e)}
                    >
                      <RemoveCircleIcon color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item md={12} sm={6} xs={6}>
                    <Slider
                      className="replica-slider"
                      defaultValue={[this.state.min_replicas, this.state.max_replicas]}
                      getAriaValueText={this.sliderText}
                      valueLabelFormat={this.sliderText}
                      aria-labelledby="discrete-slider-custom"
                      step={0.1}
                      valueLabelDisplay="on"
                      marks={this.state.replicas}
                      max={this.state.max_replica_limit}
                      min={1}
                      onChangeCommitted	={this.sliderChange}
                    />
                    <Grid container spacing={3}>
                      <Grid item md={6} >
                        <Typography variant="body2" >
                          Minimum replicas :  {this.state.min_replicas}
                        </Typography>   
                      </Grid>
                      <Grid item md={6} alignItem="right" >
                        <Typography variant="body2" className="textRight">
                        Maximum replicas :  {this.state.max_replicas}
                        </Typography>    
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <IconButton
                      onClick={(e) => this.handleIncreaseMaxReplica(e)}
                    >
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}

            <Grid container spacing={3}>
              <Grid item md={5}>
                <Alert
                  severity="info"
                  className="w-100"
                  data-test="replica-limit"
                >
                  {t("HPA.replicaLimit")} {this.props.max_replica_limit}
                </Alert>
              </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center">
              <Grid item md={2} xs={3} data-test="max-replica">
                <Typography variant="body2">{t("HPA.maxReplica")}</Typography>
              </Grid>
              <Grid item md={3} xs={9}>
                <Grid container>
                  <MuiNumberCounter
                    data-test="max-replica-changer"
                    // label={"max_replicas"}
                    defaultValue={this.state.min_replicas}
                    initialValue={this.state.max_replicas}
                    maxValue={this.state.max_replica_limit}
                    isError={this.state.error?.max_replicas}
                    updateValueChange={(val) => this.maxReplicaChange(val)}
                    updateIncomingValue={true}
                  />
                </Grid>
              </Grid>
              <Grid item md={3} xs={false}>
                <Paper className={classes.infoPaper}></Paper>
              </Grid>
            </Grid>

            <Grid container spacing={3} className="m-t-20" alignItems="center">
              <Grid item md={2} xs={3} data-test="min-replica">
                <Typography variant="body2">{t("HPA.minReplica")}</Typography>
              </Grid>
              <Grid item md={3} xs={9}>
                <Grid container>
                  <MuiNumberCounter
                    data-test="min-replica-changer"
                    // label={"min_replicas"}
                    defaultValue={1}
                    initialValue={this.state.min_replicas}
                    maxValue={this.state.max_replicas}
                    isError={this.state.error?.min_replicas}
                    updateValueChange={(val) => this.minReplicaChange(val)}
                    updateIncomingValue={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={9}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}></Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              {/* <Grid item md={2} xs={3}>
                <label>CPU Usage Condition</label>
              </Grid> */}
              <Grid item md={2}>
                <FormControlLabel
                  data-test="cpu-usage"
                  control={
                    <Checkbox
                      color="primary"
                      checked={this.state.cpu_usage}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.cpu_usage_control(e)
                      }
                      disabled={!this.props.isAuthorized}
                    />
                  }
                  label={<Typography variant="body2">{t("HPA.cpuUsage")}</Typography>}
                />
              </Grid>

              {this.state.cpu_usage && (
                <Grid item md={3} sm={9} xs={12}>
                  {/* <OutlinedInput
                              data-test="cpu-average-utilization"
                              id="cpu_average_utilization"
                              name="cpu_average_utilization"
                              value={ this.state.cpu_average_utilization }
                              customClassName="oneRemMarginBottomSeperator"
                    // error={this.state.error.cpu_average_utilization}
                    // helperText={this.state.helperText.cpu_average_utilization}
                              variant="outlined"
                              onChange={ (e) => this.handleHPAChange(e) }
                              type="number"
                              className="w-100"
                              endAdornment={
                                  <InputAdornment position="end">%</InputAdornment>
                    }
                              disabled={ !this.props.isAuthorized }
                            /> */}
                  <MuiTextField
                    data-test="cpu-average-utilization"
                    id="cpu_average_utilization"
                    name="cpu_average_utilization"
                    value={this.state.cpu_average_utilization}
                    customClassName="oneRemMarginBottomSeperator"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleHPAChange(e)
                    }
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    disabled={!this.props.isAuthorized}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={3}>
              {/* <Grid item md={2} xs={3}>
                <label>Memory Usage Condition</label>
              </Grid> */}
              <Grid item md={2}>
                <FormControlLabel
                  data-test="memory-usage"
                  control={
                    <Checkbox
                      color="primary"
                      checked={this.state.memory_usage}
                      onChange={(e) => this.memory_usage_control(e)}
                      disabled={!this.props.isAuthorized}
                    />
                  }
                  label={<Typography variant="body2">{t("HPA.memoryUsage")}</Typography>}
                />
              </Grid>
              {this.state.memory_usage && (
                <Grid item md={3} sm={9} xs={12}>
                  {/* <OutlinedInput
                              data-test="memory-average-utilization"
                              id="memory_average_utilization"
                              name="memory_average_utilization"
                              value={ this.state.memory_average_utilization }
                              customClassName="oneRemMarginBottomSeperator"
                              className="w-100"
                    // error={this.state.error.memory_average_utilization}
                    // helperText={this.state.helperText.memory_average_utilization}
                              variant="outlined"
                              onChange={ (e) => this.handleHPAChange(e) }
                              type="number"
                              endAdornment={
                                  <InputAdornment position="end">%</InputAdornment>
                    }
                              disabled={ !this.props.isAuthorized }
                            /> */}
                  <MuiTextField
                    data-test="memory-average-utilization"
                    id="memory_average_utilization"
                    name="memory_average_utilization"
                    value={this.state.memory_average_utilization}
                    customClassName="oneRemMarginBottomSeperator"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleHPAChange(e)
                    }
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    disabled={!this.props.isAuthorized}
                  />
                </Grid>
              )}
            </Grid>

            <Grid className="advancediv">
              <Link
                onClick={() => this.toggleAdvance()}
                data-test="advance-settings"
              >
                {" "}
                {t("HPA.advanceSettings")}{" "}
              </Link>
            </Grid>

            {this.state.openAdvanceSettings && (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item md={12} xs={12}>
                      <Typography
                        color="primary"
                        variant="h5"
                        data-test="scale-up-icon"
                      >
                        {t("HPA.scaleUp")}
                        <ArrowUpwardRoundedIcon className="hpaIconCircle" />
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <Typography variant="body2" data-test="hpa-interval">
                        {t("HPA.interval")}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={8}>
                      {/* <OutlinedInput
                                      id="up_average_traffic"
                                      name="up_average_traffic"
                                      data-test="up-average-traffic"
                                      value={ this.state.up_average_traffic }
                                      customClassName="oneRemMarginBottomSeperator"
                        // error={this.state.error.up_average_traffic}
                        // helperText={this.state.helperText.up_average_traffic}
                                      variant="outlined"
                                      onChange={ (e) => this.handleHPAChange(e) }
                                      type="number"
                                      className="w-100"
                                      endAdornment={
                                          <InputAdornment position="end">
                                              {t('HPA.seconds')}
                                          </InputAdornment>
                        }
                                      disabled={ !this.props.isAuthorized }
                                    /> */}
                      <MuiTextField
                        id="up_average_traffic"
                        name="up_average_traffic"
                        data-test="up-average-traffic"
                        value={this.state.up_average_traffic}
                        customClassName="oneRemMarginBottomSeperator"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleHPAChange(e)
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {t("HPA.seconds")}
                            </InputAdornment>
                          ),
                        }}
                        disabled={!this.props.isAuthorized}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="body2" data-test="change">{t("HPA.change")}</Typography>
                    </Grid>

                    <Grid item md={3} sm={4} xs={8}>
                      <MuiTextField
                        id="up_value"
                        name="up_value"
                        data-test="up-value"
                        value={this.state.up_value}
                        // error={this.state.error.up_value}
                        // helperText={this.state.helperText.up_value}
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleHPAChange(e)
                        }
                        type="number"
                        disabled={!this.props.isAuthorized}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.spaceGrid}></Grid>
                    <Grid item md={3} sm={4} xs={8}>
                      <FormControl variant="outlined" className="w-100">
                        <Select
                          name="up_type"
                          data-test="up-type"
                          value={this.state.up_type}
                          onChange={(e: any) => this.handleHPAChange(e)}
                          defaultValue={this.state.up_type}
                          disabled={!this.props.isAuthorized}
                        >
                          {["Percent", "Pods"].map((s, ind) => (
                            <MenuItem key={ind} value={s}>
                              {s === "Pods" ? "Replicas" : s}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="body2" data-test="change-interval">
                        {t("HPA.changeInterval")}{" "}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={8}>
                      {/* <OutlinedInput
                                      id="up_time_interval"
                                      name="up_time_interval"
                                      data-test="up-time-interval"
                                      value={ this.state.up_time_interval }
                                      className="w-100"
                                      customClassName="oneRemMarginBottomSeperator"
                        // error={this.state.error.up_time_interval}
                        // helperText={this.state.helperText.up_time_interval}
                                      variant="outlined"
                                      onChange={ (e) => this.handleHPAChange(e) }
                                      type="number"
                                      endAdornment={
                                          <InputAdornment position="end">
                                              {t('HPA.seconds')}
                                          </InputAdornment>
                        }
                                      disabled={ !this.props.isAuthorized }
                                    /> */}
                      <MuiTextField
                        id="up_time_interval"
                        name="up_time_interval"
                        data-test="up-time-interval"
                        value={this.state.up_time_interval}
                        customClassName="oneRemMarginBottomSeperator"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleHPAChange(e)
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {t("HPA.seconds")}
                            </InputAdornment>
                          ),
                        }}
                        disabled={!this.props.isAuthorized}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      color="primary"
                      variant="h5"
                      data-test="scale-down"
                    >
                      {t("HPA.scaleDown")}
                      <ArrowDownwardRoundedIcon className="hpaIconCircle" />
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="body2" data-test="interval-scale-down">
                        {t("HPA.interval")}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={8}>
                      {/* <OutlinedInput
                                      id="down_average_traffic"
                                      name="down_average_traffic"
                                      data-test="down-average-traffic"
                                      value={ this.state.down_average_traffic }
                                      customClassName="oneRemMarginBottomSeperator"
                        // error={this.state.error.down_average_traffic}
                        // helperText={this.state.helperText.down_average_traffic}
                                      variant="outlined"
                                      onChange={ (e) => this.handleHPAChange(e) }
                                      type="number"
                                      className="w-100"
                                      endAdornment={
                                          <InputAdornment position="end">
                                              {t('HPA.seconds')}
                                          </InputAdornment>
                        }
                                      disabled={ !this.props.isAuthorized }
                                    /> */}
                      <MuiTextField
                        id="down_average_traffic"
                        name="down_average_traffic"
                        data-test="down-average-traffic"
                        value={this.state.down_average_traffic}
                        customClassName="oneRemMarginBottomSeperator"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleHPAChange(e)
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {t("HPA.seconds")}
                            </InputAdornment>
                          ),
                        }}
                        disabled={!this.props.isAuthorized}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={4}>
                      <Typography variant="body2" data-test="hpa-change">{t("HPA.change")}</Typography>
                    </Grid>
                    <Grid item md={3} sm={4} xs={8}>
                      <MuiTextField
                        id="down_value"
                        name="down_value"
                        data-test="down-value"
                        value={this.state.down_value}
                        // error={this.state.error.down_value}
                        // helperText={this.state.helperText.down_value}
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleHPAChange(e)
                        }
                        type="number"
                        disabled={!this.props.isAuthorized}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.spaceGrid}></Grid>
                    <Grid item md={3} sm={4} xs={8}>
                      <FormControl variant="outlined" className="w-100">
                        <Select
                          name="down_type"
                          data-test="down-type"
                          value={this.state.down_type}
                          onChange={(e: any) => this.handleHPAChange(e)}
                          defaultValue={this.state.down_type}
                          disabled={!this.props.isAuthorized}
                        >
                          {["Percent", "Pods"].map((s, ind) => (
                            <MenuItem key={ind} value={s}>
                              {s === "Pods" ? "Replicas" : s}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="body2" data-test="change-interval-time">
                        {t("HPA.changeInterval")}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={8}>
                      {/* <OutlinedInput
                                      id="down_time_interval"
                                      name="down_time_interval"
                                      data-test="down-time-interval"
                                      value={ this.state.down_time_interval }
                                      customClassName="oneRemMarginBottomSeperator"
                        // error={this.state.error.down_time_interval}
                        // helperText={this.state.helperText.down_time_interval}
                                      variant="outlined"
                                      className="w-100"
                                      onChange={ (e) => this.handleHPAChange(e) }
                                      type="number"
                                      endAdornment={
                                          <InputAdornment position="end">
                                              {t('HPA.seconds')}
                                          </InputAdornment>
                        }
                                      disabled={ !this.props.isAuthorized }
                                    /> */}
                      <MuiTextField
                        id="down_time_interval"
                        name="down_time_interval"
                        data-test="down-time-interval"
                        value={this.state.down_time_interval}
                        customClassName="oneRemMarginBottomSeperator"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleHPAChange(e)
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {t("HPA.seconds")}
                            </InputAdornment>
                          ),
                        }}
                        disabled={!this.props.isAuthorized}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(useStyles)(withTranslation()(HPA));
