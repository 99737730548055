import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertDashCard from "./AlertDashCard";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { alertDashBoardStatus, getAlertLists } from "../../pages/environment/environmentinfo/alertmanager/redux/action";
import AlertCardStatus from "./AlertCardStatus";
import { Alert } from "@material-ui/lab";
import { fade } from '@material-ui/core/styles';
import { EnvSettingTabs } from "../../constants/environmentTabs";
import { AppConstants } from "../../constants/appconstants";
import CircularLoader from "../loader/CircularLoader";
import { AlertDisabledIcon, AlertFiringIcon, AlertHealthyIcon, AlertPendingIcon } from "../../helpers/customIcon";

const lighterOpacity = 0.4;

const useStyles = makeStyles((theme) => ({
  iconPrimary: {
    // color: fade(theme.palette.primary.main, lighterOpacity),
    height: 50,
    width: 50
  },
  iconSecondary: {
    // color: theme.palette.secondary.main,
    height: 50,
    width: 50
  },
  iconSuccess: {
    // color: theme.palette.success.main,
    height: 50,
    width: 50
  },
  iconWarning: {
    // color: theme.palette.warning.main,
    height: 50,
    width: 50
  },
  stateContainer: {
    paddingTop: "30px",
  },
  loader:{
    marginTop:"20px",
    padding:"10px"
  },
  divider:{
    marginTop:"20px"
  }
}));

interface Props extends PropsFromRedux {
  source?:number;
  changeHash?: (hash: string, val: number, envId?: number) => void ;
  changeInnerTab?: (event: any, value: number) => void;
  overViewTab?:boolean
}

function AlertDashBoard(props: Props) {
  const classes = useStyles();

  const [eventType, setEventType] = useState("");
  const [isInstalled, setIsInstalled] = useState(false);

  const handleEventType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEventType(e.target.value);
  };

  useEffect(() => {
    if (props.environmentDetails.id > 0 && !props.source) {
      props.getAlertLists(props.environmentDetails.id);
    }
  }, [props.environmentDetails]);

  useEffect(()=>{
    if(props.alertLists){
      const isInstall = props.alertLists.some((el:any, ind:number)=>el.is_installed === true);
      if(isInstall){
        setEventType("firing")
      }
      setIsInstalled(isInstall);
    }
  },[props.alertLists])

  useEffect(()=>{
    if(eventType){
      props.alertDashBoardStatus(props.environmentDetails.id, eventType);
    }
  },[eventType])

  const handleTempPath = () => {
    const subIndex = EnvSettingTabs("#alert", props.environmentDetails).indexOf(AppConstants.EnvSettingsTabs.alerttemplates);
    if(props.source && props.changeInnerTab){
      props.changeInnerTab({}, subIndex);
    }else if(props.changeHash){
      props.changeHash("alert", subIndex)
    }
  }

  const handleRefresh = () => {
    if (props.environmentDetails.id > 0) {
      props.getAlertLists(props.environmentDetails.id);
    }
  }

  return (
    <>
    {
      props.source === 1 && 
      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          <Button
            onClick={() => handleRefresh()}
            color="primary"
            variant="contained"
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
    }
    <Grid container justifyContent="center">
      <Grid item xs={12} md={12} lg={isInstalled || props.overViewTab ? 12 : 10}>
        <Card className="m-t-20">
          <CardHeader title={<Typography variant="h6">Alerts</Typography>}/>
          <Divider />
          <CardContent>
            {
              isInstalled ? 
              <>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12} xl={2}>
                  <AlertDashCard
                    title="FIRING"
                    stateNo={props.alertStatusDetails?.firing}
                    // background="secondary"
                    icon={<AlertFiringIcon  viewBox="0 0 50 50" className={classes.iconSecondary} />}
                  />
                </Grid>
                <Grid item md={3} xs={12} xl={2}>
                  <AlertDashCard
                    title="PENDING"
                    stateNo={props.alertStatusDetails?.pending}
                    // background="warning"
                    icon={<AlertPendingIcon viewBox="0 0 50 50" className={classes.iconWarning} />}
                  />
                </Grid>
                <Grid item md={3} xs={12} xl={2}>
                  <AlertDashCard
                    title="HEALTHY"
                    stateNo={props.alertStatusDetails?.inactive}
                    // background="success"
                    icon={<AlertHealthyIcon viewBox="0 0 50 50" className={classes.iconSuccess} />}
                  />
                </Grid>
                <Grid item md={3} xs={12} xl={2}>
                  <AlertDashCard
                    title="DISABLED"
                    stateNo={props.alertStatusDetails?.disabled}
                    // background="primary"
                    icon={
                      <AlertDisabledIcon viewBox="0 0 50 50" className={classes.iconPrimary} />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.stateContainer}>
                <Grid item>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label=""
                      name="eventType"
                      value={eventType}
                      onChange={(e) => handleEventType(e)}
                      data-test="event-radio"
                    >
                      <Grid container spacing={4}>
                        <Grid item md={3} sm={3} xs={12}>
                          <FormControlLabel
                            value="firing"
                            control={<Radio color="primary" />}
                            label="Firing"
                          />
                        </Grid>
                        <Grid item md={3} sm={3} xs={12} style={{ marginLeft: "-20px" }}>
                          <FormControlLabel
                            value="pending"
                            control={<Radio color="primary" />}
                            label="Pending"
                          />
                        </Grid>
                        <Grid
                          item
                          md={3}
                          sm={3}
                          xs={12}
                        >
                          <FormControlLabel
                            defaultChecked={true}
                            value="inactive"
                            control={<Radio color="primary" />}
                            label="Healthy"
                          />
                        </Grid>
                        <Grid item md={3} sm={3} xs={12} style={{ marginLeft: "-20px" }}>
                          <FormControlLabel
                            value="disabled"
                            control={<Radio color="primary" />}
                            label="Disabled"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            {
              !props.alertStatus ?
              <Grid container spacing={3} style={{ marginTop: "30px" }}>
              {props.alertStatusDetails &&
              props.alertStatusDetails.data.length > 0 ? (
                props.alertStatusDetails.data.map((el: any, ind: number) => (
                  <Grid item xs={12} md={12} xl={10}>
                    <AlertCardStatus statusDetail={el} eventType={eventType}/>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Alert severity="info">No rules found in {eventType==="inactive"?"healthy":eventType} state</Alert>
                </Grid>
              )}
            </Grid>:
            <div>
              <div className={classes.divider}><Divider/></div>
              <Grid container className={classes.loader}>
                <CircularLoader 
                  message={`Fetching ${eventType === "inactive" ? "healthy" : eventType} status`}
                  size={20}
                  thickness={5}
                  typoSize="h6"
                />
            </Grid>
            </div>
            }
            </>
            :
            <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="body1">No alert template installed, Please install template to receive alerts</Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => handleTempPath()}
                color="primary"
                variant="contained"
                size="small"
              >
                Go To Template
              </Button>
            </Grid>
          </Grid>
            }

          </CardContent>
        </Card>
      </Grid>
    </Grid>
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  alertStatusDetails: state.AlertReducer.alertStatusDetails,
  alertLists: state.AlertReducer.templateLists,
  alertStatus: state.AlertReducer.alertStatus,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getAlertLists: (id: number) => dispatch(getAlertLists(id)),
    alertDashBoardStatus: (id: number, status_name: string) =>
      dispatch(alertDashBoardStatus(id, status_name)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AlertDashBoard);
