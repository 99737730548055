import {
  Grid,
  Typography,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  TextField,
  Button,
  Theme
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { updateClusterLabelsAndCluster } from "./redux/actions";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import MultiValueChipInput from "../../components/emailinput/MultiValueChipInput";
import TooltipChip from "../../components/tooltipchip/TooltipChip";
import BackdropLoader from "../../components/loader/BackdropLoader";
import { ColorPicker, useColor } from "react-color-palette";
import { without } from "lodash";
import "react-color-palette/lib/css/styles.css";
import clsx from "clsx";
import { Dispatch } from "redux";

/* istanbul ignore next */
const useStyles = makeStyles((theme:Theme) => ({
  expand: {
    transform: "rotate(0deg)",
    transition: theme?.transitions?.create("transform", {
      duration: theme?.transitions?.duration?.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeader: {
    padding: "0 !important",
  },
  editIcon: {
    border: "2px dashed",
  },
  // cardHeaderAction: {
  //   marginTop: 3,
  //   marginRight: 3,
  // },
  colorOutput: {
    height: 20,
    width: 20,
    borderRadius: "50%",
    display: "inline-block",
  },
}));

interface Props extends PropsFromRedux {
  clusterLabels: string;
  clusterColor: string;
  clusterId: number;
  clusterRequestId: number;
  destroyed?:boolean;
}

type JsonBody={
  labels: string;
  color: string;
}

export const ClusterAdditionalSettings = (props:Props) => {
  const [expanded, setExpanded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [clusterLabels, setClusterLabels] = useState(props.clusterLabels);
  const [colorPalette, setColorPalette] = useColor("hex", props.clusterColor);
  const [colorPaletteTemp, setColorPaletteTemp] = useColor(
    "hex",
    props.clusterColor
  );

  const classes = useStyles();

  const [t] = useTranslation();

  const renderClusterLabels = () => {
    let tagsComponent;
    if (clusterLabels?.length > 0) {
      tagsComponent = (
        <Grid container spacing={1} data-test="cluster-labels">
          {clusterLabels?.split(",").map((_tag, ind) => (
            <TooltipChip title={_tag} key={ind} data-test="cluster-label" />
          ))}
        </Grid>
      );
    } else {
      tagsComponent = (
        <Typography
          color="textSecondary"
          component="em"
          data-test="no-cluster-labels"
        >
          N/A
        </Typography>
      );
    }
    return tagsComponent;
  };

  const handleLabelsChange = (labels:string[]) => {
    setClusterLabels(labels.join(","));
  };

  useEffect(() => {
    if (!isEdit) {
      setClusterLabels(props.clusterLabels);
      setColorPaletteTemp(colorPalette);
    }
  }, [isEdit]);

  const handleUpdate = () => {
    props.updateClusterLabelsAndCluster(
      props.clusterId,
      {
        labels: clusterLabels,
        color: colorPaletteTemp.hex,
      },
      props.clusterRequestId
    );
  };

  useEffect(() => {
    setClusterLabels(props.clusterLabels);
    setColorPalette(colorPaletteTemp);
    setIsEdit(false);
  }, [props.clusterColor, props.clusterLabels]);

  return (
    <>
      <Card className="m-t-20" data-test="additionalSettings-container" elevation={expanded ? 1 : 13}>
        <CardHeader
          // className={classes.cardHeader}
          // classes={{ action: classes.cardHeaderAction }}
          title={
            <Typography variant="subtitle1" display="inline">
                {t("Cluster.ClusterAdditionalSettings.additionalSettings")}
            </Typography>
          }
          // avatar={
          //   <IconButton
          //     className={clsx(classes.expand, {
          //       [classes.expandOpen]: expanded,
          //     })}
          //     onClick={() => {
          //       setExpanded(!expanded);
          //       setIsEdit(false);
          //     }}
          //     data-test="expand-button"
          //   >
          //     <ExpandMoreIcon />
          //   </IconButton>
          // }
          action={
            <>
                {!props.destroyed &&
                expanded && (
                  <Tooltip
                    title={isEdit ? t("Cancel") : t("Edit")}
                    placement="left"
                    // className={classes.storageEditIcon}
                    data-test="edit-button-tooltip"
                  >
                    <IconButton
                      onClick={() => setIsEdit(!isEdit)}
                      className={isEdit ? classes.editIcon : ""}
                      data-test="edit-button"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={() => {
                    setExpanded(!expanded);
                    setIsEdit(false);
                  }}
                  data-test="expand-button"
                >
                  <ExpandMoreIcon />
                </IconButton>
            </>
          }
          data-test="additionalSettings-header"
        />

        <Collapse in={expanded}>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={3}>
                    <Typography color="primary" variant="h6">
                      {t("Cluster.ClusterAdditionalSettings.labels")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {!isEdit ? (
                      renderClusterLabels()
                    ) : (
                      <MultiValueChipInput
                        values={without(clusterLabels.split(","), "")}
                        handleValues={handleLabelsChange}
                        data-test="cluster-labels-input"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={3}>
                    <Typography color="primary" variant="h6">
                      {t("Cluster.ClusterAdditionalSettings.color")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {!isEdit ? (
                      props.clusterColor ? (
                        <Grid
                          container
                          alignItems="center"
                          spacing={2}
                          data-test="cluster-color-container"
                        >
                          <Grid item style={{ maxWidth: 100 }}>
                            <TextField
                              disabled
                              defaultValue={colorPalette.hex}
                              variant="outlined"
                              data-test="cluster-color-field"
                            />
                          </Grid>
                          <Grid item>
                            <span
                              style={{ backgroundColor: colorPalette.hex }}
                              className={classes.colorOutput}
                              data-test="cluster-color-display"
                            >
                            </span>
                          </Grid>
                        </Grid>
                      ) : (
                        <Typography
                          color="textSecondary"
                          component="em"
                          data-test="no-cluster-color"
                        >
                          N/A
                        </Typography>
                      )
                    ) : (
                      <ColorPicker
                        width={280}
                        height={164}
                        color={colorPaletteTemp}
                        onChange={setColorPaletteTemp}
                        hideHSV
                        hideRGB
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {isEdit && (
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                      <Button
                        onClick={() => setIsEdit(false)}
                        color="primary"
                        variant="contained"
                        size="large"
                        style={{ width: "100%" }}
                        data-test="cancel-button"
                      >
                        {t("Cancel")}
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Button
                        onClick={handleUpdate}
                        color="primary"
                        variant="contained"
                        size="large"
                        style={{ width: "100%" }}
                        data-test="update-button"
                      >
                        {t("Cluster.ClusterAdditionalSettings.update")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
      {props.updatingClusterLabelsAndColor && (
        <BackdropLoader
          message={t("Cluster.ClusterAdditionalSettings.updating")}
        />
      )}
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  updatingClusterLabelsAndColor:
    state.ClusterReducer.updatingClusterLabelsAndColor,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    updateClusterLabelsAndCluster: (clusterId:number, jsonBody:JsonBody, clusterRequestId:number) =>
      dispatch(
        updateClusterLabelsAndCluster(clusterId, jsonBody, clusterRequestId)
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ClusterAdditionalSettings);
