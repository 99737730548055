import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
//import { SkeletonTheme } from "react-loading-skeleton";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchField from "../searchfield/SearchField";
import OperatorCollapsibleCard from "./OperatorCollapsibleCard";
import OperatorForm from "./OperatorForm";
import { connect } from "react-redux";
import {
  fetchAvailableOperators,
  fetchOperatorDetails,
  clearOperatorDetails,
} from "../../pages/operators/redux/actions";
import { List, AutoSizer } from "react-virtualized";
import { ClusterDetailsModel } from "../../models/Cluster.model";
import { OperatorClass } from "../../models/Operator.model";
import { ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import "./AddOperatorPopup.css";
import { Transition } from "./../../helpers/utils.ext";
import { Dispatch } from "redux";
/* istanbul ignore next */
// const transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

interface Props extends PropsFromRedux {
  open: boolean;
  handleClose: () => void;
  cluster: ClusterDetailsModel;
  operator: OperatorClass;
}

export const AddOperatorPopup = (props: Props) => {
  const {
    open,
    handleClose,
    cluster,
    availableOperators,
    fetchingAvailableOperators,
    operatorDetails,
  } = props;

  const [searchText, setSearchText] = useState("");
  const [selectedOperator, setSelectedOperator] =
    useState<null | OperatorClass>(null);
  const [filteredOperators, setFilteredOperators] = useState<
    OperatorClass[] | null
  >(null);
  const [activePackageName, setActivePackageName] = useState<string | null>(
    null
  );
  const [operatorsList, setOperatorsList] = useState<OperatorClass[]>([]);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [t] = useTranslation();

  const list = useRef<any>();

  //1=operator-info, 2=operator-deploy
  const [mode, setMode] = useState(1);

  const handleSearch = (st: string) => {
    setSelectedIndex(-1);
    setActivePackageName(null);
    if (st) {
      const searchedOperators = availableOperators.filter(
        (operator: OperatorClass) =>
          operator.packageName.toLowerCase().includes(st.toLowerCase()) ||
          operator.displayName.toLowerCase().includes(st.toLowerCase())
      );
      setFilteredOperators(searchedOperators);
    } else {
      setFilteredOperators(null);
    }
  };

  const handleSearchChange = (str: string) => setSearchText(str);

  const handleOperatorDeploy = (operator: OperatorClass) => {
    setSelectedOperator(operator);
    setMode(2);
  };

  const handleDeployBack = () => {
    setSelectedOperator(null);
    setMode(1);
  };

  const handlePopupClose = () => {
    handleDeployBack();
    handleClose();
  };

  const handleOperatorCardExpand = (
    packageName: string,
    isOperatorDetailFetched: boolean,
    listIndex: number
  ) => {
    props.clearOperatorDetails();
    if (packageName === activePackageName) {
      setActivePackageName(null);
      setSelectedIndex(-1);
    } else {
      setActivePackageName(packageName);
      setSelectedIndex(listIndex);

      if (!isOperatorDetailFetched) {
        props.fetchOperatorDetails({ packageName });
      }
    }
  };

  useEffect(() => {
    if (list.current) {
      list.current.recomputeRowHeights();
      list.current.forceUpdate();
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (operatorDetails) {
      setSelectedOperator({ ...operatorDetails });
    }
  }, [operatorDetails]);

  useEffect(() => {
    if (open && !availableOperators) {
      props.fetchAvailableOperators();
    }
  }, [open]);

  useEffect(() => {
    if (filteredOperators) {
      return setOperatorsList(
        filteredOperators.length ? [...filteredOperators] : []
      );
    }

    if (availableOperators?.length) {
      setOperatorsList([...availableOperators]);
    }
  }, [availableOperators, filteredOperators]);

  const _getRowHeight = ({ index }: { index: number }) =>
    index === selectedIndex ? 360 : 105;

  const rowRenderer = ({
    index,
    key,
    style,
  }: {
    index: number;
    key: React.Key;
    style: any;
  }) => {
    return (
      <div key={key} style={style}>
        <OperatorCollapsibleCard
          operator={operatorsList[index]}
          handleOperatorDeploy={handleOperatorDeploy}
          handleCardExpand={handleOperatorCardExpand}
          activePackageName={activePackageName}
          listIndex={index}
          data-test="operator-card"
        />
      </div>
    );
  };

  const RenderOperators = () => {
    if (filteredOperators && operatorsList?.length) {
      return (
        <AutoSizer data-test="filtered-list">
          {({ width, height }) => (
            <List
              rowCount={operatorsList.length}
              width={width}
              height={height}
              rowHeight={_getRowHeight}
              rowRenderer={rowRenderer}
              overscanRowCount={3}
              ref={list}
            />
          )}
        </AutoSizer>
      );
    }

    if (filteredOperators && !operatorsList?.length) {
      return (
        <div className="no-search-result" data-test="no-search-result">
          {t("Operators.AddOperatorPopup.noOperator")}
        </div>
      );
    }

    return (
      <AutoSizer data-test="unfiltered-list">
        {({ width, height }) => (
          <List
            rowCount={operatorsList.length}
            width={width}
            height={height}
            rowHeight={_getRowHeight}
            rowRenderer={rowRenderer}
            overscanRowCount={3}
            ref={list}
          />
        )}
      </AutoSizer>
    );
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handlePopupClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      data-test="add-operator-dialogue"
      className="add-operator-popup-container"
    >
      <DialogTitle>
        <div className="dialogue-title-container">
          <Typography  variant="h6" data-test="dialogue-title">
            {t("Operators.AddOperatorPopup.installOperator")}
          </Typography>

          <IconButton
            aria-label="close"
            size="small"
            onClick={handlePopupClose}
            data-test="close-popup"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className="dailogue-content">
        <div className="action-container">
          {mode === 2 && (
            <Button
              onClick={handleDeployBack}
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<ArrowBackIcon />}
              data-test="deploy-back"
            >
              {t("Cluster.ClusterList.backToList")}
            </Button>
          )}
        </div>
        <div
          className={mode === 1 ? "" : "d-none"}
          data-test="available-operators-list"
        >
          <Grid
            container
            style={{ justifyContent: "space-between" }}
            alignItems="center"
            className="dialogue-content-title"
          >
            <Grid item xs={5} sm={5}>
              <Typography className="dialogtitle" variant="h6">
                {t("Operators.OperatorCatalog.operators")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              sm={5}
              style={availableOperators?.length ? {} : { visibility: "hidden" }}
            >
              <SearchField
                label={t("Operators.OperatorCatalog.search")}
                search={searchText}
                handleSearchChange={handleSearchChange}
                handleSearch={handleSearch}
                data-test="search-field"
              />
            </Grid>
          </Grid>
          <div className="operator-list">
            <> {!fetchingAvailableOperators && RenderOperators()}</>

            {fetchingAvailableOperators && (
              <div
                className="skeleton-container"
                data-test="available-operators-skeleton"
              >
                {[0, 1, 2, 3, 4, 5, 6].map(idx => {
                  return (
                    <Paper className="skeleton-item" key={idx}>
                      {/* <SkeletonTheme height={75}> */}
                        <Grid container>
                          <Grid item xs={2}>
                            <Skeleton variant="rect" width={50} height={50} />
                          </Grid>
                          <Grid item xs={10}>
                            <div>
                              <Skeleton
                                animation="wave"
                                height={20}
                                style={{ marginBottom: 6 }}
                                width="40%"
                              />
                            </div>
                            <div>
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="40%"
                              />
                            </div>
                          </Grid>
                        </Grid>
                      {/* </SkeletonTheme> */}
                    </Paper>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {mode === 2 && selectedOperator && (
          <OperatorForm
            operator={selectedOperator}
            clusterId={cluster.id}
            handlePopupClose={handlePopupClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  availableOperators: state.OperatorReducer.availableOperators,
  fetchingAvailableOperators: state.OperatorReducer.fetchingAvailableOperators,
  operatorDetails: state.OperatorReducer.operatorDetails,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => ({
  fetchOperatorDetails: ({ packageName }: { packageName: string }) =>
    dispatch(fetchOperatorDetails({ packageName })),
  fetchAvailableOperators: () => dispatch(fetchAvailableOperators()),
  clearOperatorDetails: () => dispatch(clearOperatorDetails()),
});

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AddOperatorPopup);
