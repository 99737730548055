import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography ,Button} from "@material-ui/core";
import { fetchPods, clearPods, restartPod } from "./redux/actions";
import { connect, ConnectedProps } from "react-redux";
import { PodsOverviewCard } from "../../components/pods/";
import PodInfo from "./PodInfo";
import BackdropLoader from "../../components/loader/BackdropLoader";
import Alert from '@material-ui/lab/Alert';
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Pod } from "../../models/Environment.model";
import { Dispatch } from "redux";
import { Skeleton } from "@material-ui/lab";
import { makeStyles,createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import NoContentImage from "../../components/nocontentimagecontainer/NoContentImage";

const useStyles = makeStyles((theme: Theme) =>createStyles ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3)
  },
  typoGrapy:{ verticalAlign: "baseline" }
}));

interface Props extends PropsFromRedux {
  environmentId: number;
  isHelmEnv: boolean;
}

export const PodsOverview = (props: Props) => {
  const classes = useStyles();
  const [mode, setMode] = useState(1); // 1: list, 2: info
  const [selectedPod, setSeletectedPod] = useState<Pod | null>(null);
  const [showHelperText,setShowHelperText]=useState(false);
  const [restartAlert, setRestartAlert] = useState(false);

  const [t] = useTranslation();

  const handlePodSelect = (pod: Pod) => {
    setSeletectedPod(pod);
    setMode(2);
  };

  const handleInfoBack = (updateAction?:boolean) => {
  if(updateAction){
    setShowHelperText(updateAction)
  }
    setSeletectedPod(null);
    setMode(1);    
  };

  const getPodRestartCount = (pod: Pod) => {
    if (!pod.status?.containerStatuses) {
      return 0;
    }

    return pod.status.containerStatuses.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.restartCount,
      0
    );
  };

  useEffect(() => {
    if (props.environmentId) {
      props.fetchPods(props.environmentId, props.isHelmEnv);
    }

    return () => {
      props.clearPods();
    };
  }, [props.environmentId]);

  const refreshPodList = () => {
    setRestartAlert(false);
    setShowHelperText(false)
    props.fetchPods(props.environmentId, props.isHelmEnv);
  };

  const podCallBack = () => {
    setRestartAlert(true);
  }

  const handleRestartPod = (envId: number, jsonBody: any) => {
    props.restartPod(envId, jsonBody, podCallBack);
  }

  return mode === 1 ? (
    <div data-test="pods-overview-container">
 
      <Grid container direction="column" spacing={2}>
        {!props.fetchingPods ? (
          <>
            <div className={classes.headerContainer}>
              <Typography
                className={classes.typoGrapy}
                color="textPrimary"
                variant="h4"
              >
                {t("Pods.availableIntances")}
              </Typography>
              {(showHelperText || restartAlert) && <Alert severity="info">
                <Typography variant="subtitle1"> {showHelperText ? 'Click the "REFRESH" button to view the changes you\'ve made to the pods.':
                "Pod Restart Initiated - Click REFRESH after few seconds!"}</Typography>
              </Alert>}
              <Button
                onClick={refreshPodList}
                variant="contained"
                disableElevation
                disableFocusRipple
                disableTouchRipple
                color="primary"
                aria-haspopup="true"
              >
                {t("Environment.EnvironmentInfo.Refresh")}
              </Button>
            </div>

            {props.podsList && props.podsList.length > 0 ? (
              props.podsList?.map((pod: Pod, index: number) => (
                <PodsOverviewCard
                  podDetail={pod}
                  key={index}
                  handleClick={handlePodSelect}
                  restartCount={getPodRestartCount(pod)}
                  handleRestartPod={handleRestartPod}
                  envId = {props.environmentId}
                  data-test="pod-item"
                />
              ))
            ) : (
              <NoContentImage
                message={
                  <div>
                    <Typography variant="subtitle1">
                      No Any Available Instance
                    </Typography>
                  </div>
                }
                alt="No Instance"
                type={"env"}
              />
            )}
          </>
        ) : (
          [0, 1, 2, 3].map((idx) => {
            return (
              <Paper
                className="skeleton-item m-b-20"
                key={idx}
                data-test="skeleton-items"
              >
                {/* <SkeletonTheme height={97}> */}
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} style={{ padding: "10px" }}>
                    <Skeleton animation="wave" height={30} width="70%" />
                  </Grid>

                  <Grid item xs={12} style={{ padding: "10px" }}>
                    <Grid container spacing={2}>
                      <Grid item md={3} sm={4} xs={6}>
                        <Skeleton
                          animation="wave"
                          height={10}
                          style={{ marginBottom: 6 }}
                          width="100%"
                        />
                      </Grid>
                      <Grid item md={3} sm={4} xs={6}>
                        <Skeleton
                          animation="wave"
                          height={10}
                          style={{ marginBottom: 6 }}
                          width="100%"
                        />
                      </Grid>
                      <Grid item md={3} sm={4} xs={6}>
                        <Skeleton
                          animation="wave"
                          height={10}
                          style={{ marginBottom: 6 }}
                          width="100%"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* </SkeletonTheme> */}
              </Paper>
            );
          })
        )}
      </Grid>
      { props.fetchingPods && (
        <BackdropLoader message={t("Pods.fetchingPods")} />
      )}
    </div>
  ) : (
    <>
      {selectedPod && (
        <PodInfo
          podInfo={selectedPod}
          environmentId={props.environmentId}
          handleBack={handleInfoBack}
          restartCount={getPodRestartCount(selectedPod)}
          isHelmEnv={props.isHelmEnv}
        />
      )}
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
  return {
    podsList: state.PodsReducer.podsList,
    fetchingPods: state.PodsReducer.fetchingPods
  
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchPods: (envId: number, isHelmEnv: boolean) => dispatch(fetchPods(envId, isHelmEnv)),
    clearPods: () => dispatch(clearPods()),
    restartPod: (envId: number, jsonBody:any, callBack:() => void) => dispatch(restartPod(envId, jsonBody, callBack)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PodsOverview);
