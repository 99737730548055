import {
    Card,
    CardActions,
    CardContent,
    //Checkbox,
    Divider,
    //FormControl,
    // FormControlLabel,
    Button,
    Popover,
    Link,
    //RadioGroup,
    // Switch,
    Typography,
    Grid,
    CardHeader,
    //Radio,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
//import InfoIcon from '@material-ui/icons/Info';
import React, { Component } from 'react';
import { withTranslation,WithTranslation } from "react-i18next";
import { connect,ConnectedProps } from 'react-redux';
import DeploymentStrategy from '../../../../../components/cddeployment/DeploymentStrategy';
import ConfirmActionPopup from '../../../../../components/confirmactionpopup/ConfirmActionPopup';
//import EmailInput from "../../../../../components/emailinput/EmailInput";
import BackdropLoader from '../../../../../components/loader/BackdropLoader';
import { CreateApp_TemplateTypes } from '../../../../../constants/enums';
//import MuiTextField from '../../../../../components/textfield/MuiTextField';
import { isAuthorized } from '../../../../../helpers/utils';
import { updateEnvironment } from '../../../redux/actions'
import { Dispatch } from 'redux';
import { WithStyles, createStyles } from "@material-ui/core";
import { IdeploymentStrategy ,DeploymentValue} from '../../../../../models/Environment.model';
import CDChangePopup from '../../../../../components/cdchangepopup/CDChangePopup';
/* istanbul ignore next */
const useStyles = () => createStyles({
    branchInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    paper: {
        maxWidth: 400,
        padding: 10,
        borderRadius: 9
    }
});

interface Iprops extends PropsFromRedux,WithStyles,WithTranslation{
    handleCDChange:()=>void;
    handleAgree: any
}
type Tdata={
    isPush: boolean;
    }
interface Istate {
    isPush:                    boolean;
    isTag:                     boolean;
    isConfirmSettingsSaveOpen: boolean;
    anchorEl: Element | ((element: Element) => Element) | null;
    popOverType:               number;
    oldData:                   Partial<Tdata>;
    deploymentErrors:          any; //[]

    deployment_strategy:       Partial<IdeploymentStrategy>;
}
export class CDSettingsTab extends Component<Iprops,Istate> {
    constructor(props:Iprops) {
        super(props);
        this.state = {
            isPush: false,
            isTag: false,
            // event_type: "all",
            // email_notification: false,
            // emails: [],
            // slack_notification: false,
            // slack_webhook_url: '',
            // webhook_notification: false,
            // webhook_url: '',
            // webhook_token: '',
            isConfirmSettingsSaveOpen: false,
            anchorEl: null,
            popOverType: 0,
            oldData: {},
            deploymentErrors: [],
            deployment_strategy: {},
        }
    }

    componentDidMount() {
        const eventsArray = this.props.cdSettings && this.props.cdSettings.events ? this.props.cdSettings.events.split(',') : [];
        const oldData = {
            isPush: eventsArray.includes("push"),
            //isTag: eventsArray.includes("release"),
            // event_type: this.props.cicdSettings.event_type ? this.props.cicdSettings.event_type : "all",
            // email_notification: this.props.cicdSettings.email_notification ?? false,
            // emails: this.props.cicdSettings.emails ? this.props.cicdSettings.emails.split(',') : [],
            // slack_notification: this.props.cicdSettings.slack_notification ?? false,
            // slack_webhook_url: this.props.cicdSettings.slack_webhook_url ?? "",
            // webhook_notification: this.props.cicdSettings.webhook_notification ?? false,
            // webhook_url: this.props.cicdSettings.webhook_url ?? "",
            // webhook_token: this.props.cicdSettings.webhook_token ?? "",
        }
        this.setState({
            oldData
        })
    }

    // componentWillUnmount() {
    //     //this.props.clearCDSettings();
    // }

    UNSAFE_componentWillReceiveProps = (newProps:Iprops) => {
        if (newProps.cdSettings) {
            if (JSON.stringify(this.props.cdSettings) !== JSON.stringify(newProps.cdSettings)) {
                const eventsArray = newProps.cdSettings.events ? newProps.cdSettings.events.split(',') : [];
                const oldData = {
                    isPush: eventsArray.includes("push"),
                    // isTag: eventsArray.includes("release"),
                    // event_type: newProps.cicdSettings.event_type ? newProps.cicdSettings.event_type : "all",
                    // email_notification: newProps.cicdSettings.email_notification,
                    // emails: newProps.cicdSettings.emails ? newProps.cicdSettings.emails.split(',') : [],
                    // slack_notification: newProps.cicdSettings.slack_notification,
                    // slack_webhook_url: newProps.cicdSettings.slack_webhook_url,
                    // webhook_notification: newProps.cicdSettings.webhook_notification,
                    // webhook_url: newProps.cicdSettings.webhook_url,
                    // webhook_token: newProps.cicdSettings.webhook_token,
                }
                this.setState({
                    isPush: eventsArray.includes("push"),
                    // isTag: eventsArray.includes("release"),
                    // event_type: newProps.cicdSettings.event_type ? newProps.cicdSettings.event_type : "all",
                    // email_notification: newProps.cicdSettings.email_notification,
                    // emails: newProps.cicdSettings.emails ? newProps.cicdSettings.emails.split(',') : [],
                    // slack_notification: newProps.cicdSettings.slack_notification,
                    // slack_webhook_url: newProps.cicdSettings.slack_webhook_url,
                    // webhook_notification: newProps.cicdSettings.webhook_notification,
                    // webhook_url: newProps.cicdSettings.webhook_url,
                    // webhook_token: newProps.cicdSettings.webhook_token,
                    oldData,
                })
            }
        }
    }
    
    /* istanbul ignore next */
    // handlePushTrigger = (e) => {
    //     this.setState({
    //         isPush: e.target.checked
    //     })
    // }

    handleSave = () => {
        this.setState({
            isConfirmSettingsSaveOpen: true
        })
    }

    disagreeSettingsSaveHandler = () => {
        this.setState({
            isConfirmSettingsSaveOpen: false
        })
    }

    agreeSettingsSaveHandler = () => {
        // let _events = this.state.isPush ? "push" : '';
        // if (this.state.isTag)
        //     _events = (_events ? _events + "," : '') + "release";

        const jsonBody:{
            deployment_strategy: Partial<IdeploymentStrategy>;
        } = {
            // events: _events,
            deployment_strategy: this.state.deployment_strategy
            // event_type: this.state.event_type,
            // email_notification: this.state.email_notification,
            // emails: this.state.email_notification ? this.state.emails.join(",") : "",
            // slack_notification: this.state.slack_notification,
            // slack_webhook_url: this.state.slack_notification ? this.state.slack_webhook_url : "",
            // webhook_notification: this.state.webhook_notification,
            // webhook_url: this.state.webhook_notification ? this.state.webhook_url : "",
            // webhook_token: this.state.webhook_token
        }
        this.props.updateEnvironment(this.props.environmentDetails.id, jsonBody);
        this.setState({
            isConfirmSettingsSaveOpen: false
        })
    }
    
    /* istanbul ignore next */
    // handleMouseOver = (e, popType) => {
    //     this.setState({
    //         anchorEl: e.currentTarget,
    //         popOverType: popType
    //     });
    // }

    // handleMouseOut = (e) => {
    //     this.setState({
    //         anchorEl : null
    //       });
    // }

    handlePopoverClose = (e:{}) => {
        this.setState({ anchorEl: null });
    };

    /* istanbul ignore next */
    // testEmail = () => {
    //     this.props.testEmail(this.props.environmentDetails?.id)
    // }

    /* istanbul ignore next */
    // testSlack = () => {
    //     // if(this.state.emails?.trim()?.length > 0){
    //     this.props.testSlack(this.props.environmentDetails?.id)
    //     // }
    // }

    /* istanbul ignore next */
    // testWebhook = () => {
    //     // if(this.state.emails?.trim()?.length > 0){
    //     this.props.testWebhook(this.props.environmentDetails?.id)
    //     // }
    // }

    /* istanbul ignore next */
    // handleEventType = (event) => {
    //     this.setState({ event_type: event.target.value })
    // }
    //isFormValid

    isError = () => {
        let error = false
        // if (this.state.slack_notification) {
        //     error = error || this.state.errors.slack_webhook_url || this.state.slack_webhook_url?.trim()?.length <= 0
        // }
        // if (this.state.webhook_notification) {
        //     error = error || this.state.errors.webhook_url || this.state.errors.webhook_token || this.state.webhook_url?.trim()?.length <= 0 || this.state.webhook_token?.trim()?.length <= 0
        // }
        // if (this.state.email_notification) {
        //     error = error || this.state.errors.emails || this.state.emails?.length <= 0
        // }
        return error
    }

    /* istanbul ignore next */
    hasChanged = () => {
        if (Object.keys(this.state.oldData).length > 0) {
            const newData = {
                isPush: this.state.isPush,
                // isTag: this.state.isTag,
                // event_type: this.state.event_type,
                // email_notification: this.state.email_notification,
                // emails: this.state.emails,
                // slack_notification: this.state.slack_notification,
                // slack_webhook_url: this.state.slack_webhook_url,
                // webhook_notification: this.state.webhook_notification,
                // webhook_url: this.state.webhook_url,
                // webhook_token: this.state.webhook_token,
            }

            let changed = false
            Object.keys(this.state.oldData).forEach((k:string) => {
                if (JSON.stringify(newData[k as keyof typeof newData]) !== JSON.stringify(this.state.oldData[k as keyof Tdata])) {
                    changed = true
                    return
                }
            })
            return changed
        }
    }

    handleDeploymentTypeChange = (payload:DeploymentValue, deploymentType:string, err:any) => {
        this.setState({
            deployment_strategy: {[deploymentType]: payload},
            deploymentErrors: err
        })

    }

    render() {
        const { classes, environmentDetails, envRole, t } = this.props

        return (
            <Card elevation={1} className='ciSettings'>
                <CardHeader
                    title={<Typography variant='h6'>{t("Environment.SettingsTab.cdSettings")}</Typography>}
                    subheader={<Typography variant='body2' color='textSecondary'>{t("Environment.SettingsTab.manageCD")}</Typography>}
                    action={
                        this.props.cdSettingsError !== 401 &&
                        <>
                            
                            {
                                isAuthorized("update", envRole.name) && (
                                        <Button color="primary" 
                                            disabled={this.state.deploymentErrors?.length>0 }
                                            variant="contained"
                                            onClick={() => this.handleSave()}
                                            data-test="save-button"
                                        >
                                            {t('Environment.CDSettingsTab.save')}
                                        </Button>
                                )
                            }
                        </>
                    }
                />
                <Divider />
                <CardContent>
                <form>
                    {
                        this.props.cicdSettingsError !== 401 &&
                        <Grid container spacing={1}>
                            <Grid item md={4} data-test="branch-info">
                                {/* <AccountTreeOutlinedIcon color="primary" />
                                <Typography variant="h5" className='oneRemLeftMarginSeperator' data-test="branch-name">
                                    { this.props.environmentDetails?.service_type === CreateApp_TemplateTypes.git ? environmentDetails?.git_branch : environmentDetails?.image_tag}
                                </Typography>
                                {this.props.environmentDetails?.service_type ===
                                    CreateApp_TemplateTypes.cr &&
                                    isAuthorized("update", envRole.name) && (
                                        <Button
                                        color="primary"
                                        variant="contained"
                                        className="oneRemLeftMarginSeperator"
                                        onClick={this.props.handleCDChange}
                                        >
                                        {t("Environment.SettingsTab.change")}
                                        </Button>
                                    )} */}
                                {this.props.environmentDetails?.service_type === CreateApp_TemplateTypes.cr && isAuthorized("update", envRole.name) && (<CDChangePopup handleAgree={this.props.handleAgree} />)}
                            </Grid>
                            <DeploymentStrategy 
                                handleChange={(val,type, err) => {
                                    this.handleDeploymentTypeChange(val,type, err)
                                }}
                                deployment = {this.props.environmentDetails?.deployment_strategy}
                            />
                        </Grid>

                    }
                    {
                        this.props.cdSettingsError === 401 &&
                        <Typography variant='h5' data-test="cd-error-message">{t('Environment.CDSettingsTab.permissionError')}</Typography>
                    }
                </form>
                </CardContent>
                <Popover
                    id='webhook_pop'
                    open={Boolean(this.state.anchorEl)}
                    classes={{
                        paper: classes.paper,
                    }}
                    anchorEl={this.state.anchorEl}
                    onClose={(e) => this.handlePopoverClose(e)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {
                        this.state.popOverType === 1 &&
                        <Typography variant='h5'>
                        {t('Environment.CDSettingsTab.createUrl')} <Link href='https://api.slack.com/messaging/webhooks#create_a_webhook' target="_blank" rel="noreferrer" underline='hover'>{t('Environment.CDSettingsTab.slack')} </Link>
                        </Typography>
                    }
                    {
                        this.state.popOverType === 2 &&
                        <Typography variant='h5'>
                        {t('Environment.CDSettingsTab.hmacError')}  <Link href='#' target="_blank" rel="noreferrer" underline='hover'>{t('Environment.CDSettingsTab.readMore')} </Link>
                        </Typography>
                    }
                </Popover>
                <ConfirmActionPopup open={this.state.isConfirmSettingsSaveOpen} handleAgree={this.agreeSettingsSaveHandler} handleDisAgree={this.disagreeSettingsSaveHandler} message={t('Environment.CDSettingsTab.update')}  yesText={t('Environment.CDSettingsTab.yesText')}  noText={t('Environment.CDSettingsTab.noText')}  />
                {this.props.updatingEnv && <BackdropLoader message={t('Environment.CDSettingsTab.updatingSettings')}  />}
            </Card>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    envRole: state.EnvironmentReducer.envRole,
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    updatingEnv: state.EnvironmentReducer.updatingEnv,
    //testing: state.CICDReducer.testing,
    cdSettings:state.CICDReducer.cdSettings,
    cicdSettingsError:state.CICDReducer.cicdSettingsError,
    cdSettingsError: state.CICDReducer.cdSettingsError,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => { //dispatch
    return {
        updateEnvironment : (id:number, jsonBody:{
            deployment_strategy: Partial<IdeploymentStrategy>;
        },source?:number, changeHash?:(hash:string,value:number,envId?:number,delay?:number)=>void) => dispatch(updateEnvironment(id, jsonBody, source, changeHash)),
        // fetchCDSettings: (id) => dispatch(fetchCDSettings(id)),
        // clearCDSettings: () => dispatch(clearCDSettings()),
        // testEmail: (eId) => dispatch(testEmail(eId)),
        // testSlack: (eId) => dispatch(testSlack(eId)),
        // testWebhook: (eId) => dispatch(testWebhook(eId)),
    }
}
const connector=connect(
    mapStateToProps,
    mapDispatchtoProps
)
export default connector(withStyles(useStyles)(withTranslation()(CDSettingsTab)))
type PropsFromRedux=ConnectedProps<typeof connector>