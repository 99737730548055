import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Button, Card, CardActions, CardContent,CardHeader, Divider, Grid, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ExternalLogger from "../../../createenvironment/generals/ExternalLogger";
import { fetchLoggerConfig, updateExternalLogger } from "../../../redux/actions";
import KeyValueRow from "../../../../../components/keyvaluerow/KeyValueRow";
import { AppConstants } from "../../../../../constants/appconstants";
import { LoggerModel } from "../../../../../models/Environment.model";
import CustomButton from "../../../../../components/custombutton/CustomButton";
import { isAuthorized } from "../../../../../helpers/utils";
import { ClassNameMap } from "@material-ui/styles";
/* istanbul ignore next */
const useStyles = makeStyles({
  // secret: {
  //   fontSize: 16,
  //   fontWeight: 700,
  //   color: "#039be5",
  // },
  BtnGroup: {
    padding: "10px",
    marginTop: "30px",
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    marginLeft: "20px",
  },
});

interface Props extends PropsFromRedux {}

function ExternalLoggerTab(props: Props) {
  const classes = useStyles();

  const { environmentDetails, updateExternalLogger,envRole } = props;

  const [edit, setEdit] = useState(false);
  const [externalLogger, setExternalLogger] = useState<Partial<LoggerModel>>({});
  const [enable, setEnable] = useState(false);

  useEffect(()=>{
    props.fetchLoggerConfigDetails();
  },[])

  const updatedExternalLogger = (updatedState: any) => {

    setExternalLogger(()=>{
      return {...updatedState }
    })
  }

  const handleEditLogger = () => {
    setEdit(true);
  };

  const handleUpdateLogger = () => {
    const id = environmentDetails.id;
    const external_logging = externalLogger;
    updateExternalLogger(id, external_logging);
    setEdit(false);
    setEnable(false);
  };

  const handleEnableLogger = () => {
    setEnable(true)
  }

  const handleCancel = () => {
    if(!edit){
      setEnable(false);
    }
    setEdit(false);
  }

  interface ActionProps{
    classObj:ClassNameMap<"BtnGroup" | "btn">
  }
  const ButtonActions = ({ classObj }: ActionProps) => {
    return (
      <CardActions className={classObj.BtnGroup}>
        <div style={{display: 'flex', gap: 24 }}>
            <CustomButton
              onClick={() => handleCancel()}
              type="danger"
              label={"cancel"}
            />
            <CustomButton
              customClass={classObj.btn}
              onClick={() => handleUpdateLogger()}
              label={"update"}
            />
        </div>
      </CardActions>
    );
  };

  return (
    <>
      {edit === false ?
        <>
          <Card className="m-t-20" data-test="system-var-card">
            <CardHeader title={
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h6">External Logger</Typography>
                </Grid>
                <Grid item>
                  {
                    environmentDetails.external_logging && environmentDetails.external_logging.enabled ?
                      <Button color="primary" variant="contained" startIcon={<EditIcon />}
                        onClick={() => handleEditLogger()}
                        disabled={!isAuthorized("create", envRole.name)}
                      >
                        Edit Logger
                      </Button> :
                      !enable &&
                      <Button color="primary" variant="contained"
                          onClick={() => handleEnableLogger()}
                          disabled={!isAuthorized("create", envRole.name)}
                      >
                        Enable Logger
                      </Button>
                  }
                </Grid>
              </Grid>
            } />
            <Divider />
            <CardContent>
              {
                environmentDetails.external_logging && environmentDetails.external_logging.enabled ?
                  <>
                    {
                      environmentDetails.external_logging?.provider && <KeyValueRow
                        rowKey="Provider"
                        rowValue={environmentDetails.external_logging?.provider}
                        variant={"h5"}
                      />
                    }
                    {
                      environmentDetails.external_logging.additional_setting?.timekey && <KeyValueRow
                        rowKey="Refresh Interval"
                        rowValue={environmentDetails.external_logging.additional_setting?.timekey}
                        variant={"h5"}
                      />
                    }
                   
                    {environmentDetails.external_logging?.provider === AppConstants.externalLoggerProviders.s3 &&
                      <>
                        <KeyValueRow
                          rowKey="Bucket"
                          rowValue={environmentDetails.external_logging.aws_credential?.bucket}
                          variant={"h5"}
                        />
                        <KeyValueRow
                          rowKey="Region"
                          rowValue={environmentDetails.external_logging.aws_credential?.region}
                          variant={"h5"}
                        />
                        {/* <KeyValueRow
                          rowKey="Status"
                          rowValue={environmentDetails.external_logging.enabled?"Enabled":"Disabled"}
                          variant={"h5"}
                        /> */}
                      </>
                    }
                  </> :
                  !enable ?
                    <Typography variant="body2" align="center">Please enable External Logger</Typography> :
                    <ExternalLogger
                      enableLogger={true}
                      externalLogger={externalLogger}
                      updatedExternalLogger={updatedExternalLogger}
                      loggerConfig={props.loggerConfig}
                    />
              }
            </CardContent>
            {
              enable &&
              <ButtonActions classObj={classes}/>
            }
          </Card>
        </> :
        (
          <Card>
            <CardHeader
              title="External Logger Update"
            />
            <Divider />
            <CardContent>
              <ExternalLogger
                edit={edit}
                externalLogger={environmentDetails.external_logging}
                updatedExternalLogger={updatedExternalLogger}
                loggerConfig={props.loggerConfig}
              />
            </CardContent>
            <ButtonActions classObj={classes}/>
          </Card>
        )
      }
    </>
  )
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
      updateExternalLogger: (id: number, external_logging: any) =>dispatch(updateExternalLogger(id, external_logging)),
      fetchLoggerConfigDetails: () => dispatch(fetchLoggerConfig()),
    };
  };
  
  /* istanbul ignore next */
  const mapStateToProps = (state: any) => {
    return {
      environmentDetails: state.EnvironmentReducer.environmentDetails,
      envRole: state.EnvironmentReducer.envRole,
      loggerConfig: state.EnvironmentReducer.loggerConfig,
    };
  };
  
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ExternalLoggerTab);