import React from "react";
import {
  CardHeader,
  Typography,
  Card,
  Divider,
  Grid,
  Box,
  Paper,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import { Quotas } from "../../models/Account.model";
import { CustomColors } from "../../constants/enums";

const useStyles = makeStyles(() => ({
  quotoContainer:{
    padding: 24
  },
  quotaIndicater:{
    backgroundColor: CustomColors.Info.Lighter, 
    color: CustomColors.Info.Dark, 
    padding: 6, 
    borderRadius: 6, 
    marginLeft: 8
  }
}))
interface Props {
  quotaInfo: Quotas;
}
export const QuotaInfo = (props: Props) => {
  const classes = useStyles();
  return (
    // <Card style={{maxWidth:500}}>
    //   <CardHeader title={<Typography variant="h4">Quota</Typography>} />

    //   <Divider />

    //   <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
    //     <ul>
    //       <li>
    //         <Typography variant="subtitle1">
    //           <span style={{ fontWeight: "bold" }}>Organization Quota</span> :{" "}
    //           {props.quotaInfo.user_organization}
    //         </Typography>{" "}
    //       </li>
    //       <li>
    //         <Typography variant="subtitle1">
    //           <span style={{ fontWeight: "bold" }}>Project Quota</span> :{" "}
    //           {props.quotaInfo.user_project}
    //         </Typography>
    //       </li>
    //     </ul>
    //   </div>
    // </Card>
    <Card>
      <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box component={Paper} className={classes.quotoContainer}>
                  <Typography variant="subtitle2">
                    Organization Quota
                    <span className={classes.quotaIndicater}>{props.quotaInfo.user_organization}</span>
                  </Typography>{" "}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component={Paper} className={classes.quotoContainer}>
                <Typography variant="subtitle2">
                  Project Quota
                  <span className={classes.quotaIndicater}>{props.quotaInfo.user_project}</span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
      </CardContent>
  </Card>
  );
};
