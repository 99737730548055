import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { StaticContext, RouteComponentProps } from "react-router";
import {
  Button,
  Typography,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Step,
  StepButton,
  Stepper,
  Grid,
} from "@material-ui/core";
import CreateClusterSection from "./createClusterForm";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { sessionTokenSelector } from "../../../login/redux/selectors";
import { getvClusterSubs } from "../../../../services/ClusterService";
import { validateTokenCall } from "../../../../services/ClusterService";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import palette from "../../../../theme/palette";
const imagePath = '/images/icons/cluster-manager.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  backButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  validationSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: 10,
    //width: "50%",
    minWidth: 320,
    margin: "auto",
  },

  validationHeader: {
    marginBottom: 10,
  },
  alert: {
    width: "80%",
    
  },
  errorSection: {
    marginTop: 10,
    display:'flex',
    justifyContent:'center'
  },
  validationCard: {
    marginTop: 40,
    padding: 10,
    width:'100%'
    // maxWidth:'80%',x
    // boxShadow: "none",s
  },
  oAuth: {
    display: 'flex', flexDirection: 'column', justifyContent: "space-evenly"
    , alignItems: 'center', gap: 40, padding: "0 40px"
  }
}));

export interface Subscriptions {
  _id: string;
  name: string;
  pods: number;
  service: number;
  config_map: number;
  persistance_vol_claims: number;
  replication_ctl: number;
  secrets: number;
  loadbalancer: number;
  node_port: number;
  created: string;
  updated: string;
}
interface I_props {
  history: any
}
  // extends RouteComponentProps<{ appId: string }, StaticContext> { }
export const CreateVCluster = (props: I_props) => {
  const classes = useStyles();
  const [showAlert, setShowAlert] = React.useState(false);
  const sessionToken = useSelector(sessionTokenSelector);
  const [subscriptions, setSubscriptions] = React.useState<Subscriptions[]>([]);
  const [token, setToken] = React.useState<null|string>(null);
  const [hasChildWindowErrored, setHasChildWindowError] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState<null|string>(null)
  const [isChildWindowOpen, setIsChildWindowOpen] = React.useState(false)
  const [apiCallStatus, setApiCallStatus] = React.useState({
    isValidatingToken: false,
    isValidationSucceeded: false,
    isValidationError: false,
    validationErrorMessage:null
  });

  const hideAlert = () => {
    setShowAlert(false);
  };

  const getActiveStep = () => {
    if (token) return 1;
    return 0;
  };

  React.useEffect(() => {
    if (!token) return;

    getvClusterSubs<Subscriptions[]>(sessionToken)
      .then((response: any) => {
        // Handle the response data here
        setSubscriptions(response.data.subscriptions);
      })
      .catch((error: any) => {
        setSubscriptions([]);
      });
  }, [token]);

  const openChildWindow = () => {

    try {

      const appId = window.config.REACT_APP_01CLOUD_APPID;
      const redirectUrl = window.config.REACT_APP_CLUSTER_OAUTH_REDIRECT_URL;
      const oAUthRoute = window.config.REACT_APP_CLUSTER_MANAGER_OAUTH;
      const childWindow = window.open(
        `${oAUthRoute}?app_id=${appId}&redirect_url=${redirectUrl}`,
        "_blank",
        "width=390,height=844"
      );
      setIsChildWindowOpen(true)

      // Listen for messages from the child window
      window.addEventListener("message", (event) => {
        // Check if the message is from the child window
        if (event.source === childWindow) {

          setToken(event.data.authToken);
          setUserEmail(event.data.userEmail)
          setShowAlert(true);
          setIsChildWindowOpen(false)

        }
      });
    } catch (error) {
      setHasChildWindowError(true);
      setIsChildWindowOpen(false)
    }
  };

  useEffect(()=>{
    if(!token || !userEmail) return
    validateTokenCall(token,sessionToken)
    .then(() => {
      setApiCallStatus(() => {
        return {
          isValidatingToken: false,
          isValidationSucceeded: true,
          isValidationError: false,
          validationErrorMessage:null
        };
      });
      setShowAlert(true);
    })
    .catch((error) => {

      setApiCallStatus(() => {
        return {
          isValidatingToken: false,
          isValidationSucceeded: false,
          isValidationError: true,
          validationErrorMessage:error.response.data.message||"Something went wrong. Please contact Admin." 
        };
      });
      setShowAlert(true);
    });
    return ()=>{
      setApiCallStatus({
    isValidatingToken: false,
    isValidationSucceeded: false,
    isValidationError: false,
    validationErrorMessage:null
  })
    }
  },[token,userEmail])


  return (
    <div className={classes.root}>
      

      <section>
        <Stepper
          // style={{ background: "#f6f8fa", paddingLeft: "0px" }}
          activeStep={getActiveStep()}
          alternativeLabel
        >
          {["Login with Cluster Manager", "Create a new Cluster"].map((item) => (
            <Step data-test="step">
              {/* <StepLabel>{label}</StepLabel> */}
              <StepButton data-test="step-btn">{item}</StepButton>
            </Step>
          ))}
        </Stepper>
        <Grid container alignContent="center" justifyContent="center">
          <Grid item xs={12} md={4}>
            
          
        <Card className={classes.validationCard}>
          <CardHeader
            title={
              !token ? (
                <Typography align="center" className={classes.validationHeader} variant="h4">
                  Connect to Cluster manager
                </Typography>
              ) : (
                <section>
                  {" "}
                  {showAlert && userEmail && (
                    <Alert
                      color="success"
                    >

                      <Typography
                        className={classes.validationHeader}
                        variant="h5"
                      >
                        Logged in as {userEmail}
                      </Typography>
                    </Alert>
                  )}
                  {apiCallStatus.isValidationError && showAlert && (
                      <div className="m-t-10">
                        <Alert
                          color="error"
                          icon={<ErrorOutlineIcon />}
                        >
                          <Typography className={classes.validationHeader} variant="h5">
                          {apiCallStatus.validationErrorMessage}
                          </Typography>
                        </Alert>
                      </div>
                    )
                  }
                </section>
              )
            }
          />
          <CardContent>
            {token ? (
              <section>
                <CreateClusterSection
                  token={token}
                  history={props.history}
                  subscriptions={subscriptions}
                  hasRoleValdiationFailed={!apiCallStatus.isValidationSucceeded}
                />
              </section>
            ) : (

              <div className={classes.oAuth}>

                <img src={`${imagePath}`} alt="logo"></img>
                <Typography align="center" style={{ maxWidth: '60ch', marginTop: 32, color: palette.text.disabled }} variant="subtitle2" noWrap={false}>Click continue to connect to Cluster Manager</Typography>

                <Button color='primary' disabled={isChildWindowOpen}  fullWidth variant='contained' disableElevation onClick={openChildWindow}>
                  Continue
                </Button>
              </div>

            )}
          </CardContent>
        </Card>
        </Grid>
        </Grid>
        {hasChildWindowErrored ? (
          <section className={classes.errorSection}>
            {showAlert && (
              <Alert
                className={classes.alert}
                color="error"
                onClose={hideAlert}
              >
                <Typography className={classes.validationHeader} variant="h5">
                  Access key validation unsuccessful. Please try again.
                </Typography>
              </Alert>
            )}
          </section>
        ) : null}
        {/* {apiCallStatus.isValidationError ? (<section className={classes.errorSection}>
            {showAlert && (
              <Alert
                className={classes.alert}
                color="error"
              >
                <Typography className={classes.validationHeader} variant="h5">
                 {apiCallStatus.validationErrorMessage}
                </Typography>
              </Alert>
            )}
          </section>):null} */}
      </section>
    </div>
  );
};

export default withRouter(CreateVCluster);
