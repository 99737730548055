import palette from "../palette";

export default {
  
  elevation1: {
    boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
    borderRadius: '16px !important',
  },
  elevation2: {
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    borderRadius: '16px',
  },
  elevation3: {
    boxShadow: 'none !important',
    border: 'none !important',
    borderRadius: '16px !important',
    borderBottom : `none !important`,
    backgroundColor: `${palette.background.neutral} !important`,
  },
  elevation4: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 2px 4px 0 rgba(63,63,68,0.2)',
    borderRadius: '10px',
  },
  elevation5: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 4px 6px -1px rgba(63,63,68,0.2)',
    borderRadius: '10px',
  },
  elevation6: {
    boxShadow: 'none !important',
    border: 'none !important',
    borderRadius: '0px !important',
    borderBottom : `1px dashed ${palette.background.neutral} !important`,
  },
  elevation7: {
    boxShadow: 'none !important',
    border: '1px solid #919EAB1F',
    borderRadius: '16px !important',
  },
  elevation8: {
    boxShadow: 'none !important',
    border: 'none !important',
    borderRadius: '16px !important',
  },
  elevation9: {
    boxShadow: 'none !important',
    border: 'none !important',
    borderRadius: '0px !important',
  },
  elevation10: {
    boxShadow: 'none !important',
    border: 'none !important',
    borderRadius: '0px !important',
    borderBottom : `1px dashed ${palette.background.neutral} !important`,
    backgroundColor: `${palette.white} !important`,
  },
  elevation11: {
    boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
    borderRadius: '16px !important',
    border: `1px solid ${palette.background.primary} !important`
  },
  elevation12: {
    boxShadow: 'none !important',
    border: 'none !important',
    borderRadius: '0px !important',
    borderBottom : `none !important`,
    backgroundColor: `${palette.background.neutral} !important`,
  },
  elevation13: {
    boxShadow: 'none !important',
    border: 'none !important',
    borderRadius: '0px !important',
    borderBottom : `1px solid ${palette.background.neutral} !important`,
    backgroundColor: `${palette.white} !important`,
  },
  elevation14: {
    boxShadow: 'none !important',
    borderRadius: '16px !important',
    border: `1px solid ${palette.background.neutral} !important`
  },
  elevation15: {
    border: 'none !important',
    boxShadow: '-40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
    borderRadius: '16px !important',
  },
};
