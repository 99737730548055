import palette from "../palette";

export default {
    root: {
      margin: '4px',
      fontSize: 14,
      fontWeight: 500,
      color: palette.text.secondary,
      '&:hover': {
            backgroundColor: 'rgba(145, 158, 171, 0.16)', 
            borderRadius: '6px',  
            fontSize: 14,  
            fontWeight: 600,
            color:  palette.text.primary
      },
      '&.Mui-selected': {  
            fontSize: 14,  
            fontWeight: 600,
            color:  palette.text.primary  
      },
    },
  };