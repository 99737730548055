import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import palette from "../../theme/palette";
import SettingsIcon from '@material-ui/icons/Settings';

export const AuthformHeader = () => {

    const hanldeDocNav = (url: string)=> {
        window.open(url, '_blank')
    }

    return(
        <Grid container alignItems="center">
            <Grid item xs={6}>
            <img
                id="imgLogo"
                src="/images/logos/logo-blue.svg"
                alt="01Cloud"
                className="authlogo"
                height={40}
                width={40}
            />
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
            <Button onClick={() => hanldeDocNav(window?.config?.REACT_APP_01CLOUD_DOCS)} disableElevation style={{display: 'flex', alignItems: 'center', gap: 8}}>
                <SettingsIcon style={{color: palette.text.secondary}}/>
                <Typography variant="subtitle2">Need help?</Typography>
            </Button>
            </Grid>
        </Grid>
    )
}