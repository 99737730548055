import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getWorkflows,
  getWorkflowLog,
  clearClusterWorkflows,
  fetchClusterLogs,
} from "./redux/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import CicdCard from "../../components/cicdcard/CicdCard";
import CicdLogsPopup from "../../components/cicdlogspopup/CicdLogsPopup";
import CiLogsPopup from "../../components/cicdlogspopup/CiLogsPopup";
import CicdCardSkeleton from "../../components/skeletons/CicdCardSkeleton";
import BackdropLoader from "../../components/loader/BackdropLoader";
import ClusterWS from "../../containers/ClusterWS";
import { AppConstants } from "../../constants/appconstants";
import { withTranslation } from "react-i18next";
import NoContentImage from "../../components/nocontentimagecontainer/NoContentImage";

/* istanbul ignore next */
const useStyles = () => ({
  divBuild: {
    display: "flex",
    marginBottom: 10,
  },
  btnBuild: {
    marginLeft: "auto",
  },
  imgGrid: {
    textAlign: "center",
  },
  defaultImg: {
    width: "400px",
    objectFit: "cover",
  },
});

export class Clusterworkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //anchorEl: null
      openLogPopup: false,
      currentWorkflowName_Log: "",
      currentWorkflowLogStep: "",
      currentWorkFlowIsStale: false,
      pageNo: 1,
      pageSize: 10,
      currentTask: null,
      currentStep: null
      //clusterApplied: false
    };
  }

  componentDidMount() {
    if (this.props.clusterId > 0) {
      this.fetchClusterWorkflows(this.props.clusterId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        !this.props.clusterWorkflowsLoaded &&
        nextProps.clusterWorkflowsLoaded
      ) {
        if (
          nextProps.clusterWorkflows &&
          nextProps.clusterWorkflows.length > 0
        ) {
          const pendingWorkflows = nextProps.clusterWorkflows.filter(
            (x) =>
              x.pipeline &&
              x.pipeline.status &&
              (x.pipeline.status ===
                AppConstants.WorkflowStatus.Pending ||
                x.pipeline.status === AppConstants.WorkflowStatus.Running)
          );
          if (pendingWorkflows && pendingWorkflows.length > 0) {
            pendingWorkflows.map((item) => {
              this.props.getWorkflowLog(
                this.props.clusterId,
                item.pipeline.runner,
                true
              );
            });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.clearClusterWorkflows();
  }

  fetchClusterWorkflows = (id, pageNo) => {
    this.props.getWorkflows(id, pageNo ? pageNo : 1, this.state.pageSize);
  };

  handleShowLog = (workflowName, isWorkFlowStale, logStep, taskInfo) => {
    this.setState({
      openLogPopup: true,
      currentWorkflowName_Log: workflowName,
      currentWorkflowLogStep: logStep,
      currentWorkFlowIsStale: isWorkFlowStale,
      currentTask: taskInfo ? taskInfo.task : null,
      currentStep: taskInfo ? taskInfo.step : null
    });
    if (taskInfo) {
      const task = taskInfo?.task;
      const step = taskInfo?.step;
      if (task && step) {
        const { clusterLogs } = this.props;
        if (clusterLogs && clusterLogs.length > 0) {
          const index = clusterLogs.findIndex(x => x.name === workflowName && x.task === task.name && x.step === step.name);
          if (index > -1) {
            return;
          }
        }
        this.props.fetchClusterLogs(this.props.clusterDetails?.cluster?.id, workflowName, task.name, step.name);
      }
    }
  };

  handleCloseLogPopup = () => {
    this.setState({
      openLogPopup: false,
      currentWorkflowName_Log: "",
      currentWorkflowLogStep: "",
      currentWorkFlowIsStale: false,
      currentTask: null,
      currentStep: null
    });
  };

  fetchWorkflowLogs = (workflowName) => {
    const { clusterWorkflowLog } = this.props;
    if (clusterWorkflowLog && clusterWorkflowLog.length > 0) {
      const isExists = clusterWorkflowLog.filter(
        (x) => x.name === workflowName
      );
      if (isExists && isExists.length > 0) return;
    }
    this.props.getWorkflowLog(this.props.clusterId, workflowName);
  };

  loadMore = () => {
    let newPageNo = this.state.pageNo + 1;
    this.fetchClusterWorkflows(this.props.clusterId, newPageNo);
    this.setState({
      pageNo: newPageNo,
    });
  };

  handleApplyTerraform = () => {
    //this.setState({ clusterApplied: true })
    this.props.handleClusterApply();
  };

  render() {
  
    const { classes, clusterDetails, appliedClusters, t } = this.props;
    return (
      <div data-test="main-container">
        <ClusterWS data-test="cluster-ws" clusterId={this.props.clusterId} />
        {clusterDetails &&
          clusterDetails.status === AppConstants.ClusterStatus.Planned && (
            <div className={classes.divBuild}>
              <Button
                data-test="apply-button"
                variant="contained"
                color="primary"
                className={classes.btnBuild}
                onClick={() => this.handleApplyTerraform()}
                disabled={
                  appliedClusters &&
                  appliedClusters.find((x) => x === this.props.clusterId)
                }
              >
                {" "}
                {t("Cluster.ClusterWorkflow.apply")}{" "}
              </Button>
            </div>
          )}
        {this.props.clusterWorkflows &&
        this.props.clusterWorkflows.length > 0 ? (
          <InfiniteScroll
            data-test="infinite-scroll"
            dataLength={this.props.clusterWorkflows.length}
            next={this.loadMore.bind(this)}
            hasMore={
              this.props.clusterWorkflows.length ===
              this.state.pageSize * this.state.pageNo
            }
            scrollableTarget="scrollable-content"
          >
            {this.props.clusterWorkflows.map((workflow, index) => {
              return (
                <CicdCard
                  source="2"
                  data-test="data-card"
                  data={workflow}
                  logData={
                    this.props.clusterWorkflowLog &&
                    workflow &&
                    workflow.pipeline &&
                    this.props.clusterWorkflowLog.find(
                      (x) => x.name === workflow.pipeline.runner
                    )
                  }
                  dataCount={this.props.clusterWorkflows.length}
                  index={index}
                  key={index}
                  fetchWorkflowLogs={this.fetchWorkflowLogs}
                  //handleReRun={this.handleReRun}
                  //handleStop={this.handleStop}
                  handleShowLog={this.handleShowLog}
                  //headerName={ this.props.clusterName }
                  clusterDetails={this.props.clusterDetails}
                />
              );
            })}
          </InfiniteScroll>
        ) : !this.props.clusterWorkflows ? (
          [0, 1, 2, 3, 4].map((value) => {
            return (
              <React.Fragment key={value}>
                <CicdCardSkeleton data-test="card-skeleton" />
              </React.Fragment>
            );
          })
        ) : (
          <NoContentImage
            message={t("Cluster.ClusterWorkflow.noHistory")}
            alt="No History"
            type="env"
            data-test="no-history"
          />
        )}
        {false && this.state.openLogPopup &&
          <CicdLogsPopup
            source="2"
            openLogPopup={this.state.openLogPopup}
            logData={
              this.props.clusterWorkflowLog &&
              this.props.clusterWorkflowLog.find(
                (x) => x.name === this.state.currentWorkflowName_Log
              )
            }
            handleCloseLogPopup={this.handleCloseLogPopup}
            currentWorkFlow={
              this.props.clusterWorkflows &&
              this.props.clusterWorkflows.find(
                (x) => x.pipeline && x.pipeline.runner === this.state.currentWorkflowName_Log
              )
            }
            currentWorkflowName_Log={this.state.currentWorkflowName_Log}
            currentWorkflowLogStep={this.state.currentWorkflowLogStep}
            currentWorkFlowIsStale={this.state.currentWorkFlowIsStale}
            data-test="cicd-popup"
          />
        }
        {this.state.openLogPopup &&
          <CiLogsPopup
            isOpen={this.state.openLogPopup}
            handleClose={this.handleCloseLogPopup}
            task={this.state.currentTask?.name}
            step={this.state.currentStep}
            logData={this.props.clusterLogs && this.props.clusterLogs.find((x) => x.name === this.state.currentWorkflowName_Log && x.task === this.state.currentTask?.name && x.step === this.state.currentStep?.name)}
            data-test="cicd-popup"
          />
        }
        {this.props.fetchingClusterWorkflows && (
          <BackdropLoader
            message={t("Cluster.ClusterWorkflow.fetchingCluster")}
            data-test="loader"
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  clusterWorkflows: state.ClusterReducer.clusterWorkflows,
  clusterWorkflowLog: state.ClusterReducer.clusterWorkflowLog,
  clusterWorkflowsLoaded: state.ClusterReducer.clusterWorkflowsLoaded,
  fetchingClusterWorkflows: state.ClusterReducer.fetchingClusterWorkflows,
  appliedClusters: state.ClusterReducer.appliedClusters,
  clusterLogs: state.ClusterReducer.clusterLogs
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch) => {
  return {
    getWorkflows: (id, pageNo, pageSize) =>
      dispatch(getWorkflows(id, pageNo, pageSize)),
    getWorkflowLog: (id, workflowName, noEmptyCheck) =>
      dispatch(getWorkflowLog(id, workflowName, noEmptyCheck)),
    clearClusterWorkflows: () => dispatch(clearClusterWorkflows()),
    fetchClusterLogs: (id, workflowName, task, step) => dispatch(fetchClusterLogs(id, workflowName, task, step))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withStyles(useStyles)(withTranslation()(Clusterworkflow)));
