import { Card, CardContent, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import LaunchIcon from '@material-ui/icons/Launch';
import React, { Component } from 'react';
import Skeleton from "react-loading-skeleton";
import { connect ,ConnectedProps} from 'react-redux';
import { Link } from 'react-router-dom';
import BackdropLoader from '../../../../components/loader/BackdropLoader';
import InfiniteScroll from "react-infinite-scroll-component"
import { getDateInStandardFormat } from '../../../../helpers/utils';
//import moment from 'moment';
import { clearProjectActivities, fetchProjectActivities } from '../../redux/actions';
// import { fetchUserListInProject } from '../../../../components/adduserpopup/redux/actions';
import './ActivityTab.css';
import { withTranslation,WithTranslation } from "react-i18next";
import { Dispatch } from "redux";
import { createStyles, Theme, WithStyles } from "@material-ui/core";
import { ProjectActivity } from '../../../../models/Project.model';
import palette from '../../../../theme/palette';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

/* istanbul ignore next */
const useStyles = (theme:Theme) => createStyles({
    dropdown: {
        minWidth: 140,
        height: 50,
    },
    type: {
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            display: "none"
        }
    },
    filterGrid: {
        borderRadius: "4px",
        width: "100%",
        padding: "10px 0",
        margin: "5px auto 10px"
    },
    table:{
        borderRadius:0
    },
    card:{
        borderRadius:0
    },
    buttonActive:{
        lineHeight: '22px',
        fontWeight: 500,
        color: palette.text.primary,
        fontSize: 14,
        borderBottom: `2px solid ${palette.background.primary}`,
        borderRadius: '0px !important',
        '&:hover': {
          backgroundColor: 'transparent', 
        },
        padding: 12
      },
});

interface Props extends PropsFromRedux,WithStyles,WithTranslation{
projectId:number
}

export interface State {
    currentPage:      number;
    limit:            number;
    selectedAction:   string;
    selectedFromDate: string;
    selectedToDate:   string;
    selectedUser:     string;
    selectedModule:   string;
}
export class ActivityTab extends Component <Props,State>{
    constructor(props:Props){
        super(props);
        this.state = {
            currentPage: 1,
            limit: 20,
            selectedAction: "all",
            selectedFromDate: "",
            selectedToDate: "",
            selectedUser: "all",
            selectedModule: "all"
        }
    }

    componentDidMount(){
        this.fetchActivitiesCall();
        // this.props.fetchUserListInProject(this.props.projectId)
    }

    componentWillUnmount(){
        this.props.clearProjectActivities();
    }
    
    UNSAFE_componentWillReceiveProps = (newProps:Props) => {
        if(this.props.projectId !== newProps.projectId) 
        {
            this.props.clearProjectActivities();
            // this.props.fetchUserListInProject(newProps.projectId);
            this.resetFilters();
        }
    }

    fetchMoreActivities = () => {
        this.setState({
            currentPage: this.state.currentPage + 1
        }, () => this.fetchActivitiesCall())
    }

    isResetValid = () => {
        if(this.state.selectedAction === "all" && 
            this.state.selectedFromDate === "" && 
            this.state.selectedToDate === "" &&
            this.state.selectedUser === "all" &&
            this.state.selectedModule === "all"
        ) return false;
        return true;
    } 
    
    resetFilters = () => {
        this.props.clearProjectActivities();
        this.setState({
            currentPage: 1,
            selectedAction: "all",
            selectedFromDate: "",
            selectedToDate: "",
            selectedUser: "all",
            selectedModule: "all",
        }, () => this.fetchActivitiesCall());
    }

    fetchActivitiesCall = () => {
        this.props.fetchProjectActivities(
            this.props.projectId, 
            this.state.currentPage, 
            this.state.limit,
            this.state.selectedAction !== "all" ? this.state.selectedAction : "",
            this.state.selectedUser ?? "",
            this.state.selectedFromDate || "",
            this.state.selectedToDate || "",
            this.state.selectedModule !== "all" ? this.state.selectedModule : "",
        );
    }

    handleActionChange = (e:React.ChangeEvent<{ value: unknown }>) => {
        this.props.clearProjectActivities();
        this.setState({
            currentPage: 1,
            selectedAction: e.target.value as string,
        }, () => this.fetchActivitiesCall())
    }

    // handleUserChange = (e) => {
    //     this.props.clearProjectActivities();
    //     this.setState({
    //         currentPage: 1,
    //         selectedUser: e.target.value
    //     }, () => this.fetchActivitiesCall())
    // }

    handleDateChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            currentPage: 1,
            selectedFromDate: e.target.name === "fromDate" ? e.target.value : this.state.selectedFromDate,
            selectedToDate: e.target.name === "toDate" ? e.target.value : this.state.selectedToDate
        }, () => {
            if(!this.dateError) {
                this.props.clearProjectActivities();
                this.fetchActivitiesCall();
            }
        })
    }

    handleModuleChange = (e:React.ChangeEvent<{ value: unknown }>) => {
        this.props.clearProjectActivities();
        this.setState({
            currentPage: 1,
            selectedModule: e.target.value as string,
        }, () => this.fetchActivitiesCall())
    }

    dateError = false;
    
    render () {
        const { classes,t } = this.props;
        const invalidRange = (this.state.selectedFromDate && this.state.selectedToDate && !(new Date(this.state.selectedToDate) > new Date(this.state.selectedFromDate)));
        const dateErrorText = (Boolean(this.state.selectedToDate) !== Boolean(this.state.selectedFromDate)) ? t('Projects.ActivityTab.fromToError') : (invalidRange ? t('Projects.ActivityTab.dateEarlyError') : "");
        this.dateError = Boolean(dateErrorText);
        return (
            <div data-test="main-component" className="org-activities-container">
                {/* <div>
                    <Button
                    className={classes.buttonActive}
                    variant='text'
                    disableElevation
                    >
                    All
                    </Button>
                </div>
                <Divider /> */}
                <Grid className={classes.filterGrid} container spacing={3} justify="flex-end" alignItems="center" >    
                    <Grid item xs={12} sm={3}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                        >
                          <InputLabel>{t('Projects.ActivityTab.moduleLabel')}</InputLabel>

                          <Select
                            data-test="module-selector"
                            value={this.state.selectedModule}
                            onChange={(e) => this.handleModuleChange(e)}
                            label="Module"
                          >
                            <MenuItem value="all">
                              <em>{t('Projects.ActivityTab.allLabel')}</em>
                            </MenuItem>
                            <MenuItem value="project">{t("Support.TicketDetail.project")}</MenuItem>
                            <MenuItem value="application">{t("Support.TicketDetail.application")}</MenuItem>
                            <MenuItem value="environment">{t("Support.TicketDetail.environment")}</MenuItem>
                            <MenuItem value="webshell">{t('Projects.ActivityTab.webshell')}</MenuItem>
                          </Select>
                        </FormControl>
                    </Grid>

                      <Grid item xs={12} sm={3}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                        >
                          <InputLabel>{t('Projects.ActivityTab.actionLabel')}</InputLabel>

                          <Select
                            data-test="action-selector"
                            value={this.state.selectedAction}
                            onChange={(e) => this.handleActionChange(e)}
                            label={t('Projects.ActivityTab.actionLabel')}
                          >
                            <MenuItem value="all">
                              <em>{t('Projects.ActivityTab.allLabel')}</em>
                            </MenuItem>
                            <MenuItem value={"create"}>{t('Projects.ActivityTab.createLabel')}</MenuItem>
                            <MenuItem value={"delete"}>{t('Projects.ActivityTab.deleteLabel')}</MenuItem>
                            <MenuItem value={"update"}>{t('Projects.ActivityTab.updateLabel')}</MenuItem>
                            <MenuItem value={"share"}>{t('Projects.ActivityTab.shareLabel')}</MenuItem>
                            <MenuItem value="open">{t("Support.SupportPage.open")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                          <TextField
                              type="date"
                              label={t('Projects.ActivityTab.fromLabel')}
                            //   format="dd/mm/yyyy"
                              value={this.state.selectedFromDate}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleDateChange(e)}
                              variant="outlined"
                              InputLabelProps={{shrink: true}}
                              data-test="fromDate-textField"
                              name="fromDate"
                              error={this.dateError}
                              helperText={dateErrorText}
                            //   xs={12}
                            fullWidth
                          />
                      </Grid>
                      <Grid item xs={12} sm={this.isResetValid() ? 2 :3}>
                          <TextField
                              type="date"
                              label={t('Projects.ActivityTab.toLabel')}
                            //   format="yyyy/mm/dd"
                              value={this.state.selectedToDate}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleDateChange(e)}
                              variant="outlined"
                              InputLabelProps={{shrink: true}}
                              data-test="toDate-textField"
                              name="toDate"
                              error={this.dateError}
                              helperText={dateErrorText}
                            //   xs={12}
                              fullWidth
                          />
                      </Grid>
                      {this.isResetValid() && (
                        <Grid item  xs={12} sm={1}>
                            <Button
                              data-test="reset-button"
                              onClick={() => this.resetFilters()}
                             
                            >
                              {t('Projects.ActivityTab.resetLabel')}{" "}
                            </Button>
                        </Grid>
                      )}
                </Grid>
                <div className='activityMasterContianer' data-test="activityMaster-container">
                    <Card elevation={12}>
                        <CardContent className="titleHeader">
                            <Grid container xs={12} spacing={3}>
                                <Grid item xs={6}>
                                    <span className='activityHeader' style={{display: 'flex', gap:8, alignItems: 'center'}}>
                                        {t('Projects.ActivityTab.typeLabel')}
                                        {/* <ArrowDownwardIcon fontSize="small"/> */}
                                    </span>
                                </Grid>
                                <Grid item xs={3}>
                                    <span className='activityHeader'>{t('Projects.ActivityTab.userLabel')}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <span className='activityHeader'>{t('Projects.ActivityTab.dateLabel')}</span>
                                </Grid>
                                {/* <Grid item xs={6} className="messageStyle">
                                    <span className='activityHeader'>{t('Projects.ActivityTab.messageLabel')}</span>
                                </Grid> */}
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className={classes.card} elevation={10}>
                        {
                                this.props.isLoading && this.state.currentPage === 1 && [0,1,2,3,4,5,6,7,8,9,10,11].map(val => {
                                    return (
                                        <CardContent key={val} data-test="skeleton">
                                            <Grid container md={12}>
                                                <Grid item md={1} >
                                                    <Skeleton  circle width={40} height={40} />
                                                </Grid>

                                                <Grid item md={3}>
                                                    <Skeleton  height={20} width="50%" />
                                                </Grid>

                                                <Grid item md={2}>
                                                    <Skeleton  height={20} width="60%"/>
                                                </Grid>

                                                <Grid item md={6}>
                                                    <Skeleton  height={20} width="60%" />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                                })
                            }
                    </Card>
                    <Card className={classes.card} elevation={10}>
                        {
                            this.props.projectActivities &&
                                <InfiniteScroll
                                    data-test="infiniteScroll-component"
                                    dataLength={this.props.projectActivities.length ?? 0}
                                    next={this.fetchMoreActivities}
                                    hasMore={this.props.projectActivities.length === this.state.currentPage * this.state.limit}
                                    // loader={<h2>Loading more...</h2>}
                                   loader={<h4>Loading...</h4>}
                                    scrollThreshold={0.95}
                                    scrollableTarget="scrollable-content"
                                >
                                    {
                                        this.props.projectActivities && this.props.projectActivities.length === 0 &&
                                            <div >
                                                <CardContent data-test="noProjectActivities">
                                                    <Grid container>
                                                        <i style={{textAlign: "center", width: "100%"}}>{this.isResetValid() ? t('Projects.ActivityTab.noActivityMatchingError') : t('Projects.ActivityTab.noActivityError')}</i>
                                                    </Grid>
                                                </CardContent>
                                            </div>
                                    }
                                    { this.props.projectActivities && this.props.projectActivities.length > 0 && this.props.projectActivities.map((item:ProjectActivity) => {
                                        let username = item.user ? item.user.first_name + ' ' + item.user.last_name : "";
                                        let date = getDateInStandardFormat(item.createdat);
                                        let moduleData:string|JSX.Element = ""
                                        let actionData: string|JSX.Element= ""
                                        let actionIcon: string|JSX.Element = ""
                                        let _to = ""
                                        switch(item.module) {
                                            case "project" :
                                                _to = '/project/' + item.project_id
                                                moduleData = item.project ? <Link to={_to} className='activityInfoLink'>{ item.project.name }</Link> : <span className='activityInfoSpan'>{ item.remarks }</span>;
                                                break;
                                            case "application" :
                                                _to = '/app/' + item.application_id
                                                moduleData = item.application ? <Link to={_to} className='activityInfoLink'>{ item.application.name }</Link> : <span className='activityInfoSpan'>{ item.remarks }</span>;
                                                break;
                                            case "environment" :
                                                _to = '/environment/' + item.environment_id
                                                moduleData = item.environment ? <Link to={_to} className='activityInfoLink'>{ item.environment.name }</Link> : <span className='activityInfoSpan'>{ item.remarks }</span>;
                                                break;
                                            case "webshell" :
                                                _to = '/environment/' + item.environment_id + "#insight";
                                                moduleData = item.environment ? <Link to={_to} className='activityInfoLink'>{ item.remarks }</Link> : <span className='activityInfoSpan'>{ item.remarks }</span>;
                                                moduleData = <><span>in instance </span>{moduleData}</>
                                                break;
                                            default:
                                                moduleData = ""
                                        }
                                        switch(item.action) {
                                            case "create" : actionData = "Created"; actionIcon=<BuildOutlinedIcon fontSize="small" color="primary"/> ; break;
                                            case "update" : actionData = "Updated"; actionIcon=<EditOutlinedIcon fontSize="small" color="inherit"/>; break;
                                            case "delete" : actionData = "Deleted"; actionIcon=<DeleteOutlineOutlinedIcon fontSize="small" color="error"/>;break;
                                            case "share" : actionData = "Shared"; actionIcon=<PersonAddOutlinedIcon fontSize="small" color="action" />; break;
                                            case "unshare" : actionData = "Unshared"; actionIcon=<PersonAddDisabledIcon fontSize="small" color="error" />; break;
                                            case "open" : actionData = "Opened"; actionIcon=<LaunchIcon fontSize="small" color="primary" />; break;
                                            default : actionData = item.action ;actionIcon=<CalendarTodayOutlinedIcon fontSize="small"/>; break;
                                        }
                                        return (
                                            <div key={item.id} style={{borderBottom: `1px dashed ${palette.background.neutral}`}}>
                                                <CardContent data-test="card-data">
                                                    <Grid container xs={12} spacing={3} style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid item xs={6} style={{display: 'flex', alignItems:'center',gap:16}}>
                                                            <div className={`${actionData === 'Deleted' ? 'errorCircle' : 'editCircle'} ${classes.type}`}>
                                                                {actionIcon}
                                                            </div>
                                                            <p>{actionData} {item.module} {moduleData} {item.extras} </p>
                                                        </Grid>
                                                        <Grid item xs={3} className='nameStyle'>
                                                            <p data-test="username">{username}</p>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <p className='timeStyle' data-test="date-component">{date} UTC</p>
                                                        </Grid>

                                                        {/* <Grid item xs={6} className='messageStyle' data-test="message">
                                                            <p>{actionData} {item.module} {moduleData} {item.extras} </p>
                                                        </Grid> */}
                                                    </Grid>
                                                </CardContent>
                                            </div>
                                        )
                                    })}
                                </InfiniteScroll>     
                        }
                              
                    </Card>
                </div>
                {this.props.activityLoading && 
                    <BackdropLoader data-test="activity-loader" message={this.state.currentPage === 1 ? t('Projects.ActivityTab.fetchingProjectActivity') : t('Projects.ActivityTab.fetchingMoreProjectActivity')} />
                }
            </div>
    
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    projectActivities: state.ProjectReducer.projectActivities,
    isLoading: state.ProjectReducer.activityLoading,
    activityLoading: state.ProjectReducer.activityLoading,
    // usersListInProject: state.AddUserPopupReducer.usersListInProject,
    projectDetails: state.ProjectReducer.projectDetails,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        fetchProjectActivities : (id:number, page:number, limit:number, action:string, userid:string, datestart:string, dateend:string, module:string, ) => dispatch(fetchProjectActivities(id, page, limit, action, userid, datestart, dateend, module, )),
        clearProjectActivities : () => dispatch(clearProjectActivities()),
        // fetchUserListInProject: (payload) => dispatch(fetchUserListInProject(payload))
    }
}

const connector=connect(
    mapStateToProps,
    mapDispatchtoProps
)
  
export default connector(withStyles(useStyles)(withTranslation()(ActivityTab)))
type PropsFromRedux = ConnectedProps<typeof connector>;