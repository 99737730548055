import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withTranslation,WithTranslation } from 'react-i18next';
import { connect,ConnectedProps } from 'react-redux';
import { fetchEnvWorkflows, fetchEnvWorkflowLogByName, rerunEnvCIWorkflow, stopEnvCIWorkflow, updateEnvWorkflowLog, updateEnvWorkflows, clearEnvironmentCI, clearCIBuildTriggered } from '../../../cicdredux/actions'
import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import CicdCard from '../../../../../components/cicdcard/CicdCard';
import CicdLogsPopup from '../../../../../components/cicdlogspopup/CicdLogsPopup';
import ConfirmActionPopup from '../../../../../components/confirmactionpopup/ConfirmActionPopup'
import { sessionTokenSelector } from '../../../../login/redux/selectors';
// import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { isAuthorized } from '../../../../../helpers/utils'
import InfiniteScroll from 'react-infinite-scroll-component';
// import Skeleton from 'react-loading-skeleton';
import CicdCardSkeleton from '../../../../../components/skeletons/CicdCardSkeleton'
import BackdropLoader from '../../../../../components/loader/BackdropLoader'
import { AppConstants } from '../../../../../constants/appconstants';
import NoContentImage from '../../../../../components/nocontentimagecontainer/NoContentImage';
import { Dispatch } from 'redux';
import { WorkflowData ,LogStep,LogDataType} from '../../../../../models/workflow.model';
import { createStyles, Theme, WithStyles } from "@material-ui/core";
/* istanbul ignore next */
const useStyles = () =>createStyles ({
    divBuild: {
        display: 'flex',
        marginBottom: 10
    },
    btnBuild: {
        marginLeft : 'auto'
    },
    jobsList: {
        // [ theme.breakpoints.up('lg') ]: {
        //     height: '62vh',
        //     overflow: 'auto'
        // }
    }
});

interface Iprops extends PropsFromRedux,WithTranslation,WithStyles<typeof useStyles> {
}
interface Istate {
    openLogPopup: boolean;
    isRerunConfirmPopupOpen: boolean;
    rerun_wname: string;
    isStopConfirmPopupOpen: boolean;
    stop_wname: string;
    buildConfirmMessage: string;
    currentWorkflowName_Log: string;
    currentWorkflowLogStep: null | LogStep;
    //currentSocketUpdates: any[];
    currentWorkFlowIsStale: boolean;
    pageNo: number;
    pageSize: number;
    showFullLoader: boolean;
}

export class CITab extends Component<Iprops,Istate> {
    constructor(props:Iprops){
        super(props);
        this.state = {
            openLogPopup: false,
            isRerunConfirmPopupOpen: false,
            rerun_wname : '',
            isStopConfirmPopupOpen: false,
            stop_wname : '',
            //timer: null,
            buildConfirmMessage: '',
            currentWorkflowName_Log : '',
            currentWorkflowLogStep : null,
            //ws: props.ws ? props.ws : null,
            //envWorkflows: [],
            //dummydate : null,
            //currentSocketUpdates:[],
            currentWorkFlowIsStale: false,
            //totalWorkflowCount: 100,
            pageNo: 1,
            pageSize: 10,
            showFullLoader: false
        }
    }

    componentDidMount(){
        if(this.props.environmentDetails.id > 0)
            this.fetchEnvCIWorkflows(this.props.environmentDetails.id);
        // if(this.props.ws){
        //     // this.setState({
        //     //     ws: this.props.ws
        //     // })
        //     this.socketConnect(this.props.ws)
        // }
        
    }

    // updatedummystate = () => {
    //     this.setState({
    //         dummydate: Date.now()
    //     })
    // }

    UNSAFE_componentWillReceiveProps (nextProps:Iprops) {
        if(nextProps){
            if(nextProps.environmentDetails.id > 0)
            {
                if (!this.props.environmentDetails.id || this.props.environmentDetails.id !== nextProps.environmentDetails.id)
                {
                    this.fetchEnvCIWorkflows(nextProps.environmentDetails.id);
                }
            }
            // if(!this.props.ws && nextProps.ws)
            // {
            //     this.socketConnect(nextProps.ws);
            // }
            // if(nextProps.envWorkflows)
            // {
            //     this.setState({
            //         envWorkflows : nextProps.envWorkflows
            //     })
            // }
            // if(!this.props.envWorkflowsLoaded && nextProps.envWorkflowsLoaded)
            // {
            //     if(nextProps.envWorkflows && nextProps.envWorkflows.length > 0)
            //     {
            //         const pendingWorkflows = nextProps.envWorkflows.filter((x: WorkflowData) => x.workflow && x.workflow.status && (x.workflow.status.phase === AppConstants.WorkflowStatus.Pending || x.workflow.status.phase === AppConstants.WorkflowStatus.Running));
            //         if(pendingWorkflows && pendingWorkflows.length > 0)
            //         {
            //             pendingWorkflows.map((item: WorkflowData) => {
            //                 this.props.fetchEnvWorkflowLogByName(this.props.environmentDetails.id, item.workflow.object_meta.name, true);
            //             })
            //         }
            //     }
            //     //this.socketConnect();
            // }
            if(!this.props.ciBuildTriggered && nextProps.ciBuildTriggered)
            {
                this.setState({
                    showFullLoader: false
                })
            }
        }
    }

    componentWillUnmount() {
        //const { ws } = this.state;
        // if(ws != null) ws.close();
        this.props.clearEnvironmentCI();
    }

    fetchEnvCIWorkflows = (id:number, pageNo?:number) => {
        this.props.fetchEnvWorkflows(id, pageNo ? pageNo : 1, this.state.pageSize);
    }
    
    handleReRun = (workflowName:string, buildConfirmMessage?:string) => {
        this.setState({
            isRerunConfirmPopupOpen: true,
            rerun_wname: workflowName,
            buildConfirmMessage: buildConfirmMessage ? buildConfirmMessage : this.props.t('Environment.CiTab.rerun') 
        })
    }

    handleRerunAgreeHandler = () => {
        this.props.rerunEnvCIWorkflow(this.props.environmentDetails.id, this.state.rerun_wname)
        this.handleRerunDisAgreeHandler();
        //this.socketConnect();
        this.setState({
            showFullLoader: true
        })
    }

    handleRerunDisAgreeHandler = () => {
        this.setState({
            isRerunConfirmPopupOpen: false,
            rerun_wname: '',
            buildConfirmMessage: ''
        })
    }

    handleStop = (workflowName:string) => {
        this.setState({
            isStopConfirmPopupOpen: true,
            stop_wname: workflowName
        })
    }

    handleStopAgreeHandler = () => {
        this.props.stopEnvCIWorkflow(this.props.environmentDetails.id, this.state.stop_wname)
        this.handleStopDisAgreeHandler();
    }

    handleStopDisAgreeHandler = () => {
        this.setState({
            isStopConfirmPopupOpen: false,
            stop_wname: ''
        })
    }
  
    handleShowLog = (workflowName:string, isWorkFlowStale:boolean, logStep?:LogStep) => {
        if(!logStep){
            this.setState({
                openLogPopup: true,
                currentWorkflowName_Log : workflowName,
                currentWorkFlowIsStale: isWorkFlowStale
            })
        }else{
            this.setState({
                openLogPopup: true,
                currentWorkflowName_Log : workflowName,
                currentWorkflowLogStep : logStep,
                currentWorkFlowIsStale: isWorkFlowStale
            })
        }       
    }

    handleCloseLogPopup = () => {
        this.setState({
            openLogPopup: false,
            currentWorkflowName_Log : '',
            currentWorkflowLogStep : null,
            currentWorkFlowIsStale: false
        })
    }
    
    handleBuild = () => {
        //const { envWorkflows } = this.props;
        // if(envWorkflows && envWorkflows.length > 0 && envWorkflows[0].Workflow)
        this.handleReRun('', this.props.t('Environment.CiTab.buildCi'));
    }

    fetchWorkflowLogs = (workflowName:string) => {
        // const { envWorkflowLog } = this.props;
        // if(envWorkflowLog && envWorkflowLog.length > 0)
        // {
        //     const isExists = envWorkflowLog.filter((x:LogDataType )=> x.name === workflowName);
        //     if(isExists && isExists.length > 0)
        //         return;
        // }
        // this.props.fetchEnvWorkflowLogByName(this.props.environmentDetails.id, workflowName)
    }

    loadMore = () => {
        let newPageNo = this.state.pageNo + 1;
        this.fetchEnvCIWorkflows(this.props.environmentDetails.id, newPageNo);
        this.setState({
            pageNo: newPageNo
        });
    }

    // updatehasMoreToLoad = (length, pageSize, pageNo) => {
    //     this.setState({
    //         hasMoreToLoad :  length == pageSize * pageNo
    //     })
    // }

    render () {
        const { classes, envRole, t } = this.props;
        return (
            <div data-test="main-container">
                <Card className='scripts'>
                    <CardHeader
                    title={<Typography variant='subtitle1'>CI</Typography>}
                    action={
                        isAuthorized('build', envRole.name)  && (
                            <div className={ classes.divBuild }>
                                <Button variant="contained" color="primary" className={ classes.btnBuild } onClick={ () => this.handleBuild() } data-test="build-btn">Build</Button>
                            </div>
                        )
                    }
                    />
                    <CardContent>
                        {
                            this.props.ciBuildTriggered && 
                            <CicdCardSkeleton data-test="ci-card-skel" />
                        }
                        {
                            this.props.envWorkflows && this.props.envWorkflows.length > 0 &&
                            <InfiniteScroll
                            dataLength={ this.props.envWorkflows.length } //This is important field to render the next data

                            next={ this.loadMore.bind(this) }
                                //hasMore={this.state.hasMoreToLoad}
                            hasMore= { this.props.envWorkflows.length === this.state.pageSize * this.state.pageNo }
                            data-test="infinite-scroll"
                            scrollableTarget="scrollable-content"
                                loader={<></>}
                                //endMessage={
                                //    <p style={{textAlign: 'center'}}>
                                //        <b>Yay! You have seen it all</b>
                                //    </p>
                                //}
                            >
                                {
                                    this.props.envWorkflows.map((workflow: WorkflowData, index:number) => {
                                    return (
                                        <CicdCard source="1" 
                                            data={ workflow } 
                                            logData={this.props.envWorkflowLog && workflow && workflow.pipeline && this.props.envWorkflowLog.find((x:LogDataType) => x.name === workflow.pipeline.runner) }
                                            dataCount={ this.props.envWorkflows.length } 
                                            index={ index }
                                            key={ index }
                                            fetchWorkflowLogs={ this.fetchWorkflowLogs } 
                                            handleReRun={ this.handleReRun } 
                                            handleStop={ this.handleStop }
                                            handleShowLog={ this.handleShowLog }
                                            data-test="cicd-card"
                                        />
                                    )
                                })
                            }
                            </InfiniteScroll> 
                        }
                        {
                            this.props.envWorkflows && this.props.envWorkflows.length === 0 && 
                            <>
                                {
                                    !this.props.envWorkflowsLoaded &&
                                    <>
                                        {
                                            [ 0,1,2,3,4 ].map(value => {
                                                return (
                                                    <React.Fragment key={ value }>
                                                        <CicdCardSkeleton data-test="cicd-skel" />
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </>
                                }
                                {
                                    this.props.envWorkflowsLoaded &&
                                    <Grid container justify="center">
                                        <NoContentImage
                                            message={t('Environment.CiTab.noBuilds')}
                                            alt="No builds"
                                            data-test="no-prev-builds"
                                        />
                                    </Grid>
                                }
                            </>
                        }
                    </CardContent>
                </Card>
                

                {/* <CicdLogsPopup
                    openLogPopup={ this.state.openLogPopup } 
                    logData={this.props.envWorkflowLog && this.props.envWorkflowLog.find((x:LogDataType) => x.name === this.state.currentWorkflowName_Log) } 
                    handleCloseLogPopup={ this.handleCloseLogPopup }
                    currentWorkFlow={this.props.envWorkflows && this.props.envWorkflows.find((x: WorkflowData) => x.workflow && x.workflow.object_meta && x.workflow.object_meta.name === this.state.currentWorkflowName_Log) }
                    currentWorkflowLogStep={ this.state.currentWorkflowLogStep ? this.state.currentWorkflowLogStep : null }
                    currentWorkFlowIsStale = { this.state.currentWorkFlowIsStale }
                    data-test="logs-popup"
                /> */}

                { this.props.ciBuildTriggered && <BackdropLoader message={t('Environment.CiTab.ciBuild')}/>}
                { this.state.showFullLoader && <BackdropLoader message=""/>}
                <ConfirmActionPopup open={ this.state.isRerunConfirmPopupOpen } handleAgree={ this.handleRerunAgreeHandler } handleDisAgree={ this.handleRerunDisAgreeHandler } message={ this.state.buildConfirmMessage } yesText={t('Projects.VariablesTab.yesText')} noText={t('Projects.VariablesTab.noText')} data-test="confirm-action-pop" />
                <ConfirmActionPopup open={ this.state.isStopConfirmPopupOpen } handleAgree={ this.handleStopAgreeHandler } handleDisAgree={ this.handleStopDisAgreeHandler } message={t('Environment.CiTab.stopCi')} yesText={t('Projects.VariablesTab.yesText')} noText={t('Projects.VariablesTab.noText')} data-test="confirm-popup"/>
                {this.props.stoppingCI && <BackdropLoader message={t('Environment.CiTab.stopingCi')} data-test="back-drop" />}
                {this.props.fetchingEnvWorkflows && <BackdropLoader message={t('Environment.CiTab.fetchingWork')} data-test="backdrop-loader" />}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    envWorkflows: state.CICDReducer.envWorkflows,
    envWorkflowLog: state.CICDReducer.envWorkflowLog,
    envWorkflowsLoaded: state.CICDReducer.envWorkflowsLoaded,
    validSessionId: sessionTokenSelector(state),
    envRole: state.EnvironmentReducer.envRole,
    ciBuildTriggered: state.CICDReducer.ciBuildTriggered,
    stoppingCI: state.CICDReducer.stoppingCI,
    fetchingEnvWorkflows: state.CICDReducer.fetchingEnvWorkflows,

})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        fetchEnvWorkflows : (id:number, pageNo:number, pageSize:number) => dispatch(fetchEnvWorkflows(id, pageNo, pageSize)),
        fetchEnvWorkflowLogByName: (id:number, workflowName:string, noEmptyCheck?:boolean) => dispatch(fetchEnvWorkflowLogByName(id, workflowName, noEmptyCheck)),
        rerunEnvCIWorkflow: (id:number, workflowName:string) => dispatch(rerunEnvCIWorkflow(id, workflowName)),
        stopEnvCIWorkflow: (id:number, workflowName:string) => dispatch(stopEnvCIWorkflow(id, workflowName)),
        // updateEnvWorkflowLog: (workflowLogs) => dispatch(updateEnvWorkflowLog(workflowLogs)),
        // updateEnvWorkflows: (workflows) => dispatch(updateEnvWorkflows(workflows)),
        clearEnvironmentCI: () => dispatch(clearEnvironmentCI()),
        clearCIBuildTriggered: () => dispatch(clearCIBuildTriggered())
    }
}
  const connector=connect(
    mapStateToProps,
    mapDispatchtoProps
)
export default connector(withStyles(useStyles)(withTranslation()(CITab)))
type PropsFromRedux=ConnectedProps<typeof connector>