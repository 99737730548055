import palette from '../palette';

export default {
  root: {
    backgroundColor: '#F4F6F8',
    color: palette.text.secondary,
    fontWeight: 600,
    fontSize: '14px'
  }
};
