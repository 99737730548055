import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MuiTextField from "../../../../components/textfield/MuiTextField";
import { connect, ConnectedProps } from "react-redux";
// import { getProviderConfig } from "../../../cluster/redux/actions";
import { Dispatch } from "redux";
import MultiValueChipInput from "../../../../components/emailinput/MultiValueChipInput";
import _ from 'lodash';
import { makeStyles } from "@material-ui/core/styles";
import { StyledTableCell } from "../../../../components/styledtabelcell/StyledTabelCell";
import CloseIcon from "@material-ui/icons/Close";
import { AppConstants } from "../../../../constants/appconstants";
import PluginConfigForm from "../../../../components/pluginconfigform/PluginConfigForm";

/* istanbul ignore next */
const useStyles = makeStyles({
  tableMargin:{
    marginBottom:"40px",
  }
});

interface Props extends PropsFromRedux {
  externalSecret: any,
  edit?:boolean,
  updatedExternalSecret:(state:any)=>void,
  enableSecret?:boolean,
}

function ExternelSecret(props: Props) {
  const classes = useStyles();
  const { externalSecret, updatedExternalSecret, enableSecret, edit } = props;

  const [addKey] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<any>(edit?externalSecret.provider:"aws");
  const [secretConfig, setSecretConfig] = useState<any>(null);

  const [variables, setVariables] = useState<any>({});
  const [isErrors] = useState([])

  const [secretDetails, setSecretDetails] = useState({
    enabled:edit?externalSecret.enabled:enableSecret,
    provider:edit?externalSecret.provider:"aws",
    additional_setting:{
      refresh_interval:edit?externalSecret.additional_setting?.refresh_interval:"0",
      creation_policy:edit?externalSecret.additional_setting?.creation_policy:"Owner",
      deletion_policy:edit?externalSecret.additional_setting?.deletion_policy:"Delete",
    },
    secrets:{}
  })

  const handleSecretsEditMode = (externalSecret: any) => {
    const secret = Object.keys(externalSecret.secrets).map((key, keyIndex) =>{
    return { secretName:key, keys: externalSecret.secrets[key]}})
    return secret;
  }

  const [secretKeys, setSecretKeys]=useState<any>(edit?handleSecretsEditMode(externalSecret):[{secretName:"",keys:""}]);

  useEffect(() => {
      updatedExternalSecret(secretDetails)
  },[secretDetails])

  useEffect(()=>{
    setSecretDetails({
      ...secretDetails, ...variables
    })
  },[variables])

  useEffect(()=>{
      let finalSecretKey:any={};
      secretKeys.forEach((el:any) =>{  
        finalSecretKey[el.secretName]=el.keys
      })
    setSecretDetails((state) =>{
      return {...secretDetails, secrets:{...finalSecretKey}}
    })
  },[secretKeys])

  const handleAddKey = () => {
    setSecretKeys((prev: any)=>{
      let addedRow = [...prev, {secretName:"",keys:""}];
      return addedRow
    })
  };

  const handleSecretName = (e: any) =>{
    const index = parseInt(e.target.name);
    const value = e.target.value
    if(index > -1){
      setSecretKeys((prevState: any)=>{
        let newSecret = [...prevState];
        newSecret[index] = {...newSecret[index], secretName:value}
        return newSecret
      })
    }
  }

  const handleTags = (data: string[], index:any) => {
    if(index>-1){
    setSecretKeys((prevState:any)=>{
      let newSecret = [...prevState];
      newSecret[index] = {...newSecret[index], keys:data.join(",")}
      return newSecret
    })}
  };

  const handleRemoveSecretField = (index:number) => {
    const secret = secretKeys.filter((el:any, indx: number)=>indx !== index);
    setSecretKeys(()=> {
     let prevSecret = [...secret]
      return prevSecret
    })
  }

  const handleValueChange = (_variables:any, errorsList:any) => {
    setVariables(()=>{
      return {..._variables}
    })
    setSecretDetails({
      ...secretDetails, ..._variables
    })
  }

  const handleChangeProvider = (e: any) => {
    setSecretConfig(null);
    setSelectedProvider(e);
  }

  useEffect(()=>{
    if(selectedProvider){
      setSecretDetails({...secretDetails, provider:selectedProvider})
      const _providerConfig = AppConstants.externalSecret.find((config,index) => config.name === selectedProvider);
      setSecretConfig(_providerConfig?.config)
      setVariables({})
    }
  },[selectedProvider])

  const handleAdditionalSetting = (name: string, value: any) =>{
    setSecretDetails((state)=>{
      return {...secretDetails, additional_setting:{...state.additional_setting, [name]:value}}
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" className="w-100">
            <InputLabel>Select provider</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              name="select_provider"
              value={selectedProvider}
              label="Externel Secret"
              onChange={ (e: React.ChangeEvent<{ value: unknown }>) => handleChangeProvider(e.target.value) }
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {
                AppConstants.externalSecret.map((_provider:any, index: number)=>
                <MenuItem value={_provider.name} key={_provider.name}>{_provider.displayName}</MenuItem>
                )
              }
            </Select>
          </FormControl>
        </Grid>

        {selectedProvider && secretConfig &&
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PluginConfigForm
              pluginVersionConfig={secretConfig ?? {}}
              handleValueChange={handleValueChange}
              initialValues = {variables}
              isErrors={isErrors}
              gridItemOccupency={4}
              ignoreAlternateColour = { true }
              validateEmpty={true}
              />
            </Grid>
          </Grid>
        </Grid>
        }

      <Grid item xs={12} md={12}>
      <Button color='primary' variant="contained" size="small" 
      disabled={addKey}
      onClick={()=>handleAddKey()}
      >Add Keys</Button>
      </Grid>

      <Grid item xs={12} md={12} className={classes.tableMargin}>
      <TableContainer component={Paper} elevation={7}>
        <Table>
        <colgroup>
        <col style={{width:'30%'}}/>
        <col style={{width:'60%'}}/>
        <col style={{width:'10%'}}/>
        </colgroup>
          <TableHead>
            <TableRow>
              <StyledTableCell>Secret Name</StyledTableCell>
              <StyledTableCell>Key Value</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
        {secretKeys.map((item:any, index:number)=>(
          <>
          <TableRow>
            <StyledTableCell component="th" scope="row">
            <TextField
              label="Secret Name"
              defaultValue="Normal"
              variant="outlined"
              value={item.secretName}
              name={index.toString()}
              fullWidth
              onChange={ (e: React.ChangeEvent<{ value: unknown, name: unknown }>) => handleSecretName(e) }
            />
            </StyledTableCell>

            <StyledTableCell component="th" scope="row">
            <MultiValueChipInput  values={ _.without(item.keys && item.keys.split(','), '')  } handleValues={handleTags}
            placeholder="Type or paste KEYS values and press `Enter` ..."
            index={index}
            />
            </StyledTableCell>

            <StyledTableCell component="th" scope="row" align="center">
              {index !== 0?
              <span style={{cursor:"pointer"}}>
              <CloseIcon
                onClick={() =>handleRemoveSecretField(index)}
              />
              </span>:""
            }

            </StyledTableCell>
            
          </TableRow>
          </>
        ))}

      </Table>
      </TableContainer>
      </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <MuiTextField
                label="Refresh Interval"
                value={secretDetails.additional_setting.refresh_interval}
                name="refreshInterval"
                onChange={ (e: React.ChangeEvent<{ value: unknown }>) => handleAdditionalSetting("refresh_interval",e.target.value) }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl variant="outlined" className="w-100">
                <InputLabel>Creation Policy</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  name="creation_policy"
                  value={secretDetails.additional_setting.creation_policy}
                  label="Creation Policy"
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleAdditionalSetting("creation_policy",e.target.value)}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem value="Owner">Owner</MenuItem>
                  <MenuItem value="Merge">Merge</MenuItem>
                  <MenuItem value="None">None</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl variant="outlined" className="w-100">
                <InputLabel>Deletion Policy</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  name="deletion_policy"
                  value={secretDetails.additional_setting.deletion_policy}
                  label="Deletion Policy"
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleAdditionalSetting("deletion_policy",e.target.value)}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem value="Delete">Delete</MenuItem>
                  <MenuItem value="Merge">Merge</MenuItem>
                  <MenuItem value="Retain">Retain</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        // getProviderConfig: (provider: string) => dispatch(getProviderConfig(provider))
    };
  };
  
  /* istanbul ignore next */
  const mapStateToProps = (state: any) => {
    return {
        providerConfig: state.ClusterReducer.providerConfig
    };
  };
  
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ExternelSecret);