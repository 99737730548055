import React, {Component} from 'react';
import { Typography, FormControlLabel, Switch,
    Grid,
    Card,
    CardContent,
    IconButton,
    Menu,
    MenuItem,
    ButtonBase,
    Chip,
    Button,
    Tooltip,
    Box,
    Divider
 } from '@material-ui/core';
 import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomButton from '../../components/custombutton/CustomButton'
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import { connect, ConnectedProps } from 'react-redux';
import { getClusterList, clearNewClusterData, deleteCluster, destroyCluster, exportWorkflow, clearExportWorkflow, clearClusterInfo, applyTerraform, enableDisableCluster, getClusterDetails } from './redux/actions'
import ClusterWorkflow from '../cluster/ClusterWorkflow'
import ClusterInfo from '../cluster/ClusterInfo'
import CronJobSkeleton from '../../components/skeletons/CronJobSkeleton'
import BackdropLoader from '../../components/loader/BackdropLoader'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ConfirmDeletePopup from "../../components/confirmdeletepopup/ConfirmDeletePopup";
import { AppConstants } from '../../constants/appconstants'
import ConfirmActionPopup from '../../components/confirmactionpopup/ConfirmActionPopup'
import ClusterImportPopup from './ClusterImportPopup'
import { currentOrganization } from '../login/redux/selectors'
import HistoryIcon from '@material-ui/icons/History';
import Toast from "../../components/toast/Toast";
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { DateHandler } from "../../components/dateHandler/DateHandler"
import NoContentImage from '../../components/nocontentimagecontainer/NoContentImage';
import SearchField from "../../components/searchfield/SearchField";
import paths from '../../constants/paths';
import "./ClusterList.css";
import { Dispatch } from "redux";
import { ClusterDetailsModel } from '../../models/Cluster.model';
import { History } from 'history';
import palette from '../../theme/palette';
import { CustomColors } from '../../constants/enums';
import { ClusterImportIcon } from '../../helpers/customIcon';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { getDiffDays } from '../../helpers/utils';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { CreateVCluster } from './createcluster/vcluster/createVcluster';
import CreateClusterMaster from './createcluster/CreateClusterMaster';

/* istanbul ignore next */
const useStyles = () => ({
    jobHeader: {
      display: "flex",
    },
    createJob: {
      marginLeft: "auto",
    },
    jobStatus: {
      paddingLeft: 15,
      display: "flex",
    },
    historyIcon: {
        marginRight: "10px"
    },
    clickable : {
        cursor: "pointer",
    },
    clusterStatusWrapper:{
        border: `1px dashed ${palette.background.neutral}`, 
        padding: 8, 
        width: 'fit-content', 
        borderRadius: 8, 
        gap: 8, 
        alignItems: 'center'
    }
  });

  type statusDomProps = {
    status:string;
    type:string;
  }

  type CommonFunc = {
    handleClusterApply:()=>void;
    handleClusterDelete:()=>void;
    handleClusterDestroy:()=>void;
    handleClusterExport:()=>void;
    handleEditCluster:()=>void;
    handleHistoryClick:()=>void;
  }

  type ClusterMenuProps = {
    cluster:ClusterDetailsModel;
    appliedClusters:any;
    handleClusterMenu:(cluster:ClusterDetailsModel)=>void;
  } & CommonFunc;

  type ClusterMenuItemsProps = {
    cluster:ClusterDetailsModel;
    hideDestory?:boolean;
    appliedClusters:any;
    clusterPackageStatus?:string;
  } & CommonFunc;

  interface Props extends PropsFromRedux, WithTranslation, WithStyles<typeof useStyles> {
    history:History;
  }

  type State = {
    mode: number;
    currentCluster: any;
    currentClusterId: number;
    currentClusterName: string;
    isDeletePopupOpen: boolean;
    isDestroyPopupOpen: boolean;
    isEditPopupOpen: boolean;
    anchorElInfo: any
    currentView: number;
    isApplyPopupOpen: boolean;
    isImportPopupOpen: boolean;
    isEnablePopupOpen: boolean;
    enableSelection: any
    enableDisableId: number;
    importClusterEditPopup: boolean;
    filteredPackages: any;
    searchTextTemp: string;
    searchText: string;
    isCreateBtnVisible:boolean;
    currentClusterUrl:string;
  }

  export function StatusDOM(props:statusDomProps) {
    let statusIndicator;
    if(props.type === AppConstants.ClusterStatus.Imported)
    {
        statusIndicator = <Chip variant="outlined" color="primary" label="Imported"/>
    }
    else
    {
        switch(props.status) {
            // case AppConstants.ClusterStatus.Drafted :
            //     statusIndicator = <div>Drafted</div>
            //     break;
            // case AppConstants.ClusterStatus.Planned :
            //     statusIndicator = <div>Planned</div>
            //     break;
            // case AppConstants.ClusterStatus.Applied :
            //     statusIndicator = <div>Active</div>
            //     break;
            case AppConstants.ClusterStatus.Destroyed :
                statusIndicator = <Chip variant="outlined" color="primary"  label="Destroyed "/>
                break;
            case AppConstants.ClusterStatus.Imported : 
                statusIndicator = <Chip variant="outlined" color="primary"  label="Imported"/>
                break;
            case AppConstants.ClusterStatus.Planned:
                statusIndicator = <Chip variant="outlined" style={{ borderColor: "#ff9100", color: "#ff9100" }} label="Not Applied" />
                break;
            default:
                statusIndicator = ""
        }
    }
    return (
      <div>
        { statusIndicator }
      </div>
    )
  }

  export function ClusterMenu(props:ClusterMenuProps){
    const { cluster } = props;
    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const handleClusterMenu = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(e.currentTarget);
        props.handleClusterMenu(props.cluster);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleHistoryClick= () => {
        handleMenuClose();
        props.handleHistoryClick();
    };

    const handleEditCluster = () => {
        handleMenuClose();
        props.handleEditCluster();
    }

    const handleClusterApply = () => {
        handleMenuClose();
        props.handleClusterApply();
    }

    const handleClusterDelete= () => {
        handleMenuClose();
        props.handleClusterDelete();
    };

    const handleClusterDestroy= () => {
        handleMenuClose();
        props.handleClusterDestroy();
    };

    const handleClusterExport= () => {
        handleMenuClose();
        props.handleClusterExport();
    };
    return (
        <>
            <IconButton onClick={handleClusterMenu } data-test="icon-button" style={{padding:'0px !important'}}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                disableScrollLock={true}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                data-test= "menu-close"
            >
               
                <ClusterMenuItems cluster={ cluster }
                    hideDestory={true}
                    handleHistoryClick = { handleHistoryClick }
                    handleClusterDelete = { handleClusterDelete }
                    handleClusterApply = { handleClusterApply }
                    handleClusterDestroy = { handleClusterDestroy }
                    handleClusterExport = { handleClusterExport }
                    handleEditCluster = { handleEditCluster }
                    appliedClusters={props.appliedClusters}
                    data-test = "menu-items"
                />
            </Menu>
        </>
    )
  } 

  export function ClusterMenuItems(props:ClusterMenuItemsProps){
      const [ t ] = useTranslation()
    return (
        <>
            <MenuItem onClick={() => props.handleHistoryClick()}  data-test="history-click"> {t('Cluster.ClusterList.history')}</MenuItem>
            {
                (props.cluster.type === AppConstants.ClusterStatus.Imported || props.cluster.status === AppConstants.ClusterStatus.Planned ||  props.cluster.status === AppConstants.ClusterStatus.Drafted || props.cluster.status === "" || props.cluster.status === AppConstants.ClusterStatus.Destroyed || props.cluster.status === AppConstants.ClusterStatus.Failed) && props.cluster.provider && props.cluster.provider!=="zerone" &&
                <MenuItem onClick={() => props.handleClusterDelete()}  data-test="cluster-delete"> {t('Cluster.ClusterList.delete')} </MenuItem>
            }
            {
                props.cluster.status === AppConstants.ClusterStatus.Planned && !props.appliedClusters.find((x:number) => x === props.cluster.id) &&
                <MenuItem onClick={() => props.handleClusterApply()} data-test="apply-cluster"> {t('Cluster.ClusterList.apply')} </MenuItem>
            }
            {
                props.cluster.type !== AppConstants.ClusterStatus.Imported && (props.cluster.status === AppConstants.ClusterStatus.Applied || props.cluster.status === AppConstants.ClusterStatus.PackageInstalled) && !props.hideDestory && props.cluster.provider &&  props.cluster.provider!=="zerone" &&
                <>
                    <MenuItem onClick={() => props.handleClusterDestroy()} data-test="destroy-cluster" > {t('Cluster.ClusterList.destroy')} </MenuItem>
                </>
            }
            {
                props.cluster.type !== AppConstants.ClusterStatus.Imported && (props.cluster.status === AppConstants.ClusterStatus.Applied || props.cluster.status === AppConstants.ClusterStatus.PackageInstalled || props.cluster.status === AppConstants.ClusterStatus.PackageInstalling) &&
                <>
                    <MenuItem onClick={() => props.handleClusterExport()} data-test="cluster-export" > {t('Cluster.ClusterList.export')} </MenuItem>
                </>
            }
            {
                // props.cluster.type !== AppConstants.ClusterStatus.Imported &&
                 props.cluster.status !== AppConstants.ClusterStatus.Destroyed &&  props.cluster.provider && props.cluster.provider!=="zerone" &&
                <>
                    <MenuItem onClick={() => props.handleEditCluster()} data-test="cluster-edit"> {t('Cluster.ClusterList.edit')} </MenuItem>
                </>
            }
        </>
    )
  }

export class ClusterList extends Component<Props,State> {
    private exportRef: React.RefObject<HTMLAnchorElement>;
    constructor(props:Props){
        super(props);
        this.state = {
            //anchorEl: null,
            mode: 0, // 0:List ; 1:Workflows ; 2:Details
            currentCluster: null,
            currentClusterId: 0,
            currentClusterName: "",
            isDeletePopupOpen: false,
            isDestroyPopupOpen: false,
            isEditPopupOpen: false,
            anchorElInfo: null,
            currentView: 1,
            isApplyPopupOpen: false,
            isImportPopupOpen: false,
            isEnablePopupOpen: false,
            enableSelection: null,
            enableDisableId: 0,
            importClusterEditPopup: false,
            filteredPackages: [],
            searchTextTemp: "",
            searchText: "",
            isCreateBtnVisible:false,
            currentClusterUrl:""
        }
        this.exportRef = React.createRef();
    }

    componentDidMount() {
        this.props.getClusterList();
        if(this.props.newClusterData && this.props.newClusterData.id > 0)
        {
            this.setState({
                mode: 1,
                currentCluster: this.props.newClusterData,
                currentClusterId: this.props.newClusterData.id,
                currentClusterName: this.props.newClusterData.cluster_name
            })
            this.props.clearNewClusterData();
        }
    }

    componentWillUnmount(){
        this.props.clearClusterInfo()
    }

    handleCreateCluster = () => {
        this.props.history.push({
            pathname: paths.CREATE_ORG_CLUSTER,
        });
        
    }

    gotoCreateVcluster=()=>{
        // this.props.history.push({
        //     pathname:paths.CREATE_V_CLUSTER
        // })
        this.setState({
            mode: 3
        })
    }
    handleClusterMenu = (cluster:ClusterDetailsModel) => {
        this.setState({
          //anchorEl: e.currentTarget,
          currentCluster: cluster,
          currentClusterId: cluster.id,
          currentClusterName: cluster.cluster_name
        });
      };
    
    //   handleMenuClose = () => {
    //     this.setState({
    //       //anchorEl: null,
    //     });
    //   };

    handleViewClusterDetails = (cluster:ClusterDetailsModel) => {
        this.setState({
            mode: 2,
            currentCluster: cluster,
            currentClusterId: cluster.id,
            currentClusterName: cluster.cluster_name
        })
    }

    handleHistoryClick = () => {
        this.handleInfoMenuClose();
        this.setState({
            mode: 1
        })
    }

    handleBack = () => {
        //this.handleMenuClose();
        this.handleInfoMenuClose();
        this.setState({
          mode: 0,
          currentCluster: null,
          currentClusterId: 0,
          currentClusterName: ""
        });
        
        this.props.getClusterList();
      };
    
      handleClusterDelete = () => {
        //this.handleMenuClose();
        this.handleInfoMenuClose();
        this.setState({
            isDeletePopupOpen: true,
        });
      };

      handleDisAgreeHandler = () => {
        this.setState({
            isDeletePopupOpen: false,
        });
      };
    
      handleAgreeHandler = () => {
        this.props.deleteCluster(this.state.currentClusterId);
        this.setState({
            isDeletePopupOpen: false,
            mode: 0,
            // currentClusterId: 0,
            // currentClusterName: ""
        });
      };

      // destroy section start
      handleClusterDestroy = () => {
        //this.handleMenuClose();
        this.handleInfoMenuClose();
        this.setState({
            isDestroyPopupOpen: true,
        });
      };

      handleDestroyDisAgreeHandler = () => {
        this.setState({
            isDestroyPopupOpen: false,
        });
      };
    
      handleDestroyAgreeHandler = () => {
        let installed = false
        const clusterDetails = this.state.currentCluster
        const {cluster} = clusterDetails
        if( cluster && cluster.dns_id > 0 && cluster.image_registry_id > 0 && (clusterDetails?.type === "imported" || clusterDetails?.status === AppConstants.ClusterStatus.Applied || clusterDetails?.status === AppConstants.ClusterStatus.PackageInstalled)){
            if(this.props.clusterPackageStatus && Object.keys(this.props.clusterPackageStatus).length >= this.state.filteredPackages?.length ){
                for(var ns in this.props.clusterPackageStatus){
                    if(this.props.clusterPackageStatus[ns].status === "installed"){
                        installed = true
                        break
                    }
                }
                if(!installed){
                    this.props.destroyCluster(this.state.currentClusterId);
                    this.setState({
                        isDestroyPopupOpen: false,
                        mode: 0,
                        // currentClusterId: 0,
                        // currentClusterName: ""
                    });
                }else{
                    Toast.warn(this.props.t('Cluster.ClusterList.uninstallError'))
                    this.setState({
                        isDestroyPopupOpen: false,
                        // currentClusterId: 0,
                        // currentClusterName: ""
                    });
                }
        }else{
            Toast.warn(this.props.t('Cluster.ClusterList.fetchingStatusError'))
        }
        this.handleInfoMenuClose()
        this.setState({
            isDestroyPopupOpen: false,
            // currentClusterId: 0,
            // currentClusterName: ""
        });

      }else{
            this.props.destroyCluster(this.state.currentClusterId);
            this.handleInfoMenuClose()
            this.setState({
                isDestroyPopupOpen: false,
                // currentClusterId: 0,
                // currentClusterName: ""
            });
      }
    }

      // destroy section end
      handleClusterExport = () => {
        //this.handleMenuClose();
        this.handleInfoMenuClose();
        this.props.exportWorkflow(this.state.currentClusterId);
      }

    handleCluster_Apply = (cluster:ClusterDetailsModel) => {
        this.setState({
            currentCluster: cluster,
            currentClusterId: cluster.id,
            currentClusterName: cluster.cluster_name,
            isApplyPopupOpen: true
        });
    }

    handleClusterApply = () => {
        this.handleInfoMenuClose();
        this.setState({ isApplyPopupOpen: true })    
    }

    handleApplyAgreeHandler = () => {
        //alert(this.state.currentClusterId + "-" + this.props.currentOrganization.id);
        this.props.applyTerraform(this.state.currentClusterId, this.props.currentOrganization.id, null)
        this.setState({ isApplyPopupOpen: false })
    }
    handleApplyDisAgreeHandler = () => {
      this.setState({ isApplyPopupOpen: false })
    }

    handleEditCluster = () => {
        this.handleInfoMenuClose();
        this.setState({ isEditPopupOpen: true })    
    }

    handleEditAgreeHandler = () => {
        this.props.history.push({
            pathname: paths.EDIT_ORG_CLUSTER.replace(":id", this.state.currentClusterId?.toString()),
        });
        this.setState({ isEditPopupOpen: false })
    }
    handleEditDisAgreeHandler = () => {
      this.setState({ isEditPopupOpen: false })
    }
    
    handleInfoMenuClick = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({ anchorElInfo : event.currentTarget });
    };

    handleInfoMenuClose = () => {
        this.setState({ anchorElInfo : null });
    };

    UNSAFE_componentWillReceiveProps(nextProps:Props) {
        if(nextProps) {
            // if(!nextProps.newClusterData)
            // {
            //     this.setState({
            //         mode: 0,
            //         currentCluster: null,
            //         currentClusterId: 0,
            //         currentClusterName: ""
            //     })
            // }
            if(this.props.exportUrl === "" && nextProps.exportUrl !== ""){
                this.setState({
                    currentClusterUrl: nextProps.exportUrl
                }, () => { 
                    if(this.exportRef && this.exportRef.current){
                        this.exportRef.current.click(); 
                        setTimeout(() => {
                            this.props.clearExportWorkflow();
                            this.setState({
                                currentClusterUrl: ""
                            })
                        }, 1000);
                    }
                })
            }
            if(this.props.destroyingCluster && !nextProps.destroyingCluster){
                this.setState({
                    mode: 1,
                })
            }
            if(this.props.applyingTerraform && !nextProps.applyingTerraform){
                this.setState({
                    mode: 1,
                })
            }
          
        }
    }

    afterInstall = () => {
        this.setState({
            mode: 1
        })
    }
    
    handleListFilterClick = (type:number) => {
        this.setState({ currentView: type })
    }

    handleImportCluster = () => {
        this.setState({
            isImportPopupOpen : true
        })
    }

    closeImportCluster = () => {
        this.setState({
            isImportPopupOpen : false
        })
    }
    importSuccessHandler = (data:any) => {
        this.setState({
            mode: 2,
            currentCluster: data,
            currentClusterId: data.id,
            currentClusterName: data.cluster_name,
            isImportPopupOpen : false
        })
    }

    handleEnableDisableCluster = (e: React.ChangeEvent<{checked:boolean}>, id:number) => {
        this.setState({
            isEnablePopupOpen: true,
            enableSelection: { active: e.target.checked },
            enableDisableId: id
        })
    }

    handleEnableAgreeHandler = () => {
        this.props.enableDisableCluster(this.state.enableDisableId, this.state.enableSelection)
        this.handleEnableDisAgreeHandler();
    }

    handleEnableDisAgreeHandler = () => {
        this.setState({
            isEnablePopupOpen : false,
            enableSelection : null,
            enableDisableId: 0
        })
    }

    importedClusterEdit = () => {
        this.setState({
            importClusterEditPopup: true
        })
        this.handleInfoMenuClose()
    }

    importClusterEditAgree = (updatedCluster:ClusterDetailsModel) => {
        this.setState({
            importClusterEditPopup: false,
            mode: 2,
            // cluster: updatedCluster,
            currentCluster: updatedCluster,
            currentClusterId: updatedCluster.id,
            currentClusterName: updatedCluster.cluster_name
        })
        this.props.getClusterDetails(updatedCluster.id);
    }

    importClusterEditDisagree = () => {
        this.setState({
            importClusterEditPopup: false
        })
    }

    updatePackage = (packages:any) => {
        this.setState({
            filteredPackages: packages
        })
    }

    handleSearchChange = (st:string) => this.setState({ searchTextTemp: st });

    handleSearch = (st:string) => this.setState({ searchText: st });

    render () {
        const { classes, clusterList, t } = this.props;
        const { currentCluster, searchText } = this.state; 

        let filteredClusters = clusterList && clusterList.length > 0 && clusterList.filter((x:ClusterDetailsModel) => (this.state.currentView === 2 && x.status === AppConstants.ClusterStatus.Destroyed) ||  (this.state.currentView === 1 && x.status !== AppConstants.ClusterStatus.Destroyed))

        if(searchText){
            filteredClusters = filteredClusters?.filter((cluster:ClusterDetailsModel) => {
                // const node_group_names = cluster.node_group_detail?.length
                //   ? cluster.node_group_detail.map(node => node.node_group_name)
                //   : [];

                return (
                  cluster.cluster_name.includes(searchText) ||
                  cluster.provider.includes(searchText) ||
                  cluster.region.includes(searchText) ||
                  cluster.vpc_name.includes(searchText)
                //   node_group_names?.filter(node_group_name =>
                //     node_group_name.includes(searchText)
                //   )?.length
                );
            });
        }

        return (
            <div className='whitecontainer' data-test="main-container">
                <a href={this.state.currentClusterUrl} download ref={this.exportRef} >{""}</a>
                {
                    this.state.mode === 0 && 
                    <>
                        <div className="clusterListContainer">
                            <Grid container justifyContent="space-between" alignItems='center' spacing={1}>
                                <Grid item>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle1"
                                        data-test="clusters-title"
                                    >
                                        {t("Cluster.ClusterList.clusters")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems='center' spacing={2}>
                                        <Grid item>
                                            <SearchField
                                                label={t("Cluster.ClusterList.search")}
                                                search={this.state.searchTextTemp}
                                                handleSearchChange={this.handleSearchChange}
                                                handleSearch={this.handleSearch}
                                                data-test="search-field"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CustomButton
                                                label={t("Cluster.ClusterList.import")}
                                                onClick={this.handleImportCluster}
                                                data-test="import-btn"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CustomButton
                                                label={"Create"}
                                                onClick={this.gotoCreateVcluster}
                                                data-test="create-btn"
                                            />
                                        </Grid>
                                        {this.state.isCreateBtnVisible ?
                                            <Grid item>
                                                <CustomButton
                                                    label={t("Cluster.ClusterList.create")}
                                                    onClick={this.handleCreateCluster}
                                                    data-test="create-btn"
                                                    // style={{ minWidth: "100%" }}
                                                />
                                            </Grid> : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.clusterStatusWrapper}>
                                <Grid item>
                                    <Typography variant='subtitle2' color='textPrimary'>Status :</Typography>
                                </Grid>
                                <Grid item>
                                    {clusterList && clusterList.length > 0 && (
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Chip
                                                    label={t("Cluster.ClusterList.active")}
                                                    clickable
                                                    color="primary"
                                                    onClick={() => this.handleListFilterClick(1)}
                                                    variant={
                                                        this.state.currentView === 1 ? "default" : "outlined"
                                                    }
                                                    data-test="active-btn"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Chip
                                                    label={t("Cluster.ClusterList.destroyed")}
                                                    clickable
                                                    color="primary"
                                                    onClick={() => this.handleListFilterClick(2)}
                                                    variant={
                                                        this.state.currentView === 2 ? "default" : "outlined"
                                                    }
                                                    data-test="destroyed-btn"
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        {
                            !clusterList && [0, 1, 2].map((item, ind) => <CronJobSkeleton key={ind} data-test="skeleton"/>)
                        }
                        {
                            clusterList && clusterList.length === 0 && (
                                <NoContentImage 
                                    message={t('Cluster.ClusterList.noCluster')}
                                    alt="No Cluster"
                                    // type="env"
                                    data-test="no-cluster-grid"
                                    subtitle={"Start by adding new Cluster."}
                                /> 
                        )}
                        {
                            filteredClusters && filteredClusters.length === 0 &&
                                <NoContentImage 
                                    message={this.state.currentView === 1 ? t('Cluster.ClusterList.noActiveCluster') : t('Cluster.ClusterList.noDestroyedCluster') }
                                    alt="No Active Cluster"
                                    type="env"
                                />                                
                        }
                        {   <Grid container spacing={3} className='mt-40'>
                                {filteredClusters && filteredClusters.length > 0 && filteredClusters.map((cluster:ClusterDetailsModel, ind:number) => {
                                    return (
                                        <Grid item xs={12} md={6} lg={3} key={ ind } >
                                            <Card className={ `m-b-20 ${cluster.status === AppConstants.ClusterStatus.Destroyed ? "destroyed" : "" }`} data-test="cluster-card" elevation={1}>
                                                <CardContent style={{padding: 0}}>
                                                    <Grid container alignItems="center" spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={12} data-test="cluster">
                                                                    <Grid container justifyContent='center' alignItems='center'>
                                                                        <Grid item xs={12} data-test="cluster-provider" style={{padding: '16px 24px'}}>
                                                                            <Grid container spacing={2} alignItems='flex-start' >
                                                                                <Grid item xs={11}>
                                                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                                                        <Typography variant='subtitle1' className={classes.clickable} title={ cluster.cluster_name } onClick={() => this.handleViewClusterDetails(cluster) } data-test="cluster-name">{ cluster.cluster_name }</Typography>
                                                                                        <Typography variant='caption' style={{color: CustomColors.Success.Main}}>{cluster.status === AppConstants.ClusterStatus.Destroyed ? "Destroyed" : "Active"}</Typography>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={1} className="header-details-grid" >
                                                                                    <ClusterMenu cluster={ cluster }
                                                                                        handleClusterMenu={this.handleClusterMenu}
                                                                                        handleHistoryClick={this.handleHistoryClick}
                                                                                        handleClusterDelete={this.handleClusterDelete}
                                                                                        handleClusterDestroy={this.handleClusterDestroy}
                                                                                        handleClusterExport={this.handleClusterExport}
                                                                                        handleEditCluster={cluster.type === "imported" ? this.importedClusterEdit : this.handleEditCluster}
                                                                                        handleClusterApply={this.handleClusterApply} 
                                                                                        appliedClusters={this.props.appliedClusters}
                                                                                        data-test="cluster-menu"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Divider />
                                                                        </Grid>
                                                                        <Grid item md={12} xs={12} style={{padding: '16px 24px'}}>
                                                                            <Grid container spacing={2} alignItems='center'>
                                                                                <Grid item xs={12}  lg={6}>
                                                                                    <div style={{color: palette.text.disabled, display: 'flex', alignItems: 'center', gap: 4}}>
                                                                                        <ClusterImportIcon viewBox='0 0 16 16'/>
                                                                                        <Typography variant='caption'>{cluster.status ? cluster.status : ""} </Typography>
                                                                                    </div>
                                                                                </Grid> 
                                                                                {
                                                                                    cluster.cluster_version && (
                                                                                    <Grid item xs={12}  md={6} >
                                                                                        <div style={{color: palette.text.disabled, display: 'flex', alignItems: 'center', gap: 4}}>
                                                                                            <SettingsOutlinedIcon />
                                                                                            <Typography variant='caption' data-test="cluster-version" >{ cluster.cluster_version }</Typography>
                                                                                        </div>
                                                                                    </Grid>
                                                                                )}
                                                                                <Grid item xs={12}  md={6}>
                                                                                    {/* <DateHandler date={cluster.createdat?.toString()} icon={<AccessTimeIcon  fontSize="large" style={{color: palette.text.disabled, height: 16, width: 16}}  />} data-test="cluster-created" /> */}
                                                                                    <div style={{color: palette.text.disabled, display: 'flex', alignItems: 'center', gap: 4}}>
                                                                                        <AccessTimeIcon />
                                                                                        <Typography variant='caption'>{getDiffDays(cluster.createdat?.toString(), true)} </Typography>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12}  md={6} >
                                                                                    <div style={{color: palette.text.disabled, display: 'flex', alignItems: 'center', gap: 4}}>
                                                                                        <PublicOutlinedIcon  />
                                                                                        <Typography variant='caption' data-test="cluster-region" >{ cluster.region }</Typography>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12}  md={6} >
                                                                                    <div style={{color: palette.text.disabled, display: 'flex', alignItems: 'center', gap: 4}}>
                                                                                        <PersonOutlineOutlinedIcon  />
                                                                                        <Typography variant='caption' data-test="cluster-region" >{ cluster.provider }</Typography>
                                                                                    </div>
                                                                                </Grid>
                                                                                {/* <Grid item md={3} xs={3} className="header-details-grid">
                                                                                    {/* <ScheduleOutlinedIcon className={classes.svgicon} />
                                                                                    <span className="infoGrid">{ "4 mins"}</span> 
                                                                                </Grid> */}
                                                                            </Grid>
                                                                        </Grid>
                                                                        {(cluster.status === AppConstants.ClusterStatus.Applied || cluster.status === AppConstants.ClusterStatus.PackageInstalled) && (
                                                                            <Grid item xs={12} style={{padding: '0px 24px'}} className='clusterActiveForm'>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Switch
                                                                                            name="active"
                                                                                            color="primary"
                                                                                            checked={ cluster.cluster?.active }
                                                                                            onChange={ (e) => this.handleEnableDisableCluster(e, cluster.id) }
                                                                                        />
                                                                                    }
                                                                                    label={t('Cluster.ClusterList.activeLabel')}
                                                                                    className='w-100'
                                                                                />
                                                                            </Grid>
                                                                        )}
                                                                        {cluster.status === AppConstants.ClusterStatus.Planned && (
                                                                            <Grid item xs={12} style={{padding: '16px 24px', width: '100%', display: 'flex',  justifyContent: 'right'}}>
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={() => this.handleCluster_Apply(cluster)}
                                                                                        data-test = "apply"
                                                                                    >
                                                                                        Apply
                                                                                    </Button>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        }
                    </>
                }
                {
                    this.state.mode !== 0 &&
                    <div className="m-b-20">
                        {
                            this.state.mode === 2 &&
                            <Grid container alignItems="center" justify="space-between">
                                <Grid item>
                                    <Typography variant="subtitle1" >{ this.state.currentClusterName }</Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Show History" arrow placement="left">
                                        <IconButton onClick={() => this.handleHistoryClick()} className={classes.historyIcon} data-test= "history-button">
                                            <HistoryIcon />
                                        </IconButton>
                                    </Tooltip>
                                <Button variant="contained" color="primary" endIcon={<ExpandMoreIcon/>} disableElevation disableFocusRipple disableTouchRipple
                                    aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleInfoMenuClick(e)}
                                    data-test = "action-button"
                                >
                                {t("App.AppInfo.actions")}
                                </Button>
                                    <Menu
                                    id="simple-menu"
                                    disableScrollLock={true}
                                    anchorEl={this.state.anchorElInfo}
                                    open={Boolean(this.state.anchorElInfo)}
                                    onClose={() => this.handleInfoMenuClose()}
                                    //className={classes.profileMenu}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    data-test = "simple-menu"
                                    >
                                    {
                                        currentCluster && this.props.clusterDetails && currentCluster.id === this.props.clusterDetails.id &&
                                        <ClusterMenuItems cluster={ this.props.clusterDetails }
                                            handleHistoryClick = { this.handleHistoryClick }
                                            handleClusterDelete = { this.handleClusterDelete }
                                            handleClusterApply = { this.handleClusterApply }
                                            handleClusterDestroy = { this.handleClusterDestroy }
                                            handleClusterExport = { this.handleClusterExport }
                                            handleEditCluster = { currentCluster.type === "imported" ? this.importedClusterEdit : this.handleEditCluster }
                                            clusterPackageStatus = {this.props.clusterPackageStatus}
                                            appliedClusters={this.props.appliedClusters}
                                        />
                                    }
                                    </Menu>
                                </Grid>
                            </Grid>
                        }
                        {
                            this.state.mode === 3 && <Typography variant='subtitle1'>Create Cluster</Typography>
                        }
                        {
                            this.state.mode !== 3 &&
                            <ButtonBase 
                                onClick={this.state.mode === 2 ? this.handleBack : () => this.handleViewClusterDetails(this.state.currentCluster)} 
                                style={{ color: CustomColors.Success.Main, marginTop: 28}}
                                data-test = "button-base"
                            >
                                <ArrowBackIcon fontSize="small" />{" "}
                                <span className="jobNav">{this.state.mode === 2 ? t('Cluster.ClusterList.backToList') : t('Cluster.ClusterList.backToClusterDetails')}</span>
                            </ButtonBase>
                        }
                    </div>
                }
                {
                    this.state.mode === 1 &&
                    <>
                        <Typography variant="h4">{ this.state.currentClusterName }</Typography><br />
                        <ClusterWorkflow clusterId={ this.state.currentClusterId } 
                            clusterName={ this.state.currentClusterName } 
                            clusterDetails={ this.state.currentCluster }
                            history={ this.props.history }
                            handleClusterApply = { this.handleClusterApply } 
                        />
                    </>
                }
                
                {
                    this.state.mode === 2 &&
                    <ClusterInfo clusterId={ this.state.currentClusterId } afterInstall={this.afterInstall} updatePackage={this.updatePackage} data-test= "cluster-info" />
                }
                {
                    this.state.mode === 3 &&
                    <CreateVCluster history={this.props.history}/>
                }
                {
                    this.state.isImportPopupOpen &&
                    <ClusterImportPopup open={ this.state.isImportPopupOpen }
                        closeHandler= { this.closeImportCluster } 
                        successHandler = { this.importSuccessHandler }
                        data-test= "import-cluster"
                    />
                }
                {/* <ClusterVPopup/> */}
                {
                    this.state.importClusterEditPopup &&
                    <ClusterImportPopup open={ this.state.importClusterEditPopup }
                        closeHandler= { this.importClusterEditDisagree } 
                        successHandler = { this.importClusterEditAgree }
                        cluster={this.state.currentCluster}
                        edit={true}
                        data-test= "import-popup"
                    />
                }
                
                <ConfirmDeletePopup
                    open={ this.state.isDeletePopupOpen}
                    handleAgree={this.handleAgreeHandler}
                    handleDisAgree={this.handleDisAgreeHandler}
                    message={`This action will permanently delete all the data. Please type "${this.state.currentClusterName}" to delete the Cluster : ${this.state.currentClusterName}`}
                    // yesText={t('App.AppInfo.yesText')}
                    // noText={t('App.AppInfo.noText')}
                    action="delete"
                    toMatchName={this.state.currentClusterName}
                    toDeleteModule="cluster"
                    loading={this.props.deletingCluster}
                    data-test= "confirm-delete-popup"
                />
                <ConfirmDeletePopup
                    open={ this.state.isDestroyPopupOpen}
                    handleAgree={this.handleDestroyAgreeHandler}
                    handleDisAgree={this.handleDestroyDisAgreeHandler}
                    message={t('Subscription.SubscriptionPage.confirmDelete')}
                    // yesText={t('App.AppInfo.yesText')}
                    // noText={t('App.AppInfo.noText')}
                    action="destroy"
                    toMatchName={this.state.currentClusterName}
                    toDeleteModule="cluster"
                    loading={this.props.destroyingCluster}
                    data-test= "delete-popup"
                />
                <ConfirmActionPopup 
                    open={this.state.isEditPopupOpen} 
                    handleAgree={this.handleEditAgreeHandler} 
                    handleDisAgree={this.handleEditDisAgreeHandler} 
                    message={t('Cluster.ClusterList.editCluster')}
                    yesText={t('App.AppInfo.yesText')} 
                    noText={t('App.AppInfo.noText')} 
                    data-test= "confirm"
                />
                <ConfirmActionPopup 
                    open={this.state.isApplyPopupOpen} 
                    handleAgree={this.handleApplyAgreeHandler} 
                    handleDisAgree={this.handleApplyDisAgreeHandler} 
                    message={t('Cluster.ClusterList.applyCluster')} 
                    yesText={t('App.AppInfo.yesText')} 
                    noText={t('App.AppInfo.noText')} 
                    data-test= "confirmPopup"
                /> 
                <ConfirmActionPopup 
                    open={ this.state.isEnablePopupOpen } 
                    handleAgree={this.handleEnableAgreeHandler} 
                    handleDisAgree={this.handleEnableDisAgreeHandler} 
                    message={ `Are you sure you want to ${ this.state.enableSelection && this.state.enableSelection.active ? 'enable' : 'disable' } cluster?` }
                    yesText={t('App.AppInfo.yesText')}
                    noText={t('App.AppInfo.noText')} 
                    data-test="confirm-popup"
                />        
                { this.props.deletingCluster && <BackdropLoader message={t('Cluster.ClusterList.deletingCluster')}  data-test= "delete-cluster"/> }
                { this.props.destroyingCluster && <BackdropLoader message={t('Cluster.ClusterList.destroyingCluster')}  data-test= "destroy-cluster"/> }
                { this.props.fetchingClusterList && <BackdropLoader message={t('Cluster.ClusterList.loadingCluster')} data-test= "fetch-cluster" /> }
                { this.props.exportingCluster && <BackdropLoader message={t('Cluster.ClusterList.exportingCluster')}  data-test= "export-cluster"/> }
                { this.props.applyingTerraform && <BackdropLoader message={t('Cluster.ClusterList.applyingCluster')} data-test= "apply-form" /> }
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    clusterList: state.ClusterReducer.clusterList,
    fetchingClusterList: state.ClusterReducer.fetchingClusterList,
    newClusterData: state.ClusterReducer.newClusterData,
    deletingCluster: state.ClusterReducer.deletingCluster,
    destroyingCluster: state.ClusterReducer.destroyingCluster,
    exportingCluster: state.ClusterReducer.exportingCluster,
    exportUrl: state.ClusterReducer.exportUrl,
    applyingTerraform: state.ClusterReducer.applyingTerraform,
    currentOrganization: currentOrganization(state),
    clusterPackageStatus: state.ClusterReducer.clusterPackageStatus,
    appliedClusters: state.ClusterReducer.appliedClusters,
    clusterDetails: state.ClusterReducer.clusterDetails,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    getClusterList : () => dispatch(getClusterList()),
    clearNewClusterData: () => dispatch(clearNewClusterData()),
    deleteCluster: (id:number) => dispatch(deleteCluster(id)),
    destroyCluster: (id:number) => dispatch(destroyCluster(id)),
    exportWorkflow: (id:number) => dispatch(exportWorkflow(id)),
    clearExportWorkflow: () => dispatch(clearExportWorkflow()),
    clearClusterInfo: () => dispatch(clearClusterInfo()),
    applyTerraform: (id:number, orgId:number, history:History|null) => dispatch(applyTerraform(id, orgId, history)),
    enableDisableCluster: (id:number, jsonBody:any) => dispatch(enableDisableCluster(id, jsonBody)),
    getClusterDetails: (id:number) => dispatch(getClusterDetails(id)),
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(ClusterList)));