import {
	Avatar,
	Divider,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	Card,
	CardHeader,
	CardContent,
	Collapse,
	Link,
	createStyles
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import PriorityHighOutlinedIcon from "@material-ui/icons/PriorityHighOutlined";
import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
// import ReadMoreReact from "read-more-react";
import Toast from "../../components/toast/Toast";
import paths from "../../constants/paths";
import { getDateInStandardFormat, getNotificationNavigationId } from "../../helpers/utils";
import './Notification.css';
import { navigationCheck, updateSeenUnseen } from "./redux/actions";
//import DashboardIcon from '@material-ui/icons/Dashboard';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ReplyIcon from '@material-ui/icons/Reply';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
//import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import KeyValueRow from "../../components/keyvaluerow/KeyValueRow";
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { switchOrg } from '../organization/redux/actions';
import { currentOrganization } from '../login/redux/selectors';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Dispatch } from "redux";
import { NotificationModel } from "../../models/Common.model";
import { History } from 'history';
import { CustomColors } from "../../constants/enums";
import palette from "../../theme/palette";
import LaunchIcon from '@material-ui/icons/Launch';

//import LaunchIcon from "@material-ui/icons/Launch";
// import UpdateIcon from '@material-ui/icons/Update';
//import GetAppIcon from '@material-ui/icons/GetApp';

/* istanbul ignore next */
const useStyles = makeStyles(()=>createStyles({
	primary: {
		fontSize: 12, 
		fontWeight: 700, 
		color: CustomColors.Info.Dark,
		lineHeight: '20px'
	},
	// gridPadding: {
	// 	padding: 10
	// },
	dateText: {
		fontSize: '9px',
		fontWeight: 400,
		color: "grey"
	}
}))

type NotificatonIcon = {
	notification: NotificationModel
}

type NotificationHeader = {
	notification: NotificationModel
}

type JsonBody = {
	seen: boolean,
	ids: number[],
}

interface Props extends PropsFromRedux {
	notification: NotificationModel,
	history: History,
	handleNotificationClose?:()=>void,
	source: number,
	length? : number,
	index? : number
}

export const getIconColor = (type: string) => {
	switch(type){
		case "info":
			return "primary"
		case "error":
			return "error"
		default:
			return "primary"
	}
}

export const NotificationIconNew = (props: NotificatonIcon) => {
	let icon: any = "";
	const color = getIconColor(props.notification?.type)
	switch (props.notification?.action) {
		case "created": icon = (<AddIcon fontSize="small" color={color} data-test="add"/>); break
		case "updated": icon = (<SystemUpdateAltIcon fontSize="small" color={color} className="transform-180" data-test="update"/>); break
		case "deleted": icon = (<DeleteOutlineIcon fontSize="small" color={color} data-test="delete" />); break

		case "started": icon = (<PlayArrowIcon fontSize="small" color={color} data-test="play"/>); break
		case "stopped": icon = (<StopIcon fontSize="small" color={color} data-test="stop"/>); break
		
		case "plugin-install": icon = (<ArchiveIcon fontSize="small" color={color} data-test="archive"/>); break
		case "plugin-uninstall": icon = (<UnarchiveIcon fontSize="small" color={color} data-test="unarchive"/>); break
		
		case "replied": icon = (<ReplyIcon fontSize="small" color={color} data-test="replied"/>); break
		case "assigned": icon = (<AssignmentIndOutlinedIcon fontSize="small" color={color} data-test="assigned"/>); break
			
		default: icon = (<PriorityHighOutlinedIcon fontSize="small" color={color} className="transform-180"/>);
		//case "created": icon = (<PriorityHighOutlinedIcon fontSize="small" color={color} className="transform-180" />); break
	}

	return <div className="iconCircle"> {icon} </div>;
}

// export const NotificationIcon = (props) => {
// 	let icon = "";
// 	/* <PersonAddOutlinedIcon fontSize="small" color="action" /> */	
// 	/* <EditOutlinedIcon fontSize="small" color="warning" /> */
// 	const color = getIconColor(props.notification?.type)
// 	switch (props.notification?.scope) {
// 		case "environment":
// 				icon = (
// 					<PriorityHighOutlinedIcon
// 						fontSize="small"
// 						color={color}
// 						className="transform-180"
// 					/>
// 				);
// 			break
// 		case "project":
// 			icon = (
// 				<DashboardIcon
// 					fontSize="small"
// 					color={color}
// 					className="transform-180"
// 				/>
// 			)
// 			break
// 		case "support":
// 			if(props.notification.action === "created"){
// 				icon = (
// 					<NoteAddOutlinedIcon
// 						fontSize="small"
// 						color={color}
// 						// className="transform-180"
// 					/>
// 				)
// 			}else if(props.notification.action === "replied"){ 
// 				icon = (
// 					<ReplyIcon
// 						fontSize="small"
// 						color={color}
// 						// className="transform-180"
// 					/>
// 				)
// 			}else if(props.notification.action === "assigned"){ 
// 				icon = (
// 					<AssignmentIndOutlinedIcon
// 						fontSize="small"
// 						color={color}
// 						// className="transform-180"
// 					/>
// 				)
// 			}else {
// 				icon = (
// 					<HelpOutlineIcon
// 						fontSize="small"
// 						color={color}
// 						// className="transform-180"
// 					/>
// 				)
// 			}
// 			break
// 		default:
// 			icon = (
// 				<PriorityHighOutlinedIcon
// 					fontSize="small"
// 					color={color}
// 					className="transform-180"
// 				/>
// 			);
// 	}
// 	return <div className="iconCircle"> {icon} </div>;
// };

export const NotificationHeaderTitle = (props: NotificationHeader) => {
	const classes = useStyles();
	const { notification } = props;
	return (
		<Grid container alignItems="center" style={{cursor: 'pointer'}}>
			<Grid item xs={2} sm={1} style={{maxWidth: '6%'}}>
				<NotificationIconNew notification={notification} />
			</Grid>
			<Grid item xs={10} sm={11}>
				<Grid container alignItems="center">
					<Grid
						item
						xs={12}
						sm={7}
						//className={`${getNotificationNavigationId(notification) ? 'link-pointer' : ""} ${notification.seen ? "" : "unread"}`}
						// className={`${notification.seen ? "" : "unread"}`}
						// onClick={() => notificationNavigation()}
						data-test="notyBodytext"
					>
						{/* <ReadMoreReact
									text={props.notification?.body ?? ""}
									min={20}
									ideal={80}
									max={200}
									data-test="readmorecomponent"
									className="read-more-button"
									readMoreText="..See More"
								/> */}
						<Typography color="textPrimary" variant="body2" data-test="notyBody">{notification.body}</Typography>
					</Grid>
					<Grid item xs={12} sm={2}>
						{notification.triggered_by_name && notification.triggered_by_name !== "" &&
							<Grid container spacing={2} alignItems="center">
								<Grid item><Avatar /></Grid>
								<Grid item>{notification.triggered_by_name}</Grid>
							</Grid>
						}
					</Grid>
					<Grid item xs={12} sm={3} data-test="notyCreateTime">
						<Typography color="textSecondary" display="block" align="right" variant="caption">{getDateInStandardFormat(notification.create_timestamp * 1000)}</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export const NotificationRow = (props: Props) => {
	const matches = useMediaQuery("(max-width:600px)");
	const classes = useStyles();
	const {notification}= props
	const [expanded, setExpanded] = useState(false);
	const [ orgSwitch, setOrgSwitch ] = useState(false);
	const [ path, setPath ] = useState('');
	const [orgId,setCurrentOrgId] = useState<any>();
	const [t] = useTranslation();

	/* istanbul ignore next */
	const existanceCallBack = (data: any, module: string, id: number) => {
		if (data) {
			let changePath = "";
			switch (module) {
				case "environment":
					changePath = paths.ENVIRONMENTINFO.replace(":eId", id.toString());
					break;
				case "application":
					changePath = paths.APPDETAILS.replace(":appId", id.toString());
					break;
				case "project":
					changePath = paths.PROJECTINFO.replace(":id", id.toString());
					break;
				case "support":
					changePath = paths.TICKET_DETAIL.replace(":id", id.toString());
					break;
				default:
					changePath = "";
			}
			if (props.history && changePath) {
				setPath(changePath)
			}
		} else {
			Toast.error(
				"The " +
				module +
					" you are looking for, doesn't exist anymore"
			);
		}
		if(props.handleNotificationClose){
		props.handleNotificationClose();
		}
	};

	const notificationNavigation = () => {
		let jsonBody = {
			seen: true,
			ids: [notification.id],
		};
		if(!notification.seen){
			props.updateSeenUnseen(jsonBody);
		}
		if(getNotificationNavigationId(notification)){
			props.navigationCheck(
				notification.scope,
				// notification.model_id,
				getNotificationNavigationId(notification),
				existanceCallBack
			);
		}else{
			// Toast.info("Cannot navigate to the " + notification.scope + " module.")
			// if(props.handleNotificationClose){
			// props.handleNotificationClose();
			// }
		}
		// if(props.handleNotificationClose){
		// 	props.handleNotificationClose();
		// }
		
	};

	const expandNotification = () => { 
		setExpanded(!expanded);
		let jsonBody = {
			seen: true,
			ids: [notification.id],
		};
		if (!notification.seen) {
			props.updateSeenUnseen(jsonBody);
		}
	}

	const navigate = (type: string, value: number, organization_id?: number) => { 
		let _current = props.currentOrganization?.id ?? 0;
		if(organization_id &&  organization_id >= 0){
			if(organization_id !== _current){
				setOrgSwitch(true);
				setCurrentOrgId(organization_id);
			}
			else{
				setOrgSwitch(false);
			}
		}
		props.navigationCheck(
			type,
			value,
			existanceCallBack
		);
	}
    
	/*istanbul ignore next */
	useEffect(() => {
		if(path && orgSwitch){	
			props.switchOrg(orgId, props.history, path)
		}else if(path && !orgSwitch){
			props.history.push(path)
		}
	}, [path])

	const handleOrgClick = (id: number) => {
		props.switchOrg(id, props.history)
	}
	
	
	return (
		<>
			{props.source === 1 && (
				<>
					<ListItem
						alignItems="flex-start"
						className={`${getNotificationNavigationId(notification) ? 'link-pointer' : ""} ${notification.seen ? "m-b-5" : "unread"}`}
						onClick={() => notificationNavigation()}
						data-test="listItem"
					>
						<ListItemAvatar>
							<NotificationIconNew notification={notification} />
						</ListItemAvatar>
						<ListItemText
							classes = {notification.seen ? { primary: classes.primary } :{ primary: classes.primary }}
							primary={notification.body}
							secondary={
								<React.Fragment>
									<Typography
										component="span"
										variant="body2"
										color="textSecondary"
										//className={classes.block}
										className={classes.dateText}
									>
										{getDateInStandardFormat(
											notification.create_timestamp * 1000
										)}
									</Typography>
								</React.Fragment>
							}
						/>
					</ListItem>
					{/* <Divider variant="inset" component="li" /> */}
				</>
			)}
			{props.source === 2 && notification && (
				<Card data-test="notyRowContainer" className={notification.seen ? "" : "unreadCard"} elevation={props.index === props.length ? 0 : 6}> 
					<CardHeader
						// className={}
						onClick={() => expandNotification()}
						title={<NotificationHeaderTitle notification={notification} />}
						data-test="cardHeader"
					/>
					<Collapse in={expanded}>
						<CardContent style={{background: palette.white, padding: 24}}>
							<Grid container spacing={2} direction="column">
								<Grid item>
									{
										notification.organization_id > 0 && notification.organization_name &&
										<KeyValueRow
											keyXs={6}
											keyMd={2}
											rowKey={t('organization')}
											rowValue={
													<Link
														onClick={() => handleOrgClick(notification.organization_id)}
														underline="always"
														style={{cursor: "pointer"}}
													> 
														<LaunchIcon className="openLinkIconNotificationNotification" />
														{notification.organization_name}
														
													</Link>
												
											}
											data-test="keyValue"
										/>
									}
								</Grid>
								<Grid item>
									{
										notification.project_id > 0 &&
										<KeyValueRow
											keyXs={6}
											keyMd={2}
											rowKey={t('Support.TicketDetail.project')}
											rowValue={
													<Link
														href="#"
														onClick={() => navigate("project", notification.project_id, notification.organization_id)}
														underline="always" 
													>
														<LaunchIcon className="openLinkIconNotification" />
														{notification.project_name}
													</Link>
											}
											data-test="projectValue"
										/>
									}
								</Grid>
								<Grid item>
									{
										notification.application_id > 0 &&
										<KeyValueRow
											keyXs={6}
											keyMd={2}
											rowKey={t('Support.TicketDetail.application')}
											rowValue={
													<Link
														href="#"
														onClick={() => navigate("application", notification.application_id,notification.organization_id)}
														underline="always"
													>
														<LaunchIcon className="openLinkIconNotification" />
														{notification.application_name}
													</Link>
											}
											data-test="appValue"
										/>
									}
								</Grid>
								<Grid item>
									{
										notification.environment_id > 0 &&
										<KeyValueRow
											keyXs={6}
											keyMd={2}
											rowKey={t('Support.TicketDetail.environment')}
											rowValue={
													<Link
														href="#"
														onClick={() => navigate("environment", notification.environment_id,notification.organization_id)}
														underline="always"
													>
														<LaunchIcon className="openLinkIconNotification" />
														{notification.environment_name}
													</Link>

											}
											data-test="envValue"
										/>
									}
								</Grid>
								<Grid item>
									{
										notification.scope === "support" &&
										<KeyValueRow
											keyXs={6}
											keyMd={2}
											rowKey={t('Support.SupportPage.id')}
											rowValue={
													<Link
														href="#"
														onClick={() => navigate("support", notification.scope_id)}
														underline="always"
													>
														<LaunchIcon className="openLinkIconNotification" />
														{ "#" + notification.scope_id}
													</Link>
											}
											data-test="support"
										/>
									}
								</Grid>
							</Grid>
						</CardContent>
					</Collapse>
				</Card>
			)}
		</>
	);
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
	currentOrganization: currentOrganization(state),
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
	return {
		updateSeenUnseen: (jsonBody: JsonBody) => dispatch(updateSeenUnseen(jsonBody)),
		navigationCheck: (module: string, id: number, callBack:(data: any, module: string, id: number)=>void) =>
			dispatch(navigationCheck(module, id, callBack)),
		switchOrg: (id: number, history: History, path?: string) => dispatch(switchOrg(id, history, path))
	};
};

// export default connect(mapStateToProps, mapDispatchtoProps)(NotificationRow);


const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(NotificationRow);