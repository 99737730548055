import { Button, CssBaseline, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Formik,
} from 'formik';
import * as Yup from 'yup';
import MuiTextField from '../../components/textfield/MuiTextField';
import { forgotPassword } from '../registration/redux/actions';
import './forgotpassword.css';
import ReCAPTCHA from "react-google-recaptcha";
import { WithStyles, createStyles } from "@material-ui/core";
import { Dispatch } from "redux";
import { Theme } from "@material-ui/core";
import LandingScreen from '../../components/companybanner/LandingScreen';
import { ForgotpasswordLockIncon } from '../../helpers/customIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import palette from '../../theme/palette';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { AuthformHeader } from '../../components/authformheader/AuthformHeader';
/* istanbul ignore next */
const useStyles = (theme: Theme) => createStyles({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/infographics/login.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[ 50 ] : theme.palette.grey[ 900 ],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    // margin: theme.spacing(10, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft : '15%',
    paddingRight : '15%',
    [ theme.breakpoints.down('sm') ]: {
      paddingLeft : '2.5%',
      paddingRight : '2.5%',
      margin: theme.spacing(10, 6),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1.5, 0, 2),
  }
});

type Payload = {
  email: string,
  captcha_code: string | null
}

interface Props extends WithTranslation, PropsFromRedux, WithStyles<typeof useStyles> {
  
}

type State = {
  captcha_code: string | null,
}

export class ForgotPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {captcha_code: ""};
  }

  recaptchaRef = React.createRef<ReCAPTCHA>();

  /* istanbul ignore next */
  // handleSubmitOnEnter = (e) => {
  //     var _key = e.which || e.keyCode;
  //     if(_key === 13) {
  //         this.handleSubmit();
  //     }
  // }

  onChangeRecaptcha = (token: any) => {
    this.setState(
      {
        captcha_code: token?token:null,
      }
    );
  };

  render () {
    const { t, classes, isRequestingForgotPassword } = this.props;
    return (
        <Grid container component="main" className={ classes.root } data-test="main-container">
            {/* <CssBaseline /> */}
            {/* <CompanyBanner /> */}
            <LandingScreen mode={2}/>
            <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={6} style={{padding: '16px 24px'}}>
                {/* <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <img
                      id="imgLogo"
                      src="/images/logos/logo-blue.svg"
                      alt="01Cloud"
                      className="authlogo"
                      height={40}
                      width={40}
                    />
                  </Grid>
                  <Grid item container xs={6} justifyContent="flex-end">
                    <Link to='/' className="textDecorationNone" style={{display: 'flex', alignItems: 'center', gap: 8}}>
                      <SettingsIcon style={{color: palette.text.secondary}}/>
                      <Typography variant="subtitle2">Need help?</Typography>
                    </Link>
                  </Grid>
                </Grid> */}
                <AuthformHeader />
                {/* <div className={classes.contentHeader}>
                    <Link to="/" className='textDecorationNone'>
                        <IconButton >
                            <ArrowBackIcon />
                        </IconButton>
                    </Link>
                </div> */}
                <Grid container justifyContent="center" alignContent="center" style={{height: "100%"}}>
                  <Grid item xs={12} md={9}>
                    <div className={ classes.paper }>
                
                        {/* <img src="/images/logos/logo-blue.svg" alt="01Cloud" className='authlogo'/> */}
                        <div style={{marginBottom: 24}}>
                          <ForgotpasswordLockIncon viewBox='0 0 96 96' style={{width: 96, height: 96}}/>
                        </div>
                        {/* <Typography align='center' variant="h5">{t('ForgotPasswordResetText')}</Typography> */}
                        <Typography align='center' variant="h5">Forgot your password?</Typography>
                        <Typography align='center' variant="body2" color='textSecondary' style={{marginTop: 8, marginBottom: 40}}>Please enter the verified email in below box</Typography>
                        <Formik initialValues={ { email:"" } } 
                          data-test="formik-component"
                          onSubmit={ (values, { setSubmitting }) => {
                              setSubmitting(true);
                              const payload = {
                                email: values.email,
                                captcha_code: this.state.captcha_code
                            }
                            this.props.forgotpwdAction(payload)
                          this.recaptchaRef.current &&  this.recaptchaRef.current.reset();
                            this.setState({captcha_code: ""})
                            } }
                          validationSchema = { 
                                Yup.object().shape({
                                  email : Yup.string().email().required('Please enter your email address'),
                                }) }
                        >
                            {
                            (props) => {
                              const {
                                values,
                                touched,
                                errors,
                                dirty,
                                // isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                // handleReset,
                                isValid,
                              } = props;
                              return (
                                  <form onSubmit={ handleSubmit } className={ classes.form }>
                                      <MuiTextField 
                                        data-test="email-input"
                                        id="email" 
                                        error={Boolean(errors.email && touched.email)}
                                        label='Email Address' 
                                        name="email" 
                                        color='primary'
                                        onChange={ handleChange } 
                                        value={ values.email }
                                        onBlur={ handleBlur }
                                        helperText={ (errors.email && touched.email) && errors.email }
                                        margin="normal"
                                        disabled={isRequestingForgotPassword}
                                      />
                                      <div className='registrationButtonDiv'>
                                        <ReCAPTCHA
                                          data-test="captcha"	
                                          ref={this.recaptchaRef}
                                          sitekey={window?.config?.REACT_APP_RECAPTCHA_SITEKEY}
                                          onChange={this.onChangeRecaptcha}
                                        />
                                      </div>
                                      <div className='forgotpasswordButtonDiv'>
                                          <Button
                                            data-test="forgot-btn"
                                            variant="contained"
                                            type='submit'
                                            color='primary'
                                            // align='center'
                                            className="w-100"
                                            disabled={ !(isValid && dirty) || isRequestingForgotPassword || !this.state.captcha_code}
                                            endIcon={
                                              isRequestingForgotPassword ? (
                                                <CircularProgress size={18} thickness={4.5} />
                                              ) : null
                                            }
                                          >
                                              {t('ForgotPasswordSendRequest')}
                                          </Button>
                                      </div>
                                      {/* <div className='signInDiv'>
                                        <Link to="/" variant="body2" className='textDecorationNone'>
                                            <Typography variant="body1" className='signInLink'>{t('ReturnToSignIn')}</Typography>
                                        </Link>
                                      </div> */}
                                      {/* <Typography className='links'>
                                          <div className='containerDiv'>
                                              <Link href="#" className='TermsLink'>
                                                  {t('TermsOfUse')}
                                              </Link>
                                              <Link href="#" className='PolicyLink'>
                                                  {t('Policy')}
                                              </Link>
                                          </div>
                                      </Typography> */}
                                  </form>
                              )
                            }
                          }
                        </Formik>
                        {/* <p>Note: If you don&apos;t see the email in your Inbox, check your spam folder.</p> */}
                        <Link to='/' className="textDecorationNone">
                          <Typography variant='subtitle2' style={{display: 'flex', alignItems:'center', marginTop: 24}}>
                            <ArrowBackIosIcon fontSize='small' style={{width: 16, height: 16}}/>
                            Return to sign in
                          </Typography>
                        </Link>
                    </div>
                  </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  isRequestingForgotPassword: state.RegisterReducer.isRequestingForgotPassword
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    forgotpwdAction : (payload: Payload) => dispatch(forgotPassword(payload)),
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(ForgotPassword)));