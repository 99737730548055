import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Tooltip,
  Link,
  createStyles,
  makeStyles,
  Button
} from "@material-ui/core";
//import { makeStyles } from "@material-ui/styles";

import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import CustomButton from "../custombutton/CustomButton";
import BackdropLoader from "../loader/BackdropLoader";
import ConfirmActionPopup from "../confirmactionpopup/ConfirmActionPopup";
import { updateFileManagerStatus } from "../../pages/environment/redux/actions";
import { getStatusColor, getDateInStandardFormat, isAuthorized } from "../../helpers/utils";
import KeyValueRow from "../keyvaluerow/KeyValueRow";
import AdjustIcon from "@material-ui/icons/Adjust";
import LaunchIcon from "@material-ui/icons/Launch";
import { EnvironmentState } from "../../models/Environment.model";
import Alert from '@material-ui/lab/Alert';
import { Dispatch } from "redux";
import { Theme } from "@material-ui/core";
import { CustomColors } from "../../constants/enums";
interface Props extends PropsFromRedux {
  // environmentDetails: EnvironmentModel,
  environmentState:EnvironmentState,
}

const useStyles = makeStyles((theme:Theme) => createStyles({
  credMsgDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 10,
  },
  muiAlert:{[theme.breakpoints.up(theme.breakpoints.values.xs)]: {
    width:"100%",
    backgroundColor: CustomColors.Info.Lighter,
    borderRadius: 8
   } },
  credMsg: { 
    // fontStyle: "italic" 
    color: CustomColors.Info.Darker
  },
}));
export const FileManager = (props: Props) => {
  const classes = useStyles();
  const [isFileManagerPopupOpened, setIsFileManagerPopupOpened] =
    useState(false);

    const [showPendingText,setShowPendingText]=useState(false);
    const [credMsg,setShowCredMsg]=useState(false);

    useEffect(() => {
      if (!props.environmentDetails.file_manager_enabled) {
        return;
      }
      const _initial = props.environmentDetails.file_manager_enabled;
      const fromTime = new Date(_initial);
      const toTime = new Date();

      const differenceTravel = toTime.getTime() - fromTime.getTime();
      const seconds = Math.floor(differenceTravel / 1000);
      if (seconds < 2) {
        setShowCredMsg(true);
      }
      let timeDiff = 15 - seconds;
      if (seconds < 15) {
        setShowPendingText(true)
        setTimeout(() => {
          setShowPendingText(false);
        }, timeDiff * 1000);
      }
    }, [props.environmentDetails.file_manager_enabled]);
  const [t] = useTranslation();

  const handleFileManagerStatusUpdate = () => {
    setIsFileManagerPopupOpened(true);
  };

  const handleFileManagerStatusUpdateAgree = () => {
    props.updateFileManagerStatus(
      props.environmentDetails.id,
      !props.environmentDetails.file_manager_enabled
    );
    setIsFileManagerPopupOpened(false);
  };

  const handleFileManagerStatusUpdateDisagree = () => {
    setIsFileManagerPopupOpened(false);
  };

  const hideCredMessage=()=>{
    setShowCredMsg(false)
  }

  return (
    <>
      <Card className="m-b-20" data-test="file-manager-container">
        <CardHeader
          title={
            <Typography color="inherit" variant="h6">
              {t("Environment.StorageTab.fileManager")}
            </Typography>
          }
          action={
            isAuthorized("update", props.envRole?.name) && (
              // <CustomButton
              //   label={
              //     props.environmentDetails?.file_manager_enabled
              //       ? t("Environment.StorageTab.disable")
              //       : t("Environment.StorageTab.enable")
              //   }
              //   onClick={handleFileManagerStatusUpdate}
              //   data-test="enable-disable-button"
              // />
              <Button
                onClick={handleFileManagerStatusUpdate}
                data-test="enable-disable-button"
                variant="contained"
                color='primary'
                style={{marginRight: 48, marginTop: 16}}
              >
                {props.environmentDetails?.file_manager_enabled
                    ? t("Environment.StorageTab.disable")
                    : t("Environment.StorageTab.enable")
                }
              </Button>
            )
          }
          data-test="card-header"
        />
        <Divider />
        <CardContent>
          {!props.environmentDetails?.file_manager_enabled ? (
            <Typography
              // color="caption2"
              // variant="body"
              data-test="file-manager-helper"
            >
              {t("Environment.StorageTab.fileManagerHelper")}
            </Typography>
          ) : (
            <div>
              <KeyValueRow
                keyXs={6}
                rowKey={t("Environment.AddonInfo.status")}
                rowValue={
                  <div>
                    <Tooltip title={t("Environment.AddonInfo.status")}>
                      <AdjustIcon
                        className="topIcon"
                        style={{
                          color:showPendingText?"#FF9800": getStatusColor(props.environmentState?.state),
                        }}
                      />
                    </Tooltip>
                    <span
                      style={{
                        color:showPendingText?"#FF9800" :getStatusColor(props.environmentState?.state),
                      }}
                      // variant="body2"
                      data-test="filemanager-status"
                    >
                      {showPendingText?"Pending":props.environmentState?.state}
                    </span>
                  </div>
                }
                // variant="h5"
                data-test="status-row"
              />
              <KeyValueRow
                keyXs={6}
                rowKey={t("Environment.AddonInfo.created")}
                rowValue={getDateInStandardFormat(
                  props.environmentDetails.file_manager_enabled
                )}
                // variant="h5"
                data-test="created-at-row"
              />
              <KeyValueRow
                keyXs={6}
                rowKey="Used Resource"
                rowValue="0.1 Core / 128 MB Memory"
                // variant="h5"
                data-test="used-resource-row"
              />
              {props.environmentState.cname && !showPendingText &&(
                <KeyValueRow
                  keyXs={6}
                  rowKey={t("Environment.StorageTab.accessUrl")}
                  rowValue={
                    <Link
                      href={`https://${props.environmentState.cname}/filemanager`}
                      target="_blank"
                      className="cnameLink oneLine"
                      rel="noreferrer"
                      underline="always"
                      data-test="filemanager-link"
                    >
                      {`${props.environmentState.cname}/filemanager`}
                      <LaunchIcon className="openLinkIcon" />
                    </Link>
                  }
                  // variant="h5"
                  data-test="link-row"
                />
              )}
              {credMsg && (
                <div className={classes.credMsgDiv}>
                  <Alert severity="info" onClose={hideCredMessage} color="info">
                    <Typography variant="body2">
                        Default credential for file manager is admin/admin. Please
                        change password when you login for first time
                    </Typography>
                  </Alert>
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
      <ConfirmActionPopup
        open={isFileManagerPopupOpened}
        handleAgree={handleFileManagerStatusUpdateAgree}
        handleDisAgree={handleFileManagerStatusUpdateDisagree}
        yesText={t("Projects.VariablesTab.yesText")}
        noText={t("Projects.VariablesTab.noText")}
        message={
          props.environmentDetails?.file_manager_enabled
            ? "Are you sure you want to disable file manager?"
            : "Are you sure you want to enable file manager?"
        }
      />
      {props.updatingFileManagerStatus && (
        <BackdropLoader message="Updating file manager status" />
      )}
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  updatingFileManagerStatus: state.EnvironmentReducer.updatingFileManagerStatus,
  environmentState: state.EnvironmentReducer.environmentState,
  envRole: state.EnvironmentReducer.envRole
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    updateFileManagerStatus: (id: number, status: null | boolean) =>
      dispatch(updateFileManagerStatus(id, status)),
  };
};

// export default connect(mapStateToProps, mapDispatchtoProps)(FileManager);

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FileManager);
