export const CreateApp_TemplateTypes = {
    default : 0,
    git: 1,
    cr: 2,
    helm:3,
    op: 4,
    manageDB: 5
};

export const Get_Enum_Description = (type, value) => {
    let name = '';
    for (const key in type) {
        if (type[key] === value)
            name = key;
    }
    return name;
}

export const Cluster_Scope = [
    { key:'Shared Cluster', value:0 },
    { key:'Orgnization Cluster', value:1 }
]

export const Get_Enum_Key = (type, value) => {
    let name = '';
    const obj = type.find( x => x.value === value);
    if(obj)
    {
        name = obj.key;
    }
    return name;
}

export const CustomColors = {
    Secondary: {
        Darker:"#27097A",
        Dark:"#5119B7",
        Main:"#8E33FF",
        Light:"#C684FF",
        Lighter:"#EFD6FF"
    },
    Info: {
        Darker:"#003768",
        Dark:"#006C9C",
        Main:"#00B8D9",
        Light:"#61F3F3",
        Lighter:"#CAFDF5"
    },
    Success: {
        Darker:"#065E49",
        Dark:"#118D57",
        Main:"#22C55E",
        Light:"#77ED8B",
        Lighter:"#D3FCD2"
    },
    Warning: {
        Darker:"#7A4100",
        Dark:"#B76E00",
        Main:"#FFAB00",
        Light:"#FFD666",
        Lighter:"#FFF5CC"
    },
    Error: {
        Darker:"#7A0916",
        Dark:"#B71D18",
        Main:"#FF5630",
        Light:"#FFAC82",
        Lighter:"#FFE9D5"
    },
    Grey: {
        Disabledbg: "#919EAB14",
        border:'#C4CDD5',
        700: '#454F5B',
        Background: 'rgba(145, 158, 171, 0.08)'
    },
    other:{
        icon:"#1D7BF5",
        danger: "#EC221F",
        border: "#919EAB52",
        chartborder: "#898989",
        betatagbackground: '#EC914E'
    }
}
