import React from "react";
import { Grid, Typography } from "@material-ui/core";
import InfoPopup from "./InfoPopup";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  fixedHeight: {
    height: "20px !important",
  },
}));

interface Props {
  scope?: string;
  message: string | JSX.Element;
  url?: string;
  urlText?: string;
  label: string;
}
export default function InfoPopupContainer(props: Props) {
  const classes = useStyles();
  return (
    <Grid
      container
      style={{ height: "20px !important" }}
      data-test="main-container"
    >
      <Grid item classes={{ item: classes.fixedHeight }}>
        <Typography variant="body1" data-test="label-container">
          {" "}
          {props.label}{" "}
        </Typography>
      </Grid>
      <Grid item classes={{ item: classes.fixedHeight }}>
        <InfoPopup {...props} />
      </Grid>
    </Grid>
  );
}
