import { CustomColors } from "../../constants/enums";

export default {
    standardInfo: {
        backgroundColor: CustomColors.Info.Lighter, 
        color: CustomColors.Info.Darker, 
        // width: "100%",
        borderRadius: 8
    },
    standardSuccess: {
        backgroundColor: CustomColors.Success.Lighter,
        color: CustomColors.Info.Darker,
        borderRadius: 8
    },
    standardWarning: {
        backgroundColor: CustomColors.Warning.Lighter,
        color: CustomColors.Info.Darker,
        borderRadius: 8
    },
    standardError: {
        backgroundColor: CustomColors.Error.Lighter,
        color: CustomColors.Info.Darker,
        borderRadius: 8
    },
}