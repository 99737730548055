import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiCard from './MuiCard';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiInputLabel from './MuiInputLabel';
import MuiInputBase from './MuiInputBase';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiLinearProgress from './MuiLinearProgress';
import MuiAlert from './MuiAlert';
import MuiTableContainer from './MuiTableContainer';
import MuiDialog from './MuiDialog';
import MuiDialogContent from './MuiDialogContent';
import MuiFormHelperText from './MuiFormHelperText';
import MuiDialogActions from './MuiDialogActions';
import MuiSwitch from './MuiSwitch';
import MuiMenuItem from './MuiMenuItem';
import MuiMenu from './MuiMenu';
import MuiList from './MuiList';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiCard,
  MuiTab,
  MuiTabs,
  MuiInputLabel,
  MuiInputBase,
  MuiOutlinedInput,
  MuiLinearProgress,
  MuiAlert,
  MuiTableContainer,
  MuiDialog,
  MuiDialogContent,
  MuiFormHelperText,
  MuiDialogActions,
  MuiSwitch,
  MuiMenuItem,
  MuiMenu,
  MuiList
};
