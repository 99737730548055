import React, { useState, useMemo } from 'react'
import {
    Grid,
    Select,
    MenuItem,
    FormControl,

} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { convertToChartData, formatSizeUnits } from '../../../helpers/utils';
import ChartCard from '../../chartcard/ChartCard';
import CPUChartCard from '../../chartcard/CPUChartCard';
import RAMChartCard from '../../chartcard/RAMChartCard';
import { EnvironmentModel, EnvironmentInsight, InsightData } from '../../../models/Environment.model';

type Hpa_scaler = {
    running: InsightData[],
    desired: InsightData[],
    max_replica: InsightData[],
    min_replica: InsightData[],
    current_max_replica: InsightData[]
}

type Props = {
    selectedNameSpace: string,
    environmentOverviewInsights: EnvironmentInsight,
    hpaInsight: Hpa_scaler,
    getUsageData: (duration: any) => void,
    environmentDetails: EnvironmentModel
}

export default function OverviewInsights(props: Props) {
    const [t] = useTranslation()
    const [selectedDuration, setSelectedDuration] = useState("Hour")

    const [data_disk_usage, unitObjectDisk] = useMemo(() => {
        const _data_disk_usage: any =
            props.selectedNameSpace &&
            (props.environmentOverviewInsights?.disk_usages ?? []).filter(
                (itm) => itm.metric.namespace === props.selectedNameSpace
            );
        const maxOfDisk =
            _data_disk_usage && _data_disk_usage.length > 0
                ? Math.max(..._data_disk_usage[0].values.map((o: any) => o[1]), 0)
                : 0;
        const _unitObjectDisk: any = formatSizeUnits(maxOfDisk, 2);
        return [_data_disk_usage, _unitObjectDisk]
    }, [props.selectedNameSpace, props.environmentOverviewInsights, selectedDuration])

    const [hpa_insight_data] = useMemo(() => {
        const { hpaInsight, selectedNameSpace } = props

        let hpa_running = selectedNameSpace && hpaInsight?.running?.filter((itm) => itm.metric.namespace === selectedNameSpace);

        let hpa_desired = selectedNameSpace && hpaInsight?.desired?.filter((itm) => itm.metric.namespace === selectedNameSpace);

        let hpa_max_replica = selectedNameSpace && hpaInsight?.max_replica?.filter((itm) => itm.metric.namespace === selectedNameSpace);

        let hpa_min_replica = selectedNameSpace && hpaInsight?.min_replica?.filter((itm) => itm.metric.namespace === selectedNameSpace);

        let _hpa_insight_data: any = []
        const _temp_data = [
            {
                running: hpa_running && hpa_running[0]?.values,
                desired: hpa_desired && hpa_desired[0]?.values,
                hpa_insight_max_replica: hpa_max_replica && hpa_max_replica[0]?.values,
                hpa_insight_min_replica: hpa_min_replica && hpa_min_replica[0]?.values,
            }
        ]
        _hpa_insight_data = convertToChartData(_temp_data, 6, { DivideBy: 1 })
        return [_hpa_insight_data]
    }, [props.selectedNameSpace, props.hpaInsight, selectedDuration])

    const handleDurationChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedDuration(e.target.value as string)
        props.getUsageData({
            duration: e.target.value
        }  )
    }
    return (
        <div className="m-t-20" data-test="overviewInsightContainer">
            <Grid container justify="flex-end" spacing={1}>
                <Grid item>
                    <FormControl
                        variant="outlined"
                    >
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedDuration}
                            onChange={handleDurationChange}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem value={"Hour"}>{t('Environment.OverviewTab.hour')}</MenuItem>
                            <MenuItem value={"Day"}>{t('Environment.OverviewTab.day')}</MenuItem>
                            <MenuItem value={"Week"}>{t('Environment.OverviewTab.week')}</MenuItem>
                            <MenuItem value={"Month"}>{t('Environment.OverviewTab.month')}</MenuItem>
                            {/* <MenuItem value={'Year'}>1 year</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <div className="m-t-20">
                <Grid container spacing={3}>
                    {
                        <Grid item sm={12} xs={12} md={12}>
                            <ChartCard
                                title={t('Environment.OverviewTab.diskUsage')}
                                data={
                                    data_disk_usage?.length > 0
                                        ? convertToChartData(
                                            data_disk_usage[0].values,
                                            4,
                                            unitObjectDisk
                                        )
                                        : []
                                }
                                lineColor="lightBlue"
                                suffix={unitObjectDisk?.Unit}
                                maxY={(props.environmentOverviewInsights?.total_pv * 1024 * 1024 * 1024) / unitObjectDisk.DivideBy}
                                ytitle="Memory"
                                xtitle="Time"
                            />
                        </Grid>
                    }
                    {
                        <Grid item sm={6} xs={12} md={6}>
                            <CPUChartCard
                                source="overview"
                                replicas={props.environmentDetails?.replicas}
                                selectedPod={props.selectedNameSpace}
                                environmentInsights={props.environmentOverviewInsights}
                            />
                        </Grid>
                    }
                    {
                        <Grid item sm={6} xs={12} md={6}>
                            <RAMChartCard
                                source="overview"
                                replicas={props.environmentDetails?.replicas}
                                selectedPod={props.selectedNameSpace}
                                environmentInsights={props.environmentOverviewInsights}
                            />
                        </Grid>
                    }
                    {props.environmentDetails?.auto_scaler?.enabled &&
                        <Grid item sm={12} xs={12} md={12}>
                            <ChartCard
                                title="Autoscale"
                                data={hpa_insight_data ? hpa_insight_data : []}
                                source={"hpa"}
                                lineColor="lightPink"
                                suffix={" Pod"}
                                maxY={props.hpaInsight?.current_max_replica}
                                ytitle="Pods"
                                xtitle="Time"
                                data-test="hpaScalerChart"
                            />
                        </Grid>
                    }
                </Grid>
            </div>
        </div>
    )
}
