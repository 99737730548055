import React from "react";
import ChartCard from "./ChartCard";
import { withTranslation } from "react-i18next";
import { convertToChartData, formatSizeUnits } from "../../helpers/utils";

export const RAMChartCard = (props) => {
  const { t, environmentInsights, selectedPod, replicas } = props;

  var data_memory_usage = null,
    maxOfRAM = null,
    unitObjectRAM = null;

  data_memory_usage = props.source === "overview" ?
    selectedPod &&
    environmentInsights?.memory_usages?.length > 0 &&
    environmentInsights.memory_usages.filter(
      (itm) => itm.metric.namespace === selectedPod
    ) : selectedPod &&
    environmentInsights?.memory_usages?.length > 0 &&
    environmentInsights.memory_usages.filter(
      (itm) => itm.metric?.pod ? itm.metric.pod === selectedPod : itm.metric?.container === selectedPod
    );
  maxOfRAM =
    data_memory_usage && data_memory_usage.length > 0
      ? Math.max(...data_memory_usage[0].values.map((o) => o[1]), 0)
      : 0;
  unitObjectRAM = formatSizeUnits(maxOfRAM, 2);

  var maxY =
    (environmentInsights?.total_memory * 1024 * 1024) / unitObjectRAM.DivideBy;
  maxY = props.source === "overview" ? maxY * replicas : maxY;

  return (
    <ChartCard
      title={t("Environment.OverviewTab.ram")}
      data={
        data_memory_usage && data_memory_usage.length > 0
          ? convertToChartData(data_memory_usage[0].values, 3, unitObjectRAM)
          : []
      }
      lineColor={"blue"}
      suffix={unitObjectRAM && unitObjectRAM.Unit}
      maxY={maxY}
      ytitle={"Memory"}
      xtitle="Time"
      data-test="chart-card"
    />
  );
};

export default withTranslation()(RAMChartCard);
