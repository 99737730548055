import { Box, Button, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NoContentImage from "../../components/nocontentimagecontainer/NoContentImage";
import { getClusterScanReport, getScanClusterPlugin } from "./redux/actions";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import BackdropLoader from "../../components/loader/BackdropLoader";
import { Skeleton } from "@material-ui/lab";
import NewClusterScan from "./NewClusterScan";
import ClusterScanReport from "./ClusterScanReport";
import ClusterscanList from "./Clusterscanlist";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useTranslation } from "react-i18next";
import palette from "../../theme/palette";

const useStyles = makeStyles((theme: Theme) => ({
    dropdown: {
        minWidth: 160,
        height: 50,
    },
    cardPadding: {
        padding: "15px"
    },
    margin: {
        marginTop: "30px",
    },
    mainContainer:{
        backgroundColor: palette.white, 
        padding: 16, 
        borderRadius: 16
    }
}));

interface Props extends PropsFromRedux {}

interface Cluster {
    cluster_id: number;
    cluster_name: string;
}

function SecurityTab(props: Props) {
    const classes = useStyles();
    const [t] = useTranslation();
    const [isClusterData, setIsClusterData] = useState<Cluster[]>([]); 
    const [isClusterScanReport, setIsClusterScanReport] = useState<any[]>([]);
    const [mode, setMode] = useState<number>(1);
    const [selectedCluster, setSelectedCluster] = useState<Cluster | null>(null);
    const [listId, setListId] = useState<number>(0);

    useEffect(() => {
        if (props.clusterList) {
            setIsClusterData(props.clusterList || []);
        }
    }, [props.clusterList]);

    useEffect(() => {
        props.getScanClusterPlugin();
    }, [props.getScanClusterPlugin]);

    useEffect(() => {
        if (props.clusterScanedRepoart) {
            setIsClusterScanReport(props.clusterScanedRepoart);
        }
    }, [props.clusterScanedRepoart]);

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedCluster = isClusterData.find(cluster => cluster.cluster_id === event.target.value as number) || null;
        setSelectedCluster(selectedCluster);
        if (selectedCluster) {
            props.getClusterScanReport(selectedCluster.cluster_id);
        }
    };

    const handleRunScan = () => {
        setMode(2);
    };

    const handleInfoBack = () => {
        setMode(1);
    };

    const openDeatils = (listID : number) => {
        setMode(3);
        setListId(listID);
    }

    const handleRefresh = () => {
        console.log("Selected Cluster:", selectedCluster);
        if (selectedCluster) {
            props.getClusterScanReport(selectedCluster.cluster_id);
        }
    }

    return (
        <div data-test="main-container" className={classes.mainContainer}>
            {mode === 1 && 
            <>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="subtitle1">{t("Cluster.Security.security")}</Typography>
                    </Grid>
                    <Grid item>
                        <Box display="flex" gridGap={16} alignItems="center">
                            <FormControl variant="outlined" className={classes.dropdown}>
                                <InputLabel>{t("Cluster.Security.selectcluster")}</InputLabel>
                                <Select
                                    value={selectedCluster?.cluster_id || ''}
                                    onChange={handleSelectChange}
                                    label="Select cluster"
                                    name="clusterlist"
                                    data-test="clusterlist-select"
                                >
                                    <MenuItem value="">{t("Cluster.Security.selectcluster")}</MenuItem>
                                    {isClusterData.map((item: Cluster) => (
                                        <MenuItem key={item.cluster_id} value={item.cluster_id}>
                                            {item.cluster_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button
                                className="m-l-10"
                                color="primary"
                                variant="contained"
                                size="medium"
                                onClick={handleRunScan}
                            >
                                {t("Cluster.Security.runscan")}
                            </Button>
                            <IconButton
                                title={t("Cluster.Security.refresh")}
                                color="primary"
                                aria-label="Refresh"
                                onClick={() => handleRefresh()}
                                data-test="refresh-button"
                            >
                                <RefreshIcon style={{ fontSize: 25 }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                {props.scanningCluster && (
                    <Grid container spacing={3} direction="column" data-test="Skeleton-loader">
                        <Grid item>
                            <Skeleton variant="rect" height={40} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" height={40} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" height={40} />
                        </Grid>
                    </Grid>
                )}

                <div className={classes.margin}>
                    {(isClusterScanReport.length !== 0 && selectedCluster !== null && !props.scanningCluster) ? (
                        <Grid container spacing={2}>
                            {isClusterScanReport.map((item, index) => (
                                <Grid item xs={12} sm={4} key={index}>
                                    <ClusterscanList index={index} item={item} openDeatils={openDeatils} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <>
                            {(selectedCluster !== null && !props.scanningCluster) && (
                                <Grid container justify="center" alignItems="center" direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h5">
                                            {t("Cluster.Security.noclusterrecords")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={handleRunScan}
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                        >
                                            {t("Cluster.Security.runscan")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}

                    {(selectedCluster === null && !props.scanningCluster) && (
                        <NoContentImage 
                            message={t("Cluster.Security.clusternotselected")}
                            alt="No Active Cluster"
                            type="env"
                        />
                    )}
                </div>

            </>
            }

            {mode === 2 &&
                <NewClusterScan handleBack={handleInfoBack} clusterId={selectedCluster?.cluster_id || 0}/>
            }

            {mode === 3 && selectedCluster && (
                <ClusterScanReport clusterId={selectedCluster.cluster_id} listID={listId} handleBack={handleInfoBack}/>
            )}

            {props.fetchingClusterScanPlugins && <BackdropLoader message={t("Cluster.Security.fetchingplugins")} data-test="fetch-cluster-plugin" />}
            {props.scanningCluster && <BackdropLoader message={t("Cluster.Security.fetchreportlist")} data-test="fetch-cluster" />}
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    scanningCluster: state.ClusterReducer.scanningCluster,
    clusterList: state.ClusterReducer.clusterList,
    clusterScanedRepoart: state.ClusterReducer.clusterScanedRepoart,
    fetchingClusterScanPlugins: state.ClusterReducer.fetchingClusterScanPlugins
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getScanClusterPlugin: () => dispatch(getScanClusterPlugin()),
    getClusterScanReport: (clusterId: number) => dispatch(getClusterScanReport(clusterId))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SecurityTab);
