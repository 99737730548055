import React from "react";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Carousel from "react-material-ui-carousel";
import SubscriptionCard from "../subscriptioncard/SubscriptionCard";
import "./subscriptionList.css";
import { SubscriptionListModel, SubscriptionSettings } from "../../models/Project.model";


const settings:SubscriptionSettings = {
  autoPlay: false,
  animation: "slide",
  cycleNavigation: false,
  navButtonsAlwaysInvisible: true,
};

export const ResponsiveSubscriptionList = (props: SubscriptionListModel) => {
  const { matches, selectedIndex } = props;
  if (matches) {
    return (
      <Carousel {...settings} index={selectedIndex} className={"fullWidth"} data-test="subscription-container-carousal">
        {props.subscriptionList?.length > 0 &&
          props.subscriptionList?.map((subscription, index) => (
            <div key={index}>
              <SubscriptionCard
                cardDisabled={
                  props.isLowerSubscription
                    ? props.isLowerSubscription(subscription)
                    : false
                }
                details={subscription}
                handleSubscriptionClick={props.handleSubscriptionClick}
                selectedSubscriptionId={props.selectedSubscriptionId}
                data-test="subscription-card-in-carousal"
              />
            </div>
          ))}
      </Carousel>
    );
  } else {
    return (
      <>{
      props.subscriptionList &&
      props.subscriptionList.map((subscription) => {
        return (
          <Grid item md={3} sm={matches ? 12 : 6} xs={12} key={subscription.id} data-test="subscription-container-grid">
            <SubscriptionCard
              cardDisabled={
                props.isLowerSubscription
                  ? props.isLowerSubscription(subscription)
                  : false
              }
              details={subscription}
              handleSubscriptionClick={props.handleSubscriptionClick}
              selectedSubscriptionId={props.selectedSubscriptionId}
              data-test="subscription-card"
            />
          </Grid>
        );
      })
      }</>
    );
  }
}

export const SubscriptionList = (props: SubscriptionListModel) => {
  const matches = useMediaQuery("(max-width:960px)");
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  React.useEffect(() => {
    if (props.subscriptionList) {
      props.subscriptionList.forEach((s, i) => {
        if (s.id === props.selectedSubscriptionId) {
          setSelectedIndex(i);
          return;
        }
      });
    }
    return () => {
      setSelectedIndex(0);
    };
  }, [props.subscriptionList, props.selectedSubscriptionId]);
  
  return (
    <ResponsiveSubscriptionList
    matches = {matches}
    selectedIndex = {selectedIndex}
    {...props}
    data-test = "subscription-list"
    />
  )
  
};

export default SubscriptionList;
