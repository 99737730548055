import {
  // Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  Theme
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import { useTranslation } from "react-i18next";
// import { UsageGaugeChart } from "../../components/chartcard/UsageGaugeChart";
import { getClusterInsights } from "./redux/actions";
import { TimeSeriesGraph } from "../../components/chartcard/TimeSeriesGraph";
import { Dispatch } from "redux";

const useStyles = makeStyles((theme:Theme) => ({
  editIcon: {
    border: "2px dashed",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme?.transitions?.create("transform", {
      duration: theme?.transitions?.duration?.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeader: {
    padding: "0 !important",
  },
  storageEditIcon: {
    marginTop: "8px !important",
    marginRight: "8px !important",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 0"
  },
  select: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formControl: {
    flexDirection: "row",
    alignItems: "center"
  },
  loader: {
    marginRight: 10,
    animationDuration: '550ms',
  },
}));

interface Props extends PropsFromRedux {
  clusterId: number;
}

type JsonBody = {
  start_time:number;
  end_time:number;
};

export const ClusterMonitor = (props:Props) => {
  const [expanded, setExpanded] = useState(false);
  const [t] = useTranslation();
  const [insightsData, setInsightsData] = useState<any>(null);
  const [range, setRange] = useState(60);
  
  const classes = useStyles();
  
  const makeApiCall = (minutes:number) => {
    let _id = props.clusterId;
    let _endTime = new Date().getTime();
    let _startTime = _endTime - (minutes * 60 * 1000);
    let _jsonBody = {
      start_time: parseInt((_startTime / 1000).toString()),
      end_time: parseInt((_endTime / 1000).toString()),
    };
    props.getClusterInsights(_id, _jsonBody);
  }
  
  useEffect(() => {
    makeApiCall(60);
  }, []);
  
  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setRange(e.target.value as number);
    makeApiCall(e.target.value as number);
  }

  useEffect(() => {
    if (!props.clusterInsights?.cpu_usages || !props.clusterInsights?.disk_usages || !props.clusterInsights?.memory_usages) return;
    let _i = props.clusterInsights;
    setInsightsData({
      cpu_usages: {
        total: parseInt(_i.total_cpu[0].values[0][1]),
        values: _i.cpu_usages[0].values,
        label: t('Environment.OverviewTab.cpuUsage'),
        unit: t('Cluster.ClusterMonitor.cores')
      },
      memory_usages: {
        total: parseInt((_i.total_memory[0].values[0][1] / (1024 ** 3)).toString()),
        values: _i.memory_usages[0].values,
        label: t('Cluster.ClusterMonitor.memoryUsage'),
        unit: t('Cluster.ClusterMonitor.gb')
      },
      disk_usages: {
        total: parseInt((_i.total_disk[0].values[0][1] / (1024 ** 3)).toString()),
        values: _i.disk_usages[0].values,
        label: t('Environment.OverviewTab.diskUsage'),
        unit: t('Cluster.ClusterMonitor.gb')
      },
    });
  }, [props.clusterInsights]);

  return (
    <>
      <Card className="m-t-20" data-test="card-container" elevation={expanded ? 1 : 13}>
        <CardHeader
          data-test="card-header"
          // className={classes.cardHeader}
          title={
            <Typography variant="subtitle1" display="inline" data-test="card-title">
              {t("Cluster.ClusterInfo.clusterMonitoring")}
              <IconButton data-test="checked-icon">
                {
                  (props?.clusterInsights?.cpu_usages && props?.clusterInsights?.disk_usages && props?.clusterInsights?.memory_usages) && (
                    <CheckCircleSharpIcon
                      fontSize="small"
                      style={{ color: "green" }}
                    />
                  )
                }
              </IconButton>
            </Typography>
          }
          subheader={
            <Typography display="block" className="m-b-5" variant="body2">
              {t("Cluster.ClusterInfo.clusterMonitoringHelper")}
            </Typography>
          }
          // avatar={
          //   <IconButton
          //     data-test="expand-icon-button"
          //     className={clsx(classes.expand, {
          //       [classes.expandOpen]: expanded,
          //     })}
          //     onClick={() => {
          //       setExpanded(!expanded);
          //     }}
          //   >
          //     <ExpandMoreIcon />
          //   </IconButton>
          // }
          action={
          <IconButton
            data-test="expand-icon-button"
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
          }
        />
        <Collapse in={expanded}>
          <CardContent data-test="card-content">
            <Grid className={classes.grid}>
              <FormControl variant="outlined" className={classes.formControl}>
                {
                  props.fetchingClusterInsights &&
                    <CircularProgress className={classes.loader} size={25} thickness={4} />
                }
                <Select
                  id="demo-simple-select-outlined"
                  value={range}
                  MenuProps={{ disableScrollLock: true }}
                  onChange={handleChange}
                  labelWidth={0}
                  classes={{root: classes.select}}
                  disabled={props.fetchingClusterInsights}
                  data-test="range-select"
                >
                  <MenuItem value={5}>{t('Cluster.ClusterMonitor.5minutes')}</MenuItem>
                  <MenuItem value={15}>{t('Cluster.ClusterMonitor.15minutes')}</MenuItem>
                  <MenuItem value={30}>{t('Cluster.ClusterMonitor.30minutes')}</MenuItem>
                  <MenuItem value={1 * 60}>{t('Cluster.ClusterMonitor.1hour')}</MenuItem>
                  <MenuItem value={3 * 60}>{t('Cluster.ClusterMonitor.3hour')}</MenuItem>
                  <MenuItem value={6 * 60}>{t('Cluster.ClusterMonitor.6hour')}</MenuItem>
                  <MenuItem value={12 * 60}>{t('Cluster.ClusterMonitor.12hour')}</MenuItem>
                  <MenuItem value={24 * 60}>{t('Cluster.ClusterMonitor.24hour')}</MenuItem>
                  <MenuItem value={2 * 24 * 60}>{t('Cluster.ClusterMonitor.2days')}</MenuItem>
                  <MenuItem value={7 * 24 * 60}>{t('Cluster.ClusterMonitor.7days')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                {
                  insightsData && 
                    <TimeSeriesGraph 
                      data={{memory_usages: insightsData.memory_usages}} 
                      title={t('Cluster.ClusterMonitor.custerMemoryUsage')}
                      yLabel={t('Cluster.ClusterMonitor.gb')}
                      total={insightsData.memory_usages.total}
                      data-test="memory-usage-graph"
                      // handleTimeChange={makeApiCall}
                      // loading={props.fetchingClusterInsights}
                    />
                }
              </Grid>
              <Grid item xs={12} md={6}>
                {
                  insightsData && 
                    <TimeSeriesGraph 
                      data={{disk_usages: insightsData.disk_usages}} 
                      title={t('Cluster.ClusterMonitor.clusterDiscUsage')}
                      yLabel={t('Cluster.ClusterMonitor.gb')}
                      color="#ff9429"
                      total={insightsData.disk_usages.total}
                      data-test="disk-usage-graph"

                      // handleTimeChange={makeApiCall}
                      // loading={props.fetchingClusterInsights}
                    />
                }
              </Grid>
              <Grid item xs={12}>
                {
                  insightsData && 
                    <TimeSeriesGraph 
                      data={{cpu_usages: insightsData.cpu_usages}} 
                      title={t('Cluster.ClusterMonitor.clusterCPUUsage')}
                      yLabel={t('Cluster.ClusterMonitor.noCores')}
                      color="#29ff66"
                      total={insightsData.cpu_usages.total}
                      data-test="cpu-usage-graph"
                      // handleTimeChange={makeApiCall}
                      // loading={props.fetchingClusterInsights}
                    />
                }
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4}>
                <UsageGaugeChart
                  title="Cluster Disk Usage"
                  used={insights.disk.used}
                  total={insights.disk.total}
                  unit={insights.disk.unit}
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

const mapStateToProps = (state:any) => ({
  clusterInsights: state.ClusterReducer.clusterInsights,
  fetchingClusterInsights: state.ClusterReducer.fetchingClusterInsights,
});

const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getClusterInsights: (id:number, jsonBody:JsonBody) =>
      dispatch(getClusterInsights(id, jsonBody)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ClusterMonitor);
