import { Avatar, ButtonBase, Card, CardContent, Divider, Grid, Tooltip, Typography  } from '@material-ui/core';
//import GitHubIcon from '@material-ui/icons/GitHub';
import MemoryOutlinedIcon from '@material-ui/icons/MemoryOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StraightenOutlinedIcon from '@material-ui/icons/StraightenOutlined';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { coreConversion, getDiffDays, spaceCoversion, getStatusColor } from '../../helpers/utils';
import './EnvironmentCard.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CancelIcon from '@material-ui/icons/Cancel';
import { CreateApp_TemplateTypes, CustomColors } from '../../constants/enums';
import { DateHandler } from '../dateHandler/DateHandler'
import {useTranslation} from 'react-i18next';
import { AppConstants } from '../../constants/appconstants';
import paths from "../../constants/paths"
import { EnvironmentModel } from '../../models/Environment.model';
import palette from '../../theme/palette';
import { getAppStatusColor } from '../../helpers/utils.ext';
import { LabelHandler } from '../labelHandler/LabelHandler';
import AddIcon from '@material-ui/icons/Add';
const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20
    },
    avatar: {
        color: 'blue',
        backgroundColor: 'red'
    },
    shortTitle: {
        background: 'white',
        border: '1px solid #0057fa',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30
    },
    container: {
        padding: '1rem',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    progress: {
        margin: '7px 20px 0px 20px',
        borderRadius: 20,
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    versionIndicator: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '18px',
        color: palette.text.disabled
    },
    svgicon: {
        fontSize: 16,
        // color: '#357dfd',
        // marginRight: 5,
        marginRight: '1px !important'
    },
    labelCardText: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '16px'
    },
    cardLinkContainer: {
        textDecoration: 'none'
    },
    cardDiscriptionContainer: {
        marginTop: 16
    },
    labelContainer: {
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'center', 
        gap: 8
    },
    cardDivider:{
        backgroundColor: palette.white, 
        borderBottom: `1px dashed ${palette.background.neutral}`
    },
    cicdTitle: {
        fontSize: '14px', 
        fontWeight: 600, 
        lineHeight: '24px'
    },
    buildTitle:{
        fontWeight: 600
    },
    deploymentDetailsWrapper: {
        display: 'flex', 
        justifyContent: 'space-between', 
        width: '100%'
    },
    detailsDetailsInnercontainer: {
        display: 'flex', 
        gap: 6, 
        alignItems: 'center'
    },
    jobsDetailscontainer: {
        display: 'flex', 
        gap: 10, 
        alignItems:'center', 
        justifyContent: 'flex-end'
    },
    addonsDetailsContainer: {
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: 6
    },
    avatarContainer:{
        borderRadius: '50%',
        height: 24,
        width: 24,
    },
    avatarCardText: {
        fontSize: 12, 
        fontWeight: 500, 
        lineHeight: '18px', 
        color: CustomColors.Success.Dark
    }
}));

type Props = {
    isHelmChart: boolean,
    details:EnvironmentModel
}

const getStatusIcon = (status: string) => {
    let statusIcon: JSX.Element|string = ""
    switch(status){
        case AppConstants.EnvironmentStatus.Running:
            statusIcon = <CheckCircleIcon style={{color: getStatusColor(status)}} />
            break
        case AppConstants.EnvironmentStatus.Stopped:
            statusIcon = <CheckCircleIcon style={{color: getStatusColor(status)}} />
            break
        case AppConstants.EnvironmentStatus.Failed:
            statusIcon = <CancelIcon style={{color: getStatusColor(status)}} />
            break
        case AppConstants.EnvironmentStatus.Pending:
            statusIcon = <WatchLaterIcon style={{color: getStatusColor(status)}} />
            break
        default:
            statusIcon = <WatchLaterIcon style={{color: getStatusColor(status)}} />
            
    }
    return statusIcon
}

export const EnvironmentCard = (props: Props) => {
    const { details } = props;
    const classes = useStyles();
    const [t] = useTranslation()
    const detailsID = (details.id).toString();

    console.log(details.status);
    

    return (
        <Grid data-test="main-component" item md={ 4 } xs={ 12 }>
            <Card elevation={1}>
                <CardContent>
                    <Link to={{ pathname: props.isHelmChart ? paths.HELMENVIRONMENTINFO.replace(":eId", detailsID) : paths.ENVIRONMENTINFO.replace(":eId", detailsID),state: { details } } } className={classes.cardLinkContainer}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container justify="space-between">
                                    <Grid item xs={12}>
                                        <div className={ classes.titleContainer }>
                                            <Typography data-test="env-name" variant='h5'>{details.name}</Typography>

                                            <Typography className={classes.versionIndicator}>
                                                {
                                                    !props.isHelmChart && details.version && details.version.name
                                                }
                                                {
                                                    props.isHelmChart && details?.chartVersion && details.chartVersion.app_version
                                                }
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid data-test="env-version" item xs={12} className={classes.cardDiscriptionContainer}>
                                        <Grid container>
                                            <Grid item xs={12} sm={3}>
                                                <Typography className='env-label-handler-container' style={{backgroundColor: getAppStatusColor(details.status).backgroundColor}} >
                                                    <span>
                                                        <Tooltip className="statusIcon" title={t('EnvironmentStatus')}>
                                                            {getStatusIcon(details.status)}
                                                        </Tooltip>
                                                        <span style={{ color: getAppStatusColor(details.status).color}} className="statusTxtenv"> { details.status }</span>
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <div className={classes.labelContainer}>
                                                    <div>
                                                        <DateHandler 
                                                            data-test="created-info" 
                                                            date={details.createdat}  
                                                            icon={<ScheduleIcon className={classes.svgicon} style={{color: palette.background.primary}}/>}
                                                            labelType='Grey'
                                                            className='env-label-handler-container'
                                                            labelClassName={classes.labelCardText}
                                                        />
                                                    </div>
                                                    {
                                                        !props.isHelmChart &&
                                                        <>
                                                            <LabelHandler 
                                                                label={details && details.resource && details.resource.cores && coreConversion(details.resource.cores)}
                                                                iconTooltip={t('Environment.EnvironmentInfo.memory')}
                                                                icon={<MemoryOutlinedIcon className={ classes.svgicon } style={{color: CustomColors.Success.Dark}}/>}
                                                                labelType='Success'
                                                                className='env-label-handler-container'
                                                                labelClassName={classes.labelCardText}
                                                            />

                                                            <LabelHandler 
                                                                label={details && details.resource && details.resource.memory && spaceCoversion(details.resource.memory)}
                                                                iconTooltip={t('Environment.EnvironmentInfo.ram')}
                                                                icon={<StraightenOutlinedIcon className={ classes.svgicon } style={{color: CustomColors.Secondary.Dark}}/>}
                                                                labelType='Secondary'
                                                                className='env-label-handler-container'
                                                                labelClassName={classes.labelCardText}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>                                       
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                    
                    {
                        !props.isHelmChart && details.service_type > 0 && details.service_type !== CreateApp_TemplateTypes.op &&
                        <>
                            {(details.service_type === CreateApp_TemplateTypes.cr || details.service_type === CreateApp_TemplateTypes.git) && <Divider className={`${classes.cardDivider} carddivider`} />}
                            <Grid container>
                                {(details.service_type === CreateApp_TemplateTypes.cr || details.service_type === CreateApp_TemplateTypes.git) && 
                                (<>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" className={classes.cicdTitle}>{t('Environment.EnvironmentInfo.ci/cd')}</Typography>
                                    </Grid>
                                </>)}
                                <Grid item xs={12}>
                                    {
                                        details.service_type === CreateApp_TemplateTypes.git &&
                                        <div className={classes.deploymentDetailsWrapper}>
                                            <div className={classes.detailsDetailsInnercontainer}>
                                                <div>
                                                    <Typography variant='caption' color='textSecondary' className={classes.buildTitle}>{t('LastBuild')}</Typography>
                                                </div>
                                                <div data-test="build-time">
                                                    <Typography variant='caption' color='textSecondary'>{ getDiffDays(details.last_deployed, true) }</Typography>
                                                </div>
                                            </div>
                                            <div className={classes.detailsDetailsInnercontainer}>
                                                <div>
                                                    <Typography variant='caption' color='textSecondary' className={classes.buildTitle}> {t('Environment.OverviewTab.branch')} </Typography>
                                                </div>
                                                <div>
                                                    <img src="/images/icons/git.svg" alt="Git" title="Git" height="18" width="18" className="svgiconimg"/>
                                                    <Typography variant='caption' color='textSecondary'>{details.git_branch} </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        details.service_type === CreateApp_TemplateTypes.cr &&
                                        <div className={classes.deploymentDetailsWrapper}>
                                            <div className={classes.detailsDetailsInnercontainer}>
                                                <div>
                                                    <Typography variant='caption' color='textSecondary' className={classes.buildTitle}>{t('Environment.OverviewTab.lastDeployed')} </Typography>
                                                </div>
                                                <div>
                                                    <Typography variant='caption' color='textSecondary'>{getDiffDays(details.last_deployed, true)}</Typography>
                                                </div>
                                            </div>
                                            <div className={classes.detailsDetailsInnercontainer}>
                                                <div>
                                                    <Typography variant='caption' color='textSecondary' className={classes.buildTitle}>{t('Environment.OverviewTab.tag')} </Typography>
                                                </div>
                                                <div>
                                                    <img src="/images/icons/containerregistry.svg" alt="Container Registry" title={t('Environment.OverviewTab.cr')} className="svgiconimg" /> 
                                                    <Typography variant='caption' color='textSecondary'>{details.image_tag}</Typography>
                                                </div>                                          
                                            </div>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </>
                        
                    }
                    {
                        !props.isHelmChart && details.service_type !== CreateApp_TemplateTypes.op &&
                        <>
                            <Divider className={`${classes.cardDivider} carddivider`}/>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid data-test="autoscale-info" item md={6} className={classes.detailsDetailsInnercontainer}>
                                    <Typography variant='body2' className={classes.buildTitle}>{t('Scaling')} </Typography>
                                    <Typography variant='caption' color={details.auto_scaler && details.auto_scaler.enabled ? "primary" : 'textSecondary'}>{details.auto_scaler && details.auto_scaler.enabled ? t('Enabled') : t('Disabled')}</Typography>
                                </Grid>
                                {details.service_type > 0 && (
                                    <Grid item md={6} className={classes.jobsDetailscontainer}>
                                        <Typography variant='body2' className={classes.buildTitle}>{t('Jobs')} </Typography>
                                        <Typography variant='caption' color='textSecondary'>{details.CronJob ? details.CronJob.length : 0}</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    }
                    {
                        !props.isHelmChart && details.service_type !== CreateApp_TemplateTypes.op &&
                        <>
                            <Divider className={`${classes.cardDivider} carddivider`}/>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <Typography variant="body1"className={classes.cicdTitle}>{t('AddonsLabel')}</Typography>
                                    <div className="envavatardiv">
                                        <div className={classes.addonsDetailsContainer}>
                                            {
                                                details.addons && details.addons.length > 0 && details.addons.length <= 3 &&
                                                details.addons.map((addOn, ind) => (
                                                    <Tooltip data-test="addon-icon" title={addOn?.version?.info[0]?.name} arrow key={ind}>
                                                        <Link 
                                                            to={{ pathname: paths.ENVIRONMENTINFO.replace(":eId", detailsID) + "#addons", state: { details, addonEnvId: addOn.id } }} 
                                                            className={classes.detailsDetailsInnercontainer}
                                                        >
                                                            <Avatar
                                                                variant="rounded" 
                                                                className={`${classes.avatarContainer} envavatar`}
                                                            >
                                                                <img 
                                                                    src={addOn?.plugin_version?.plugin?.image} 
                                                                    height="24" 
                                                                    width="24" 
                                                                    className="addonsstatus" 
                                                                    alt="" 
                                                                />
                                                            </Avatar>
                                                        </Link>
                                                    </Tooltip>
                                                ))
                                            }
                                            {
                                                details.addons && details.addons.length > 3 &&
                                                <>
                                                    {details.addons.slice(0,3).map((addOn, ind) => (
                                                        <Tooltip data-test="addon-icon" title={addOn?.version?.info[0]?.name} arrow key={ind}>
                                                            <Link 
                                                                to={{ pathname: paths.ENVIRONMENTINFO.replace(":eId", detailsID) + "#addons", state: { details, addonEnvId: addOn.id } }} 
                                                                style={{ display: 'flex', alignItems: 'center', gap: 6 }}
                                                            >
                                                                <Avatar
                                                                    variant="rounded" 
                                                                    className={`${classes.avatarContainer} envavatar`}
                                                                >
                                                                    <img 
                                                                        src={addOn?.plugin_version?.plugin?.image} 
                                                                        height="24" 
                                                                        width="24" 
                                                                        className="addonsstatus" 
                                                                        alt="" 
                                                                    />
                                                                </Avatar>
                                                            </Link>
                                                        </Tooltip>
                                                    ))}
                                                    <Avatar
                                                        style={{
                                                            backgroundColor:CustomColors.Success.Lighter,
                                                        }}
                                                        variant="rounded" 
                                                        className={`${classes.avatarContainer} envavatar`}
                                                    >
                                                        <Typography className={classes.avatarCardText}>{"+" + details.addons.slice(3).length}</Typography>
                                                    </Avatar>
                                                </>
                                                
                                            }
                                        </div>
                                        <div>
                                            <Tooltip data-test="addon-add-icon" title={t('AddAddons')} arrow>
                                                <Link to={{ pathname: paths.ENVIRONMENTINFO.replace(":eId", detailsID) + '#addons', state: { details } }} style={{ textDecoration: 'none' }}>
                                                <Avatar 
                                                    variant="rounded"  
                                                    style={{ 
                                                        backgroundColor: CustomColors.Grey.Disabledbg
                                                    }}
                                                    className={`${classes.avatarContainer} envavatar`}
                                                >
                                                    <AddIcon color='primary'/>
                                                </Avatar>
                                                </Link>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    }
                    
                </CardContent>
            </Card>
        </Grid>
    )
}

export default EnvironmentCard