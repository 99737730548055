import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { fetchDeploymentTypes } from "../../pages/environment/cicdredux/actions";
import PluginConfigForm from "../pluginconfigform/PluginConfigForm";
import { DeploymentValue } from "../../models/Environment.model";
// deployment interface

interface AnalysisParamType {
  interval: string;
  maxWeight?: number;
  stepWeight?: number;
  threshold: number;
  iterations?: number;
}
interface SurgeParams {
  maxSurge: number;
  maxUnavailable: number;
}

interface AnalysisType {
  analysis: AnalysisParamType;
}

interface DeploymentType {
  [key: string]: SurgeParams | AnalysisType;
}
interface Props extends PropsFromRedux {
  handleChange: (val: DeploymentValue, type: string, err: any) => void;
  deployment?: DeploymentType;
 
}

/* istanbul ignore next */
const useStyles = makeStyles(() => ({
  deploymentDropdown: {
    width: "100%",
  },
}));
export const DeploymentStrategy = (props: Props) => {
  const [deploymentType, setDeploymentType] = React.useState("rollingUpdate");
  const [variables, setVariables] = React.useState({});
  const [isErrors, setIsErrors] = React.useState([]);
  const [cdConfig, setCdConfig] = React.useState(null);
  const [t] = useTranslation();
  const classes = useStyles();

  React.useEffect(() => {
    props.fetchDeploymentTypes();
  }, []);

  React.useEffect(() => {
    if (props.deployment) {
      setDeploymentType(Object.keys(props.deployment)[0]);
    }
    // else if (props.cdConfig?.properties && Object.keys(props.cdConfig.properties).length > 0) {
    //     setDeploymentType(Object.keys(props.cdConfig.properties)[0]);
    // }
  }, [props.cdConfig, props.deployment]);

  React.useEffect(() => {
    if (props.cdConfig && deploymentType !== "") {
      setCdConfig(null);
      setTimeout(() => {
        setCdConfig(props.cdConfig.properties[deploymentType]);
      }, 0);
    }
    if (props.deployment && props.deployment[deploymentType]) {
      setVariables(props.deployment[deploymentType]);
    }
  }, [deploymentType, props.cdConfig]);

  const updateParent = () => {
    // const payload = {
    //     [deploymentType]: { ...variables }
    // }
    props.handleChange(variables, deploymentType, isErrors);
  };

  React.useEffect(() => {
    updateParent();
  }, [deploymentType, variables]);

  const handleDeploymentChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setDeploymentType(e.target.value as string);
    setVariables({});
  };

  const handleValueChange = (_variables: any, err: any) => {
    setVariables(_variables);
    setIsErrors(err);
  };

  return (
    <Grid container className="m-t-10" spacing={2} data-test="main-container">
      <Grid item md={12} sm={6} xs={12}>
        <Typography color="textPrimary" variant="subtitle2">
          {t("Environment.CdTab.deployment")}
        </Typography>
      </Grid>
      {props.cdConfig && (
        <Grid item xs={12}>
          <Grid container spacing={1} direction={"column"}>
            {/* <Grid item>
              <Typography variant="h6" color="textPrimary">
                {t("Environment.CdTab.type")}
              </Typography>
            </Grid> */}
            <Grid xs={6} sm={4} md={3} item className="w-100">
              <FormControl
                variant="outlined"
                className={classes.deploymentDropdown}
              >
                <InputLabel>{t("Environment.CdTab.type")}</InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  value={deploymentType}
                  onChange={(e) => {
                    handleDeploymentChange(e);
                  }}
                  disabled={false}
                  MenuProps={{
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                    getContentAnchorEl: null,
                  }}
                  data-test="deployment-type-select"
                >
                  {Object.keys(props.cdConfig?.properties)?.map((deploy) => (
                    <MenuItem
                      key={deploy}
                      value={deploy}
                      data-test="deployment-type-item"
                    >
                      {props.cdConfig.properties[deploy].title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}
      {cdConfig && (
        <PluginConfigForm
          pluginVersionConfig={cdConfig}
          isErrors={isErrors}
          handleValueChange={handleValueChange}
          gridItemOccupency={3}
          initialValues={variables}
          // calculateResources={this.calculateResources}
          // selectedResourceObject={this.state.selectedResourceObject}
          data-test="plugin-form"
        />
      )}
    </Grid>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  cdConfig: state.CICDReducer.cdConfig,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDeploymentTypes: () => dispatch(fetchDeploymentTypes()),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeploymentStrategy);
