import React from "react";
import MDEditor from "@uiw/react-md-editor";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  mdDesc: {
    wordBreak: "break-word",
    color: "black",
    // padding: "5px 10px",
    // fontFamily: "'Cutive Mono', 'Ubuntu Mono', monospace",
  },
  mdEditor:{
    borderRadius: 16,
  }
}));

type Props = {
  readOnly: boolean
  value: string
  onChange?: (value?: string) => void;
  title?: string
}

export const MarkdownField = (props: Props) => {
  const classes = useStyles();
  return (
    <div>
      {props.readOnly ? (
        <MDEditor.Markdown className={classes.mdDesc} source={props.value} />
      ) : (
        <>
          {/* <Card>
            <CardHeader title={(props.title ?? "Description") + "(*)"} />
            <Divider />
            <CardContent> */}
              <MDEditor
                value={props.value}
                onChange={props.onChange}
                height={250}
                preview={"edit"}
                className={classes.mdEditor}
              />
            {/* </CardContent> */}
           {/* </Card> */}
        </>
      )}
    </div>
  );
};
export default MarkdownField;
