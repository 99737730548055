import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Card, CardContent, CardHeader, Box, Tooltip, Typography, Divider } from "@material-ui/core";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { LabelHandler } from "../../../../components/labelHandler/LabelHandler";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDateInStandardFormat, getDiffDays, gitDiffBtnScanDate } from "../../../../helpers/utils";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import { CustomColors } from "../../../../constants/enums";
import palette from "../../../../theme/palette";

const useStyles = makeStyles({
  cardPadding:{
    padding: "15px"
  },
  statusIcons:{
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center'
  },
  dividers:{
      backgroundColor: palette.white, 
      borderBottom: `1px dashed ${palette.background.neutral} !important`
  },
});

interface Props extends PropsFromRedux {
  openDetails: (listID:number) => void
  list:any;
  serialNo: number;
}

export function ScannedList(props: Props) {
  const classes = useStyles();

  const openDetailsPage = (id:number) => {
    props.openDetails(id);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} key={props.serialNo}>
          <Card
            className="m-b-20"
            data-test="pods-overview-container"
            onClick={() => openDetailsPage(props.list.id)}
            style={{ cursor: "pointer" }}
            elevation={1}
          >
            <CardHeader 
              title={
                  <Box style={{display: "flex", alignItems: 'center', gap: 16}}>
                      <div style={{backgroundColor: props.list.status === "completed" ? CustomColors.Success.Lighter : props.list.status === "error" ? CustomColors?.Info?.Lighter : CustomColors?.Warning.Lighter, padding: 12, borderRadius: 12, width: 'fit-content', display: 'flex', alignItems: 'center'}}>
                          <Tooltip title={props.list.status}>
                              {props.list.status === "completed" ?
                                  <CheckOutlinedIcon fontSize="medium" style={{ color: CustomColors.Success.Dark }} /> 
                                  :
                                  props.list.status === "error" ?
                                  <CloseOutlinedIcon fontSize="medium" style={{ color: CustomColors.other.icon }} /> :
                                  <CircularProgress size={24} style={{ color: CustomColors.Warning.Dark }} />
                              }
                          </Tooltip>
                      </div>
                      <div>
                          <Typography variant="subtitle1">{props.list.name?.split("-", 2)[0]}</Typography>
                          <Typography variant="caption">Posted date: {props.list.name?.split("-").slice(1).join("-")}</Typography>
                      </div>
                  </Box>
              }
            />
            <Divider className={classes.dividers}/>
            <CardContent className={classes.cardPadding}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                      <LabelHandler
                        icon={<RestoreOutlinedIcon />}
                        iconTooltip={props.list.time_taken + " sec"}
                        label={gitDiffBtnScanDate(props.list.time_taken)}
                        
                        labelTooltip={props.list.time_taken + " sec"}
                        data-test="pod-age"
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <LabelHandler
                        icon={<AccessTimeOutlinedIcon />}
                        iconTooltip={getDateInStandardFormat(props.list.createdAt)}
                        label={getDiffDays(props.list.createdAt, true)}
                        labelTooltip={getDateInStandardFormat(props.list.createdAt)}
                        data-test="pod-age"
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <LabelHandler
                        icon={<SettingsOutlinedIcon />}
                        iconTooltip={props.list.plugin_name}
                        label={props.list.plugin_name}
                        labelTooltip={props.list.plugin_name}
                        data-test="Trivy"
                      />
                    </Grid>      
                    <Grid item md={6} xs={6}>
                      <LabelHandler
                        icon={<ReportProblemOutlinedIcon color="error" />}
                        iconTooltip={props.list.vulnerability_count}
                        label={`Vulnerability Count(${props.list.vulnerability_count})`}
                        labelTooltip={props.list.vulnerability_count}
                        data-test="pod-age"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ScannedList);
