import React from "react";
import MuiTextField from "../textfield/MuiTextField";
import { Box, Typography } from "@material-ui/core";
import { CloudeUploadIcon } from "../../helpers/customIcon";
import palette from "../../theme/palette";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    uploadfilehelperText:{
        color: palette.text.disabled
    }
}));

interface Props {
    selectedFile: File | null; 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; 
    disabled?: boolean; 
    name? : string;
    label?: string | any
}

export const FileUpload = (props : Props) => {
    const classes = useStyles();
    return (
        <label className="custom-file-input">
            <MuiTextField
                id="file-upload"
                type="file"
                color="primary"
                name={props.name}
                variant="outlined"
                className="w-100"
                margin="normal"
                data-test="file-field"
                onChange={props.onChange} 
                disabled={props.disabled} 
                style={{ display: 'none' }}
            />
            <Box component="div" className="custom-file-upload">
                {props.selectedFile ? (
                    <>
                        <Typography variant="body2">{props.selectedFile?.name}</Typography>
                        <CloudeUploadIcon fontSize="large" viewBox="0 0 40 40" />
                        <Typography variant="body2" className={classes.uploadfilehelperText}>Change file</Typography>
                    </>
                ) : (
                    <>
                        <CloudeUploadIcon fontSize="large" viewBox="0 0 40 40" />
                        <Typography variant="body2" className={classes.uploadfilehelperText}>Upload file</Typography>
                    </>
                )}
            </Box>
        </label>
    );
};

export default FileUpload;
