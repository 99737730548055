import palette from "../palette";

export default {
    textColorInherit: {
        "&$selected": {
            color: palette.text.primary, 
            fontWeight: 600,
        },
        color: palette.text.secondary,
        fontFamily: 'Public Sans, sans-serif',
        fontWeight: 400,
        fontSize: '14px'
    },
    wrapper: {
        flexDirection: "none",
        justifyContent: 'start',
    },
    root: {
        width:'fit-content'
    }
}