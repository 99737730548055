import React, {useState, useEffect} from 'react'
//import {useHistory} from "react-router-dom"
import { Select, FormControl, MenuItem, InputLabel, Typography, Box, Card, CardContent} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { connect ,ConnectedProps} from "react-redux"
import { fetchClusterList, fetchRegionList } from "./redux/actions"
import OperatorCatalogContainer from '../../../components/operatorcatalog/OperatorCatalogContainer'
import BackdropLoader from "../../../components/loader/BackdropLoader";
import NoContentImage from '../../../components/nocontentimagecontainer/NoContentImage';
import { useTranslation } from 'react-i18next'
import paths from "../../../constants/paths"
import { ClusterModel } from '../../../models/Cluster.model';
import { StaticContext,RouteComponentProps } from 'react-router';
import { Dispatch } from "redux";
import { Operator } from '../../../models/Operator.model';



const useStyles = makeStyles({
    dropdown: {
        minWidth: 100,
        height: 55,
    },
    container: {
        marginTop: 20,
        minHeight: '20rem',
    },
    formControl: {
        width: "20%",
        marginTop: "0.9rem"
    },
    label:{
        marginTop: "1.7rem",
        width: "4rem"
    }
});

type Props = RouteComponentProps<{pId:string,tType:string,source:string}, StaticContext> & PropsFromRedux ;
export const OperatorSelection = (props:Props) =>{

    //const history = useHistory()
    const {history} = props
    const [t] =  useTranslation()
    const classes = useStyles()
    
    const [region, setRegion] = useState<string|null>(null)
    const [cluster, setCluster] = useState<ClusterModel|null>(null)

    useEffect(()=>{
        props.fetchRegionList()
    },[])

    const handleRegionChange = (e:React.ChangeEvent<{ value: unknown }>) => {
        setRegion(e.target.value as string)
        props.fetchClusterList(e.target.value as string)
        setCluster(null)
    }
    
    const handleClusterChange = (e:React.ChangeEvent<{ value: unknown }>) => {
        setCluster(JSON.parse(e.target.value as string) as ClusterModel)
    }

    const handleCatalogClick = (catalog:Operator) => {
        if(!cluster) return;
        history.push({
                pathname: paths.CREATEAPP_SELECTREGION.replace(':pId', props.match?.params?.pId).replace(':tType', props.match?.params?.tType).replace(':source', props.match?.params?.source).replace(':plugId', `${catalog?.package_name}__${cluster.id}__${region}`),
              
          });
       
    }
return(
<>  
    <Card>
        <CardContent>
            {!props.fetchingRegion ? (
            props.regions && props.regions.length > 0 ? (
            <Box display="flex">

            <Typography className={classes.label}> Region </Typography>
            <FormControl
                variant="outlined"
                className={`w-100 ${classes.formControl}`}
            >
            <InputLabel> Select Region </InputLabel>
                <Select
                    value={region}
                    onChange={(e) => handleRegionChange(e)}
                    className={classes.dropdown}
                    label="Select Region"
                    data-test= "select-region"
                >
                
                    {props.regions?.map((reg:string, ind:number) => (
                        <MenuItem value={reg} key={ind}>
                            <em>{reg}</em>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            </Box>
            ): (<NoContentImage message="No Region List" alt="No Region List"  data-test= "nocontent-image"/>)
            ):(<BackdropLoader message="Fetching Region List" data-test= "creating-backdrop"/>)
            }
        
            {region && props.cluster && (
            <Box display='flex' className="m-b-20">
            <Typography className={classes.label}> Clusters </Typography>
            <FormControl
                variant="outlined"
                className={`w-100 ${classes.formControl}`}
            >
                <InputLabel> Select Cluster </InputLabel>

                <Select
                    // value={cluster.name}
                    onChange={(e) => handleClusterChange(e)}
                    className={classes.dropdown}
                    label= "Select Cluster"
                    data-test="select-cluster"
                >
                    {props.cluster?.map((clust:ClusterModel, ind:number) => (
                        <MenuItem value={JSON.stringify({...clust})} key={ind}>
                            <em>{clust.name}</em>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            </Box>
            
            )}
        </CardContent>
    </Card>
    {/* checking for cluster causes tests to fail */}
   {region && cluster  && (<OperatorCatalogContainer region={region} clusterId={cluster.cluster_request_id} title={t('Operators.OperatorCatalog.operators')} onClick={handleCatalogClick} data-test="operator-container" />
)}
</>
)
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => {
    return{
    regions: state.CreateAppReducer.regions,
    fetchingRegion: state.CreateAppReducer.fetchingRegion,
    cluster:  state.CreateAppReducer.cluster,
}}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => ({
    fetchRegionList:() => dispatch(fetchRegionList()),
    fetchClusterList: (region:string) => dispatch(fetchClusterList(region)),
    
});
const connector=connect(mapStateToProps, mapDispatchToProps)
export default connector(OperatorSelection)
type PropsFromRedux = ConnectedProps<typeof connector>;
