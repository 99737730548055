import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { LineChart } from "react-chartkick";
import 'chart.js';
//import moment from "moment";
import { getDateInChartFormat } from '../../helpers/utils';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from 'recharts';
import palette from '../../theme/palette';

const LineChartComponent = ({
  data,
  maxY,
  // lineColor,
  //xtitle = '',
  ytitle = '',
  suffix = '',
  //legend = false,
  source,
  // secondlinecolor
}) => {
  const theme = useTheme();
  const widthMatches = useMediaQuery(theme.breakpoints.down('sm'));

  const XTickFormatter = (tickItem) => {
    return getDateInChartFormat(tickItem);
  };

  return (
    // <LineChart
    //   data={data}
    //   responsive={true}
    //   colors={[lineColor]}
    //   curve={true}
    //   legend={legend}
    //   dataset={{ paddingLeft: 1000 }}
    //   xtitle={xtitle}
    //   ytitle={ytitle}
    //   suffix={suffix}
    //   library={{
    //     color: "red",
    //     scales: {
    //       xAxes: [
    //         {
    //           type: "time",
    //           parser: "string",
    //           time: {
    //             unit: "hour",
    //             displayFormats: {
    //               hour: "MMM D - h:mm a",
    //               //minute: 'h:mm a',
    //             },
    //           },
    //         },
    //       ],
    //       yAxes: [
    //         {
    //           ticks: {
    //             precision: 2,
    //           },
    //         },
    //       ],
    //     },
    //   }}
    // />
    <ResponsiveContainer width="100%" height={ widthMatches ? 250 : 400}>
          <LineChart
            data={ data.data }
            margin={ {
          top: 1,
          right: 5,
          left: 5,
          bottom: 5,
        } }
          >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="xAxixLabel"
                tickFormatter={ (tickItem) => XTickFormatter(tickItem) }
                tickSize={ 2 }
          //interval={16}
                tickMargin={ 10 }
              />
              <YAxis
                unit={ source === 'hpa' ? '' : suffix }
                allowDecimals={ false }
                domain={
            source === 'hpa'
              ? 0
              : [
                  () => {
                    return 0;
                  },
                  (dataMax) => {
                    // if(maxY){
                    //   return parseInt(maxY)
                    // }
                    if (dataMax === Infinity || dataMax === -Infinity) {
                      return 0;
                    }
                    if (maxY) {
                      return Math.max(parseInt(data.maxY) + 1, maxY);
                    }
                    return parseInt(data.maxY) + 1;
                  },
                ]
          }
                label={
            source === 'hpa'
              ? {
                  value: ytitle,
                  offset: 15,
                  angle: -90,
                  position: 'outsideLeft',
                }
              : ''
          }
                padding={ { left: 10 } }
              />
              <Tooltip
                payload={ [ { unit: suffix } ] }
                formatter={ (value) => {
                  let per = '';
                  let percent = 101;
                  if (maxY) {
                    percent = (value / maxY) * 100;
                    per = `(${ Math.round(percent * 100) / 100 } %)`
                  }
                  return value + ' ' + suffix + `${ percent > 100 ? '' : per }`;
                } }
                active={ true }
                contentStyle={{
                  backgroundColor: palette.white, 
                  borderRadius: '16px',  
                  padding: '10px', 
                  boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.25)', 
                }}
                itemStyle={{
                  fontSize: '10px', 
                  lineHeight: '16px'
                }}
              />
              {/* <Legend verticalAlign="top" height={ 30 } iconType="circle" /> */}
              {data &&
          data.label &&
          data.label.map((labelData, ind) => (
              <Line
                type="monotone"
                dataKey={ labelData.name }
                stroke={ labelData.strokeColor }
              // activeDot={{ r: 5 }}
                key={ ind }
              />
          ))}
          </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent