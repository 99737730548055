import paths from './constants/paths';
import Login from './pages/login';
//import Dashboard from './pages/dashboard'
import Register from './pages/registration';
import RequestDemo from './pages/registration/RequestDemo'
import ForgotPassword from './pages/forgotpassword';
import AccountInfo from './pages/account/AccountInfo';
import ProjectList from './pages/project/projectlist/ProjectList';
import CreateProject from './pages/project/createproject/CreateProject';
import ProjectInfo from './pages/project/projectinfo/ProjectInfo';
import AppInfo from './pages/app/appinfo/AppInfo';
import CreateApp from './pages/app/createapp/CreateApp';
import CreateAppMaster from './pages/environment/createenvironment/CreateAppMaster';
import ResetPassword from './pages/resetpassword/ResetPassword';
import PluginSelection from './pages/app/createapp/PluginSelection';
import RegionSelection from './pages/app/createapp/RegionSelection'
import EnvironmentInfo from './pages/environment/environmentinfo/EnvironmentInfo';
import LoginCallback from './pages/login/LoginCallback'
import VerifyEmail from './pages/registration/VerifyEmail';
import NoMatchPage from './containers/NoMatchPage'
import OrganizationInfo from './pages/organization/organizationinfo/OrganizationInfo'
import CreateOrganization from './pages/organization/createorganization/CreateOrganization'
import CreateClusterMaster from './pages/cluster/createcluster/CreateClusterMaster';
import InviteLogin from './pages/registration/InviteLogin'
import Support from './pages/support/Support.tsx'
import TicketDetail from "./pages/support/TicketDetail.tsx";
import NotificationComponent from './pages/notification/Notification'
import CreateTicketForm  from './pages/support/CreateTicketForm.tsx';
import SuccessCallback from './components/billing/SuccessCallback';
import CatalogSelection from './pages/app/createapp/CatalogSelection';
import OperatorSelection from './pages/app/createapp/OperatorSelection';
import FailureCallback from './components/billing/FailureCallback';
import HelmEnvironmentInfo from './pages/helmenvironment/helmenvironmentinfo/HelmEnvironmentInfo';
import CreateHelmEnvironment from './pages/helmenvironment/CreateHelmEnvironment';
import EditHelmEnvironment from './pages/helmenvironment/EditHelmEnvironment';
import EditOperatorEnvironment from "./pages/operators/EditOperatorEnvironment"
//import OperatorDetail from './pages/operators/operatorsinfo/OperatorDetail';
import CreateOperatorEnvironment from "./pages/operators/CreateOperatorEnvironment"
import LoginSSO from './pages/login/LoginSSO';
import CreateVCluster  from './pages/cluster/createcluster/vcluster/createVcluster';
import { OAuthRedirectSuccess } from './components/oauth/OAuthRedirect';
export default {
    DEFAULT:{
        component: Login,
        route: paths.DEFAULT
    },
    ResetPassword:{
        component: ResetPassword,
        route: paths.RESETPASSWORD
    }, 
    // DASHBOARD:{
    //     component: Dashboard,
    //     route: paths.DASHBOARD
    // },
    REGISTER:{
        component: Register,
        route: paths.REGISTER
    },
    REQUESTDEMO: {
        component: RequestDemo,
        route: paths.REQUESTDEMO
    },
    INVITE_LOGIN: {
        component: InviteLogin ,
        route: paths.INVITE_LOGIN
    },
    FORGOTPASSWORD:{
        component: ForgotPassword,
        route: paths.FORGOTPASSWORD
    },
    ACCOUNTDETAILS: {
        component: AccountInfo,
        route: paths.ACCOUNTDETAILS
    },
    PROJECTLIST: {
        component: ProjectList,
        route: paths.PROJECTLIST
    },
    CREATEPROJECT: {
        component: CreateProject,
        route: paths.CREATEPROJECT
    },
    PROJECTINFO: {
        component: ProjectInfo,
        route: paths.PROJECTINFO
    },
    APPDETAILS: {
        component: AppInfo,
        route: paths.APPDETAILS
    },
    CREATEAPP: {
        component: CreateApp,
        route: paths.CREATEAPP
    },
    CREATEAPP_SELECTPLUGIN: {
        component: PluginSelection,
        route: paths.CREATEAPP_SELECTPLUGIN
    },
    CREATEAPP_SELECTCATALOG: {
        component: CatalogSelection,
        route: paths.CREATEAPP_SELECTCATALOG
    },
    CREATEAPP_SELECTOPERATOR: {
        component: OperatorSelection,
        route: paths.CREATEAPP_SELECTOPERATOR
    },
    CREATEAPP_SELECTREGION: {
        component: RegionSelection,
        route: paths.CREATEAPP_SELECTREGION
    },
    CREATEENVIRONMENT: {
        component: CreateAppMaster,
        route: paths.CREATEENVIRONMENT
    },
    CREATEOPERATORENVIRONMENT: {
        component: CreateOperatorEnvironment,
        route: paths.CREATEOPERATORENVIRONMENT
    },
    EDITOPERATORENVIRONMENT: {
        component: EditOperatorEnvironment,
        route: paths.EDITOPERATORENVIRONMENT
    },
    CREATEHELMENVIRONMENT: {
        component: CreateHelmEnvironment,
        route: paths.CREATEHELMENVIRONMENT
    },
    EDITHELMENVIRONMENT: {
        component: EditHelmEnvironment,
        route: paths.EDITHELMENVIRONMENT
    },
    ORGANIZATIONINFO: {
        component: OrganizationInfo,
        route: paths.ORGANIZATIONINFO
    },
    ENVIRONMENTINFO: {
        component: EnvironmentInfo,
        route: paths.ENVIRONMENTINFO
    },
    HELMENVIRONMENTINFO: {
        component: HelmEnvironmentInfo,
        route: paths.HELMENVIRONMENTINFO
    },
    LOGINCALLBACK: {
        component: LoginCallback,
        route: paths.LOGINCALLBACK
    },
    BITBUCKETLOGINCALLBACK: {
        component: LoginCallback,
        route: paths.BITBUCKETLOGINCALLBACK
    },
    LOGINCALLBACKGITLAB: {
        component: LoginCallback,
        route: paths.LOGINCALLBACKGITLAB
    },
    VERIFYEMAIL: {
        component: VerifyEmail,
        route: paths.VERIFYEMAIL
    },
    CREATE_ORGANIZATION: {
        component: CreateOrganization,
        route: paths.CREATE_ORGANIZATION
    },
    CREATE_V_CLUSTER: {
        component: CreateVCluster,
        route: paths.CREATE_V_CLUSTER,
    },
    CREATE_ORG_CLUSTER: {
        component: CreateClusterMaster,
        route: paths.CREATE_ORG_CLUSTER
    },
    EDIT_ORG_CLUSTER: {
        component: CreateClusterMaster,
        route: paths.EDIT_ORG_CLUSTER
    },
    SUPPORT: {
        component: Support,
        route: paths.SUPPORT
    },
    TICKET_DETAIL: {
        component: TicketDetail,
        route: paths.TICKET_DETAIL,
    },
    TICKET_CREATE: {
        component: CreateTicketForm,
        route: paths.TICKET_CREATE,
    },
    NOTIFICATIONS: {
        component: NotificationComponent,
        route: paths.NOTIFICATIONS
    },
    PAYMENT_SUCCESS: {
        component: SuccessCallback,
        route: paths.PAYMENTSUCCESSCALLBACK
    },
    PAYMENT_FAILURE: {
        component: FailureCallback,
        route: paths.PAYMENTFAILURECALLBACK
    },
    LOGIN_SSO: {
        component: LoginSSO,
        route: paths.LOGIN_SSO
    },
    NOMATCHPAGE: {
        component: NoMatchPage
    },

    OAuth:{
        route: paths.OAuth,
        component: OAuthRedirectSuccess
    }
    // OPERATOR_DETAIL: {
    //     component: OperatorDetail,
    //     route: paths.OPERATOR_DETAIL
    // },
}
