import { makeStyles, Tab, Tabs} from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
//import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import ProjectTab from "./projectstab/ProjectsTab";
import SubscriptionTab from "./subscriptiontab/SubscriptionTab";
import VariablesTab from "./variablestab/VariablesTab";
import { useTranslation } from 'react-i18next';
import paths from "../../../../constants/paths";
import {TabPanel} from "../../../../components/tabpanel/TabPanel"
import { WithWidth } from '@material-ui/core/withWidth';
import { History } from 'history';
import palette from "../../../../theme/palette";
import { StyledTab, StyledTabs } from "../../../../components/StyledTab/StyledTab";
// import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
/* istanbul ignore next */
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "inherit",
    display: "flex",
    padding: "0",
    margin: "0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  tabs: {
    // borderRight: `1px solid ${theme.palette.divider}`,
    // [theme.breakpoints.down("md")]: {
    //   backgroundColor: "white",
    // },
  },
  tabPanel: {
    padding: "0",
    margin: "0",
    width: "100%",
    minWidth: 250,
  },
  labelContainer : {
    backgroundColor: palette.background.disabled,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    justifyContent: 'center',
    padding: '6px',
    borderRadius: '8px'
  },
  sideNavBar:{
    display:"flex", 
    flexDirection: 'column'
  }
}));

interface Props extends WithWidth,PropsFromRedux {
  history: History,
  innerTab: number,
  updateInnerTab:(value: number)=>void,
}

export function VerticalTabs(props: Props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [t] = useTranslation()

  /* istanbul ignore next */
  const getNamebyTabIndex = (newValue: any) => {
    const tabPanelObj = document.getElementById('settingsTab-' + newValue);
    if (tabPanelObj) {
      const name = tabPanelObj.getAttribute("name");
      if (name) {
        props.history.push({
          pathname: paths.PROJECTINFO.replace(":id", props.projectDetails?.id) + "#settings_" + name
        });
      }
    }
  }

  const handleChange = (e: any, newValue: any) => {
    //setValue(newValue);
    getNamebyTabIndex(newValue);
    props.updateInnerTab(newValue);
  };

  useEffect(() => {
    setValue(props.innerTab);
  }, [props.innerTab]);

  useEffect(() => {
    return (() => {
      props.updateInnerTab(0);
    })
  }, []);

  return (
      <div className={classes.root} data-test="main-container">
        <div className={classes.sideNavBar}>
          <p style={{display: isWidthDown("md", props.width) ? 'none' : 'flex'}} className={classes.labelContainer}>
            Settings
          </p>
          <StyledTabs
            orientation={isWidthDown("md", props.width) ? undefined : "vertical"}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
            variant="scrollable"
            indicatorColor="primary"
            data-test="tabs-container"
          >
              <StyledTab label={t('General')} icon={<SettingsOutlinedIcon fontSize="medium"/>} {...a11yProps(0)} data-test="general-tab"/>
              {/* <Tab label="Permissions" { ...a11yProps(1) } /> */}
              {/* <Tab label="Billing History" { ...a11yProps(2) } /> */}
              <StyledTab label={t('Variables')} icon={<SettingsEthernetIcon fontSize="medium"/>} {...a11yProps(1)} data-test="variables-tab"/>
              <StyledTab label={t('SettingsSubscription')} icon={<LocalAtmOutlinedIcon fontSize="medium"/>} {...a11yProps(2)} data-test="subscription-tab"/>
          </StyledTabs>
        </div>
          <TabPanel value={value} index={0} className={classes.tabPanel} name="general" data-test="general-tabpanel" padding={2} tabPanel="settingsTab">
              <ProjectTab />
          </TabPanel>
          {/* <TabPanel value={ value } index={ 1 } className={ classes.tabPanel }>
              <UserPermissionsTab />
          </TabPanel> */}
          {/* <TabPanel value={ value } index={ 2 } className={ classes.tabPanel }>
              <BillingTab />
          </TabPanel> */}
          <TabPanel value={value} index={1} className={classes.tabPanel} name="variables" data-test="variables-tabpanel" padding={2} tabPanel="settingsTab">
              <VariablesTab />
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.tabPanel} name="subscription" data-test="subscription-tabpanel" padding={2} tabPanel="settingsTab">
              <SubscriptionTab />
          </TabPanel>
      </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) =>{
  return {
    projectRole: state.ProjectReducer.projectRole,
    projectDetails: state.ProjectReducer.projectDetails
  };
};

// export default connect(mapStateToProps, null)(withWidth()(VerticalTabs));

const connector=connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withWidth()(VerticalTabs));
