import { Button, Divider, Grid, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RestoreFromTrashOutlinedIcon from "@material-ui/icons/RestoreFromTrashOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import StraightenOutlinedIcon from "@material-ui/icons/StraightenOutlined";
import clsx from "clsx";
import React, { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import { getDiffDays, isAuthorized } from "../../helpers/utils";
import {useTranslation} from 'react-i18next';
import NoContentImage from "../../components/nocontentimagecontainer/NoContentImage";
import { UserRole } from "../../models/Environment.model";
import { InitContainerType } from '../../models/Environment.model';
/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    // marginLeft: "auto",
    float: "right",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  jobHeader: {
    display: "flex",
    marginBottom: 24,
  },
  jobBtn: {
    marginLeft: "auto",
  },
  right: {
    float: "right",
  },
  jobCard: {
    marginBottom: 10,
  },
}));


interface InitCardProps{
initContainer: InitContainerType;
editAction: (id: number) => void;
deleteAction:(id: number) => void;
restoreAction:(id:number)=>void;
source:number;
envRole:UserRole;
}

export const InitContainerCard = (props:InitCardProps) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [t] = useTranslation()
  const {
    initContainer,
    editAction,
    deleteAction,
    restoreAction,
  } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
      <Card  className={classes.jobCard} data-test="main-container">
          <CardContent >
              <Grid container alignItems="center" spacing={2}>
                  <Grid item md={5}>
                      {/* <Typography variant="inherit" className="jobname oneLine" data-test="cont-name">
                          {initContainer.name}
                      </Typography> */}
                      <Typography variant="h6" data-test="cont-name">
                          {initContainer.name}
                      </Typography>
                  </Grid>
                  {initContainer.createdat && (
                    <Grid item md={2} xs={6} className="header-details-grid">
                        <Tooltip title={t('CronJob.CronJobInfo.createdTime')}  arrow>
                            <ScheduleOutlinedIcon className="svgicon" />
                        </Tooltip>
                        {/* <span className="infoGrid" data-test="cont-create">
                          {getDiffDays(initContainer.createdat, true)}
                        </span> */}
                        <Typography variant="caption" data-test="cont-create">
                          {getDiffDays(initContainer.createdat, true)}
                        </Typography>
                    </Grid>
                  )}
                  <Grid item md={2} xs={6} className="header-details-grid">
                      <Tooltip title={t('CronJob.CronJobInfo.image')} arrow>
                          <StraightenOutlinedIcon className="svgicon" />
                      </Tooltip>
                      {/* <span className="infoGrid" data-test="cont-image">{initContainer.image}</span> */}
                      <Typography variant="caption" data-test="cont-image">{initContainer.image}</Typography>
                  </Grid>

                  <Grid item md={3}>
                     <span style={{display:'flex',alignItems:'center',flexDirection:'row-reverse'}}>
                     <IconButton
                        className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        data-test="expand-btn"
                     >
                          <ExpandMoreIcon />
                     </IconButton>
                      {(props.source === 1 ||
              (props.source === 2 &&
                isAuthorized("update", props.envRole?.name))) && (
                <>
                    <IconButton
                      className={classes.right}
                      disabled={!(initContainer.active ?? true)}
                      title={t('CronJob.CronJobInfo.edit')}
                    >
                        <EditOutlinedIcon
                          onClick={() => {
                            if(initContainer.id){
                              editAction(initContainer.id);
                            }
                          }}
                          data-test="edit-btn"
                        />
                    </IconButton>
                </>
            )}

{(props.source === 1 ||
              (props.source === 2 &&
                isAuthorized("delete", props.envRole?.name))) && (
                <>
                    <IconButton
                      className={classes.right}
                        title={initContainer.active===undefined || initContainer.active?t('CronJob.CronJobInfo.delete'):t('InitContainer.InitContainerList.restore')}
                    >
                        {initContainer.active !== undefined &&
                    (initContainer.active ? (
                        <DeleteOutlinedIcon
                          onClick={() => {
                        if(initContainer.id){
                          deleteAction(initContainer.id);
                        }
                        }}
                          // title={t('CronJob.CronJobInfo.delete')}
                          data-test="delete-btn"
                        />
                    ) : (
                        <RestoreFromTrashOutlinedIcon
                          onClick={() => {
                        if(initContainer.id){
                          restoreAction(initContainer.id);
                        }
                        }}
                          color="secondary"
                          // title={t('InitContainer.InitContainerList.restore')}
                          data-test="restore-btn"
                        />
                    ))}
                        {initContainer.active ?? (
                        <DeleteOutlinedIcon
                          onClick={() => {
                       if(initContainer.id){
                        deleteAction(initContainer.id);
                       }
                      }}
                          // title={t('CronJob.CronJobInfo.delete')}
                          data-test="not-active-delete"
                        />
                  )}
                    </IconButton>
                </>
            )}
                     </span>
                  </Grid>
              </Grid>
          </CardContent>
          {expanded && <Divider/>}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                  <Grid container>
                      <Grid item md={12}>
                          <AceEditor
                            mode="javascript"
                            theme="monokai"
                            fontSize={14}
                            showPrintMargin={false}
                            width="100%"
                            height="200px"
                            readOnly={true}
                            value={initContainer.command}
                            setOptions={{
                              showLineNumbers: true,
                              tabSize: 4,
                            }}
                            style={{borderRadius: 16}}
                            data-test="editor"
                          />
                      </Grid>
                  </Grid>
              </CardContent>
          </Collapse>
      </Card>
  );
};

interface Props{
  editAction: (id: number) => void;
  deleteAction:(id: number) => void;
  restoreAction:(id:number)=>void;
  source:number;
  envRole:UserRole;
  addInitContainer: () => void;
  create:boolean;
  initContainers:InitContainerType[];
  currentInitContainer:InitContainerType|null;
  }
  
export default function RecipeReviewCard(props:Props) {
  const classes = useStyles();
  const [t] = useTranslation();

  const deleteAction = (id:number) => {
    props.deleteAction(id);
  };

  const editAction = (id:number) => {
    props.editAction(id);
  };

  const restoreAction = (id:number) => {
    props.restoreAction(id);
  };

  return (
      <div data-test="main-container">
          {
        // !props.create &&
        // props.source === 2 &&
        (isAuthorized("create", props.envRole?.name) || props.source === 1) && (
        <div className={classes.jobHeader}>
            <Button
              variant="contained"
              color="primary"
              className={classes.jobBtn}
              onClick={props.addInitContainer}
              disabled={props.create}
              data-test="add-job-btn"
            >
                {t('InitContainer.InitContainerList.addStartupJob')}
            </Button>
        </div>
        )
      }
          {props.initContainers?.length > 0 &&
        props.initContainers?.map(
          (initContainer) =>
            (initContainer.id) !==
              (props.currentInitContainer?.id ??
                props.currentInitContainer?.id) && (
                <InitContainerCard
                  key={initContainer.id}
                  initContainer={initContainer}
                  source={props.source}
                  deleteAction={deleteAction}
                  editAction={editAction}
                  restoreAction={restoreAction}
                  envRole={props.envRole}
                  data-test="cont-card"
                />
            )
        )}
          {/* {!props.initContainers && [1,2,3,4].map(ind=>(<Skeleton /> ) )
                                
                              } */}
          {props.initContainers &&
        props.initContainers.length === 0 &&
        props.source === 2 && (
          <NoContentImage
            message={t('InitContainer.InitContainerList.noScripts')}
            alt="No init containers"
            data-test="info-image"
          />
        )}
      </div>
  );
}
