import { Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import palette from '../../theme/palette';
import { makeStyles } from "@material-ui/core/styles";

type Props = {
    type?: string;
    module?: string;
    alt?: string;
    className?: string;
    message: string|JSX.Element;
    subtitle?: string|JSX.Element;
}
const useStyle = makeStyles({
    textColor:{
        color: palette.text.disabled
    }
})

export default function NoContentImage(props: Props) {
    const [src, setSrc] = React.useState("")
    const classes = useStyle();

    React.useEffect(() => {
        let _src = ""
        switch (props.type) {
            case "project":
                // _src = "/images/infographics/no_proj.svg"
                _src = "/images/infographics/no_proj_new.svg"
                break
            case "app":
                // _src = "/images/infographics/no_app.svg"
                _src = "/images/infographics/no_proj_new.svg"
                break
            case "env":
                // _src = "/images/infographics/no_env.svg"
                _src = "/images/infographics/no_proj_new.svg"
                break
            case "support":
                // _src = "/images/infographics/no_proj.svg"
                _src = "/images/infographics/no_proj_new.svg"
                break
            case "search":
                // _src = "/images/infographics/search.svg"
                _src = "/images/infographics/no_proj_new.svg"
                break
            case "404":
                _src = "/images/infographics/new_404.svg"
                break
            default:
                // _src = "/images/infographics/no_proj.svg"
                _src = "/images/infographics/no_proj_new.svg"
        }
        setSrc(_src)
    }, [props.type])

    return (
        <>
            {props.type === "404" ?
            (<Grid item xs md={6} lg={4} className="imgGrid" data-test="image-grid">
                <div data-test="message-container" style={{justifyContent:'center', alignItems: 'center'}}>
                    <Typography variant='h3' color='textPrimary'>Sorry, page not found!</Typography>
                    <Typography variant='body1' align='center' color='textSecondary' className='m-t-16' style={{marginBottom: 80}}>Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.</Typography>
                </div>
                <img
                    src={src}
                    alt={props.alt ?? `No ${props.module ?? 'content'}`}
                    className={props.className ?? "defaultImg"}
                    data-test="no-content-image"
                />                
            </Grid>)    : 
            (<Grid item xs className="imgGrid" data-test="image-grid">
                <img
                    src={src}
                    alt={props.alt ?? `No ${props.module ?? 'content'}`}
                    className={props.className ?? "defaultImg"}
                    data-test="no-content-image"
                />
                {/* <p data-test="message-container">{props.message ?? `No ${props.module ?? 'content'} found`}</p> */}
                <div data-test="message-container">
                    <Typography variant='h6' className={classes.textColor}>{props.message ?? `No ${props.module ?? 'content'} found`}</Typography>
                    <Typography variant='caption' className={classes.textColor}>{props.subtitle}</Typography>
                </div>
            </Grid>)}           
        </>
    )
}