import { Button, Card, Grid } from "@material-ui/core";
import React, { useState } from "react";
import AlertExpandableCard from "../../../../../components/alertmanager/AlertExpandableCard";
import { getAlertLists } from "../redux/action";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";

interface Props extends PropsFromRedux {
  alertLists: any;
}

function Template(props: Props) {
  const [isExpand,setIsExpand] = useState(false);
  const handleRefresh = () => {
    props.getAlertLists(props.environmentDetails?.id);
  }
  console.log(isExpand);
  
  return (
    <>
    <Grid container spacing={2} justify="flex-end">
      <Grid item>
      <Button
          onClick={() => handleRefresh()}
          color="primary"
          variant="contained"
        >
          Refresh
        </Button>
      </Grid>
    </Grid>
    <Grid container spacing={3} direction="column" className={`${isExpand ? "" :"alertwhitecontainer"} m-t-24 p-24`}>
        {props.alertLists &&
          props.alertLists.map((item: any, ind: number) => (
          <Grid item xs={12}>
            <AlertExpandableCard type="Template" alertDetails={item} setIsExpand={setIsExpand}/>
          </Grid>
        ))}
    </Grid>
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  // alertLists: state.AlertReducer.templateLists,
});
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getAlertLists: (id: number) => dispatch(getAlertLists(id)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(Template);
type PropsFromRedux = ConnectedProps<typeof connector>;
