import {
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import EditDialog from "./EditDialog";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { addRule } from "../../pages/environment/environmentinfo/alertmanager/redux/action";
import ConfirmActionPopup from "../confirmactionpopup/ConfirmActionPopup";
import { getAlertDate } from "../../helpers/utils";
import { StyledTableCell, StyledTableRow } from "../styledtabelcell/StyledTabelCell";

const useStyles = makeStyles(() => ({
  content: {
    padding: "5px",
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

interface Props extends PropsFromRedux {
  rules: any;
  type: string;
  template: string;
}

function AlertTable(props: Props) {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [editRule, setEditRule] = useState(null);
  const [alertRules, setAlertRules] = useState(
    props.rules ? props.rules : null
  );
  const [openConfirm, setOpenConfirm] = useState(false);
  const [alertName, setAlertName] = useState("");

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleEdit = (el: any) => {
    setEditRule(el);
    setOpenDialog(true);
  };

  const handleDisabled = (alertName: string) => {
    setOpenConfirm(true);
    setAlertName(alertName);
  };

  const handleConfirmPopupAgree = () => {
    const updatedRules = alertRules.map((rule: any) =>
      rule.alert === alertName ? { ...rule, disabled: !rule.disabled } : rule
    );
    setAlertRules(updatedRules);
    const updatedRule = updatedRules.find(
      (rule: any) => rule.alert === alertName
    );
    if (updatedRule) {
      props.addRule(props.environmentDetails?.id, props.template, updatedRule);
    }
    setOpenConfirm(false);
  };

  const handleConfirmPopupDisagree = () => {
    setOpenConfirm(false);
  };

  return (
    <>
      {openDialog && (
        <EditDialog
          openDialog={openDialog}
          handleClose={handleClose}
          rules={editRule}
          template={props.template}
        />
      )}

      {openConfirm && (
        <ConfirmActionPopup
          open={openConfirm}
          handleAgree={handleConfirmPopupAgree}
          handleDisAgree={handleConfirmPopupDisagree}
          yesText="yes"
          noText="No"
          message="Are you sure you want to update rule?"
        />
      )}

<TableContainer component={Paper} elevation={1} className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
              <StyledTableCell>Rule Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Duration</StyledTableCell>
              {props.type === "Rules" && (
                <StyledTableCell>Silence</StyledTableCell>
              )}
              {props.type === "Rules" && (
                <StyledTableCell>Action</StyledTableCell>
              )}
              {props.type !== "Rules" && (
                <StyledTableCell></StyledTableCell>
              )}
            </TableRow>
        </TableHead>
        <TableBody>
            {alertRules.map((el: any, ind: number) => (
              <StyledTableRow>
                <TableCell>{el.alert}</TableCell>
                <TableCell>{el.annotations?.description_display}</TableCell>
                <TableCell>{getAlertDate(el.for)}</TableCell>
                {props.type === "Rules" && (
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          name="bulk_edit"
                          color="primary"
                          checked={el.disabled}
                          onChange={() => handleDisabled(el.alert)}
                        />
                      }
                      label=""
                    />
                  </TableCell>
                )}

                <TableCell>
                    {props.type === "Rules" && (
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEdit(el)}
                        >
                          <EditIcon />
                        </IconButton>

                     )}
                  {/* </Grid> */}
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    addRule: (id: number, template: string, jsonBody: any) =>
      dispatch(addRule(id, template, jsonBody)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AlertTable);
