import React from 'react';
import { CircularProgress } from '@material-ui/core';
import AdjustIcon from '@material-ui/icons/Adjust';
import Slide from '@material-ui/core/Slide';
import { AppConstants } from '../constants/appconstants';
import { TransitionProps } from '@material-ui/core/transitions';
import palette from '../theme/palette';
import { CustomColors } from '../constants/enums';

export const Transition = React.forwardRef(function Transition(
  tprops: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...tprops} />;
});

export const getProgressIcon = (
  state: string,
  statusColor: string,
  size?: number
): JSX.Element => {
  switch (state) {
    case AppConstants.EnvironmentStatus.Running:
    case AppConstants.EnvironmentStatus.Stopped:
    case AppConstants.EnvironmentStatus.Succeeded:
    case AppConstants.EnvironmentStatus.Failed:
      return <AdjustIcon className="topIcon" style={{ color: statusColor }} />;

    default:
      return (
        <CircularProgress
          size={size || 15}
          thickness={5}
          style={{ color: statusColor, marginRight: 10 }}
        />
      );
  }
};

export function VersionRibbon() {
  return <div className="ribbon ribbon-top-right"><span>ßETA</span></div>
} 

export const getLabelStyles = (labelType?: string) => {
  switch (labelType) {
    case "Grey":
      return {
        color: palette.text.primary,
        backgroundColor: CustomColors.Grey.Disabledbg,
      };
    case "Info":
      return {
        color: CustomColors.Info.Dark,
        backgroundColor: CustomColors.Info.Lighter,
      };
    case "Success":
      return {
        color: CustomColors.Success.Dark,
        backgroundColor: CustomColors.Success.Lighter,
      };
    case "Warning":
      return {
        color: CustomColors.Warning.Dark,
        backgroundColor: CustomColors.Warning.Lighter,
      };
    case "Secondary":
      return {
        color: CustomColors.Secondary.Dark,
        backgroundColor: CustomColors.Secondary.Lighter,
      };
    case "Disabled":
    return {
      color: palette.text.disabled,
      backgroundColor: 'transparent',
    };
    default:
      return {
        // color: palette.text.primary, 
        backgroundColor: "transparent", 
      };
  }
};

export const getAppStatusColor = (state : string) => {
  switch (state) {
    case AppConstants.Status.Running:
      return {
        color: CustomColors.Success.Dark,
        backgroundColor: CustomColors.Success.Lighter,
      };
    case AppConstants.Status.Succeeded:
      return {
        color: CustomColors.Success.Dark,
        backgroundColor: CustomColors.Success.Lighter,
      };
    case AppConstants.EnvironmentStatus.FetchingStatus:
      return {
        color: CustomColors.Warning.Dark,
        backgroundColor: CustomColors.Warning.Lighter,
      };
    case AppConstants.EnvironmentStatus.Pending:
      return {
        color: CustomColors.Warning.Dark,
        backgroundColor: CustomColors.Warning.Lighter,
      };
    case AppConstants.EnvironmentStatus.Deploying:
      return {
        color: CustomColors.Warning.Dark,
        backgroundColor: CustomColors.Warning.Lighter,
      };
    case AppConstants.Status.Failed:
      return {
        color: CustomColors.Error.Dark,
        backgroundColor: CustomColors.Error.Lighter,
      };
    case AppConstants.Status.Stopped:
      return {
        color: CustomColors.Error.Dark,
        backgroundColor: CustomColors.Error.Lighter,
      };
    case AppConstants.PackageStatus.NotInstalled:
      return {
        color: CustomColors.Info.Dark,
        backgroundColor: CustomColors.Info.Lighter,
      };
    case AppConstants.PackageStatus.Installed:
      return {
        color: CustomColors.Success.Dark,
        backgroundColor: CustomColors.Success.Lighter,
      };
    default:
      return {
        color: palette.text.primary, 
        backgroundColor: "transparent", 
      };
  }
};