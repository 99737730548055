import React, { Component } from 'react'
import { withTranslation ,WithTranslation} from "react-i18next";
import { connect ,ConnectedProps} from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    Card,
    IconButton,
    CardContent,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, FormControlLabel, Switch ,createStyles, Theme, WithStyles,
    CardHeader,
    Divider
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StraightenOutlinedIcon from '@material-ui/icons/StraightenOutlined';
import cronstrue from 'cronstrue';
// import CronJobPopUp from './CronJobPopUp'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import { fetchJobDetails, clearCronJobDetails, fetchCronJobLog } from './redux/actions'
import { getDateInStandardFormat } from '../../helpers/utils'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import CronJobSkeleton from '../../components/skeletons/CronJobSkeleton'
import InfiniteScroll from 'react-infinite-scroll-component';
import { FilledStatusIndicator } from '../../components/statusindicator/statusindicator'
import { isAuthorized } from '../../helpers/utils'
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
//import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import BackdropLoader from '../../components/loader/BackdropLoader';
import RefreshIcon from '@material-ui/icons/Refresh';
import { LabelHandler } from "../../components/labelHandler/LabelHandler";
import { DateHandler } from "../../components/dateHandler/DateHandler"
import { Dispatch } from "redux";
import { CronJobLog, CronJobModel } from '../../models/cron.model';
import palette from '../../theme/palette';
/* istanbul ignore next */
const useStyles = (theme:Theme) =>createStyles ({
    jobHeader: {
        display: "flex"
    },
    createJob: {
        marginLeft: "auto"
    },
    jobStatus: {
        paddingLeft: 15,
        display: "flex"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    expandOpen: {
        transform: 'rotate(180deg)',
      },
      infoCardDivider:{
        borderBottom: `1px dashed ${palette.background.neutral}`, 
        backgroundColor: palette.white, 
        marginBottom: 24, 
    }
});

interface Props extends PropsFromRedux,WithStyles<typeof useStyles>,WithTranslation{
jobId:number;
handleJobMenu:(e:React.MouseEvent<HTMLButtonElement>,jobId:number,jobName:string)=>void;
getLastRunDateTime:(jobId:number)=>string;
handleUpdateSuspend:(e:React.ChangeEvent<HTMLInputElement>,jobDetails:CronJobModel,cb:()=>void)=>void;
handleJobEdit:(jobId:number)=>void;
handleJobRunnow:(jobId:number)=>void;
handleJobDelete:(jobId:number,jobName:string)=>void;
handleMenuClose:()=>void


}


interface State  {
 anchorEl: Element | ((element: Element) => Element) | null;
    currentLog: CronJobLog|null;
    pageNo:     number;
    pageSize:   number;
}

export class CronJobInfo extends Component<Props,State> {
    constructor(props:Props) {
        super(props);
        this.state = {
            anchorEl: null,
            // openLogPopup: false,
            currentLog: null,
            pageNo: 1,
            pageSize: 15,
            // showLogs: false
        }
    }

    componentDidMount() {
        this.props.fetchJobDetails(this.props.environmentDetails.id, this.props.jobId);
        this.props.fetchCronJobLog(this.props.environmentDetails.id, this.props.jobId, this.state.pageNo, this.state.pageSize);
    }

    componentWillUnmount() {
        this.props.clearCronJobDetails();
    }

    handleJobMenu = (e:React.MouseEvent<HTMLButtonElement>, jobId:number, jobName:string) => {
        this.setState({
            anchorEl : e.currentTarget,
        });
        this.props.handleJobMenu(e, jobId, jobName)
    }
    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
        });
        // this.props.handleMenuClose()
    }

    // handleShowLog = (log) => {
    //     this.setState({
    //         openLogPopup: true,
    //         currentLog: log
    //     });
    // }

    // handleCloseLogPopup = () => {
    //     this.setState({
    //         openLogPopup: false,
    //         currentLog: null
    //     });
    // }
    // handleShowLogs = () => {
    //     this.setState({
    //         showLogs: !this.state.showLogs
    //       })
        
    //   };

    loadMore = () => {
        let newPageNo = this.state.pageNo + 1;
        this.props.fetchCronJobLog(this.props.environmentDetails.id, this.props.jobId, newPageNo, this.state.pageSize);
        this.setState({
            pageNo: newPageNo
        });
    }

    /* istanbul ignore next */
    updateCallBack = () => {
        this.props.fetchJobDetails(this.props.environmentDetails.id, this.props.jobId);
    }

    refreshHistory = () => {
        this.props.fetchCronJobLog(this.props.environmentDetails.id, this.props.jobId, 1, this.state.pageSize);
    }

    render() {
        const { classes, cronjobDetails, cronjob_Log, envRole, t } = this.props;
        return (
            <div data-test="cronjobInfoContainer">
                <Card >
                    <CardHeader 
                        title={<Typography variant='h6'>Job details</Typography>}
                    />
                    <Divider />
                    <CardContent className='cornjobInfo'>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {
                                    cronjobDetails && 
                                    <div data-test="cronJobInfo">
                                        <Card>
                                            <CardHeader 
                                                title={<Typography variant='h6' data-test="cronJobName">{ cronjobDetails.name }</Typography>}
                                                action={
                                                    <Grid container>
                                                        <Grid item xs={6} className={`${!isAuthorized("update", this.props.envRole.name) ? "noCursor" : ""}`}>
                                                            <div className={ classes.jobStatus }>
                                                                <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        name="active"
                                                                        color="primary"
                                                                        checked={ !cronjobDetails.suspend }
                                                                        onChange={(e) => this.props.handleUpdateSuspend(e, cronjobDetails, this.updateCallBack)}
                                                                    />
                                                                        }
                                                                label={t('CronJob.CronJobCreate.active')}
                                                                className={!isAuthorized("update", this.props.envRole.name) ? "avoid-clicks" : "" }
                                                                data-test="active-status-toggle"
                                                                />
                                                            </div>
                                                        </Grid>
                                                        { 
                                                            isAuthorized("update", envRole.name) &&                                        
                                                            <Grid item xs={6} data-test="cronJobMenuOptions">
                                                                <IconButton onClick={ (e) => this.handleJobMenu(e, this.props.jobId, cronjobDetails.name) } className="right"
                                                                data-test="cron-job-menu-expand"
                                                                >
                                                                    <MoreVertIcon/>
                                                                </IconButton>
                                                                <Menu
                                                                anchorEl={ this.state.anchorEl }
                                                                disableScrollLock={true}
                                                                open={ Boolean(this.state.anchorEl) }
                                                                onClose={ () => this.handleMenuClose() }
                                                                getContentAnchorEl={ null }
                                                                anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
                                                                transformOrigin={ { vertical: 'top', horizontal: 'center' } }
                                                                data-test="cron-job-menu"
                                                                >
                                                                    <MenuItem onClick={ () => { this.props.handleJobEdit(this.props.jobId); this.handleMenuClose(); } } data-test="edit-item">{t('CronJob.CronJobInfo.edit')}</MenuItem>
                                                                    <MenuItem onClick={ () => { this.props.handleJobRunnow(this.props.jobId); this.handleMenuClose(); } } data-test="run-item">{t('CronJob.CronJobInfo.run')}</MenuItem>
                                                                    { isAuthorized("delete", envRole.name) && <MenuItem onClick={ () => { this.props.handleJobDelete(this.props.jobId, cronjobDetails.name); this.handleMenuClose(); }  } data-test="delete-item">{t('CronJob.CronJobInfo.delete')}</MenuItem> }
                                                                </Menu>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                }
                                            />
                                            <CardContent>
                                                <Divider className={classes.infoCardDivider}/>
                                                <Grid>
                                                    <Grid container alignItems="center">
                                                        <Grid item md={10} xs={12}>
                                                            <Grid container>
                                                                <Grid item md={12} xs={12}>
                                                                    <Grid container spacing={1}>
                                                                        {cronjobDetails?.user && 
                                                                            <Grid item md={3} xs={6} className="header-details-grid">
                                                                                <LabelHandler
                                                                                    label={`${cronjobDetails?.user?.first_name ?? ""} ${
                                                                                    cronjobDetails?.user?.last_name ?? ""
                                                                                    } `}
                                                                                    icon={<PersonOutlineIcon style={{color: palette.text.disabled}}/>}
                                                                                    iconTooltip={t(
                                                                                    "CronJob.CronJobInfo.createdBy"
                                                                                    )}
                                                                                    labelTooltip={t(
                                                                                    "CronJob.CronJobInfo.createdBy"
                                                                                    )}
                                                                                    variant='caption'
                                                                                    labelType='Disabled'
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        <Grid item md={3} xs={6} className="header-details-grid">
                                                                            <LabelHandler
                                                                                label={cronstrue.toString(cronjobDetails.schedule)}
                                                                                icon={<DateRangeOutlinedIcon style={{color: palette.text.disabled}}/>}
                                                                                iconTooltip={t("CronJob.CronJobInfo.jobTime")}
                                                                                labelTooltip={cronstrue.toString(cronjobDetails.schedule)}
                                                                                labelType='Disabled'
                                                                                variant='caption'
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={3} xs={6} className="header-details-grid">
                                                                            <DateHandler
                                                                                date={cronjobDetails.createdat}
                                                                                icon={<ScheduleOutlinedIcon style={{color: palette.text.disabled}}/>}
                                                                                labelType='Disabled'
                                                                                // iconTooltip={t('CronJob.CronJobInfo.createdTime')}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={3} xs={6} className="header-details-grid" style={{overflow: 'hidden'}}>
                                                                            <LabelHandler
                                                                                label={cronjobDetails.image}
                                                                                icon={<StraightenOutlinedIcon style={{color: palette.text.disabled}}/>}
                                                                                iconTooltip={t(
                                                                                    "CronJob.CronJobInfo.image"
                                                                                )}
                                                                                variant='caption'
                                                                                labelType='Disabled'
                                                                            />
                                                                        </Grid>
                                                                        {this.props.getLastRunDateTime(this.props.jobId) &&
                                                                            <Grid item md={2} xs={6} className="header-details-grid">
                                                                                <Tooltip title={t('CronJob.CronJobInfo.lastRun')} arrow>
                                                                                    <DirectionsRunIcon className="svgicon" />
                                                                                </Tooltip>
                                                                                <span className="infoGrid">{ this.props.getLastRunDateTime(this.props.jobId) } </span>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <AceEditor
                                                            placeholder={t('CronJob.CronJobCreate.enterCodeHere')} 
                                                            mode="javascript"
                                                            theme="monokai"
                                                            fontSize={14}
                                                            showPrintMargin={false}
                                                            width="100%"
                                                            height="200px" 
                                                            readOnly={true}
                                                            value={ cronjobDetails.command }
                                                            setOptions={{
                                                                    showLineNumbers: true,
                                                                    tabSize: 4
                                                                }}
                                                            style={{borderRadius: 16, marginTop: 20}}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                        {/* <Grid>
                                            <Grid container alignItems="center">
                                                <Grid item md={10} xs={12}>
                                                    <Grid container>
                                                        <Grid item md={12} xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item md={2} xs={6} className="header-details-grid">
                                                                    <LabelHandler
                                                                        label={cronstrue.toString(cronjobDetails.schedule)}
                                                                        icon={<DateRangeOutlinedIcon />}
                                                                        iconTooltip={t("CronJob.CronJobInfo.jobTime")}
                                                                        labelTooltip={cronstrue.toString(cronjobDetails.schedule)}
                                                                    />
                                                                </Grid>
                                                                {cronjobDetails?.user && 
                                                                    <Grid item md={2} xs={6} className="header-details-grid">
                                                                        <LabelHandler
                                                                            label={`${cronjobDetails?.user?.first_name ?? ""} ${
                                                                            cronjobDetails?.user?.last_name ?? ""
                                                                            } `}
                                                                            icon={<PersonOutlineIcon />}
                                                                            iconTooltip={t(
                                                                            "CronJob.CronJobInfo.createdBy"
                                                                            )}
                                                                            labelTooltip={t(
                                                                            "CronJob.CronJobInfo.createdBy"
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                }
                                                                <Grid item md={2} xs={6} className="header-details-grid">
                                                                    <DateHandler
                                                                        date={cronjobDetails.createdat}
                                                                        icon={<ScheduleOutlinedIcon />}
                                                                        // iconTooltip={t('CronJob.CronJobInfo.createdTime')}
                                                                    />
                                                                </Grid>
                                                                <Grid item md={3} xs={6} className="header-details-grid">
                                                                    <LabelHandler
                                                                        label={cronjobDetails.image}
                                                                        icon={<StraightenOutlinedIcon />}
                                                                        iconTooltip={t(
                                                                            "CronJob.CronJobInfo.image"
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                {this.props.getLastRunDateTime(this.props.jobId) &&
                                                                    <Grid item md={2} xs={6} className="header-details-grid">
                                                                        <Tooltip title={t('CronJob.CronJobInfo.lastRun')} arrow>
                                                                            <DirectionsRunIcon className="svgicon" />
                                                                        </Tooltip>
                                                                        <span className="infoGrid">{ this.props.getLastRunDateTime(this.props.jobId) } </span>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {isAuthorized("update", envRole.name) && (

                                                <Grid item md={1} className={`center ${!isAuthorized("update", this.props.envRole.name) ? "noCursor" : ""}`}>
                                                    <div className={ classes.jobStatus }>
                                                        <FiberManualRecordRoundedIcon fontSize="small" style={{ color: cronjobDetails.suspend ? "red" : green[500] }}/> 
                                                            <Typography style={{ marginLeft:"10px" }} variant="h5">{ cronjobDetails.suspend ? "Inactive" : "Active" }</Typography>
                                                        <FormControlLabel
                                                        control={
                                                            <Switch
                                                                name="active"
                                                                color="primary"
                                                                checked={ !cronjobDetails.suspend }
                                                                onChange={(e) => this.props.handleUpdateSuspend(e, cronjobDetails, this.updateCallBack)}
                                                            />
                                                                }
                                                        label={t('CronJob.CronJobCreate.active')}
                                                        className={!isAuthorized("update", this.props.envRole.name) ? "avoid-clicks" : "" }
                                                        data-test="active-status-toggle"
                                                        />
                                                    </div>
                                                </Grid>
                                                { 
                                                    isAuthorized("update", envRole.name) &&                                        
                                                    <Grid item md={1} data-test="cronJobMenuOptions">
                                                        <IconButton onClick={ (e) => this.handleJobMenu(e, this.props.jobId, cronjobDetails.name) } className="right"
                                                        data-test="cron-job-menu-expand"
                                                        >
                                                            <MoreVertIcon/>
                                                        </IconButton>
                                                        <Menu
                                                            id={`simple-menu-${index}`}
                                                        anchorEl={ this.state.anchorEl }
                                                        disableScrollLock={true}
                                                        open={ Boolean(this.state.anchorEl) }
                                                        onClose={ () => this.handleMenuClose() }
                                                        getContentAnchorEl={ null }
                                                        anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
                                                        transformOrigin={ { vertical: 'top', horizontal: 'center' } }
                                                        data-test="cron-job-menu"
                                                        >
                                                            <MenuItem onClick={ () => { this.props.handleJobEdit(this.props.jobId); this.handleMenuClose(); } } data-test="edit-item">{t('CronJob.CronJobInfo.edit')}</MenuItem>
                                                            <MenuItem onClick={ () => { this.props.handleJobRunnow(this.props.jobId); this.handleMenuClose(); } } data-test="run-item">{t('CronJob.CronJobInfo.run')}</MenuItem>
                                                            { isAuthorized("delete", envRole.name) && <MenuItem onClick={ () => { this.props.handleJobDelete(this.props.jobId, cronjobDetails.name); this.handleMenuClose(); }  } data-test="delete-item">{t('CronJob.CronJobInfo.delete')}</MenuItem> }
                                                        </Menu>
                                                    </Grid>
                                                } 
                                            </Grid>
                                        </Grid> */}
                                        {/* <Grid className="m-t-20" data-test="cronJobExpression">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="filled-multiline-flexible"
                                                        className="codeDiv"
                                                        multiline
                                                        rows={4}
                                                        //defaultValue="echo hello world"
                                                        variant="filled"
                                                        value={ cronjobDetails.command }
                                                    />
                                                    <AceEditor
                                                    placeholder={t('CronJob.CronJobCreate.enterCodeHere')} 
                                                    mode="javascript"
                                                    theme="monokai"
                                                    fontSize={14}
                                                    showPrintMargin={false}
                                                    width="100%"
                                                    height="200px" 
                                                    readOnly={true}
                                                    value={ cronjobDetails.command }
                                                    setOptions={{
                                                            showLineNumbers: true,
                                                            tabSize: 4
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>   */}

                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardHeader
                                        title={<Typography variant="h6">{t('CronJob.CronJobInfo.history')}</Typography>}
                                        action={
                                            <IconButton data-test="refreshButton" className='m-t-20' onClick={ () => { this.refreshHistory()}}>
                                                <RefreshIcon color="primary" />
                                            </IconButton>
                                        }
                                    />
                                    <Divider />
                                    <CardContent style={{marginTop: 12}}>
                                        {
                                            // !cronjob_Log && [1,2,3,4].map((item, ind) =>(<CronJobSkeleton  key={ind} data-test="cronJobSkeleon"/> ) )
                                            this.props.fetchingJobDetail && [1,2,3,4].map((item, ind) =>(<CronJobSkeleton  key={ind} data-test="cronJobSkeleon"/> ) )
                                        }
                                        {
                                            !cronjob_Log || cronjob_Log.length === 0 && 
                                            <Grid item xs className="imgGrid">
                                                <p data-test="no-log-message">{t('CronJob.CronJobInfo.noHistory')}</p>
                                            </Grid>  
                                        }
                                                    
                                        {
                                            cronjob_Log && cronjob_Log.length > 0 && 
                                            <InfiniteScroll
                                            dataLength={ cronjob_Log.length }
                                            next={this.loadMore.bind(this)}
                                            hasMore= { cronjob_Log.length === this.state.pageSize * this.state.pageNo }
                                            loader={<h4>Loading...</h4>}
                                            data-test="cron-job-log-list"
                                            scrollableTarget="scrollable-content"

                                            >
                                                {
                                                cronjob_Log.map((log:CronJobLog, index:number) => {
                                                    return (
                                                        <div data-test="cronJobLogContainer" key={index}>
                                                            <ExpansionPanel className="m-t-20">
                                                                <ExpansionPanelSummary
                                                                    expandIcon={ <ExpandMoreIcon fontSize="small" /> }
                                                                    aria-controls="panel1a-content"
                                                                >
                                                                    <Grid container spacing={1}>
                                                                        <Grid item md={1} style={{ alignSelf: 'center' }}>
                                                                            <FilledStatusIndicator status={log.status} />
                                                                        </Grid>
                                                                        <Grid item md={11}>
                                                                            <Typography variant="h5" color="primary" className="oneLine" >{getDateInStandardFormat(log.datetime)} </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </ExpansionPanelSummary>
                                                                    <ExpansionPanelDetails>

                                                                        <AceEditor
                                                                        
                                                                        mode="javascript"
                                                                        theme="monokai"
                                                                        fontSize={14}
                                                                        showPrintMargin={false}
                                                                        width="100%"
                                                                        height="200px" 
                                                                        readOnly={true}
                                                                        value= { log.log }
                                                                        setOptions={{
                                                                                showLineNumbers: true,
                                                                                tabSize: 4
                                                                            }}
                                                                            style={{
                                                                                marginTop: 20,
                                                                                borderRadius: 16
                                                                            }}
                                                                        />
                                                                
                                                                    </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                        </div>
                                                    )
                                                })
                                            }
                                            </InfiniteScroll>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {/* <Grid container data-test="cronJobHistory" justify="space-between" alignItems="center">
                    <Typography variant="h5" className='envTitle m-t-20'>{t('CronJob.CronJobInfo.history')}</Typography>
                    <IconButton data-test="refreshButton" className='m-t-20' onClick={ () => { this.refreshHistory()}}>
                        <RefreshIcon color="primary" />
                    </IconButton>
                </Grid> */}
             
                
                { this.props.fetchingJobDetail && <BackdropLoader message={t('CronJob.CronJobInfo.fetchingDetails')} data-test="fetching-loader" />}
                
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    cronjobDetails: state.CronJobReducer.cronjobDetails,
    cronjob_Log: state.CronJobReducer.cronjob_Log,
    envRole: state.EnvironmentReducer.envRole,
    fetchingJobDetail: state.CronJobReducer.fetchingJobDetail,
    //cronjob_Log: [{"DateTime":"2020-08-04T09:17:03Z","Log":"testing job logs\n","Namespace":"zerone-409-1013","Owner":"mytestjob","PodName":"mytestjob-1596532620-bb55v","Type":"Job"},{"DateTime":"2020-08-04T09:18:03Z","Log":"testing job logs\n","Namespace":"zerone-409-1013","Owner":"mytestjob","PodName":"mytestjob-1596532680-t9r48","Type":"Job"},{"DateTime":"2020-08-04T09:19:04Z","Log":"testing job logs\n","Namespace":"zerone-409-1013","Owner":"mytestjob","PodName":"mytestjob-1596532740-d8dtc","Type":"Job"},{"DateTime":"2020-08-04T09:20:05Z","Log":"testing job logs\n","Namespace":"zerone-409-1013","Owner":"mytestjob","PodName":"mytestjob-1596532800-drgzh","Type":"Job"},{"DateTime":"2020-08-04T09:21:06Z","Log":"testing job logs\n","Namespace":"zerone-409-1013","Owner":"mytestjob","PodName":"mytestjob-1596532860-f2qhn","Type":"Job"}]
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        fetchJobDetails: (eId:number, cId:number) => dispatch(fetchJobDetails(eId, cId)),
        fetchCronJobLog: (eId:number, cId:number, pageNo:number, pageSize:number) => dispatch(fetchCronJobLog(eId, cId, pageNo, pageSize)),
        clearCronJobDetails: () => dispatch(clearCronJobDetails())
    };
};

const connector= connect(
    mapStateToProps,
    mapDispatchtoProps
)
export default connector(withStyles(useStyles)(withTranslation()(CronJobInfo)));
type PropsFromRedux = ConnectedProps<typeof connector>;