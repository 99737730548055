import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { connect, ConnectedProps } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import ConfirmActionPopup from '../../../../../components/confirmactionpopup/ConfirmActionPopup';
import BackdropLoader from '../../../../../components/loader/BackdropLoader';
import SubscriptionCard from '../../../../../components/subscriptioncard/SubscriptionCard';
import SubscriptionList from '../../../../../components/subscriptionlist/SubscriptionList';
import { isAuthorized } from '../../../../../helpers/utils';
import { getSubscriptionListCall } from '../../../createproject/redux/actions';
import { editProjectApiCall } from '../../../redux/actions';
import './SubscriptionTab.css';
import {withTranslation, WithTranslation} from 'react-i18next';
import { currentOrgRole } from '../../../../login/redux/selectors';
import { Dispatch } from "redux";
import { History } from 'history';
import { ProjectModel, SubscriptionModel } from '../../../../../models/Project.model';
import { CommantIcon } from '../../../../../helpers/customIcon';

type Payload = Pick<ProjectModel,"id"|"optimize_cost"|"dedicated_lb">

interface ExtendedPayload extends Payload{
    subscription_id: number
}

interface Props extends WithTranslation, PropsFromRedux {
    history?: History
}

type State = {
    subscriptionList : SubscriptionModel[],
    selectedSubscriptionId : number,
    upgradeDisabled: boolean, 
    projectSubscription: SubscriptionModel|null, 
    message: string,
    isConfirmPopupOpen: boolean
}

export class BillingTab extends Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            subscriptionList : this.props.subscriptionList,
            //isSubscriptionSelected : false,
            selectedSubscriptionId : this.props.projectDetails ? this.props.projectDetails.subscription_id : 0,
            // selectedSubscriptionAmount : 0,
            // selectedSubscriptionName : 'Starter'
            upgradeDisabled: true, 
            projectSubscription: null, 
            message: "Are You Sure you want to upgrade the Subscription?",
            isConfirmPopupOpen: false
        }
    }

    componentDidMount(){
        const { getSubList , history } = this.props;
        getSubList(history)
        if(this.props.projectDetails){
            this.setState({
                projectSubscription: this.getSubscriptionWithId(this.props.projectDetails.subscription_id) || null
            })
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps: Props) => {
        if(newProps.projectDetails.id > 0)
        {
            if(!this.props.projectDetails.id || this.props.projectDetails.id !== newProps.projectDetails.id)
            {
                this.setState({
                    selectedSubscriptionId: newProps.projectDetails.subscription_id,
                    upgradeDisabled: true,
                })
                
            }
        }
        if(newProps.subscriptionList) {
            if((!this.props.subscriptionList || this.props.subscriptionList.length === 0) && newProps.subscriptionList.length > 0)
            {
                this.setState({
                    subscriptionList: newProps.subscriptionList
                }, () => {
                        this.setState({
                        projectSubscription: this.getSubscriptionWithId(newProps.projectDetails.subscription_id) || null
                    })          
                })
            }
        }
      }

    /*static getDerivedStateFromProps(nextProps, prevState) {
        const first = nextProps.subscriptionList || [];
        const second = prevState.subscriptionList || [];
        const isSame = first.every((e)=> second.includes(e)) && second.every((e)=> first.includes(e))
        if(!isSame){
            let subscriptionListDummy = nextProps.subscriptionList;
            let selectedSubscriptionAmount;
            let selectedSubscriptionName;

            subscriptionListDummy.forEach(subscription => {
                if(subscription.id === nextProps.projectDetails.subscription_id){
                    subscription[ 'selected' ] = true;
                    selectedSubscriptionAmount = subscription.price;
                    selectedSubscriptionName = subscription.name;
                }
            });
            return {
                subscriptionList : subscriptionListDummy,
                selectedSubscriptionAmount,
                selectedSubscriptionName 
            }
        }
     }*/

    handleSubscriptionClick = (subscription: SubscriptionModel) => {
        // const subscriptionListDummy = [ ...this.state.subscriptionList ];
        // let selectedSubscriptionAmount;
        // let selectedSubscriptionName;

        // subscriptionListDummy.forEach(subscription => {
        //     if(subscription.id === subscriptionId){
        //         subscription[ 'selected' ] = true;
        //         selectedSubscriptionAmount = subscription.price;
        //         selectedSubscriptionName = subscription.name;
        //     }else {
        //         subscription[ 'selected' ] = false
        //     }
        // });

        // this.setState({
        //     subscriptionList : subscriptionListDummy,
        //     selectedSubscriptionId: subscriptionId,
        //     isSubscriptionSelected : true,
        //     selectedSubscriptionAmount,
        //     selectedSubscriptionName 
        // })
        
        this.setState({
            selectedSubscriptionId: subscription.id,
        })
        if(this.props.projectDetails?.subscription?.price >= subscription?.price ){
            this.setState({
                upgradeDisabled: true
            })
        } else{
            this.setState({
                upgradeDisabled: false,
                message: `Are you sure you want to upgrade the Subscription to ${this.getSubscriptionWithId(subscription.id)?.name}? \n\n Once you upgrade, you won't be able to downgrade your subscription.`
            })
        }

    }

    getSubscriptionWithId = (id: number) => {
        if(this.state.subscriptionList){
            for(let i=0; i< this.state.subscriptionList.length; i++){
                const sub = this.state.subscriptionList[i]
                if(sub.id === id){
                    return sub
                }
            }
        }
    }

    isLowerSubscription(subscription: SubscriptionModel){
        if(this.props?.projectDetails?.subscription_id > subscription.id ){
           return true
        }
        return false
    }
 
    handleUpdateSubscription = () => {
        this.setState({
            isConfirmPopupOpen : true
        }) 
    }

    // getProjectSubscription = () => {
    //     if(this.state.subscriptionList && this.state.projectDetails){
    //         for(let i=0; i< this.state.subscriptionList.length; i++){
    //             const sub = this.state.subscriptionList[i]
    //             if(sub.id === this.state.projectDetails.subscription_id){
    //                 return sub
    //             }
    //         }
    //     }
    // }

    handleDisAgreeHandler = () => {
        this.setState({
            isConfirmPopupOpen : false
        })
    }
    
    handleAgreeHandler = () => {
        const payload = {
            //name : this.props.projectDetails.name,
            subscription_id : this.state.selectedSubscriptionId,
            id : this.props.projectDetails.id,
            optimize_cost: this.props.projectDetails.optimize_cost,
            dedicated_lb: this.props.projectDetails.dedicated_lb,
            //description: 'Required',
        }
        this.props.editProjectApiCallAction(payload , this.props.projectDetails.id);
        this.setState({
            isConfirmPopupOpen : false
        })
      }
    canUserUpgrade=()=>{
        // check if default organization and role is owner

        const isOrg=this.props.currentOrganization?.ID>0 || this.props.currentOrganization?.id>0;
        const isInDefOrgAndProjOwner=  !isOrg && this.props.projectRole.name==='Owner'

        // is inside org and is admin/owner of org
        const isOrgAdminOrOwner= isOrg && [0,1].includes(this.props.currentOrgRole) 
        return isInDefOrgAndProjOwner || isOrgAdminOrOwner
    }
    
    render () {

        const { subscriptionList, upgradeDisabled, projectSubscription, message } = this.state;
        const {projectRole,t} = this.props

        return (
            <div data-test="main-container">
                <div className='subscriptionCardsContainer'>
                   <Card>
                    <CardHeader 
                    title={<Typography variant='h6'>Select Subscription</Typography>}/>
                    <Divider />
                    <CardContent>
                        <Grid container 
                        className='subscriptionContianer' 
                        spacing={ 2 }
                        >
                            {
                                subscriptionList === null && [0,1,2,3,4,5].map(value => {
                                    return (
                                        <Grid item md={ 4 } sm= { 6 } xs={ 12 } key={ value } data-test="sub-skel">
                                            <Paper variant='outlined' style={{padding : '1rem'}}>
                                                <SkeletonTheme height={250}>
                                                    <div className='oneRemMarginBottomSeperator'>
                                                        <Skeleton />
                                                    </div>
                                                    <div className='oneRemMarginBottomSeperator'>
                                                        <Skeleton />
                                                    </div>
                                                    <br />
                                                    <Skeleton count={5} />
                                                </SkeletonTheme>
                                            </Paper>
                                        </Grid>
                                    )
                                })
                            }
                            {  isAuthorized("update", projectRole.name) ? (
                                <SubscriptionList 
                                    isLowerSubscription = {this.isLowerSubscription}
                                    handleSubscriptionClick = {this.handleSubscriptionClick}
                                    selectedSubscriptionId = {this.state.selectedSubscriptionId}
                                    subscriptionList = {subscriptionList}
                                    data-test="sub-list"
                                />                         
                            ) : (   projectSubscription && (
                            <Grid item md={ 4 } sm= { 6 } xs={ 12 }>
                                <SubscriptionCard 
                                cardDisabled={false}
                                details = { projectSubscription }
                                selectedSubscriptionId = { this.state.selectedSubscriptionId }
                                handleSubscriptionClick = { ()=>{} }
                                data-test="sub-card"
                                />
                            </Grid>
                                )
                            )}

                        </Grid>
                        {
                            subscriptionList && subscriptionList.length > 0 &&
                            <Typography
                              gutterBottom
                              variant="body1"
                              style={{alignItems:'center', display: 'flex', gap: 24, marginTop: 60}}
                            >
                                <CommantIcon viewBox='0 0 40 40' />
                                {t("Projects.CreateProject.cannotDowngrade")}
                            </Typography>
                        }
                    </CardContent>
                    <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                        {  isAuthorized("update", projectRole.name) &&  this.canUserUpgrade()  && (                            
                            <div>
                                <Button variant='contained' color='primary' disabled={upgradeDisabled }
                                onClick={ this.handleUpdateSubscription }
                                data-test="upgrade-btn"
                                > {t('Projects.SubscriptionTab.upgradeLabel')}
                                </Button>
                            </div>
                        )}
                    </CardActions>
                   </Card>
                    <ConfirmActionPopup open={ this.state.isConfirmPopupOpen } handleAgree={ this.handleAgreeHandler } handleDisAgree={ this.handleDisAgreeHandler } message={message} yesText={t('Projects.SubscriptionTab.yesText')} noText={t('Projects.SubscriptionTab.noText')} data-test="confirm-popup"/>
                    {this.props.editingProject && <BackdropLoader message={t('Projects.SubscriptionTab.updateSubsMessage')} />}

                </div>
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        subscriptionList : state.CreateProjectReducer.subscriptionList,
        projectDetails: state.ProjectReducer.projectDetails,
        projectRole: state.ProjectReducer.projectRole,
        editingProject: state.ProjectReducer.editingProject,
       
        currentOrgRole: currentOrgRole(state),
        currentOrganization: state.AuthReducer.currentOrganization,
    }
}

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        getSubList : (history: History|undefined) => dispatch(getSubscriptionListCall(history)),
      editProjectApiCallAction : (payload: ExtendedPayload , id: number) => dispatch(editProjectApiCall(payload , id))
    }
  }

// export default withRouter(connect(
//     mapStateToProps,
//     mapDispatchtoProps
// )(withTranslation()(BillingTab)))

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(BillingTab));

