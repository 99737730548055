import { Button, Card, CardActions, CardContent, CardHeader, Divider, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {
    Formik
} from 'formik';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as Yup from 'yup';
import ConfirmActionPopup from '../../../components/confirmactionpopup/ConfirmActionPopup';
import ImageCropper from '../../../components/imagecropper/ImageCropper';
import MuiTextField from '../../../components/textfield/MuiTextField';
import './ProfileTab.css';
import { deactivateAccount, updateProfileDetails } from './redux/actions';
import {withTranslation, WithTranslation} from 'react-i18next';
import LinkedAccounts from './LinkedAccounts';
import { Dispatch } from "redux";
import { UserModel } from '../../../models/Account.model';
import { QuotaInfo } from '../../../components/quotaInfo/quotaInfo';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import { UpdatePhotoIcon } from '../../../helpers/customIcon';
import PasswordTab from '../passwordtab/PasswordTab';
import InsertChartIcon from "@material-ui/icons/InsertChartOutlined";
import { InsertLink } from '@material-ui/icons';

// const styles = {
//     raisedPrimary: {
//         color: colors.red[900],
//         backgroundColor: colors.red[600],
//         '&:hover': {
//             backgroundColor: colors.red[800],
//             // Reset on mouse devices
//             '@media (hover: none)': {
//                 backgroundColor: colors.red[600],
//             },
//         },
//     },
// };

type Payload = Pick<UserModel, "first_name"|"last_name"|"email"|"id">

interface ExtendedPayload extends Payload{
    image?: string,
    company?: string
}

interface Props extends WithTranslation, PropsFromRedux {}

type State = {
    first_name: string,
    last_name: string,
    email: string,
    company: string,
    isformTobeEdited: boolean,
    isPasswordToBeEdited: boolean,
    isPopupOpened: boolean,
    imgUploadeditModeEnabled: boolean,
    isUpdatePassword: boolean
}

export class Profile extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            first_name: this.props.userDetails?.first_name,
            last_name: this.props.userDetails?.last_name,
            email: this.props.userDetails?.email,
            company: this.props.userDetails?.company,
            isformTobeEdited: false,
            isPasswordToBeEdited: false,
            isPopupOpened: false,
            imgUploadeditModeEnabled: false,
            isUpdatePassword: false
        }
    }

    // componentDidMount() {
    //     this.props.getAccountsList()
    //     const $this = this;
    //     window.addEventListener('message', function(e){
    //         // if(e?.data?.source?.match('react-devtools') || e.origin !== window?.config?.REACT_APP_MESSAGE_ORIGIN_URL) return;
    //         if(e?.data?.source?.match('react-devtools')) return;
    //         $this.props.getAccountsList();
    //     }, false) 
    // }

    // componentWillUnmount(){
    //     this.props.clearAccountList()
    // }

    /* istanbul ignore next */
    // handleOnChange = (e) => {
    //     this.setState({
    //         [e.target.name]: e.target.value
    //     })
    // }

    handleDeleteAccount = () => {
        this.setState({
            isPopupOpened: true,
        })
    }

    handleDisAgreeHandler = () => {
        this.setState({
            isPopupOpened: false
        })
    }

    handleAgreeHandler = () => {
        this.props.deactivateAccount();
        this.setState({
            isPopupOpened: false
        })
    }

    /* istanbul ignore next */
    handleUpdate = () => {
        const payload: Payload = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            id: this.props.userDetails.id,
        }
        this.props.updateProfileDetailsAction(payload)
    }

    handleEdit = () => {
        this.setState({
            isformTobeEdited: true
        })
    };

    handleCancel = () => {
        this.setState({
            isformTobeEdited: false,
            
        })
    }

    handleImgUpload = () => {
        this.setState({
            imgUploadeditModeEnabled: true,
        })
    }

    handleImgUploadCancel = () => {
        this.setState({
            imgUploadeditModeEnabled: false
        })
    }

    imageSuccessCallBack = (fileURL: string) => {
        const payload: ExtendedPayload = {
            image: fileURL,
            id: this.props.userDetails.id,
            first_name: this.props.userDetails.first_name,
            last_name: this.props.userDetails.last_name,
            email: this.props.userDetails.email
        }
        this.props.updateProfileDetailsAction(payload)
        this.handleImgUploadCancel();
    }

    render() {
        const { isformTobeEdited } = this.state;
        const {t} = this.props;        
        return (
            <div data-test="main-container" className='detailsContainer'>
                <Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Card className='profileParentContainer'>
                                        <CardContent>
                                            <Grid container spacing={2} alignItems="center" justifyContent="center" className='profileContainer'>
                                                <Grid item>
                                                    <div className="imgContaineruser">
                                                        <div className="imgWrapperuser" style={{ backgroundImage: `url(${this.props.userDetails && this.props.userDetails.image ? this.props.userDetails.image : '/images/infographics/noimg.jpg'})` }} >
                                                            <div className='imageUploadContainer' data-test="image-edit-icon" onClick={() => this.handleImgUpload()}>
                                                                <UpdatePhotoIcon style={{width: 32, height: 32}}/>
                                                                Update photo
                                                            </div>
                                                        </div>
                                                        {/* <span className="circlebtnuser">
                                                            <EditIcon data-test="image-edit-icon" onClick={() => this.handleImgUpload()} fontSize="small" className="upload-icon-align" />
                                                        </span> */}
                                                    </div>
                                                </Grid>
                                                {/* <Grid item className='profileImageNote'>
                                                    <Typography variant='caption'>
                                                        Allowed *.jpeg, *.jpg, *.png, *.gif
                                                        Max size of 3.1 MB
                                                    </Typography>
                                                </Grid> */}
                                                {/* <Grid item>
                                                    <FormControlLabel
                                                        className='switchLabel'
                                                        label="Public profile"
                                                        control={
                                                            <Switch
                                                                name="bulk_edit"
                                                                color="primary"
                                                            />
                                                        }
                                                        labelPlacement="start"
                                                    />
                                                </Grid> */}
                                                {/* <Grid item>
                                                    <div className='userDetaisContainer'>
                                                        <p className='userName'>{this.props.userDetails?.first_name + ' ' + this.props.userDetails?.last_name}</p>
                                                        <p className='userCompany'>{this.props.userDetails?.company}</p>
                                                        <p className='profileEmail'>{this.state.email}</p>

                                                    </div>

                                                </Grid> */}
                                            </Grid>

                                        </CardContent>
                                        {/* <Divider /> */}
                                        <CardActions className='profileContainerButtonWrapper'>

                                            <Button data-test="da-button" variant='contained' color="secondary" size="small" onClick={() => this.handleDeleteAccount()}>
                                                {t('Account.ProfileTab.deactivateAccount')}
                                            </Button>

                                            {/* <Button data-test="da-button" color="secondary" size="small" startIcon={<HighlightOffRoundedIcon />} onClick={() => this.handleDeleteAccount()}>
                                                {t('Account.ProfileTab.deactivateAccount')}
                                            </Button> */}

                                            {/* <Button data-test="ea-button" color="primary" size="small" startIcon={<EditIcon />} onClick={() => this.handleEdit()}>
                                                {t('Account.ProfileTab.editAccount')}
                                            </Button> */}

                                        </CardActions>
                                    </Card>
                                </Grid>
                                {this.props.userDetails && this.props.userDetails.quotas &&   
                                    <Grid item xs={12} md={12} style={{marginTop: 16}}>
                                        <QuotaInfo quotaInfo={this.props.userDetails.quotas}/>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={2} direction='column'>
                                <Grid item xs={12}>
                                    <Card>
                                        {/* <CardHeader title={t('Account.ProfileTab.editInfoTitle')}/>
                                        <Divider /> */}
                                        <CardContent>

                                            <Formik initialValues={{
                                                firstName: this.props.userDetails?.first_name, 
                                                company: this.props.userDetails?.company,
                                                lastName: this.props.userDetails?.last_name,
                                                email: this.props.userDetails?.email
                                            }}
                                                onSubmit={(values) => {
                                                    const payload: ExtendedPayload = {
                                                        first_name: values.firstName,
                                                        last_name: values.lastName,
                                                        email: this.state.email,
                                                        company: values.company,
                                                        id: this.props.userDetails.id,
                                                    }
                                                    this.setState({
                                                        isformTobeEdited: false
                                                    })
                                                    this.props.updateProfileDetailsAction(payload)
                                                }}
                                                validationSchema={
                                                    Yup.object().shape({
                                                        firstName: Yup.string()
                                                            .min(2, t('Account.PasswordTab.tooShort'))
                                                            .max(30, t('Account.PasswordTab.tooLong'))
                                                            .required(t('Account.ProfileTab.firstNameError')),
                                                        lastName: Yup
                                                            .string()
                                                            .min(2, t('Account.PasswordTab.tooShort'))
                                                            .max(30, t('Account.PasswordTab.tooLong'))
                                                            .required(t('Account.ProfileTab.lastNameError')),
                                                        company: Yup
                                                            .string()
                                                            .min(2, t('Account.PasswordTab.tooShort'))
                                                            .max(30, t('Account.PasswordTab.tooLong'))
                                                            .required(t('Account.ProfileTab.companyNameError'))
                                                    })}
                                            >
                                                {
                                                    (props) => {
                                                        const {
                                                            values,
                                                            touched,
                                                            errors,
                                                            //dirty,
                                                            //isSubmitting,
                                                            handleChange,
                                                            handleBlur,
                                                            handleSubmit,
                                                            //handleReset,
                                                            resetForm,
                                                            isValid,
                                                        } = props;

                                                        return (
                                                            <form onSubmit={handleSubmit} >
                                                                <Grid>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12} md={6}>
                                                                            <MuiTextField
                                                                                id="firstName"
                                                                                error={Boolean(errors.firstName && touched.firstName)}
                                                                                label={t('Account.ProfileTab.firstNameLabel')}
                                                                                name="firstName"
                                                                                autoFocus
                                                                                style={{ display: 'block' }}
                                                                                onChange={handleChange}
                                                                                value={values.firstName}
                                                                                onBlur={handleBlur}
                                                                                helperText={((errors.firstName && touched.firstName) && errors.firstName)?.toString()} margin="normal"
                                                                            />

                                                                        </Grid>

                                                                        <Grid item xs={12} md={6}>

                                                                            <MuiTextField
                                                                                id="lastName"
                                                                                error={Boolean(errors.lastName && touched.lastName)}
                                                                                type="lastName"
                                                                                label={t('Account.ProfileTab.lastNameLabel')}
                                                                                name="lastName"
                                                                                style={{ display: 'block' }}
                                                                                onChange={handleChange}
                                                                                value={values.lastName}
                                                                                onBlur={handleBlur}
                                                                                helperText={((errors.lastName && touched.lastName) && errors.lastName)?.toString()} margin="normal"
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6}>
                                                                            <MuiTextField
                                                                                id="email"
                                                                                error={Boolean(errors.company && touched.company)}
                                                                                type="email"
                                                                                label={"Email"}
                                                                                value={values.email}
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6}>
                                                                            <MuiTextField
                                                                                id="company"
                                                                                error={Boolean(errors.company && touched.company)}
                                                                                type="company"
                                                                            //   color='secondary'
                                                                                label={t('Account.ProfileTab.companyNameLabel')}
                                                                                style={{ display: 'block' }}
                                                                                name="company"
                                                                                onChange={handleChange}
                                                                                value={values.company}
                                                                                onBlur={handleBlur}
                                                                                helperText={((errors.company && touched.company) && errors.company)?.toString()}
                                                                            />
                                                                        </Grid>

                                                                    </Grid>
                                                                </Grid>
                                                                <br />
                                                                {/* <Divider /> */}
                                                                <CardActions className='accountDetailsButtonWrapper' style={{justifyContent: this.state.isUpdatePassword ? 'flex-end' : 'space-between'}}>
                                                                    {!this.state.isUpdatePassword && (
                                                                        <div style={{display: 'flex', alignItems: 'center', gap: 8, cursor: 'pointer'}} onClick={() => this.setState({isUpdatePassword: true})}>
                                                                            <Typography color='primary'>Update password</Typography>
                                                                            <InsertLink color='primary' fontSize='medium'/>
                                                                        </div>
                                                                    )}
                                                                    <div style={{display: 'flex', gap: 12}}>
                                                                        <Button color="primary" variant="contained" size="small" disabled={this.state.first_name === values.firstName && this.state.last_name === values.lastName && this.state.company === values.company? true: false} 
                                                                        onClick={() => {
                                                                            this.handleCancel();
                                                                            resetForm();
                                                                        }}>
                                                                            {t('Account.ProfileTab.cancelLabel')}
                                                                        </Button>
                                                                        <Button variant="contained" disabled={!isValid || (this.state.first_name === values.firstName && this.state.last_name === values.lastName && this.state.company === values.company? true: false)} color="primary" size="small" type='submit' >
                                                                            {t('Account.ProfileTab.updateDetailsLabel')}
                                                                        </Button>
                                                                    </div>
                                                                </CardActions>

                                                            </form>
                                                        )
                                                    }
                                                }
                                            </Formik>

                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.isUpdatePassword && <PasswordTab handleCancel={() => this.setState({isUpdatePassword: false})}/>}
                                </Grid>
                                {/* <Grid item xs={12}>
                                    {this.props.userDetails && this.props.userDetails.quotas &&   
                                        <Grid item xs={12} md={12}>
                                            <QuotaInfo quotaInfo={this.props.userDetails.quotas}/>
                                        </Grid>
                                    }
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ConfirmActionPopup open={this.state.isPopupOpened} handleAgree={this.handleAgreeHandler} handleDisAgree={this.handleDisAgreeHandler} message={t('Account.ProfileTab.deactivateMessage')} yesText={t('Account.ProfileTab.yesText')} noText={t('Account.ProfileTab.noText')} />
                {
                    this.state.imgUploadeditModeEnabled && 
                    <ImageCropper 
                        data-test="image-cropper"
                        title={t('Account.ProfileTab.imageCropperTitle')}
                        isOpen={this.state.imgUploadeditModeEnabled}
                        onClose={this.handleImgUploadCancel}
                        onSuccess={ this.imageSuccessCallBack}
                    />
                }
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    userDetails: state.AuthReducer.user,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        updateProfileDetailsAction: (payload: ExtendedPayload) => dispatch(updateProfileDetails(payload)),
        deactivateAccount: () => dispatch(deactivateAccount())
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(Profile));
