import {
  Card,
  Collapse,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
  IconButton,
  Button,
  Grid,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AlertCard from "./AlertCard";
import AlertTable from "./AlertTable";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import {
  getRules,
  installTemplate,
  unInstallTemplate,
} from "../../pages/environment/environmentinfo/alertmanager/redux/action";
import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import CircularLoader from "../loader/CircularLoader";
import palette from "../../theme/palette";
import { CustomColors } from "../../constants/enums";

const useStyles = makeStyles(() => ({
  cardHeader: {
    padding: "16px 8px",
  },
  expand: {
    transform: "rotate(0deg)",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeaderAction: {
    marginTop: 0 ,
    marginRight: 0,
  },
  spinner: {
    marginRight: 10,
    animationDuration: "500",
  },
  buttonUninstall: {
    width: "90px",
    backgroundColor: CustomColors.other.danger, 
    fontSize: '11px', 
    // color: palette.white,
    padding: '3px 16px',
    borderRadius: 8
  },
  buttonInstall: {
    width: "90px",
    backgroundColor: palette.background.primary, 
    fontSize: '11px', 
    // color: palette.white,
    padding: '3px 16px',
    borderRadius: 8
  }
}));

interface Props extends PropsFromRedux {
  type: string;
  alertDetails:{
    template: string;
    is_installed: boolean;
  };
  setIsExpand?: any
}

function AlertExpandableCard(props: Props) {
  const classes = useStyles();

  const [expandSysVar, setExpandSysVar] = useState(false);
  const [filteredRules, setFilteredRules] = useState<any>(null);
  const [confirm, setConfirm] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const[disable, setDisable] = useState(false)


  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const rules = props.rulesList.find(
      (item: any, ind: number) => item.template === props.alertDetails.template
    );
    setFilteredRules(rules);
  }, [props.rulesList]);

  const handleExpand = () => {
    const exist = props.rulesList.some(
      (item: any, ind: number) => item.template === props.alertDetails.template
    );
    !exist &&
      !expandSysVar &&
      props.getRules(props.environmentDetails?.id, props.alertDetails.template);
    setExpandSysVar(!expandSysVar);
    props.setIsExpand(!expandSysVar);
  };

  const handleInstallation = (is_installed:boolean) => {
    setConfirm(true)
    setIsInstalled(is_installed)
  };

  const handleConfirmPopupDisagree = () => {
    setConfirm(false);
  }

  const handleConfirmPopupAgree = () => {
    if (isInstalled === true) {
      props.unInstallTemplate(props.environmentDetails?.id, props.alertDetails.template);
    } else {
      const jsonBody = {
        template_name:props.alertDetails.template
      }
      props.installTemplate(props.environmentDetails?.id, props.alertDetails.template, jsonBody);
    }
    setConfirm(false);
    setDisable(true);
  }

  return (
    <>
    <Card elevation={props.type === "Rules" ? 13 : expandSysVar ? 1 : 14} className="alertTemplate">
      <CardHeader
        className={classes.cardHeader}
        classes={{ action: classes.cardHeaderAction }}
        title={
          <Typography variant="h5" display="inline">
            <strong>{props.alertDetails.template}</strong>
          </Typography>
        }
        avatar={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expandSysVar,
            })}
            onClick={() => handleExpand()}
          >
            <ExpandMoreIcon color="primary"/>
          </IconButton>
        }
        action={ props.type === "Template" &&
          <Button
          // variant="contained"
          // color={props.alertDetails.is_installed ? "secondary" : "primary"}
          onClick={() => handleInstallation(props.alertDetails.is_installed)}
          disabled={disable}
          className={props.alertDetails.is_installed ? classes.buttonUninstall : classes.buttonInstall}
          style={{color: palette.white}}
        >
          {props.alertDetails.is_installed ? "Uninstall" : "Install"}
        </Button>
        
        }
      />
      <Collapse in={expandSysVar} data-test="card-content">
        <Divider />
        <br />
        <CardContent data-test="not-applied-card-content">
          {isMobile
            ? filteredRules && (
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <AlertCard
                        type={props.type}
                        rules={filteredRules.rules}
                      />
                    </Grid>
                </Grid>
              )
            : filteredRules ? (
                <Grid container spacing={2}>
                  <AlertTable
                    rules={filteredRules.rules}
                    type={props.type}
                    template={props.alertDetails.template}
                  />
                </Grid>
              ):
              <Grid container justify="center">
                <CircularLoader 
                  message={`Fetching ${props.alertDetails.template} rules`}
                  size={30}
                  thickness={5}
                  typoSize="h4"
                />
              </Grid>
              }
        </CardContent>
      </Collapse>
    </Card>

    {confirm && (
      <ConfirmActionPopup
        open={confirm}
        handleAgree={handleConfirmPopupAgree}
        handleDisAgree={handleConfirmPopupDisagree}
        yesText="yes"
        noText="No"
        message={<p>Are you sure to {isInstalled === true?"uninstall":"install"} <strong>{props.alertDetails.template}</strong> template</p>}
      />
    )}
  </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  rulesList: state.AlertReducer.rulesList,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getRules: (id: number, template: string) =>
      dispatch(getRules(id, template)),
    installTemplate: (id: number, template: string, jsonBody: any) =>
      dispatch(installTemplate(id, template, jsonBody)),
    unInstallTemplate: (id: number, template: string) =>
      dispatch(unInstallTemplate(id, template)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AlertExpandableCard);
