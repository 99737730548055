import { Box, Card, CardContent, CardHeader, Divider, Grid, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { LabelHandler } from "../../components/labelHandler/LabelHandler";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import { getDateInStandardFormat, getDiffDays, gitDiffBtnScanDate } from "../../helpers/utils";
import { CustomColors } from "../../constants/enums";
import palette from "../../theme/palette";

interface Props {
    openDeatils: (reportId: number) => void;
    index: any;
    item: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    cardPadding: {
        padding: "15px"
    },
    cardMarginTop : {
        marginTop: '24px'
    },
    statusIcons:{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    dividers:{
        backgroundColor: palette.white, 
        borderBottom: `1px dashed ${palette.background.neutral} !important`
    },
}));

function ClusterscanList(props: Props) {
    const classes = useStyles();
    const { openDeatils, index, item } = props;

    const cardHandler = () => {
        openDeatils(item.id);
    }

    return (
        <div data-test="main-container" className={classes.cardMarginTop}>
            <Grid container spacing={2}>
                <Grid item xs={12} key={index}>
                    <Card
                        data-test="Cluster-scaned-list-container"
                        style={{ cursor: "pointer" }}
                        onClick={cardHandler}
                        elevation={1}
                    >
                        <CardHeader
                            title={
                                // <LabelHandler
                                //     icon={item.status === "completed" ?
                                //         <CheckOutlinedIcon fontSize="medium" style={{ color: "#4CAF50" }} /> :
                                //         item.status === "error" ?
                                //             <CloseOutlinedIcon fontSize="medium" style={{ color: "#FF5252" }} /> :
                                //             <CircularProgress size={18} style={{ color: "#FF9800" }} />}
                                //     iconTooltip={item.status}
                                //     label={item.name}
                                //     labelTooltip={item.name}
                                //     data-test="cluster-name"
                                // />
                                <Box style={{display: "flex", alignItems: 'center', gap: 16}}>
                                    <div style={{backgroundColor: item.status === "completed" ? CustomColors.Success.Lighter : item.status === "error" ? CustomColors?.Info?.Lighter : CustomColors?.Warning.Lighter, padding: 12, borderRadius: 12, width: 'fit-content', display: 'flex', alignItems: 'center'}}>
                                        <Tooltip title={item.status}>
                                            {item.status === "completed" ?
                                                <CheckOutlinedIcon fontSize="medium" style={{ color: CustomColors.Success.Dark }} /> 
                                                :
                                                item.status === "error" ?
                                                <CloseOutlinedIcon fontSize="medium" style={{ color: CustomColors.other.icon }} /> :
                                                <CircularProgress size={24} style={{ color: CustomColors.Warning.Dark }} />
                                            }
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Typography variant="subtitle1">{item.name?.split("-", 2)[0]}</Typography>
                                        <Typography variant="caption">Posted date: {item.name?.split("-").slice(1).join("-")}</Typography>
                                    </div>

                                </Box>
                            }
                        />
                        <Divider className={classes.dividers}/>
                        <CardContent className={classes.cardPadding}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={6}>
                                            <LabelHandler
                                                icon={<RestoreOutlinedIcon style={{color: palette.text.disabled}}/>}
                                                iconTooltip={item.time_taken + " sec"}
                                                label={gitDiffBtnScanDate(item.time_taken)}
                                                labelTooltip={item.time_taken + " sec"}
                                                data-test="time-taken"
                                                variant="caption"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <LabelHandler
                                                icon={<AccessTimeOutlinedIcon style={{color: palette.text.disabled}}/>}
                                                iconTooltip={getDateInStandardFormat(item.createdAt)}
                                                label={getDiffDays(item.createdAt, true)}
                                                labelTooltip={getDateInStandardFormat(item.createdAt)}
                                                variant="caption"
                                                data-test="date"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <LabelHandler
                                                icon={<SettingsOutlinedIcon style={{color: palette.text.disabled}}/>}
                                                iconTooltip={item.plugin_name}
                                                label={item.plugin_name}
                                                labelTooltip={item.plugin_name}
                                                data-test="Trivy"
                                                variant="caption"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <LabelHandler
                                                icon={<ReportProblemOutlinedIcon style={{color: palette.text.disabled}}/>}
                                                iconTooltip={item.vulnerability_count}
                                                label={`Vulnerability Count(${item.vulnerability_count})`}
                                                labelTooltip={item.vulnerability_count}
                                                data-test="vulnerability-count"
                                                variant="caption"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default ClusterscanList;
